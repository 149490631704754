import axios from 'axios/index';

export const axiosClients = {
    graphql: {
        client: axios.create({
            withCredentials: true,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json'
            }
        })
    },
    graphqlMultipart: {
        client: axios.create({
            withCredentials: true,
            responseType: 'json',
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    rest: {
        client: axios.create({
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json'
            }
        })
    },
    api: {
        client: axios.create({
            baseURL: '/api',
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json'
            }
        })
    },
    auth: {
        client: axios.create({
            baseURL: '/api/auth',
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json'
            }
        })
    },
    apiMultipart: {
        client: axios.create({
            baseURL: '/api',
            responseType: 'json',
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    empty: {
        client: axios.create({
            baseURL: '',
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
};

