import React, {Component} from 'react';
import {
    Typography,
    Button,
    MenuItem,
    CircularProgress,
    withStyles
} from "@material-ui/core";
import {withTranslation} from 'react-i18next';
import Flex from 'components/grid/Flex';
import SidebarSelect from 'components/input/SidebarSelect';
import * as colors from 'components/colors/Colors';
import _ from 'lodash';
import utils from 'utils/Utils';
import MultiInput from 'components/input/MultiInput';
import ConfirmationDialog from 'components/confirmation-dialog/ConfirmationDialog';
import Subordinate from './Subordinate';
import HmoBorderedButton from 'components/button/HmoBorderedButton';
import Tag from 'components/tags/Tag';
import PersonIcon from '@material-ui/icons/Person';
import Dropzone from 'react-dropzone';
import Resizer from "react-image-file-resizer";
import HmoAvatar from 'components/avatar/HmoAvatar';
import EpiAlert from 'components/alert/EpiAlert';
import DefaultTextField from 'components/hmo-textfield/DefaultTextField';
import Accordion from 'components/grid/Accordion';
import UserManager from 'scenes/user-manager/UserManager';
import AccessManager from 'scenes/user-manager/access-manager/AccessManager';
import {CLINIC} from 'domain/User.model';
import NewContactDialog from './NewContactDialog';
import MultiInputEmailAddressEditor from 'scenes/user-manager/access-manager/MultiInputEmailAddressEditor';
import {CLINIC_CENTER} from 'domain/EntityAuthorizationType.model';

export const styles = theme => ({
    container: {
        "@media (min-width: 1350px)": {
            maxWidth: '48%',
            width: '50%'
        },
        maxWidth: '100%',
        width: '100%',
        margin: 10
    }
});

export class Contact extends Component {

    state = {
        oldContact: {},
        isDeleteDialogOpen: false,
        contactToDelete: '',
        newImage: '',
        isNewContactDialogOpen: false
    }

    componentDidMount() {
        this.setState({oldContact: this.props.contact});
    }

    toggleNewContactDialog = () => {
        this.setState({isNewContactDialogOpen: !this.state.isNewContactDialogOpen});
    }

    save = event => {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.setState({
            oldContact: this.props.contact
        }, () => {
            this.props.saveContact(this.props.contact);
        })
    }

    changeState = event => {
        this.props.onContactChange(this.props.contact)(event);
    }

    toggleContactPersonType = contactPersonType => {
        let contactPersonTypes = [...this.props.contact.personTypes];
        const index = contactPersonTypes.findIndex(i => i === contactPersonType);
        if (index === -1) {
            contactPersonTypes.push(contactPersonType);
        } else {
            contactPersonTypes.splice(index, 1);
        }
        this.changeState({
            target: {
                name: "personTypes",
                value: contactPersonTypes
            }
        });
    }

    handlePhoneNumbersChange = (props) => {
        this.changeState({
            target: {
                name: 'phoneNumbers',
                value: props.phoneNumbers
            }
        });
    }

    handleFaxNumbersChange = (props) => {
        this.changeState({
            target: {
                name: 'faxNumbers',
                value: props.faxNumbers
            }
        });
    }

    handleEmailAddressesChange = (props) => {
        this.changeState({
            target: {
                name: 'emailAddresses',
                value: props.emailAddresses
            }
        });
    }

    openDeleteDialog = (contactToDelete) => event => {
        this.setState({
            contactToDelete
        });
    }

    deleteUserDetails = () => {
        this.props.deleteUserDetails(this.state.contactToDelete);
        this.setState({
            contactToDelete: ''
        });
    }

    closeDeleteDialog = () => {
        this.setState({
            contactToDelete: ''
        });
    }

    onDrop = (acceptedFiles) => {
        if (acceptedFiles) {
            try {
                Resizer.imageFileResizer(
                        acceptedFiles[0],
                        300,
                        300,
                        "JPEG",
                        100,
                        0,
                        file => {
                            this.props.uploadContactImage(this.props.contact, file)
                        },
                        "file",
                        200,
                        200
                );
            } catch (err) {
                console.log(err);
                this.setState({alert: 'not-an-image'});
            }
        }
    };

    closeSnackbar = () => {
        this.setState({alert: ''});
    }

    addSubordinate = (subordinate, contact) => {
        this.props.addSubordinate(subordinate, contact);
        this.toggleNewContactDialog();
    }

    render() {
        const {
            t: translate, contact, contacts, configuration, clinicCenters, isLoading, refreshContacts, classes
        } = this.props;
        const {
            changeState, openDeleteDialog, deleteUserDetails, save, toggleContactPersonType, handleFaxNumbersChange,
            onDrop, toggleNewContactDialog, addSubordinate
        } = this;
        const {contactToDelete, isNewContactDialogOpen} = this.state;

        const isContactChanged = utils.anyPropertyChanged(this.state.oldContact, contact);
        const subordinates = contacts.filter(contactInArray => contactInArray.subordinateToBusinessId === contact.businessId);
        return (
                <div className={classes.container}>
                    <Accordion openByDefault={!contact.givenName} titleComponent={
                        <Flex item container justifyContent={'space-between'} alignItems={'center'}>
                            <Typography style={{
                                fontWeight: 'bold'
                            }}>
                                {translate('global.contact')}: {utils.formattedUserName(contact)}
                            </Typography>

                            <Flex item={'0 1 auto'} container alignItems={'center'}>
                                {
                                    contact?.userBusinessId
                                            ? <UserManager {...{
                                                userBusinessId: contact?.userBusinessId,
                                                emailAddresses: contact?.emailAddresses
                                            }}/>
                                            : <AccessManager {...{
                                                disabled: !contact.clinicCenterBusinessId,
                                                patient: null,
                                                person: contact,
                                                invitedRoleType: CLINIC,
                                                buttonStyle: {height: 30},
                                                beforeOpen: save,
                                                callback: refreshContacts,
                                                entityIdToAssign: clinicCenters?.find(center => center.businessId === contact.clinicCenterBusinessId)?.id,
                                                entityType: CLINIC_CENTER
                                            }}/>
                                }
                                <Button size={'small'} variant={'outlined'} onClick={openDeleteDialog(contact)}
                                        style={{margin: '0px 10px'}}>
                                    {translate('global.delete')}
                                </Button>
                                {
                                    isContactChanged && !isLoading &&
                                    <Button size={'small'}
                                            style={{backgroundColor: contact?.clinicCenterBusinessId ? 'orange' : 'grey'}}
                                            onClick={save}
                                            disabled={!contact?.clinicCenterBusinessId}>
                                        {translate('global.save')}
                                    </Button>
                                }
                                {
                                    isLoading && <CircularProgress/>
                                }
                            </Flex>
                        </Flex>
                    }>
                        <Flex container column>
                            <Flex id={'first-line'} item container wrap>
                                <Flex item container>
                                    <DefaultTextField label={translate("case.salutation")}
                                                      style={{width: '100%'}}
                                                      select
                                                      SelectProps={{
                                                          value: contact.salutation || '',
                                                          name: 'salutation',
                                                          onChange: changeState
                                                      }}>
                                        {configuration.salutations && configuration.salutations.map(salutation => (
                                                <MenuItem key={salutation} value={salutation}>
                                                    {translate(`global.${salutation}`)}
                                                </MenuItem>
                                        ))}
                                    </DefaultTextField>
                                    <DefaultTextField value={contact.title}
                                                      name={'title'}
                                                      style={{width: '100%'}}
                                                      label={translate("case.title")}
                                                      onChange={changeState}/>
                                </Flex>
                                <Flex item container>
                                    <DefaultTextField value={contact.givenName || ''}
                                                      label={translate("case.first-name")}
                                                      style={{width: '100%'}}
                                                      name={'givenName'}
                                                      onChange={changeState}/>

                                    <DefaultTextField label={translate("case.last-name")}
                                                      value={contact.familyName || ''}
                                                      onChange={changeState}
                                                      style={{width: '100%'}}
                                                      name={'familyName'}/>

                                </Flex>
                            </Flex>
                            <Flex id={'image-line'} item container>
                                <div style={{height: 150, width: 150, paddingRight: 10, paddingBottom: 10}}>
                                    {
                                        contact.avatarDownloadUrl &&
                                        <HmoAvatar user={contact} style={{height: 140, width: 140}}/>
                                    }
                                    {
                                        !contact.avatarDownloadUrl &&
                                        <Dropzone onDrop={onDrop}>
                                            {({getRootProps, getInputProps, isDragActive}) => {
                                                return (
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            <PersonIcon style={{
                                                                height: 140, width: 140, borderRadius: '50%',
                                                                fill: colors.persianGreen
                                                            }}/>
                                                        </div>
                                                )
                                            }}
                                        </Dropzone>
                                    }
                                </div>
                                <Flex item container column>
                                    <DefaultTextField label={translate("global.occupation")}
                                                      value={contact.occupation || ''}
                                                      onChange={changeState}
                                                      name={'occupation'}/>
                                    <SidebarSelect {...{
                                        value: clinicCenters.find(cc => cc.businessId === contact.clinicCenterBusinessId) || '',
                                        onChange: changeState,
                                        name: 'clinicCenterBusinessId',
                                        inputLabel: translate("global.clinic-center"),
                                        options: clinicCenters,
                                        renderValue: value => value.name,
                                        labelGet: 'name',
                                        valueGet: 'businessId',
                                        error: !contact?.clinicCenterBusinessId
                                    }}/>
                                </Flex>
                            </Flex>

                            <Flex id={'third-line'} item container>
                                <Flex item container column style={{marginRight: 10}}>
                                    <MultiInput {...{
                                        inputValues: contact.phoneNumbers,
                                        translate,
                                        name: 'phoneNumbers',
                                        updateInputValues: this.handlePhoneNumbersChange
                                    }}/>
                                </Flex>
                                <Flex item container column>
                                    {
                                        contact.userEmailAddress && !contact.emailAddresses.includes(contact.userEmailAddress) &&
                                        <DefaultTextField value={contact.userEmailAddress || ''}
                                                          label={translate("global.user-email-address")}
                                                          disabled
                                                          onChange={() => {
                                                          }}/>
                                    }
                                    <MultiInputEmailAddressEditor {...{
                                        entityIdToAssign: clinicCenters
                                                .find(cc => cc.businessId === contact.clinicCenterBusinessId)?.id,
                                        entityType: CLINIC_CENTER,
                                        emailAddresses: contact.emailAddresses,
                                        invitedRoleType: CLINIC,
                                        person: contact,
                                        updateInputValues: this.handleEmailAddressesChange,
                                        refresh: refreshContacts
                                    }}/>
                                </Flex>
                            </Flex>
                            <Flex id={'forth-line'} item container column>
                                <MultiInput {...{
                                    inputValues: contact.faxNumbers,
                                    translate,
                                    name: 'faxNumbers',
                                    updateInputValues: handleFaxNumbersChange
                                }}/>
                            </Flex>
                            <Flex id={'fifth-line'} item container>
                                <DefaultTextField
                                        label={translate('global.comment')}
                                        multiline
                                        rows={3}
                                        style={{width: '100%'}}
                                        value={contact.comment || ''}
                                        onChange={changeState}
                                        name={'comment'}
                                />
                            </Flex>
                            <Flex id={'sixth-line'} item container style={{flexWrap: 'wrap', margin: '10px 0px'}}>
                                {
                                    configuration.contactPersonTypes.map((contactPersonType, index) => (
                                            <Tag key={index} {...{
                                                doTranslate: true,
                                                key: index,
                                                selected: contact.personTypes.includes(contactPersonType),
                                                tag: contactPersonType,
                                                toggleSelection: toggleContactPersonType
                                            }}/>
                                    ))
                                }
                            </Flex>
                            {
                                subordinates && (subordinates || []).map((subordinate, index) => (
                                        <Subordinate key={`${subordinate.businessId} - index`} {...{
                                            contact: subordinate, configuration,
                                            deleteUserDetails: this.props.deleteUserDetails,
                                            onContactChange: this.props.onContactChange,
                                            saveContact: this.props.saveContact,
                                            changeState,
                                            refreshContacts,
                                            entityIdToAssign: clinicCenters
                                                    .find(cc => cc.businessId === contact.clinicCenterBusinessId)?.id,
                                        }}/>
                                ))
                            }
                            <Flex item grow={0} container justifyContent={'center'} style={{margin: 10}}>
                                <HmoBorderedButton style={{flex: 1}} onClick={toggleNewContactDialog} size={'small'}>
                                    + {translate('global.add-secretary')}
                                </HmoBorderedButton>
                            </Flex>
                        </Flex>
                    </Accordion>
                    <ConfirmationDialog {...{
                        dialogOpen: !_.isEmpty(contactToDelete),
                        onDialogClose: this.closeDeleteDialog,
                        onConfirm: deleteUserDetails,
                        confirmationTextKey: "clinic.delete-contact"
                    }}/>
                    <EpiAlert
                            {...{
                                isOpen: !!this.state.alert,
                                close: this.closeSnackbar,
                                severity: this.state.alert === 'error' ? this.state.alert : 'success',
                                message:
                                        this.state.alert === 'error'
                                                ? translate('global.backend-call-failed')
                                                : this.state.alert
                                                        ? translate(`global.${this.state.alert}`)
                                                        : '',
                            }}
                    />
                    {
                        isNewContactDialogOpen &&
                        <NewContactDialog {...{
                            toggleNewContactDialog,
                            addSubordinate,
                            contact
                        }}/>
                    }
                </div>
        );
    }
}

export default withStyles(styles)(withTranslation()(Contact));
