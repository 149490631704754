import thunk from 'redux-thunk';
import rootReducer from './Root.reducer';
import { multiClientMiddleware } from 'redux-axios-middleware';
import { axiosClients } from './Clients';
import { interceptors as interceptorsConfig } from './interceptors-config';
import {configureStore} from "@reduxjs/toolkit";
import HMOApi from "./api/HMOApi";
import {setupListeners} from "@reduxjs/toolkit/query";

const axiosOptions = {
    returnRejectedPromiseOnError: true,
    interceptors: interceptorsConfig
};

const initialState = {};

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({immutableCheck: false, serializableCheck: false})
                .concat([HMOApi.middleware])
                .concat(thunk)
                .concat(multiClientMiddleware(axiosClients, axiosOptions))
    },
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production'
});

setupListeners(store.dispatch);

export default store;

