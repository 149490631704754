import {useEffect, useState} from "react";
import {deleteProgramTemplates, getCareProvidersProgramTemplates, saveProgramTemplates} from "../ManagedCare.action";
import {sortSteps} from "scenes/patient/functions";
import {Typography, Button, Switch, Box, Radio, RadioGroup, FormControlLabel} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ShrinkTextField from "components/hmo-textfield/ShrinkTextField";
import Flex from "components/grid/Flex";
import TemplateStep from "./TemplateStep";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import _ from "lodash";
import Title from "scenes/managed-care/component/Title";
import HmoBorderedButton from "components/button/HmoBorderedButton";
import {isCaseManager, isManager} from "domain/User.model";
import {ThemeProvider} from "@material-ui/core/styles";
import {patientTheme} from "scenes/patient/PatientPage";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {AssignmentModel} from "./AssignmentModal";
import {useDispatch, useSelector} from "react-redux";
import {useTheme} from "@material-ui/core/styles";
import AddNewStepButton from "../../patient/program-view/AddNewStepButton";
import {useUpsertProgramTemplateMutation} from "../../patient/ProgramApi";
import {omit} from "lodash/fp";
import {useSnackbar} from 'notistack';
import {positionalRelationShipReducer} from 'domain/ProgramStepTemplate.model';
import {mapProgramTemplateByCareProvider} from 'domain/ProgramTemplate.model';

const useStyles = makeStyles((theme) => ({
    listOfProgramItems: {
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        padding: 0,
        marginBottom: "10px",
        "& > li:not(:last-child)": {
            marginBottom: theme.spacing(2),
        },
        "& > li": {
            display: "block",
        },
    },
    sideBar: {
        marginTop: "14px",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[200],
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
    },
    listOfGrantedAccesses: {
        marginTop: theme.spacing(1),
        backgroundColor: "white",
        border: `1px solid ${theme.palette.divider}`,
        padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    },
}));

const Steps = (props) => {
    const {
        classes,
        reorderSteps,
        sortedSteps,
        isDraggable,
        deleteStep,
        editableId,
        user,
        setEditableId,
        saveStep,
        addStep,
    } = props;
    return (
            <>
                <DragDropContext onDragEnd={reorderSteps}>
                    <Droppable droppableId={"sortableActions"}>
                        {(provided) => (
                                <ul
                                        className={classes.listOfProgramItems}
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            flex: "1 1 auto",
                                        }}
                                >
                                    {sortedSteps.map((step, index) => {
                                        return (
                                                <Draggable
                                                        draggableId={"step_id_" + step.id}
                                                        index={index}
                                                        isDragDisabled={!isDraggable}
                                                        canDragInteractiveElements={true}
                                                        key={step.id}
                                                >
                                                    {(provided) => (
                                                            <li
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                            >
                                                                <TemplateStep
                                                                        {...{
                                                                            index,
                                                                            step,
                                                                            otherStepsInProgram: sortedSteps
                                                                                    .filter((s) => s.id !== step.id)
                                                                                    .map((_step, index) => ({
                                                                                        ..._step,
                                                                                        position: index + 1,
                                                                                    })),
                                                                            deleteStep,
                                                                            editableId,
                                                                            user,
                                                                            setEditableId,
                                                                            saveStep,
                                                                        }}
                                                                />

                                                                {isManager(user) && editableId === null && (
                                                                        <AddNewStepButton
                                                                                onClick={() => {
                                                                                    addStep(index + 1);
                                                                                }}
                                                                        />
                                                                )}
                                                            </li>
                                                    )}
                                                </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </ul>
                        )}
                    </Droppable>
                </DragDropContext>
                {isManager(user) && sortedSteps.length === 0 && (
                        <AddNewStepButton
                                onClick={() => {
                                    addStep();
                                }}
                        />
                )}
            </>
    );
};

const CareProviderAssignmentSidebar = (props) => {
    const {
        t,
        classes,
        program,
        careProviders,
        selectedCareProvidersIds,
        dispatch,
        updateTemplatesAndSelectedCareProviders,
        programSet,
        save,
        setIsAssignmentModalOpen,
        selectedCareProviders,
    } = props;
    const theme = useTheme();
    const onChange = (event) => {
        if (event.target.value === "ALL") {
            const notSelectedCareProviders = careProviders.filter(
                    (careProvider) => !selectedCareProvidersIds.includes(careProvider.id)
            );

            const stepsToCreate = sortSteps(program.steps, program.stepsOrder);
            const templatesToAdd = notSelectedCareProviders
                    .map(careProvider => careProvider.id)
                    .map(mapProgramTemplateByCareProvider(program, stepsToCreate));
            dispatch(saveProgramTemplates(templatesToAdd))
                    .then(() => {
                        updateTemplatesAndSelectedCareProviders();
                    })
                    .finally(() => {
                        programSet(
                                {
                                    ...program,
                                    sharedType: event.target.value,
                                },
                                save
                        );
                    });
        } else {
            programSet(
                    {
                        ...program,
                        sharedType: event.target.value,
                    },
                    save
            );
        }
    };
    return (
            <Box className={classes.sideBar}>
                <RadioGroup value={program.sharedType} onChange={onChange}>
                    <FormControlLabel
                            style={{alignItems: "flex-start"}}
                            labelPlacement="end"
                            value="ALL"
                            control={<Radio color="primary"/>}
                            label={t("template-access-assignment.give-access-to-all")}
                    />
                    <Box mb={2} display={"flex"}/>
                    <FormControlLabel
                            style={{alignItems: "flex-start"}}
                            labelPlacement="end"
                            value="INVITED"
                            control={<Radio color="primary"/>}
                            label={t("template-access-assignment.give-access-to-selected-cme")}
                    />
                </RadioGroup>
                {program.sharedType === "INVITED" && (
                        <>
                            <Button
                                    fullWidth
                                    size="small"
                                    style={{margin: "14px auto 0"}}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<AddIcon/>}
                                    onClick={() => {
                                        setIsAssignmentModalOpen(true);
                                    }}
                            >
                                {t("template-access-assignment.add-btn")}
                            </Button>
                            {selectedCareProviders.length > 0 && (
                                    <Box className={classes.listOfGrantedAccesses}>
                                        {selectedCareProviders.map((careProvider, idx) => (
                                                <div
                                                key={idx}
                                                        style={{
                                                            borderBottom: `1px solid ${theme.palette.divider}`,
                                                            padding: idx === 0 ? "0 0 8px" : "8px 0",
                                                        }}
                                                >
                                                    {careProvider.name}
                                                </div>
                                        ))}
                                    </Box>
                            )}
                        </>
                )}
            </Box>
    );
};

export const ProgramTemplateEditor = (props) => {
    const {
        program,
        programChange,
        toggleProgramSuspendedStatus,
        onEnrollBack,
        deleteStep,
        addStep,
        programSet,
        reorderSteps,
        reset,
        user,
        style,
        toggleDeleteDialog,
        save,
        editableId,
        setStepInEditableMode,
        saveStep,
    } = props;
    const [isAssignmentModalOpen, setIsAssignmentModalOpen] = useState(false);
    const [selectedCareProvidersIds, setSelectedCareProvidersIds] = useState([]);
    const [careProvidersTemplates, setCareProvidersTemplates] = useState([]);
    const [upsertProgramTemplate, {}] = useUpsertProgramTemplateMutation();
    const classes = useStyles();
    const {t} = useTranslation();
    const sortedSteps = _.sortBy(program?.steps || [], (item) => program?.stepsOrder.map(Number).indexOf(item.id));
    const dispatch = useDispatch();
    const careProviders = useSelector((state) => state.careProviderReducer.careProviders);
    const selectedCareProviders = careProviders.filter((c) => selectedCareProvidersIds.includes(c.id));
    const updateTemplatesAndSelectedCareProviders = () => {
        dispatch(getCareProvidersProgramTemplates()).then((response) => {
            const careProvidersTemplates = response?.payload?.data ?? [];
            setCareProvidersTemplates(careProvidersTemplates);
            setSelectedCareProvidersIds(
                    careProvidersTemplates
                            .filter((template) => template.parentTemplateId === program.id)
                            .map((template) => template.careProviderId)
            );
        });
    };

    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        updateTemplatesAndSelectedCareProviders();
    }, []);

    const isDraggable = editableId === null;

    const onAssignment = (updatedSelectionOfCareProviderIds) => {
        const noLongerSelectedCareProviderIds = selectedCareProvidersIds.filter(
                (c) => !updatedSelectionOfCareProviderIds.includes(c)
        );
        const templatesToDelete = careProvidersTemplates
                .filter((template) => template.parentTemplateId === program.id)
                .filter((template) => noLongerSelectedCareProviderIds.includes(template.careProviderId))
                .map((template) => template.id);
        const stepsToCreate = sortSteps(program.steps, program.stepsOrder)
                .filter((step) => !step.paused);

        const newTemplates = updatedSelectionOfCareProviderIds
                .filter((careProviderId) => !selectedCareProvidersIds.includes(careProviderId))
                .map(mapProgramTemplateByCareProvider(program, stepsToCreate));

        const saveTemplatesPromise = dispatch(saveProgramTemplates(newTemplates));
        const deleteTemplatesPromise = dispatch(deleteProgramTemplates(templatesToDelete));
        Promise.all([deleteTemplatesPromise, saveTemplatesPromise]).then(() => {
            updateTemplatesAndSelectedCareProviders();
        });
        setIsAssignmentModalOpen(false);
    };

    const copyProgramTemplate = (program, programNameSuffix = "(copy)") => {
        const _sortedSteps = sortSteps(program.steps, program.stepsOrder);
        return {
            ...program,
            name: program.name + " " + programNameSuffix,
            id: undefined,
            steps: _sortedSteps.map(omit(["id"])),
            stepsOrder: [],
            stepDependencyPositionalRelations: _sortedSteps.reduce(positionalRelationShipReducer, []),
        };
    };

    const copyProgramTemplateHandler = (program, programNameSuffix = "(copy)") => {
        upsertProgramTemplate({
            programTemplate: copyProgramTemplate(program, programNameSuffix),
        }).unwrap().then(() => {
            enqueueSnackbar(t('global.data.saved.success'), {
                variant: "success",
                anchorOrigin: {'horizontal': 'center', vertical: 'bottom'},
                autoHideDuration: 1500
            });
        });
    };

    return (
            <ThemeProvider theme={patientTheme}>
                <Flex item container column>
                    <Title user={user} title={t("global.edit-program-template")} onBack={reset} style={style}/>
                    <Flex item container column padding={15} style={style}>
                        <Box display={"flex"} flexDirection={"row"} style={{gap: "40px"}}>
                            <Box flex={"0 1 100%"}>
                                <div data-testid="program-editor-program-data-fields" style={{width: "100%"}}>
                                    <ShrinkTextField
                                            label={t("global.program-name")}
                                            value={program?.name || ""}
                                            style={{minWidth: "100%", paddingRight: "48px"}}
                                            name={"name"}
                                            onChange={programChange}
                                    />
                                    <ShrinkTextField
                                            label={t("global.tag")}
                                            value={program?.shortName || ""}
                                            style={{minWidth: "100%", paddingRight: "48px"}}
                                            name={"shortName"}
                                            onChange={programChange}
                                    />
                                </div>

                                <Flex item grow={0} container style={{width: "50%", marginTop: 20}}>
                                    <Typography data-testid="program-editor-program-template-name" variant={"h3"}>{program?.name}</Typography>
                                </Flex>
                                <Flex item grow={0} container style={{marginTop: 10}}>
                                    <Typography>{t('program.base-template-warning')}</Typography>
                                </Flex>

                                <Flex
                                        item
                                        container
                                        justifyContent={"space-between"}
                                        alignItems={"center"}
                                        style={{marginTop: 20}}
                                >
                                    <Typography variant={"h6"} style={{fontWeight: "bold"}}>
                                        {t("global.necessary-steps")}
                                    </Typography>
                                </Flex>
                                <Box display={"flex"} flexDirection={"column"} flex={"1 0 auto"}>
                                    <Steps
                                            {...{
                                                classes,
                                                reorderSteps,
                                                sortedSteps,
                                                isDraggable,
                                                deleteStep,
                                                editableId,
                                                user,
                                                setEditableId: setStepInEditableMode,
                                                saveStep,
                                                addStep,
                                            }}
                                    />
                                </Box>
                            </Box>
                            <Box style={{width: isCaseManager(user) ? "24%" : ""}}>
                                <Box
                                        display={"flex"}
                                        flex={"1 1 auto"}
                                        flexDirection={"column"}
                                        paddingRight={"20px"}
                                        marginTop={"10px"}
                                        marginBottom={"30px"}
                                >
                                    <Box display={"flex"} style={{gap: "10px"}} justifyContent={"flex-end"}>
                                        {isManager(user) && !program.suspendedParticipation && (
                                                <HmoBorderedButton onClick={toggleDeleteDialog}>
                                                    {t("global.delete")}
                                                </HmoBorderedButton>
                                        )}
                                        {isManager(user) && program.suspendedParticipation && (
                                                <HmoBorderedButton style={{marginLeft: 15}} onClick={onEnrollBack}>
                                                    Enroll back
                                                </HmoBorderedButton>
                                        )}

                                        {isManager(user) && <HmoBorderedButton
                                                onClick={() => copyProgramTemplateHandler(program, "(" + t("global.copy") + ")")}
                                        >
                                            {t("global.toCopy")}
                                        </HmoBorderedButton>}
                                    </Box>
                                    <Box display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
                                    <span style={{fontWeight: program.suspended ? "bold" : "initial"}}>
                                        {t("global.stopped")}
                                    </span>{" "}
                                        <Switch
                                                name="suspended"
                                                onChange={toggleProgramSuspendedStatus}
                                                checked={!program.suspended}
                                                color="primary"
                                        />{" "}
                                        <span style={{fontWeight: !program.suspended ? "bold" : "initial"}}>
                                        {t("global.active")}
                                    </span>
                                    </Box>
                                </Box>
                                {isCaseManager(user) && (
                                        <CareProviderAssignmentSidebar
                                                {...{
                                                    t,
                                                    classes,
                                                    program,
                                                    careProviders,
                                                    selectedCareProvidersIds,
                                                    dispatch,
                                                    save,
                                                    programSet,
                                                    setIsAssignmentModalOpen,
                                                    selectedCareProviders,
                                                    updateTemplatesAndSelectedCareProviders,
                                                }}
                                        />
                                )}
                            </Box>
                        </Box>
                    </Flex>
                </Flex>
                <AssignmentModel
                        selectedCareProvidersIds={selectedCareProvidersIds}
                        programName={program.name}
                        isOpen={isAssignmentModalOpen}
                        onClose={() => {
                            setIsAssignmentModalOpen(false);
                        }}
                        save={onAssignment}
                />
            </ThemeProvider>
    );
};

export default ProgramTemplateEditor;
