import React, {Component} from 'react';
import {
    withStyles,
    Typography
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {
    iceberg,
    htmlWhite,
} from 'components/colors/Colors';
import {withTranslation} from 'react-i18next';
import HmoInputLabel from 'components/input-label/HmoInputLabel';
import HmoTextField from 'components/hmo-textfield/HmoTextField';

const styles = theme => ({
    title: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: 'black',
        fontSize: 15
    },
    inputRoot: {
        backgroundColor: htmlWhite,
        borderRadius: 4,
        paddingLeft: 7
    },
});

export class Tnm extends Component {

    render() {
        const {classes, t: translate, disabled, handleCaseChange} = this.props;
        const {affected, examined, content} = this.props._case;

        return (
            <Flex item container>
                <Flex item container column style={{margin: 10, backgroundColor: iceberg, padding: 15}}>
                    <Typography className={classes.title}>
                        {translate(`case.tnm-title`)}
                    </Typography>
                    <HmoTextField
                        value={content || ''}
                        multiline
                        variant={'outlined'}
                        rows={5}
                        onChange={handleCaseChange}
                        fullWidth
                        disabled={disabled}
                        name={'content'}
                        InputProps={{
                            style: {
                                padding: 5
                            }
                        }}
                        style={{
                            backgroundColor: htmlWhite,
                        }}
                    />
                </Flex>
                <Flex item container column style={{margin: 10, backgroundColor: iceberg, padding: 15}}>
                    <Typography className={classes.title}>
                        {translate('case.lymph-nodes')}
                    </Typography>
                    <Flex item container column>
                        <HmoInputLabel>{translate('case.affected')}</HmoInputLabel>
                        <HmoTextField
                            value={affected || ''}
                            onChange={handleCaseChange}
                            fullWidth
                            disabled={disabled}
                            name={'affected'}
                        />
                        <HmoInputLabel style={{marginTop: 10}}>{translate('case.examined')}</HmoInputLabel>
                        <HmoTextField
                            value={examined || ''}
                            onChange={handleCaseChange}
                            fullWidth
                            disabled={disabled}
                            name={'examined'}
                        />
                    </Flex>
                </Flex>
            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(Tnm));
