import {listClinics, upsertClinic, setActivityTab} from 'scenes/clinics/Clinics.action';

export default {
    mapStateToProps: (state) => {
        return {
            clinicCertifications: state.caseReducer.configuration.clinicCertifications || [],
            indications: state.caseReducer.configuration.indications || [],
            subIndications: (state.caseReducer.configuration.subIndications || [])
                .map(si => si.subIndication),
            clinics: state.clinicsReducer.clinics,
            activityTab: state.clinicsReducer.activityTab
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            listClinics: () => dispatch(listClinics()),
            upsertClinic: clinic => dispatch(upsertClinic(clinic)),
            setActivityTab: activityTab => dispatch(setActivityTab(activityTab)),
        };
    }
};
