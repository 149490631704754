import {
    TextField,
} from "@material-ui/core";
import Flex from "components/grid/Flex";
import HmoInputLabel from "components/input-label/HmoInputLabel";
import { useTranslation } from "react-i18next";

export const DateTextField = ({ ...other }) => {
    const { t } = useTranslation();
    return <TextField InputLabelProps={{ shrink: true }} label={t("case.date")} {...other} />;
};

export const LabeledValue = (props) => {
    const { label, value, boldValue } = props;
    return (
        <Flex item container column>
            <HmoInputLabel small>{label}</HmoInputLabel>
            <span style={{fontWeight: boldValue ? 'bold' : ''}}>
                {value || "-"}
            </span>
        </Flex>
    );
};

export const Field = (props) => {
    const { label, value, name, onChange, isEditable, color, boldValue, ...other } = props;
    return isEditable ? (
        <TextField
            label={label}
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={value || ""}
            name={name}
            onChange={onChange}
            {...other}
        />
    ) : (
        <LabeledValue label={label} value={value} boldValue={boldValue} />
    );
};
