import React, {Component} from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import Flex from 'components/grid/Flex';
import './quillEditor.css'
const Block = Quill.import('blots/block');
const BlockEmbed = Quill.import('blots/block/embed');
class MyBlock extends Block {}
class MyBlockEmbed extends BlockEmbed {}

MyBlock.tagName = 'div';
MyBlockEmbed.tagName = 'div';

Quill.register('blots/block', MyBlock, true);
Quill.register('blots/block/embed', MyBlockEmbed, true);
/**
 * https://quilljs.com/docs/api/
 */
export class QuillEditor extends Component {
    editorRef = React.createRef();
    outerContainerRef = React.createRef();
    editor = null;
    htmlToCommit = null;
    options = {
        theme: 'snow',
        modules: {
            toolbar: {
                container: [
                    ['bold', 'italic', 'underline', 'strike'],
                    [{'list': 'ordered'}, {'list': 'bullet'}],
                ]
            },
        },
        formats:
                [
                    'align', 'background', 'header', 'font', 'size', 'align', 'bold', 'italic', 'underline', 'strike',
                    'script', 'list', 'bullet', 'indent', 'link', 'color', 'clean', 'image', 'blockquote', 'code-block'
                ],
    };

    componentDidMount() {
        this.initializeQuillJs();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.updateId !== prevProps.updateId) {
            this.editor.root.innerHTML = this.props.text;
            this.editor.update();
        }
    }

    initializeQuillJs = () => {
        const options = {
            ...this.options,
            modules: {
                toolbar: this.props.toolbar || this.options.modules.toolbar,
            }
        };
        this.editor = new Quill(this.editorRef.current, options);
        this.editor.root.innerHTML = this.props.text;
        this.editor.update();
        this.editor.on('text-change', this.onChange);
        this.props.customInitializer && this.props.customInitializer(this.editor, this.outerContainerRef);
    };

    onChange = (value, delta, source) => {
        if (source === 'api') {
            return;
        }
        this.htmlToCommit = this.editor.root.innerHTML.replaceAll('<p>', '<div>').replaceAll('</p>', '</div>');
        if (this.onChangeTimeout) {
            clearTimeout(this.onChangeTimeout);
            this.onChangeTimeout = null;
        }
        this.onChangeTimeout = setTimeout(() => this.commitText(), 300);
    };

    commitText() {
        clearTimeout(this.onChangeTimeout);
        this.onChangeTimeout = null;
        this.props.handleTextChange(this.htmlToCommit);
    }

    onContainerClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        this.editor.focus();
    };

    render() {
        return (
                <Flex container item direction={'column'} style={this.props.containerStyle} divElementRef={this.outerContainerRef}>
                    <div ref={this.editorRef} style={{flex: '1 1 auto'}} onClick={this.onContainerClick}/>
                </Flex>
        );
    }
}

export default QuillEditor;
