import React, {Component} from 'react';
import {withStyles, IconButton, Typography, Select, MenuItem, ListItemText, Input, Slider} from "@material-ui/core";
import {persianGreen, htmlWhite, jaffa, concrete, grey, htmlGrey, doveGrey} from 'components/colors/Colors';
import Flex from 'components/grid/Flex';
import GoogleMapReact from 'google-map-react';
import configs from "configs/Configs";
import _ from 'lodash';
import MapIcon from '@material-ui/icons/Map';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import Tag from 'components/tags/Tag';
import update from 'immutability-helper';

const styles = theme => ({});

function toRadians(number) {
    return number * Math.PI / 180;
}

function distanceWithPythagoras(from, to) {
    const fromLatitude = from.latitude || from.lat;
    const toLatitude = to.latitude || to.lat;
    const fromLongitude = from.longitude || from.lng;
    const toLongitude = to.longitude || to.lng;
    const radiusOfEarth = 6371000;

    const φ1 = toRadians(fromLatitude);
    const φ2 = toRadians(toLatitude);
    const Δλ = toRadians(toLongitude - fromLongitude);
    return Math.acos(Math.sin(φ1) * Math.sin(φ2) + Math.cos(φ1) * Math.cos(φ2) * Math.cos(Δλ)) * radiusOfEarth;
}

export class PersonMarker extends Component {
    render() {
        return (
            <div
                style={{
                    position: 'absolute',
                    padding: 0,
                    top: -20,
                    left: -15
                }}
            >
                <PersonPinCircleIcon style={{width: 30, height: 30, color: doveGrey}}/>
            </div>
        )
    }
}

export class Marker extends Component {

    state = {
        isHovered: false
    };

    toggleHoverOff = () => {
        this.setState({isHovered: false});
    };

    toggleHoverOn = () => {
        this.setState({isHovered: true});
    };

    render() {
        const {clinicCenter, zoom, handleMarkerSelect, isSelected, currentDistance} = this.props;
        const {isHovered} = this.state;
        return (
            <div
                style={{position: 'absolute', zIndex: isSelected || isHovered ? 2000 : 'unset'}}
                onClick={handleMarkerSelect}
                onMouseEnter={this.toggleHoverOn}
                onMouseLeave={this.toggleHoverOff}
            >
                <div
                    style={{
                        display: isSelected || isHovered ? 'block' : 'none',
                        padding: 4,
                        border: '1px solid black',
                        boxShadow: `1px 2px 2px ${grey}`,
                        backgroundColor: concrete,
                        width: 200,
                        color: jaffa
                    }}
                >{
                    `${clinicCenter.name}
                            ${_.get(clinicCenter, 'manager')}, (${currentDistance ? currentDistance : clinicCenter.distance} ${currentDistance ? 'on route' : 'km in air'})
                            `
                }</div>
                <IconButton style={{
                    padding: 0,
                    position: 'absolute',
                    width: 50 * (zoom / 10),
                    height: 50 * (zoom / 10),
                    top: -(60 * (zoom / 10)),
                    left: -(50 / 2 * (zoom / 10))
                }}>
                    <div style={{
                        width: 50 * (zoom / 10),
                        height: 50 * (zoom / 10),
                        borderRadius: '50% 50% 50% 0',
                        background: isSelected ? jaffa : persianGreen,
                        transform: 'rotate(-45deg)',
                    }}>
                        <div style={{
                            width: 30 * (zoom / 10),
                            height: 30 * (zoom / 10),
                            borderRadius: '50%',
                            margin: `${10 * (zoom / 10)}px 0 0 ${10 * (zoom / 10)}px`,
                            backgroundColor: htmlWhite,
                            color: isSelected ? jaffa : persianGreen,
                            transform: 'rotate(45deg)',
                            fontWeight: 'bold',
                            fontSize: `${
                                24 * (zoom / 10)
                            }px`,
                            paddingTop: 2
                        }}>
                            {clinicCenter.latestQualityRatingAggregate || '-'}
                        </div>
                    </div>
                </IconButton>
            </div>
        )
    }
}

const MapHeader = props => {
    const {translate, clinicCenter, selectedClinicCenter, toggleMap, saveClinicCenter} = props;
    return (
        <Flex item container style={{padding: 7, marginBottom: 5, borderBottom: `1px solid ${grey}`}}
              alignItems={'center'} justifyContent={'space-between'}>
            <Flex item container alignItems={'center'}>
                <MapIcon/>
                <Typography variant={'h5'}
                            style={{
                                marginLeft: 10,
                                color: htmlGrey
                            }}>{translate('case.assign-clinic')}</Typography>
            </Flex>
            {
                !_.isEmpty(selectedClinicCenter) && _.get(clinicCenter,'name') !== saveClinicCenter.name &&
                <Flex item container justifyContent={'flex-end'}>
                    <IconButton onClick={toggleMap}>
                        <CloseIcon/>
                    </IconButton>
                    <IconButton onClick={saveClinicCenter}>
                        <CheckIcon/>
                    </IconButton>
                </Flex>
            }
        </Flex>
    );
};

const Filters = props => {
    const {
        translate, distanceRelation, handleDistanceRelationChange, distanceFilterValue, handleDistanceFilterValueChange,
        ratingFilterValue, handleRatingSliderChange, classes, handleRatingInputChange, certifications,
        selectedCertifications, toggleCertification, subIndications, selectedSubIndications, toggleSubIndication
    } = props;
    return (
        <Flex item container direction={'column'} style={{maxWidth: 800}}>
            <Flex item container justifyContent={'space-between'} alignItems={'center'} padding={4}>
                <Flex item container alignItems={'center'}>
                    <Typography style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        marginRight: 10,
                        color: htmlGrey
                    }}>{translate('case.distance')}</Typography>
                    <Select
                        value={distanceRelation}
                        onChange={handleDistanceRelationChange}
                        name={'distanceRelation'}
                        renderValue={value => value}
                    >
                        <MenuItem key={'>'} value={'>'}>
                            <ListItemText primary={'>'}/>
                        </MenuItem>
                        <MenuItem key={'<'} value={'<'}>
                            <ListItemText primary={'<'}/>
                        </MenuItem>
                    </Select>
                    <Input value={distanceFilterValue} onChange={handleDistanceFilterValueChange}
                           style={{maxWidth: 50}}/>
                    <Typography>km</Typography>
                </Flex>
                <Flex item container alignItems={'center'}>
                    <Typography style={{
                        fontSize: 15,
                        whiteSpace: 'nowrap',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        marginRight: 10,
                        color: htmlGrey
                    }}>{translate('case.rating-filter')}</Typography>
                    <Slider
                        value={ratingFilterValue}
                        min={1}
                        max={5}
                        step={0.1}
                        onChange={handleRatingSliderChange}
                        aria-labelledby="input-slider"
                    />
                    <Input
                        className={classes.input}
                        value={ratingFilterValue}
                        onChange={handleRatingInputChange}
                        inputProps={{
                            style: {marginLeft: 5, minWidth: 40},
                            step: 0.1,
                            min: 1,
                            max: 5,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                        }}
                    />
                </Flex>
            </Flex>
            <Flex item container direction={'column'} padding={4}>
                <Typography style={{
                    fontSize: 15,
                    whiteSpace: 'nowrap',
                    width: '100%',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    marginRight: 10,
                    color: htmlGrey
                }}>{translate('case.certification-filter')}</Typography>
                <Flex item container style={{flexWrap: 'wrap'}}>
                    {
                        certifications.map((certification, index) => (
                            <Tag {...{
                                key: index,
                                selected: selectedCertifications.includes(certification),
                                tag: certification,
                                toggleSelection: toggleCertification
                            }}/>
                        ))
                    }
                </Flex>
            </Flex>
            <Flex item container direction={'column'} padding={4}>
                <Typography style={{
                    fontSize: 15,
                    whiteSpace: 'nowrap',
                    width: '100%',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    marginRight: 10,
                    color: htmlGrey
                }}>{translate('case.indication-filter')}</Typography>
                <Flex item container style={{flexWrap: 'wrap'}}>
                    {
                        subIndications.map((subIndication, index) => (
                            <Tag {...{
                                key: index,
                                selected: selectedSubIndications.includes(subIndication),
                                tag: subIndication,
                                toggleSelection: toggleSubIndication
                            }}/>
                        ))
                    }
                </Flex>
            </Flex>
        </Flex>
    );
};

export class Map extends Component {

    state = {
        zoom: undefined,
        isGoogleApiLoaded: false,
        clinicCenters: [],
        selectedClinicCenter: '',
        distanceRelation: '<',
        distanceFilterValue: 60,
        ratingFilterValue: 4,
        selectedCertifications: [],
        selectedSubIndications: [],
        googleApi: {},
        directionPath: undefined,
        currentDistance: '',
        address: ''
    };

    directionsRenderer = undefined;
    circleRenderer = undefined;

    componentDidMount() {
        this.props.fetchPatientCoordinates(this.props.patientBusinessId).then(response => {
            const address = response.payload.data;
            this.setState({
                address: address,
                selectedClinicCenter: this.props.clinicCenter,
                clinicCenters: this.props.clinicCenters.map(clinicCenter => ({
                    ...clinicCenter,
                    distance: (distanceWithPythagoras(address, {
                        latitude: clinicCenter.latitude,
                        longitude: clinicCenter.longitude
                    }) / 1000).toFixed(0)
                }))
            })
        })
    }

    handleGoogleApiLoad = ({map, maps, ...rest}) => {
        this.setState({
            googleApi: {map, maps, ...rest}
        });
        const center = {lat: this.state.address.latitude, lng: this.state.address.longitude};
        this.circleRenderer = new maps.Circle({
            strokeColor: 'blue',
            strokeOpacity: 0.3,
            strokeWidth: 1,
            fillOpacity: 0,
            map,
            center,
            radius: this.state.distanceFilterValue * 1000,
        });
    };

    selectClinicCenter = clinicCenter => {
        const maps = this.state.googleApi.maps;
        const directionsService = new maps.DirectionsService();
        const that = this;

        directionsService.route(
            {
                origin: {lat: this.state.address.latitude, lng: this.state.address.longitude},
                destination: {lat: clinicCenter.latitude, lng: clinicCenter.longitude},
                travelMode: maps.TravelMode.DRIVING,
            },
            (result, status) => {
                if (status === maps.DirectionsStatus.OK) {
                    if (that.directionsRenderer) {
                        that.directionsRenderer.setDirections(result);
                    } else {
                        that.directionsRenderer = new maps.DirectionsRenderer({
                            map: this.state.googleApi.map,
                            directions: result
                        });
                    }

                    this.setState({
                        currentDistance: result.routes[0].legs[0].distance.text
                    });
                }
            }
        );
        this.setState({
            selectedClinicCenter: clinicCenter
        })
    };

    saveClinicCenter = () => {
        this.props.handleClinicCenterChange(this.state.selectedClinicCenter);
    };

    handleChange = (googleApiProps) => {
        if (googleApiProps.zoom !== this.state.zoom) {
            this.setState({zoom: googleApiProps.zoom});
        }
    };

    handleDistanceRelationChange = event => {
        this.setState({
            distanceRelation: event.target.value
        })
    };

    handleDistanceFilterValueChange = event => {
        this.setState({
            distanceFilterValue: event.target.value
        }, () => {
            if (this.circleRenderer) {
                this.circleRenderer.setRadius(this.state.distanceFilterValue * 1000);
            }
        })
    };

    handleRatingSliderChange = (event, newValue) => {
        this.setState({
            ratingFilterValue: newValue
        })
    };

    handleRatingInputChange = event => {
        this.setState({
            ratingFilterValue: event.target.value
        })
    };

    relationEvaluator = () => {
        const that = this;
        return {
            '<': clinicCenter => {
                return Number(that.state.distanceFilterValue) > Number(clinicCenter.distance)
            },
            '>': clinicCenter => {
                return Number(that.state.distanceFilterValue) < Number(clinicCenter.distance)
            },
        }
    };

    distanceFilter = clinicCenter => {
        return this.relationEvaluator()[this.state.distanceRelation](clinicCenter);
    };

    subIndicationFilter = clinicCenter => {
        return this.state.selectedSubIndications.length === 0
            ? true
            : !_.isEmpty(
                _.intersection(
                    this.state.selectedSubIndications,
                    clinicCenter.subIndications
                )
            )
    };

    certificationFilter = clinicCenter => {
        return this.state.selectedCertifications.length === 0
            ? true
            : !_.isEmpty(
                _.intersection(
                    this.state.selectedCertifications,
                    clinicCenter.certifications
                )
            )
    };

    toggleCertification = certification => {
        const index = this.state.selectedCertifications.findIndex(cert => cert === certification);
        if (index === -1) {
            this.setState({
                selectedCertifications: update(this.state.selectedCertifications, {
                    $push: [certification]
                })
            })
        } else {
            this.setState({
                selectedCertifications: update(this.state.selectedCertifications, {
                    $splice: [[index, 1]]
                })
            })
        }
    };

    toggleSubIndication = subIndication => {
        const index = this.state.selectedSubIndications.findIndex(subInd => subInd === subIndication);
        if (index === -1) {
            this.setState({
                selectedSubIndications: update(this.state.selectedSubIndications, {
                    $push: [subIndication]
                })
            })
        } else {
            this.setState({
                selectedSubIndications: update(this.state.selectedSubIndications, {
                    $splice: [[index, 1]]
                })
            })
        }
    };

    render() {
        const {classes, translate,  clinicCenter, toggleMap, certifications, subIndications} = this.props;
        const {
            zoom, clinicCenters, selectedClinicCenter, distanceRelation, distanceFilterValue, ratingFilterValue,
            currentDistance, selectedCertifications, selectedSubIndications, address
        } = this.state;
        const {
            selectClinicCenter, saveClinicCenter, handleDistanceRelationChange, handleDistanceFilterValueChange,
            handleRatingSliderChange, handleRatingInputChange, distanceFilter, toggleCertification, certificationFilter,
            toggleSubIndication, subIndicationFilter, handleGoogleApiLoad, handleChange
        } = this;
        const center = address && {lat: address.latitude, lng: address.longitude};
        let filteredClinicCenters = clinicCenters
            .filter(distanceFilter)
            .filter(subIndicationFilter)
            .filter(certificationFilter);
        if (!_.isEmpty(selectedClinicCenter) && filteredClinicCenters
            .findIndex(clinicCenterInArray => clinicCenterInArray.name === selectedClinicCenter.name) === -1) {
            filteredClinicCenters.push(selectedClinicCenter);
        }
        return (
            <Flex item container direction={'column'} style={{padding: 15}}>
                <MapHeader {...{translate, clinicCenter, selectedClinicCenter, toggleMap, saveClinicCenter}} />
                <Filters {...{
                    translate, classes, distanceRelation, distanceFilterValue, ratingFilterValue, certifications,
                    selectedCertifications, subIndications, selectedSubIndications, handleDistanceRelationChange,
                    handleDistanceFilterValueChange, handleRatingSliderChange, handleRatingInputChange,
                    toggleCertification, toggleSubIndication
                }}/>
                <Flex item container style={{width: 800, height: 600}}>
                    {
                        center &&
                        <GoogleMapReact
                            bootstrapURLKeys={{key: configs.getGoogleMapsApiKey()}}
                            defaultCenter={center || configs.getGoogleMapsInitialCenterConfig().coordinates}
                            defaultZoom={configs.getGoogleMapsInitialCenterConfig().zoom}
                            onChange={handleChange}
                            yesIWantToUseGoogleMapApiInternals={true}
                            onGoogleApiLoaded={handleGoogleApiLoad}
                        >
                            {/*NOTE LM: Cannot use the variable "center" here, only props. If I do, the marker moves with the map...*/}
                            {/*Its possible Google somehow overrides the word itself or somehow kills the react lifecycle*/}
                            {
                                !currentDistance &&
                                <PersonMarker lat={address.latitude}
                                              lng={address.longitude}/>
                            }

                            {
                                filteredClinicCenters.map((clinicCenterInArray, index) => (
                                    zoom && <Marker
                                        clinicCenter={clinicCenterInArray}
                                        address={address}
                                        lat={clinicCenterInArray.latitude}
                                        lng={clinicCenterInArray.longitude}
                                        key={index + clinicCenterInArray.name}
                                        currentDistance={currentDistance}
                                        zoom={zoom}
                                        handleMarkerSelect={() => selectClinicCenter(clinicCenterInArray)}
                                        isSelected={_.get(selectedClinicCenter, 'name') === clinicCenterInArray.name}
                                    />
                                ))
                            }
                        </GoogleMapReact>
                    }
                </Flex>
            </Flex>
        );
    }
}

export default withStyles(styles)(Map);
