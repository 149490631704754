import React, {Component} from 'react';
import DefaultTextField from 'components/hmo-textfield/DefaultTextField';

export class SidebarInput extends Component {
    render() {
        const {translate, value, onChange, name, disabled, inputLabel, style, ...other} = this.props;

        return (
            <DefaultTextField
                value={value || ''}
                name={name}
                label={translate(inputLabel)}
                onChange={onChange}
                fullWidth
                disabled={disabled}
                {...other}
            />
        )
    }
}

export default SidebarInput;
