import React, {Component} from 'react';
import Flex from 'components/grid/Flex';
import { htmlWhite} from 'components/colors/Colors';
import HmoAvatar from 'components/avatar/HmoAvatar';
import moment from 'moment';

export class CaseManagerActivity extends Component {

    intervalId = null;

    componentDidMount() {
        this.intervalId = setInterval(() => {
            this.props.fetchCaseManagers();
        }, 1000 * 60 * 5);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
        this.intervalId = null;
    }

    render() {
        const {caseManagers = []} = this.props;

        return (
            <Flex container item={'1 0 auto'} justifyContent={'flex-end'}
                  style={{flexWrap: 'nowrap'}}>
                {
                    caseManagers.map((caseManager, index) => (
                        <Flex item={'0 0 auto'} key={index} style={{position: 'relative', marginLeft: -7}}>
                            <HmoAvatar
                                {...{user: caseManager}}
                                style={{
                                    margin: 0,
                                    border: caseManager.lastActivity
                                        ? moment.utc(caseManager.lastActivity).add(1, 'hour').isSameOrAfter(moment())
                                            ? `3px solid #4dd964`
                                            : '3px solid orange'
                                        : `3px solid ${htmlWhite}`,
                                    zIndex: caseManagers.length - index,
                                    width: 36,
                                    height: 36
                                }}
                            />
                        </Flex>
                    ))
                }
            </Flex>
        );
    }
}

export default CaseManagerActivity;
