export const MedicineForm = (lang) => [
    { value: 'tablet', label: lang('medicationUnit.tablet') },
    { value: 'capsule', label: lang('medicationUnit.capsule') },
    { value: 'syrup', label: lang('medicationUnit.syrup') },
    { value: 'syringe', label: lang('medicationUnit.syringe') },
    { value: 'drops', label: lang('medicationUnit.drops') },
    { value: 'ampoule', label: lang('medicationUnit.ampoule') },
    { value: 'ointment', label: lang('medicationUnit.ointment') },
    { value: 'plaster', label: lang('medicationUnit.plaster') },
    { value: 'bandage', label: lang('medicationUnit.bandage') },
    { value: 'other', label: lang('medicationUnit.other') },
]
