import React, {Component, Fragment} from 'react';
import {
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    Step,
    StepLabel,
    Stepper,
    Typography,
    withStyles
} from "@material-ui/core";
import {htmlGreen, htmlRed, transparentBlack054} from 'components/colors/Colors';
import HmoTextField from 'components/hmo-textfield/HmoTextField';
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from '@material-ui/icons/Visibility';
import CircularProgress from '@material-ui/core/CircularProgress';
import PasswordChecklist from "react-password-checklist"

const styles = theme => ({});

export class ResetPassword extends Component {

    passwordInputRef = React.createRef();

    state = {
        newPassword: '',
        newPasswordRepeat: '',
        isMatchingPasswords: true,
        isValidPassword: true,
        isNewPasswordBeingSaved: false,
        isNewPasswordSaved: false,
        errorDuringSave: false,
        isPasswordVisible: false,
    };

    handleStateChange = event => {
        const target = event.target;
        this.setState({
            [target.name]: target.value,
        }, () => {
            this.setState({
                isMatchingPasswords: this.state.newPassword === this.state.newPasswordRepeat,
                isValidPassword: this.state.newPassword.length > 7
            });
        })
    };

    handleSubmit = () => {
        if (this.state.isValidPassword && this.state.isMatchingPasswords) {
            this.setState({
                isNewPasswordBeingSaved: true,
                isNewPasswordSaved: false
            }, () => this.props.saveNewPassword(this.state.newPassword, this.props.token)
                .then(() => {
                    this.setState({
                        isNewPasswordBeingSaved: false,
                        isNewPasswordSaved: true
                    });
                })
                .catch(() => {
                    this.setState({
                        isNewPasswordBeingSaved: false,
                        isNewPasswordSaved: true,
                        errorDuringSave: true
                    });
                }));
        }
    };

    togglePasswordVisibility = () => {
        this.setState(state => ({isPasswordVisible: !state.isPasswordVisible}));
    };

    onEnter = event => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.passwordInputRef.current.focus();
        }
    };

    validatePassword = isValid => {
        this.setState({isValidPassword: isValid});
    }

    render() {
        const {classes, t: translate, changeView} = this.props;
        const {
            handleStateChange, handleSubmit, togglePasswordVisibility, passwordInputRef, onEnter, validatePassword
        } = this;
        const {
            newPassword, newPasswordRepeat, isMatchingPasswords, isValidPassword, isNewPasswordBeingSaved,
            isNewPasswordSaved, errorDuringSave, isPasswordVisible
        } = this.state;
        return (
            <form style={{width: '100%', maxWidth: '450px'}}>
                <Stepper activeStep={!isNewPasswordSaved ? 1 : 2}>
                    <Step completed={true}>
                        <StepLabel/>
                    </Step>
                    {
                        changeView &&
                        <Step completed={isNewPasswordSaved}>
                            <StepLabel/>
                        </Step>
                    }
                    <Step completed={isNewPasswordSaved && !errorDuringSave}>
                        <StepLabel/>
                    </Step>
                </Stepper>
                <Typography variant={'h4'}>{translate('login.reset-password-new')}</Typography>
                {
                    !isNewPasswordSaved &&
                    <Fragment>
                        <Typography style={{color: transparentBlack054, marginBottom: 30}}>
                            {translate('login.reset-password-new-text')}
                        </Typography>
                        <FormControl margin="normal" required fullWidth>
                            <HmoTextField
                                name={'newPassword'}
                                label={translate('login.reset-password-new-request')}
                                error={!isValidPassword}
                                type={isPasswordVisible ? 'text' : 'password'}
                                value={newPassword}
                                onChange={handleStateChange}
                                onEnterKey={onEnter}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={togglePasswordVisibility}
                                            >
                                                {isPasswordVisible ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>)
                                }}
                            />
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <HmoTextField
                                name={'newPasswordRepeat'}
                                label={translate('login.reset-password-new-repeat')}
                                helperText={!newPasswordRepeat || isMatchingPasswords ? '' : translate("login.reset-password-new-not-same")}
                                error={!!newPasswordRepeat && !isMatchingPasswords}
                                inputRef={passwordInputRef}
                                type={isPasswordVisible ? 'text' : 'password'}
                                value={newPasswordRepeat}
                                onChange={handleStateChange}
                                onEnterKey={handleSubmit}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={togglePasswordVisibility}
                                            >
                                                {isPasswordVisible ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>)
                                }}
                            />
                        </FormControl>
                        <PasswordChecklist
                            rules={["minLength","specialChar","number","capital","match"]}
                            minLength={10}
                            value={newPassword}
                            valueAgain={newPasswordRepeat}
                            onChange={validatePassword}
                            messages={{
                                minLength: translate('global.minLength'),
                                specialChar: translate('global.specialChar'),
                                number: translate('global.password-number'),
                                capital: translate('global.capital'),
                                match: translate('global.match'),
                            }}
                        />
                    </Fragment>
                }
                {
                    isNewPasswordSaved &&
                    <Typography style={{color: errorDuringSave ? htmlRed : htmlGreen, marginBottom: 30, marginTop: 30}}>
                        {
                            errorDuringSave
                                ? translate('login.reset-password-new-save-fail')
                                : translate('login.reset-password-new-save-success')
                        }
                    </Typography>
                }
                <Flex item container style={{marginTop: 20}}>
                    {
                        isNewPasswordSaved &&
                            <Button
                                fullWidth
                                variant="contained"
                                data-testid="reset-password-go-to-login-on-success-btn"
                                color="primary"
                                onClick={() => changeView('LOGIN')}
                            >
                                {translate('login.reset-password-new-done')}
                            </Button>
                    }
                    {
                        !isNewPasswordSaved &&
                        <Fragment>
                            {
                                changeView &&
                                <Button
                                    style={{
                                        width: '40%',
                                        marginRight: 10
                                    }}
                                    variant="contained"
                                    color="default"
                                    onClick={() => changeView('LOGIN')}
                                >
                                    {translate('global.cancel')}
                                </Button>
                            }
                            <Button
                                fullWidth
                                data-testid="forgot-password-submit-new"
                                variant="contained"
                                color="primary"
                                disabled={!isValidPassword}
                                className={classes.submit}
                                onClick={handleSubmit}
                            >
                                {translate('login.reset-password-new-save')}
                            </Button>
                        </Fragment>
                    }
                </Flex>
                {
                    isNewPasswordBeingSaved &&
                    <Flex item container style={{marginTop: 20}} justifyContent={'center'} alignItems={'center'}>
                        <CircularProgress/>
                    </Flex>
                }
            </form>
        );
    }
}

export default withStyles(styles)(withTranslation()(ResetPassword));
