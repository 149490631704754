import * as actionTypes from './EmailManager.action.types';

export function sendEmails(mailTypes, emailAddress, personType, userDetailsBusinessId, caseBusinessId) {
    return {
        type: actionTypes.SEND_PATIENT_EMAILS,
        payload: {
            client: 'api',
            request: {
                method: 'post',
                url: '/patient/send-emails',
                data: {
                    caseBusinessId,
                    userDetailsBusinessId,
                    mailTypes,
                    personType,
                    emailAddress
                }
            }
        }
    }
}

export function printEmails(mailTypes, emailAddress, personType, caseBusinessId, userDetailsBusinessId) {
    return {
        type: actionTypes.PRINT_PATIENT_EMAILS,
        payload: {
            client: 'api',
            request: {
                method: 'post',
                responseType: 'arraybuffer',
                url: '/patient/print-emails',
                data: {
                    caseBusinessId,
                    userDetailsBusinessId,
                    mailTypes,
                    personType,
                    emailAddress
                }
            }
        }
    }
}
