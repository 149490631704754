import HMOApi, {CareProviders} from "store/api/HMOApi";

export const PatientApi = HMOApi.injectEndpoints({
    endpoints: (build) => ({
        getCareProviders: build.query({
            query: () => ({
                url: `care-provider/all`,
                method: "GET",
            }),
            providesTags: [CareProviders],
        }),
        getCareProviderPatientAccesses: build.query({
            query: ({ patientId, careProviderId }) => ({
                url: `care-provider/patient-accesses/${patientId}/care-provider/${careProviderId}`,
                method: "GET",
            }),
        }),
        getCareProviderUserDetails: build.query({
            query: ({ careProviderId }) => ({
                url: `user-details/care-provider/${careProviderId}`,
                method: "GET",
            }),
        }),
    }),
});

export const {
    useGetCareProvidersQuery,
    useLazyGetCareProviderPatientAccessesQuery,
    useGetCareProviderPatientAccessesQuery,
    useGetCareProviderUserDetailsQuery,
} = PatientApi;
