import React, { useCallback } from "react";
import BooleQuestion from "../input/BooleQuestion";
import FormSection from "../FormSection";
import { isPropertySelected } from "../config/ValidationSchema";
import { Grid, MenuItem, Select, Box, TextField, InputAdornment, IconButton } from "@material-ui/core";
import Save from "@material-ui/icons/Save";
import AnamnesisSelectbox from "../input/AnamnesisSelectbox";
import BooleSubQuestion from "../input/BooleSubQuestion";
import DataListInputField from "../input/DataListInputField";
import { useTranslation } from "react-i18next";
import CustomYearPicker from "../input/CustomYearPicker";
import InputDivider from "../input/InputDivider";
import { PlaceholderOrValue } from "../input/FieldSelect";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSelector } from "react-redux";
import { useAddAutoimmuneDiseaseMutation } from "scenes/patient/anamnesis/AnamnesisApi";
import { fetchConfiguration } from "scenes/case/Case.action";
import { useDispatch } from "react-redux";
import { isManager } from "domain/User.model";
import { matchSorter } from "match-sorter";
import Question from "../../../../../components/question/Question";
import { QuestionWithFiltering } from "./QuestionWithFiltering";

const NeurologicalDisease = ({ form, lang, t, readonly }) => {
    return (
        <>
            <BooleQuestion
                disabled={readonly}
                form={form}
                fieldName="haveNeurologicalDisease"
                question={lang("haveNeurologicalDisease")}
            />
            {isPropertySelected(form, "haveNeurologicalDisease") && (
                <Box mb={2}>
                    <DataListInputField
                        disabled={readonly}
                        form={form}
                        fieldName="haveNeurologicalDiseaseData"
                        rowData={{ date: null, disease: "", treatment: "" }}
                    >
                        {({ onChange, item, DefaultDeleteIcon }) => (
                            <>
                                <Grid container key={item.id} spacing={1}>
                                    <Grid item xs={3}>
                                        <TextField
                                            disabled={readonly}
                                            fullWidth
                                            label={lang("whichDisease")}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            select
                                            SelectProps={{
                                                displayEmpty: true,
                                                value: item?.disease ?? null,
                                                onChange: (e) =>
                                                    onChange({
                                                        id: item.id,
                                                        field: "disease",
                                                        props: { value: e.target.value },
                                                    }),
                                                renderValue: PlaceholderOrValue(item?.disease, t),
                                            }}
                                        >
                                            <MenuItem value="PARKINSON">{lang("PARKINSON")}</MenuItem>
                                            <MenuItem value="MULTIPLE_SKLEROSE">{lang("MULTIPLE_SKLEROSE")}</MenuItem>
                                            <MenuItem value="EPILEPSIE">{lang("EPILEPSIE")}</MenuItem>
                                            <MenuItem value="MIGRÄNE">{lang("MIGRÄNE")}</MenuItem>
                                            <MenuItem value="POLYNEUROPATHIE">{lang("POLYNEUROPATHIE")}</MenuItem>
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <CustomYearPicker
                                            disabled={readonly}
                                            name="date"
                                            fullWidth
                                            type="date"
                                            label={lang("sinceWhen")}
                                            value={item?.date ?? ""}
                                            onChange={(e) => {
                                                onChange({
                                                    id: item.id,
                                                    field: "date",
                                                    props: { value: e.target.value },
                                                });
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            disabled={readonly}
                                            fullWidth
                                            label={lang("treatment")}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            select
                                            SelectProps={{
                                                displayEmpty: true,
                                                value: item?.treatment ?? null,
                                                onChange: (e) =>
                                                    onChange({
                                                        id: item.id,
                                                        field: "treatment",
                                                        props: { value: e.target.value },
                                                    }),
                                                renderValue: PlaceholderOrValue(item?.treatment, t),
                                            }}
                                        >
                                            <MenuItem value="CORTISON">{lang("CORTISON")}</MenuItem>
                                            <MenuItem value="ANTIKÖRPER">{lang("ANTIKÖRPER")}</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={2}>
                                        {!readonly && (
                                            <DefaultDeleteIcon
                                                style={{
                                                    marginTop: "10px",
                                                }}
                                                item={item}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </DataListInputField>
                </Box>
            )}
        </>
    );
};

const AutoimmuneDisease = ({ form, lang, readonly }) => {
    const { t, i18n } = useTranslation();
    const languageConditionalRender = (option) => {
        return i18n.exists("anamnesis.comorbidity." + option) ? lang(option) : option;
    };
    const autoimmuneDiseases = [
        ...useSelector((state) => state.caseReducer.configuration?.autoimmuneDiseases),
        "HASHIMOTO",
        "SCHUPPENFLECHTE",
        "NEURODERMITIS",
        "PSORIASIS",
        "DARMERKRANKUNGEN",
        "AUTOIMMUNGASTRITIS",
        "HAUTERKRANKUNGEN",
        "SKLERODERMIE",
        "LUPUS",
        "DIABETESTYPE1",
        "HAARAUSFALL",
    ].sort((a, b) => {
        return languageConditionalRender(a)?.toLowerCase() > languageConditionalRender(b)?.toLowerCase() ? 1 : -1;
    });

    const user = useSelector((state) => state.mainReducer.user);
    const [addAutoimmuneDisease] = useAddAutoimmuneDiseaseMutation();
    const dispatch = useDispatch();
    const checkAndAddAutoimmuneDisease = (disease) => {
        if (disease && disease?.length > 2 && !autoimmuneDiseases.includes(disease)) {
            addAutoimmuneDisease(disease)
                .unwrap()
                .then(() => dispatch(fetchConfiguration()));
        }
    };
    return (
        <>
            <BooleQuestion
                disabled={readonly}
                form={form}
                fieldName="haveAutoimmuneDisease"
                question={lang("haveAutoimmuneDisease")}
            />
            {isPropertySelected(form, "haveAutoimmuneDisease") && (
                <Box mb={2}>
                    <DataListInputField
                        disabled={readonly}
                        form={form}
                        fieldName="haveAutoimmuneDiseaseData"
                        rowData={{ date: null, disease: "", treatment: "" }}
                    >
                        {({ onChange, item, DefaultDeleteIcon }) => {
                            return (
                                <>
                                    <Grid container key={item.id} spacing={1}>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                disabled={readonly}
                                                freeSolo
                                                options={autoimmuneDiseases}
                                                getOptionLabel={languageConditionalRender}
                                                value={item?.disease || ""}
                                                filterOptions={(options, { inputValue }) =>
                                                    //NOTE LM: when the value is directly changed by onInputChange
                                                    // in freesolo the autocomplete stops filtering (it has no way to know
                                                    // the submitted state and inputValue is always empty). Thus, we have to supply
                                                    // filtering manually
                                                    matchSorter(options, item?.disease || "")
                                                }
                                                onChange={(event, value) => {
                                                    //NOTE LM: We want to keep the all uppercase default values, which are translated
                                                    // however when you choose such a value onINputChange would use its
                                                    // translated form. Thats why we have to sit on onChage as well.
                                                    // When you select boths runs, if onChange runs first we stop propagation
                                                    // if oninputchange runs first the debounce will take care of it.
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                    onChange({
                                                        id: item.id,
                                                        field: "disease",
                                                        props: { value: value || event.target.value },
                                                    });
                                                }}
                                                onInputChange={(event, value) => {
                                                    onChange({
                                                        id: item.id,
                                                        field: "disease",
                                                        props: { value: value || event.target.value },
                                                    });
                                                }}
                                                renderInput={(params) => {
                                                    return (
                                                        <TextField
                                                            {...params}
                                                            placeholder=""
                                                            InputLabelProps={{ shrink: true }}
                                                            label={lang("whichDisease")}
                                                            name="disease"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {item?.disease &&
                                                                            !autoimmuneDiseases.includes(
                                                                                item?.disease
                                                                            ) &&
                                                                            isManager(user) && (
                                                                                <InputAdornment position="start">
                                                                                    <IconButton
                                                                                        style={{ padding: 5 }}
                                                                                        onClick={() =>
                                                                                            checkAndAddAutoimmuneDisease(
                                                                                                item?.disease
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <Save
                                                                                            fontSize="small"
                                                                                            style={{ fill: "orange" }}
                                                                                        />
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            )}

                                                                        {params.InputProps.endAdornment}
                                                                    </>
                                                                ),
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={3}>
                                            <CustomYearPicker
                                                disabled={readonly}
                                                name="date"
                                                fullWidth
                                                type="date"
                                                label={lang("sinceWhen")}
                                                value={item?.date ?? ""}
                                                onChange={(e) => {
                                                    onChange({
                                                        id: item.id,
                                                        field: "date",
                                                        props: { value: e.target.value },
                                                    });
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={3}>
                                            <TextField
                                                disabled={readonly}
                                                fullWidth
                                                label={lang("treatment")}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                select
                                                SelectProps={{
                                                    displayEmpty: true,
                                                    value: item?.treatment ?? null,
                                                    onChange: (e) =>
                                                        onChange({
                                                            id: item.id,
                                                            field: "treatment",
                                                            props: { value: e.target.value },
                                                        }),
                                                    renderValue: PlaceholderOrValue(item?.treatment, t),
                                                }}
                                            >
                                                <MenuItem value="CORTISON">{lang("CORTISON")}</MenuItem>
                                                <MenuItem value="IBUPROFEN">{lang("IBUPROFEN")}</MenuItem>
                                                <MenuItem value="DICLOFENAC">{lang("DICLOFENAC")}</MenuItem>
                                                <MenuItem value="ANTIKÖRPERTHERAPIE">
                                                    {lang("ANTIKÖRPERTHERAPIE")}
                                                </MenuItem>
                                                <MenuItem value="CHEMOTHERAPIE_METHOTREXATE">
                                                    {lang("CHEMOTHERAPIE_METHOTREXATE")}
                                                </MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={2}>
                                            {!readonly && (
                                                <DefaultDeleteIcon
                                                    style={{
                                                        marginTop: "10px",
                                                    }}
                                                    item={item}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </>
                            );
                        }}
                    </DataListInputField>
                </Box>
            )}
        </>
    );
};

const Comorbidities = ({ form, readonly, filters }) => {
    const { t } = useTranslation();
    const lang = useCallback((name) => t(`anamnesis.comorbidity.${name}`), [t]);
    return (
        <FormSection title={lang("title")}>
            <QuestionWithFiltering filters={filters} filterValue={form.values?.haveDementia}>
                <BooleQuestion
                    disabled={readonly}
                    form={form}
                    fieldName="haveDementia"
                    question={lang("haveDementia")}
                />
                <InputDivider />
            </QuestionWithFiltering>
            <QuestionWithFiltering filters={filters} filterValue={form.values?.haveNeurologicalDisease}>
                <NeurologicalDisease {...{ form, lang, t, readonly }} />
                <InputDivider />
            </QuestionWithFiltering>
            <QuestionWithFiltering filters={filters} filterValue={form.values?.haveAutoimmuneDisease}>
                <AutoimmuneDisease {...{ form, lang, readonly }} />
                <InputDivider />
            </QuestionWithFiltering>

            <QuestionWithFiltering filters={filters} filterValue={form.values?.haveCOPD}>
                <BooleQuestion
                    disabled={readonly}
                    form={form}
                    fieldName="haveCOPD"
                    info={lang("haveCOPDInfo")}
                    question={lang("haveCOPDQuestion")}
                />

                <InputDivider />
            </QuestionWithFiltering>

            <QuestionWithFiltering filters={filters} filterValue={form.values?.haveAsthma}>
                <BooleQuestion
                    disabled={readonly}
                    form={form}
                    fieldName="haveAsthma"
                    question={lang("haveAsthmaQuestion")}
                />

                <InputDivider />
            </QuestionWithFiltering>

            <QuestionWithFiltering filters={filters} filterValue={form.values?.haveGastrointestinalBleeding}>
                <BooleQuestion
                    disabled={readonly}
                    form={form}
                    fieldName="haveGastrointestinalBleeding"
                    question={lang("haveGastrointestinalBleedingQuestion")}
                />
                <InputDivider />
            </QuestionWithFiltering>
            <QuestionWithFiltering filters={filters} filterValue={form.values?.haveOtherBleeding}>
                <BooleQuestion
                    disabled={readonly}
                    form={form}
                    fieldName="haveOtherBleeding"
                    question={lang("haveOtherBleedingQuestion")}
                />
                <InputDivider />
            </QuestionWithFiltering>

            <QuestionWithFiltering filters={filters} filterValue={form.values?.havePulmonaryHypertension}>
                <BooleQuestion
                    disabled={readonly}
                    form={form}
                    info={lang("havePulmonaryHypertensionInfo")}
                    fieldName="havePulmonaryHypertension"
                    question={lang("havePulmonaryHypertensionQuestion")}
                />
                <InputDivider />
            </QuestionWithFiltering>

            <QuestionWithFiltering filters={filters} filterValue={form.values?.haveRenealInsufficiency}>
                <BooleQuestion
                    disabled={readonly}
                    form={form}
                    fieldName="haveRenealInsufficiency"
                    info={lang("haveRenealInsufficiencyInfo")}
                    question={lang("haveRenealInsufficiencyQuestion")}
                />
                {isPropertySelected(form, "haveRenealInsufficiency") && (
                    <Box mb={2}>
                        <Grid container spacing={0}>
                            <Grid item xs={4}>
                                <AnamnesisSelectbox
                                    disabled={readonly}
                                    form={form}
                                    fieldName={"haveRenealInsufficiencyType"}
                                    label={lang("haveRenealInsufficiencyTypeLabel")}
                                >
                                    <MenuItem value="GFR90">GFR 90ml/min oder höher</MenuItem>
                                    <MenuItem value="GFR6089">GFR 60-89ml/min</MenuItem>
                                    <MenuItem value="GFR3059">GFR 30-59ml/min</MenuItem>
                                    <MenuItem value="GFR1529">GFR 15-29ml/min</MenuItem>
                                    <MenuItem value="GFRLT15">GFR &lt; 15ml/min oder aktuelle Dialysepflicht</MenuItem>
                                </AnamnesisSelectbox>
                            </Grid>
                        </Grid>

                        <BooleSubQuestion
                            disabled={readonly}
                            form={form}
                            fieldName="haveRenealInsufficiencyDialysis"
                            question={lang("haveRenealInsufficiencyDialysisQuestion")}
                        />
                        {isPropertySelected(form, "haveRenealInsufficiencyDialysis") && (
                            <Grid container spacing={0}>
                                <Grid item xs={4}>
                                    <AnamnesisSelectbox
                                        disabled={readonly}
                                        form={form}
                                        fieldName={"haveRenealInsufficiencyDialysisType"}
                                        label={t("anamnesis.specify")}
                                    >
                                        <MenuItem value="HAEMODIALYSIS">{lang("HAEMODIALYSIS")}</MenuItem>
                                        <MenuItem value="PERITONAL_DIALYSIS">{lang("PERITONAL_DIALYSIS")}</MenuItem>
                                    </AnamnesisSelectbox>
                                </Grid>
                            </Grid>
                        )}
                    </Box>
                )}
                <InputDivider />
            </QuestionWithFiltering>

            <QuestionWithFiltering filters={filters} filterValue={form.values?.haveOrganTransplantation}>
                <BooleQuestion
                    disabled={readonly}
                    form={form}
                    fieldName="haveOrganTransplantation"
                    question={lang("haveOrganTransplantationQuestion")}
                />
                {isPropertySelected(form, "haveOrganTransplantation") && (
                    <Box mb={2}>
                        <DataListInputField
                            disabled={readonly}
                            form={form}
                            fieldName="haveOrganTransplantationData"
                            rowData={{ date: null, value: "" }}
                        >
                            {({ onChange, item, DefaultDeleteIcon }) => (
                                <>
                                    <Grid container key={item.id} spacing={1}>
                                        <Grid item xs={3}>
                                            <CustomYearPicker
                                                disabled={readonly}
                                                name="date"
                                                fullWidth
                                                type="date"
                                                label={""}
                                                value={item?.date ?? ""}
                                                onChange={(e) => {
                                                    onChange({
                                                        id: item.id,
                                                        field: "date",
                                                        props: { value: e.target.value },
                                                    });
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={3}>
                                            <Select
                                                disabled={readonly}
                                                fullWidth
                                                displayEmpty
                                                value={item?.value ?? null}
                                                onChange={(e) =>
                                                    onChange({
                                                        id: item.id,
                                                        field: "value",
                                                        props: { value: e.target.value },
                                                    })
                                                }
                                                renderValue={PlaceholderOrValue(item?.value, t)}
                                            >
                                                <MenuItem value="HEART">{lang("HEART")}</MenuItem>
                                                <MenuItem value="LUNG">{lang("LUNG")}</MenuItem>
                                                <MenuItem value="LIVER">{lang("LIVER")}</MenuItem>
                                                <MenuItem value="KIDNEY">{lang("KIDNEY")}</MenuItem>
                                                <MenuItem value="PANCREAS">{lang("PANCREAS")}</MenuItem>
                                                <MenuItem value="OTHER">{lang("OTHER")}</MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid item xs={2}>
                                            {!readonly && (
                                                <DefaultDeleteIcon
                                                    style={{
                                                        marginTop: "10px",
                                                    }}
                                                    item={item}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </DataListInputField>
                    </Box>
                )}

                <InputDivider />
            </QuestionWithFiltering>

            <QuestionWithFiltering filters={filters} filterValue={form.values?.haveRestlessLegsSyndrome}>
                <BooleQuestion
                    disabled={readonly}
                    form={form}
                    fieldName="haveRestlessLegsSyndrome"
                    question={lang("haveRestlessLegsSyndromeQuestion")}
                />

                <InputDivider />
            </QuestionWithFiltering>

            <QuestionWithFiltering filters={filters} filterValue={form.values?.haveMigraine}>
            <BooleQuestion
                disabled={readonly}
                form={form}
                fieldName="haveMigraine"
                question={lang("haveMigraineQuestion")}
            />
            {isPropertySelected(form, "haveMigraine") && (
                <Box mb={2}>
                    <Grid container spacing={0}>
                        <Grid item xs={4}>
                            <AnamnesisSelectbox
                                disabled={readonly}
                                form={form}
                                fieldName={"haveMigraineTimesPerMonth"}
                                label={lang("haveMigraineTimesPerMonthLabel")}
                            >
                                <MenuItem value="LT3">&lt; 3</MenuItem>
                                <MenuItem value="3-5">3-5</MenuItem>
                                <MenuItem value="GT5">&gt; 5</MenuItem>
                            </AnamnesisSelectbox>
                        </Grid>
                    </Grid>
                </Box>
            )}
            <InputDivider />
            </QuestionWithFiltering>

            <QuestionWithFiltering filters={filters} filterValue={form.values?.haveCOVIDTimesInfected}>
                <BooleQuestion disabled={readonly} form={form} fieldName="haveCOVID" question={lang("haveCOVIDQuestion")} />
                {isPropertySelected(form, "haveCOVID") && (
                    <Box mb={2}>
                        <Grid container spacing={0}>
                            <Grid item xs={4}>
                                <AnamnesisSelectbox
                                    disabled={readonly}
                                    form={form}
                                    fieldName={"haveCOVIDTimesInfected"}
                                    label={lang("haveCOVIDTimesInfectedLabel")}
                                >
                                    {[1, 2, 3, 4, 5].map((e) => (
                                        <MenuItem key={`COVID-infected-${e}`} value={e}>
                                            {e}
                                        </MenuItem>
                                    ))}
                                    <MenuItem value="GT5">&gt; 5</MenuItem>
                                </AnamnesisSelectbox>
                            </Grid>
                        </Grid>
                    </Box>
                )}
                <InputDivider />
            </QuestionWithFiltering>

            <QuestionWithFiltering filters={filters} filterValue={form.values?.haveCOVIDVaccination}>
            <BooleQuestion
                disabled={readonly}
                form={form}
                fieldName="haveCOVIDVaccination"
                question={lang("haveCOVIDVaccinationQuestion")}
            />
            {isPropertySelected(form, "haveCOVIDVaccination") && (
                <Box mb={2}>
                    <Grid container spacing={0}>
                        <Grid item xs={4}>
                            <AnamnesisSelectbox
                                disabled={readonly}
                                form={form}
                                fieldName={"haveCOVIDVaccinationTimes"}
                                label={lang("haveCOVIDVaccinationTimesQuestion")}
                            >
                                {[1, 2, 3, 4, 5].map((e) => (
                                    <MenuItem key={`COVID-vaccination-${e}`} value={e}>
                                        {e}
                                    </MenuItem>
                                ))}
                                <MenuItem value="GT5">&gt; 5</MenuItem>
                            </AnamnesisSelectbox>
                        </Grid>
                    </Grid>
                </Box>
            )}

            <InputDivider />
            </QuestionWithFiltering>

            <QuestionWithFiltering filters={filters} filterValue={form.values?.haveThromboses}>
            <BooleQuestion
                disabled={readonly}
                form={form}
                fieldName="haveThromboses"
                info={lang("haveThrombosesInfo")}
                question={lang("haveThrombosesQuestion")}
            />

            {isPropertySelected(form, "haveThromboses") && (
                <Box mb={2}>
                    <DataListInputField
                        disabled={readonly}
                        form={form}
                        fieldName="haveThrombosesData"
                        rowData={{ date: "", value: "THROMBOSES_DATA" }}
                    >
                        {({ onChange, item, DefaultDeleteIcon }) => (
                            <>
                                <Grid container key={item.id} spacing={0}>
                                    <Grid item xs={3} style={{ display: "flex" }}>
                                        <CustomYearPicker
                                            disabled={readonly}
                                            name="date"
                                            type="date"
                                            value={item?.date ?? ""}
                                            onChange={(e) => {
                                                onChange({
                                                    id: item.id,
                                                    field: "date",
                                                    props: { value: e.target.value },
                                                });
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <DefaultDeleteIcon
                                            style={{
                                                marginTop: "10px",
                                            }}
                                            item={item}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </DataListInputField>
                </Box>
            )}

            <InputDivider />
            </QuestionWithFiltering>

            <QuestionWithFiltering filters={filters} filterValue={form.values?.haveDepression}>

            <BooleQuestion
                disabled={readonly}
                form={form}
                fieldName="haveDepression"
                info={lang("haveDepressionInfo")}
                question={lang("haveDepressionQuestion")}
            />
            {isPropertySelected(form, "haveDepression") && (
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={8} md={6} lg={5} xl={3}>
                        <AnamnesisSelectbox
                            disabled={readonly}
                            form={form}
                            fieldName={"haveDepressionType"}
                            label={t("anamnesis.specify")}
                        >
                            <MenuItem value="TREATED_MEDICATION"> {lang("TREATED_MEDICATION")} </MenuItem>
                            <MenuItem value="TREATED_THERAPY"> {lang("TREATED_THERAPY")} </MenuItem>
                            <MenuItem value="UNTREATED"> {lang("UNTREATED")} </MenuItem>
                        </AnamnesisSelectbox>
                    </Grid>
                </Grid>
            )}
            </QuestionWithFiltering>
        </FormSection>
    );
};

export default Comorbidities;
