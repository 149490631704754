export const FETCH_NEW_CASE = '@case_edit/FETCH_NEW_CASE';
export const FETCH_NEW_CASE_SUCCESS = '@case_edit/FETCH_NEW_CASE_SUCCESS';
export const FETCH_NEW_CASE_FAIL = '@case_edit/FETCH_NEW_CASE_FAIL';

export const FETCH_CASE = '@case_edit/FETCH_CASE';
export const FETCH_CASE_SUCCESS = '@case_edit/FETCH_CASE_SUCCESS';
export const FETCH_CASE_FAIL = '@case_edit/FETCH_CASE_FAIL';

export const FETCH_PATIENT_COORDINATES = '@case_edit/FETCH_PATIENT_COORDINATES';
export const FETCH_PATIENT_COORDINATES_SUCCESS = '@case_edit/FETCH_PATIENT_COORDINATES_SUCCESS';
export const FETCH_PATIENT_COORDINATES_FAIL = '@case_edit/FETCH_PATIENT_COORDINATES_FAIL';

export const UPDATE_CASE = '@case_edit/UPDATE_CASE';

export const SAVE_CASE = '@case_edit/SAVE_CASE';
export const SAVE_CASE_SUCCESS = '@case_edit/SAVE_CASE_SUCCESS';
export const SAVE_CASE_FAIL = '@case_edit/SAVE_CASE_FAIL';

export const UPDATE_PATIENT = '@case_edit/UPDATE_PATIENT';

export const ADD_LOG = '@case_edit/ADD_LOG';
export const ADD_LOG_SUCCESS = '@case_edit/ADD_LOG_SUCCESS';
export const ADD_LOG_FAIL = '@case_edit/ADD_LOG_FAIL';

export const ADD_NEW_PATIENT_QUESTION_LOG = '@case_edit/ADD_NEW_PATIENT_QUESTION_LOG';
export const ADD_NEW_PATIENT_QUESTION_LOG_SUCCESS = '@case_edit/ADD_NEW_PATIENT_QUESTION_LOG_SUCCESS';
export const ADD_NEW_PATIENT_QUESTION_LOG_FAIL = '@case_edit/ADD_NEW_PATIENT_QUESTION_LOG_FAIL';

export const DELETE_EMPTY_CASE = '@case_edit/DELETE_EMPTY_CASE';
export const DELETE_EMPTY_CASE_SUCCESS = '@case_edit/DELETE_EMPTY_CASE_SUCCESS';
export const DELETE_EMPTY_CASE_FAIL = '@case_edit/DELETE_EMPTY_CASE_FAIL';

export const CANCEL_CASE = '@case_edit/CANCEL_CASE';
export const CANCEL_CASE_SUCCESS = '@case_edit/CANCEL_CASE_SUCCESS';
export const CANCEL_CASE_FAIL = '@case_edit/CANCEL_CASE_FAIL';

export const REOPEN_CASE = '@case_edit/REOPEN_CASE';
export const REOPEN_CASE_SUCCESS = '@case_edit/REOPEN_CASE_SUCCESS';
export const REOPEN_CASE_FAIL = '@case_edit/REOPEN_CASE_FAIL';

export const CLONE_CASE = '@case_edit/CLONE_CASE';
export const CLONE_CASE_SUCCESS = '@case_edit/CLONE_CASE_SUCCESS';
export const CLONE_CASE_FAIL = '@case_edit/CLONE_CASE_FAIL';

export const UPDATE_SECOND_OPINION_DETAIL = '@case_edit/UPDATE_SECOND_OPINION_DETAIL';
export const UPDATE_SECOND_OPINION_DETAIL_SUCCESS = '@case_edit/UPDATE_SECOND_OPINION_DETAIL_SUCCESS';
export const UPDATE_SECOND_OPINION_DETAIL_FAIL = '@case_edit/UPDATE_SECOND_OPINION_DETAIL_FAIL';

export const DELETE_SECOND_OPINION_DETAIL = '@case_edit/DELETE_SECOND_OPINION_DETAIL';
export const DELETE_SECOND_OPINION_DETAIL_SUCCESS = '@case_edit/DELETE_SECOND_OPINION_DETAIL_SUCCESS';
export const DELETE_SECOND_OPINION_DETAIL_FAIL = '@case_edit/DELETE_SECOND_OPINION_DETAIL_FAIL';

export const CONFIRM_SECOND_OPINION = '@case_edit/CONFIRM_SECOND_OPINION';
export const CONFIRM_SECOND_OPINION_SUCCESS = '@case_edit/CONFIRM_SECOND_OPINION_SUCCESS';
export const CONFIRM_SECOND_OPINION_FAIL = '@case_edit/CONFIRM_SECOND_OPINION_FAIL';

export const LIST_CASE_EDITOR_MATCHES = '@case_edit/LIST_CASE_EDITOR_MATCHES';
export const LIST_CASE_EDITOR_MATCHES_SUCCESS = '@case_edit/LIST_CASE_EDITOR_MATCHES_SUCCESS';
export const LIST_CASE_EDITOR_MATCHES_FAIL = '@case_edit/LIST_CASE_EDITOR_MATCHES_FAIL';

export const UPSERT_CASE_EDITOR_MATCH = '@case_edit/UPSERT_CASE_EDITOR_MATCH';
export const UPSERT_CASE_EDITOR_MATCH_SUCCESS = '@case_edit/UPSERT_CASE_EDITOR_MATCH_SUCCESS';
export const UPSERT_CASE_EDITOR_MATCH_FAIL = '@case_edit/UPSERT_CASE_EDITOR_MATCH_FAIL';

export const REMOVE_CASE_EDITOR_MATCH = '@case_edit/REMOVE_CASE_EDITOR_MATCH';
export const REMOVE_CASE_EDITOR_MATCH_SUCCESS = '@case_edit/REMOVE_CASE_EDITOR_MATCH_SUCCESS';
export const REMOVE_CASE_EDITOR_MATCH_FAIL = '@case_edit/REMOVE_CASE_EDITOR_MATCH_FAIL';

export const RESET = '@case_edit/RESET';
