export const LIST_CLINICS = '@clinics/LIST_CLINICS';
export const LIST_CLINICS_SUCCESS = '@clinics/LIST_CLINICS_SUCCESS';
export const LIST_CLINICS_FAIL = '@clinics/LIST_CLINICS_FAIL';

export const GET_CLINIC = '@clinics/GET_CLINIC';
export const GET_CLINIC_SUCCESS = '@clinics/GET_CLINIC_SUCCESS';
export const GET_CLINIC_FAIL = '@clinics/GET_CLINIC_FAIL';

export const CREATE_CLINIC = '@clinics/CREATE_CLINIC';
export const CREATE_CLINIC_SUCCESS = '@clinics/CREATE_CLINIC_SUCCESS';
export const CREATE_CLINIC_FAIL = '@clinics/CREATE_CLINIC_FAIL';

export const UPDATE_CLINIC = '@clinics/UPDATE_CLINIC';
export const UPDATE_CLINIC_SUCCESS = '@clinics/UPDATE_CLINIC_SUCCESS';
export const UPDATE_CLINIC_FAIL = '@clinics/UPDATE_CLINIC_FAIL';

export const UPDATE_CLINIC_CENTER = '@clinics/UPDATE_CLINIC_CENTER';
export const UPDATE_CLINIC_CENTER_SUCCESS = '@clinics/UPDATE_CLINIC_CENTER_SUCCESS';
export const UPDATE_CLINIC_CENTER_FAIL = '@clinics/UPDATE_CLINIC_CENTER_FAIL';

export const CREATE_CLINIC_CENTER = '@clinics/UPDATE_CLINIC';
export const CREATE_CLINIC_CENTER_SUCCESS = '@clinics/CREATE_CLINIC_CENTER_SUCCESS';
export const CREATE_CLINIC_CENTER_FAIL = '@clinics/CREATE_CLINIC_CENTER_FAIL';

export const ADD_LOG = '@clinics/ADD_LOG';
export const ADD_LOG_SUCCESS = '@clinics/ADD_LOG_SUCCESS';
export const ADD_LOG_FAIL = '@clinics/ADD_LOG_FAIL';

export const FETCH_CLINIC_LOGS = '@clinics/FETCH_CLINIC_LOGS';
export const FETCH_CLINIC_LOGS_SUCCESS = '@clinics/FETCH_CLINIC_LOGS_SUCCESS';
export const FETCH_CLINIC_LOGS_FAIL = '@clinics/FETCH_CLINIC_LOGS_FAIL';

export const FETCH_CONTACTS = '@clinics/FETCH_CONTACTS_LOGS';
export const FETCH_CONTACTS_SUCCESS = '@clinics/FETCH_CONTACTS_SUCCESS';
export const FETCH_CONTACTS_FAIL = '@clinics/FETCH_CONTACTS_FAIL';

export const SET_ACTIVITY_TAB = '@clinics/SET_ACTIVITY_TAB';
