export const BlockedScreen = ({isShown}) => {
    return (
        <div
            style={{
                position: "absolute",
                visibility: isShown ? "visible" : "hidden",
                top: 0,
                left: 0,
                width: "100%",
                height: "100vh",
                background: "white",
                opacity: isShown ? 1 : 0,
                zIndex: 1399,
                transition: "opacity .3s ease-in-out",
            }}
        ></div>
    );
};
