import React, {PureComponent} from 'react';
import {withStyles, Typography, Tooltip} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {HEADER_BASES} from './ClinicHeader';
import Tag from 'components/tags/Tag';
import _ from 'lodash';
import * as colors from 'components/colors/Colors';

const styles = theme => ({
    clinicTile: {
        margin: '5px 0',
        backgroundColor: 'white',
        boxShadow: '0px 0px 8px #0000001A',
        borderRadius: 4,
        alignItems: 'center'
    },
    clinicText: {
        fontWeight: 'bold',
        fontSize: 13,
    }
});

export class ClinicTile extends PureComponent {

    state = {
        isLoaded: false,
    };

    ref = React.createRef();

    componentDidMount() {
        this.observer = new IntersectionObserver(this.load);
        if (this.ref.current) {
            this.observer.observe(this.ref.current);
        }
    }

    componentWillUnmount() {
        this.observer.unobserve(this.ref.current);
    }

    load = entries => {
        if (entries[0].isIntersecting) {
            this.setState({isLoaded: true});
            this.observer.unobserve(this.ref.current);
        }
    };

    render() {
        const {
            classes,
            clinic,
            isSelected,
            selectClinic,
            handleDoubleClick,
            selectCertificate,
            selectSubIndication
        } = this.props;
        const rating = clinic.centers && clinic.centers.length === 1
            ? clinic.centers[0].latestQualityRatingAggregate
            : '!';
        return (
            <Flex container className={classes.clinicTile} style={{
                ...(isSelected && {
                    boxShadow: '0px 0px 8px #049F9081',
                    border: '1px solid #049F90'
                })
            }} onClick={selectClinic} onDoubleClick={handleDoubleClick}>
                <div id={`${clinic.businessId}-observed`} ref={this.ref}/>
                {
                    this.state.isLoaded &&
                    <>
                        <Flex item={`0 0 ${HEADER_BASES.name}`} container
                              style={{marginLeft: 10}}
                              alignItems={'center'} padding={3}>
                            <Typography className={classes.clinicText}>
                                {clinic.name}
                            </Typography>
                        </Flex>

                        <Flex item={`0 0 ${HEADER_BASES.rating}`} container padding={3}>
                            <Tooltip title={`
                            `}>
                            <Flex container center style={{
                                borderRadius: 4,
                                minWidth: 20,
                                backgroundColor: _.isFinite(rating) ? colors.jaffa : colors.mediumPurple
                            }}>
                                <Typography className={classes.clinicText} style={{color: colors.htmlWhite}}>
                                    {rating}
                                </Typography>
                            </Flex>
                            </Tooltip>
                        </Flex>

                        <Flex item={`0 0 ${HEADER_BASES.city}`} container padding={3}>
                            <Typography className={classes.clinicText}>
                                {_.get(clinic, 'address.city')}
                            </Typography>
                        </Flex>

                        <Flex item={`0 0 ${HEADER_BASES.cancerType}`} container style={{flexWrap: 'wrap'}} padding={3}>

                            {clinic.centers.flatMap(center => center.subIndications).map((subIndication, index) => (
                                <Tag
                                    key={subIndication + '' + index}
                                    {...{
                                        selected: false,
                                        tag: subIndication,
                                        toggleSelection: selectSubIndication
                                    }}/>
                            ))}
                        </Flex>
                        <Flex item={`0 0 ${HEADER_BASES.certificate}`} container style={{flexWrap: 'wrap'}} padding={3}>

                            {clinic.centers.flatMap(center => center.certifications).map((certificate, index) => (
                                <Tag
                                    key={certificate + '' + index}
                                    {...{
                                        selected: false,
                                        tag: certificate,
                                        toggleSelection: selectCertificate
                                    }}/>
                            ))}
                        </Flex>
                        <Flex item={`0 0 ${HEADER_BASES.tag}`} container style={{flexWrap: 'wrap'}} padding={3}>

                            <Typography className={classes.clinicText}>
                                -
                            </Typography>
                        </Flex>
                        <Flex item={`0 0 ${HEADER_BASES.comment}`} container style={{flexWrap: 'wrap'}} padding={3}>

                            <Typography className={classes.clinicText}>
                                -
                            </Typography>
                        </Flex>
                    </>
                }
            </Flex>
        );
    }
}

export default withStyles(styles)(ClinicTile);
