import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import {useEffect} from "react";
import {pictonBlue} from 'components/colors/Colors';

export default function StandaloneToggleButton({form, name, value, children, ...props}) {
    return (
        <ToggleButton
            {...props}
            value={value || ''}
            name={name}
            style={{border: !value ? `1px dashed ${pictonBlue}` : '1px solid white'}}
            selected={value}
            size="small"
            onChange={() => {
                form.setFieldValue(name, !value);
            }}
        >
            {children}
        </ToggleButton>
    );
}
