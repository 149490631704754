import {useTranslation} from "react-i18next";
import React, {useCallback} from "react";
import FormSection from "../FormSection";
import BooleQuestion from "../input/BooleQuestion";
import {isPropertySelected} from "../config/ValidationSchema";
import DataListInputField from "../input/DataListInputField";
import {Grid} from "@material-ui/core";
import DataListMonthPicker from "../input/DataListMonthPicker";
import EpaTextField from "components/hmo-textfield/EpaTextField";

const ArthroseForm = ({form, readonly}) => {
    const {t} = useTranslation();
    const lang = useCallback((name) => t(`anamnesis.arthrose.${name}`), [t]);

    return (
            <FormSection title={lang('title')}>
                    <BooleQuestion
                            disabled={readonly}
                            form={form}
                            fieldName="haveArthrose"
                            question={lang('haveArthroseQuestion')}
                    />

                    {
                            isPropertySelected(form, 'haveArthrose') &&
                            <DataListInputField
                                    disabled={readonly}
                                    form={form}
                                    fieldName="haveArthroseData"
                                    rowData={{date: null, value: ''}}
                            >
                                {({onChange, item, DefaultDeleteIcon}) =>
                                        <Grid container key={item.id} spacing={1}>
                                            <Grid item xs={2} style={{minWidth: 170}}>
                                                <DataListMonthPicker {...{
                                                    disabled: readonly,
                                                    item, onChange, label: lang("since-when")
                                                }}/>
                                            </Grid>

                                            <Grid item xs={3}>
                                                <EpaTextField
                                                        disabled={readonly}
                                                        name="value"
                                                        label={lang('which-joint')}
                                                        value={item?.value ?? ''}
                                                        onChange={(e) => {
                                                            onChange({
                                                                id: item.id,
                                                                field: 'value',
                                                                props: {value: e.target.value}
                                                            })
                                                        }}
                                                />

                                            </Grid>
                                            <Grid item xs={2}>
                                                {!readonly && <DefaultDeleteIcon style={{
                                                    marginTop: '25px'
                                                }} item={item}/>}
                                            </Grid>

                                        </Grid>

                                }
                            </DataListInputField>
                    }

            </FormSection>
    );
};

export default ArthroseForm;
