export const AGREEMENT_OF_PATIENT_RECEIVED = "AGREEMENT_OF_PATIENT_RECEIVED"; //
export const TNE_ORIGINAL = "TNE_ORIGINAL"; //
export const SEV = "SEV"; //
export const EINVERSTANDNISERKLARUNG_ORIGINAL = 'EINVERSTANDNISERKLARUNG_ORIGINAL' //
export const SEV_ORIGINAL = "SEV_ORIGINAL"; //
export const PROFORMA_INVOICE_ISSUED = "PROFORMA_INVOICE_ISSUED";//
export const REIMBURSEMENT = "REIMBURSEMENT"; //
export const INVOICE_PAID = "INVOICE_PAID";//
export const DOCUMENTS_REQUESTED = "DOCUMENTS_REQUESTED"; //
export const SECOND_OPINION_READY = "SECOND_OPINION_READY"; //
export const SECOND_OPINION_SENT = "SECOND_OPINION_SENT"; //
export const QUESTIONNAIRE = "QUESTIONNAIRE"; //
export const FINAL_INVOICE_ISSUED = "FINAL_INVOICE_ISSUED";//
export const DOCUMENTS_COMPLETED = "DOCUMENTS_COMPLETED"; //
export const CDS_AVAILABLE= "CDS_AVAILABLE"; //
export const CD_SEND_TO_TB= "CD_SEND_TO_TB"; //
export const CD_BACK_TO_PATIENT= "CD_BACK_TO_PATIENT"; //
export const INFO_CD_BACK_TO_PATIENT= "INFO_CD_BACK_TO_PATIENT"; //
export const EVALUATION_OF_STUDY= "EVALUATION_OF_STUDY"; //

//NOTE LM: Ordering comes from the backend, the order here does not supposed to matter and you should NOT use it as such
export const MILESTONES = {
    AGREEMENT_OF_PATIENT_RECEIVED: ['ALL'],
    TNE_ORIGINAL: ['ALL'],
    EINVERSTANDNISERKLARUNG_ORIGINAL: ['HERBST_2022'],
    SEV: ['ALL'],
    SEV_ORIGINAL: ['ALL'],
    PROFORMA_INVOICE_ISSUED: ['SELBSTZAHLER', 'HERBST_2022'],
    REIMBURSEMENT: ['VERSICHERUNG', 'HELIOS'],
    INVOICE_PAID: ['SELBSTZAHLER', 'HERBST_2022'],
    DOCUMENTS_REQUESTED: ['ALL'],
    DOCUMENTS_COMPLETED: ['ALL'],
    FINAL_INVOICE_ISSUED: ['SELBSTZAHLER', 'HERBST_2022'],
    SECOND_OPINION_READY: ['ALL'],
    SECOND_OPINION_SENT: ['ALL'],
    CDS_AVAILABLE: ['ALL'],
    CD_SEND_TO_TB: ['ALL'],
    INFO_CD_BACK_TO_PATIENT: ['ALL'],
    CD_BACK_TO_PATIENT: ['ALL'],
    QUESTIONNAIRE: ['ALL'],
    EVALUATION_OF_STUDY: ['STUDIE_DKG'],
};

export const immutableMilestones = [
    AGREEMENT_OF_PATIENT_RECEIVED, SECOND_OPINION_READY
];

export const filterForCostCoverage = (milestone, costCoverages) => {
    const types = MILESTONES[milestone];
    if (!types || types.includes('ALL')) return true;

    return types.some(type => costCoverages.includes(type));
}
