import { useMemo } from "react";
import { useSelector } from "react-redux";
import { isPatient } from "domain/User.model";

/**
 * Hook to determine if the current user is a patient.
 *
 * @returns {boolean} - Returns true if the user is a patient, false otherwise.
 */
export const useIsPatient = () => {
    const user = useSelector((state) => state.mainReducer.user);
    return useMemo(() => isPatient(user), [user]);
}
