import { Box, Typography, IconButton, Tooltip, Button, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CancelIcon from "@mui/icons-material/Cancel";
import { useIsPatient, useIsManager, useIsContactPerson } from "utils";

export const ProgramRequestToUnsubscribeControlPanel = ({
    program,
    onCancelProgramTermination,
    onReopenProgram,
    onToggleDeleteDialog,
}) => {
    const { t: translate } = useTranslation();
    const userIsManager = useIsManager();
    const userIsContactPerson = useIsContactPerson();
    const userIsPatient = useIsPatient();
    const { suspendedParticipation, requestToUnsubscribe } = program;
    const theme = useTheme();
    return suspendedParticipation && userIsManager ? (
        <Box display={"flex"} style={{ gap: theme.spacing(2), marginLeft: "8px", alignItems: 'center' }}>
            <Button onClick={onReopenProgram} size="small" variant="outlined">
                {translate('program.reopen-program').toUpperCase()}
            </Button>
            <span
                style={{
                    fontWeight: theme.typography.fontWeightBold,
                    fontSize: "18px",
                    color: theme.palette.grey[700],
                }}
            >
                {translate('global.aborted')}
            </span>
        </Box>
    ) : (
        <>
            {requestToUnsubscribe && userIsManager && (
                <Box display={"flex"} justifyContent="flex-start" flex={"1 1 auto"}>
                    <Typography color="textPrimary" variant="subtitle2" style={{ paddingRight: "4px" }}>
                        {translate("program.user-requested-to-unsubscribe")}
                    </Typography>
                </Box>
            )}
            {requestToUnsubscribe && !userIsManager && (
                <Box display={"flex"} justifyContent="flex-start" flex={"1 1 auto"}>
                    <Typography color="textPrimary" variant="subtitle2" style={{ paddingRight: "4px" }}>
                        {translate("program.request-to-unsubscribe-was-sent")}
                    </Typography>
                </Box>
            )}
            <Box display={"flex"} flex={"1 1 auto"} />

            {!userIsContactPerson && (
                <>
                    {requestToUnsubscribe ? (
                        <Box display={"flex"} style={{ gap: "4px", marginLeft: "8px" }}>
                            <Button onClick={onCancelProgramTermination} size="small" variant="outlined">
                                {translate("program.cancel-program-termination")}
                            </Button>
                            {userIsManager && (
                                <Button
                                    onClick={onToggleDeleteDialog}
                                    variant="contained"
                                    size="small"
                                    style={{ backgroundColor: "#CF3A59", color: "white" }}
                                >
                                    {translate("program.confirm-program-termination")}
                                </Button>
                            )}
                        </Box>
                    ) : (
                        <Tooltip title={translate("global.unsub-program")} placement="left">
                            <IconButton
                                style={{ padding: 6 }}
                                disabled={userIsPatient && requestToUnsubscribe}
                                onClick={onToggleDeleteDialog}
                            >
                                <CancelIcon color={userIsPatient && requestToUnsubscribe ? "disabled" : "error"} />
                            </IconButton>
                        </Tooltip>
                    )}
                </>
            )}
        </>
    );
};
