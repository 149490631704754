import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from 'store/Store';
import Main from 'scenes/Main';
import Immutable from 'immutable';
import 'i18n/i18n';
import HmoTheme from "components/hmo-theme/HmoTheme";
import CssBaseline from '@material-ui/core/CssBaseline';
import 'components/table/table.css'
import 'components/appcss/App.css'
import {SnackbarProvider} from "notistack";

// See more: https://github.com/facebook/draft-js/issues/950
Immutable.Iterable.noLengthWarning = true;

//bump

ReactDOM.render(
    <SnackbarProvider maxSnack={3}>
        <Provider store={store}>
            <HmoTheme>
                <CssBaseline/>
                <BrowserRouter>
                    <Main/>
                </BrowserRouter>
            </HmoTheme>
        </Provider>
    </SnackbarProvider>
    ,
    document.getElementById('hmo-frontend-root')
);
