import React, {Component, PureComponent} from 'react';
import {FormHelperText, TextField, Typography, withStyles} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {
    htmlWhite,
    iceberg,
    pictonBlue,
    transparentBlack054,
    materialBlue,
    htmlRed
} from 'components/colors/Colors';
import _ from 'lodash';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import ClinicPicker from "scenes/case/edit/component/clinic/ClinicPicker";
import CalendarPage from 'components/calendar/CalendarPage';
import Diagnosis from './Diagnosis';
import SecondOpinionDetails from './SecondOpinionDetails';
import HmoInputLabel from 'components/input-label/HmoInputLabel';
import HmoTextField from 'components/hmo-textfield/HmoTextField';
import Tnm from './Tnm';
import ReleaseSecondOpinion from './ReleaseSecondOpinion';
import {Autocomplete} from '@material-ui/lab';
import {isClinic} from 'domain/User.model';
import {DOCUMENTS_COMPLETED} from "domain/Milestone.model";

const STATEMENT_TYPES = ['Radiologisch', 'Endoskopisch', 'Sonographisch', 'Szintigraphisch', 'Other'];
const LABORATORY_TYPES = ['Marker', 'Sonstige'];
const GRADING_TYPES = ['UICC', 'Dukes', 'Gleason', 'Grading', 'Other'];
const EMPTY_ARRAY = [];

const styles = theme => ({
    inputItem: {
        padding: '7px 7px',
    },
    inputRoot: {
        backgroundColor: htmlWhite, borderRadius: 4, paddingLeft: 7
    },
    entryContainer: {
        borderRadius: 6, margin: 10, marginTop: 20, padding: 15
    },
    title: {
        textTransform: 'uppercase', fontWeight: 'bold', color: transparentBlack054, fontSize: 18, p: {
            color: 'red'
        }
    },
    dayNumber: {
        fontWeight: '700',
        fontSize: 24,
        lineHeight: 'normal',
        padding: '5px 0',
        color: 'red'
    }
});

class Header extends PureComponent {
    render() {
        const {
            classes, translate, selectedSubIndications, selectedIndication, handleCaseChange, subIndications, disabled,
            diseaseDetails, secondOpinionUntil, creationDate, handleSecondOpinionUntilChange, documentReadyDate,
            handleSubIndicationChange
        } = this.props;
        const subIndicationTags = subIndications
            .filter(si => si.indication === selectedIndication)
            .map(si => si.subIndication);
        const today = moment().startOf('day')
        const finishDate = secondOpinionUntil
            ? moment(secondOpinionUntil)
            : documentReadyDate
                ? moment(documentReadyDate).add(10, 'day')
                : '';
        const finishUntilDay = moment(finishDate).startOf('day')
        const remainingDays = moment.duration(finishUntilDay.diff(today)).asDays();
        const isOverDue = remainingDays < 0;

        return (
            <Flex container item alignItems={'strech'}>
                <Flex item container className={classes.entryContainer}
                      style={{backgroundColor: iceberg, borderRadius: 5}}>
                    <Flex item container direction={'column'}>
                        <Flex item container>
                            {!!subIndicationTags.length && (
                                <Flex item container className={classes.inputItem} column>
                                    <Typography
                                        style={{textTransform: 'uppercase', fontSize: 12, color: 'rgb(142, 142, 142)'}}>
                                        {translate("case.sub-indication")}
                                    </Typography>
                                    <Autocomplete
                                        multiple
                                        disabled={disabled}
                                        options={subIndicationTags || []}
                                        onChange={handleSubIndicationChange}
                                        value={selectedSubIndications || ''}
                                        freeSolo
                                        ChipProps={{
                                            style: {
                                                height: 25, color: htmlWhite, backgroundColor: pictonBlue
                                            }, color: 'secondary'
                                        }}
                                        renderInput={params => (<TextField
                                            {...params}
                                            classes={{root: classes.inputRoot}}
                                            placeholder={translate('global.please-select')}
                                        />)}
                                    />
                                    {_.isEmpty(selectedIndication) &&
                                    <FormHelperText>{translate('case.no-indication-error')}</FormHelperText>}
                                </Flex>
                            )}
                            <Flex item container className={classes.inputItem} column>
                                <HmoInputLabel>{translate("case.disease-details")}</HmoInputLabel>
                                <HmoTextField
                                    value={diseaseDetails || ''}
                                    disabled={disabled}
                                    onChange={handleCaseChange}
                                    fullWidth
                                    name={'diseaseDetails'}
                                />
                            </Flex>
                        </Flex>
                        <ClinicPicker style={{padding: 7}} disabled={disabled} {...{selectedIndication}} />
                    </Flex>
                </Flex>
                <Flex item grow={0} container column className={classes.inputItem}
                      style={{
                          backgroundColor: iceberg, borderRadius: 5, margin: 10, padding: 15, width: 220,
                          marginTop: 20
                      }}
                >
                    <Flex item container justifyContent={'space-between'}>
                        <CalendarPage {...{
                            title: translate(documentReadyDate ? "case.accepted-date" : "case.created-date"),
                            date: moment(documentReadyDate || creationDate),
                            name: 'creationDate',
                            disabled: true
                        }}/>

                        <CalendarPage {...{
                            title: translate("case.zmbis-date"),
                            date: finishDate,
                            name: 'dueDate',
                            onChange: handleSecondOpinionUntilChange,
                            disabled: disabled,
                            classes: {dayNumber: remainingDays > 0 ? undefined : classes.dayNumber}
                        }}/>
                    </Flex>
                    <Flex item container direction={'column'}
                          justifyContent={'flex-end'}
                          alignItems={'center'} style={{paddingTop: 10}}>
                        <Typography style={{
                            color: isOverDue ? htmlRed : materialBlue,
                            fontSize: 13,
                            textTransform: 'uppercase',
                            fontWeight: 'bold'
                        }}>
                            {
                                finishDate
                                    ? translate(isOverDue ? 'case.overdue-days' : 'case.remaining-days', {number: Math.abs(Math.floor(remainingDays))})
                                    : translate('global.nv')
                            }
                            {
                                finishDate && isOverDue &&
                                <span style={{color: transparentBlack054}}>
                                 {translate('case.overdue-days-last')}
                                </span>
                            }
                        </Typography>

                    </Flex>
                </Flex>

            </Flex>

        );
    }
}

const ICD10 = props => {
    const {
        secondOpinionDetails, documents, downloadFile, disabled
    } = props;
    return (<SecondOpinionDetails {...{
        detailType: 'ICD10',
        secondOpinionDetails,
        documents,
        downloadFile,
        disabled
    }}/>);
};

const Grading = props => {
    const {
        secondOpinionDetails, documents, downloadFile, disabled
    } = props;
    return (<SecondOpinionDetails {...{
        detailType: 'GRADING',
        secondOpinionDetails,
        types: GRADING_TYPES,
        documents,
        downloadFile,
        disabled
    }}/>);
};

const Pahtology = props => {
    const {
        secondOpinionDetails, documents, downloadFile, disabled
    } = props;
    return (<SecondOpinionDetails {...{
        detailType: 'PATHOLOGY',
        secondOpinionDetails,
        documents,
        downloadFile,
        disabled
    }}/>);
};

const Statement = props => {
    const {
        secondOpinionDetails, documents, downloadFile, disabled
    } = props;
    return (<SecondOpinionDetails {...{
        detailType: 'STATEMENT',
        secondOpinionDetails,
        types: STATEMENT_TYPES,
        documents,
        downloadFile,
        disabled
    }}/>);
};

const Laboratory = props => {
    const {
        secondOpinionDetails, documents, downloadFile, disabled
    } = props;
    return (<SecondOpinionDetails {...{
        detailType: 'LABORATORY',
        secondOpinionDetails,
        types: LABORATORY_TYPES,
        documents,
        downloadFile,
        disabled
    }}/>);
};

const AccompanyingDisease = props => {
    const {
        secondOpinionDetails, documents, downloadFile, disabled
    } = props;
    return (<SecondOpinionDetails {...{
        detailType: 'ACCOMPANYING_DISEASE',
        secondOpinionDetails,
        documents,
        downloadFile,
        disabled
    }}/>);
};

const FormerTherapy = props => {
    const {
        translate, secondOpinionDetails, documents, handleCaseChange, furtherTherapy,
        downloadFile, disabled
    } = props;
    return (
        <Flex item container column>
            <SecondOpinionDetails {...{
                detailType: 'FORMER_THERAPY',
                secondOpinionDetails,
                documents,
                downloadFile,
                disabled
            }}/>
            <Flex item container direction={'column'}
                  style={{
                      backgroundColor: iceberg, padding: '0 15px',
                      margin: '0 10px',
                      borderRadius: '0 0 5px 5px'
                  }}>
                <HmoInputLabel>{translate('case.further-therapy')}</HmoInputLabel>
                <HmoTextField
                    value={furtherTherapy || ''}
                    multiline
                    variant={'outlined'}
                    rows={3}
                    onChange={handleCaseChange}
                    fullWidth
                    disabled={disabled}
                    name={'furtherTherapy'}
                    InputProps={{
                        style: {
                            padding: 5,
                        }
                    }}
                    style={{
                        backgroundColor: htmlWhite,
                        marginBottom: 10
                    }}
                />
            </Flex>
        </Flex>
    );
};

let AdditionalInformation = props => {
    const {translate, additionalInformation, handleCaseChange, disabled} = props;
    return (
        <Flex item container column
              style={{backgroundColor: iceberg, borderRadius: 5, padding: 15, margin: 10}}>
            <HmoInputLabel>{translate('case.additional-information')}</HmoInputLabel>
            <HmoTextField
                value={additionalInformation || ''}
                multiline
                variant={'outlined'}
                rows={4}
                onChange={handleCaseChange}
                fullWidth
                disabled={disabled}
                placeholder={translate('global.notes-description')}
                name={'additionalInformation'}
                InputProps={{
                    style: {
                        padding: 5
                    }
                }}
                style={{
                    backgroundColor: htmlWhite,
                }}
            />
        </Flex>
    );
};


export class SecondOpinion extends Component {

    handleSubIndicationChange = (event, value) => {
        this.props.updateCase({
            subIndications: value
        })
    };

    handleSecondOpinionUntilChange = date => {
        this.props.updateCase({
            secondOpinionUntil: date
        })
    };

    downloadFile = document => {
        this.props.downloadCaseFile(document);
    };

    render() {
        const {
            classes, t: translate, configuration, updateCase, save, handleCaseChange, user, confirmSecondOpinion,
            milestones, addNewPatientQuestionLog
        } = this.props;
        const {
            handleSubIndicationChange, handleSecondOpinionUntilChange, downloadFile
        } = this;
        const {
            diseaseDetails, secondOpinionUntil, furtherTherapy, additionalInformation, creationDate,
        } = this.props.case;
        const secondOpinionDetails = this.props.case.secondOpinionDetails || EMPTY_ARRAY;
        const documents = this.props.case.documents || EMPTY_ARRAY;
        const disabled = isClinic(user);
        const agreementMilestone = milestones.find(ms => ms.id === DOCUMENTS_COMPLETED)
        const documentReadyDate = agreementMilestone?.date

        return (<Flex item container direction={'column'}>
            <Header {...{
                classes,
                translate,
                selectedIndication: this.props.case.indication,
                selectedSubIndications: this.props.case.subIndications || [],
                subIndications: configuration.subIndications,
                diseaseDetails,
                secondOpinionUntil,
                documentReadyDate,
                creationDate,
                handleSubIndicationChange,
                handleSecondOpinionUntilChange,
                handleCaseChange,
                disabled
            }}/>

            <Diagnosis {...{
                handleCaseChange, updateCase, _case: this.props.case, disabled
            }}/>

            <AdditionalInformation {...{
                classes, translate, additionalInformation, handleCaseChange, disabled
            }}/>

            <ICD10 {...{
                secondOpinionDetails,
                documents,
                downloadFile,
                disabled
            }}/>

            <Tnm {...{
                _case: this.props.case, handleCaseChange, disabled
            }}/>

            <Grading {...{
                secondOpinionDetails,
                documents,
                downloadFile,
                disabled
            }}/>

            <Pahtology {...{
                secondOpinionDetails,
                documents,
                downloadFile,
                disabled
            }}/>

            <Statement {...{
                secondOpinionDetails,
                documents,
                downloadFile,
                disabled
            }}/>

            <Laboratory {...{
                secondOpinionDetails,
                documents,
                downloadFile,
                disabled
            }}/>

            <AccompanyingDisease {...{
                secondOpinionDetails,
                documents,
                downloadFile,
                disabled
            }}/>

            <FormerTherapy {...{
                translate,
                secondOpinionDetails,
                documents,
                furtherTherapy,
                handleCaseChange,
                downloadFile,
                disabled
            }}/>

            <ReleaseSecondOpinion {...{
                confirmSecondOpinion,
                handleCaseChange,
                addNewPatientQuestionLog,
                updateCase,
                _case: this.props.case,
                disabled,
                milestones,
                user,
                save
            }} />
        </Flex>);
    }
}

export default withStyles(styles)(withTranslation()(SecondOpinion));
