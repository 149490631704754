export const SPECIAL_STEP_TYPE = Object.freeze({
    START: "start",
    END: "end",
    LATER: "later",
    CONFIRMATION: "confirmation"
});

export const medicationWalkthroughSteps = [
    {
        title: "medication-plan",
        selector: "[walkthrough-element=\"add-medication-btn\"]",
        positionX: "bottom",
        positionY: "left",
        offsetX: -130,
        offsetY: 55,
        type: SPECIAL_STEP_TYPE.CONFIRMATION
    }
    ];
