import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogTitle, withStyles} from "@material-ui/core";
import {Trans, withTranslation} from "react-i18next";
import DialogContent from "@material-ui/core/DialogContent";
import Flex from "components/grid/Flex";
import Typography from "@material-ui/core/Typography";
import {withTheme} from "@material-ui/core/styles";

export const styles = theme => ({
    dialogContainer: {
        minHeight: 50
    }
});

class ConfirmationDialog extends Component {

    render() {
        const {
            dialogOpen, onDialogClose, onConfirm, confirmationTextKey, title, theme, confirmationTextValues, children,
            classes, confirmI18NKey, cancelI18NKey, singleConfirmationButton, titleStyles, confirmationDisabled,
            alternativeConfirmLabel, alternativeCancelLabel
        } = this.props;
        return <Dialog open={!!dialogOpen}>

            <DialogTitle style={{paddingBottom: '2px', color: theme.palette.secondary.main}}
                         id="form-dialog-title">
                {
                    title
                            ? title
                            : <Trans i18nKey={"global.attention"}/>
                }
            </DialogTitle>

            <DialogContent>
                <Flex container item grow={1} shrink={0} className={classes.dialogContainer}>
                    <Typography style={titleStyles}>
                        <Trans i18nKey={confirmationTextKey} values={confirmationTextValues}/>
                    </Typography>
                </Flex>
                {children}
            </DialogContent>
            {
                singleConfirmationButton
                        ? <DialogActions>
                            <Button onClick={(event) => onConfirm(event)}
                                    color="primary"
                                    variant="contained"
                                    autoFocus>
                                <Trans i18nKey={confirmI18NKey || alternativeConfirmLabel || "global.ok"}/>
                            </Button>
                        </DialogActions>
                        : <DialogActions>
                            <Button onClick={(event) => onDialogClose(event)}
                                    color="primary"
                                    size={'small'}
                                    variant="outlined">
                                <Trans i18nKey={cancelI18NKey || alternativeCancelLabel || "global.no"}/>
                            </Button>
                            <Button onClick={(event) => onConfirm(event)}
                                    variant="contained"
                                    autoFocus
                                    size={'small'}
                                    color="secondary"
                                    disabled={confirmationDisabled}
                            >
                                <Trans i18nKey={confirmI18NKey || alternativeConfirmLabel || "global.yes"}/>
                            </Button>
                        </DialogActions>
            }
        </Dialog>;
    }
}

export default withTheme(withStyles(styles)(withTranslation()(ConfirmationDialog)));
