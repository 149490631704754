import React, {Component} from 'react';
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import connector from './ProgramTemplateManager.connect';
import {Button, IconButton} from '@material-ui/core';
import ProgramTemplateEditor from '../component/ProgramTemplateEditor';
import Title from 'scenes/managed-care/component/Title';
import ProgramRow from './ProgramTemplateRow';
import ProgramHeader from './ProgramTemplateHeader';
import {FixedSizeList} from 'react-window';
import {useResizeDetector} from 'react-resize-detector';
import HmoInputWithAdornment from 'components/input/HmoInputWithAdornment';
import {Search, CloseOutlined as CloseIcon} from "@material-ui/icons";
import {stringGet} from 'utils/Utils';
import * as colors from 'components/colors/Colors';
import utils from 'utils/Utils';
import _ from 'lodash';
import Sidebar from 'components/sidebar/Sidebar';
import {isCaseManager, isManager} from 'domain/User.model';
import {withNavigation} from 'utils/Route.utils';
import {MANAGED_CARE} from 'routes/routes';
import update from 'immutability-helper';
import EpiAlert from 'components/alert/EpiAlert';
import NewProgramTemplateDialog from '../component/NewProgramTemplateDialog';
import {styled} from '@material-ui/core/styles';
import { DeleteProgramTemplateConfirmationModal } from '../component/DeleteProgramTemplateConfirmationModal';

const SidebarPlaceholder = styled((props) => <Flex item container column {...props} />)(({theme}) => ({
    position: 'fixed',
    backgroundColor: colors.sideBarColor,
    height: '100vh',
    top: 0,
    left: 0,
    width: 60,
    maxWidth: 60,
    zIndex: 7,
}));

const Filters = (props) => {
    const {translate, toggleNewProgramTemplateDialog, positiveSearch, stateChange, isLoading, user} = props;
    return (
            <>
                <Flex item={'0 1 auto'} container justifyContent={'space-between'} style={{
                    marginBottom: 10,
                    marginTop: 10,
                    marginLeft: 70,
                    marginRight: 10,
                    padding: 5
                }}>
                    <Flex item container alignItems={'center'}>
                        <HmoInputWithAdornment
                                value={positiveSearch}
                                placeholder={translate('global.search')}
                                onChange={stateChange}
                                name={'positiveSearch'}
                                rootStyle={{
                                    padding: 0,
                                    marginRight: 15,
                                    paddingLeft: 10,
                                    width: 420,
                                    border: '1px solid #859EC2',
                                    backgroundColor: 'unset'
                                }}
                                startAdornment={<Search/>}
                                endAdornment={
                                    !_.isEmpty('') &&
                                    <IconButton
                                            style={{
                                                padding: 3,
                                                backgroundColor: colors.silver,
                                                fontSize: 13,
                                                marginRight: 3
                                            }}
                                            onClick={() => {
                                            }}>
                                        <CloseIcon style={{color: colors.concrete, fontSize: 'inherit'}}/>
                                    </IconButton>
                                }
                        />
                    </Flex>
                    {isCaseManager(user) &&
                    <Button data-testid="add-new-program-template-btn" onClick={toggleNewProgramTemplateDialog} disabled={isLoading} style={{
                        backgroundColor: '#245B67', textTransform: 'capitalize', padding: '0px 30px',
                        color: colors.htmlWhite, maxHeight: 37, borderRadius: 10, height: 37,
                    }} size={'small'}>
                        + {translate('global.add-new-program-template')}
                    </Button>
                    }
                </Flex>
            </>
    );
};

function ProgramListWindow(props) {
    const {width, height, ref} = useResizeDetector();
    const {programs, selectProgram} = props;
    return <div ref={ref} role="list" data-testid="program-template-window" style={{
        height: '98%',
        marginLeft: 70,
        marginRight: 10,
    }}>
        <FixedSizeList
                height={height || 300}
                itemCount={programs?.length}
                itemSize={40}
                width={width || 500}
                itemData={{rows: programs}}
        >
            {({index, style}) => (
                    <div key={index} style={style}>
                        <ProgramRow  {...{
                            program: programs[index],
                            selectProgram,
                        }}/>
                    </div>
            )}
        </FixedSizeList>
    </div>;
}

export class ProgramTemplateManager extends Component {

    state = {
        alert: '',
        sortParameter: 'name',
        reverseSort: 'false',
        positiveSearch: '',
        selectedProgram: {},
        isDeleteDialogOpen: false,
        isLoading: false,
        isNewProgramTemplateDialogOpen: false,
        editableId: null
    }

    toggleNewProgramTemplateDialog = () => {
        this.setState({isNewProgramTemplateDialogOpen: !this.state.isNewProgramTemplateDialogOpen});
    }

    closeSnackbar = () => {
        this.setState({alert: ''});
    }

    componentDidMount() {
        this.props.getProgramTemplates();
    }

    onSort = sortParameter => event => {
        this.setState({
            sortParameter,
            reverseSort: this.state.sortParameter === sortParameter ? !this.state.reverseSort : false
        });
    };

    freeTextFilter = program => {
        return this.state.positiveSearch === ''
            || stringGet(program, 'name', '').toLowerCase().includes(this.state.positiveSearch.toLowerCase())
            || stringGet(program, 'shortName', '').toLowerCase().includes(this.state.positiveSearch.toLowerCase());
    };

    stateChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    selectProgram = program => event => {
        this.setState({selectedProgram: program});
    }

    programChange = event => {
        this.setState({
            selectedProgram: update(this.state.selectedProgram, {
                [event.target.name]: {$set: event.target.value}
            })
        }, () => {
            this.delayedSave()
        });
    }

    programSet = (newProgram, cb) => {
        this.setState({selectedProgram: newProgram}, cb)
    }

    reorderSteps = (result) => {
        if (!result.destination) return;
        const {steps, stepsOrder} = this.state.selectedProgram;
        const items = _.sortBy(steps, item => stepsOrder.map(Number).indexOf(item.id)).map(item => item.id);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        this.setState({
            selectedProgram: update(this.state.selectedProgram, {
                stepsOrder: {
                    $set: items
                }
            })
        }, () => {
            this.delayedSave()
        });
    }

    setStepInEditableMode = (id) => {
        this.setState({editableId: id});
    }

    addStep = (position) => {
        this.delayedSave.cancel();
        this.props.newProgramTemplateStep(this.state.selectedProgram.id, position).then(response => {
            const data = response?.payload?.data;
            const stepsOrder = data.stepsOrder;
            const steps = data?.steps;
            const found = steps.find((x) => x.id === Number(stepsOrder[position ?? 0]));
            this.setState({
                selectedProgram: response?.payload?.data,
                editableId: found?.id || null
            })
        });
    }

    stepChange = (step, cb) => {
        const index = this.state.selectedProgram.steps.findIndex(_step => _step.id === step.id);
        if (index > -1) {
            this.setState({
                selectedProgram: update(this.state.selectedProgram, {
                    steps: {
                        [index]: {
                            $set: step
                        }
                    }
                })
            }, () => {
                cb && cb();
            });
        }
    }

    toggleProgramSuspendedStatus = () => {
        this.setState({
            selectedProgram: update(this.state.selectedProgram, {
                suspended: {
                    $set: !this.state.selectedProgram.suspended
                }
            })
        }, () => {
            this.save()
        });
    }

    deleteStep = id => {
        const index = this.state.selectedProgram.steps.findIndex(_step => _step.id === id);
        const stepOrderIndex = this.state.selectedProgram.stepsOrder.findIndex(_id => Number(_id) === id);
        if (index > -1) {
            this.setState({
                selectedProgram: update(this.state.selectedProgram, {
                    steps: {
                        $splice: [[index, 1]]
                    },
                    stepsOrder:
                            stepOrderIndex > -1
                                    ? {$splice: [[stepOrderIndex, 1]]}
                                    : {$set: this.state.selectedProgram.stepsOrder}
                })
            }, () => {
                if (this.props.programTemplates.findIndex(program => this.state.selectedProgram.id === program.id) > -1) {
                    this.save();
                }
            });
        }

    }

    saveStep = (changedStep) => {
        this.delayedSave.cancel();
        this.stepChange(changedStep, this.save);
    }

    save = () => {
        return this.props.saveProgramTemplate(this.state.selectedProgram)
                .then(response => {
                    this.props.getProgramTemplates();
                    this.setState({
                        alert: 'success',
                        editableId: null
                    });
                    return response;
                })
                .catch(error => {
                    this.setState({
                        alert: 'error',
                        editableId: null
                    });
                    return error;
                });
    }

    delayedSave = _.debounce(this.save, 500);

    componentWillUnmount() {
        if (!this.state.isDeleteDialogOpen) {
            this.delayedSave.flush();
        }
    }

    reset = () => {
        this.setState({selectedProgram: {}}, () => {
            this.props.getProgramTemplates()
        });
    }

    toggleDeleteDialog = () => {
        this.setState({isDeleteDialogOpen: !this.state.isDeleteDialogOpen});
    }

    deleteProgram = (deleteOptions) => {
        this.delayedSave.cancel();
        this.props.deleteProgramTemplate(this.state.selectedProgram.id, deleteOptions).then(() => {
            this.reset();
        })
    }

    render() {
        const {t: translate, programTemplates, user, deleteProgramTemplate} = this.props;
        const {
            onSort, freeTextFilter, stateChange, selectProgram, programChange, programSet, stepChange,
            toggleProgramSuspendedStatus, addStep, deleteStep, reorderSteps, reset, toggleDeleteDialog, save,
            deleteProgram, toggleNewProgramTemplateDialog, setStepInEditableMode, saveStep
        } = this;
        const {
            sortParameter, reverseSort, selectedProgram, positiveSearch, isDeleteDialogOpen, isLoading,
            isNewProgramTemplateDialogOpen, editableId,
        } = this.state;

        const filteredPrograms = programTemplates
                .filter(freeTextFilter)
                .sort(utils.by({sortParameter, reverseSort}));

        return (
                <>
                    {
                        isManager(user) ? <Sidebar/> : <SidebarPlaceholder/>
                    }
                    <Flex item container column style={{height: '100vh'}}>
                        {
                            _.isEmpty(selectedProgram)
                                    ? <>
                                        <Title title={translate(_.isEmpty(selectedProgram)
                                                ? "global.program-management"
                                                : "global.program"
                                        )}
                                               user={user}
                                               onBack={() => this.props.navigate(MANAGED_CARE.path)}
                                               style={{marginLeft: 60}}
                                        />
                                        <Filters {...{
                                            translate,
                                            toggleNewProgramTemplateDialog,
                                            positiveSearch,
                                            stateChange,
                                            isLoading,
                                            user
                                        }}/>
                                        <ProgramHeader {...{
                                            onSort, sortParameter, reverseSort
                                        }}/>
                                        <ProgramListWindow {...{
                                            programs: filteredPrograms,
                                            selectProgram
                                        }}/>
                                    </>
                                    : <ProgramTemplateEditor {...{
                                        program: selectedProgram,
                                        programChange,
                                        deleteProgram: deleteProgramTemplate,
                                        style: {marginLeft: 60},
                                        user,
                                        toggleDeleteDialog,
                                        programSet, save, toggleProgramSuspendedStatus,
                                        stepChange, addStep, deleteStep, reorderSteps, reset, editableId,
                                        setStepInEditableMode, saveStep
                                    }}/>
                        }
                    </Flex>
                    <EpiAlert
                            {...{
                                isOpen: !!this.state.alert,
                                close: this.closeSnackbar,
                                severity: this.state.alert === 'error' ? this.state.alert : 'success',
                                message:
                                        this.state.alert === 'error'
                                                ? translate('global.backend-call-failed')
                                                : this.state.alert
                                                        ? translate(`global.${this.state.alert}`)
                                                        : '',
                            }}
                    />

                    <DeleteProgramTemplateConfirmationModal isOpen={isDeleteDialogOpen} onClose={toggleDeleteDialog}
                    templateId={this.state.selectedProgram.id} templateName={this.state.selectedProgram.name} onDelete={(deleteOptions) => {
                        toggleDeleteDialog();
                        deleteProgram(deleteOptions);
                    }}
                    />

                    {
                        isNewProgramTemplateDialogOpen &&
                        <NewProgramTemplateDialog
                                selectProgram={selectProgram}
                                toggleNewProgramTemplateDialog={toggleNewProgramTemplateDialog}/>
                    }
                </>

        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(
        withTranslation()(
                withNavigation(ProgramTemplateManager)
        )
);
