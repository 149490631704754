import React, {Component} from 'react';
import {withStyles, InputBase} from "@material-ui/core";
import {concrete, grey} from 'components/colors/Colors';
import _ from 'lodash';

export const styles = theme => ({
    root: {
        width: '100%',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        padding: '8px 10px 8px 10px',
        borderRadius: 4,
        position: 'relative',
        backgroundColor: concrete,
        border: 'unset',
        maxHeight: 37
    },
    startAdornment: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 16,
        paddingRight: 5,
        color: grey
    },
    endAdornment: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 16,
        paddingRight: 5,
        color: grey
    },
});

export class HmoInputWithAdornment extends Component {

    selectAllOnFocus = event => {
        event.target.select && event.target.select();
    };

    handleKeyPress = (onEnterKey, onKeyPress) => event => {
        const isEnterPressed = (event.key && event.key === 'Enter') || (event.keyCode && event.keyCode === 13);
        const isShiftPressed = event.shiftKey;
        if (isEnterPressed && !isShiftPressed){
            onEnterKey && onEnterKey(event);
        } else {
            onKeyPress && onKeyPress(event);
        }
    };

    render() {
        const {
            bold, center, inputProps, onEnterKey, onKeyPress, onFocus, classes, startAdornment, backgroundColor, border,
            startAdornmentStyle, rootStyle, endAdornment, endAdornmentStyle, ...otherProps
        } = this.props;
        return (
            <InputBase
                classes={{
                    root: classes.root,
                    input: classes.input,
                }}
                style={{
                    backgroundColor,
                    border,
                    ...rootStyle,
                }}
                inputProps={{
                    style: {
                        ..._.get(inputProps, 'style'),
                        fontWeight: bold ? 'bold' : 'normal',
                        textAlign: center ? 'center' : 'left',
                    },
                    ...inputProps
                }}
                onKeyPress={this.handleKeyPress(onEnterKey, onKeyPress)}
                onFocus={onFocus ? onFocus : this.selectAllOnFocus}
                startAdornment = {
                    startAdornment &&
                    <div className={classes.startAdornment} style={startAdornmentStyle}>
                        {startAdornment}
                    </div>
                }
                endAdornment = {
                    endAdornment &&
                    <div className={classes.endAdornment} style={endAdornmentStyle}>
                        {endAdornment}
                    </div>
                }
                {...otherProps}
            />
        );
    }
}

export default withStyles(styles)(HmoInputWithAdornment);
