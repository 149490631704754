import React, {Component} from 'react';
import {htmlWhite, sideBarColor} from 'components/colors/Colors';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import {isCaseManager} from "domain/User.model";

export const Hmo_Theme = {
    palette: {
        background: {
            default: htmlWhite
        },
        sidebarColor: sideBarColor
    },

    overrides: {
        MuiButtonBase:{
            root:{
                textTransform: 'unset'
            }
        },
        MuiTypography: {
            root: {
                cursor: 'default',
                fontSize: 16
            },
            body1: {
                fontSize: 16
            },
            h1: {
                fontWeight: 600,
                lineHeight: '41.15px',
                fontSize: '34px',
                fontStyle: 'normal',
                color: '#030303'
            },
            h2: {
                fontWeight: 600,
                lineHeight: '41.15px',
                fontSize: '28px',
                fontStyle: 'normal',
                color: '#030303'
            },
            h3: {
                fontWeight: 600,
                lineHeight: '26px',
                fontSize: '22px',
                fontStyle: 'normal',
                color: '#030303'
            },
            h4: {
                fontWeight: 600,
                lineHeight: '22px',
                fontSize: '18px',
                fontStyle: 'normal',
                color: '#030303'
            },
        },
    }
};

export const Menu = {

    marginLeft : user => ({ marginLeft: isCaseManager(user) ? 60 : 0 })
}

class HmoTheme extends Component {
    render() {
        return (
            <ThemeProvider theme={createTheme(Hmo_Theme)}>
                {this.props.children}
            </ThemeProvider>
        );
    }
}

export default HmoTheme;
