import { MenuItem, Select, FormControl } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

export const ExcludeToggle = ({ value, onChange }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <FormControl size="small">
            <Select style={{'color': value ? theme.palette.error.main: ''}} value={value} onChange={onChange}>
                <MenuItem value={false}>{t('cohort.include-match')}</MenuItem>
                <MenuItem value={true}>{t('cohort.exclude-match')}</MenuItem>
            </Select>
        </FormControl>
    );
};
