import Flex from 'components/grid/Flex';
import {Typography, Checkbox} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import {transparentBlack054} from 'components/colors/Colors';
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {useGetAccessesOfferedQuery} from 'scenes/user-manager/UserDetailAccessApi';
import {useState, useEffect} from "react";
import update from 'immutability-helper';
import {CLINIC, DOCTOR, ASSISTANT, CASE_MANAGER} from 'domain/User.model';
import {useSelector} from 'react-redux';
import _ from 'lodash';

function mapDescription(description, translate) {
    let updatedDescription = _.cloneDeep(description);
    ['CANCER', 'HEART', 'ORTHOPEDICS', 'FULL_PATIENT'].forEach(key => {
        updatedDescription = updatedDescription?.replaceAll(key, translate(`global.${key}`));
    })
    return updatedDescription;
}

function mapAccessesOffered(accessesOffered, translate) {
    return accessesOffered?.map(access => ({
        ...access,
        selected: true,
        description: mapDescription(access.description, translate)
    }))
}

function getInitialAccesses({invitedRoleType, user, translate, entityId}) {
    switch (invitedRoleType) {
        case CLINIC:
            return [{
                selected: true,
                description: translate('global.clinic-center-access'),
                patronUserId: user?.id,
                patronRoleType: CASE_MANAGER,
                entityType: 'CLINIC_CENTER',
                entityId
            }];
        case DOCTOR:
        case ASSISTANT:
            return [{
                selected: true,
                description: translate('global.care-provider-access'),
                patronUserId: user?.id,
                patronRoleType: CASE_MANAGER,
                entityType: 'CARE_PROVIDER',
                entityId
            }];
        default:
            return [];
    }
}

const AccessRow = (props) => {
    const {disabled, onChange, title, checked, style} = props;
    return (
            <Flex item={`0 0 25px`} container alignItems={'center'} style={style}>
                <Checkbox
                        style={{padding: 2, marginRight: 5}}
                        checked={checked}
                        onChange={onChange}
                        disabled={disabled}
                        checkedIcon={<CheckBoxIcon/>}
                />
                <Typography>{title}</Typography>
            </Flex>
    );
};

const AccessInputSelector = props => {
    const {invitedRoleType, patientId, personUserDetailsId, setSelectedAccesses, entityId} = props;
    const {t: translate} = useTranslation();
    const user = useSelector(state => state.mainReducer.user);
    const [possibleAccesses, setPossibleAccesses] = useState(getInitialAccesses({
        invitedRoleType, user, translate, entityId
    }));
    useEffect(() => {
        setSelectedAccesses(possibleAccesses?.filter(access => access.selected));
    }, [])

    const {
        data: accessesOffered,
    } = useGetAccessesOfferedQuery({
        patientId,
        personUserDetailsId,
        role: invitedRoleType
    }, {skip: [CLINIC, DOCTOR, ASSISTANT].includes(invitedRoleType)});
    useEffect(() => {
        if (accessesOffered) {
            const accesses = mapAccessesOffered(accessesOffered, translate);
            setPossibleAccesses(accesses)
            setSelectedAccesses(accesses?.filter(access => access.selected));
        }
    }, [accessesOffered])

    const toggleAccess = index => event => {
        const isFullPatientAccess = possibleAccesses[index].entityType === 'FULL_PATIENT';
        const isFullPatientJustTurnedOn = isFullPatientAccess && !possibleAccesses[index];
        let selectedAccesses;
        if (isFullPatientJustTurnedOn) {
            selectedAccesses = possibleAccesses.map(access => ({
                ...access,
                selected: access.entityType !== 'FULL_PATIENT'
            }));
        } else {
            selectedAccesses = update(possibleAccesses, {
                [index]: {
                    selected: {$set: !possibleAccesses[index].selected}
                }
            });
        }
        setPossibleAccesses(selectedAccesses);
        setSelectedAccesses(selectedAccesses?.filter(access => access.selected));
    };
    const isFullPatientSelected = possibleAccesses?.find(access => access.entityType === 'FULL_PATIENT')?.selected;
    return possibleAccesses?.length > 1
            ? <Flex item container style={{margin: '10px 0px'}} column>
                <Typography style={{
                    color: transparentBlack054,
                    fontSize: 12
                }}>{translate('global.invite-select-access')}</Typography>
                <Flex item container column padding={10}
                      style={{backgroundColor: 'white', borderRadius: 6}}>
                    {
                        possibleAccesses?.map((access, index) => {
                            const isFullPatientAccess = access.entityType === 'FULL_PATIENT';

                            return <AccessRow key={index} {...{
                                disabled: invitedRoleType === 'PATIENT'
                                        || (!isFullPatientAccess && isFullPatientSelected),
                                title: access.description,
                                checked: access.selected,
                                onChange: toggleAccess(index)
                            }}/>
                        })
                    }
                </Flex>
            </Flex>
            : <></>;
};

export default AccessInputSelector;
