import React, {PureComponent} from 'react';
import {IconButton, Typography, withStyles,} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {iceberg, jaffa} from 'components/colors/Colors';
import {withTranslation} from 'react-i18next';
import _ from 'lodash';
import {AddBox as AddIcon} from '@material-ui/icons';
import SecondOpinionDetailDialog from './second-opinion-dialog/SecondOpinionDetailDialog';
import HmoInputLabel from 'components/input-label/HmoInputLabel';
import ConfirmationDialog from 'components/confirmation-dialog/ConfirmationDialog';
import SecondOpinionDetailsRow from './SecondOpinionDetailsRow';
import SortIcon from 'components/sort-icon/SortIcon';
import {connect} from 'react-redux';
import connector from './SecondOpinionDetails.connect';
import {DETAIL_TYPE_FIELDS} from './DetailsType.model';

const styles = theme => ({
    title: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: 'black',
        fontSize: 15
    },
    cell: {
        padding: 2,
        alignItems: 'flex-start'
    },
    rowContainer: {
        backgroundColor: 'white',
        margin: 2,
        padding: 5,
        boxShadow: `0px 0px 8px #0000001A`,
        borderRadius: 4
    }
});

const HeaderCells = props => {
    const {classes, translate, detailType, sortBy, sortParameter, reverseSort} = props;
    return (
            <Flex item container style={{
                borderRadius: '5px 5px 0 0', marginBottom: 5, marginTop: detailType === 'FORMER_THERAPY' ? 5 : 0,
                paddingLeft: 7
            }}>
                <Flex item={`0 0 30px`} container className={classes.cell}/>
                {
                    DETAIL_TYPE_FIELDS[detailType].includes('from') &&
                    <Flex item={`0 0 100px`} container className={classes.cell} onClick={sortBy('from')}>
                        <HmoInputLabel>
                            {DETAIL_TYPE_FIELDS[detailType].includes('to') ? translate('global.from') : translate('global.date')}
                        </HmoInputLabel>
                        <SortIcon isShown={sortParameter === 'from'} reverse={reverseSort}/>
                    </Flex>
                }

                {
                    DETAIL_TYPE_FIELDS[detailType].includes('to') &&
                    <Flex item={`0 0 100px`} container className={classes.cell} onClick={sortBy('to')}>
                        <HmoInputLabel>
                            {translate('global.to')}
                        </HmoInputLabel>
                        <SortIcon isShown={sortParameter === 'to'} reverse={reverseSort}/>
                    </Flex>
                }

                {
                    DETAIL_TYPE_FIELDS[detailType].includes('code') &&
                    <Flex item={`0 0 120px`} container className={classes.cell} onClick={sortBy('code')}>
                        <HmoInputLabel>
                            {DETAIL_TYPE_FIELDS[detailType].includes('type') ? translate('case.type') : translate('case.code')}
                        </HmoInputLabel>
                        <SortIcon isShown={sortParameter === 'code'} reverse={reverseSort}/>
                    </Flex>
                }

                {
                    DETAIL_TYPE_FIELDS[detailType].includes('freeText') &&
                    <Flex item={`0 0 10%`} container className={classes.cell} onClick={sortBy('freeText')}>
                        <HmoInputLabel>
                            {translate(`case.freeText.${detailType}`)}
                        </HmoInputLabel>
                        <SortIcon isShown={sortParameter === 'freeText'} reverse={reverseSort}/>
                    </Flex>
                }

                <Flex item container className={classes.cell} onClick={sortBy('content')}>
                    <HmoInputLabel>
                        {translate('case.description')}
                    </HmoInputLabel>
                    <SortIcon isShown={sortParameter === 'content'} reverse={reverseSort}/>
                </Flex>

                <Flex item={`0 0 200px`} container className={classes.cell}>
                    <HmoInputLabel>
                        {translate('case.documents')}
                    </HmoInputLabel>
                </Flex>
            </Flex>
    );
};

const Rows = (props) => {
    const {
        classes, translate, secondOpinionDetails, documents, openEditDialogFor, openDeleteDialogFor, disabled
    } = props;
    return _.isEmpty(secondOpinionDetails)
            ?
            <Flex item container center column fullWidth className={classes.rowContainer} {...{
                ...(!disabled && {onClick: openEditDialogFor({})})
            }}>
                <HmoInputLabel>{translate('case.no-entry')}</HmoInputLabel>
                <HmoInputLabel>+ {translate('case.add-first-entry')}</HmoInputLabel>
            </Flex>
            :
            secondOpinionDetails.map(secondOpinionDetail => <SecondOpinionDetailsRow
                    key={secondOpinionDetail.businessId}
                    {...{
                        secondOpinionDetail,
                        documents,
                        openEditDialogFor,
                        openDeleteDialogFor,
                        disabled
                    }}/>)
};

export class SecondOpinionDetails extends PureComponent {

    state = {
        selectedSecondOpinionDetail: undefined,
        secondOpinionDetailToDelete: '',
        sortParameter: '',
        reverseSort: false
    };

    componentDidMount() {
        const sortParameter = DETAIL_TYPE_FIELDS[this.props.detailType].includes('from')
                ? 'from' : DETAIL_TYPE_FIELDS[this.props.detailType].includes('code')
                        ? 'code' : DETAIL_TYPE_FIELDS[this.props.detailType].includes('freeText')
                                ? 'freeText' : '';
        this.setState({
            sortParameter,
            reverseSort: sortParameter === 'from'
        });
    }

    openEditDialogFor = selectedSecondOpinionDetail => event => {
        this.setState({
            selectedSecondOpinionDetail
        })
    };

    closeEditDialog = () => {
        this.setState({
            selectedSecondOpinionDetail: undefined
        })
    };

    openDeleteDialogFor = secondOpinionDetailToDelete => event => {
        event.stopPropagation();
        event.preventDefault();
        this.setState({
            secondOpinionDetailToDelete
        })
    };

    closeDeleteDialog = () => {
        this.setState({
            secondOpinionDetailToDelete: ''
        })
    };

    deleteSelectedSecondOpinionDetail = () => {
        this.props.deleteSecondOpinionDetail(this.state.secondOpinionDetailToDelete.businessId,
                this.props.caseBusinessId);
        this.closeDeleteDialog();
    };

    sortBy = sortParameter => () => {
        this.setState({
            sortParameter,
            reverseSort: this.state.sortParameter === sortParameter && !this.state.reverseSort
        })
    }

    byProperty = (a, b) => {
        const sortParameter = this.state.sortParameter;
        const reverseSort = this.state.reverseSort;
        if (_.get(a, sortParameter) > _.get(b, sortParameter)) {
            return reverseSort ? 1 : -1;
        } else if (_.get(a, sortParameter) < _.get(b, sortParameter)) {
            return reverseSort ? -1 : 1;
        } else {
            return 0;
        }
    }

    render() {
        const { classes, t: translate, secondOpinionDetails, documents, detailType, types, disabled } = this.props;
        const {selectedSecondOpinionDetail, secondOpinionDetailToDelete, sortParameter, reverseSort} = this.state;
        const {
            openEditDialogFor, closeEditDialog, openDeleteDialogFor, closeDeleteDialog, sortBy, byProperty,
            deleteSelectedSecondOpinionDetail
        } = this;
        const filteredSecondOpinionDetails = secondOpinionDetails
                .filter(detail => detail.detailType === detailType)
                .sort(byProperty);

        return (
                <Flex item container direction={'column'}
                      style={{
                          backgroundColor: iceberg,
                          padding: 15,
                          margin: 10,
                          marginBottom: detailType === 'FORMER_THERAPY' ? 0 : 10,
                          borderRadius: detailType === 'FORMER_THERAPY' ? '4px 4px 0 0' : 4
                      }}
                >
                    <Flex item container justifyContent={'space-between'} alignItems={'center'}
                          style={{
                              ...(detailType === 'FORMER_THERAPY' && {
                                  backgroundColor: jaffa,
                                  margin: -15,
                                  padding: 15,
                                  borderRadius: '4px 4px 0 0'
                              })
                          }}>
                        <Typography className={classes.title} {...{
                            ...(detailType === 'FORMER_THERAPY' && {style: {color: 'white'}})
                        }}>
                            {translate(`case.detail-type.${detailType}`)}
                        </Typography>
                        <IconButton onClick={openEditDialogFor({})} style={{padding: 3}} disabled={disabled}>
                            <AddIcon {...{
                                ...(detailType === 'FORMER_THERAPY' && {style: {fill: 'white'}})
                            }}/>
                        </IconButton>
                    </Flex>
                    <Flex item container column style={{
                        ...(detailType === 'FORMER_THERAPY' && {marginTop: 15})
                    }}>

                        <HeaderCells {...{
                            classes, detailType, translate, sortBy, sortParameter, reverseSort
                        }}/>
                        <Rows {...{
                            classes,
                            translate,
                            secondOpinionDetails: filteredSecondOpinionDetails,
                            documents,
                            openEditDialogFor,
                            openDeleteDialogFor,
                            disabled
                        }}/>
                        <SecondOpinionDetailDialog {...{
                            detailType,
                            types: types,
                            documents: documents.filter(doc => doc.documentData.type !== 'TNE'),
                            open: selectedSecondOpinionDetail !== undefined,
                            selectedSecondOpinionDetail,
                            closeEditDialog,
                        }}/>
                    </Flex>
                    <ConfirmationDialog {...{
                        dialogOpen: !_.isEmpty(secondOpinionDetailToDelete),
                        onDialogClose: closeDeleteDialog,
                        onConfirm: deleteSelectedSecondOpinionDetail,
                        confirmationTextKey: "case.delete-second-opinion-dialog"
                    }}/>
                </Flex>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(
        withTranslation()(SecondOpinionDetails))
);
