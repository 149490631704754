import React, {Component} from 'react';
import {
    withStyles,
    Button,
    Dialog,
    Typography,
    IconButton,
    CircularProgress,
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {
    htmlRed,
    grey,
} from 'components/colors/Colors';
import {withTranslation} from 'react-i18next';
import {
    Delete as DeleteIcon
} from "@material-ui/icons";
import Title from 'components/title/Title';
import HmoFilledButton from 'components/button/HmoFilledButton';
import {withRouter} from 'utils/Route.utils';

const styles = () => ({
    dialogText: {
        fontSize: 15
    },
    cancelButton: {
        fontSize: 13,
        textTransform: 'unset',
        color: '#D94141'
    },
});

export class DeleteEmptyCase extends Component {

    state = {
        isLoading: false,
        open: false
    };


    toggleOpen = () => {
        this.setState({
            open: !this.state.open
        })
    };


    deleteEmptyCase = () => {
        this.setState({
            isLoading: true
        });
        this.props.deleteEmptyCase(this.props._case.businessId)
            .then(() => {
            })
            .catch(() => {
            })
            .then(() => {
                this.props.reset();
                this.props.navigate('/', {replace: true});
            })
    };

    render() {
        const {classes, t: translate, isCaseCanceled} = this.props;
        const { open, isLoading} = this.state;
        const {toggleOpen, deleteEmptyCase} = this;

        return (
            <>
                <IconButton style={{padding: 3}} onClick={toggleOpen}
                            disabled={isCaseCanceled}>
                    <DeleteIcon style={{fill: 'white'}}/>
                </IconButton>
                <Dialog open={open} onClose={toggleOpen} maxWidth={false}>
                    <Flex item container direction={'column'} padding={20}>
                        <Flex item container style={{paddingBottom: 10, borderBottom: `1px solid ${grey}`}}
                              alignItems={'center'} justifyContent={'space-between'}>
                            <Flex item container alignItems={'center'}>
                                <Title style={{color: htmlRed}}>{translate('case.delete-case')}</Title>
                            </Flex>
                            <Flex item container justifyContent={'flex-end'}>
                                <Button onClick={toggleOpen} className={classes.cancelButton}>
                                    {translate('global.cancel')}
                                </Button>
                                {
                                    isLoading
                                        ?
                                        <Flex item={'0 0 0'} container padding={3}>
                                            <CircularProgress/>
                                        </Flex>
                                        :
                                        <HmoFilledButton
                                            onClick={deleteEmptyCase} style={{backgroundColor: '#D94141'}}>
                                            <DeleteIcon style={{fill: 'white'}}/>
                                            {translate('case.delete-case')}
                                        </HmoFilledButton>
                                }
                            </Flex>
                        </Flex>
                        <Flex item container column>
                            <Flex item={'0 0 80px'} container style={{marginTop: 10}}>
                                <Typography className={classes.dialogText}>
                                {translate('case.delete-empty-case-text')}
                                </Typography>
                            </Flex>
                        </Flex>
                    </Flex>
                </Dialog>
            </>
        );
    }
}

export default withStyles(styles)(withTranslation()(withRouter(DeleteEmptyCase)));
