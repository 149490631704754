import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import {ProgramTemplateStepRenderer} from "./ProgramTemplateStepRenderer";

export class TemplateStep extends Component {
    state = {
        isDeleteDialogOpen: false,
    };

    toggleDeleteDialog = () => {
        this.setState({
            isDeleteDialogOpen: !this.state.isDeleteDialogOpen,
        });
    };

    deleteStep = () => {
        this.props.deleteStep(this.props.step.id);
    };

    cancelStep = () => {
        this.props.setEditableId(null);
    };

    render() {
        const {toggleDeleteDialog, deleteStep, cancelStep} = this;
        const {saveStep, setEditableId, editableId, index, step, user, otherStepsInProgram} = this.props;
        const {isDeleteDialogOpen} = this.state;

        return <>
            <ProgramTemplateStepRenderer {...{
                user,
                position: index + 1,
                step,
                toggleDelete: toggleDeleteDialog,
                dragHandleProps: {},
                editableId,
                setEditableId,
                cancelStep,
                saveStep,
                otherStepsInProgram,
            }} />

            <ConfirmationDialog
                    {...{
                        dialogOpen: isDeleteDialogOpen,
                        onDialogClose: toggleDeleteDialog,
                        onConfirm: deleteStep,
                        confirmationTextKey: "global.delete-step",
                    }}
            />
        </>
    }
}

export default withTranslation()(TemplateStep);
