import React, {Component} from 'react';
import {withStyles,} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {htmlBlack, htmlBlue, htmlWhite, lawngreen, sanMarinoBlue} from 'components/colors/Colors';
import HmoAvatar from 'components/avatar/HmoAvatar';

export const styles = theme => ({
    avatarTooltipLabel: {
        fontSize: 14
    },
    createIcon: {
        margin: -7,
        color: sanMarinoBlue
    },
    active: {
        height: 10,
        width: 10,
        marginTop: 3,
        marginLeft: 10,
        backgroundColor: lawngreen,
        borderColor: htmlBlack,
        borderStyle: 'solid',
        borderRadius: '50%',
        borderWidth: 0.5,
        display: 'inline-block'
    }
});

export class CaseManagerFilter extends Component {
    render() {
        const {
            classes,
            caseManagerSuggestions,
            selectedCaseManagerIds,
            toggleSelectedCaseManagerId,
            loggedInUsers
        } = this.props;
        return (
            <Flex container item={'1 0 auto'} justifyContent={'flex-end'}
                  style={{flexWrap: 'nowrap'}}>
                {
                    caseManagerSuggestions.length !== 0 &&
                    caseManagerSuggestions.map((caseManager, index) => (
                        <Flex item={'0 0 auto'} key={index} style={{position: 'relative'}}>
                            <Flex container item style={{marginLeft: -7}}>
                                <Flex container item direction={"column"}>
                                    <HmoAvatar
                                        {...{user: caseManager}}
                                        style={{
                                            margin: 0,
                                            border: selectedCaseManagerIds.includes(caseManager.businessId)
                                                ? `2px solid ${htmlBlue}`
                                                : `2px solid ${htmlWhite}`,
                                            zIndex: caseManagerSuggestions.length - index,
                                            width: 36,
                                            height: 36
                                        }}
                                        onClick={() => toggleSelectedCaseManagerId(caseManager.businessId)}
                                    />
                                    {
                                        (loggedInUsers.includes(caseManager.userId)) &&
                                        <span className={classes.active}/>
                                    }
                                </Flex>
                            </Flex>
                        </Flex>
                    ))
                }
            </Flex>
        );
    }
}

export default withStyles(styles)(CaseManagerFilter);
