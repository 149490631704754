import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, Box } from "@material-ui/core";
import { PossibleTimePeriods } from "domain/Program.model";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import {useState} from 'react';
import {EXACT} from 'domain/ProgramStepDateType.model';

export const EditReoccurringStepDialog = props => {
    const {isOpen, cancelStep, setIsEditReoccurringStepDialogOpen, saveStep} = props;
    const { t } = useTranslation();
    const theme  = useTheme();
    const [step, setStep] = useState(props.step);

    const onChange = event =>{
        setStep({
            ...step,
            [event.target.name]: event.target.value
        })
    }

    const onClose= () => {
        cancelStep();
        setIsEditReoccurringStepDialogOpen(false)
    };

    const onStop= () => {
        saveStep({
            ...step,
            dateType: EXACT,
            dateTimeSpan: null,
            dateLabelValue: null
        });
        setIsEditReoccurringStepDialogOpen(false);
    };

    const onSave= () => {
        saveStep(step);
        setIsEditReoccurringStepDialogOpen(false);
    };

    return <Dialog open={isOpen} maxWidth={'xs'} >
        <DialogTitle>
            {t('program.recurring-appointment')}</DialogTitle>
        <DialogContent>
            <div style={{marginBottom: theme.spacing(2), fontSize: '15px'}}>
                {t('program.recurring-appointment-modal-text')}
            </div>
            <div style={{marginBottom: theme.spacing(1), color: theme.palette.grey[600]}}>
                {t('global.description')}
            </div>
            <div style={{fontWeight: 'bold', marginBottom: theme.spacing(1)}}>{step.description}</div>
            <div style={{marginBottom: theme.spacing(1), color: theme.palette.grey[600]}}>
                {t('program.recurring-cycle')}
            </div>
            <Box display={'flex'} alignItems={'center'}>
                <div style={{marginRight: '10px'}}>
                    {t('global.all')}
                </div>
                        <TextField
                            type="number"
                            name="dateTimeSpanValue"
                            onChange={onChange}
                            value={step.dateTimeSpanValue ?? 1}
                            style={{ width: "40px", marginRight: "10px" }}
                        />
                        <TextField
                            onChange={onChange}
                            name="dateTimeSpan"
                            value={step.dateTimeSpan ?? "DAYS"}
                            select
                            style={{ marginRight: "10px" }}
                            >

                            {Object.entries(PossibleTimePeriods).map(([label, value]) => (
                                <MenuItem key={value} value={value}>
                                    {t(`timeSpans.${label.toLowerCase()}`)}
                                </MenuItem>
                            ))}
                            </TextField>
            </Box>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} variant="outlined" color="default">
                {t('global.cancel')}
            </Button>
            <Button onClick={onStop} variant="outlined" style={{ backgroundColor: theme.palette.error.main, color: "white" }}>
                {t('global.stop')}
            </Button>
            <Button onClick={onSave} variant="contained" color="primary">
                {t('global.apply')}
            </Button>
        </DialogActions>
    </Dialog>;
};
