import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import {withStyles} from "@material-ui/core/styles";

const styles = {
    root: {},
};

const StyledKeyboardDatePicker = withStyles(styles)(({label, date, classes, handleChange, name, disabled}) => {
    return (
            <KeyboardDatePicker
                    name={name}
                    className={classes.root}
                    variant="inline"
                    margin="none"
                    id="date-picker-inline"
                    autoOk={true}
                    label={label}
                    value={date}
                    disabled={disabled}
                    fullWidth
                    format="DD.MM.YYYY"
                    InputLabelProps={{ shrink: true }}
                    onChange={m => {
                        handleChange('dateOfBirth', m?.toISOString())
                    }}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
            />
    );
});

const StyledDatePicker = ({label, date, onChange, name, disabled}) => {
    return (
            <MuiPickersUtilsProvider utils={MomentUtils} locale="de">
                <StyledKeyboardDatePicker label={label} name={name} date={date} handleChange={onChange} disabled={disabled}/>
            </MuiPickersUtilsProvider>
    );
};

export default StyledDatePicker;
