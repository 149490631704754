import Flex from "components/grid/Flex";
import { Divider, Paper, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useFetchLaboratoryByPatientQuery } from "scenes/patient/laboratory/LaboratoryApi";
import { useFetchAnamnesisByPatientQuery } from "scenes/patient/anamnesis/AnamnesisApi";
import { useFetchPatientByIdQuery, useGetRiskQuery } from "scenes/patient/PatientApi";
import { missingAnswers } from "scenes/patient/my-risk/calculators/RiskProfile.mapper";
import HmoInputLabel from "components/input-label/HmoInputLabel";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import _ from "lodash";
import Skeleton from "@material-ui/lab/Skeleton";
import HematologyRisk from "./HematologyRisk";

const HealthTile = (props) => {
    const { percent, title } = props;
    return (
        <Flex
            item
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            style={{ margin: 5, maxHeight: 40, padding: "5px 10px", borderRadius: 6, border: "1px solid grey" }}
        >
            <Typography>{title}</Typography>
            <Typography style={{ color: "green" }}>-{_.round(percent, 0)}%</Typography>
        </Flex>
    );
};
const RiskProfileTooltip = (fullRiskPercent) => {
    const CustomTooltip = ({ payload }) => {
        const { t: translate } = useTranslation();
        let title;
        let value = _.round(payload[0]?.value, 0);
        switch (payload[0]?.payload.id) {
            case "fullRiskPercent":
                title = translate("my-risk.fullRiskPercent");
                value = _.round(fullRiskPercent, 0);
                break;
            case "patientRiskPercent":
                title = translate("my-risk.patientRiskPercent");
                break;
            case "empty":
                title = "";
                break;
            case "healthyRiskPercent":
                title = translate("my-risk.healthyRiskPercent");
                break;
            default:
                title = "";
        }

        return title ? <span style={{ fontSize: 14, fontWeight: "bold" }}>{title + ": " + value + "%"}</span> : "";
    };
    return CustomTooltip;
};

const RiskProfile = ({ translate, riskData, anamnesisData, isAnswersMissing }) => {
    const {
        patientRiskWithoutSmoking,
        patientRiskWithNormalBloodPressure,
        patientRiskWithHealthyLifeStyle,
        chipRisk,
        patientRiskPercent,
        fullRiskPercent,
    } = riskData;
    const healthyRisk = patientRiskWithHealthyLifeStyle * chipRisk;
    const healthyRiskPercent = Math.min(healthyRisk * 100, 100);
    const noSmokeRiskPercent = Math.min(patientRiskWithoutSmoking * chipRisk * 100, 100);
    const lowPressureRiskPercent = Math.min(patientRiskWithNormalBloodPressure * chipRisk * 100, 100);

    const fixedSmoking = fullRiskPercent - noSmokeRiskPercent;
    const fixedBloodPressure = fullRiskPercent - lowPressureRiskPercent;
    const fixedBoth = fullRiskPercent - healthyRiskPercent;
    const width = 340;
    const roundedFullRiskPercent = _.round(fullRiskPercent, 0);
    const Smoker = !!anamnesisData.smokeStatus;
    const firstPartOfBloodPressure = _.isString(anamnesisData.currentBloodPressure)
        ? anamnesisData.currentBloodPressure?.split("/")[0]
        : undefined;
    const BloodPressureSystolic = firstPartOfBloodPressure ? Number(firstPartOfBloodPressure) : false;
    const isHealthyRiskVisible = healthyRiskPercent < fullRiskPercent && (Smoker || BloodPressureSystolic > 120);
    return (
        <>
            <Flex item grow={0} container column style={{ marginBottom: isAnswersMissing ? 0 : 25, maxWidth: width }}>
                <Typography style={{ fontSize: 24, fontWeight: "bold", textAlign: "center" }}>
                    {translate("global.cardiovascular")}
                </Typography>
                <Flex item grow={0} container column fullWidth style={{ height: width, width }}>
                    <PieChart width={width} height={width}>
                        <text
                            x={"50%"}
                            y={"50%"}
                            textAnchor="middle"
                            dominantBaseline="middle"
                            style={{
                                fontSize: roundedFullRiskPercent === 100 ? width / 3.3 : width / 2.5,
                                fontWeight: "bold",
                            }}
                        >
                            {!roundedFullRiskPercent && (
                                    <tspan style={{ fontSize: width / 10, fill: "#d7d7d7" }}>
                                        {translate("my-risk.not-verifiable")}
                                    </tspan>
                            )}
                            {!!roundedFullRiskPercent && (
                                    <>
                                        {roundedFullRiskPercent}
                                        <tspan
                                                dy={roundedFullRiskPercent === 100 ? "0.35em" : "0.74em"}
                                                style={{ fontSize: width / 10, fontWeight: "normal" }}
                                        >
                                            %
                                        </tspan>
                                    </>
                            )}

                        </text>
                        <Pie
                            isAnimationActive={false}
                            data={
                                [
                                    { percent: 100 - fullRiskPercent },
                                    {
                                        percent:
                                            fullRiskPercent !== patientRiskPercent
                                                ? fullRiskPercent - patientRiskPercent
                                                : fullRiskPercent,
                                    },
                                    { percent: fullRiskPercent !== patientRiskPercent ? patientRiskPercent : 0 },
                                ]
                            }
                            dataKey="percent"
                            innerRadius="78%"
                            outerRadius="100%"
                            startAngle={-270}
                            paddingAngle={0}
                        >
                            <Cell id={"empty"} fill={"#f2f2f2"} />
                            <Cell id={"fullRiskPercent"} fill={"#ac0000"} />
                            {fullRiskPercent !== patientRiskPercent && (
                                <Cell id={"patientRiskPercent"} fill={"#e50000"} />
                            )}
                        </Pie>
                        {isHealthyRiskVisible && (
                            <Pie
                                isAnimationActive={false}
                                data={[{ percent: 100 - healthyRiskPercent }, { percent: healthyRiskPercent }]}
                                dataKey="percent"
                                innerRadius="70%"
                                outerRadius="80%"
                                startAngle={-270}
                                paddingAngle={0}
                            >
                                <Cell id={"empty"} fill={"white"} />
                                <Cell id={"healthyRiskPercent"} fill={"#83bb48"} />
                            </Pie>
                        )}

                        <Tooltip content={RiskProfileTooltip(fullRiskPercent)} position={{ x: 300, y: 0 }} />
                    </PieChart>
                </Flex>
                <Typography style={{ fontSize: 12, textAlign: "center" }}>
                    {translate("my-risk.provided-by")}
                    <a target={"_blank"} href={"https://herzfit.dev.digital-medicine.org/"}>
                        Deutsche Herzstiftung
                    </a>
                </Typography>
            </Flex>
            <Flex item grow={0} container column>
                <Typography style={{ fontWeight: "bold", fontSize: 16 }}>
                    {translate("my-risk.risk-text") + _.round(fullRiskPercent, 0) + "%"}
                </Typography>
                {(Smoker || BloodPressureSystolic > 120) &&
                (fixedSmoking > 0 || fixedBloodPressure > 0 || fixedBoth > 0) ? (
                    <>
                        <Divider style={{ margin: "20px 10px" }} />
                        <Typography style={{ fontWeight: "bold" }}>{translate("my-risk.reduce-risk-text")}?</Typography>
                        <Flex
                            item
                            container
                            justifyContent={"space-between"}
                            style={{ padding: "0px 15px", marginTop: 10, maxHeight: 40 }}
                        >
                            <HmoInputLabel>{translate("global.action")}</HmoInputLabel>
                            <HmoInputLabel>{translate("my-risk.optimization")}</HmoInputLabel>
                        </Flex>
                        {Smoker && fixedSmoking > 0 && (
                            <HealthTile percent={fixedSmoking} title={translate("my-risk.stop-smoking")} />
                        )}
                        {BloodPressureSystolic > 120 && fixedBloodPressure > 0 && (
                            <HealthTile percent={fixedBloodPressure} title={translate("my-risk.fix-blood-pressure")} />
                        )}
                        {Smoker && BloodPressureSystolic > 120 && fixedBoth > 0 && (
                            <HealthTile percent={fixedBoth} title={translate("my-risk.both")} />
                        )}
                    </>
                ) : (
                    <>
                        <Divider style={{ margin: "20px 10px" }} />
                        <Typography style={{ fontWeight: "bold" }}>
                            {translate("my-risk.no-reduction-possible")}
                        </Typography>
                    </>
                )}
            </Flex>
        </>
    );
};

const PlaceHolder = () => {
    return (
        <>
            <Skeleton variant="text" height={"2em"} />
            <Skeleton variant="rect" height={"266px"} />
        </>
    );
};

const MissingMyRiskAnswers = ({ answersMissing, translate }) => {
    const anamnesisMissing = answersMissing?.filter((key) => key.startsWith("anamnesis"));
    const laboratoryMissing = answersMissing?.filter((key) => key.startsWith("laboratory"));
    return (
        <>
            <Typography variant={"h6"} style={{ margin: "20px 0px" }}>
                {translate("my-risk.missing-answers")}
            </Typography>
            {!!anamnesisMissing.length && (
                <Paper style={{ padding: 10, width: "100%" }} elevation={4}>
                    <Typography
                        style={{
                            margin: "5px 0px",
                            fontWeight: "bold",
                            fontSize: 16,
                        }}
                    >
                        {translate("patient.anamnase-questionaire")}
                    </Typography>
                    <ul>
                        {anamnesisMissing.map((translateKey) => (
                            <li key={translateKey}>
                                <Typography key={translateKey} style={{ fontWeight: "bold" }}>
                                    {translate(translateKey)}
                                </Typography>
                            </li>
                        ))}
                    </ul>
                </Paper>
            )}
            {!!laboratoryMissing.length && (
                <Paper style={{ padding: 10, marginTop: 10, width: "100%" }} elevation={4}>
                    <Typography
                        style={{
                            margin: "5px 0px",
                            fontWeight: "bold",
                            fontSize: 16,
                        }}
                    >
                        {translate("patient.laboratory-history")}
                    </Typography>
                    <ul>
                        {laboratoryMissing.map((translateKey) => (
                            <li key={translateKey}>
                                <Typography style={{ fontWeight: "bold" }}>{translate(translateKey)}</Typography>
                            </li>
                        ))}
                    </ul>
                </Paper>
            )}
        </>
    );
};

const MyRisk = () => {
    const { t: translate } = useTranslation();
    const { patientId } = useParams();
    const { data: riskData, isSuccess: riskDataLoadedSuccessfully } = useGetRiskQuery(patientId, {
        refetchOnMountOrArgChange: true,
    });
    const { data: patientData, isSuccess: patientDataLoadedSuccessfully } = useFetchPatientByIdQuery(patientId, {
        refetchOnMountOrArgChange: true,
    });
    const { data: laboratoryData, isSuccess: laboratoryDataLoadedSuccessfully } = useFetchLaboratoryByPatientQuery(
        { patientId },
        { skip: !patientId, refetchOnMountOrArgChange: true },
    );
    const { data: anamnesisData, isSuccess: anamnesisDataLoadedSuccessfully } = useFetchAnamnesisByPatientQuery(
        { patientId },
        { skip: !patientId, refetchOnMountOrArgChange: true },
    );

    const answersMissing =
        laboratoryDataLoadedSuccessfully && anamnesisDataLoadedSuccessfully
            ? missingAnswers(laboratoryData, anamnesisData)
            : [];

    const dataIsLoaded =
        patientDataLoadedSuccessfully &&
        riskDataLoadedSuccessfully &&
        laboratoryDataLoadedSuccessfully &&
        anamnesisDataLoadedSuccessfully;
    const isAnswersMissing = answersMissing?.length > 0;
    return (
        <Flex item container>
            <Flex item container column flex={1} style={{ padding: 20, maxWidth: "50%" }} alignItems="center">
                {dataIsLoaded && (
                    <>
                        <RiskProfile
                            {...{
                                translate,
                                riskData,
                                anamnesisData,
                                isAnswersMissing,
                            }}
                        />
                        {isAnswersMissing && <MissingMyRiskAnswers {...{ translate, answersMissing }} />}
                    </>
                )}
                {!dataIsLoaded && <PlaceHolder />}
            </Flex>
            <Flex item container flex={1} column style={{ padding: 20, maxWidth: "50%" }}>
                {dataIsLoaded ? (
                    <HematologyRisk
                        riskData={riskData}
                        patientData={patientData}
                        mutationData={anamnesisData?.chipMutationData}
                        laboratoryData={laboratoryData}
                    />
                ) : (
                    <PlaceHolder />
                )}
            </Flex>
        </Flex>
    );
};

export default MyRisk;
