export const validationError = (form, name) => {
    if ( ( form.touched[name] && form.errors[name]) || form.errors[name] != null ) {
        return {
            error: true,
            helperText: form.errors[name],
            textFieldProps : {
                error: true,
                helperText: form.errors[name],
            }
        }
    }
    return {};
}

export const isPropertySelected = (form,name) =>   form.values?.[name] !== null && form.values?.[name] === true
