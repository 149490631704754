import React, {Component} from 'react';
import {connect} from 'react-redux';
import connector from './Registration.connect';
import Flex from 'components/grid/Flex';
import {withRouter} from 'utils/Route.utils';
import {withTranslation} from 'react-i18next';
import {
    Button,
    Typography,
    Link
} from "@material-ui/core";
import {ReactComponent as KrebsZweitmeinungLogo} from 'assets/img/krebszweitmeinung-logo.svg';
import LanguageSelector from 'components/language-selector/LanguageSelector';

export class Registration extends Component {

    render() {
        const {t: translate, logout} = this.props;

        return (
            <Flex item container column center>
                <Flex item container justifyContent={'flex-start'} style={{width: '100%', padding: 10}}>
                    <KrebsZweitmeinungLogo style={{width: 150, height: 50}}/>
                    <Flex item container justifyContent={'flex-end'} padding={8}>
                        <LanguageSelector/>
                    </Flex>
                </Flex>
                <Flex item container column style={{width: '100%', maxWidth: 450, padding: 10}}>
                    <Typography>{translate('login.welcome1')}< /Typography>
                    <Typography>{translate('login.welcome2')}< /Typography>
                    <Flex item container>
                        <Typography>{translate('login.welcome3')}&nbsp;</Typography>
                        <a target="_blank" href={translate('login.hmo-link')}>{translate('login.hmo-link')}</a>
                    </Flex>

                    <Flex item container style={{marginTop: 20}}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{marginLeft: 10}}
                            onClick={logout}
                        >
                            {translate('global.logout')}
                        </Button>

                    </Flex>
                </Flex>
            </Flex>
        );
    }
}

export default connect(connector.mapStateToProps,
    connector.mapDispatchToProps)(withRouter(withTranslation()(Registration)));
