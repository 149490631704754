import React from 'react';
import {
    withStyles,
    Typography,
    MenuItem,
    IconButton,
    CircularProgress
} from "@material-ui/core";
import {withTranslation} from 'react-i18next';
import Flex from 'components/grid/Flex';
import CommunicationsLog from 'components/log/CommunicationsLog';
import * as colors from 'components/colors/Colors';
import HmoInputLabel from 'components/input-label/HmoInputLabel';
import Accordion from 'components/grid/Accordion';
import {connect} from 'react-redux';
import connector from './ClinicCenter.connect';
import _ from 'lodash';
import FileUploader from 'components/file-upload/FileUploader';
import DeleteIcon from '@material-ui/icons/Delete';
import EpiAlert from 'components/alert/EpiAlert';
import AutoTags from 'components/tags/AutoTags';
import update from 'immutability-helper';
import TumorBoardUnavailable from './TumorBoardUnavailable';
import DefaultTextField from 'components/hmo-textfield/DefaultTextField';
import {withRouter} from 'utils/Route.utils';
import Rating from 'components/rating/Rating';
import {toggleValue} from 'utils/Utils';

const styles = theme => ({
    inputItem: {
        paddingTop: 7,
        paddingBottom: 7,
    },
    inputRoot: {
        backgroundColor: 'white',
        borderRadius: 4,
        paddingLeft: 7,
        marginBottom: 1,
        height: 36
    },
    accordionDetails: {
        backgroundColor: colors.alabaster,
        display: 'flex',
        flexDirection: 'column'
    }
});

export class ClinicCenter extends React.PureComponent {

    state = {
        attachments: [],
        logs: [],
        isLoading: false,
        alert: ''
    }

    onSubIndicationChange = subIndications => {
        this.props.onChangeCenter(this.props.center, (e) => update(e, {'subIndications': {$set: subIndications}}));
    }

    onIndicationChange = event => {
        const value = event.target.value;
        this.props.onChangeCenter(this.props.center, (e) => update(e, {'subIndications': {$set: []}}));
        this.props.onChangeCenter(this.props.center, (e) => update(e, {'indications': {$set: [value]}}));
    }

    componentDidMount() {
        if (this.props.center.businessId) {
            this.listClinicCenterAttachments();
            this.props.fetchClinicLogs(this.props.center.businessId).then(response => {
                this.setState({
                    logs: response.payload.data || []
                })
            });
        }
    }

    listClinicCenterAttachments = () => {
        this.props.listClinicCenterAttachments(this.props.center.businessId).then(response => {
            this.setState({
                attachments: _.get(response, 'payload.data')
            })
        })
    }

    onDrop = (acceptedFiles, rejectedFiles) => {
        this.setState({
                fileUploadInProgress: true
            },
            () => this.props.uploadClinicCenterFile(acceptedFiles[0], acceptedFiles[0].name,
                    this.props.center.businessId, this.props.center.id)
                .then(response => {
                    this.listClinicCenterAttachments();
                })
                .catch(error => console.log('Error: ', error))
                .then(() => {
                    this.setState({
                        open: false,
                        fileUploadInProgress: false
                    })
                })
        )
    };

    deleteAttachment = document => event => {
        this.props.deleteClinicCenterFile(document)
            .then(response => {
                this.listClinicCenterAttachments();
            })
    }

    onChange = event => {
        const value = _.get(event, 'target.value');
        const name = _.get(event, 'target.name');
        this.props.onChangeCenter(this.props.center, (e) => update(e, {[name]: {$set: value}}));
    }

    onRatingChange = props => {
        this.props.onChangeCenter(this.props.center, (e) => update(e, {
            [props.name]: {$set: props.value},
        }));
    }

    onCertificateChange = certifications => {
        this.onChange({target: {name: 'certifications', value: certifications}});
    }

    onTumorBoardDaysChange = day => {
        const tumorBoardDays = _.get(this.props, 'center.tumorBoardDays') || [];
        this.onChange({target: {name: 'tumorBoardDays', value: toggleValue(tumorBoardDays, day)}});
    }

    onCollaborationTypeChange = type => {
        const collaborationTypes = _.get(this.props, 'center.collaborationTypes') || [];
        this.onChange({target: {name: 'collaborationTypes', value: toggleValue(collaborationTypes, type)}});
    }

    addNewRange = range => {
        this.props.onChangeCenter(this.props.center, (e) => update(e, {
            tumorBoardUnavailable: {
                $push: [range]
            }
        }));
    }

    onExistingDateChange = ({index, name, value}) => {
        this.props.onChangeCenter(this.props.center, (e) => update(e, {
            tumorBoardUnavailable: {
                [index]: {
                    [name]: {$set: value}
                }
            }
        }));
    }

    closeSnackbar = () => {
        this.setState({
            alert: '',
        });
    };

    addCenterLog = comment => {
        this.props.addClinicLog(comment, this.props.center.businessId, 'CENTER').then(response => {
            this.setState({
                logs: _.unionBy(this.state.logs, [response?.payload?.data], 'timestamp')
            })
        });
    }

    render() {
        const {t: translate, user, certifications, center, indications, subIndications, openByDefault} = this.props;
        const {attachments = [], isLoading, alert, logs} = this.state;
        const {
            onDrop, deleteAttachment, onChange, onCertificateChange, onTumorBoardDaysChange, addNewRange,
            onExistingDateChange, onCollaborationTypeChange, closeSnackbar, addCenterLog, onRatingChange,
            onIndicationChange, onSubIndicationChange
        } = this;

        return (
            <Accordion openByDefault={openByDefault} titleComponent={
                <Flex item container justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant={'h6'}>{translate('clinic.center')}: {center.name}</Typography>
                    {
                        isLoading &&
                        <CircularProgress size={20}/>
                    }
                </Flex>
            }>

                {
                    center.businessId &&
                    <Flex container column>
                        <Flex item container column style={{marginBottom: 10}}>
                            <Flex item container alignItems={'center'}>
                                <Typography style={{fontWeight: 'bold'}}>{translate("clinic.open-cases")}:</Typography>
                                <Typography style={{
                                    color: 'white',
                                    fontSize: 13,
                                    backgroundColor: '#D94141',
                                    textAlign: 'center',
                                    padding: 2,
                                    marginLeft: 5,
                                    borderRadius: 5,
                                    minWidth: 20
                                }}>{center.numberOfActiveCases || 0}</Typography>
                            </Flex>
                            <Flex item container alignItems={'center'}>
                                <Typography style={{
                                    color: 'white',
                                    fontSize: 14,
                                    backgroundColor: 'orange',
                                    textAlign: 'center',
                                    padding: 2,
                                    borderRadius: 5,
                                    marginRight: 5,
                                    minWidth: 20
                                }}>{center.latestQualityRatingAggregate || 0}</Typography>
                                <Typography
                                    style={{fontSize: 12, opacity: 0.5}}>({center.numberOfCases || 0})</Typography>
                            </Flex>
                        </Flex>

                        <Flex id={"clinic-details"} item container alignItems={'center'}>
                            <Flex item={'0 1 auto'} container>
                                <DefaultTextField label={translate("clinic.indications")}
                                                  select
                                                  SelectProps={{
                                                      value: (center || []).indications[0] || '',
                                                      name: 'indications',
                                                      onChange: onIndicationChange
                                                  }}>
                                    {indications && indications.map(indication => (
                                        <MenuItem key={indication} value={indication}>
                                            {translate(`global.${indication}`)}
                                        </MenuItem>
                                    ))}
                                </DefaultTextField>
                            </Flex>
                            <Flex item={'0 1 auto'} container column style={{marginRight: 20, paddingBottom: 8}}>
                                <HmoInputLabel style={{marginLeft: 10, textTransform: 'none'}}> {translate('clinic.certificates')} </HmoInputLabel>
                                <AutoTags {...{
                                    selectedValues: center.certifications || [],
                                    options: certifications,
                                    onChange: onCertificateChange
                                }}/>
                            </Flex>
                            <Flex item container column style={{paddingBottom: 8}}>
                                <HmoInputLabel style={{marginLeft: 10, textTransform: 'none'}}> {translate('clinic.sub-indications')} </HmoInputLabel>
                                <AutoTags {...{
                                    componentProps: {
                                        disabled: center?.indications.length !== 1
                                    },
                                    selectable: (e, v) => e.props.options.indexOf(v) !== -1,
                                    selectedValues: center?.subIndications || [],
                                    options: subIndications.filter(e => e?.indication === center?.indications[0]).map(e => e.subIndication),
                                    onChange: onSubIndicationChange
                                }}/>
                            </Flex>
                        </Flex>
                        <Flex item container>
                            <Flex id={'details'} item fullWidth container column>
                                <DefaultTextField
                                    value={center.name || ''}
                                    name={'name'}
                                    label={translate('global.name')}
                                    onChange={onChange}
                                />
                                <DefaultTextField
                                    value={center.faxNumber || ''}
                                    name={'faxNumber'}
                                    label={translate('global.fax-number')}
                                    onChange={onChange}
                                />
                                <DefaultTextField
                                    value={center.manager || ''}
                                    name={'manager'}
                                    label={translate('clinic.manager')}
                                    onChange={onChange}
                                />
                                <Flex item container>
                                    <DefaultTextField
                                        value={center.caseAmount || ''}
                                        name={'caseAmount'}
                                        label={translate('clinic.case-number')}
                                        onChange={onChange}
                                    />
                                    <Flex item container column>
                                        <DefaultTextField
                                            label={translate('clinic.per')}
                                            select
                                            SelectProps={{
                                                value: center.caseFrequency || '',
                                                onChange,
                                                name: 'caseFrequency'
                                            }}
                                        >
                                            <MenuItem key={'DAY'} value={'DAILY'}>
                                                {translate('clinic.DAY')}
                                            </MenuItem>
                                            <MenuItem key={'WEEK'} value={'WEEKLY'}>
                                                {translate('clinic.WEEK')}
                                            </MenuItem>
                                            <MenuItem key={'MONTH'} value={'MONTHLY'}>
                                                {translate('clinic.MONTH')}
                                            </MenuItem>
                                            <MenuItem key={'QUARTER'} value={'QUARTERLY'}>
                                                {translate('clinic.QUARTER')}
                                            </MenuItem>
                                        </DefaultTextField>
                                    </Flex>
                                </Flex>
                                <Flex item style={{marginTop: 10}}>
                                    {attachments.map((item, ix) => (
                                        <Flex key={_.uniqueId('attachment_')} item={'0 1 auto'} container
                                              style={{border: '1px solid grey', borderRadius: 6, paddingLeft: 6}}
                                              justifyContent={'space-between'} alignItems={'center'}>
                                            <Typography
                                                style={{color: 'blue', fontStyle: 16}}>
                                                {item.fileName}
                                            </Typography>
                                            <IconButton onClick={deleteAttachment(item)} style={{padding: 3}}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Flex>
                                    ))}
                                    <FileUploader onDrop={onDrop}/>
                                </Flex>
                                <DefaultTextField
                                    label={translate('clinic.contract-status')}
                                    select
                                    style={{marginBottom: 10}}
                                    SelectProps={{
                                        value: center.contractStatus || '',
                                        onChange,
                                        name: 'contractStatus'
                                    }}
                                >
                                    <MenuItem key={'SIGNED'} value={'SIGNED'}>
                                        {translate('clinic.SIGNED')}
                                    </MenuItem>
                                    <MenuItem key={'REVIEW'} value={'REVIEW'}>
                                        {translate('clinic.REVIEW')}
                                    </MenuItem>
                                    <MenuItem key={'NOT_SENT'} value={'NOT_SENT'}>
                                        {translate('clinic.NOT_SENT')}
                                    </MenuItem>
                                </DefaultTextField>
                                <Accordion openByDefault title={translate('clinic.tumor-conference')}>
                                    <Flex item container column>
                                        <HmoInputLabel> {translate('clinic.tumor-conference-days')} </HmoInputLabel>
                                        <Flex item={'0 1 auto'} container justifyContent={'flex-start'}>
                                            {
                                                ["MO", "TU", "WE", "TH", "FR", "SA", "SU"].map(day => (
                                                    <Flex item={'0 0 0'} container center padding={3} style={{
                                                        backgroundColor: (center.tumorBoardDays || []).includes(day) ? '#4B8AC9' : 'white',
                                                        color: (center.tumorBoardDays || []).includes(day) ? 'white' : 'black',
                                                        border: '1px solid lightgrey',
                                                        borderRadius: 6,
                                                        minWidth: 40,
                                                        margin: '0 5px'
                                                    }}
                                                          key={day}
                                                          onClick={() => onTumorBoardDaysChange(day)}
                                                    >
                                                        <Typography>{translate(`clinic.${day}`)}</Typography>
                                                    </Flex>
                                                ))
                                            }
                                        </Flex>
                                        <DefaultTextField
                                            label={translate('global.comment')}
                                            multiline
                                            rows={3}
                                            value={center.tumorBoardComment}
                                            onChange={onChange}
                                            name={'tumorBoardComment'}
                                        />
                                        <TumorBoardUnavailable {...{
                                            addNewRange,
                                            onExistingChange: onExistingDateChange,
                                            tumorBoardUnavailable: center.tumorBoardUnavailable || [],
                                        }}/>
                                    </Flex>
                                </Accordion>
                                <Accordion openByDefault title={translate('clinic.collaboration')}>
                                    <Flex item container column>
                                        <HmoInputLabel> {translate('clinic.collaboration-types')} </HmoInputLabel>
                                        <Flex item={'0 1 auto'} container justifyContent={'flex-start'}>
                                            {
                                                ["IMAGE", "PERSONAL", "FILE"].map(type => (
                                                    <Flex item={'0 0 0'} container center padding={3} style={{
                                                        backgroundColor: (center.collaborationTypes || []).includes(type) ? '#4B8AC9' : 'white',
                                                        color: (center.collaborationTypes || []).includes(type) ? 'white' : 'black',
                                                        border: '1px solid lightgrey',
                                                        borderRadius: 6,
                                                        margin: '0 5px'
                                                    }}
                                                          key={type}
                                                          onClick={() => onCollaborationTypeChange(type)}
                                                    >
                                                        <Typography
                                                            style={{whiteSpace: 'nowrap'}}>{translate(`clinic.${type}`)}</Typography>
                                                    </Flex>
                                                ))
                                            }
                                        </Flex>
                                        <DefaultTextField
                                            multiline
                                            label={translate('global.comment')}
                                            rows={3}
                                            value={center.collaborationComment}
                                            onChange={onChange}
                                            name={'collaborationComment'}
                                        />
                                        <HmoInputLabel
                                            style={{marginTop: 5}}> {translate('global.quality')} </HmoInputLabel>
                                        <Flex item container justifyContent={'space-between'} padding={5}>
                                            <Typography
                                                style={{fontSize: 15}}>{translate('global.quality-of-case')}</Typography>
                                            <Rating rating={center.latestQualityRatingAggregate} onChange={() => {
                                            }}
                                                    disabled
                                                    name={'latestQualityRatingAggregate'}/>
                                        </Flex>
                                        <Flex item container justifyContent={'space-between'} padding={5}>
                                            <Typography
                                                style={{fontSize: 15}}>{translate('global.speed')}</Typography>
                                            <Rating rating={center.speedRating} onChange={onRatingChange}
                                                    name={'speedRating'}/>
                                        </Flex>
                                        <Flex item container justifyContent={'space-between'} padding={5}>
                                            <Typography
                                                style={{fontSize: 15}}>{translate('global.communication')}</Typography>
                                            <Rating rating={center.communicationRating} onChange={onRatingChange}
                                                    name={'communicationRating'}/>
                                        </Flex>
                                    </Flex>

                                </Accordion>
                            </Flex>
                            <CommunicationsLog {...{
                                title: translate('clinic.center-communications-log'),
                                style: {flex: '1 1 auto', padding: 5, maxWidth: '40%', minWidth: '40%'},
                                logs: logs || [],
                                user,
                                addLog: addCenterLog
                            }}/>
                        </Flex>
                        <EpiAlert
                            {...{
                                isOpen: !!alert,
                                close: closeSnackbar,
                                severity: alert === 'error' ? alert : 'success',
                                message:
                                    alert === 'error'
                                        ? translate('global.backend-call-failed')
                                        : alert
                                            ? translate(`global.${alert}`)
                                            : '',
                            }}
                        />
                    </Flex>
                }
            </Accordion>
        );
    }
}

export default withStyles(styles)(
    connect(connector.mapStateToProps, connector.mapDispatchToProps)(
        withTranslation()(
            withRouter(ClinicCenter))
    )
);

