import React, {useCallback, useState} from 'react';
import {Grid, Tab, Tabs, Typography} from "@material-ui/core";
import Divider from "@mui/material/Divider";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {a11yProps, TabPanel} from "../../component/tab";
import LaboratoryTimeline from "./LaboratoryTimeline";
import LaboratoryDashboard from "./LaboratoryDashboard";
import {
    useFetchPatientByIdQuery,
} from "scenes/patient/PatientApi";
import {useParams} from "react-router-dom";

const useStyles = makeStyles((_theme) => ({
    root: {
        flexGrow: 1,
        justifyContent: 'space-between',
        '& .MuiTabs-flexContainer': {
            display: 'block',
        }
    },

    gridTitle: {
        marginLeft: '37px',
        marginTop: '15px',
    },

    mainTitle: {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '22px',
        lineHeight: '27px',
        color: '#245B67'
    },


}));

const LaboratoryForm = () => {
    const {patientId} = useParams();
    const {data: patientData} = useFetchPatientByIdQuery(patientId);
    const anonymized = patientData?.anonymized;
    const readonly = anonymized;
    const {t} = useTranslation();
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const lang = useCallback((name) => t(`laboratory.${name}`), [t]);
    const handleChange = (event, newValue) => setValue(newValue);

    return <div className={classes.root}>
        <div style={{
            display: 'flex', justifyContent: 'space-between',
            alignItems: 'center', paddingLeft: '20px', paddingTop: '20px'
        }}>
            <Typography data-testid="laboratory-welcome-title" style={{alignSelf: 'start'}} component="h1" variant="h3" color="primary">
                {lang('title')}
            </Typography>
            <Tabs value={value} onChange={handleChange}>
                <Tab label={lang('dashboard')} {...a11yProps(0)} />
                <Tab label={lang('calendar')} {...a11yProps(1)} />
            </Tabs>
        </div>

        <Divider flexItem light orientation="horizontal" style={{width: '100%'}}/>

        <Grid>
            <Grid item xs={12} style={{padding: '20px'}}>
                <TabPanel value={value} index={0}>
                    <LaboratoryDashboard readonly={readonly}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <LaboratoryTimeline readonly={readonly}/>
                </TabPanel>

            </Grid>
        </Grid>
    </div>
};

export default LaboratoryForm;
