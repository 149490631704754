import React, {Component} from 'react';
import {withStyles, Typography} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {htmlWhite, iceberg} from 'components/colors/Colors';
import {withTranslation} from 'react-i18next';
import HmoInputLabel from 'components/input-label/HmoInputLabel';
import HmoTextField from 'components/hmo-textfield/HmoTextField';
import DatePicker from 'components/calendar/DatePicker';

const styles = theme => ({
    inputItem: {
        padding: '7px 7px',
    },
    inputRoot: {
        backgroundColor: htmlWhite,
        borderRadius: 4,
        paddingLeft: 7
    },
    entryContainer: {
        borderRadius: 6,
        margin: 10,
        marginTop: 20,
        padding: 15
    },
    title: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: 'black',
        fontSize: 15
    }
});

export class Diagnosis extends Component {

    handlePrimaryTumorDiagnosedChange = date => {
        this.props.updateCase({
            primaryTumorDiagnosed: date
        })
    };

    handleMetastasisDiagnosedChange = date => {
        this.props.updateCase({
            metastasisDiagnosed: date
        })
    };

    render() {
        const {classes, t: translate, _case, disabled, handleCaseChange} = this.props;
        const {primaryTumorDiagnosed, primaryTumorLocalized, metastasisDiagnosed, metastasisLocalized} = _case;
        const {
            handlePrimaryTumorDiagnosedChange, handleMetastasisDiagnosedChange
        } = this;

        return (
            <Flex item container>
                <Flex item container direction={'column'} className={classes.entryContainer}
                      style={{backgroundColor: iceberg}}>
                    <Typography className={classes.title}>{translate('case.primary-tumor')}</Typography>
                    <Flex item container column>
                        <DatePicker {...{
                            value: primaryTumorDiagnosed,
                            name: 'primaryTumorDiagnosed',
                            onChange: handlePrimaryTumorDiagnosedChange,
                            label: translate('case.diagnosed'),
                            disabled,
                            style: {width: '100%', flex: '0 1 auto'}
                        }}/>
                        <Flex item container column>
                            <HmoInputLabel>{translate('case.localization')}</HmoInputLabel>
                            <HmoTextField
                                value={primaryTumorLocalized || ''}
                                multiline
                                variant={'outlined'}
                                rows={4}
                                placeholder={translate('global.describe-localization')}
                                onChange={handleCaseChange}
                                fullWidth
                                disabled={disabled}
                                name={'primaryTumorLocalized'}
                                InputProps={{
                                    style: {
                                        padding: 5
                                    }
                                }}
                                style={{
                                    backgroundColor: htmlWhite,
                                }}
                            />
                        </Flex>
                    </Flex>
                </Flex>
                <Flex item container direction={'column'} className={classes.entryContainer}
                      style={{backgroundColor: iceberg}}>
                    <Typography className={classes.title}>{translate('case.metastasis')}</Typography>
                    <Flex item container column>
                        <DatePicker {...{
                            value: metastasisDiagnosed,
                            name: 'metastasisDiagnosed',
                            onChange: handleMetastasisDiagnosedChange,
                            label: translate('case.diagnosed'),
                            disabled,
                            style: {width: '100%', flex: '0 1 auto'}
                        }}/>
                        <Flex item container column>
                            <HmoInputLabel>{translate('case.localization')}</HmoInputLabel>
                            <HmoTextField
                                value={metastasisLocalized || ''}
                                multiline
                                variant={'outlined'}
                                rows={4}
                                placeholder={translate('global.describe-localization')}
                                onChange={handleCaseChange}
                                fullWidth
                                disabled={disabled}
                                name={'metastasisLocalized'}
                                InputProps={{
                                    style: {
                                        padding: 5
                                    }
                                }}
                                style={{
                                    backgroundColor: htmlWhite,
                                }}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(Diagnosis));
