import React, {Component} from 'react';
import {
    InputAdornment,
    IconButton,
    Typography
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import HmoAvatar from 'components/avatar/HmoAvatar';
import ShrinkTextField from 'components/hmo-textfield/ShrinkTextField';
import Send from '@material-ui/icons/Send';
import utils from "utils/Utils";
import {connect} from 'react-redux';
import connector from './Chat.connect';
import {isContactPerson} from 'domain/User.model';
import _ from 'lodash';

function timeStamp(timestamp, translate) {
    if (moment(timestamp).isSame(moment(), 'minute')) {
        return translate("global.just-now");
    } else if (moment(timestamp).isSame(moment(), 'day')) {
        return translate("global.today") + " " + moment(timestamp).format("hh:mm A")
    } else {
        return moment(timestamp).format('DD MMM YYYY, HH:mm')
    }
}

const Log = ({translate, log, children, avatar, backgroundColor, justifySelf}) => {
    return (
            <Flex item grow={0} container
                  style={{maxWidth: '80%', justifySelf: justifySelf || 'flex-end', margin: '5px 0px'}}>
                <Flex item={'0 1 auto'} container style={{marginRight: 6, marginTop: 5}}>
                    {
                            avatar &&
                            <HmoAvatar user={log}/>
                    }
                </Flex>
                <Flex item container column>
                    <Flex item grow={0} container justifyContent={'space-between'} alignItems={'center'}>
                        {children}
                        {/*
                        NOTE LM Task-2403: DO NOT REMOVE the following nbsp!
                        There is a wierd ass render bug in chromium which causes the time stamp to flicker and partially
                        overlap the element below the bounding box, in certain cases. Usually when scrolling upward,
                        but not when scrolling downward. Edge and Chrome is affected but not Firefox.
                        The text, and about half the timestamp "correctly disappears" when they are scroll out of view,
                        but some of the timestamp remains in view.
                        If the children exist (which is the avatar for OtherLog) this does not happen, thus we add a
                        non-breaking space to fix the issue.
                        */}
                        &nbsp;
                        <Typography style={{
                            opacity: 0.54,
                            fontSize: 10,
                            fontWeight: 400,
                            color: "#030303"
                        }}>{timeStamp(log.timestamp, translate)}</Typography>
                    </Flex>
                    <Flex item container
                          style={{backgroundColor: backgroundColor || '#E2E1E4', borderRadius: 4, padding: '5px 10px'}}>
                        {log.text}
                    </Flex>
                </Flex>

            </Flex>
    )
}

const YourLog = ({translate, log}) => {
    return <Log {...{translate, log, backgroundColor: '#B9D5E9'}}/>
}

const OtherLog = ({log, translate}) => {
    return <Log {...{translate, log, avatar: true, justifySelf: 'flex-start'}}>
        <Typography style={{marginRight: 10}}>{utils.formattedUserName(log)}</Typography>
    </Log>
}

export class ChatLog extends Component {

    state = {
        newMessage: '',
        chatLog: []
    };

    componentDidMount() {
        if (this.props.entityId && this.props.entityType) {
            this.refresh();
        }
    }

    refresh = () => {
        this.props.getChatLog(this.props.entityType, this.props.entityId).then(response =>
                this.setState({
                    chatLog: response?.payload?.data
                }, () => {
                    const element = document.getElementById('logs');
                    element.scrollTop = element.scrollHeight;
                })
        )
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    };

    addMessage = () => {
        this.setState({
            newMessage: ''
        });
        if (this.state.newMessage) {
            this.props.upsertChatLog({
                text: this.state.newMessage,
                entityId: this.props.entityId,
                entityType: this.props.entityType,
                timestamp: moment.utc().format(utils.API_DATE_FORMAT),
                userId: this.props.user.id,
                dispatcherBusinessId: this.props.user.userDetailsBusinessId || this.props.user.businessId
            }).then(() => {
                this.refresh();
            });
        }
    };

    render() {
        const {t: translate, user, style} = this.props;
        const {newMessage, chatLog} = this.state;
        const {handleChange, addMessage} = this;

        const sortedLog = _.orderBy(chatLog, ['timestamp'], ['asc']);
        return (
                <Flex walkthrough-element="chat" item={'0 1 auto'} container column style={{
                    borderRadius: 10,
                    ...style
                }}>
                    <Flex item container column style={{
                        borderRadius: 10,
                        border: '1px solid #859EC2',
                        maxHeight: 300,
                        minHeight: 50,
                        overflow: 'hidden',
                        padding: 5
                    }}>
                        <Flex id={'logs'} item container column style={{
                            overflow: 'auto',
                            padding: 5
                        }}>
                            {
                                (sortedLog || []).map(log => (
                                        <Flex item grow={0} grid key={log.id}>
                                            {
                                                log.userId === user.id
                                                        ? <YourLog log={log} translate={translate}/>
                                                        : <OtherLog log={log} translate={translate}/>
                                            }
                                        </Flex>
                                ))
                            }
                        </Flex>
                    </Flex>
                    <ShrinkTextField
                            InputProps={{
                                style: {border: '1px solid #859EC2', marginRight: -8},
                                endAdornment: <InputAdornment position={'end'}>
                                    <IconButton style={{padding: 0}} onClick={addMessage}>
                                        <Send/>
                                    </IconButton>
                                </InputAdornment>
                            }}
                            inputProps={{
                                autoComplete: 'off'
                            }}
                            disabled={isContactPerson(user)}
                            placeholder={translate('global.send-message')}
                            value={newMessage || ''}
                            onEnterKey={addMessage}
                            onChange={handleChange}
                            name={'newMessage'}
                    />
                </Flex>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(ChatLog));
