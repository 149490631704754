import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState, useRef } from "react";
import { asyncQuerySelector } from "./asyncQuerySelector";
import { useTranslation } from "react-i18next";
import { ModalWindowArrow } from "./ModalWindowArrow";
import { SPECIAL_STEP_TYPE } from "./mainWalkthrough/mainWalkthroughStepsConfig";

const useStyles = makeStyles((theme) => ({
    modalWindow: {
        position: "absolute",
        backgroundColor: "#e3f2f5",
        width: "400px",
        padding: "24px 24px 16px",
        borderRadius: "20px",
        border: "2px solid #255b66",
    },
    modalWindowTitle: {
        color: "#255b66",
        fontSize: "24px",
        fontWeight: "bold",
        marginBottom: "10px",
    },
    modalWindowContentStart: {
        color: "#777777",
        fontSize: "15px",
        marginBottom: "10px",
    },
    modalWindowContent: {
        color: "#255b66",
        fontSize: "15px",
        marginBottom: "10px",
    },
    actionButtons: {
        display: "flex",
        flexDirection: "column",
        marginTop: "24px",
        alignItems: "center",
        gap: "8px",
        color: "#255b66",
    },
    mainButton: {
        padding: "8px 34px",
        color: "white",
        backgroundColor: "#255b66",
        borderRadius: "5px",
        border: "none",
        fontSize: "15px",
        cursor: "pointer",
    },
    mainStartButton: {
        padding: "10px 64px",
        color: "white",
        backgroundColor: "#255b66",
        borderRadius: "5px",
        border: "none",
        fontSize: "20px",
        cursor: "pointer",
    },
    secondaryButton: {
        fontSize: "15px",
        cursor: "pointer",
    },
    closeIcon: {
        position: "absolute",
        right: "12px",
        top: "10px",
        cursor: "pointer",
    },
    beforeStartQuestion: {
        color: "#255b66",
        fontSize: "18px",
    },
}));

export const WalkthroughStep = ({
    onNext,
    onLater,
    onComplete,
    title,
    questionBeforeStart,
    type = "default",
    content,
    positionX,
    positionY,
    offsetX = 0,
    offsetY = 0,
    selector,
    onClose,
    showAnimation,
}) => {
    const [yOffset, setYOffset] = useState(0);
    const [xOffset, setXoffset] = useState(0);
    const { t } = useTranslation();
    const modalWindowRef = useRef(null);
    useEffect(() => {
        const baseXOffset = modalWindowRef?.current?.getBoundingClientRect()?.x;
        const baseYOffset = modalWindowRef?.current?.getBoundingClientRect()?.y;
        setXoffset(baseXOffset);
        setYOffset(baseYOffset);
    }, [modalWindowRef]);
    useEffect(() => {
        if (type === SPECIAL_STEP_TYPE.START || type === SPECIAL_STEP_TYPE.END) return;
        const handleResize = (e) => {
            asyncQuerySelector(selector)
                .then((targetEl) => {
                    if (e instanceof Event) {
                        modalWindowRef.current.style.transition = "none";
                    } else {
                        if(showAnimation) {
                            modalWindowRef.current.style.transition = "all 0.4s ease-in-out"
                        }
                    }
                    const yOffset = Math.floor(
                        positionX === "bottom"
                            ? offsetY + targetEl.getBoundingClientRect().bottom
                            : positionX === "top"
                            ? offsetY +
                              (targetEl.getBoundingClientRect().top -
                                  modalWindowRef.current.getBoundingClientRect().height)
                            : offsetY + targetEl.getBoundingClientRect().top
                    );
                    const xOffset =
                        positionY === "right"
                            ? Math.floor(offsetX + targetEl.getBoundingClientRect().right)
                            : Math.floor(
                                  offsetX +
                                      (targetEl.getBoundingClientRect().left -
                                          modalWindowRef.current.getBoundingClientRect().width)
                              );
                    setYOffset(yOffset);
                    setXoffset(xOffset);
                })
                .catch(console.log);
        };

        window.addEventListener("resize", handleResize);
        window.addEventListener("scroll", handleResize);
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("scroll", handleResize);
        };
    }, [positionX, positionY, offsetX, offsetY, selector, type]);
    const classes = useStyles();

    const arrowConfig = {
        bottomright: {
            style: { position: "absolute", left: "-25px", top: "-100px" },
            arrowProps: { width: 200, height: 100, points: "90,100 0,0 190,100" },
        },
        bottomleft: {
            style: { position: "absolute", right: "-100px", top: "-25px" },
            arrowProps: { width: 100, height: 200, points: "0,80 100,0 0,150" },
        },
        topright: {
            style: { position: "absolute", left: "-25px", bottom: "-105px" },
            arrowProps: { width: 200, height: 100, points: "90,0 0,100 190,0" },
        },
        topleft: {
            style: { position: "absolute", right: "-100px", top: "-25px" },
            arrowProps: { width: 100, height: 200, points: "0,80 100,200 0,150" },
        },
        middleleft: {
            style: { position: "absolute", right: "-200px", top: "50px" },
            arrowProps: { width: 200, height: 100, points: "0,4 180,40 0,96" },
        },
    };

    const arrow = (() => {
        if (type === SPECIAL_STEP_TYPE.START || type === SPECIAL_STEP_TYPE.END) return null;
        const config = arrowConfig[`${positionX}${positionY}`];
        return (
            <div style={config.style}>
                <ModalWindowArrow {...config.arrowProps} />
            </div>
        );
    })();

    return (
        <div
            ref={modalWindowRef}
            className={classes.modalWindow}
            style={
                type !== "start" && type !== "end"
                    ? {
                          opacity: xOffset === 0 && yOffset === 0 ? 0 : 1,
                          top: yOffset + "px",
                          left: xOffset + "px",
                      }
                    : {
                          opacity: xOffset === 0 && yOffset === 0 ? 0 : 1,
                          top: "unset",
                          left: "unset",
                      }
            }
        >
            <CloseIcon data-testid="close-walkthrough-btn" className={classes.closeIcon} onClick={onClose} />
            <div className={classes.modalWindowTitle}>{title}</div>
            <div className={type === "start" ? classes.modalWindowContentStart : classes.modalWindowContent}>
                {content}
            </div>
            {type === "start" && <div className={classes.beforeStartQuestion}>{questionBeforeStart}</div>}
            <div className={classes.actionButtons}>
                {type === "start" && (
                    <button onClick={onNext} className={classes.mainStartButton}>
                        {t("walkthrough.lets-go")}
                    </button>
                )}
                {type === "default" && (
                    <button onClick={onNext} className={classes.mainButton}>
                        {t("walkthrough.next")}
                    </button>
                )}
                {type === "confirmation" && (
                    <button onClick={onNext} className={classes.mainButton}>
                        {t("global.ok")}
                    </button>
                )}
                {type === "default" && (
                    <div onClick={onComplete} className={classes.secondaryButton}>
                        {t("walkthrough.finish-tutorial")}
                    </div>
                )}
                {type === "start" && (
                    <div onClick={onLater} className={classes.secondaryButton}>
                        {t("walkthrough.later")}
                    </div>
                )}
                {(type === "end" || type === "later") && (
                    <button onClick={onComplete} className={classes.mainButton}>
                        {t("walkthrough.finish-tutorial")}
                    </button>
                )}
            </div>
            {(type === "default" || type === "later" || type === "confirmation") && arrow}
        </div>
    );
};
