import { Dialog, DialogContent, DialogActions, Button, DialogTitle } from "@material-ui/core";
import { Trans } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";

export const PatientDeletionTypeDialog = ({ open, onDelete, onAnonymize, onClose }) => {
    const theme = useTheme();
    const translationRoot = "patient.deletion.anonymize-or-delete-modal";
    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle style={{ paddingBottom: "2px", color: theme.palette.secondary.main }}>
                <Trans i18nKey={"global.delete-patient-button"} />
            </DialogTitle>
            <DialogContent>
                <Trans i18nKey={[translationRoot, "content"].join(".")} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}
                        color="primary"
                        size={'small'}
                        variant="outlined">
                    <Trans i18nKey={"global.cancel"}/>
                </Button>
                <Button onClick={onDelete}
                    color="secondary"
                    size={'small'}
                    variant="contained">
                    <Trans i18nKey={[translationRoot, "delete-btn"].join(".")} />
                </Button>
                <Button onClick={onAnonymize}
                    color="primary"
                    size={'small'}
                    variant="contained">
                    <Trans i18nKey={[translationRoot, "anonymize-btn"].join(".")} />
                </Button>
            </DialogActions>
        </Dialog>
    );
};
