import  { ItemStatusEnum } from 'domain/Step.model';
import moment from 'moment';

export const getStepStatus = (item) => {
    if(!item) return ItemStatusEnum.Normal;
    if(item.isCompleted) return ItemStatusEnum.Completed;
    if(item.date &&  moment(item.date).isBetween(moment().startOf('day'), moment().add(7, 'days'))) return ItemStatusEnum.Upcoming;
    if(item.date && moment().isAfter(moment(item.date).endOf('day'))) return ItemStatusEnum.Overdue;
    return ItemStatusEnum.Normal;
}
