import {uuid} from 'utils/Utils';

export function emptyCaseContactPerson() {
    return {
        businessId: uuid(),
        comment: '',
        salutation: '',
        title: '',
        givenName: '',
        familyName: '',
        phoneNumbers: [],
        emailAddresses: [],
        street: '',
        zipCode: '',
        city: '',
        state: '',
        country: 'Deutschland',
        personTypes: ['']
    }
}
