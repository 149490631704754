import React, {Component} from 'react';
import Flex from 'components/grid/Flex';
import {Typography} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import {rainForest} from 'components/colors/Colors';

export class EmailTypeHeader extends Component {
    render() {
        const {t: translate} = this.props;

        return (
            <Flex item container>
                <Flex item={`0 0 50px`} container/>
                <Flex item={`0 0 280px`} container>
                    <Typography style={{textTransform: 'uppercase', color: rainForest}}>
                        {translate(`global.theme`)}
                    </Typography>
                </Flex>
                <Flex item container justifyContent={'flex-end'} style={{marginRight: 5}}>
                    <Typography style={{textTransform: 'uppercase', color: rainForest}}>
                        {translate(`global.sent`)}
                    </Typography>
                </Flex>
            </Flex>
        );
    }
}

export default withTranslation()(EmailTypeHeader);
