import {positionalRelationShipReducer} from 'domain/ProgramStepTemplate.model';

export const mapProgramTemplateByCareProvider = (program, stepsToCreate) => (careProviderId) => {
    return {
        ...program,
        steps: stepsToCreate
            .map((step) => ({
                ...step,
                id: undefined,
                programTemplateId: undefined,
                parentProgramStepTemplateId: step.id
            })),
        id: undefined,
        type: "CARE_PROVIDER",
        sharedType: "INVITED",
        parentTemplateId: program.id,
        careProviderId,
        //NOTE: Since we dont know the new ids, this order have to be empty
        stepsOrder: [],
        stepDependencyPositionalRelations: stepsToCreate.reduce(positionalRelationShipReducer, [])
    };
}
