import React, {Component} from 'react';
import PersonIcon from '@material-ui/icons/Person';
import Dropzone from 'react-dropzone';
import Resizer from "react-image-file-resizer";
import HmoAvatar from 'components/avatar/HmoAvatar';
import {connect} from 'react-redux';
import connector from './HmoAvatar.connect';
import { withTheme } from '@material-ui/core/styles'

export class AvatarWithUpload extends Component {

    onDrop = (acceptedFiles) => {
        if (acceptedFiles) {
            try {
                Resizer.imageFileResizer(
                        acceptedFiles[0],
                        300,
                        300,
                        "JPEG",
                        100,
                        0,
                        file => {
                            this.props.uploadProfileImage(this.props.userDetails.businessId, file).then(
                                    () => this.props.refreshUserDetails()
                            )
                        },
                        "file",
                        200,
                        200
                );
            } catch (err) {
                console.log(err);
                this.setState({alert: 'not-an-image'});
            }
        }
    };

    render() {
        const {userDetails, theme} = this.props;
        return (
                <div style={{height: 150, width: 150, paddingLeft: 5, paddingBottom: 10}}>
                    {
                        userDetails.avatarDownloadUrl &&
                        <HmoAvatar user={userDetails} style={{height: 140, width: 140}}/>
                    }
                    {
                        !userDetails.avatarDownloadUrl &&
                        <Dropzone onDrop={this.onDrop}>
                            {({getRootProps, getInputProps, isDragActive}) => {
                                return (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <PersonIcon style={{
                                                height: 140, width: 140, borderRadius: '50%',
                                                fill: theme.palette.primary.light
                                            }}/>
                                        </div>
                                )
                            }}
                        </Dropzone>
                    }
                </div>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTheme(AvatarWithUpload));
