export const UPLOAD_CASE_FILE = '@file_handler/UPLOAD_CASE_FILE';
export const UPLOAD_CASE_FILE_SUCCESS = '@file_handler/UPLOAD_CASE_FILE_SUCCESS';
export const UPLOAD_CASE_FILE_FAIL = '@file_handler/UPLOAD_CASE_FILE_FAIL';

export const UPLOAD_PATIENT_FILE = '@file_handler/UPLOAD_PATIENT_FILE';

export const UPLOAD_CARE_PROGRAM_FILE = '@file_handler/UPLOAD_CARE_PROGRAM_FILE';

export const GET_UPLOAD_FILE_URL = '@file_handler/GET_UPLOAD_FILE_URL';
export const GET_UPLOAD_FILE_URL_SUCCESS = '@file_handler/GET_UPLOAD_FILE_URL_SUCCESS';
export const GET_UPLOAD_FILE_URL_FAIL = '@file_handler/GET_UPLOAD_FILE_URL_FAIL';

export const UPLOAD_CLINIC_CENTER_FILE = '@file_handler/UPLOAD_CLINIC_CENTER_FILE';
export const UPLOAD_CLINIC_CENTER_FILE_SUCCESS = '@file_handler/UPLOAD_CLINIC_CENTER_FILE_SUCCESS';
export const UPLOAD_CLINIC_CENTER_FILE_FAIL = '@file_handler/UPLOAD_CLINIC_CENTER_FILE_FAIL';

export const DOWNLOAD_CASE_FILE = '@file_handler/DOWNLOAD_CASE_FILE';
export const DOWNLOAD_CASE_FILE_SUCCESS = '@file_handler/DOWNLOAD_CASE_FILE_SUCCESS';
export const DOWNLOAD_CASE_FILE_FAIL = '@file_handler/DOWNLOAD_CASE_FILE_FAIL';

export const DOWNLOAD_SELECTED_FILE = '@file_handler/DOWNLOAD_SELECTED_FILE';
export const DOWNLOAD_SELECTED_FILE_SUCCESS = '@file_handler/DOWNLOAD_SELECTED_FILE_SUCCESS';
export const DOWNLOAD_SELECTED_FILE_FAIL = '@file_handler/DOWNLOAD_SELECTED_FILE_FAIL';

export const DOWNLOAD_CLINIC_CENTER_FILE = '@file_handler/DOWNLOAD_CLINIC_CENTER_FILE';
export const DOWNLOAD_CLINIC_CENTER_FILE_SUCCESS = '@file_handler/DOWNLOAD_CLINIC_CENTER_FILE_SUCCESS';
export const DOWNLOAD_CLINIC_CENTER_FILE_FAIL = '@file_handler/DOWNLOAD_CLINIC_CENTER_FILE_FAIL';

export const GET_FILE_ENCRYPTION_KEY = '@file_handler/GET_FILE_ENCRYPTION_KEY';
export const GET_FILE_ENCRYPTION_KEY_SUCCESS = '@file_handler/GET_FILE_ENCRYPTION_KEY_SUCCESS';
export const GET_FILE_ENCRYPTION_KEY_FAIL = '@file_handler/GET_FILE_ENCRYPTION_KEY_FAIL';

export const GET_FILE_DOWNLOAD_URL = '@file_handler/GET_FILE_DOWNLOAD_URL';
export const GET_FILE_DOWNLOAD_URL_SUCCESS = '@file_handler/GET_FILE_DOWNLOAD_URL_SUCCESS';
export const GET_FILE_DOWNLOAD_URL_FAIL = '@file_handler/GET_FILE_DOWNLOAD_URL_FAIL';

export const GET_SELECTED_FILE_DOWNLOAD_URL = '@file_handler/GET_SELECTED_FILE_DOWNLOAD_URL';
export const GET_SELECTED_FILE_DOWNLOAD_URL_SUCCESS = '@file_handler/GET_SELECTED_FILE_DOWNLOAD_URL_SUCCESS';
export const GET_SELECTED_FILE_DOWNLOAD_URL_FAIL = '@file_handler/GET_SELECTED_FILE_DOWNLOAD_URL_FAIL';

export const UPDATE_DOCUMENT = '@file_handler/UPDATE_DOCUMENT';
export const UPDATE_DOCUMENT_SUCCESS = '@file_handler/UPDATE_DOCUMENT_SUCCESS';
export const UPDATE_DOCUMENT_FAIL = '@file_handler/UPDATE_DOCUMENT_FAIL';

export const DELETE_CASE_FILE = '@file_handler/DELETE_CASE_FILE';
export const DELETE_CASE_FILE_SUCCESS = '@file_handler/DELETE_CASE_FILE_SUCCESS';
export const DELETE_CASE_FILE_FAIL = '@file_handler/DELETE_CASE_FILE_FAIL';

export const DELETE_CENTER_FILE = '@file_handler/DELETE_CENTER_FILE';
export const DELETE_CENTER_FILE_SUCCESS = '@file_handler/DELETE_CENTER_FILE_SUCCESS';
export const DELETE_CENTER_FILE_FAIL = '@file_handler/DELETE_CENTER_FILE_FAIL';

export const LIST_CLINIC_CENTER_FILES = '@file_handler/LIST_CLINIC_CENTER_FILES';
export const LIST_CLINIC_CENTER_FILES_SUCCESS = '@file_handler/LIST_CLINIC_CENTER_FILES_SUCCESS';
export const LIST_CLINIC_CENTER_FILES_FAIL = '@file_handler/LIST_CLINIC_CENTER_FILES_FAIL';

export const LIST_ATTACHMENTS = '@file_handler/LIST_ATTACHMENTS';
export const LIST_ATTACHMENTS_SUCCESS = '@file_handler/LIST_ATTACHMENTS_SUCCESS';
export const LIST_ATTACHMENTS_FAIL = '@file_handler/LIST_ATTACHMENTS_FAIL';

export const DOWNLOAD_INDICATE_FILES = '@file_handler/DOWNLOAD_INDICATE_FILES';
export const DOWNLOAD_INDICATE_FILES_SUCCESS = '@file_handler/DOWNLOAD_INDICATE_FILES_SUCCESS';
export const DOWNLOAD_INDICATE_FILES_FAIL = '@file_handler/DOWNLOAD_INDICATE_FILES_FAIL';
