import utils from './Utils';

export function encryptFile(file, key, iv) {
    return file.arrayBuffer().then(buffer => {
        const initializationVector = new TextEncoder("utf-8").encode(iv);
        const encryptionKey = new TextEncoder("utf-8").encode(key);
        const data = new Int8Array(buffer);
        return crypto.subtle.importKey("raw", encryptionKey.buffer, 'AES-CBC', false, ["encrypt", "decrypt"])
            .then(encodedKey => {
                return crypto.subtle.encrypt({name: "AES-CBC", iv: initializationVector}, encodedKey, data)
                    .then(encryptedContent => {
                        return encryptedContent;
                    })
            })

    });
}

export function decryptAndDownloadDocument(encryptedContent, key, iv, fileName) {
    return decryptDocument(encryptedContent, key, iv)
        .then(decryptedContent => {
            let url = window.URL.createObjectURL(new Blob([decryptedContent], {type: "application/octet-stream"}));
            utils.triggerNativeDownload(url, fileName)
        });
}

export function decryptAndReturn(encryptedContent, key, iv) {
    return decryptDocument(encryptedContent, key, iv)
        .then(decryptedContent => {
            return window.URL.createObjectURL(new Blob([decryptedContent], {type: "application/octet-stream"}));
        });
}

export function decryptDocument(encryptedContent, key, iv) {
    const initializationVector = new TextEncoder("utf-8").encode(iv);
    const encryptionKey = new TextEncoder("utf-8").encode(key);
    return encryptedContent.arrayBuffer().then(buffer => {
        const data = new Int8Array(buffer);
        return crypto.subtle.importKey("raw", encryptionKey.buffer, 'AES-CBC', false, ["encrypt", "decrypt"])
            .then(encodedKey => {
                return crypto.subtle.decrypt({name: "AES-CBC", iv: initializationVector}, encodedKey, data)
            });
    });
}

export default {
    encryptFile,
    decryptAndDownloadDocument,
    decryptDocument,
    decryptAndReturn
};
