import React from 'react';
import {withStyles} from "@material-ui/core";
import * as colors from 'components/colors/Colors';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import ClinicMap from 'scenes/clinics/components/ClinicMap';
import SidebarInput from 'components/input/SidebarInput';

const styles = theme => ({
    title: {
        color: colors.transparentBlack054,
        fontSize: 12,
        textTransform: 'uppercase'
    },
});

export class ClinicSidebar extends React.PureComponent {

    render() {
        const {t: translate, selectedClinic, onChange, disabled, style} = this.props;

        return (
            <div id={'right sidebar'}
                 style={{
                     width: 370,
                     minWidth: 370,
                     maxWidth: 370,
                     padding: 20,
                     backgroundColor: colors.concrete,
                     overflow: 'auto',
                     ...style
                 }}>
                <ClinicMap {...{selectedClinic, width: 330, height: 400}}/>
                <SidebarInput {...{
                    value: selectedClinic.name,
                    name: 'name',
                    inputLabel: 'global.name',
                    translate, onChange, disabled,
                }}/>
                <SidebarInput {...{
                    value: _.get(selectedClinic, 'street'),
                    name: 'street',
                    inputLabel: 'case.street',
                    translate, onChange: onChange, disabled,
                }}/>
                <SidebarInput {...{
                    value: _.get(selectedClinic, 'zipCode'),
                    name: 'zipCode',
                    inputLabel: 'case.zip',
                    translate, onChange: onChange, disabled,
                }}/>
                <SidebarInput {...{
                    value: _.get(selectedClinic, 'city'),
                    name: 'city',
                    inputLabel: 'case.city',
                    translate, onChange: onChange, disabled,
                }}/>
                <SidebarInput {...{
                    value: _.get(selectedClinic, 'state'),
                    name: 'state',
                    inputLabel: 'case.state',
                    translate, onChange: onChange, disabled,
                }}/>
                <SidebarInput {...{
                    value: _.get(selectedClinic, 'country'),
                    name: 'country',
                    inputLabel: 'case.country',
                    translate, onChange: onChange, disabled,
                }}/>
                <SidebarInput {...{
                    value: _.get(selectedClinic, 'website'),
                    name: 'website',
                    inputLabel: 'case.website',
                    translate, onChange, disabled,
                }}/>
                <SidebarInput {...{
                    value: _.get(selectedClinic, 'center[0].manager'),
                    name: 'manager',
                    inputLabel: 'clinic.contact-person',
                    translate, onChange: () => {
                    }, disabled,
                }}/>
            </div>
        );
    }
}

export default withStyles(styles)(withTranslation()(ClinicSidebar));
