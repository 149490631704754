import React, {Component} from 'react';
import {Button, FormControl, IconButton, InputAdornment, Typography, withStyles,} from "@material-ui/core";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from '@material-ui/icons/Visibility';
import {htmlOrange, transparentBlack054} from 'components/colors/Colors';
import HmoTextField from 'components/hmo-textfield/HmoTextField';
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';

const styles = theme => ({
    submit: {
        marginTop: 20
    },
    forgotPassword: {
        color: transparentBlack054,
        textTransform: 'unset',
        padding: 'unset',
        fontSize: 12
    }
});

export class LoginPage extends Component {
    render() {
        const {
            classes,
            t: translate,
            warning,
            userName,
            password,
            mfa,
            verifyCode,
            handleSubmit,
            onEnter,
            changeState,
            passwordInputRef,
            togglePasswordVisibility,
            isPasswordVisible,
            changeView,
            goToRegister
        } = this.props;

        return <Flex item container column style={{width: '100%', maxWidth: '450px'}}>
            <Flex item container style={{marginBottom: 20}} column>
                <Typography style={{fontSize: 16, fontWeight: 'bold'}}>
                    {translate('login.welcome')}
                </Typography>
                <Typography style={{opacity: 0.85}}>
                    {translate('login.welcome-text')}
                </Typography>
            </Flex>
            <form>
                <FormControl margin="normal" required fullWidth>
                    <HmoTextField
                            name={'userName'}
                            label={translate('login.username')}
                            autoFocus
                            inputProps={{
                                autoCapitalize: 'off'
                            }}
                            type="text"
                            value={userName}
                            onEnterKey={onEnter}
                            onChange={changeState}
                            transparentBg
                            disabled={mfa}
                    />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                    {mfa && (<HmoTextField
                            name={'verifyCode'}
                            label={translate('login.verifyCode')}
                            helperText={warning ? translate("login.incorrect-verification") : ''}
                            error={!!warning}
                            autoFocus
                            inputProps={{
                                autoCapitalize: 'off'
                            }}
                            type="text"
                            value={verifyCode}
                            onEnterKey={onEnter}
                            onChange={changeState}
                            transparentBg
                    />)}
                    {!mfa && (<HmoTextField
                            name={'password'}
                            label={translate('login.password')}
                            inputRef={passwordInputRef}
                            helperText={warning ? translate("login.incorrect") : ''}
                            error={!!warning}
                            type={isPasswordVisible ? 'text' : 'password'}
                            value={password}
                            onChange={changeState}
                            onEnterKey={handleSubmit}
                            transparentBg
                            InputProps={{
                                endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                    aria-label="Toggle password visibility"
                                                    onClick={togglePasswordVisibility}
                                            >
                                                {isPasswordVisible ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>)
                            }}
                    />)}
                </FormControl>

                <Flex item container justifyContent={!mfa ? 'space-between' : 'flex-end'}>
                    {
                        mfa
                                ? (
                                        <Button className={classes.forgotPassword} onClick={() => changeView('LOGIN')}>
                                            {translate('login.back-login')}...
                                        </Button>)
                                : <>
                                    <div style={{display: 'flex', alignItems: 'baseline'}}>
                                    <span style={{fontSize: '12px', fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.64)', paddingRight: '4px'}}>
                                        {translate('global.new-here')} {' -> '}
                                    </span>
                                    <Button className={classes.forgotPassword} onClick={goToRegister}>
                                        {translate('global.register')}
                                    </Button>
                                    </div>
                                    <Button data-testid="forgot-password-btn" className={classes.forgotPassword} onClick={() => changeView('FORGOT')}>
                                        {translate('login.forgot-password')}...
                                    </Button>
                                </>

                    }
                </Flex>
                {mfa &&
                <Typography style={{
                    color: htmlOrange,
                    fontWeight: 'bold',
                    paddingTop: 10
                }}>{translate('login.two-factor-label')}</Typography>
                }
                <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={!userName || !password}
                        className={classes.submit}
                        onClick={handleSubmit}
                        data-testid="login-button"
                >
                    {mfa ? translate('login.verify-button') : translate('login.login-button')}
                </Button>
            </form>
        </Flex>
    }
}

export default withStyles(styles)(withTranslation()(LoginPage));
