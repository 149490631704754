import {Modal} from "@material-ui/core";
import {
    Box,
    Grid,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Button,
    IconButton,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import {useGetCareProviderPatientAccessesQuery} from "scenes/patient/CareProviderApi";
import {useTranslation} from "react-i18next";
import {useIsManager} from "utils";
import {useTheme} from "@material-ui/core/styles";

const DeleteCareProviderAccessModalContent = ({onClose, onDelete, dispatchAction, careProviderAccess, patientId}) => {
    const {data = {}} = useGetCareProviderPatientAccessesQuery(
            {careProviderId: careProviderAccess.id, patientId},
            careProviderAccess?.id,
            {skip: true}
    );
    const theme = useTheme();
    const {t} = useTranslation();
    const userIsManager = useIsManager();

    const contentLabel = [
        "deleteCareProviderAccessModal",
        userIsManager ? "manager" : "patient",
        userIsManager ? "" : data.selfAssigned ? "selfAssigned" : "managerAssigned",
        "content",
    ]
            .filter((x) => x !== "")
            .join(".");

    const cancelLabel = data.selfAssigned ? "global.no" : "global.cancel";

    return <Box mt={"40vh"}>
        <Grid container justifyContent="center">
            <Grid item xs={11} sm={10} md={8} lg={4} xl={3}>
                <Card>
                    <CardHeader style={{color: theme.palette.error.main}}
                            title={t('deleteCareProviderAccessModal.title')}
                            action={
                                <>
                                    <IconButton
                                            onClick={() => {
                                                onClose();
                                            }}
                                            size="small"
                                    >
                                        <CloseIcon/>
                                    </IconButton>
                                </>
                            }
                    />
                    <CardContent>
                        {t(contentLabel, {clinicName: careProviderAccess?.name})}
                    </CardContent>

                    <CardActions>
                        <Box marginLeft={"auto"} display={"flex"} style={{gap: "8px"}}>
                            <Button size="small" onClick={onClose} variant="outlined">
                                {t(cancelLabel).toUpperCase()}
                            </Button>
                            <Button
                                    size="small"
                                    onClick={() => {
                                        onDelete(data.accessAssignmentId);
                                    }}
                                    type="submit"
                                    style={{backgroundColor: theme.palette.error.main, color: 'white'}}
                                    variant="outlined"
                                    color="primary"
                            >
                                {t('deleteCareProviderAccessModal.revoke-access-button')}
                            </Button>
                        </Box>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    </Box>;
};

export const DeleteCareProviderAccessModal = ({open, ...params}) => {
    return (
            <Modal open={open}>
                <div>
                    <DeleteCareProviderAccessModalContent {...params} />
                </div>
            </Modal>
    );
};
