import React, {useCallback, useEffect, useState, createContext} from 'react';
import {Form} from "formik";
import GeneralForm from "./section/GeneralForm";
import ChipForm from "./section/ChipForm";
import CardioVascularForm from "./section/CardiovascularForm";
import ClinicalSymptomsForm from "./section/ClinicalSymptomsForm";
import QualityOfLife from "./section/QualityOfLifeForm";
import CancerForm from "./section/CancerForm";
import CardioDiseaseForm from "./section/CardioDiseaseForm";
import Comorbidities from "./section/ComorbiditiesForm";
import AllergiesForm from "./section/AllergiesForm";
import OtherDiseaseForm from "./section/OtherDiseaseForm";
import {useDebouncedCallback} from "use-debounce";
import {makeStyles} from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import {useTranslation} from "react-i18next";
import HematologyForm from "./section/HematologyForm";
import ArthroseForm from "./section/ArthroseForm";
import { AnamnesisFilters } from './section/AnamnesisFilters';
import { QuestionWithFiltering } from './section/QuestionWithFiltering';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '10px',
        '& .MuiGrid-root.MuiGrid-container': {
            margin: "0px"
        },

        '& h4' : {
            margin: 0,
            minWidth: 'max-content'
        },

        '& label' : {
            minWidth: 'max-content'
        }
    },
}));

const AnamnesisFormWrapper = ({form, onSave, patientData, setUserDetails, user, readonly}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const lang = useCallback((name) => t(`anamnesis.${name}`), [t]);

    const [filters, setFilters] = useState({
        yes: true,
        no: true,
        notAnswered: true,
    });

    const debouncedHandleOnSave = useDebouncedCallback((values) => {
        onSave(values, form);
    }, 2000);

    useEffect(() => {
        if(form.isValid) {
            debouncedHandleOnSave(form.values);
        }
    }, [form])

    return (
        <Form>
            <div className={classes.root}>

                <Box justifyContent="space-between" alignItems="center" p="10px" display="flex">
                    <Typography component="h1" data-testid="anamnesis-welcome-title" variant="h3" color="primary">
                        {lang('mainTitle')}
                    </Typography>
                    <AnamnesisFilters filters={filters} setFilters={setFilters}/>
                </Box>
                    <GeneralForm {...{form, patientData, setUserDetails, user, readonly}}/>
                    <ChipForm form={form} readonly={readonly} filters={filters} filterValue={form.values?.["hasChipMutation"]}/>
                    <HematologyForm form={form} readonly={readonly} filters={filters}/>
                    <QuestionWithFiltering filters={filters} filterValue={form.values?.haveArthrose}>
                        <ArthroseForm form={form} readonly={readonly}/>
                    </QuestionWithFiltering>
                    <QuestionWithFiltering filters={filters} filterValue={form.values?.haveAllergies}>
                        <AllergiesForm form={form} readonly={readonly}/>
                    </QuestionWithFiltering>
                    <CardioVascularForm form={form} readonly={readonly} filters={filters}/>
                    <ClinicalSymptomsForm form={form} readonly={readonly} filters={filters}/>
                    <QualityOfLife form={form} readonly={readonly} filters={filters}/>
                    <CancerForm form={form} readonly={readonly} filters={filters}/>
                    <CardioDiseaseForm form={form} readonly={readonly} filters={filters}/>
                    <Comorbidities form={form} readonly={readonly} filters={filters}/>
                    <OtherDiseaseForm form={form} readonly={readonly} filters={filters}/>
            </div>
        </Form>
    );
};

export default AnamnesisFormWrapper;
