import React, {useState} from 'react';
import {Typography, Paper} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {styled} from '@material-ui/core/styles';
import {grey240} from 'components/colors/Colors';

const Container = styled((props) => <Paper {...props} />)(({theme, open}) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    marginBottom: 5,
    boxShadow: open ? theme.shadows[3] : theme.shadows[1]
}));

export default function Accordion(props) {
    const {
        openByDefault = false,
        children,
        titleComponent,
        title,
        padding,
        accordionOpen,
        setAccordionOpen,
        disabled,
        style,
        borderStyle,
        containerStyle,
        ...other
    } = props;
    const [open, setOpen] = useState(openByDefault);
    const toggle = () => disabled ? () => {
    } : setOpen(open => !open);
    const isOpen = setAccordionOpen ? accordionOpen : open;

    return (
            <Container open={isOpen} {...other} style={containerStyle}>
                <Flex item grow={0} container alignItems={'center'}
                      onClick={setAccordionOpen ? setAccordionOpen : toggle}
                      style={{
                          backgroundColor: grey240,
                          padding: 10,
                          margin: -10,
                          ...(!isOpen && borderStyle),
                          ...style
                      }}>
                    {
                            !disabled &&
                            <ExpandMoreIcon style={{transform: isOpen ? 'rotate(180deg)' : 'unset'}}/>
                    }
                    <Typography style={{
                        marginRight: 10,
                        fontSize: 14,
                        textTransform: 'uppercase'
                    }}>{title}</Typography>
                    {titleComponent && titleComponent}
                </Flex>
                <Flex item={0} style={{marginTop: isOpen ? 13 : 0}}>
                    {
                            isOpen && children
                    }
                </Flex>

            </Container>
    );
}
