import {
    logout
} from 'scenes/Main.action';
import {fetchCurrentUser} from 'scenes/user-manager/UserManager.action';
export default {
    mapStateToProps: state => {
        return {
            user: state.mainReducer.user,
            roles: state.mainReducer.user?.roles,
        }
    },
    mapDispatchToProps: dispatch => {
        return {
            fetchCurrentUser: () => dispatch(fetchCurrentUser()),
            logout: () => dispatch(logout()),
        }
    }
}
