import React from 'react';
import {Checkbox, Typography, Paper} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import moment from 'moment';
import utils from 'utils/Utils';
import {styled} from '@material-ui/core/styles';
const Container = styled((props) => <Paper elevation={2} {...props} />)(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
    padding: '5px 0px',
    marginBottom: 5,
    border: '1px solid lightgrey'
}));

export default function EmailTypeRow(props) {
    const {
        classes, translate, type ,caseLogs, isActOfAssignmentEnabled, selectedEmailTypes, selectEmailType,
        isInsuranceCovered, doesUserExists
    } = props;
    let disabled = false;
    let notEnoughData = props.notEnoughData;
    let timeStamp;
    const caseLogCopy = [...caseLogs].reverse();
    switch (type) {
        case 'WELCOME': {
            timeStamp = caseLogCopy.find(log => log.type === 'WELCOME_EMAIL_SENT')?.timestamp;
            notEnoughData = props.notEnoughData || !isInsuranceCovered;
            break;
        }
        case 'COST_NOTE': {
            timeStamp = caseLogCopy.find(log => log.type === 'COST_NOTE_EMAIL_SENT')?.timestamp;
            break;
        }
        case 'POWER_OF_ATTORNEY': {
            timeStamp = caseLogCopy.find(log => log.type === 'POWER_OF_ATTORNEY_EMAIL_SENT')?.timestamp;
            break;
        }
        case 'ACT_OF_ASSIGNMENT': {
            timeStamp = caseLogCopy.find(log => log.type === 'ACT_OF_ASSIGNMENT_EMAIL_SENT')?.timestamp;
            disabled = !isActOfAssignmentEnabled;
            break;
        }
        case 'QUESTIONNAIRE':
        default:
            break;
    }
    return (
        disabled ? <></> :
        <Container>
            <Flex item={`0 0 50px`} container>
                <Checkbox
                    checked={selectedEmailTypes.includes(type)}
                    onChange={selectEmailType(type)}
                    disabled={disabled || notEnoughData}
                    checkedIcon={
                        <CheckBoxIcon />
                    }
                />
            </Flex>
            <Flex item={`0 0 330px`} container>
                <Typography className={selectedEmailTypes.includes(type)
                    ? classes.cellText
                    : classes.notSelectedCellText} style={{opacity: disabled || notEnoughData ? 0.5 : 1}}>
                    {translate(`email-types.${type}`)}
                </Typography>
                {
                    type === 'WELCOME' && notEnoughData && !isInsuranceCovered &&
                    <Typography style={{color: 'orange', marginLeft: 10}}>({translate(`case.insurance-required`)})</Typography>
                }
                {
                    type === 'WELCOME' && notEnoughData && isInsuranceCovered &&
                    <Typography style={{color: 'orange', marginLeft: 10}}>({translate(`global.missing-data`)})</Typography>
                }
            </Flex>
            <Flex item container justifyContent={'flex-end'} style={{marginRight: 5}}>
                {timeStamp ? moment(timeStamp).format(utils.VIEW_DATE_FORMAT) : ''}
            </Flex>
        </Container>
    );
};
