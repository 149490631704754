import PatientSidebarItem from "./PatientSidebarIem";
import ChatLog from 'components/log/ChatLog';
import {useTranslation} from "react-i18next";
const PatientSidebarChat = ({patientId}) => {
    const { t:translate } = useTranslation();
    return (
        <PatientSidebarItem title={translate("global.chat")}>
            <ChatLog entityId={patientId} entityType={"FULL_PATIENT"}/>
        </PatientSidebarItem>
    )
}

export default PatientSidebarChat;
