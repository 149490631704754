export const SPECIAL_STEP_TYPE = Object.freeze({
    START: "start",
    END: "end",
    LATER: "later"
});

export const newMedicationWalkthroughSteps = [
    {
        title: "medicine-name",
        selector: "[walkthrough-element=\"ingredient\"]",
        positionX: "bottom",
        positionY: "right",
        offsetX: -300,
        offsetY: 74,
    },
    {
        title: "medicine-dosage",
        selector: "[walkthrough-element=\"medicine-dosage\"]",
        positionX: "bottom",
        positionY: "right",
        offsetX: -20,
        offsetY: 80,
    },
    {
        title: "medicine-form",
        selector: "[walkthrough-element=\"medicine-form\"]",
        positionX: "bottom",
        positionY: "right",
        offsetX: -130,
        offsetY: 80,
    },
    {
        title: "medicine-timeframe",
        selector: "[walkthrough-element=\"medicine-timeframe\"]",
        positionX: "bottom",
        positionY: "right",
        offsetX: -330,
        offsetY: 110,
    },
    {
        title: "medicine-application",
        selector: "[walkthrough-element=\"medicine-application\"]",
        positionX: "top",
        positionY: "right",
        offsetX: -130,
        offsetY: 30,
    },
    {
        title: "medicine-hint",
        selector: "[walkthrough-element=\"medicine-hint\"]",
        positionX: "top",
        positionY: "right",
        offsetX: -200,
        offsetY: -52,
    },
    {
        title: "medicine-indication",
        selector: "[walkthrough-element=\"medicine-hint\"]",
        positionX: "top",
        positionY: "right",
        offsetX: -340,
        offsetY: 20,
    },
    {
        title: "medicine-self-medication",
        selector: "[walkthrough-element=\"medicine-self-medication\"]",
        positionX: "top",
        positionY: "right",
        offsetX: 10,
        offsetY: -92,
    },
];
