import {
    updateCase,
    fetchPatientCoordinates,
} from 'scenes/case/edit/CaseEdit.action';

export default {
    mapStateToProps: (state) => {
        return {
            clinicCertifications: state.caseReducer.configuration.clinicCertifications,
            subIndications: (state.caseReducer.configuration.subIndications || []).map(si => si.subIndication),
            clinicCenters: state.clinicsReducer.clinicCentersWithClinicData,
            clinicCenterBusinessId: state.caseEditReducer.case.clinicCenterBusinessId || '',
            patientBusinessId: state.caseEditReducer.case?.patient?.businessId
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            updateCase: update => dispatch(updateCase(update)),
            fetchPatientCoordinates: (patientBusinessId) => dispatch(fetchPatientCoordinates(patientBusinessId)),
        };
    }
};
