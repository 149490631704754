import { useContext } from "react";
import { inputRendererMap } from "../../../../filter-configs/inputRendererMap";
import filterSate from "../../../../filter-state";
import { Trans, useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import { RemoveRuleBtn } from "../node-control-components/RemoveRuleBtn";
import { AddOrClauseButton } from "../node-control-components/AddOrClauseButton";
import { DisableToggle } from "../node-control-components/DisableToggle";
import { WidgetType } from "../../../../filter-configs/widgetTypes";

export const OrGroupRenderer = ({ config, rule, idx, indexPath, widgetType }) => {
    const { dispatch } = useContext(filterSate.queryFormContext);
    const { t } = useTranslation();

    const handleUpdateGroup = (updatedProperties) => {
        dispatch({
            type: filterSate.actions.UPDATE_NODE,
            indexPath: indexPath,
            payload: {
                properties: {
                    ...rule.properties,
                    ...updatedProperties,
                },
            },
        });
    };

    const handleUpdateRule = (_idx, updatedProperties) => {
        dispatch({
            type: filterSate.actions.UPDATE_NODE,
            indexPath,
            payload: {
                properties: updatedProperties,
            },
        });
    };

    const innerHandleUpdateRule = (innerRuleIdx, updatedProperties) => {
        const fullIndexPath = indexPath.concat(innerRuleIdx);
        dispatch({
            type: filterSate.actions.UPDATE_NODE,
            indexPath: fullIndexPath,
            payload: {
                properties: updatedProperties,
            },
        });
    };

    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
            <DisableToggle rule={rule} idx={idx} handleUpdateRule={handleUpdateRule} />
            {config.showLabel !== false && (
                <Box display="flex" alignItems="center" mr={1} marginTop={"7px"}>
                    <Trans i18nKey={config.label || rule.properties.field} />
                </Box>
            )}
            <div>
                {rule.properties.value.map((v, innerRuleIdx) => {
                    const currentIndexPath = indexPath.concat(innerRuleIdx);
                    const Renderer = inputRendererMap[widgetType];
                    return (
                        <div
                            key={`${idx}-${innerRuleIdx}`}
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginBottom: "22px",
                                position: "relative",
                            }}
                        >
                            {innerRuleIdx !== 0 && widgetType !== WidgetType.NESTED && (
                                <div
                                    style={{
                                        position: "absolute",
                                        left: "-16px",
                                        transform: 'translateX(-100%)'
                                    }}
                                >

                                {t('global.or').toUpperCase()}
                                </div>
                            )}
                            <Renderer
                                inOrGroup={true}
                                groupExclude={rule.properties.exclude}
                                rule={v}
                                config={config}
                                idx={innerRuleIdx}
                                handleUpdateRule={innerHandleUpdateRule}
                                handleUpdateGroup={handleUpdateGroup}
                            />
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <AddOrClauseButton
                                    onClick={() => {
                                        dispatch({
                                            type: filterSate.actions.ADD_GROUP_RULE,
                                            indexPath: currentIndexPath,
                                            groupIndex: idx,
                                            index: innerRuleIdx,
                                        });
                                    }}
                                />
                                <RemoveRuleBtn
                                    onClick={() => {
                                        dispatch({
                                            type: filterSate.actions.REMOVE_GROUP_RULE,
                                            indexPath: currentIndexPath,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
