import * as actionTypes from './Program.action.types';

//TODO LM: replace with useLazyGetAccessesOfferedQuery
export function getAccessesOffered(patientId, personUserDetailsId, role) {
    return {
        type: actionTypes.GET_PATIENT_PROGRAMS,
        payload: {
            client: 'api',
            request: {
                method: 'post',
                url: `access-offered`,
                data:{
                    patientId,
                    personUserDetailsId,
                    role
                }
            }
        }
    }
}
