import { Typography, IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Flex from "components/grid/Flex";
import Accordion from "components/grid/Accordion";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {useTheme} from "@material-ui/core/styles";

const CareProviderWithAccess = ({ careProviderAccess, onDelete }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <Accordion
            disabled
            key={careProviderAccess.id}
            accordionOpen={false}
            containerStyle={{
                boxShadow: "unset",
                border: "1px solid blue",
                borderColor: careProviderAccess?.accessRemovalRequested ? theme.palette.error.main: "blue",
                borderRadius: 10,
            }}
            style={{
                backgroundColor: careProviderAccess?.accessRemovalRequested ? theme.palette.grey[200]: "white",
                borderRadius: 10,
            }}
            titleComponent={
                <Flex item style={{ alignItems: "center",}} container justifyContent={"space-between"}>
                    <Flex item container column>
                        <Typography style={{ fontWeight: "bold" }}>{careProviderAccess?.name}</Typography>
                        <Typography style={{ fontSize: 11 }}>{t("global.care-provider")}</Typography>
                    </Flex>

                        <IconButton
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete(e);
                            }}
                        >
                            <DeleteOutlineIcon style={{ fontSize: "21px" }} />
                        </IconButton>
                </Flex>
            }
        />
    );
};

export default CareProviderWithAccess;
