import React, {Component} from 'react';
import {
    withStyles,
    IconButton,
    Typography,
} from "@material-ui/core";
import {Add, Delete} from '@material-ui/icons';
import Flex from 'components/grid/Flex';
import {htmlWhite, persianGreen} from 'components/colors/Colors';
import _ from 'lodash';
import {Autocomplete} from '@material-ui/lab';
import DefaultTextField from 'components/hmo-textfield/DefaultTextField';

const styles = theme => ({
    inputItem: {
        padding: '7px 7px',
    },
    inputRoot: {
        backgroundColor: htmlWhite,
    },
    tag: {
        margin: 2,
        borderRadius: 7,
        color: persianGreen,
        fontWeight: 'bold',
        textTransform: 'capitalize',
        padding: '1px 7px 2px',
        border: `1px solid ${persianGreen}`,
        minWidth: 150,
        marginRight: 10
    },
});

const FreeTagSelect = (props) => {
    const {options, onChange, onInputChange, addAndSelectNewOption, value, handleKeyPress, componentProps } = props;


    return (
        <Flex item={'0 0 0'} container style={{minWidth: 250}} alignItems={'center'}>
            <Autocomplete
                options={options || []}
                onChange={onChange}
                onInputChange={onInputChange}
                value={value || ''}
                freeSolo
                fullWidth
                onKeyPress={handleKeyPress}
                size="small"
                renderInput={(params) => <DefaultTextField {...params} standAlone />}
                {...componentProps}
            />
            {
                 !_.get(componentProps, 'disabled', false)  &&
                <IconButton style={{padding: 3}} onClick={addAndSelectNewOption}>
                    <Add style={{fill: value ? 'orange' : ''}}/>
                </IconButton>
            }
        </Flex>
    );
};

const Tag = (props) => {
    const {classes, tag, onDelete, value } = props;
    return (
        <Flex item={'0 0 auto'} container className={classes.tag} justifyContent={'space-between'} alignItems={'center'}>
            <Typography style={{fontSize: 16}}>{value ?? tag}</Typography>
            <IconButton onClick={onDelete(tag)} style={{padding: 3}}>
                <Delete style={{fill: persianGreen}}/>
            </IconButton>
        </Flex>
    );
};

export class AutoTags extends Component {

    state = {
        value: '',
    }

    onInputChange = (event, value) => {
        this.setState({
            value: value || ''
        })
    };

    addValue = (value) => {
        this.setState({
            value: ''
        }, () => {

            if ( this.props.selectable != null && !this.props.selectable(this, value)  ) {
                return;
            }

            if ( _.isObject(value) && _.has(value, 'value') ) {
                this.props.onChange && this.props.onChange(_.union(this.props.selectedValues, [value.value]));
            } else {
                this.props.onChange && this.props.onChange(_.union(this.props.selectedValues, [value]));
            }
        });
    }

    onDelete = tag => event => {
        this.props.onChange && this.props.onChange(_.difference(this.props.selectedValues, [tag]));
    }

    onChange = (value, values) => {
        if ( !_.isArray(value) && _.has(values,'value') ) {
            this.setState({
                value: values
            });
        } else {
            if ((values || []).includes(value)) {
                this.addValue(value);
            }
        }
    };

    addAndSelectNewOption = event => {
        event.preventDefault();
        event.stopPropagation();
        if (this.state.value) {
            this.addValue(this.state.value);
        }
    };

    handleKeyPress = event => {
        const isEnterPressed = (event.key && event.key === 'Enter') || (event.keyCode && event.keyCode === 13);
        const isShiftPressed = event.shiftKey;
        if (isEnterPressed && !isShiftPressed) {
            this.addValue(this.state.value);
        }
    };

    render() {
        const {classes, options, style, componentProps, selectedValues, tagProps } = this.props;
        const {value} = this.state;
        const {onChange, onInputChange, addAndSelectNewOption, onDelete, handleKeyPress} = this;

        return (
            <Flex item container style={{...style, flexWrap: 'wrap'}} alignItems={'center'}>
                {
                    selectedValues.map(selectedValue => (
                        <Tag key={selectedValue} classes={classes} tag={selectedValue} onDelete={onDelete} value={ tagProps?.value(selectedValue)  }  />
                    ))
                }

                {
                    _.get(this.props, 'isVisible', true) &&
                    <FreeTagSelect {...{
                        componentProps, options, onChange, onInputChange, addAndSelectNewOption, handleKeyPress, value
                    }}/>
                }
            </Flex>
        );
    }
}


export default withStyles(styles)(AutoTags);
