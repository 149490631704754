import moment from 'moment';
import utils from 'utils/Utils';

export function toDates(string){
    const dates = string.split(';');
    return {
        from: moment(dates[0]),
        to: moment(dates[1])
    }
}

export function toDatesString(ranges){
    return ranges.map(range=>{
        return range.from.utc().format(utils.API_DATE_FORMAT) + ';' + range.to.utc().format(utils.API_DATE_FORMAT);
    });
}
