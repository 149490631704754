import React, {Component} from 'react';
import Flex from 'components/grid/Flex';
import GoogleMapReact from 'google-map-react';
import configs from "configs/Configs";
import * as colors from 'components/colors/Colors';
import _ from 'lodash';
import { IconButton } from "@material-ui/core";

export class Marker extends Component {

    render() {
        const {clinic, zoom} = this.props;
        return (
            <div style={{position: 'absolute'}}>
                <IconButton style={{
                    padding: 0,
                    position: 'absolute',
                    width: 50 * (zoom / 10),
                    height: 50 * (zoom / 10),
                    top: -(60 * (zoom / 10)),
                    left: -(50 / 2 * (zoom / 10))
                }}>
                    <div style={{
                        width: 50 * (zoom / 10),
                        height: 50 * (zoom / 10),
                        borderRadius: '50% 50% 50% 0',
                        background: colors.jaffa,
                        transform: 'rotate(-45deg)',
                    }}>
                        <div style={{
                            width: 30 * (zoom / 10),
                            height: 30 * (zoom / 10),
                            borderRadius: '50%',
                            margin: `${10 * (zoom / 10)}px 0 0 ${10 * (zoom / 10)}px`,
                            backgroundColor: colors.htmlWhite,
                            color: colors.jaffa,
                            transform: 'rotate(45deg)',
                            fontWeight: 'bold',
                            fontSize: `${
                                24 * (zoom / 10)
                            }px`,
                            paddingTop: 2
                        }}>
                            {clinic.rating || '-'}
                        </div>
                    </div>
                </IconButton>
            </div>
        )
    }
}

export class ClinicMap extends Component {

    state = {
        zoom: 5,
        googleApi: {},
    };

    handleMapChange = (googleApiProps) => {
        if (googleApiProps.zoom !== this.state.zoom) {
            this.setState({zoom: googleApiProps.zoom});
        }
    };

    handleGoogleApiLoad = ({map, maps, ...rest}) => {
        this.setState({
            googleApi: {map, maps, ...rest}
        });
    };

    render() {
        const {width, height, style, selectedClinic = {}} = this.props;
        const address = {
            street: selectedClinic.street,
            zipCode: selectedClinic.zipCode,
            country: selectedClinic.country,
            state: selectedClinic.state,
            city: selectedClinic.city
        };
        const markerExists = !_.isEmpty(selectedClinic)
        && _.get(selectedClinic, 'latitude')
        && _.get(selectedClinic, 'longitude')
        && selectedClinic.name;

        return (
            <Flex item={'0 1 auto'} container style={{...style, width, height}}>
                {
                    <GoogleMapReact
                        bootstrapURLKeys={{key: configs.getGoogleMapsApiKey()}}
                        defaultCenter={{
                            lat: 51.1657,
                            lng: 10.4515
                        }}
                        defaultZoom={5}
                        onChange={this.handleMapChange}
                        yesIWantToUseGoogleMapApiInternals={true}
                        onGoogleApiLoaded={this.handleGoogleApiLoad}
                    >
                        {
                            markerExists &&
                            <Marker
                                clinic={selectedClinic}
                                address={address}
                                lat={selectedClinic.latitude}
                                lng={selectedClinic.longitude}
                                key={selectedClinic.name}
                                zoom={this.state.zoom}
                            />
                        }
                    </GoogleMapReact>
                }
            </Flex>
        );
    }
}

export default ClinicMap;
