import * as actionTypes from './Registration.action.types';

export function register(props) {
    return {
        type: actionTypes.REGISTER,
        payload: {
            client: 'auth',
            request: {
                method: 'post',
                url: `register`,
                data: props
            }
        }
    }
}

export function getEmailForToken(inviteToken) {
    return {
        type: actionTypes.GET_EMAIL_FOR_TOKEN,
        payload: {
            client: 'auth',
            request: {
                method: 'get',
                url: `get-registration-data-for-token/${inviteToken}`,
            }
        }
    }
}

export function listCareProvidersForRegistration() {
    return {
        type: 'LIST_CARE_PROVIDERS_FOR_REGISTRATION',
        payload: {
            client: 'api',
            request: {
                method: 'get',
                url: `auth/care-providers-for-registration`,
            }
        }
    }
}

export function downloadPrivacyPolicy(careProviderId) {
    return dispatch => {
        return dispatch(getPrivacyPolicy(careProviderId))
            .then(response => {
                const file = response.payload.data;
                return window.URL.createObjectURL(new Blob([file], {type: "application/pdf"}));
            });
    }
}

export function downloadDeclarationOfParticipation(careProviderId) {
    return dispatch => {
        return dispatch(getDeclarationOfParticipation(careProviderId))
            .then(response => {
                const file = response.payload.data;
                return window.URL.createObjectURL(new Blob([file], {type: "application/pdf"}));
            });
    }
}

export function getPrivacyPolicy(careProviderId = '') {
    return {
        type: 'GET_PRIVACY_POLICY',
        payload: {
            client: 'api',
            request: {
                method: 'get',
                url: `/auth/privacy-policy/${careProviderId}`,
                responseType: 'blob'
            }
        }
    }
}

export function getDeclarationOfParticipation(careProviderId = '') {
    return {
        type: 'GET_DECLARATION_OF_PARTICIPATION',
        payload: {
            client: 'api',
            request: {
                method: 'get',
                url: `/auth/declaration-of-participation/${careProviderId}`,
                responseType: 'blob'
            }
        }
    }
}

export function downloadFilledWillingnessDeclaration({careProviderId, userDetailsId}) {
    return dispatch => {
        return dispatch(getFilledWillingnessDeclaration({careProviderId, userDetailsId}))
            .then(response => {
                const file = response.payload.data;
                return window.URL.createObjectURL(new Blob([file], {type: "application/pdf"}));
            });
    }
}

function getFilledWillingnessDeclaration({careProviderId, userDetailsId}) {
    return {
        type: 'GET_FILLED_WILLINGNESS_DECLARATION',
        payload: {
            client: 'api',
            request: {
                method: 'post',
                url: `/program/declaration-of-participation`,
                data: {careProviderId, userDetailsId},
                responseType: 'blob'
            }
        }
    }
}

export function requestRegistrationTokenForEmail(email) {
    return {
        type: 'REQUEST_REGISTRATION_TOKEN_FOR_EMAIL',
        payload: {
            client: 'api',
            request: {
                method: 'post',
                url: `/auth/send-registration-token/${email}`,
            }
        }
    }
}
