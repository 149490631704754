import {fetchCase} from 'scenes/case/edit/CaseEdit.action';
import {
    deleteCaseFile,
    downloadSelectedDocumentForCase,
    downloadCaseFile,
    updateDocument,
    uploadCaseFile,
    downloadAndReturnCaseFile,
} from 'scenes/FileHandler.action';
import * as _ from "lodash";

export default {
    mapStateToProps: (state) => {
        return {
            user: state.mainReducer.user,
            assignedDocumentIds: _.get(state, 'caseEditReducer.case.secondOpinionDetails', []).flatMap(value => value.assignedDocumentIds),
            caseBusinessId: state.caseEditReducer.case.businessId,
            caseId: state.caseEditReducer.case.id,
            patientId: state.caseEditReducer.case?.patient?.id,
            documents: state.caseEditReducer.case.documents,
            documentTypes: state.caseReducer.configuration.documentTypes
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            fetchCase: caseBusinessId => dispatch(fetchCase(caseBusinessId)),
            uploadCaseFile: (file, fileName, documentType, caseBusinessId, entityId, patientId) =>
                dispatch(uploadCaseFile(file, fileName, documentType, caseBusinessId, entityId, patientId)),
            downloadCaseFile: (document) => dispatch(downloadCaseFile(document)),
            downloadAndReturnCaseFile: (document) => dispatch(downloadAndReturnCaseFile(document)),
            downloadSelectedDocument: documentIds =>
                dispatch(downloadSelectedDocumentForCase(documentIds)),
            deleteFile: document => dispatch(deleteCaseFile(document)),
            updateDocument: (document, options) => dispatch(updateDocument(document, options)),
        };
    }
};
