import {updateDate, update} from 'utils/Utils';

/**
 * hu.h4software.hmo.patient.model.UserDetailsInput
 */
export function mapPatientInputForBackend(caseFromFrontend, caseOnBackend) {
    const {patient: patientFromFrontend } = caseFromFrontend;
    const patientOnBackend = caseOnBackend?.patient;
    return {
        businessId: patientFromFrontend.businessId,
        id: patientFromFrontend.id,
        salutation: update('salutation', patientFromFrontend, patientOnBackend),
        title: update('title', patientFromFrontend, patientOnBackend),
        bioSex: update('bioSex', patientFromFrontend, patientOnBackend),
        givenName: update('givenName', patientFromFrontend, patientOnBackend),
        familyName: update('familyName', patientFromFrontend, patientOnBackend),
        phoneNumbers: update('phoneNumbers', patientFromFrontend, patientOnBackend),
        emailAddresses: update('emailAddresses', patientFromFrontend, patientOnBackend),
        street: update('street', patientFromFrontend, patientOnBackend),
        zipCode: update('zipCode', patientFromFrontend, patientOnBackend),
        city: update('city', patientFromFrontend, patientOnBackend),
        state: update('state', patientFromFrontend, patientOnBackend),
        country: update('country', patientFromFrontend, patientOnBackend),
        dateOfBirth: updateDate('dateOfBirth', patientFromFrontend, patientOnBackend),
        dateOfDeath: updateDate('dateOfDeath', patientFromFrontend, patientOnBackend),
        source: update('source', patientFromFrontend, patientOnBackend),
        sourceFreeText: update('sourceFreeText', patientFromFrontend, patientOnBackend),
    }
}

export function emptyPatient(){
    return {
        title: '',
        salutation: '',
        bioSex: '',
        givenName: '',
        familyName: '',
        phoneNumbers: [''],
        emailAddresses: [],
        street: '',
        zipCode: '',
        city: '',
        state: '',
        country: '',
        source: '',
        sourceFreeText: '',
        dateOfBirth: '',
        dateOfDeath: ''
    }
}
