import { useCallback, useMemo }  from 'react';
import { Walkthrough } from "../Walkthrough";
import { newMedicationWalkthroughSteps } from "./newMedicationWalkthroughSteps";
import { useDispatch, useSelector } from "react-redux";

import { SET_SHOW_NEW_MEDICATION_WALKTHROUGH } from '../../../scenes/Main.action.types';
import { useTranslation } from 'react-i18next';
import { updateUserWalkthroughs } from "../../user-manager/UserManager.action";

export const NewMedicationWalkthrough = () => {
    const walkthroughsCompleted = useSelector((state) => state.mainReducer.walkthroughsCompleted);
    const { t } = useTranslation();
    const dispatch = useDispatch();


    const translateStep = useCallback((stepName, translationPart) => {
        return t(`walkthrough.new-medication.${stepName}.${translationPart}`);
    }, [t]);


    const translatedSteps = useMemo(() => newMedicationWalkthroughSteps.map(step => {
        return {
            ...step,
            title: translateStep(step.title, "title"),
            content: translateStep(step.title, "content"),
        }
    }), [translateStep]);

    const newMedicationPlanWalkthroughCompleted = useSelector(state => state.mainReducer.walkthroughsCompleted.newMedicationPlanWalkthrough);
    const toggleWalkthrough = () => {
        dispatch({type: SET_SHOW_NEW_MEDICATION_WALKTHROUGH, payload: !newMedicationPlanWalkthroughCompleted });
    }

    const onCompleted = () => {
        dispatch(updateUserWalkthroughs({...walkthroughsCompleted, newMedicationPlanWalkthrough: true}));
        toggleWalkthrough();
    }
    return !newMedicationPlanWalkthroughCompleted ? <Walkthrough steps={translatedSteps} toggleWalkthrough={toggleWalkthrough} onCompleteCallback={onCompleted}/>  : null;
}
