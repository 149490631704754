import React, {PureComponent,} from 'react';
import {withStyles, IconButton, FormControlLabel, Checkbox} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {withTranslation} from "react-i18next";
import {concrete, htmlGrey, htmlGreen, pictonBlue, htmlWhite, silver} from 'components/colors/Colors';
import HmoInputWithAdornment from 'components/input/HmoInputWithAdornment';
import TagInputWithSuggestions from 'components/tags/TagInputWithSuggestions';
import {Search, ExpandMore, ExpandLess, CloseOutlined as CloseIcon} from "@material-ui/icons";
import CaseManagerFilter from "./CaseManagerFilter";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {isCaseManager} from 'domain/User.model';
import ButtonSwitch from 'components/switch/ButtonSwitch';
import HmoFilledButton from 'components/button/HmoFilledButton';
import _ from 'lodash';

export const styles = theme => ({
    topLineFilters: {
        paddingBottom: 10
    },
    inputContainerMargins: {
        flexWrap: 'wrap'
    },
    tags: {
        backgroundColor: concrete,
        borderRadius: 10,
        border: 'unset',
        marginTop: 5
    },
    showAllLabel: {
        minHeight: 36,
        marginRight: 5,
        marginLeft: 15,
        marginTop: 5,
        marginBottom: 5,
        fontSize: 13,
        paddingRight: 6,
        borderRadius: 7,
        border: `1px dashed ${pictonBlue}`,
        textTransform: 'capitalize',
        color: htmlGrey,
        display: 'inline',
        whiteSpace: 'nowrap',
        '&:hover': {
            cursor: 'pointer'
        },
    },
    tagNumberIndicator: {
        backgroundColor: pictonBlue,
        color: htmlWhite,
        borderRadius: '50%',
        padding: 3,
        width: 20,
        marginLeft: 5,
        fontSize: 12
    },
    checkBoxIcon: {
        fill: htmlGreen
    },
    switchActive: {
        color: pictonBlue,
        fontWeight: 'bold'
    }
});

export class Filters extends PureComponent {

    state = {
        showTags: false
    };

    toggleShowTags = () => {
        this.setState({
            showTags: !this.state.showTags
        });
    };

    reset = () => {
        this.props.resetSelectedTags();
        this.setState({
            showTags: false
        });
    };

    render() {
        const {
            classes, t: translate, searchText, tagSuggestions, selectedTags, handleSearchTextChange,
            tagFilterRelation, resetSearchText, toggleTagSelection, caseManagerSuggestions, selectedCaseManagerIds,
            toggleSelectedCaseManagerId, handleTagFilterRelationChange, reminderOnly, toggleReminderOnly,
            isHistoryActive, setHistory, loggedInUsers, create, user, fetchCaseManagers
        } = this.props;
        const {showTags} = this.state;
        const {toggleShowTags, reset} = this;
        return (
            <Flex container className={classes.topLineFilters} direction={'column'}>
                <Flex container item justifyContent={'space-between'} alignItems={'center'} style={{flexWrap: 'wrap'}}>
                    <Flex item container className={classes.inputContainerMargins} alignItems={'center'}>
                        <HmoInputWithAdornment
                            value={searchText}
                            placeholder={translate('global.search')}
                            onChange={handleSearchTextChange}
                            name={'searchText'}
                            rootStyle={{padding: 0, paddingLeft: 10, width: 420, minHeight: 36, marginRight: 20}}
                            startAdornment={<Search/>}
                            endAdornment={
                                !_.isEmpty(searchText) &&
                                <IconButton style={{padding: 3, backgroundColor: silver, fontSize: 13, marginRight: 3}}
                                            onClick={resetSearchText}>
                                    <CloseIcon style={{color: concrete, fontSize: 'inherit'}}/>
                                </IconButton>
                            }
                        />

                        {
                            setHistory &&
                            <ButtonSwitch {...{
                                isLeftSelected: !isHistoryActive,
                                labelRight: translate("case.history"),
                                labelLeft: translate("case.active-cases"),
                                selectLeft: () => setHistory(false),
                                selectRight: () => setHistory(true)
                            }}/>

                        }
                        {
                            toggleReminderOnly &&
                            <FormControlLabel
                                style={{marginLeft: 15}}
                                control={
                                    <Checkbox
                                        checked={reminderOnly}
                                        onChange={toggleReminderOnly}
                                        checkedIcon={<CheckBoxIcon className={classes.checkBoxIcon}/>}
                                    />
                                }
                                label={translate('case.reminder-only')}
                            />
                        }
                        <Flex container item={'0 1 auto'} className={classes.showAllLabel} alignItems={'center'}>
                            <Flex item container onClick={toggleShowTags} alignItems={'center'}>
                                {showTags ? <ExpandLess/> : <ExpandMore/>}
                                <span>{showTags ? translate('case.hideTagsLabel') : translate('case.showTagsLabel')}</span>
                            </Flex>
                            {
                                selectedTags.length > 0 &&
                                <Flex item container className={classes.tagNumberIndicator}
                                      justifyContent={'center'}>
                                    <IconButton style={{padding: 0, color: 'inherit', fontSize: 'inherit'}}
                                                onClick={reset}>
                                        <CloseIcon style={{color: 'inherit', fontSize: 'inherit'}}/>
                                    </IconButton>
                                </Flex>
                            }
                        </Flex>
                    </Flex>
                    {
                        toggleSelectedCaseManagerId &&
                        <CaseManagerFilter {...{
                            caseManagerSuggestions,
                            selectedCaseManagerIds,
                            toggleSelectedCaseManagerId,
                            loggedInUsers,
                            fetchCaseManagers
                        }}/>
                    }
                    {
                        isCaseManager(user) &&
                        <HmoFilledButton onClick={create}>+ {translate('global.add-new-patient')}</HmoFilledButton>
                    }
                </Flex>
                {
                    showTags &&
                    <TagInputWithSuggestions {...{
                        selectedTags,
                        tagSuggestions,
                        tagFilterRelation,
                        toggleTagSelection,
                        handleTagFilterRelationChange
                    }}/>
                }

            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(Filters));
