import React, {useCallback, useState} from "react";
import update from "immutability-helper";
import {IconButton} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/EditOutlined";
import {AnamnesisTable} from "./AnamnesisTable";
import moment from "moment";
import {padCharsStart} from "lodash/fp";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";

const ChipDataTable = ({form, onEditRow, disabled}) => {
    const {t, i18n} = useTranslation();
    const lang = useCallback((name) => t(`anamnesis.chip.table.${name}`), [t]);
    const [idToDelete, setIdToDelete] = useState('');
    const [geneDelete, setGeneDelete] = useState('');
    const handleDeleteRow = (rowId) => {
        const updated = update(form.values["chipMutationData"], {
            $apply: (data) => data.filter((item) => item.id !== rowId),
        });

        form.setFieldValue("chipMutationData", updated);
        setIdToDelete('');
        setGeneDelete('');
    };

    const setRowToDelete = params => {
        setIdToDelete(params.row.id);
        setGeneDelete(params.row.gene);
    }
    const columns = [
        { field: "gene", minWidth: 100, width: 120, headerName: lang("gene") },
        {
            field: "exon",
            minWidth: 70,
            width: 90,
            headerName: "Exon",
            renderCell: (x) => {
                const padValueWithZero = padCharsStart("0")(2);
                return <div>{x.row.exon ? padValueWithZero(x.row.exon) : ""}</div>;
            },
        },
        { field: "dna", minWidth: 150, width: 220, headerName: lang("dna") },
        { field: "protein", minWidth: 120, width: 220, headerName: lang("protein") },
        { field: "vaf", minWidth: 50, width: 100, headerName: lang("vaf") },
        {
            field: "source",
            minWidth: 170,
            width: 220,
            headerName: "Source",
            renderCell: (x) => {
                return <div>{x.row.source ? t(`anamnesis.chip.source.${x.row.source}`) : ""}</div>;
            },
        },
        {
            field: "date",
            headerName: lang("date"),
            renderCell: (x) => {
                return <div>{x.row.date ? moment(x.row.date).locale(i18n.language).format("MMM YYYY") : ""}</div>;
            },
        },
        {
            field: "actions",
            headerName: "",
            width: 116,
            renderCell: (params) => (
                <>
                    {!disabled && (
                        <>
                            <IconButton
                                onClick={() => {
                                    onEditRow(params.row.id);
                                }}
                                style={{ padding: 8 }}
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton onClick={() => setRowToDelete(params)} style={{ padding: 8 }}>
                                <DeleteIcon />
                            </IconButton>
                        </>
                    )}
                </>
            ),
        },
    ];

    return (
            <>
                <AnamnesisTable
                        style={{marginTop: "20px"}}
                        columns={columns}
                        rows={form?.values["chipMutationData"] || []}
                />
                <ConfirmationDialog {...{
                    dialogOpen: idToDelete,
                    onDialogClose: () => setIdToDelete(''),
                    onConfirm: () => handleDeleteRow(idToDelete),
                    confirmationTextKey: "global.delete-warning",
                    confirmationTextValues: {name: geneDelete}
                }}/>
            </>
    );
};

export default ChipDataTable;
