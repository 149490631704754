import { useReducer, useCallback, useEffect } from 'react';

const actions = {
  SET_ALREADY_SEEN_STEPS: 'SET_ALREADY_SEEN_STEPS',
  SET_COLLAPSED_STEPS: 'SET_COLLAPSED_STEPS',
};

function reducer(state, action) {
  switch (action.type) {
    case actions.SET_ALREADY_SEEN_STEPS:
      return { ...state, alreadySeenSteps: action.payload };
    case actions.SET_COLLAPSED_STEPS:
      return { ...state, collapsedSteps: action.payload };
    default:
        return state;
  }
}

// This hook is used to track which steps have been seen and which steps have been collapsed
// reducer makes it a bit bloated maybe in this case useState would be better
export const useStepTracker = (originalProgram, isProgramsSuccess, isProgramError) => {
  const [state, dispatch] = useReducer(reducer, {
    alreadySeenSteps: originalProgram?.steps?.map((step) => step.id) || [],
    collapsedSteps: originalProgram?.steps?.filter((step) => step.isCompleted).map((step) => step.id) || [],
  });

  const setAlreadySeenSteps = useCallback((steps) => {
    dispatch({ type: actions.SET_ALREADY_SEEN_STEPS, payload: steps });
  }, []);

  const setCollapsedSteps = useCallback((steps) => {
    dispatch({ type: actions.SET_COLLAPSED_STEPS, payload: steps });
  }, []);

  useEffect(() => {
    setAlreadySeenSteps(originalProgram?.steps?.map((x) => x.id) || []);
    setCollapsedSteps(originalProgram?.steps?.filter((step) => step.isCompleted).map((step) => step.id) || []);
  }, [isProgramsSuccess, isProgramError]);

  return { ...state, setAlreadySeenSteps, setCollapsedSteps };
}
