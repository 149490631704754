export const SPECIAL_STEP_TYPE = Object.freeze({
    START: "start",
    END: "end",
    LATER: "later",
    CONFIRMATION: "confirmation"
});

export const medicationListWalkthroughSteps = [
    {
        title: "medication-list",
        selector: "[walkthrough-element=\"remove-medication-btn\"]",
        positionX: "bottom",
        positionY: "left",
        offsetX: -80,
        offsetY: 20,
        type: SPECIAL_STEP_TYPE.CONFIRMATION
    }
];
