import {
    Box,
    Switch,
    FormControlLabel,
    Checkbox,
    TextField,
    MenuItem, RadioGroup, Radio, FormControl,
} from "@material-ui/core";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import EmailIcon from "@material-ui/icons/Email";
import {useTranslation} from "react-i18next";
import {useTheme} from "@material-ui/core/styles";
import {toArray} from "utils/Utils";
import {EmailTemplateManagerWithButton} from "scenes/care-provider/EmailTemplateManager";
import React from "react";
import {useGenerateEmailPreviewQuery} from "scenes/EmailTemplateApi";
import Flex from "components/grid/Flex";

const notificationOptions = ["EMAIL", "POST", "PHONE", "PERSONAL_VISIT"];
const PossibleTimePeriods = Object.freeze({
    Days: "DAYS",
    Weeks: "WEEKS",
    // Months: "MONTHS",
});

const reminderOptions = Object.freeze([
    {
        type: "EMAIL",
        disabled: false,
        fields: {
            timeSpan: "emailReminderDateTimeSpan",
            value: "emailReminderDateTimeSpanValue",
        }
    },
    {
        type: "SMS",
        disabled: true,
        fields: {
            timeSpan: "smsReminderDateTimeSpan",
            value: "smsReminderDateTimeSpanValue",
        }
    },
]);

const StepEmailTemplateManager = (props) => {
    const {translate, step, emailTemplateEntityType} = props;
    const emailTemplateType = 'Special_Request_For_Program_Step';
    const {data} = useGenerateEmailPreviewQuery({
        emailTemplateEntityType,
        entityId: step.id,
        language: 'de',
        emailTemplateType
    });
    const emailTemplate = data?.emailTemplate;
    return <EmailTemplateManagerWithButton {...{
        buttonStyle: {marginLeft: 10},
        emailTemplateEntityType,
        entityId: step.id,
        emailTypeFilter: item => item === emailTemplateType,
        warning: !emailTemplate ? translate('program.template-missing') : ''
    }}/>;
};

const NotificationControl = ({label, name, onChange, checked, disabled, icon: Icon}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    return (
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <span
                    style={{
                        marginRight: theme.spacing(2),
                        color: theme.palette.grey[900],
                        fontWeight: theme.typography.fontWeightBold,
                    }}
            >
                {label}
            </span>
                {Icon && (
                        <Icon
                                style={{
                                    color: checked ? theme.palette.warning.main : theme.palette.grey[500],
                                    marginRight: theme.spacing(1),
                                }}
                        />
                )}
                <div style={{marginLeft: 'auto', display: 'flex', alignItems: 'center'}}>
                    <span style={{
                        fontWeight: checked ? "" : "bold",
                        color: disabled ? "gray" : ''
                    }}>{t("global.no")}</span>
                    <Switch name={name} disabled={disabled} checked={checked} onChange={onChange} color="primary"/>
                    <span style={{
                        fontWeight: checked ? "bold" : "",
                        color: disabled ? "gray" : ''
                    }}>{t("global.yes")}</span>
                </div>
            </Box>
    );
};

export const NotificationsTaskSettingsPanel = ({step, setStep, emailTemplateEntityType, isTemplate}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const availableNotificationTypes = toArray(step.availableNotificationTypes, ",");
    const handleStepChange = (event) => setStep({...step, [event.target.name]: event.target.value});
    const setSpecialRequestType = type => () => {
        setStep({
            ...step,
            specialRequestType: type,
        });
    };
    return (
        <>
            <Box display={"flex"} flexDirection={"column"} maxWidth={"700px"}>
                <NotificationControl
                    label={t("global.remind-patient")}
                    name="reminderIsOn"
                    checked={step.reminderIsOn}
                    icon={NotificationsNoneIcon}
                    onChange={(event, value) => setStep({ ...step, reminderIsOn: value })}
                />
                {step.reminderIsOn && (
                    <Box marginLeft={3} mb={1} display={"flex"} flexDirection={"column"}>
                        {/* this should be a separate component */}
                        {reminderOptions.map((reminderOption, index) => (
                            <Box display={"flex"} alignItems={"center"} key={`reminder-option-${index}`}>
                                <FormControlLabel
                                    style={{ minWidth: "120px" }}
                                    control={
                                        <Checkbox
                                            disabled={true}
                                            checked={reminderOption.type === "EMAIL"}
                                            color="primary"
                                            size="small"
                                        />
                                    }
                                    label={t("program.step-reminders.types." + reminderOption.type)}
                                />
                                <TextField
                                    value={step[reminderOption.fields.value]}
                                    type="number"
                                    size="small"
                                    name={reminderOption.fields.value}
                                    disabled={reminderOption.disabled}
                                    inputProps={{ style: { paddingTop: 2, paddingBottom: 2 } }}
                                    style={{ width: "38px", marginRight: "10px" }}
                                    onChange={handleStepChange}
                                />

                                <TextField
                                    value={step[reminderOption.fields.timeSpan]}
                                    disabled={reminderOption.disabled}
                                    name={reminderOption.fields.timeSpan}
                                    size="small"
                                    select
                                    inputProps={{ style: { paddingTop: 2, paddingBottom: 2 } }}
                                    style={{ padding: 0 }}
                                    onChange={handleStepChange}
                                >
                                    {Object.entries(PossibleTimePeriods).map(([label, value]) => (
                                        <MenuItem key={value} value={value}>
                                            {t(`timeSpans.${label.toLowerCase()}`)}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <span style={{ marginLeft: "8px" }}>
                                    {t("program.step-reminders.before-scheduled-date")}
                                </span>
                            </Box>
                        ))}
                    </Box>
                )}
                <NotificationControl
                    label={t("program.step-personal-email.title")}
                    icon={EmailIcon}
                    name="specialRequestIsOn"
                    checked={step.specialRequestIsOn}
                    onChange={() => {
                        setStep({
                            ...step,
                            specialRequestIsOn: !Boolean(step.specialRequestIsOn),
                        });
                    }}
                />
                {step.specialRequestIsOn && (
                    <Flex item container>
                        <Flex item grow={0} container alignItems={"flex-start"} style={{ marginRight: 20 }}>
                            <StepEmailTemplateManager
                                translate={t}
                                step={step}
                                emailTemplateEntityType={emailTemplateEntityType}
                            />
                        </Flex>

                        <Flex item container column>
                            <Flex item container>
                                <Radio
                                    color="primary"
                                    onChange={setSpecialRequestType("SCHEDULE")}
                                    checked={step.specialRequestType === "SCHEDULE"}
                                />
                                <Box display={"flex"} alignItems={"center"} style={{marginLeft:6}}>
                                    <TextField
                                        value={step.specialRequestDateTimeSpanValue}
                                        type="number"
                                        size="small"
                                        name={"specialRequestDateTimeSpanValue"}
                                        inputProps={{ style: { paddingTop: 2, paddingBottom: 2 } }}
                                        style={{ width: "38px", marginRight: "10px" }}
                                        onChange={handleStepChange}
                                    />

                                    <TextField
                                        value={step.specialRequestDateTimeSpan}
                                        name={"specialRequestDateTimeSpan"}
                                        size="small"
                                        select
                                        inputProps={{ style: { paddingTop: 2, paddingBottom: 2 } }}
                                        style={{ padding: 0 }}
                                        onChange={handleStepChange}
                                    >
                                        {Object.entries(PossibleTimePeriods).map(([label, value]) => (
                                            <MenuItem key={value} value={value}>
                                                {t(`timeSpans.${label.toLowerCase()}`)}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <span style={{ marginLeft: "8px" }}>
                                        {t("program.step-reminders.before-scheduled-date")}
                                    </span>
                                </Box>
                            </Flex>
                            <Flex item container alignItems={'center'}>
                                <Radio
                                    color="primary"
                                    disabled={!isTemplate}
                                    onChange={setSpecialRequestType("IMMEDIATE")}
                                    checked={step.specialRequestType === "IMMEDIATE"}
                                />
                                <span style={{ marginLeft: "8px" }}>
                                    {t("program.step-reminders.IMMEDIATE")}
                                </span>
                            </Flex>
                            <Flex item container alignItems={'center'}>
                                <Radio
                                    color="primary"
                                    onChange={setSpecialRequestType("WHEN_DATE_ASSIGNED")}
                                    checked={step.specialRequestType === "WHEN_DATE_ASSIGNED"}
                                />
                                <span style={{ marginLeft: "8px" }}>
                                    {t("program.step-reminders.WHEN_DATE_ASSIGNED")}
                                </span>
                            </Flex>
                        </Flex>
                    </Flex>
                )}
                <NotificationControl
                    label={t("global.ask-patient-about-method-of-informing-examination-result")}
                    name="informResultPreference"
                    checked={step.informResultPreference}
                    onChange={() => {
                        setStep({
                            ...step,
                            informResultPreference: !Boolean(step.informResultPreference),
                        });
                    }}
                />
                {step.informResultPreference && (
                    <Box marginLeft={1} marginTop={"2px"} display="flex" flexDirection={"column"}>
                        <span style={{ color: theme.palette.grey[700] }}>
                            {t("program.step-notifications.which-options-should-be-offered")}
                        </span>
                        <Box marginLeft={2} display="flex" flexDirection={"column"}>
                            {notificationOptions.map((notificationOption, index) => (
                                <FormControlLabel
                                    key={`notification-option-${index}`}
                                    control={
                                        <Checkbox
                                            disabled={false}
                                            checked={availableNotificationTypes.includes(notificationOption)}
                                            color="primary"
                                            size="small"
                                        />
                                    }
                                    onChange={() => {
                                        setStep({
                                            ...step,
                                            availableNotificationTypes: availableNotificationTypes.includes(
                                                notificationOption,
                                            )
                                                ? availableNotificationTypes
                                                      .filter(
                                                          (alreadySelectedOption) =>
                                                              alreadySelectedOption !== notificationOption,
                                                      )
                                                      .join(",")
                                                : [...availableNotificationTypes, notificationOption].join(","),
                                        });
                                    }}
                                    label={t("program.step-notifications.types." + notificationOption)}
                                />
                            ))}
                        </Box>
                    </Box>
                )}
            </Box>
        </>
    );
};
