import React, {Component} from 'react';
import Flex from 'components/grid/Flex';
import Tag from 'components/tags/Tag';
import update from 'immutability-helper';
import HmoInputLabel from 'components/input-label/HmoInputLabel';

export class CostCoverage extends Component {

    handleCostCoverageChange = type => {
        const haveHerbst2022 = this.props.selectedCostCoverageTypes.find(typeInArray => typeInArray === 'HERBST_2022');
        if (type === 'HERBST_2022') {
            if (haveHerbst2022) {
                this.props.updateCase({
                    costCoverageTypes: []
                });
            } else {
                const herbstCCValue = this.props.costCoverageAmounts.find(amountInArray => amountInArray.amount === 150);
                this.props.updateCase({
                    costCoverageTypes: ['HERBST_2022'],
                    costCoverageValue: herbstCCValue.value
                });
            }
        } else if (haveHerbst2022) {
            this.props.updateCase({
                costCoverageTypes: [type]
            });
        } else {
            const index = this.props.selectedCostCoverageTypes.findIndex(typeInArray => typeInArray === type);
            if (index > -1) {
                this.props.updateCase({
                    costCoverageTypes: update(this.props.selectedCostCoverageTypes, {
                        $splice: [[index, 1]]
                    })
                })
            } else {
                this.props.updateCase({
                    costCoverageTypes: update(this.props.selectedCostCoverageTypes, {
                        $push: [type]
                    })
                })
            }
        }
    };

    render() {
        const {translate, selectedCostCoverageTypes, costCoverageTypes, isEditDisabled} = this.props;

        return (
            <Flex container column>
                <HmoInputLabel>{translate("case.cost-coverage")}</HmoInputLabel>
                <Flex item container style={{flexWrap: 'wrap'}}>
                    {
                        costCoverageTypes.map((type, index) => (
                            <Tag {...{
                                key: index,
                                isEditDisabled,
                                selected: selectedCostCoverageTypes.includes(type),
                                tag: type,
                                toggleSelection: this.handleCostCoverageChange
                            }}/>
                        ))
                    }
                </Flex>
            </Flex>
        );
    }
}

export default CostCoverage;
