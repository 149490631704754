import mainReducer from 'scenes/Main.reducer';
import caseEditReducer from 'scenes/case/edit/CaseEdit.reducer';
import caseReducer from 'scenes/case/Case.reducer';
import clinicsReducer from 'scenes/clinics/Clinics.reducer';
import socketReducer from 'socket/Socket.reducer';
import managedCareReducer from 'scenes/managed-care/ManagedCare.reducer';
import careProviderReducer from 'scenes/care-provider/CareProvider.reducer';
import HMOApi from "store/api/HMOApi";
import DropZoneReducer from "scenes/patient/patient-sidebar/UploadFiles/DropZone.reducer";

export default {
    mainReducer,
    caseEditReducer,
    caseReducer,
    socketReducer,
    clinicsReducer,
    managedCareReducer,
    careProviderReducer,
    DropZoneReducer,
    [HMOApi.reducerPath]: HMOApi.reducer
};
