export const FETCH_CURRENT_USER = '@user/FETCH_CURRENT_USER';
export const FETCH_CURRENT_USER_SUCCESS = '@user/FETCH_CURRENT_USER_SUCCESS';
export const FETCH_CURRENT_USER_FAIL = '@user/FETCH_CURRENT_USER_FAIL';

export const FETCH_CURRENT_USER_DETAILS = '@user/FETCH_CURRENT_USER_DETAILS';
export const FETCH_CURRENT_USER_DETAILS_SUCCESS = '@user/FETCH_CURRENT_USER_DETAILS_SUCCESS';
export const FETCH_CURRENT_USER_DETAILS_FAIL = '@user/FETCH_CURRENT_USER_DETAILS_FAIL';

export const GET_USER = '@user/GET_USER';
export const GET_USER_SUCCESS = '@user/GET_USER_SUCCESS';
export const GET_USER_FAIL = '@user/GET_USER_FAIL';

export const UPDATE_USER = '@user/UPDATE_USER';
export const UPDATE_USER_SUCCESS = '@user/UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = '@user/UPDATE_USER_FAIL';

export const GET_EXISTING_USER_DATA = '@user/GET_EXISTING_USER_DATA';
export const GET_EXISTING_USER_DATA_SUCCESS = '@user/GET_EXISTING_USER_DATA_SUCCESS';
export const GET_EXISTING_USER_DATA_FAIL = '@user/GET_EXISTING_USER_DATA_FAIL';

export const GET_EXISTING_ENTITIES_BY_EMAIL = '@user/GET_EXISTING_ENTITIES_BY_EMAIL';
export const GET_EXISTING_ENTITIES_BY_EMAIL_SUCCESS = '@user/GET_EXISTING_ENTITIES_BY_EMAIL_SUCCESS';
export const GET_EXISTING_ENTITIES_BY_EMAIL_FAIL = '@user/GET_EXISTING_ENTITIES_BY_EMAIL_FAIL';

export const GRANT_ACCESS_TO_USER = '@user/GRANT_ACCESS_TO_USER';
export const GRANT_ACCESS_TO_USER_SUCCESS = '@user/GRANT_ACCESS_TO_USER_SUCCESS';
export const GRANT_ACCESS_TO_USER_FAIL = '@user/GRANT_ACCESS_TO_USER_FAIL';

export const GRANT_ACCESS_TO_CARE_PROVIDER = '@user/GRANT_ACCESS_TO_CARE_PROVIDER';
export const GRANT_ACCESS_TO_CARE_PROVIDER_SUCCESS = '@user/GRANT_ACCESS_TO_CARE_PROVIDER_SUCCESS';
export const GRANT_ACCESS_TO_CARE_PROVIDER_FAIL = '@user/GRANT_ACCESS_TO_CARE_PROVIDER_FAIL';

export const INVITE = '@user/INVITE';
export const INVITE_SUCCESS = '@user/INVITE_SUCCESS';
export const INVITE_FAIL = '@user/INVITE_FAIL';

export const CONFIRM_ACCESS = '@user/CONFIRM_ACCESS';
export const CONFIRM_ACCESS_SUCCESS = '@user/CONFIRM_ACCESS_SUCCESS';
export const CONFIRM_ACCESS_FAIL = '@user/CONFIRM_ACCESS_FAIL';

export const REJECT_ACCESS = '@user/REJECT_ACCESS';
export const REJECT_ACCESS_SUCCESS = '@user/REJECT_ACCESS_SUCCESS';
export const REJECT_ACCESS_FAIL = '@user/REJECT_ACCESS_FAIL';

export const GET_OUTSTANDING_INVITES = '@user/GET_OUTSTANDING_INVITES';
export const GET_OUTSTANDING_INVITES_SUCCESS = '@user/GET_OUTSTANDING_INVITES_SUCCESS';
export const GET_OUTSTANDING_INVITES_FAIL = '@user/GET_OUTSTANDING_INVITES_FAIL';

export const GET_USER_DETAILS = '@user/GET_USER_DETAILS';
export const GET_USER_DETAILS_SUCCESS = '@user/GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAIL = '@user/GET_USER_DETAILS_FAIL';

export const CREATE_USER_DETAILS = '@user/CREATE_USER_DETAILS';
export const CREATE_USER_DETAILS_SUCCESS = '@user/CREATE_USER_DETAILS_SUCCESS';
export const CREATE_USER_DETAILS_FAIL = '@user/CREATE_USER_DETAILS_FAIL';

export const UPDATE_USER_DETAILS = '@user/UPDATE_USER_DETAILS';
export const UPDATE_USER_DETAILS_SUCCESS = '@user/UPDATE_USER_DETAILS_SUCCESS';
export const UPDATE_USER_DETAILS_FAIL = '@user/UPDATE_USER_DETAILS_FAIL';

export const DELETE_USER_DETAILS = '@user/DELETE_USER_DETAILS';
export const DELETE_USER_DETAILS_SUCCESS = '@user/DELETE_USER_DETAILS_SUCCESS';
export const DELETE_USER_DETAILS_FAIL = '@user/DELETE_USER_DETAILS_FAIL';

export const DELETE_PATIENT = '@user/DELETE_PATIENT';
export const DELETE_PATIENT_SUCCESS = '@user/DELETE_PATIENT_SUCCESS';
export const DELETE_PATIENT_FAIL = '@user/DELETE_PATIENT_FAIL';

export const GET_ALREADY_INVITED_EMAILS = '@user/GET_ALREADY_INVITED_EMAILS';
export const GET_ALREADY_INVITED_EMAILS_SUCCESS = '@user/GET_ALREADY_INVITED_EMAILS_SUCCESS';
export const GET_ALREADY_INVITED_EMAILS_FAIL = '@user/GET_ALREADY_INVITED_EMAILS_FAIL';

export const GET_CONTACTS_FOR = '@user/GET_CONTACTS_FOR';
export const GET_CONTACTS_FOR_SUCCESS = '@user/GET_CONTACTS_FOR_SUCCESS';
export const GET_CONTACTS_FOR_FAIL = '@user/GET_CONTACTS_FOR_FAIL';

export const UPLOAD_PROFILE_IMAGE = '@user/UPLOAD_PROFILE_IMAGE';
export const UPLOAD_PROFILE_IMAGE_SUCCESS = '@user/UPLOAD_PROFILE_IMAGE_SUCCESS';
export const UPLOAD_PROFILE_IMAGE_FAIL = '@user/UPLOAD_PROFILE_IMAGE_FAIL';

export const FETCH_CARE_PROVIDER_CONTACTS = '@user/FETCH_CARE_PROVIDER_CONTACTS';
export const FETCH_CARE_PROVIDER_CONTACTS_SUCCESS = '@user/FETCH_CARE_PROVIDER_CONTACTS_SUCCESS';
export const FETCH_CARE_PROVIDER_CONTACTS_FAIL = '@user/FETCH_CARE_PROVIDER_CONTACTS_FAIL';

export const DELETE_ACCESS_ASSIGNMENTS_BY_IDS = '@user/DELETE_ACCESS_ASSIGNMENTS_BY_IDS';
export const DELETE_ACCESS_ASSIGNMENTS_BY_IDS_SUCCESS = '@user/DELETE_ACCESS_ASSIGNMENTS_BY_IDS_SUCCESS';
export const DELETE_ACCESS_ASSIGNMENTS_BY_IDS_FAIL = '@user/DELETE_ACCESS_ASSIGNMENTS_BY_IDS_FAIL';

export const GET_CONTACTS_GROUPED_BY_ACCESS_LEVEL = '@user/GET_CONTACTS_GROUPED_BY_ACCESS_LEVEL';
export const GET_CONTACTS_GROUPED_BY_ACCESS_LEVEL_SUCCESS = '@user/GET_CONTACTS_GROUPED_BY_ACCESS_LEVEL_SUCCESS';
export const GET_CONTACTS_GROUPED_BY_ACCESS_LEVEL_FAIL = '@user/GET_CONTACTS_GROUPED_BY_ACCESS_LEVEL_FAIL';

export const FETCH_WALKTHROUGH_STATUSES = '@user/FETCH_WALKTHROUGH_STATUSES';
export const FETCH_WALKTHROUGH_SUCCESS = '@user/FETCH_WALKTHROUGH_STATUSES_SUCCESS';
export const FETCH_WALKTHROUGH_FAIL = '@user/FETCH_WALKTHROUGH_STATUSES_FAIL';


export const UPDATE_WALKTHROUGH_STATUSES = '@user/UPDATE_WALKTHROUGH_STATUSES';
export const UPDATE_WALKTHROUGH_SUCCESS = '@user/UPDATE_WALKTHROUGH_STATUSES_SUCCESS';
export const UPDATE_WALKTHROUGH_FAIL = '@user/UPDATE_WALKTHROUGH_STATUSES_FAIL';
