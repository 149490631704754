import { getNextStep } from '../getNextStep';
/**
 * Comparator function that can be used to sort programs by next upcoming step date
 */
export const compareByNextStepLowestDate = (programA, programB) => {
    const nextStepA = getNextStep(programA.careProgramData.steps, programA.careProgramData.stepsOrder);
    const nextStepB = getNextStep(programB.careProgramData.steps, programB.careProgramData.stepsOrder);
    if(!nextStepA?.date) {
        return 1; // if there is not date provided item should be at the end of the list
    }
    if(!nextStepB?.date) {
        return -1;
    }
    if(new Date(nextStepA.date) < new Date(nextStepB.date)) {
        return -1
    } else if(new Date(nextStepA.date) > new Date(nextStepB.date)) {
        return 1
    }
    return 0;
}
