import update from "immutability-helper";
import { createContext } from 'react';

export const uploadFilesInitialState = {
    locallyReadDocumentIds: [],
    isDocListExpanded: false,
    fileUploadDialog: false,
    filesToUpload: [],
    isFileUploadTypeDialogOpen: false,
    deleteConfirmationDialog: {
        isOpen: false,
        documentType: null,
        documentId: null,
        documentName: "",
    },
    documentLinkEditDialog: {
        isOpen: false,
        link: null,
    },
    documentEditDialog: {
        isOpen: false,
        document: null,
    },
};

export const UploadFilesAction = Object.freeze({
    CloseFileUploadDialog: "CLOSE_FILE_UPLOAD_DIALOG",
    ToggleDocumentListExpansion: "TOGGLE_DOCUMENT_LIST_EXPANSION",
    SetFilesToUpload: "SET_FILES_TO_UPLOAD",
    OpenDocumentEditDialog: "CLOSE_DOCUMENT_EDIT_DIALOG",
    CloseDocumentEditDialog: "CLOSE_DOCUMENT_EDIT_DIALOG",
    OpenDocumentLinkEditDialog: "OPEN_DOCUMENT_LINK_EDIT_DIALOG",
    CloseDocumentLinkEditDialog: "CLOSE_DOCUMENT_LINK_EDIT_DIALOG",
    ToggleFileUploadTypeDialog: "TOGGLE_FILE_UPLOAD_TYPE_DIALOG",
    SetByIndexFileToUploadAsUploaded: "SET_BY_INDEX_FILE_TO_UPLOAD_AS_UPLOADED",
    SetByIndexFileToUploadAsError: "SET_BY_INDEX_FILE_TO_UPLOAD_AS_ERROR",
    OpenDeleteConfirmationDialog: "OPEN_DELETE_CONFIRMATION_DIALOG",
    CloseDeleteConfirmationDialog: "CLOSE_DELETE_CONFIRMATION_DIALOG",
    AddIdToLocallyReadDocumentIds: "ADD_ID_TO_LOCALLY_READ_DOCUMENT_IDS",
});

export const uploadFilesReducer = (state, action) => {
    switch (action.type) {
        case UploadFilesAction.CloseFileUploadDialog:
            return {
                ...state,
                isFileUploadDialogOpen: false,
                filesToUpload: [],
            };
        case UploadFilesAction.ToggleDocumentListExpansion:
            return {
                ...state,
                isDocListExpanded: !state.isDocListExpanded,
            };
        case UploadFilesAction.OpenDocumentEditDialog:
            return {
                ...state,
                documentEditDialog: {
                    isOpen: true,
                    document: action.payload,
                },
            };
        case UploadFilesAction.CloseDocumentEditDialog:
            return {
                ...state,
                documentEditDialog: uploadFilesInitialState.documentEditDialog,
            };
        case UploadFilesAction.OpenDocumentLinkEditDialog:
            return {
                ...state,
                documentLinkEditDialog: {
                    isOpen: true,
                    link: action.payload,
                },
            };
        case UploadFilesAction.CloseDocumentLinkEditDialog:
            return {
                ...state,
                documentLinkEditDialog: uploadFilesInitialState.documentLinkEditDialog,
            };
        case UploadFilesAction.SetFilesToUpload:
            return {
                ...state,
                filesToUpload: action.payload,
                isFileUploadDialogOpen: true,
            };
        case UploadFilesAction.ToggleFileUploadTypeDialog:
            return {
                ...state,
                isFileUploadTypeDialogOpen: !state.isFileUploadTypeDialogOpen,
            };
        case UploadFilesAction.SetByIndexFileToUploadAsUploaded:
            return {
                ...state,
                filesToUpload: update(state.filesToUpload, {
                    [action.payload]: { isUploadInProgress: { $set: false }, isUploadDone: { $set: true } },
                }),
            };
        case UploadFilesAction.SetByIndexFileToUploadAsError:
            return {
                ...state,
                filesToUpload: update(state.filesToUpload, {
                    [action.payload.index]: {
                        isUploadInProgress: { $set: false },
                        isUploadDone: { $set: true },
                        isVirusFound: {$set: action.payload.status === 422},
                        isError: {$set: action.payload.status !== 422},
                    },
                }),
            };
        case UploadFilesAction.OpenDeleteConfirmationDialog:
            return {
                ...state,
                deleteConfirmationDialog: {
                    isOpen: true,
                    documentType: action.payload.documentType,
                    documentId: action.payload.id,
                    documentName: action.payload.documentName,
                }
            };
        case UploadFilesAction.CloseDeleteConfirmationDialog: {
            return {
                ...state,
                deleteConfirmationDialog: uploadFilesInitialState.deleteConfirmationDialog
            };
        }
        case UploadFilesAction.AddIdToLocallyReadDocumentIds: {
            return {
                ...state,
                locallyReadDocumentIds: [...state.locallyReadDocumentIds, action.payload]
            };
        }
        default:
            return state;
    }
};

export const UploadFilesDispatchContext = createContext(null);
