/**
 * Creates a conversion function based on provided conversion factors.
 * @param {Object.<string, number>} factors - The conversion factors.
 * @returns {(
 * value: number,
 * inputUnit: string,
 * outputUnit: string
 * ) => number} The conversion function.
 **/
export const convertByFactors = (factors) => (value, inputUnit, outputUnit) => {
    // just to avoid floating point arithmetic errors so we don't get values like 0.9999999999999999
    if(inputUnit === outputUnit) {
        return value;
    }
    return (value / factors[inputUnit]) * factors[outputUnit];
};
