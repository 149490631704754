import { Walkthrough } from "../Walkthrough"
import { medicationWalkthroughSteps } from "./medicationWalkthroughStepsConfig"
import { useDispatch, useSelector } from "react-redux";

import { SET_SHOW_MEDICATION_PLAN_WALKTHROUGH, SET_NEW_MEDICATION_BTN_HIGHLIGHT } from '../../../scenes/Main.action.types';
import { useTranslation } from "react-i18next";
import { updateUserWalkthroughs } from "../../user-manager/UserManager.action";

export const MedicationWalkthrough = () => {
    const { t } = useTranslation();
    const walkthroughsCompleted = useSelector((state) => state.mainReducer.walkthroughsCompleted);
    const translateStep = (stepName, translationPart) => {
        return t(`walkthrough.medication-plan-steps-content.${stepName}.${translationPart}`);
    }
    const dispatch = useDispatch();
    const introMedicationPlanWalkthrough = useSelector(state => state.mainReducer.walkthroughsCompleted.introMedicationPlanWalkthrough);
    const appIntroWalkthrough = useSelector(state => state.mainReducer.walkthroughsCompleted.appIntroWalkthrough);
    const toggleWalkthrough = () => {
        dispatch({type: SET_SHOW_MEDICATION_PLAN_WALKTHROUGH, payload: !introMedicationPlanWalkthrough});
    }

    const onCompleted = () => {
        dispatch({type: SET_NEW_MEDICATION_BTN_HIGHLIGHT, payload: true})
        dispatch(updateUserWalkthroughs({...walkthroughsCompleted, introMedicationPlanWalkthrough: true}));
    }

    const translatedSteps = medicationWalkthroughSteps.map(step => {

    return {
        ...step,
        title: translateStep(step.title, "title"),
        content: translateStep(step.title, "content"),
        questionBeforeStart: step.type === 'start' ? translateStep(step.title, "question-before-start") : undefined,
    }
});

    return (!introMedicationPlanWalkthrough && appIntroWalkthrough) ? <Walkthrough steps={translatedSteps} toggleWalkthrough={toggleWalkthrough} onCompleteCallback={onCompleted} /> : null;
}
