import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { useTranslation } from 'react-i18next';

const LaboratoryContextMenu = ({anchorEl, onClose, onDelete, onEdit}) => {
    const { t } = useTranslation();
    return (
        <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClose} anchorOrigin={{vertical: 'top', horizontal: 'center' }} >
            <MenuItem onClick={onEdit}>{t('laboratory.timeline.editOption')}</MenuItem>
            <MenuItem onClick={onDelete}>{t('laboratory.timeline.deleteOption')}</MenuItem>
        </Menu>
    )
}

export default LaboratoryContextMenu;
