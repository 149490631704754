import { FULL_PATIENT } from '../../domain/EntityAuthorizationType.model';
import * as actionTypes from './UserManager.action.types';

export function fetchCurrentUser() {
    return {
        type: actionTypes.FETCH_CURRENT_USER,
        payload: {
            client: 'api',
            request: {
                method: 'get',
                url: 'current-user',
            }
        }
    }
}

export function fetchCurrentUserDetails(userDetailsId) {
    return {
        type: actionTypes.FETCH_CURRENT_USER_DETAILS,
        payload: {
            client: 'api',
            request: {
                method: 'get',
                url: `user-details/${userDetailsId}`,
            }
        }
    }
}

export function getUser(userId) {
    return {
        type: actionTypes.GET_USER,
        payload: {
            client: 'api',
            request: {
                method: 'get',
                url: `user/${userId}`,
            }
        }
    }
}

export function updateUser(props) {
    return {
        type: actionTypes.UPDATE_USER,
        payload: {
            client: 'api',
            request: {
                method: 'post',
                url: `user`,
                data: props
            }
        }
    }
}

// update Walkthroughs
export function updateUserWalkthroughs(walkthroughs) {
    return {
        type: actionTypes.UPDATE_WALKTHROUGH_STATUSES,
        payload: {
            client: 'api',
            request: {
                method: 'post',
                url: `me/walkthroughs`,
                data: walkthroughs
            }
        }
    }
}
export function grantAccessToCareProvider(props) {
    return {
        type: actionTypes.GRANT_ACCESS_TO_CARE_PROVIDER,
        payload: {
            client: 'api',
            request: {
                method: 'post',
                url: `grant-access-to-care-provider`,
                data: props
            }
        }
    }
}

export function confirmAccess(invitedEmail) {
    return {
        type: actionTypes.CONFIRM_ACCESS,
        payload: {
            client: 'api',
            request: {
                method: 'post',
                url: `confirm-access/${invitedEmail}`,
            }
        }
    }
}

export function rejectAccess(invitedEmail) {
    return {
        type: actionTypes.REJECT_ACCESS,
        payload: {
            client: 'api',
            request: {
                method: 'delete',
                url: `reject-access/${invitedEmail}`,
            }
        },
        data: {invitedEmail}
    }
}

export function getOutstandingRegisteredInvites() {
    return {
        type: actionTypes.GET_OUTSTANDING_INVITES,
        payload: {
            client: 'api',
            request: {
                method: 'get',
                url: `outstanding-registered-invites`,
            }
        }
    }
}

export function getExistingUser(emailAddress) {
    return {
        type: actionTypes.GET_EXISTING_USER_DATA,
        payload: {
            client: 'api',
            request: {
                method: 'get',
                url: `existing-user/${emailAddress}`,
            }
        }
    }
}

export function getUserDetails(userDetailId) {
    return {
        type: actionTypes.GET_USER_DETAILS,
        payload: {
            client: 'api',
            request: {
                url: `user-details/${userDetailId}`,
                method: 'get',
            }
        }
    }
}

export function createUserDetails({userDetailsData, invitedRoleType, entityType, entityId}) {
    return {
        type: actionTypes.CREATE_USER_DETAILS,
        payload: {
            client: 'api',
            request: {
                url: `user-details`,
                method: 'put',
                data: {
                    userDetailsData,
                    invitedRoleType,
                    entityType,
                    entityId
                }
            }
        }
    }
}

export function updateUserDetails(userDetailsData) {
    return {
        type: actionTypes.UPDATE_USER_DETAILS,
        payload: {
            client: 'api',
            request: {
                url: `user-details`,
                method: 'post',
                data: userDetailsData
            }
        }
    }
}

export function deleteUserDetails(userDetailsId) {
    return {
        type: actionTypes.DELETE_USER_DETAILS,
        payload: {
            client: 'api',
            request: {
                url: `user-details/${userDetailsId}`,
                method: 'delete',
            }
        }
    }
}

/***
 * @param {'HARD_DELETE' | 'WITH_ANONYMIZATION'} deletionType
 */
export function deletePatient(userDetailsId, deletionType) {
    return {
        type: actionTypes.DELETE_PATIENT,
        payload: {
            client: 'api',
            request: {
                url: `patient/${userDetailsId}`,
                method: 'delete',
                params: {
                    deletionType: deletionType
                }
            }
        }
    }
}

export function getAlreadyInvitedEmails(props) {
    return {
        type: actionTypes.GET_ALREADY_INVITED_EMAILS,
        payload: {
            client: 'api',
            request: {
                url: '/invited-emails',
                method: 'post',
                data: props
            }
        }
    }
}
export function getContacts(patientId, entityType=FULL_PATIENT, entityId = patientId) {
    return {
        type: actionTypes.GET_CONTACTS_FOR,
        payload: {
            client: 'api',
            request: {
                url: `program/contacts/${patientId}/${entityType}/${entityId}`,
                method: 'get',
            }
        }
    }
}

export function uploadProfileImage(userDetailsBusinessId, file) {
    const formData = new FormData();
    formData.append('businessId', userDetailsBusinessId);
    formData.append('content', new Blob([file]));
    return {
        type: actionTypes.UPLOAD_PROFILE_IMAGE,
        payload: {
            client: 'apiMultipart',
            request: {
                method: 'post',
                url: 'user-details/contact-img',
                data: formData
            }
        }
    };
}

export function fetchCareProviderContacts(careProviderId) {
    return {
        type: actionTypes.FETCH_CARE_PROVIDER_CONTACTS,
        payload: {
            client: 'api',
            request: {
                method: 'get',
                url: `user-details/care-provider/${careProviderId}`,
            }
        }
    }
}

export function getWalkthroughStatuses() {
    return (dispatch) => {
        return dispatch({
        type: actionTypes.FETCH_WALKTHROUGH_STATUSES,
        payload: {
            client: 'api',
            request: {
                url: '/me/walkthroughs',
                method: 'get',
            }
        }
    })
}
}
