import {persianGreen} from 'components/colors/Colors';

export default function Divider(props) {
    return <div style={{
        height: 2,
        alignSelf: 'center',
        width: '90%',
        backgroundColor: props.color || persianGreen,
        margin: 5,
        marginTop: props.top || 20,
        marginBottom: props.bottom || 5,
        ...props.style
    }}/>
}
