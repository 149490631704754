import { Checkbox } from "@material-ui/core";
export const DisableToggle = ({ rule, idx, handleUpdateRule }) => {
 return <Checkbox
 style={{padding: '5px'}}
            checked={!rule.properties.disabled}
            onChange={() => {
                handleUpdateRule(idx, {
                    ...rule.properties,
                    disabled: !rule.properties.disabled,
                });
            }}
            color="primary"
          />
 };
