import Flex from 'components/grid/Flex';
import {Typography, Button, Dialog} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import {useState} from 'react';
import {useGetCareProvidersForRegistrationQuery, useGrantAccessToCareProviderMutation} from 'scenes/patient/PatientApi';
import {Autocomplete} from '@material-ui/lab';
import DefaultTextField from 'components/hmo-textfield/DefaultTextField';
import EyeIcon from '@material-ui/icons/VisibilitySharp';
import {
    isPatient
} from 'domain/User.model';
import utils from 'utils/Utils';
import {useSelector} from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import {useEnqueueSuccess, useEnqueueError} from 'components/alert/SnackbarHooks';

const CareProviderAccess = ({careProvidersForPatient, patient}) => {
    const {t: translate} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const user = useSelector(state => state.mainReducer.user);
    const {data: allCareProviders} = useGetCareProvidersForRegistrationQuery();
    const [grantAccessToCareProvider] = useGrantAccessToCareProviderMutation();
    const [selectedCareProvider, setSelectedCareProvider] = useState('');
    const toggleOpen = () => {
        setIsOpen(!isOpen)
        setSelectedCareProvider('');
    };
    const enqueueError = useEnqueueError();
    const enqueueSuccess = useEnqueueSuccess();
    const selectCareProvider = (event, careProvider) => setSelectedCareProvider(careProvider);
    const grantAccess = () => {
        grantAccessToCareProvider({
            careProviderId: selectedCareProvider.id,
            patientId: patient.id
        }).unwrap()
                .then(() => {
                    enqueueSuccess();
                })
                .catch(error => {
                    enqueueError();
                })
                .then(() => {
                    toggleOpen();
                });
    }
    const stopPropagation = (event) => event.stopPropagation();
    return <>

        <Button onClick={toggleOpen}
                style={{textTransform: 'unset', color: '#256af6', fontWeight: 'bold'}}>
            {translate("global.grant-access-doctor")}
            <AddIcon style={{backgroundColor: '#cbdbfd', borderRadius: '50%', marginLeft: 10}}/>
        </Button>
        <Dialog open={isOpen} maxWidth={"sm"} onClick={stopPropagation}>
            <Flex item container column padding={20} style={{minWidth: 400, backgroundColor: '#f8f8fa'}}>
                {
                    !isPatient(user) &&
                    <Flex item={'0 1 auto'} container style={{
                        width: '50%',
                        borderBottom: '1px solid black'
                    }}>
                        <Typography>
                            {translate(`global.patient`)}: &nbsp; {utils.formattedUserName(patient)}
                        </Typography>


                    </Flex>
                }
                <Flex id={'TITLE'} item container alignItems={'center'} justifyContent={'space-between'}>
                    <Typography variant={'h6'}>
                        {
                            isPatient(user)
                                    ? translate(`global.invite-title`)
                                    : translate(`global.invite-title-manager`)
                        }
                    </Typography>
                    <EyeIcon/>
                </Flex>
                <Flex item container column>
                    <Typography>
                        {translate(`global.invite-care-provider-text-1-${isPatient(user) ? 'patient' : 'manager'}`)}
                    </Typography>
                    <Typography>
                        {translate(`global.invite-care-provider-text-2-${isPatient(user) ? 'patient' : 'manager'}`)}
                    </Typography>
                    <Autocomplete
                            options={allCareProviders || []}
                            onChange={selectCareProvider}
                            name={'selectedCareProvider'}
                            value={selectedCareProvider || null}
                            getOptionSelected={(option, value) => {
                                return option.id === value.id
                            }}
                            getOptionDisabled={(option) => careProvidersForPatient.some(item => item.id === option.id)}
                            style={{marginBottom: 10}}
                            getOptionLabel={careProvider => careProvider.name}
                            renderOption={careProvider => careProvider.name}
                            renderInput={params => (<DefaultTextField
                                    {...params}
                                    label={translate("global.care-provider")}/>)}
                    />
                    <Flex item container justifyContent={'space-between'}>
                        <Button variant={'outlined'} size={'small'} style={{margin: 5}}
                                onClick={toggleOpen}>
                            {translate('global.cancel')}
                        </Button>
                        <Button color={'secondary'} variant={'contained'} size={'small'}
                                style={{margin: 5}} disabled={!selectedCareProvider} onClick={grantAccess}>
                            {translate('global.grant-access')}
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
        </Dialog>
    </>;
};

export default CareProviderAccess;
