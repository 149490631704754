import {TextField} from "@material-ui/core";

const EpaTextField = props =>
        <TextField
                fullWidth
                {...props}
                error={!!props.error}
                value={props.value || ''}
                inputProps={{
                    autoComplete: "off",
                    ...props.inputProps
                }}
                InputLabelProps={{
                    shrink: true,
                    ...props.InputLabelProps
                }}
        />

export default EpaTextField;
