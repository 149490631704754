import React, {Component} from 'react';
import {
    withStyles,
    Button
} from "@material-ui/core";
import {
    fafaWhite,
    white
} from 'components/colors/Colors';

const styles = theme => ({
    root: {
        fontWeight: 'bold',
        backgroundColor: fafaWhite,
        color: '#030303',
        border: `2px solid #245B67`,
        boxShadow: 'none',
        textTransform: 'none',
        borderRadius: 10,
        height: 34,
        '&:hover': {
            backgroundColor: white,
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: white,
        },
    },
});

export class HmoBorderedButton extends Component {

    render() {
        const {classes, children, ...other} = this.props;

        return (
            <Button
                {...{
                    variant: "contained",
                    classes: {
                        ...classes
                    },
                    ...other,
                }}
            >
                {children}
            </Button>
        );
    }
}

export default withStyles(styles)(HmoBorderedButton);
