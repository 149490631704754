import React, {Component} from 'react';
import {Typography, withStyles, IconButton, CircularProgress} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import {rainForest} from 'components/colors/Colors';
import {
    ArrowBack as BackIcon,
} from '@material-ui/icons';

const styles = theme => ({
    titleText: {
        fontSize: 15,
        marginRight: 10,
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        textTransform: 'uppercase',
    },
    titleBarRoot: {
        minHeight: 45,
        paddingLeft: 4,
        paddingRight: 20,
    }
});

export class Title extends Component {
    render() {
        const {classes, title, onBack, style, isLoading} = this.props;

        return <Flex container justifyContent={'space-between'} alignItems={'center'} className={classes.titleBarRoot}
                     style={{
                         borderBottom: '1px solid #E2E1E4',
                         ...style
                     }}>
            <Flex item container alignItems={'center'}>
                <Flex item={'0 1 auto'} style={{marginLeft: 4, marginRight: 4,}}>
                    {
                        onBack &&
                        <IconButton
                                disableRipple={true}
                                onClick={onBack}
                                disabled={isLoading}
                                style={{padding: 3}}
                        >
                            {
                                isLoading &&
                                <CircularProgress style={{width: 20, height: 20}}/>
                            }
                            {
                                !isLoading &&
                                <BackIcon style={{color: rainForest}}/>
                            }
                        </IconButton>
                    }

                </Flex>
                <Typography className={classes.titleText}>
                    {title}
                </Typography>
                {
                    isLoading &&
                    <CircularProgress style={{width: 24, height: 24, position: "fixed", left: 'calc(165px + 29%)'}}/>
                }
                <Flex item container justifyContent={'flex-end'}>
                    {this.props.children}
                </Flex>
            </Flex>
        </Flex>;
    }
}

export default withStyles(styles)(withTranslation()(Title));
