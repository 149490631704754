import React from 'react';
import Divider from "@mui/material/Divider";

const InputDivider = (props) => {
    return (
        <Divider flexItem light orientation="horizontal" variant="fullWidth" {...props} style={{width: '100%', ...props.style}}/>
    );
};

export default InputDivider;
