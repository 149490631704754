import {FormControlLabel, Checkbox, useTheme} from "@material-ui/core";
import {cond, eq} from "lodash/fp";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {isCaseManager} from "../../../../domain/User.model";

const SEVERITY_LEVELS = {
    WARNING: "warning",
    CRITICAL: "critical",
};

const permissions = [
    {
        role: "patient",
        permissions: [
            {
                name: "view",
                fieldName: "patientCanView",
            },
            {
                name: "complete",
                fieldName: "patientCanComplete",
                displayedSeverity: SEVERITY_LEVELS.WARNING,
            },
        ],
    },
    {
        role: "care-provider",
        permissions: [
            {
                name: "edit",
                fieldName: "editable",
            },
            {
                name: "delete",
                fieldName: "skippable",
                displayedSeverity: SEVERITY_LEVELS.CRITICAL,
            },
        ],
    },
];

export const EditingTaskRulesSettingsPanel = ({step, setStep}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const user = useSelector((state) => state.mainReducer.user);

    const permissionSeverityToColor = cond([
        [eq(SEVERITY_LEVELS.CRITICAL), () => theme.palette.error.main],
        [eq(SEVERITY_LEVELS.WARNING), () => theme.palette.warning.main],
        [() => true, () => 'inherit'],
    ]);
    return <div style={{display: "flex", flexDirection: "row", gap: "38px"}}>
        {permissions
                .filter(item => item.role === 'care-provider' ? isCaseManager(user) : true)
                .map((rolePermission) => (
                        <div style={{display: "flex", flexDirection: "column"}} key={rolePermission.role}>
                            <h2>{t(["program", "step-permissions", rolePermission.role, "title"].join("."))}</h2>
                            {rolePermission.permissions.map((permission) => {
                                let fieldName = permission.fieldName || permission.name;
                                return (
                                        <FormControlLabel
                                                disabled={permission.disabled}
                                                key={permission.name}
                                                control={
                                                    <Checkbox
                                                            checked={fieldName in step ? step[fieldName] : true}
                                                            color={"primary"}
                                                            onChange={() => {
                                                                setStep({
                                                                    ...step,
                                                                    [fieldName]: !Boolean(step[fieldName])
                                                                });
                                                            }}
                                                    />
                                                }
                                                style={{color: permissionSeverityToColor(permission.displayedSeverity)}}
                                                label={t(
                                                        [
                                                            "program",
                                                            "step-permissions",
                                                            rolePermission.role,
                                                            "permissions",
                                                            permission.name,
                                                        ].join(".")
                                                )}
                                        />
                                );
                            })}
                        </div>
                ))}
    </div>;
};
