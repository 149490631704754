import {logout, loadAccessToken, heartBeat} from './Main.action'
import {initializeWebsocket} from "socket/Socket.action";
import {setupCaseConfigurations} from 'scenes/case/Case.action';
import { getWalkthroughStatuses } from 'scenes/user-manager/UserManager.action';

export default {
    mapStateToProps: state => {
        return {
            user: state.mainReducer.user,
            isConfigurationLoaded: state.mainReducer.isConfigurationLoaded,
            websocketClient: state.socketReducer.client,
            websocketClientConnected: state.socketReducer.connected,
            authenticated: state.mainReducer.authenticated,
            accessToken: state.mainReducer.accessToken,
            appScreenIsBlocked: state.mainReducer.appScreenIsBlocked,
            appIntroWalkthrough: state.mainReducer.walkthroughsCompleted.appIntroWalkthrough,
        }
    },
    mapDispatchToProps: dispatch => {
        return {
            logout: () => dispatch(logout()),
            loadAccessToken: () => dispatch(loadAccessToken()),
            initializeWebsocket: () => dispatch(initializeWebsocket()),
            setupCaseConfigurations: () => dispatch(setupCaseConfigurations()),
            heartBeat: () => dispatch(heartBeat()),
            getWalkthroughStatuses: () => dispatch(getWalkthroughStatuses()),
        }
    }
}
