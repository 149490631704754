import { IconButton } from "@material-ui/core";
import { HighlightOff } from "@material-ui/icons";
import { useTheme } from "@material-ui/core/styles";
export const RemoveRuleBtn = ({onClick}) => {
    const theme = useTheme();
    return (
        <div style={{ marginLeft: "10px", cursor: "pointer" }} onClick={onClick}>
            <IconButton size="small" style={{ marginBottom: "2px", color: theme.palette.error.light }}>
                <HighlightOff />
            </IconButton>
        </div>
    );
};
