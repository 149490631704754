import { DEPENDENT_ON_PREVIOUS_TASK, RECURRING, EXACT } from "domain/ProgramStepDateType.model";

export function dateValue({
    date,
    time,
    dateType,
    dateTimeSpan,
    dateTimeSpanValue,
    missingDependency,
    calculatedDateRenderer,
    previousDateType,
    dateUpdatedBy,
    dependency,
    previousDependency,
    isCompleted
}) {
    return {
        date: date ?? "",
        time,
        dateType,
        dateTimeSpan,
        dateTimeSpanValue,
        calculatedDateRenderer,
        missingDependency,
        previousDateType,
        dateUpdatedBy,
        dependency,
        previousDependency,
        isCompleted,
        //a wild this appears outside of a react class, its super effective... at making sure this will break eventually
        //TODO LM: Refactor 'this' out of existence
        // rendered view is going to be used for 3 cases:
        // 1. when date will be calculated via dependency
        // 2. when date was calculated via dependency and now set to manual by algorithm
        // 3. when date was calculated as recurring and new set to manual by algorithm
        // otherwise, we just want to show the date as is

        // this should be removed! this structure made some sense in the beginning, but right now it only produces problems
        getRenderedView() {
            const shouldUseRenderer =
                this.dateType === DEPENDENT_ON_PREVIOUS_TASK ||
                this.dateType === RECURRING ||
                (this.dateType === EXACT && this.previousDateType === DEPENDENT_ON_PREVIOUS_TASK && dateUpdatedBy === "ALGORITHM") ||
                (this.dateType === EXACT && this.previousDateType === RECURRING && dateUpdatedBy === "ALGORITHM")
            return shouldUseRenderer
                ? this.calculatedDateRenderer({
                      dateTimeSpan: this.dateTimeSpan,
                      dateTimeSpanValue: this.dateTimeSpanValue,
                      missingDependency: this.missingDependency,
                      dateType: this.dateType,
                      previousDateType: this.previousDateType,
                      date: this.date,
                      dependency: this.dependency,
                      previousDependency: this.previousDependency,
                      isCompleted,
                  })
                : isCompleted ? <span style={{color: '#00AE9E'}}>{this.date}</span> : this.date;
        },
        map(fn) {
            const mappedValue = this.date !== "" ? fn(this.date, this.time) : this.date;
            return dateValue({
                ...this,
                date: mappedValue,
            });
        },
    };
}
