import { useTranslation } from "react-i18next";
import { medicationListWalkthroughSteps } from "./MedicationListWalkthroughStepsConfig";
import { Walkthrough } from "../Walkthrough";
import { useDispatch, useSelector } from "react-redux";
import { SET_MEDICATION_LIST_WALKTHROUGH_COMPLETED } from '../../../scenes/Main.action.types';
import { updateUserWalkthroughs } from "../../user-manager/UserManager.action";

export const MedicationListWalkthrough = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const walkthroughsCompleted = useSelector((state) => state.mainReducer.walkthroughsCompleted);
    const { medicationListWalkthrough, appIntroWalkthrough, introMedicationPlanWalkthrough } = walkthroughsCompleted;

    const translateStep = (stepName, translationPart) => {
        return t(`walkthrough.medication-list.${stepName}.${translationPart}`);
    }

    const toggleWalkthrough = () => {
        dispatch({type: SET_MEDICATION_LIST_WALKTHROUGH_COMPLETED , payload: !medicationListWalkthrough });
    }

    const onCompleted = () => {
        dispatch({type: SET_MEDICATION_LIST_WALKTHROUGH_COMPLETED , payload: true});
        dispatch(updateUserWalkthroughs({...walkthroughsCompleted, medicationListWalkthrough : true}));
    }

    const translatedSteps = medicationListWalkthroughSteps.map(step => {

    return {
        ...step,
        title: translateStep(step.title, "title"),
        content: translateStep(step.title, "content"),
    }
    });

    return !medicationListWalkthrough && appIntroWalkthrough && introMedicationPlanWalkthrough ? <Walkthrough steps={translatedSteps} toggleWalkthrough={toggleWalkthrough} onCompleteCallback={onCompleted}  /> : null;
};
