import * as actionTypes from './Clinics.action.types';
import update from "immutability-helper";
import {toDatesString} from "./edit/UnavailableDate";

export function listClinics() {
    return {
        type: actionTypes.LIST_CLINICS,
        payload: {
            client: 'api',
            request: {
                method: 'get',
                url: `clinics`,
            }
        }
    }
}

export function getClinic(businessId) {
    return {
        type: actionTypes.GET_CLINIC,
        payload: {
            client: 'api',
            request: {
                method: 'get',
                url: `clinic/${businessId}`,
            }
        }
    }
}

export function setActivityTab(activityTab){
    return {
        type: actionTypes.SET_ACTIVITY_TAB,
        data: {activityTab}
    }
}

export function upsertClinic(clinic) {
    return {
        type: clinic.businessId ? actionTypes.UPDATE_CLINIC : actionTypes.CREATE_CLINIC,
        payload: {
            client: 'api',
            request: {
                method: 'post',
                url: `clinic`,
                data: update(clinic, {
                    centers: {
                        $apply: centers => centers.map(center => ({
                            ...center,
                            tumorBoardUnavailable: toDatesString(center.tumorBoardUnavailable)
                        }))
                    }
                })
            }
        }
    }
}

export function upsertClinicCenter(clinicCenter) {
    return {
        type: clinicCenter.businessId ? actionTypes.UPDATE_CLINIC_CENTER : actionTypes.CREATE_CLINIC_CENTER,
        payload: {
            client: 'api',
            request: {
                method: 'post',
                url: `clinic-center`,
                data: clinicCenter
            }
        }
    }
}

export function addClinicLog(comment, clinicBusinessId, type) {
    return {
        type: actionTypes.ADD_LOG,
        payload: {
            client: 'api',
            request: {
                url: `clinic/${clinicBusinessId}/log/${type}`,
                method: 'post',
                data: {comment}
            }
        }
    }
}

export function fetchClinicLogs(clinicBusinessId) {
    return {
        type: actionTypes.FETCH_CLINIC_LOGS,
        payload: {
            client: 'api',
            request: {
                method: 'get',
                url: `clinic/${clinicBusinessId}/logs`,
            }
        }
    }
}

export function fetchContacts(clinicBusinessId) {
    return {
        type: actionTypes.FETCH_CONTACTS,
        payload: {
            client: 'api',
            request: {
                method: 'get',
                url: `user-details/clinic/${clinicBusinessId}`,
            }
        }
    }
}
