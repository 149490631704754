import * as actionTypes from './Main.action.types';
import jwt_decode from "jwt-decode";
import _ from 'lodash';
import moment from 'moment';
import {fetchCurrentUser, fetchCurrentUserDetails} from 'scenes/user-manager/UserManager.action';

export function login(emailAddress, password, verifyCode) {
    return (dispatch) => {
        if (verifyCode) {
            return dispatch({
                type: actionTypes.LOGIN,
                payload: {
                    client: 'auth',
                    request: {
                        url: '/verifyCode',
                        method: 'post',
                        data: {
                            authenticationProvider: 'INTERNAL',
                            emailAddress,
                            verifyCode,
                            origin: 'ADMIN_INTERFACE'
                        }
                    }
                }
            }).then((response) => {
                dispatch(setupAccessData(response.payload.data.accessToken));
                dispatch({
                    type: actionTypes.LOAD_REFRESH_TOKEN,
                    data: {refreshToken: response.payload.data.refreshToken}
                });
                window.localStorage.setItem("accessToken", response.payload.data.accessToken);
                window.localStorage.setItem("mfaToken", response.payload.data.mfaToken);
                const timeStamp = moment().toISOString();
                window.localStorage.setItem('lastTimeStamp', timeStamp);
                window.localStorage.setItem("refreshToken", response.payload.data.refreshToken);
                return response;
            });
        }

        return dispatch({
            type: actionTypes.LOGIN,
            payload: {
                client: 'auth',
                request: {
                    url: '/login',
                    method: 'post',
                    data: {
                        mfaToken: window.localStorage.getItem("mfaToken"),
                        authenticationProvider: 'INTERNAL',
                        emailAddress,
                        password,
                        origin: 'ADMIN_INTERFACE'
                    }
                }
            }
        }).then((response) => {
            if (response.payload.data.mfaRequired) {
                return response;
            }
            dispatch(setupAccessData(response.payload.data.accessToken));
            dispatch({
                type: actionTypes.LOAD_REFRESH_TOKEN,
                data: {refreshToken: response.payload.data.refreshToken}
            });
            window.localStorage.setItem("accessToken", response.payload.data.accessToken);
            const timeStamp = moment().toISOString();
            window.localStorage.setItem('lastTimeStamp', timeStamp);
            window.localStorage.setItem("refreshToken", response.payload.data.refreshToken);
            return response;
        });
    }
}

export function silentLogin(refreshToken) {
    return dispatch => {
        return dispatch({
            type: actionTypes.SILENT_LOGIN,
            payload: {
                client: 'auth',
                request: {
                    url: '/login',
                    method: 'post',
                    data: {
                        authenticationProvider: 'TOKEN_RENEWAL',
                        token: refreshToken,
                    }
                }
            }
        }).then(response => {
            //TODO: figure out is it ok that we fetch current user every time when we silent login?
            dispatch(setupAccessData(response.payload.data.accessToken));
            dispatch({
                type: actionTypes.LOAD_REFRESH_TOKEN,
                data: {refreshToken: response.payload.data.refreshToken}
            })
            window.localStorage.setItem("accessToken", response.payload.data.accessToken);
            window.localStorage.setItem("refreshToken", response.payload.data.refreshToken);
            const timeStamp = moment().toISOString();
            window.localStorage.setItem('lastTimeStamp', timeStamp);
            return response;
        })
    }
}

export function loadAccessToken() {
    return dispatch => {
        const accessToken = window.localStorage.getItem("accessToken");
        const refreshToken = window.localStorage.getItem("refreshToken");
        const timeStamp = moment().toISOString();
        window.localStorage.setItem('lastTimeStamp', timeStamp);
        if (accessToken) {
            dispatch(setupAccessData(accessToken));
        }
        if (refreshToken) {
            dispatch({
                type: actionTypes.LOAD_REFRESH_TOKEN,
                data: {refreshToken}
            });
        }
    }
}

function setupAccessData(accessToken, providerId) {
    return dispatch => {
        let parsedToken;
        try {
            parsedToken = jwt_decode(accessToken);
        } catch (e){
            dispatch(logout());
        }
        if(moment.unix(parsedToken?.exp).isBefore(moment())){
            dispatch(logout());
        } else {
            const userId = parsedToken.sub;
            const firstName = _.get(parsedToken, 'firstName');
            const lastName = _.get(parsedToken, 'lastName');
            const userName = !firstName && !lastName ? 'as a Guest' : firstName + (firstName ? ' ' : '') + lastName;
            dispatch(fetchCurrentUser()).then(response => {
                    if(response.payload.data.userDetailsId) {
                        dispatch(fetchCurrentUserDetails(response.payload.data.userDetailsId))
                    }
                }
            ).catch(() => dispatch(logout()));
            dispatch({
                type: actionTypes.LOAD_ACCESS_TOKEN,
                data: {accessToken, userId, userName}
            });
        }
    }
}

export function requestNewPassword(username) {
    const data = {
        emailAddress: username
    }
    return {
        type: actionTypes.REQUEST_NEW_PASSWORD,
        payload: {
            client: 'auth',
            request: {
                method: 'post',
                url: 'user/new-password',
                data
            }
        }
    }
}

export function saveNewPassword(password, token) {
    return {
        type: actionTypes.SAVE_NEW_PASSWORD,
        payload: {
            client: 'auth',
            request: {
                method: 'post',
                url: `user/password-reset/${token}`,
                data: {password}
            }
        }
    }
}

export function logout() {
    return dispatch => {
        return dispatch({
            type: actionTypes.LOGOUT,
            payload: {
                client: 'api',
                request: {
                    url: 'logout',
                    method: 'post',
                }
            }
        }).finally(() => {
            window.localStorage.removeItem("accessToken");
            window.localStorage.removeItem("refreshToken");
            window.localStorage.removeItem("selectedRole");
            window.location.href = "/";
        });
    }
}

export function silentLogout() {
    return () => {
        window.localStorage.removeItem("accessToken");
        window.localStorage.removeItem("refreshToken");
    }
}

export function heartBeat() {
    return {
        type: actionTypes.HEART_BEAT,
        payload: {
            client: 'api',
            request: {
                method: 'get',
                url: 'heart-beat',
            }
        }
    }
}
export function setHandledOutstandingInvites(status) {
    return {
        type: actionTypes.SET_HANDLED_OUTSTANDING_INVITES,
        payload: {
            data: status
        }
    }
}
