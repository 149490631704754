import React, {Component} from 'react';
import {CASE_EDIT, CASES} from 'routes/routes';
import {connect} from 'react-redux';
import connector from './CaseList.connect';
import _ from 'lodash';
import {withNavigation} from 'utils/Route.utils';
import {toBoolean} from 'utils/Utils';
import {isCaseManager, isPatient, isClinic} from 'domain/User.model';
import CaseListForManager from './CaseListForManager';
import CaseListForClinic from './CaseListForClinic';
import update from 'immutability-helper';
import {DONE, CANCELLATION} from 'domain/WorkflowStatus';

export class CaseList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            subscribedToWebSocket: false,
            loggedInUsers: [],
            statusSortMap: {}
        }
    }

    componentDidMount() {
        this.props.listCases();
        this.listRefresher = setInterval(() => this.props.listCases(), 60000 * 5);
        this.subscribeToWebsocketTopics();
    }

    componentWillUnmount() {
        clearInterval(this.listRefresher);
        this.unsubscribeFromWebsocketTopics();
    }

    subscribeToWebsocketTopics() {
        if (!this.state.subscribedToWebSocket && this.props.websocketClientConnected) {
            this.setState({subscribedToWebSocket: true});
            this.props.websocketClient.subscribe("/topic/logged-in-users", (fragment) => {
                const payload = JSON.parse(fragment.body);
                if (!_.isEqual(payload, this.state.loggedInUsers)) {
                    this.setState({
                        loggedInUsers: payload
                    })
                }
            });
        }
    }

    unsubscribeFromWebsocketTopics() {
        if (this.state.subscribedToWebSocket && this.props.websocketClientConnected) {
            this.setState({subscribedToWebSocket: false});
            this.props.websocketClient.unsubscribe("/topic/logged-in-users");
        }
    }

    handleDoubleClick = caseBusinessId => {
        this.props.reset();
        this.props.navigate(CASE_EDIT.pathWithId(caseBusinessId), {state: {previousPath: CASES.path}});
    };

    handleClick = caseBusinessId => {
        this.props.reset();
        this.props.navigate(CASE_EDIT.pathWithId(caseBusinessId), {state: {previousPath: CASES.path}});
    };

    create = () => {
        this.props.reset();
        this.props.fetchNewCase().then(response => {
            const caseBusinessId = response.payload.data.businessId;
            this.props.navigate(CASE_EDIT.pathWithId(caseBusinessId), {state: {isNewCase: true}});
        });
    };

    handleSearchTextChange = event => {
        this.props.updateFilters({
            searchText: event.target.value,
        });
    };

    resetSearchText = () => {
        this.props.updateFilters({
            searchText: '',
        });
    };

    resetSelectedTags = () => {
        this.props.updateFilters({
            selectedTags: []
        });
    };

    handleTagFilterRelationChange = event => {
        this.props.updateFilters({
            tagFilterRelation: event.target.value
        });
    };

    toggleTagSelection = tag => {
        this.props.updateFilters({
            selectedTags: this.props.selectedTags.includes(tag)
                    ? _.without(this.props.selectedTags, tag)
                    : [...this.props.selectedTags, tag]
        });
    };

    toggleSelectedCaseManagerId = caseManagerId => {
        this.props.updateFilters({
            selectedCaseManagerIds: this.props.selectedCaseManagerIds.includes(caseManagerId)
                    ? _.without(this.props.selectedCaseManagerIds, caseManagerId)
                    : [...this.props.selectedCaseManagerIds, caseManagerId]
        });
    };

    togglePanel = panel => () => {
        this.props.updatePanels(
                this.props.expandedPanels.includes(panel)
                        ? _.without(this.props.expandedPanels, panel)
                        : [...this.props.expandedPanels, panel]
        );
    };

    toggleReminderOnly = () => {
        this.props.updateFilters({
            reminderOnly: !this.props.reminderOnly
        });
    };

    setHistory = value => {
        this.props.updateFilters({
            isHistoryActive: value
        });
    };

    acceptCase = (_case) => {
        this.props.acceptCase(_case).then(() => {
            this.props.listCases();
        })
    }

    sortBy = (status, sortParameter) => () => {
        if (this.state.statusSortMap[status]) {
            this.setState({
                statusSortMap: update(this.state.statusSortMap, {
                    [status]: {
                        $set: {
                            sortParameter,
                            reverseSort: this.state.statusSortMap[status].sortParameter === sortParameter
                                    && !this.state.statusSortMap[status].reverseSort
                        }
                    }
                })
            })
        } else {
            this.setState({
                statusSortMap: {
                    ...this.state.statusSortMap,
                    [status]: {
                        sortParameter,
                        reverseSort: false
                    }
                }
            })
        }
    }

    byStatusProperty = status => (a, b) => {
        const sortParameter = _.get(this.state, `statusSortMap[${status}].sortParameter`);
        const reverseSort = _.get(this.state, `statusSortMap[${status}].reverseSort`);

        if (_.get(a, sortParameter) === _.get(b, sortParameter)) {
            return 0;
        }

        if (!_.get(a, sortParameter)) {
            return reverseSort ? 1 : -1;
        }

        if (!_.get(b, sortParameter)) {
            return reverseSort ? -1 : 1;
        }

        if (_.get(a, sortParameter)?.toString().toLowerCase() > _.get(b, sortParameter)?.toString().toLowerCase()) {
            return reverseSort ? -1 : 1;
        } else if (_.get(b, sortParameter)?.toString().toLowerCase() > _.get(a, sortParameter)?.toString().toLowerCase()) {
            return reverseSort ? 1 : -1;
        } else {
            return 0;
        }
    }

    render() {
        const {
            user, cases, workflowStatuses, selectedCaseManagerIds, searchText, selectedTags,
            tagSuggestions, caseManagers, caseManagerSuggestions, tagFilterRelation, filteredCases, isFilterActive,
            expandedPanels, reminderOnly, isHistoryActive
        } = this.props;
        const {
            handleDoubleClick, handleClick, create, handleSearchTextChange, resetSearchText, toggleTagSelection,
            toggleSelectedCaseManagerId, handleTagFilterRelationChange, resetSelectedTags, togglePanel, setHistory,
            toggleReminderOnly, acceptCase, sortBy, byStatusProperty
        } = this;
        const {loggedInUsers, statusSortMap} = this.state;
        const notCancelledCases = cases.filter(caseInArray => caseInArray.status !== CANCELLATION);
        let statuses = isFilterActive
                ? workflowStatuses.filter(status => {
                    return _.some(filteredCases, caseInArray => caseInArray.status === status.id)
                })
                : workflowStatuses.filter(status => status.groupId !== DONE);
        let filteredStatusGroups = _.union(statuses.map(status => status.groupId));
        const isAllExpanded = toBoolean(reminderOnly
                || selectedCaseManagerIds.length > 0
                || selectedTags.length > 0
                || searchText);
        return (
                <>
                    {
                        isCaseManager(user) &&
                        <CaseListForManager {...{
                            user, selectedCaseManagerIds, searchText, selectedTags, tagSuggestions, caseManagers,
                            statuses, notCancelledCases, filteredStatusGroups, loggedInUsers, isHistoryActive,
                            isAllExpanded, caseManagerSuggestions, tagFilterRelation, filteredCases, isFilterActive,
                            expandedPanels, reminderOnly, handleDoubleClick, handleClick, create, statusSortMap,
                            handleSearchTextChange, resetSearchText, toggleTagSelection, toggleSelectedCaseManagerId,
                            handleTagFilterRelationChange, togglePanel, resetSelectedTags, setHistory, sortBy,
                            toggleReminderOnly, byStatusProperty
                        }}/>
                    }
                    {
                        isClinic(user) && !isCaseManager(user) && !isPatient(user) &&
                        <CaseListForClinic {...{
                            user, searchText, selectedTags, tagSuggestions, caseManagers, tagFilterRelation, statuses,
                            notCancelledCases, filteredCases, isFilterActive, isHistoryActive, handleDoubleClick,
                            handleClick, handleSearchTextChange, resetSearchText, toggleTagSelection, acceptCase,
                            handleTagFilterRelationChange, resetSelectedTags, togglePanel, setHistory, sortBy,
                            byStatusProperty, statusSortMap
                        }}/>
                    }
                </>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(withNavigation(CaseList));
