import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { IconButton } from "@material-ui/core";

export const AddOrClauseButton = ({ onClick }) => {
    return (
        <div style={{marginLeft: '8px'}}>
            <IconButton size="small" onClick={onClick} style={{ marginBottom: "2px" }}>
                <MoreHorizIcon />
            </IconButton>
        </div>
    );
};
