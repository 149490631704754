import _ from "lodash";
import HMOApi, { DOCUMENT_LINK } from "store/api/HMOApi";
const BASE_URL = "/document-links"
export const documentLinksApi = HMOApi.injectEndpoints({
    endpoints: (build) => ({
        uploadDocumentLink: build.mutation({
            query: ({id, storageTag, documentLinkDTO}) => {
                return {
                    url: `${BASE_URL}/${storageTag}`,
                    method: "POST",
                    data: {
                        ...documentLinkDTO,
                        entityBusinessId: id,
                    },
                }
            },
            invalidatesTags: [DOCUMENT_LINK],
        }),
        getDocumentLinks: build.query({
            query: ({id: businessId, storageTag}) => ({
                url: `/document-links/${storageTag}/${businessId}`,
            }),
            providesTags: [DOCUMENT_LINK],
        }),
        getMultipleDocumentLinks: build.query({
            async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
                const requests = args.map(data => fetchWithBQ({ url: `/document-links/${data.storageTag}/${data.entityBusinessId}`}));
                const responses = await Promise.all(requests).catch(err => {
                    return {error: err}
                });
                const result = responses.flatMap(x => x.data);
                const orderedData = _.orderBy(result, [obj => new Date(obj.uploadTime)], ['asc'])
                return {data: orderedData };
            },
            providesTags: [DOCUMENT_LINK],
        }),
        updateDocumentLink: build.mutation({
            query: ({id, documentLinkDTO}) => ({
                url: `${BASE_URL}/${id}`,
                method: "PUT",
                data: documentLinkDTO
            }),
            invalidatesTags: [DOCUMENT_LINK]
        }),
        deleteDocumentLink: build.mutation({
            query: (id) => ({
                url: `${BASE_URL}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [DOCUMENT_LINK]
        }),
        markDocumentLinkAsRead: build.mutation({
            query: (id) => ({
                url: `${BASE_URL}/${id}/mark-as-visited`,
                method: "get",
            }),
            invalidatesTags: [DOCUMENT_LINK]
        }),
    }),
});

export const {
    useGetDocumentLinksQuery,
    useGetMultipleDocumentLinksQuery, //TODO LM: Only used once
    useUploadDocumentLinkMutation,
    useDeleteDocumentLinkMutation,
    useUpdateDocumentLinkMutation,
    useMarkDocumentLinkAsReadMutation,
} = documentLinksApi;
