import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, Button, Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useIsManager } from "../../../utils/useIsManager";

const useStyles = makeStyles({
    placeholder: {
        "& textarea::placeholder": {
            fontStyle: "italic",
            color: "hsla(0,0%,0%,.38)",
        },
    },
});

export const ProgramUnsubscribeModal = ({ isOpen, onClose, onUnsubscribe, programName, requestToUnsubscribe }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const [reason, setReason] = useState("");
    const userIsManager = useIsManager();
    const canSubmit = reason !== "" || requestToUnsubscribe;

    const actionButtonBgColor = !canSubmit ? theme.palette.error.light : theme.palette.error.main;

    const getTranslationPath = getTranslationPathWithRole(userIsManager, requestToUnsubscribe);

    const titleText = t(getTranslationPath("title"), { programName: programName });
    const bodyText = t(getTranslationPath("body-text"), { programName: programName });
    const textFieldLabel = t(getTranslationPath("text-field-label"));
    const cancelButtonLabel = t("global.cancel").toUpperCase();
    const actionButtonLabel = t(getTranslationPath("unsub-btn")).toUpperCase();
    const unsubscribeTextFieldPlaceholderText = t(getTranslationPath("reason-placeholder"));

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle style={{ color: theme.palette.error.main }}>{titleText}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {bodyText}
                </DialogContentText>
                <TextField
                    value={reason}
                    fullWidth={true}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label={textFieldLabel}
                    InputProps={{ className: classes.placeholder }}
                    required={!requestToUnsubscribe}
                    multiline
                    minRows={3}
                    placeholder={unsubscribeTextFieldPlaceholderText}
                    variant="standard"
                    onChange={(e) => {
                        setReason(e.target.value);
                    }}
                />

                <Box style={{ gap: "10px" }} display={"flex"} mt={2} flexDirection="row" justifyContent={"flex-end"}>
                    <Button onClick={onClose} color="primary" size="small" variant="outlined">
                        {cancelButtonLabel}
                    </Button>
                    <Button
                    color="secondary"
                    size="small"
                        disabled={!canSubmit}
                        onClick={() => onUnsubscribe(reason)}
                        style={{ backgroundColor: actionButtonBgColor, color: "#FFF" }}
                        variant="contained"
                    >
                        {actionButtonLabel}
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

/**
 * @param {boolean} isUserManager
 * @returns {function("body-text" | "title" | "text-field-label" | "unsub-btn" | "reason-placeholder"): string}
 */
function getTranslationPathWithRole(isUserManager, isUnsubscribeRequested) {
    return (textType) => {
        const basePath = "program.unsubscribe-modal";
        const roleDependantPath = isUserManager ? "manager" : "patient";
        const managerPathDependingOnUnsubscribeStatus = isUserManager ? (isUnsubscribeRequested ? "user-requested-unsubscribe" : "default") : "";
        return [basePath, roleDependantPath, managerPathDependingOnUnsubscribeStatus, textType]
            .filter((partPath) => partPath !== "")
            .join(".");
    };
}
