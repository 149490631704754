import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@mui/icons-material/EditOutlined";
import {useListOfFilesStyles} from './ListOfFiles';
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import {capitalizeFileExtension, truncateFilename} from "./utils";
import {useTheme, useMediaQuery} from "@material-ui/core";
import flow from "lodash/fp/flow";
import {downloadFile, downloadAndReturnFile} from "scenes/FileHandler.action";
import {formatFileSize} from "./utils";
import moment from 'moment';
import {UploadFilesAction, UploadFilesDispatchContext} from "./uploadFilesState";
import {useContext, useReducer} from "react";
import {useDispatch} from "react-redux";
import DownloadIcon from '@material-ui/icons/CloudDownload';
import PdfViewer from 'components/pdf-viewer/PdfViewer';
import {useTranslation} from 'react-i18next';

const DocumentListTile = ({file, isFileShownAsNew}) => {
    const {t} = useTranslation();
    const classNames = useListOfFilesStyles();
    const theme = useTheme();
    const isLgSize = useMediaQuery(theme.breakpoints.down("lg"));
    const getDisplayedFileName = flow([truncateFilename(isLgSize ? 18 : 14), capitalizeFileExtension]);
    const dispatchAction = useContext(UploadFilesDispatchContext);
    const dispatch = useDispatch();

    const [state, setState] = useReducer(
            (state, newState) => ({...state, ...newState}),
            {isPdfOpen: false, pdfFile: ''}
    );
    const onEditDocument = () => {
        dispatchAction({
            type: UploadFilesAction.OpenDocumentEditDialog,
            payload: file
        });
    };

    const downloadDocument = async () => {
        //NOTE Akim:
        // dispatch(HMOApi.util.invalidateTags([FILE])); that is an alternative to action above
        // invalidation of files to get new isRead status
        // feels a bit excessive so right now track of downloaded files is kept in local state
        dispatchAction({type: UploadFilesAction.AddIdToLocallyReadDocumentIds, payload: file.documentId});
        await dispatch(downloadFile(file, file.fileStorageType));
    };

    const downloadAndShowPdf = () => {
        dispatchAction({type: UploadFilesAction.AddIdToLocallyReadDocumentIds, payload: file.documentId});
        if (state.pdfFile) {
            setState({isPdfOpen: true});
        } else {
            dispatch(downloadAndReturnFile(file, file.fileStorageType))
                    .then(pdfFile => {
                        setState({
                            pdfFile,
                            isPdfOpen: true
                        });
                    });
        }
    }

    const toggleIsPdfOpen = () => {
        setState({isPdfOpen: !state.isPdfOpen});
    };

    return <li key={file.documentId} style={{outline: isFileShownAsNew ? "2px solid #9a50c6" : ""}}
               className={classNames.item}>
        <InsertDriveFileOutlinedIcon style={{color: isFileShownAsNew ? "#9A50C6" : ""}} className={classNames.icon}/>
        <div onClick={file.fileName.endsWith('pdf') ? downloadAndShowPdf : downloadDocument}
             className={classNames.textDescription}>
            <div className={classNames.documentTitle}>
                <span style={{color: "#9a50c6"}}>{isFileShownAsNew && t('global.new') + ': '}</span>
                <span>{getDisplayedFileName(file.fileName)}</span>
            </div>
            <div className={classNames.primaryInfo}>
                <div>{moment(file.uploadTime).format("DD MMMM YYYY")}</div>
                <div>{formatFileSize(file.fileSize)}</div>
                <div style={{marginLeft: "auto"}}>{file.label}</div>
            </div>
            <div className={classNames.additionalInfo}>{file.description}</div>
        </div>
        <div className={classNames.actionButtons}>
            {
                file.fileName.endsWith('pdf') &&
                <IconButton style={{padding: 3, marginLeft: 5}} onClick={downloadDocument}>
                    <DownloadIcon/>
                </IconButton>
            }
            <IconButton
                    size="small"
                    onClick={onEditDocument}
            >
                <EditIcon style={{fontSize: "21px"}}/>
            </IconButton>
            <IconButton
                    size="small"
                    onClick={() => {
                        dispatchAction({
                            type: UploadFilesAction.OpenDeleteConfirmationDialog,
                            payload: {
                                documentType: "FILE",
                                id: file.documentId,
                                documentName: file.fileName
                            },
                        });
                    }}
            >
                <CancelIcon style={{fontSize: "21px"}} className={classNames.deleteIcon}/>
            </IconButton>
        </div>
        {
            state.pdfFile && state.isPdfOpen &&
            <PdfViewer fileBlobUrl={state.pdfFile} onClose={toggleIsPdfOpen}/>
        }
    </li>;
};

export default DocumentListTile;
