import React, {PureComponent} from 'react';
import {CircularProgress, Dialog, DialogTitle, withStyles,} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import * as colors from 'components/colors/Colors';
import {withTranslation} from 'react-i18next';
import _ from 'lodash';
import ConfirmationDialog from 'components/confirmation-dialog/ConfirmationDialog';
import DocumentTile from './DocumentTile';
import UploadDialog from './UploadDialog';
import UpdateDialog from './UpdateDialog';
import utils from 'utils/Utils';
import {connect} from 'react-redux';
import connector from './DocumentSidebar.connect';
import {sideBarIndex} from 'components/zindex/zIndex';
import FileUploader from 'components/file-upload/FileUploader';
import {isPatient} from "domain/User.model";
import DocumentDownload from './DocumentDownload';
import update from 'immutability-helper';

const styles = theme => ({
    container: {
        zIndex: sideBarIndex
    }
});

export function getDocumentType(fileName = "", documentTypes) {
    const betweenUnderScores = /(?<=\_)(.*?)(?=\_)/g;
    const typeInFileName = fileName.match(betweenUnderScores);
    return documentTypes.find(type => type.shortName === (typeInFileName ? typeInFileName[0] : ''));
}

export function getDocumentState(file, documentTypes, user) {
    let originalFileNameParts = file?.name.split('.') || [];
    const fileExtension = originalFileNameParts.length > 1 ? originalFileNameParts.pop() : '';
    const fileNameWithoutExtension = originalFileNameParts.join("");
    const defaultToGeneral = isPatient(user);
    const documentType = getDocumentType(fileNameWithoutExtension, documentTypes);
    return {
        file,
        fileName: fileNameWithoutExtension + '.' + fileExtension,
        fileExtension,
        fileNameWithoutExtension,
        documentType: documentType
                ? documentType
                : defaultToGeneral
                        ? documentTypes.find(type => type.enumName === 'GENERAL')
                        : undefined
    }
}

export class DocumentsSidebar extends PureComponent {

    state = {
        isFileUploading: false,
        fileUploadInProgress: false,
        filesToUpload: [],
        isFileUploadDialogOpen: false,
        fileName: '',
        documentType: '',
        fileToDelete: '',
        documentToUpdate: '',
        allFileDownloadDialogOpened: false,
        documentUploadProgressIndex: ''
    };

    closeUploadDialog = () => {
        this.setState({
            isFileUploadDialogOpen: false
        });
    };

    setFileToDelete = document => {
        this.setState({
            fileToDelete: document
        })
    };

    setDocumentToUpdate = document => {
        this.setState({
            documentToUpdate: document,
            fileName: '',
            documentType: this.props.documentTypes.find(type => type.enumName === document.type)
        }, () => document && this.handleFileNameChange({target: {value: document.fileName}}))
    };

    uploadFiles = () => {
        const promises = [];
        this.state.filesToUpload.forEach((document, index) => {
            promises.push(new Promise((resolve, reject) => {
                if (this.state.isFileUploadDialogOpen) {
                    this.setState({
                        filesToUpload: update(this.state.filesToUpload, {
                            [index]: {
                                isUploadInProgress: {$set: true},
                                isVirusFound: {$set: false},
                                isError: {$set: false},
                            }
                        })
                    }, () => {
                        this.props.uploadCaseFile(document.file, document.fileName, document.documentType.enumName,
                                this.props.caseBusinessId, this.props.caseId, this.props.patientId)
                                .then(() => {
                                    if (this.state.documentType === 'TNE') {
                                        this.props.fetchCase(this.props.caseBusinessId)
                                    }
                                    this.setState({
                                        filesToUpload: update(this.state.filesToUpload, {
                                            [index]: {
                                                isUploadInProgress: {$set: false},
                                                isUploadDone: {$set: true},
                                            }
                                        })
                                    }, () => {
                                        resolve();
                                    })
                                })
                                .catch(error => {
                                    this.setState({
                                        filesToUpload: update(this.state.filesToUpload, {
                                            [index]: {
                                                isUploadInProgress: {$set: false},
                                                isUploadDone: {$set: true},
                                                isVirusFound: {$set: error.error.response.status === 422},
                                                isError: {$set: error.error.response.status !== 422},
                                            }
                                        })
                                    }, () => {
                                        reject();
                                    })
                                })
                    })
                }
            }))
        });
        Promise.all(promises).then(() => {
            this.setState({isFileUploadDialogOpen: false});
            this.props.fetchCase(this.props.caseBusinessId);
        })
    }

    handleFileNameChange = event => {
        const value = event.target.value;
        const isNewFileUpdate = !this.state.fileName;
        let originalFileNameParts = (isNewFileUpdate ? value : this.state.fileName).split('.');
        const fileExtension = originalFileNameParts.length > 1 ? originalFileNameParts.pop() : '';
        const fileNameWithoutExtension = isNewFileUpdate ? originalFileNameParts.join("") : value;

        this.setState({
            fileName: fileNameWithoutExtension + '.' + fileExtension,
            fileExtension,
            fileNameWithoutExtension
        })
    };

    handleFilesToUploadChange = filesToUpload => {
        this.setState({filesToUpload});
    }

    handleDocumentTypeChange = event => {
        this.setState({
            documentType: event.target.value
        })
    };

    onDrop = (acceptedFiles) => {
        const filesToUpload = acceptedFiles.map(file => getDocumentState(file, this.props.documentTypes, this.props.user));
        this.setState({
            filesToUpload,
            isFileUploadDialogOpen: true
        });
    };

    downloadCaseFile = document => {
        this.props.downloadCaseFile(document);
    };

    deleteFile = () => {
        this.props.deleteFile(this.state.fileToDelete);
        this.setState({
            fileToDelete: ''
        })
    };

    preventDots = event => {
        if (event.key.charCodeAt(0) === 46) {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    render() {
        const {
            classes, t: translate, documents = [], user, updateDocument, assignedDocumentIds, caseBusinessId,
            documentTypes, downloadAndReturnCaseFile
        } = this.props;
        const {
            isFileUploadDialogOpen, fileName, documentType, fileUploadInProgress, documentToUpdate, fileExtension,
            fileNameWithoutExtension, allFileDownloadDialogOpened, filesToUpload
        } = this.state;
        const {
            onDrop, closeUploadDialog, uploadFiles, handleFileNameChange, handleDocumentTypeChange, setFileToDelete,
            downloadCaseFile, deleteFile, setDocumentToUpdate, preventDots, handleFilesToUploadChange
        } = this;
        const sortedDocs = _.orderBy(documents, ['documentData.fileName'], ['desc']);
        const tneDocuments = sortedDocs.filter(document => document.documentData?.type === 'TNE');
        const notTneDocuments = sortedDocs.filter(document => document.documentData?.type !== 'TNE');
        const newDocuments = notTneDocuments.filter(document => document.documentData?.status === 'UNREAD')
                .filter(document => !assignedDocumentIds.includes(document.documentData?.documentId));
        const assignedDocuments = notTneDocuments
                .filter(document => assignedDocumentIds.includes(document.documentData?.documentId));
        const unassignedFiles = notTneDocuments
                .filter(document => !assignedDocumentIds.includes(document.documentData?.documentId))
                .filter(document => document.documentData?.status === 'READ')
        const isMobile = utils.checkMobileBrowser();

        return (
                <Flex item={'1 1 0'} direction={'column'} className={classes.container} style={{
                    padding: isMobile ? 5 : 15,
                    minWidth: 370,
                    width: 470,
                    backgroundColor: isMobile ? colors.htmlWhite : colors.mercuryGrey,
                    ...(!isMobile && !isPatient(user) && {
                        height: '100vh',
                        overflowY: 'auto'
                    }),
                }}>
                    <Flex item container grow={0} justifyContent={'space-between'}>
                        <DocumentDownload t={translate} documents={sortedDocs} caseBusinessId={caseBusinessId}
                                          user={user}/>
                    </Flex>
                    <FileUploader onDrop={onDrop}/>
                    <Dialog
                            onClose={() => false}
                            open={allFileDownloadDialogOpened}
                    >
                        <DialogTitle>
                            {translate('case.download-all-documents')} <CircularProgress/>
                        </DialogTitle>
                    </Dialog>

                    <Flex item container direction={'column'} style={{margin: '20px 0'}}>
                        {
                            !_.isEmpty(newDocuments) &&
                            newDocuments.map(document => (
                                    <DocumentTile
                                            {...{
                                                setFileToDelete,
                                                setDocumentToUpdate,
                                                user,
                                                document,
                                                translate,
                                                downloadCaseFile,
                                                assignmentStatus: 'UNASSIGNED',
                                                downloadAndReturnCaseFile
                                            }}
                                            key={document.documentData?.documentId}
                                    />
                            ))
                        }
                    </Flex>

                    <Flex item container direction={'column'} style={{margin: '20px 0'}}>
                        {
                            !_.isEmpty(tneDocuments) &&
                            tneDocuments.map(document => (
                                    <DocumentTile
                                            {...{
                                                downloadCaseFile,
                                                setFileToDelete,
                                                setDocumentToUpdate,
                                                user,
                                                document,
                                                translate,
                                                downloadAndReturnCaseFile
                                            }}
                                            key={document.documentData?.documentId}
                                    />
                            ))
                        }
                    </Flex>

                    {
                        !_.isEmpty(unassignedFiles) &&
                        unassignedFiles.map(document => (
                                <DocumentTile
                                        {...{
                                            setFileToDelete,
                                            setDocumentToUpdate,
                                            user,
                                            document,
                                            translate,
                                            downloadCaseFile,
                                            assignmentStatus: 'UNASSIGNED',
                                            downloadAndReturnCaseFile
                                        }}
                                        key={document.documentData?.documentId}
                                />
                        ))
                    }

                    {
                        !_.isEmpty(assignedDocuments) &&
                        assignedDocuments.map(document => (
                                <DocumentTile
                                        {...{
                                            setFileToDelete,
                                            setDocumentToUpdate,
                                            user,
                                            document,
                                            translate,
                                            downloadCaseFile,
                                            assignmentStatus: 'ASSIGNED',
                                            downloadAndReturnCaseFile
                                        }}
                                        key={document.documentData?.documentId}
                                />
                        ))
                    }

                    <UploadDialog {...{
                        open: isFileUploadDialogOpen,
                        closeUploadDialog,
                        uploadFiles,
                        documentTypes,
                        fileUploadInProgress,
                        filesToUpload,
                        handleFilesToUploadChange
                    }}/>

                    <ConfirmationDialog {...{
                        dialogOpen: !_.isEmpty(this.state.fileToDelete),
                        onDialogClose: () => setFileToDelete(''),
                        onConfirm: deleteFile,
                        confirmationTextKey: "case.delete-dialog"
                    }}/>

                    <UpdateDialog {...{
                        open: !_.isEmpty(documentToUpdate),
                        closeUpdateDialog: () => setDocumentToUpdate(''),
                        handleFileNameChange,
                        documentType,
                        documentTypes,
                        handleDocumentTypeChange,
                        documentToUpdate,
                        updateDocument,
                        fileNameWithoutExtension,
                        fileExtension,
                        fileName,
                        preventDots
                    }}/>
                </Flex>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(DocumentsSidebar)));
