import {makeStyles} from "@material-ui/core/styles";
import {htmlWhite, alabaster} from 'components/colors/Colors';
import Flex from 'components/grid/Flex';
import classNames from 'classnames';
import {IconButton, Tooltip} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import {Link} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    active: {
        background: `linear-gradient(90deg, ${htmlWhite} 10%, transparent 0%)`,
    },
    icon: {
        width: 25,
        height: 25,
        color: htmlWhite,
        fill: htmlWhite
    },
    hover: {
        '&:hover': {
            background: `linear-gradient(90deg, ${alabaster} 10%, transparent 0%)`,
        }
    },
}));

const SidebarListItem = ({route}) => {
    const classes = useStyles();
    const {t: translate} = useTranslation();
    return (
            <Flex item={'0 1 60px'} container justifyContent={'center'}
                  className={classNames(classes.hover, route.path === window.location.pathname ? classes.active : '')}>
                <Tooltip
                        placement="right-start"
                        disableFocusListener
                        title={translate(route.subtitle)}>
                    <IconButton component={Link}
                                style={{padding: 0}}
                                onClick={() => {
                                }}
                                to={route.path}>
                        <route.icon className={classes.icon}/>
                    </IconButton>
                </Tooltip>
            </Flex>
    );
};

export default SidebarListItem;
