import { makeStyles } from "@material-ui/core/styles";
import Flex from "components/grid/Flex";
import { IconButton, Tab, Tabs, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useParams, Outlet, Link, useLocation, Navigate } from "react-router-dom";
import Title from "components/title/Title";
import React from "react";
import Sidebar from "components/sidebar/Sidebar";
import {FILTERS, COHORTS, FILTER_NEW, FILTER_EDIT} from "routes/routes";
import * as colors from "../../components/colors/Colors";
import { ArrowBackOutlined as BackIcon } from "@material-ui/icons";
import {ThemeProvider} from "@material-ui/core/styles";
import {patientTheme} from "scenes/patient/PatientPage";

const useStyles = makeStyles((theme) => ({
    tab: {
        fontSize: 14,
        textTransform: "capitalize",
    },
    tabRoot: {
        borderBottom: "1px solid #E2E1E4",
        fontColor: "#E2E1E4",
        "& .MuiTabs-scroller": {},
        "& .MuiTabs-flexContainer": {
            maxHeight: "20px",
        },
        "& .MuiTabs-indicator": {
            backgroundColor: "#05343F",
            borderWidth: "1px",
        },
    },
}));

const CohortFiltersMainPage = ({ route }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { filterId } = useParams();
    const showTabs = [FILTER_NEW.path, FILTERS.path, COHORTS.path, FILTER_EDIT.pathWithId(filterId)].includes(pathname);
    return (
        <>
            <Sidebar />
            <Flex container item column style={{ margin: `0 10px 10px 65px` }}>
                <Flex item container>
                    <Flex item container grow={5} padding={15} column>
                        <Title>{t("cohort.title")}</Title>
                        {showTabs && (
                            <Tabs className={classes.tabRoot} value={pathname}>
                                <Tab
                                    className={classes.tab}
                                    label={t("cohort.new-filter")}
                                    value={FILTER_NEW.path}
                                    to={FILTER_NEW.path}
                                    component={Link}
                                />
                                <Tab
                                    className={classes.tab}
                                    label={t("cohort.filters")}
                                    value={FILTERS.path}
                                    to={FILTERS.path}
                                    component={Link}
                                />
                                <Tab
                                    className={classes.tab}
                                    label={t("cohort.cohorts")}
                                    value={COHORTS.path}
                                    to={COHORTS.path}
                                    component={Link}
                                />
                            </Tabs>
                        )}

                        <ThemeProvider theme={patientTheme}>
                            <Outlet />
                        </ThemeProvider>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
};

export default CohortFiltersMainPage;
