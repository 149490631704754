import React, { Component } from 'react';
import { withStyles, Typography } from "@material-ui/core";
import Dropzone from 'react-dropzone';
import DragAndDropImage from 'assets/img/d&d.png';
import * as colors from 'components/colors/Colors';
import {withTranslation} from 'react-i18next';

const styles = theme => ({
    dropDownArea: {
        height: 150,
        marginTop: 10,
        marginBottom: 10,
        backgroundColor: colors.porcelain,
        borderRadius: 5,
        display: 'flex',
        flex: '0 1 auto',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }
});

export class FileUploader extends Component {
    render() {
        const {classes, t: translate, onDrop} = this.props;

        return (
            <Dropzone onDrop={onDrop}>
                {({getRootProps, getInputProps, isDragActive}) => {
                    return (
                        <div {...getRootProps()} className={classes.dropDownArea}>
                            <input {...getInputProps()} />
                            <Typography style={{color: colors.htmlDarkGrey}}>{translate('case.d&d-top-text')}</Typography>
                            <img src={DragAndDropImage} alt={''} style={{width: 60, height: 60, margin: 10}}/>
                            <Typography
                                style={{color: colors.htmlDarkGrey}}>{translate('case.d&d-bottom-text')}</Typography>
                        </div>
                    )
                }}
            </Dropzone>
        );
    }
}

export default withStyles(styles)(withTranslation()(FileUploader));
