export const hexToRgba = (hex) => (alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const rgbToRgba = (rgb) => (alpha) => {
    const [r, g, b] = rgb
        .replace(/[^\d,]/g, "")
        .split(",")
        .map((value) => parseInt(value));
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
