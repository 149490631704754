import { FormControl, MenuItem, Select, InputLabel, Box } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";

const transformValueToValueWithLabel = (item) => {
    if (typeof item === "object") {
        return { value: item.value, label: item.label };
    }
    return { value: item, label: item };
};

const generateMenuItems = (values) => {
    return [""]
        .concat(values.filter((v) => v !== ""))
        .map(transformValueToValueWithLabel)
        .map((value, idx) => (
            <MenuItem key={idx} value={value.value}>
                <Trans i18nKey={value.value === "" ? "global.please-select" : value.label} />
            </MenuItem>
        ));
};

export const DropdownRenderer = ({ config, rule, idx, handleUpdateRule }) => {
    const { t } = useTranslation();
    return (
        <Box key={idx} display="flex" flexDirection="row" alignItems="center" style={{ gap: "10px" }}>
            <Box display="flex" flexDirection="column">
                <FormControl size="small">
                    {config.compact && config.label !== "" &&
                            <InputLabel style={{ position: 'absolute', top: '-15px'}} shrink>{t(config.label) || rule.properties.field}</InputLabel>
                    }
                    <Select
                        style={{ width: config.width ? config.width + "px" : undefined, marginTop: 0 }}
                        value={rule.properties.value}
                        displayEmpty
                        onChange={(e) => {
                            handleUpdateRule(idx, {
                                ...rule.properties,
                                value: e.target.value,
                            });
                        }}
                    >
                        {generateMenuItems(config.values)}
                    </Select>
                </FormControl>
            </Box>
        </Box>
    );
};
