import { geneValues, sampleLocations } from "../../patient/anamnesis/component/section/MutationModalForm";
import { ProblemSeverityEnum, DiscomfortLevelEnum } from "../../patient/anamnesis/enums";
import { MentalDiscomfortLevelEnum } from "../../patient/anamnesis/enums/MentalDiscomfortLevel.copy";
import { Category } from "./categories";
import { createLabValueConfig } from "./createNestedConfig";
import { WidgetType } from "./widgetTypes";

const dropDownValues = {
    treatmentStatus: [
        { value: "TREATED", label: "global.treated" },
        { value: "UNTREATED", label: "global.untreated" },
    ],
    problemSeverity: Object.values(ProblemSeverityEnum).map((value) => ({
        value,
        label: `anamnesis.quality.${value}`,
    })),
    mentalProblemsSeverity: Object.values(MentalDiscomfortLevelEnum).map((value) => ({
        value,
        label: `anamnesis.quality.${value}`,
    })),
    discomfortLevel: Object.values(DiscomfortLevelEnum).map((value) => ({
        value,
        label: `anamnesis.quality.${value}`,
    })),
    bloodCountChange: [
        "LEUKOCYTE_INCREASE",
        "LEUKOCYTE_REDUCTION",
        "ANAMIE",
        "INCREASE_IN_HEMATOCRIT",
        "INCREASE_IN_PLATELETS",
        "REDUCTION_IN_PLATELETS",
    ].map((value) => ({
        value,
        label: `anamnesis.hematology.${value}`,
    })),
    CHRSScoreType: ["low", "intermediate", "high"].map((value) => ({
        value,
        label: `my-risk.score-label.${value}`,
    })),
    chipSampleLocations: sampleLocations.map((value) => ({
        value,
        label: `anamnesis.chip.source.${value}`,
    }))

};

export const fields = {
    bio_sex: {
        widgetType: WidgetType.DROPDOWN,
        category: Category.GENERAL,
        widgetConfig: {
            label: "anamnesis.general.genderLabel",
            values: [
                { value: "MAN", label: "global.male-bio-sex" },
                {
                    value: "WOMAN",
                    label: "global.female-bio-sex",
                },
            ],
        },
    },
    "risk.hematology_score_type": {
        widgetType: WidgetType.DROPDOWN,
        category: Category.RISK_SCORE,
        widgetConfig: {
            label: "Clonal Hematopoiesis Risk Score (CHRS)",
            values: dropDownValues.CHRSScoreType,
        },
    },
    "risk.full_risk_percent": {
        widgetType: WidgetType.RANGE_FILTER,
        category: Category.RISK_SCORE,
        widgetConfig: {
            label: "my-risk.cardiovascular-risk-percent",
            width: 56,
        },
    },
    "anamnesis.GENERAL.height": {
        widgetType: WidgetType.RANGE_FILTER,
        category: Category.GENERAL,
        widgetConfig: {
            label: "anamnesis.general.heightLabel",
            width: 56,
        },
    },
    "anamnesis.GENERAL.weight": {
        widgetType: WidgetType.RANGE_FILTER,
        category: Category.GENERAL,
        widgetConfig: {
            label: "anamnesis.general.weightLabel",
            width: 56,
        },
    },
    "anamnesis.CARDIO_DISEASE.hasHealthyDiet": {
        widgetType: WidgetType.SWITCH,
        category: Category.CARDIO_RISK_FACTORS,
        widgetConfig: {
            label: "anamnesis.cardiovascular.hasHealthyDiet",
        },
    },
    "anamnesis.CARDIO_DISEASE.hasTimePressureAndStress": {
        widgetType: WidgetType.SWITCH,
        category: Category.CARDIO_RISK_FACTORS,
        widgetConfig: {
            label: "anamnesis.cardiovascular.hasTimePressureAndStress",
        },
    },
    "anamnesis.CCS": {
        widgetType: WidgetType.SWITCH_WITH_SELECT,
        category: Category.RISK_SCORE,
        defaultRuleProperties: {
            value: {
                type: "switchWithSelect",
                switchValue: {
                    field: "anamnesis.CCS.hasCCS",
                    value: true,
                },
                selectValue: {
                    field: "anamnesis.CCS.ccsValue",
                    value: "",
                },
            },
        },
        widgetConfig: {
            label: "anamnesis.clinical.CCSValueLabelInQuery",
            values: ["CCS2", "CCS3", "CCS4"],
        },
    },

    "anamnesis.RISK_FACTOR.chipMutationData.values_count": {
        widgetType: WidgetType.SIMPLE_NUMBER,
        category: Category.CHIP,
        widgetConfig: {
            width: 48,
            label: "anamnesis.chip.mutationCount",
            operators: ["eq", "gt", "lt", "gte", "lte"],
        },
    },
    "anamnesis.ARTHROSE.haveArthroseData": {
        widgetType: WidgetType.SWITCH_WITH_AUTOCOMPLETE,
        category: Category.ARTHROSE,
        defaultRuleProperties: {
            nestedPath: "anamnesis.ARTHROSE.haveArthroseData.values",
            value: {
                type: "switchWithNestedSelect",
                switchValue: {
                    field: "anamnesis.ARTHROSE.haveArthrose",
                    value: true,
                },
                selectValue: {
                    field: "anamnesis.ARTHROSE.haveArthroseData.values.value",
                    value: "",
                },
            },
        },
        widgetConfig: {
            autoLoadValues: true,
            autocompleteLabel: "anamnesis.arthrose.which-joint",
            width: 350,
            label: "anamnesis.arthrose.title",
            allowOrCondition: false,
        }
    },
    age: {
        widgetType: WidgetType.RANGE_FILTER,
        category: Category.GENERAL,
        defaultRuleProperties: {
            value: {
                type: "dateRange",
                from: 18,
                to: 40,
            },
        },
        widgetConfig: {
            width: 56,
            label: "global.age",
        },
    },
    "anamnesis.CARDIOVASCULAR.smokeStatus": {
        widgetType: WidgetType.SWITCH,
        category: Category.CARDIO_RISK_FACTORS,
        widgetConfig: {
            label: "anamnesis.cardiovascular.smokeStatus",
        },
    },
    "anamnesis.CARDIOVASCULAR.oldSmokeStatus": {
        widgetType: WidgetType.SWITCH,
        category: Category.CARDIO_RISK_FACTORS,
        widgetConfig: {
            label: "anamnesis.cardiovascular.oldSmokeStatus",
        },
    },
    "anamnesis.CARDIOVASCULAR.alcoholDrinkingStatus": {
        widgetType: WidgetType.SWITCH,
        category: Category.CARDIO_RISK_FACTORS,
        widgetConfig: {
            label: "anamnesis.cardiovascular.alcoholDrinkingStatus",
        },
    },

    "anamnesis.NYHA": {
        widgetType: WidgetType.SWITCH_WITH_SELECT,
        category: Category.RISK_SCORE,
        defaultRuleProperties: {
            value: {
                type: "switchWithSelect",
                switchValue: {
                    field: "anamnesis.NYHA.hasNYHA",
                    value: true,
                },
                selectValue: {
                    field: "anamnesis.NYHA.nyhaValue",
                    value: "",
                },
            },
        },
        widgetConfig: {
            label: "anamnesis.clinical.NYHAValueLabelInQuery",
            values: ["NYHA2", "NYHA3", "NYHA4"],
        },
    },
    "anamnesis.QUALITY_OF_LIFE.dailyActivityValue": {
        widgetType: WidgetType.DROPDOWN,
        category: Category.QUALITY_OF_LIFE,
        widgetConfig: {
            label: "anamnesis.quality.dailyActivityValueShort",
            values: dropDownValues.problemSeverity,
        },
    },
    "anamnesis.RISK_FACTOR.chipMutationData.values": {
        widgetType: WidgetType.NESTED,
        category: Category.CHIP,
        defaultRuleProperties: {
            exclude: false,
            value: {
                type: "nested",
                nestedValues: [
                    {
                        type: "rule",
                        properties: {
                            field: "anamnesis.RISK_FACTOR.chipMutationData.values.gene",
                            value: "",
                        },
                    },
                    {
                        type: "rule",
                        properties: {
                            field: "anamnesis.RISK_FACTOR.chipMutationData.values.exon",
                            value: "",
                        },
                    },
                    {
                        type: "rule",
                        properties: {
                            field: "anamnesis.RISK_FACTOR.chipMutationData.values.dna",
                            value: "",
                        },
                    },
                    {
                        type: "rule",
                        properties: {
                            field: "anamnesis.RISK_FACTOR.chipMutationData.values.protein",
                            value: "",
                        },
                    },
                    {
                        type: "rule",
                        properties: {
                            field: "anamnesis.RISK_FACTOR.chipMutationData.values.vaf",
                            operator: "eq",
                            value: "",
                        },
                    },
                    {
                        type: "rule",
                        properties: {
                            field: "anamnesis.RISK_FACTOR.chipMutationData.values.source",
                            value: "",
                        },
                    },
                ],
            },
        },
        widgetConfig: {
            label: "anamnesis.chip.mutationData",
            allowOrCondition: true,
            canExclude: true,
        },
    },
    "anamnesis.RISK_FACTOR.chipMutationData.values.gene": {
        widgetType: WidgetType.DROPDOWN,
        widgetConfig: {
            width: 150,
            label: "anamnesis.chip.table.gene",
            values: geneValues,
        },
    },
    "anamnesis.RISK_FACTOR.chipMutationData.values.vaf": {
        widgetType: WidgetType.SIMPLE_NUMBER,
        widgetConfig: {
            width: 80,
            label: "anamnesis.chip.table.vaf",
            operators: ["eq", "gt", "lt", "gte", "lte"],
        },
    },
    "anamnesis.RISK_FACTOR.chipMutationData.values.exon": {
        widgetType: WidgetType.SIMPLE_NUMBER,
        widgetConfig: {
            label: "Exon",
            width: 50,
            operators: [],
        },
    },
    "anamnesis.RISK_FACTOR.chipMutationData.values.dna": {
        widgetType: WidgetType.AUTOCOMPLETE,
        widgetConfig: {
            label: "anamnesis.chip.table.dna",
            width: 150,
            autoLoadValues: true,
        },
    },
    "anamnesis.RISK_FACTOR.chipMutationData.values.protein": {
        widgetType: WidgetType.AUTOCOMPLETE,
        widgetConfig: {
            label: "anamnesis.chip.table.protein",
            width: 150,
            autoLoadValues: true,
        },
    },
    "anamnesis.RISK_FACTOR.chipMutationData.values.source": {
        widgetType: WidgetType.DROPDOWN,
        widgetConfig: {
            label: "Source",
            width: 180,
            values: dropDownValues.chipSampleLocations,
        },
    },
    "anamnesis.COMBINED.haveAllergies": {
        widgetType: WidgetType.SWITCH,
        category: Category.OTHER,
        widgetConfig: {
            label: "anamnesis.allergies.title",
        },
    },
    "anamnesis.CARDIOVASCULAR.hasCardiovascularDisease": {
        widgetType: WidgetType.SWITCH,
        category: Category.CARDIO_RISK_FACTORS,
        widgetConfig: {
            label: "anamnesis.cardiovascular.hasCardiovascularDisease",
        },
    },
    "anamnesis.CARDIOVASCULAR.hasSufferingFromDiabetes": {
        widgetType: WidgetType.SWITCH_WITH_SELECT,
        category: Category.CARDIO_RISK_FACTORS,
        defaultRuleProperties: {
            value: {
                type: "switchWithSelect",
                switchValue: {
                    field: "anamnesis.CARDIOVASCULAR.hasSufferingFromDiabetes",
                    value: true,
                },
                selectValue: {
                    field: "anamnesis.CARDIOVASCULAR.diabetesState",
                    value: "",
                },
            },
        },
        widgetConfig: {
            label: "Diabetes mellitus",
            values: dropDownValues.treatmentStatus,
        },
    },
    "anamnesis.COMBINED.haveAsthma": {
        widgetType: WidgetType.SWITCH,
        category: Category.COMORBIDITIES,
        widgetConfig: {
            label: "anamnesis.comorbidity.asthma",
        },
    },
    "anamnesis.HEMATOLOGY.haveHematoFamilialSyndromOrGenetic": {
        widgetType: WidgetType.SWITCH,
        category: Category.HEMATOLOGY,
        widgetConfig: {
            label: "anamnesis.hematology.haveHematoFamilialSyndromOrGenetic",
        },
    },
    "anamnesis.HEMATOLOGY.haveActiveHematoDisease": {
        widgetType: WidgetType.SWITCH_WITH_AUTOCOMPLETE,
        category: Category.HEMATOLOGY,
        defaultRuleProperties: {
            nestedPath: "anamnesis.HEMATOLOGY.haveActiveHematoDiseaseData.values",
            value: {
                type: "switchWithNestedSelect",
                switchValue: {
                    field: "anamnesis.HEMATOLOGY.haveActiveHematoDisease",
                    value: true,
                },
                selectValue: {
                    field: "anamnesis.HEMATOLOGY.haveActiveHematoDiseaseData.values.value",
                    value: "",
                },
            },
        },
        widgetConfig: {
            autoLoadValues: true,
            autocompleteLabel: "anamnesis.hematology.what-kind",
            width: 350,
            allowOrCondition: true,
            label: "anamnesis.hematology.haveActiveHematoDisease",
        },
    },
    "anamnesis.HEMATOLOGY.haveFormerHematoDisease": {
        widgetType: WidgetType.SWITCH_WITH_AUTOCOMPLETE,
        category: Category.HEMATOLOGY,
        defaultRuleProperties: {
            nestedPath: "anamnesis.HEMATOLOGY.haveFormerHematoDiseaseData.values",
            value: {
                type: "switchWithNestedSelect",
                switchValue: {
                    field: "anamnesis.HEMATOLOGY.haveFormerHematoDisease",
                    value: true,
                },
                selectValue: {
                    field: "anamnesis.HEMATOLOGY.haveFormerHematoDiseaseData.values.value",
                    value: "",
                },
            },
        },
        widgetConfig: {
            autoLoadValues: true,
            autocompleteLabel: "anamnesis.hematology.what-kind",
            width: 350,
            label: "anamnesis.hematology.haveFormerHematoDisease",
        },
    },
    "anamnesis.HEMATOLOGY.havePreviousChangeInBloodCount": {
        widgetType: WidgetType.SWITCH_WITH_SELECT,
        category: Category.HEMATOLOGY,
        defaultRuleProperties: {
            nestedPath: "anamnesis.HEMATOLOGY.havePreviousChangeInBloodCountData.values",
            value: {
                type: "switchWithNestedSelect",
                switchValue: {
                    field: "anamnesis.HEMATOLOGY.havePreviousChangeInBloodCount",
                    value: true,
                },
                selectValue: {
                    field: "anamnesis.HEMATOLOGY.havePreviousChangeInBloodCountData.values.value",
                    value: "",
                },
            },
        },
        widgetConfig: {
            label: "anamnesis.hematology.havePreviousChangeInBloodCount",
            values: dropDownValues.bloodCountChange,
        },
    },
    "anamnesis.HEMATOLOGY.haveCurrentChangeInBloodCount": {
        widgetType: WidgetType.SWITCH_WITH_SELECT,
        category: Category.HEMATOLOGY,
        defaultRuleProperties: {
            nestedPath: "anamnesis.HEMATOLOGY.haveCurrentChangeInBloodCountData.values",
            value: {
                type: "switchWithNestedSelect",
                switchValue: {
                    field: "anamnesis.HEMATOLOGY.haveCurrentChangeInBloodCount",
                    value: true,
                },
                selectValue: {
                    field: "anamnesis.HEMATOLOGY.haveCurrentChangeInBloodCountData.values.value",
                    value: "",
                },
            },
        },
        widgetConfig: {
            label: "anamnesis.hematology.haveCurrentChangeInBloodCount",
            values: dropDownValues.bloodCountChange,
        },
    },

    "anamnesis.HEMATOLOGY.haveAbdomenUltrasoundData": {
        widgetType: WidgetType.SWITCH_WITH_AUTOCOMPLETE,
        category: Category.HEMATOLOGY,
        defaultRuleProperties: {
            nestedPath: "anamnesis.HEMATOLOGY.haveAbdomenUltrasoundData.values",
            value: {
                type: "switchWithNestedSelect",
                switchValue: {
                    field: "anamnesis.HEMATOLOGY.haveAbdomenUltrasound",
                    value: true,
                },
                selectValue: {
                    field: "anamnesis.HEMATOLOGY.haveAbdomenUltrasoundData.values.value",
                    value: "",
                },
            },
        },
        widgetConfig: {
            autoLoadValues: true,
            autocompleteLabel: "anamnesis.hematology.spleen-size-reported",
            width: 350,
            label: "anamnesis.hematology.spleenExamination",
            allowOrCondition: false,
        }
    },
    "anamnesis.CARDIOVASCULAR.hasSufferingFromHypercholesterolemia": {
        widgetType: WidgetType.SWITCH_WITH_SELECT,
        category: Category.CARDIO_RISK_FACTORS,
        defaultRuleProperties: {
            value: {
                type: "switchWithSelect",
                switchValue: {
                    field: "anamnesis.CARDIOVASCULAR.hasSufferingFromHypercholesterolemia",
                    value: true,
                },
                selectValue: {
                    field: "anamnesis.CARDIOVASCULAR.hypercholesterolemiaState",
                    value: "",
                },
            },
        },
        widgetConfig: {
            label: "anamnesis.cardiovascular.hypercholesterolemia",
            values: dropDownValues.treatmentStatus,
        },
    },
    "anamnesis.QUALITY_OF_LIFE.anxiousValue": {
        widgetType: WidgetType.DROPDOWN,
        category: Category.QUALITY_OF_LIFE,
        widgetConfig: {
            label: "anamnesis.quality.anxiousOrDepressive",
            values: dropDownValues.mentalProblemsSeverity,
        },
    },
    "anamnesis.QUALITY_OF_LIFE.discomfortValue": {
        widgetType: WidgetType.DROPDOWN,
        category: Category.QUALITY_OF_LIFE,
        widgetConfig: {
            label: "anamnesis.quality.painOrDiscomfort",
            values: dropDownValues.discomfortLevel,
        },
    },
    "anamnesis.QUALITY_OF_LIFE.washAndDressValue": {
        widgetType: WidgetType.DROPDOWN,
        category: Category.QUALITY_OF_LIFE,
        widgetConfig: {
            label: "anamnesis.quality.selfCareAbility",
            values: dropDownValues.problemSeverity,
        },
    },
    "anamnesis.CARDIOVASCULAR.sportStatus": {
        widgetType: WidgetType.SWITCH,
        category: Category.CARDIO_RISK_FACTORS,
        widgetConfig: {
            label: "anamnesis.cardiovascular.sportStatus",
        },
    },
    medications: {
        widgetType: WidgetType.NESTED,
        category: Category.MEDICATIONS,
        widgetConfig: {
            label: "medication.ingredient",
            showLabel: false,
            allowOrCondition: true,
            allowAndCondition: true,
            andConditionLabel: "cohort.add-to-group.medication",
            canExclude: true,
        },
        defaultRuleProperties: {
            exclude: false,
            value: {
                type: "nested",
                nestedValues: [
                    {
                        type: "rule",
                        properties: {
                            field: "medications.ingredient",
                            value: "",
                        },
                    },
                    {
                        type: "rule",
                        properties: {
                            field: "medCriteria",
                            virtualField: true, // maybe this should not be a part of stored rule, but for now it is
                            value: "",
                        },
                    },
                ],
            },
        },
    },
    "medications.ingredient": {
        widgetType: WidgetType.AUTOCOMPLETE,
        widgetConfig: {
            label: "medication.table.ingredient",
            width: 240,
            autoLoadValues: true,
        },
    },
    medCriteria: {
        widgetType: WidgetType.DROPDOWN,
        widgetConfig: {
            label: "cohort.criteria",
            width: 208,
            values: [
                { value: "ACTIVE", label: "cohort.actual-subscription" },
                { value: "NON_ACTIVE", label: "cohort.past-subscription" },
                { value: "NEVER_SUBSCRIBED", label: "cohort.never-subscribed" },
            ],
        },
    },
    ...[
        {
            key: "hemoglobin",
            units: [
                { value: "gperdl", label: "g/dl" },
                { value: "mmolperl", label: "mmol/l" },
            ],
            defaultUnit: "gperdl",
            fieldInStandardUnit: "hemoglobin_in_mmolperl",
        },
        {
            key: "kreatinin",
            units: [
                { value: "mgperdl", label: "mg/dl" },
                { value: "mikromolperll", label: "μmol/l" },
                { value: "nmolperlml", label: "nmol/ml" },
            ],
            defaultUnit: "mgperdl",
            fieldInStandardUnit: "kreatinin_in_mikromolperl",
        },
        { key: "leukozyten", units: [{ value: "gperl", label: "G/l" }], defaultUnit: "gperl" },
        {
            key: "totalcholesterol",
            units: [
                { value: "mgperdl", label: "mg/dl" },
                { value: "mmolperldl", label: "mmol/dl" },
            ],
            defaultUnit: "mgperdl",
            fieldInStandardUnit: "totalcholesterol_in_mmolperl",
        },
        {
            key: "ldlcholesterol",
            units: [
                { value: "mgperdl", label: "mg/dl" },
                { value: "mmolperldl", label: "mmol/dl" },
            ],
            defaultUnit: "mgperdl",
            fieldInStandardUnit: "ldlcholesterol_in_mmolperl",
        },
        {
            key: "hdlcholesterol",
            units: [
                { value: "mgperdl", label: "mg/dl" },
                { value: "mmolperldl", label: "mmol/dl" },
            ],
            defaultUnit: "mgperdl",
            fieldInStandardUnit: "hdlcholesterol_in_mmolperl",
        },
        {
            key: "crp",
            units: [
                {
                    value: "mgperdl",
                    label: "mg/dl",
                },
                {
                    value: "mgperl",
                    label: "mg/l",
                },
                {
                    value: "nmolperll",
                    label: "nmol/l",
                },
                {
                    value: "nmolperml",
                    label: "nmol/ml",
                },
            ],
            defaultUnit: "mgperl",
            fieldInStandardUnit: "crp_in_mgperl",
        },
        { key: "mcv", units: [{ value: "fperl", label: "fl" }], defaultUnit: "fperl" },
        { key: "rdw", units: [{ value: "percent", label: "%" }], defaultUnit: "percent" },
        { key: "thrombozyten", units: [{ value: "gperl", label: "G/l" }], defaultUnit: "gperl" },
        { key: "gfr", units: [{ value: "mlpermin", label: "ml/min" }], defaultUnit: "mlpermin" },
    ].reduce((acc, conf) => {
        return {
            ...acc,
            ...createLabValueConfig(conf),
        };
    }, {}),
};

