import React, {Component} from 'react';
import Flex from 'components/grid/Flex';

export class Rating extends Component {

    state = {
        selectedRating: 0
    }

    componentDidMount() {
        this.setState({
            selectedRating: this.props.rating
        })
    }

    onRatingChange = selectedRating => event => {
        if (!this.props.disabled) {
            this.setState({
                selectedRating
            }, () => {
                    this.props.onChange({
                        value: selectedRating,
                        name: this.props.name,
                        target: {value: selectedRating, name: this.props.name}}
                    );
            })
        }
    }

    render() {
        const {disabled, name} = this.props;
        const {selectedRating} = this.state;
        const {onRatingChange} = this;

        return (
            <Flex container spaceBetween style={{width: 75, height: 20}}>
                {
                    [1, 2, 3, 4, 5].map(rating => (
                        <div key={rating+name} style={{
                            backgroundColor: rating <= selectedRating ? "#ED7D31" : "#CCCCCC",
                            cursor: disabled ? 'unset': 'pointer',
                            borderRadius: "50%",
                            height: 12,
                            width: 12
                        }} onClick={onRatingChange(rating)}>

                        </div>
                    ))
                }
            </Flex>
        );
    }
}

export default Rating;
