export const transformRuleToOrGroup = (rule) => {
    return {
        type: "group",
        properties: {
            disabled: rule.properties.disabled,
            exclude: rule.properties.exclude,
            field: rule.properties.field,
            value: [
                {
                    ...rule,
                    properties: {
                        ...rule.properties,
                        exclude: false,
                    },
                },
            ],
        },
    };
};

// same as in addRuleToAndGroup
// should use one function to add rule to group
export const addRuleToOrGroup = (group, rule) => {
    return {
        ...group,
        properties: {
            ...group.properties,
            value: [...group.properties.value, rule],
        },
    };
}

