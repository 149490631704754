import {Button, Typography, Box, CircularProgress} from "@material-ui/core";
import Flex from "components/grid/Flex";
import Program from "./Program";
import _ from "lodash";
import NewProgramDialog from "./NewProgramDialog";
import {isManager} from "domain/User.model";
import {compareBySuspendedParticipation} from "./functions";
import {compareByNextStepLowestDate} from "./functions/comparators/nextStepDateComparator";
import PatientAgreements from "./PatientAgreements";
import {useState} from "react";
import {useSelector, shallowEqual} from "react-redux";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useFetchPatientProgramsQuery} from 'scenes/patient/ProgramApi';
import GreySwitch from 'components/switch/GreySwitch';
import {
    useFetchPatientByIdQuery,
} from "scenes/patient/PatientApi";

const PatientPrograms = () => {
    const {t: translate} = useTranslation();
    const {patientId} = useParams();
    const user = useSelector((state) => state.mainReducer.user, shallowEqual);
    const {data: programs = [], isLoading} = useFetchPatientProgramsQuery(patientId);
    const {data: patientData = {}, isSuccess} = useFetchPatientByIdQuery(patientId);

    const anonymized = patientData.anonymized;
    const readonlyMode = anonymized;

    const [isNewProgramDialogOpen, setIsNewProgramDialogOpen] = useState(false);
    const [showOnlyActiveProgramsToggle, setShowOnlyActivePrograms] = useState(true);
    const toggleNewProgramDialog = () => {
        setIsNewProgramDialogOpen(!isNewProgramDialogOpen)
    };

    const toggleShowOnlyActivePrograms = () => setShowOnlyActivePrograms(!showOnlyActiveProgramsToggle);

    const careProgramFilter = (program) => {
        return isManager(user)
                ? (showOnlyActiveProgramsToggle && !anonymized) ? !program.careProgramData.suspendedParticipation : true
                : !program.careProgramData.suspendedParticipation;
    };

    return <Flex data-testid="programs-view-page" item container>
        <Flex id={"programs"} item container style={{width: "75%", padding: 10}} column>
            <Box id="welcome-name" data-testid="patient-programs-welcome-title" justifyContent="space-between" alignItems="center" p="10px 0px 10px 10px"
                 display="flex">
                <Typography component="h1" variant="h3" color="primary">
                    {translate("global.program-title")}
                </Typography>
                {
                    (isManager(user) && !readonlyMode) &&
                    <Button
                            size={"small"}
                            color="primary"
                            style={{...(!programs.length && {backgroundColor: "orange"})}}
                            variant="contained"
                            onClick={toggleNewProgramDialog}
                    >
                        + {translate("global.add-new-program")}
                    </Button>
                }
            </Box>
            {
                (isManager(user) && !readonlyMode)  &&
                <Box marginLeft={"10px"}>
                    <PatientAgreements patientId={patientId}/>
                    <GreySwitch {...{
                        isLeftSelected: showOnlyActiveProgramsToggle,
                        labelLeft: translate("global.active-programs"),
                        labelRight: translate("global.all-programs"),
                        toggle: toggleShowOnlyActivePrograms,
                        style: {float: 'right'}
                    }}/>
                </Box>
            }
            <Flex id={"programs"} role="list" item container column>
                {([...programs].sort(compareByNextStepLowestDate)
                        .sort(compareBySuspendedParticipation) || [])
                        .filter(careProgramFilter)
                        .map((program, index) => (
                                <Program
                                        key={program?.careProgramData?.id + " - " + index}
                                        program={program}
                                        canBeOpened={!readonlyMode}
                                />
                        ))}
                {isLoading && _.isEmpty(programs) && (
                        <Flex item container center padding={20}>
                            <CircularProgress/>
                        </Flex>
                )}
                {!isLoading && _.isEmpty(programs) && (
                        <Flex item container center>
                            <Typography style={{fontSize: 20, fontWeight: "bold", opacity: 0.5}}>
                                {translate("global.program-missing")}
                            </Typography>
                        </Flex>
                )}
            </Flex>
        </Flex>
        {
            isNewProgramDialogOpen &&
            <NewProgramDialog {...{patientId, cancel: toggleNewProgramDialog}}/>
        }
    </Flex>;
};

export default PatientPrograms;
