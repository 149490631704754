import React, { Component, Fragment } from "react";
import { withStyles, Typography, Tabs, Tab, IconButton, CircularProgress } from "@material-ui/core";
import EpiAlert from "components/alert/EpiAlert";
import Flex from "components/grid/Flex";
import { withRouter } from "utils/Route.utils";
import ClinicPicker from "scenes/case/edit/component/clinic/ClinicPicker";
import { iceberg, htmlBlack } from "components/colors/Colors";
import utils from "utils/Utils";
import { CASES, CASE_EDIT } from "routes/routes";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import connector from "./CaseEdit.connect";
import _ from "lodash";
import PatientSidebar from "./component/patient/PatientSidebar";
import moment from "moment";
import TodoSection from "./component/TodoSection";
import Milestones from "./component/Milestones";
import CommunicationsLog from "components/log/CommunicationsLog";
import Question from "components/question/Question";
import SecondOpinion from "./component/second-opinion/SecondOpinion";
import DocumentsSidebar from "./component/document/DocumentsSidebar";
import MobileUserFileUpload from "./component/mobile-user/MobileUserFileUpload";
import UsedDocuments from "./component/second-opinion/UsedDocuments";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import { isCaseManager, isClinic, isPatient } from "domain/User.model";
import Sidebar from "components/sidebar/Sidebar";
import { REGISTER_PATIENT, NEW, REQUEST_EXPERT_TO_TAKE_OVER_CASE } from "domain/WorkflowStatus";
import CaseEditTitleBar from "./component/CaseEditTitleBar";
import { mapCaseInputForBackend } from "domain/Case.model";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

const CASE = "CASE";
const DOCUMENTATION = "DOCUMENTATION";
const ALL_DOCUMENT = "ALL_DOCUMENT";

const styles = (theme) => ({
    tabIndicator: {
        backgroundColor: htmlBlack,
    },
    tabSelected: {
        fontWeight: "bold",
    },
    tabRoot: {
        textTransform: "capitalize",
        fontSize: 15,
    },
    avatar: {
        margin: 0,
    },
    entryContainer: {
        borderRadius: 6,
        margin: 10,
        marginTop: 20,
        padding: 15,
    },
});

const Tabulators = (props) => {
    const { classes, translate, user, changeActivityTab, activityTab, toggleCloneDialog, isNewCase, isLoading } = props;
    return (
        <Flex item grow={0} container>
            <Tabs value={activityTab} onChange={changeActivityTab} classes={{ indicator: classes.tabIndicator }}>
                {isCaseManager(user) && (
                    <Tab
                        value={CASE}
                        classes={{
                            selected: classes.tabSelected,
                            root: classes.tabRoot,
                        }}
                        label={
                            <Flex container style={{ color: "inherit", fontSize: "inherit" }} alignItems={"center"}>
                                <Typography
                                    style={{
                                        color: "inherit",
                                        fontSize: "inherit",
                                        fontWeight: "bold",
                                        marginRight: 5,
                                    }}
                                >
                                    {translate(isCaseManager(user) ? "global.case" : "global.second-opinion")}
                                </Typography>
                                {isCaseManager(user) && !isNewCase && !isLoading && (
                                    <IconButton
                                        style={{ padding: 5 }}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            event.preventDefault();
                                            toggleCloneDialog();
                                        }}
                                    >
                                        <FileCopyOutlinedIcon style={{ fontSize: 18 }} />
                                    </IconButton>
                                )}
                                {isLoading && <CircularProgress style={{ width: 20, height: 20 }} />}
                            </Flex>
                        }
                    />
                )}

                <Tab
                    value={DOCUMENTATION}
                    classes={{
                        selected: classes.tabSelected,
                        root: classes.tabRoot,
                    }}
                    label={translate(isPatient(user) ? "global.second-opinion" : "global.documentation")}
                />
                <Tab
                    value={ALL_DOCUMENT}
                    classes={{
                        selected: classes.tabSelected,
                        root: classes.tabRoot,
                    }}
                    label={translate("global.all-documents")}
                />
            </Tabs>
        </Flex>
    );
};

const PatientUploadMessage = (props) => {
    const { translate } = props;
    return (
        <Flex item container direction={"column"} style={{ padding: "15% 20%" }}>
            <Typography variant={"h6"} style={{ margin: 10 }}>
                {translate("case.second-opinion-user-message-1")}
            </Typography>
            <Typography variant={"h6"} style={{ margin: 10 }}>
                {translate("case.second-opinion-user-message-2")}
            </Typography>
            <Typography variant={"h6"} style={{ margin: 10 }}>
                {translate("case.second-opinion-user-message-3")}
            </Typography>
            <Typography variant={"h6"} style={{ margin: 10 }}>
                {translate("case.second-opinion-user-message-4")}
            </Typography>
            <Typography variant={"h6"} style={{ margin: 10 }}>
                {translate("case.second-opinion-user-message-5")}
            </Typography>
        </Flex>
    );
};

export class CaseEdit extends Component {
    state = {
        activityTab: CASE,
        alert: "",
        goBack: false,
        newStatus: "",
        isCloneDialogOpen: false,
        isLoading: false,
    };

    componentDidMount() {
        if (
            !_.isEmpty(this.props.routeParams.caseBusinessId) &&
            !_.get(this.props, "location.state.isNewCase", false)
        ) {
            this.props
                .fetchCase(this.props.routeParams.caseBusinessId)
                .then(() => {
                    this.getAlreadyInvitedEmails();
                    if (_.get(this.props, "case.status") === NEW && isCaseManager(this.props.user)) {
                        this.handleStatusChange(REGISTER_PATIENT);
                    }
                })
                .catch(() => {
                    this.props.navigate(CASES.path);
                });
        } else if (_.get(this.props, "location.state.isNewCase", false)) {
            window.history.replaceState({}, document.title);
        }
        if (!isPatient(this.props.user)) {
            this.setState({
                activityTab: isCaseManager(this.props.user) ? CASE : DOCUMENTATION,
            });
        }
    }

    getAlreadyInvitedEmails = () => {
        return this.props.getAlreadyInvitedEmails({
            entityType: "CASE",
            entityId: this.props?.case?.id,
            patientId: this.props?.case?.patient?.id,
        });
    };

    componentWillUnmount() {
        if (!this.state.goBack) {
            this.delayedSave.cancel();
            if (isCaseManager(this.props.user) && this.props.case.businessId) {
                this.saveCase();
                this.props.reset();
            }
        }
    }

    saveCase = () => {
        return this.props.saveCase(mapCaseInputForBackend(this.props.case, this.props.caseOnBackend));
    };

    alertCancelAndSave = (savePromise) => {
        this.delayedSave.cancel();
        savePromise
            .then(() => {
                this.setState({ alert: "saved" });
            })
            .catch(() => {
                this.setState({ alert: "error" });
            });
    };

    save = (goBack) => {
        if (!goBack && isCaseManager(this.props.user)) {
            this.alertCancelAndSave(this.saveCase());
        } else if (!goBack && isClinic(this.props.user)) {
            this.alertCancelAndSave(this.props.saveCaseSecondOpinion(mapCaseInputForBackend(this.props.case)));
        } else if (goBack && isCaseManager(this.props.user)) {
            this.setState({ goBack });
            this.delayedSave.cancel();
            this.saveCase();
            this.props.reset();
            this.props.navigate(CASES.path);
        } else {
            this.props.navigate(CASES.path);
        }
    };

    changeActivityTab = (event, value) => {
        this.setState({ activityTab: value });
    };

    handleCaseChange = (event) => {
        this.updateCase({
            [event.target.name]: event.target.value,
        });
    };

    handleCaseManagerChange = (event, caseManager) => {
        this.updateCase({
            caseManagerId: caseManager.businessId,
        });
    };

    handleStatusChange = (newStatus) => {
        if (this.props.case?.clinicCenterBusinessId && [REQUEST_EXPERT_TO_TAKE_OVER_CASE].includes(newStatus)) {
            this.setState({ newStatus });
        } else {
            this.updateCase({ status: newStatus });
        }
    };

    removeWorkflowStatusChange = () => {
        this.setState({ newStatus: "" });
    };

    confirmWorkflowStatusChange = () => {
        this.updateCase({
            status: this.state.newStatus,
            clinicCenterBusinessId: "",
        });
        this.removeWorkflowStatusChange();
    };

    updateCase = (update) => {
        this.props.updateCase(update);
        this.delayedSave();
    };

    updatePatient = (update) => {
        if (isCaseManager(this.props.user)) {
            this.props.updatePatient(update);
            this.delayedSave();
        }
    };

    delayedSave = _.debounce(this.save, 5000);

    handleMilestoneChange = (milestone) => (event) => {
        const newMilestone = {
            id: milestone.id,
            date: milestone.date ? null : moment().format(utils.API_DATE_FORMAT),
        };
        const milestones = this.props.case.milestones.map((milestoneInArray) =>
            milestoneInArray.id === newMilestone.id ? newMilestone : milestoneInArray,
        );
        this.updateCase({ milestones });
    };

    closeSnackbar = () => {
        this.setState({
            alert: "",
        });
    };

    confirmSecondOpinion = (businessId) => {
        this.delayedSave.cancel();
        return this.saveCase().then(() => {
            return this.props.confirmSecondOpinion(businessId);
        });
    };

    addCaseLog = (comment) => {
        this.props.addCaseLog(comment, this.props.case.businessId);
    };

    toggleCloneDialog = () => {
        this.setState({ isCloneDialogOpen: !this.state.isCloneDialogOpen });
    };

    cloneCase = () => {
        this.setState({ isLoading: true, isCloneDialogOpen: false }, () => {
            this.props.cloneCase(this.props.routeParams.caseBusinessId).then((response) => {
                this.props.navigate(CASE_EDIT.pathWithId(response?.payload?.data?.businessId), {
                    state: { previousPath: CASES.path },
                });
                window.location.reload();
            });
        });
    };

    cancelCase = (dataDeletionType) => {
        this.delayedSave.cancel();
        return this.saveCase().then(() => {
            this.props
                .cancelCase(dataDeletionType, mapCaseInputForBackend(this.props.case))
                .then(() => {})
                .catch(() => {})
                .then(() => {
                    this.props.reset();
                    this.props.navigate("/", { replace: true });
                });
        });
    };

    render() {
        const {
            classes,
            t: translate,
            user,
            configuration,
            fetchConfiguration,
            addNewPatientQuestionLog,
            checkAndAssignUser,
            lastCaseForUser,
            downloadCaseFile,
            createUserDetails,
            fetchCase,
            rejectAccessToEntityForInvitedEmail
        } = this.props;
        const {
            save,
            changeActivityTab,
            handleCaseChange,
            updateCase,
            handleCaseManagerChange,
            handleMilestoneChange,
            updatePatient,
            confirmSecondOpinion,
            removeWorkflowStatusChange,
            confirmWorkflowStatusChange,
            handleStatusChange,
            addCaseLog,
            getAlreadyInvitedEmails,
            toggleCloneDialog,
            cloneCase,
            cancelCase,
        } = this;
        const { newStatus, isCloneDialogOpen, isLoading } = this.state;
        const isEditDisabled = !isCaseManager(user);
        const _case = this.props.case;
        return !this.props.case.businessId ? (
            <Fragment />
        ) : isPatient(user) ? (
            <MobileUserFileUpload indication={_case.patient.indication} />
        ) : (
            <>
                {isCaseManager(user) && <Sidebar />}
                <Flex container item style={{ marginLeft: isCaseManager(user) ? 60 : 0 }}>
                    <Flex item={`4 1 ${isClinic(user) ? "auto" : "66%"}`} container column>
                        <CaseEditTitleBar
                            {...{
                                fetchCase,
                                save,
                                handleCaseChange,
                                handleCaseManagerChange,
                                cancelCase,
                            }}
                        />
                        <Tabulators
                            {...{
                                classes,
                                translate,
                                user,
                                changeActivityTab,
                                toggleCloneDialog,
                                isNewCase: _.get(this.props, "location.state.isNewCase", false),
                                activityTab: this.state.activityTab,
                                isLoading,
                            }}
                        />
                        <Flex id={"main-content"} item container direction={"column"} padding={7}>
                            {this.state.activityTab === CASE && isCaseManager(user) && (
                                <Flex item container grow={0}>
                                    <Flex item grow={0} basis={"60%"} container direction={"column"}>
                                        <TodoSection
                                            {...{
                                                translate,
                                                case: this.props.case,
                                                statuses: configuration.workflowStatuses,
                                                isEditDisabled,
                                                updateCase,
                                                handleStatusChange,
                                            }}
                                        />
                                        <Flex
                                            item
                                            grow={0}
                                            container
                                            className={classes.entryContainer}
                                            column
                                            style={{
                                                backgroundColor: iceberg,
                                                minHeight: 200,
                                                height: "fit-content",
                                            }}
                                        >
                                            <Question
                                                {...{
                                                    isEditDisabled,
                                                    patientQuestionLogs: _case.patientQuestionLogs,
                                                    addNewPatientQuestionLog,
                                                    caseBusinessId: _case.businessId,
                                                }}
                                            />
                                            <ClinicPicker disabled={isEditDisabled} />
                                        </Flex>
                                    </Flex>
                                    <Flex item grow={0} basis={"40%"} container direction={"column"}>
                                        <Milestones
                                            {...{
                                                isEditDisabled,
                                                milestones: this.props.case.milestones,
                                                handleMilestoneChange,
                                                handleCaseChange,
                                                satisfaction: _case.satisfaction,
                                                costCoverageTypes: _case.costCoverageTypes,
                                                cdType: _case.cdType,
                                            }}
                                        />
                                        <CommunicationsLog
                                            {...{
                                                title: translate("global.communications-log"),
                                                logs: _case.caseLogs,
                                                isEditDisabled,
                                                user,
                                                addLog: addCaseLog,
                                            }}
                                        />
                                    </Flex>
                                </Flex>
                            )}
                            {this.state.activityTab === DOCUMENTATION && isPatient(user) && (
                                <PatientUploadMessage {...{ translate }} />
                            )}
                            {this.state.activityTab === DOCUMENTATION && !isPatient(user) && (
                                <Flex item container grow={0}>
                                    <SecondOpinion
                                        {...{
                                            user,
                                            case: this.props.case,
                                            configuration,
                                            handleCaseChange,
                                            addNewPatientQuestionLog,
                                            updateCase,
                                            save,
                                            downloadCaseFile,
                                            confirmSecondOpinion,
                                            milestones: this.props.case.milestones,
                                        }}
                                    />
                                </Flex>
                            )}
                            {this.state.activityTab === ALL_DOCUMENT && !isPatient(user) && (
                                <Flex item container grow={0}>
                                    <UsedDocuments
                                        {...{
                                            case: this.props.case,
                                            downloadCaseFile,
                                        }}
                                    />
                                </Flex>
                            )}
                        </Flex>
                    </Flex>
                    {!isClinic(user) && this.state.activityTab !== ALL_DOCUMENT && (
                        <Flex id={"side-bar-content"} item={"0 1 33%"} container style={{ maxWidth: 470 }}>
                            {!_.isEmpty(configuration) && this.state.activityTab === CASE && (
                                <PatientSidebar
                                    {...{
                                        configuration,
                                        updatePatient,
                                        handleCaseChange,
                                        createUserDetails,
                                        updateCase,
                                        fetchConfiguration,
                                        checkAndAssignUser,
                                        lastCaseForUser,
                                        _case,
                                        isEditDisabled,
                                        save,
                                        fetchCase,
                                        getAlreadyInvitedEmails,
                                        rejectAccessToEntityForInvitedEmail
                                    }}
                                />
                            )}
                            {!_.isEmpty(configuration) && this.state.activityTab === DOCUMENTATION && (
                                <DocumentsSidebar isEditDisabled={isEditDisabled} />
                            )}
                        </Flex>
                    )}
                    <EpiAlert
                        {...{
                            isOpen: !!this.state.alert,
                            close: this.closeSnackbar,
                            severity: this.state.alert === "error" ? this.state.alert : "success",
                            message:
                                this.state.alert === "error"
                                    ? translate("global.backend-call-failed")
                                    : this.state.alert
                                      ? translate(`global.${this.state.alert}`)
                                      : "",
                        }}
                    />
                    <ConfirmationDialog
                        {...{
                            dialogOpen: newStatus,
                            onDialogClose: removeWorkflowStatusChange,
                            onConfirm: confirmWorkflowStatusChange,
                            confirmationTextKey: "global.confirm-new-workflow",
                        }}
                    />
                </Flex>
                <ConfirmationDialog
                    {...{
                        dialogOpen: isCloneDialogOpen,
                        onDialogClose: toggleCloneDialog,
                        onConfirm: cloneCase,
                        confirmationTextKey: "global.clone-case",
                    }}
                />
            </>
        );
    }
}

export default withStyles(styles)(
    connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(withRouter(CaseEdit))),
);
