import { Switch, FormControl, TextField, Box } from "@material-ui/core";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { useFetchDistinctFieldValuesQuery } from "../../../CohortApi";

export const SwitchWithAutocompleteRenderer = ({ config, rule, idx, handleUpdateRule, }) => {
    const nestedSelectField = !!rule.properties.nestedPath
    const autoCompleteField = rule.properties.value.selectValue.field;
    const { data: autoLoadedFieldValues = [], isLoading } = useFetchDistinctFieldValuesQuery(
        { field: autoCompleteField, nested: nestedSelectField },
        { skip: !config.autoLoadValues, refetchOnReconnect: true },
    );
    const values = (config.autoLoadValues ? autoLoadedFieldValues : config.values).filter((v) => v !== "");
    const { t } = useTranslation();
    return (
        <Box key={idx} display="flex" flexDirection="row" alignItems="center" style={{ gap: "10px" }}>
            <Box display="flex" alignItems="center">
                <Trans i18nKey="global.no" />
                <Switch
                    checked={rule.properties.value.switchValue.value}
                    size="small"
                    onChange={(e) =>
                        handleUpdateRule(idx, {
                            ...rule.properties,
                            value: {
                                ...rule.properties.value,
                                switchValue: {
                                    field: rule.properties.value.switchValue.field,
                                    value: e.target.checked,
                                },
                            },
                        })
                    }
                    color="primary"
                />
                <Trans i18nKey="global.yes" />
            </Box>

            <Box key={idx} display="flex" flexDirection="row" alignItems="center" style={{ gap: "10px" }}>
                <Box display="flex" flexDirection="column">
                    <FormControl size="small">
                        <Autocomplete
                            disabled={!rule.properties.value.switchValue.value}
                            freeSolo
                            options={values}
                            value={rule.properties.value.selectValue.value}
                            size="small"
                            loading={config.autoLoadValues && isLoading}
                            loadingText={t("global.loading") + "..."}
                            onInputChange={(e, value) => {
                                handleUpdateRule(idx, {
                                    ...rule.properties,
                            value: {
                                ...rule.properties.value,
                                selectValue: {
                                    field: rule.properties.value.selectValue.field,
                                    value,
                                },
                            },
                                });
                            }}
                        filterOptions={(options) =>
                            options.filter((option) => option.toLowerCase().includes(rule.properties.value.selectValue.value.toLowerCase()))
                        }
                            disableClearable
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        size="small"
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                            style: {
                                                marginTop: 0,
                                            }
                                        }}
                                        placeholder=""
                                        onChange={params.onChange}
                                        value={params.value}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: {
                                                position: 'absolute',
                                                top: '-15px'
                                            }
                                        }}
                                        label={t(config.autocompleteLabel)}
                                        style={{ width: config.width ? config.width + "px" : undefined }}
                                    />
                                );
                            }}
                        />
                    </FormControl>
                </Box>
            </Box>
        </Box>
    );
};
