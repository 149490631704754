import {makeStyles} from "@material-ui/core/styles";
import CustomYearPicker from "./CustomYearPicker";
import React from "react";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    calendarYearMonthInput: {
        minWidth: "160px",
        maxWidth: "160px",
    },
}));

const CustomMonthPicker = ({label, ...props}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    return <CustomYearPicker
            fullWidth
            format={"MMM YYYY"}
            views={["month", "year"]}
            classes={{ root: classes.calendarYearMonthInput }}
            placeholder={" "}
            {...(label === '' ? {label: ''} : label ? {label} : {label: t('anamnesis.when')})}
            {...props}
            InputLabelProps={{
                shrink: true,
                ...props.InputLabelProps
            }}
    />;
};

export default CustomMonthPicker;
