import React from 'react';
import {TextField} from '@material-ui/core';
import {styled} from '@material-ui/core';
import {ThemeProvider, createTheme} from "@material-ui/core/styles";
import { Hmo_Theme } from "components/hmo-theme/HmoTheme";
//NOTE LM: We need this empty theme to override the global theme by the patient page.
export const textFieldTheme = createTheme({
    ...Hmo_Theme,
    overrides: {
        MuiTypography: {
            body1: {
                fontSize: 'unset'
            }
        }
    }
});

const DefaultTextField = styled((props) => {
    const {InputLabelProps, size, variant, onEnterKey, onKeyPress, standAlone, ...other} = props;

    const handleKeyPress = (onEnterKey, onKeyPress) => event => {
        const isEnterPressed = (event.key && event.key === 'Enter') || (event.keyCode && event.keyCode === 13);
        const isShiftPressed = event.shiftKey;
        if (isEnterPressed && !isShiftPressed) {
            onEnterKey && onEnterKey(event);
        } else {
            onKeyPress && onKeyPress(event);
        }
    };

    return <TextField {...other}
                      onKeyPress={handleKeyPress(onEnterKey, onKeyPress)}
                      variant={variant || "outlined"}
                      size={'small'}
                      InputLabelProps={{shrink: true, ...InputLabelProps}}
    />
})(({theme, standAlone}) => ({
    minWidth: 150,
    marginRight: standAlone ? 0 : 7,
    marginTop: standAlone ? 0 : 12,
    //NOTE LM: I have to use attribute contains selector because the mui theme gives each underlying class unique names.
    //NOTE LM 2: Don't forget the space before the square bracket, it works for theme overrides but not selectors.
    '& [class*="MuiSelect-select"]':{
        paddingTop: 9.5,
        paddingBottom: 9.5
    },
    '& [class*="MuiListItemText-root"]': {
        margin: 0
    }
}));

const ThemedDefaultTextFiled = props => <ThemeProvider theme={textFieldTheme}>
    <DefaultTextField {...props}/>
</ThemeProvider>

export default ThemedDefaultTextFiled;
