import React, {Component} from 'react';
import {IconButton, withStyles} from "@material-ui/core";
import {
    pictonBlue,
    htmlWhite,
    concrete,
    dustyGrey
} from 'components/colors/Colors';
import {MoreHoriz as More} from '@material-ui/icons';
import Flex from 'components/grid/Flex';
import _ from 'lodash';
import MaterialSelect from "components/material-select/MaterialSelect";
import Tag from 'components/tags/Tag'

const styles = theme => ({
    tagSearchInput: {
        fontFamily: 'inherit',
        fontSize: 16,
        borderRadius: 10,
        padding: 6,
        backgroundColor: concrete,
        marginTop: 5
    },
});

export class TagInputWithSuggestions extends Component {

    state = {
        showMore: false,
    };

    toggleShowMore = () => {
        this.setState({showMore: true})
    };

    render() {
        const {
            classes, selectedTags, tagSuggestions, tagFilterRelation, toggleTagSelection, handleTagFilterRelationChange
        } = this.props;
        const {showMore} = this.state;
        const {toggleShowMore} = this;

        const initialNumberOfTagsToShow = 30;
        const tags = showMore ? tagSuggestions : _.take(tagSuggestions, initialNumberOfTagsToShow);
        const tagSuggestionExceedsLimit = tags.length > initialNumberOfTagsToShow;
        return (
            <Flex item container alignItems={'center'} className={classes.tagSearchInput}>
                <Flex item container style={{flexWrap: 'wrap', borderRight: `1px solid ${dustyGrey}`, marginRight: 6}}>
                    {
                        tags.map((tag, index) =>
                            <Tag key={index} doTranslate {...{
                                tag,
                                selected: typeof(tag) === "string"
                                    ? selectedTags.includes(tag)
                                    : _.some(selectedTags, ['name', _.get(tag, 'name')]),
                                unSelectedColor: pictonBlue,
                                toggleSelection: toggleTagSelection}}/>)
                    }
                    {
                        !tagSuggestionExceedsLimit && !showMore &&
                        <IconButton size="small" style={{padding: 1}} onClick={toggleShowMore}>
                            <More/>
                        </IconButton>
                    }
                </Flex>
                <Flex item={'0 0 70px'} style={{backgroundColor: htmlWhite}}>
                    <MaterialSelect
                        options={['OR', 'AND']}
                        value={tagFilterRelation || 'OR'}
                        name={'tagFilterRelation'}
                        handleChange={handleTagFilterRelationChange}
                        translationPath={"case.tagFilterRelation."}
                    />
                </Flex>
            </Flex>
        )
    }
}

export default withStyles(styles)(TagInputWithSuggestions);
