import React, {Component} from 'react';
import {Checkbox, ListItemText, MenuItem, Select, Typography, withStyles,} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {alabaster, htmlOrange, htmlWhite, persianGreen} from 'components/colors/Colors';
import {CheckCircleOutline as CheckedIcon, RadioButtonUnchecked as UnCheckedIcon,} from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import {
    immutableMilestones,
    filterForCostCoverage,
    CDS_AVAILABLE,
    AGREEMENT_OF_PATIENT_RECEIVED,
    TNE_ORIGINAL
} from 'domain/Milestone.model';
import RadioButtonSwitch from 'components/switch/RadioButtonSwitch';

const styles = theme => ({
    avatar: {
        margin: 0
    },
    entryContainer: {
        borderRadius: 6,
        margin: 10,
        marginTop: 20,
        padding: 15
    },
    selectRoot: {
        color: htmlWhite,
        fontSize: '1.25rem',
        fontWeight: 500,
        padding: 'unset'
    },
    milestone: {
        fontSize: 15
    },
    date: {
        color: '#1B9F90',
        fontSize: 13,
    },
    dateContainer: {
        border: '1px solid #1B9F90',
        borderRadius: 4,
        padding: '0px 4px',
        marginRight: 3
    },
    icon: {
        fontSize: 18,
    },
    checkBoxRoot: {
        padding: 4
    }
});

export class Milestones extends Component {

    toggleInputPlan = () => {
        const {handleCaseChange, cdType} = this.props;

        const event = {
            target: {
                name: "cdType",
                value: !!!cdType
            }
        }

        handleCaseChange(event);
    }

    getTranslationKey(milestone, costCoverageTypes) {
        const isStudy = costCoverageTypes.includes('STUDIE_DKG')

        if (!isStudy) return `case.milestone.${milestone.id}`;

        if (milestone.id !== AGREEMENT_OF_PATIENT_RECEIVED && milestone.id !== TNE_ORIGINAL) {
            return `case.milestone.${milestone.id}`;
        } else {
            return `case.milestone.${milestone.id}_STUDY`;
        }
    }

    render() {
        const {
            classes, t: translate, milestones, handleMilestoneChange, isEditDisabled, handleCaseChange, satisfaction,
            costCoverageTypes, cdType
        } = this.props;
        return <Flex item container direction={'column'} className={classes.entryContainer} style={{
            backgroundColor: alabaster,
            height: 'fit-content',
            paddingLeft: '7%'
        }}>
            {
                !_.isEmpty(milestones) && milestones
                        .filter(m => filterForCostCoverage(m.id, costCoverageTypes))
                        .map(milestone => {
                                    const isImmutableIcon = immutableMilestones.includes(milestone.id);
                                    return <div key={milestone.id}>
                                        <Flex item="0 1 auto" container alignItems={'center'} key={milestone.id}
                                              justifyContent={'space-between'}>
                                            <Flex item container justifyContent={'space-between'}>
                                                <Flex item container>
                                                    <Typography className={classes.milestone}>
                                                        {translate(this.getTranslationKey(milestone, costCoverageTypes))}
                                                    </Typography>
                                                </Flex>

                                                {
                                                    milestone.date &&
                                                    <Flex item grow={0} className={classes.dateContainer}>
                                                        <Typography className={classes.date}>
                                                            {moment(milestone.date).format('DD. MMM YYYY')}
                                                        </Typography>
                                                    </Flex>
                                                }
                                            </Flex>

                                            <Flex container item={'0 0 0'}>
                                                <Checkbox
                                                        checked={!!milestone.date}
                                                        disabled={isImmutableIcon || isEditDisabled}
                                                        onChange={handleMilestoneChange(milestone)}
                                                        className={classes.checkBoxRoot}
                                                        checkedIcon={<CheckedIcon
                                                                className={classes.icon}
                                                                style={{
                                                                    color: isImmutableIcon ? persianGreen : 'unset',
                                                                    fill: isImmutableIcon ? persianGreen : 'unset',
                                                                }}/>}
                                                        icon={<UnCheckedIcon className={classes.icon}/>}
                                                />
                                            </Flex>
                                        </Flex>
                                        {
                                            milestone.id === CDS_AVAILABLE && milestone.date &&
                                            <Flex item container alignItems={'center'}
                                                  key={milestone.id + 'orig'}
                                                  justifyContent={'space-between'}>
                                                <RadioButtonSwitch id={'CDS_AVAILABLE'}
                                                                   style={{
                                                                       marginBottom: 10,
                                                                       marginLeft: 30
                                                                   }} {...{
                                                    isLeftSelected: cdType,
                                                    toggle: this.toggleInputPlan,
                                                    labelRight: translate(`case.milestone.COPY`),
                                                    labelLeft: translate(`case.milestone.ORIGINAL`),
                                                }}/>
                                            </Flex>
                                        }
                                    </div>;
                                }
                        )
            }

            {/* SATISFACTION */}
            <Flex item="0 1 auto" container alignItems={'center'} justifyContent={'space-between'}>
                <Flex item container justifyContent={'space-between'}>
                    <Flex item container>
                        <Typography className={classes.milestone}>
                            {translate(`case.milestone.SATISFACTION`)}
                        </Typography>
                    </Flex>

                    <Flex container item={'0 0 0'}>
                        <Select
                                value={satisfaction || ''}
                                onChange={handleCaseChange}
                                fullWidth
                                disabled={isEditDisabled}
                                name={'satisfaction'}
                                renderValue={value => translate(`case.milestone.${value}`)}
                                style={{width: 150}}
                        >
                            <MenuItem value="SATISFIED">
                                <ListItemText primary={translate(`case.milestone.SATISFIED`)}/>
                            </MenuItem>
                            <MenuItem value="NEUTRAL">
                                <ListItemText primary={translate(`case.milestone.NEUTRAL`)}/>
                            </MenuItem>
                            <MenuItem value="NOTSATISFIED">
                                <ListItemText primary={translate(`case.milestone.NOTSATISFIED`)}/>
                            </MenuItem>
                        </Select>
                    </Flex>
                </Flex>
            </Flex>

            {/* If no coverage type selected */}
            {costCoverageTypes.length === 0 &&
            <Flex item="0 1 auto" container alignItems={'center'} justifyContent={'space-between'}>
                <Flex item container>
                    <Typography style={{color: htmlOrange, fontWeight: 'bold', paddingTop: 10}}
                                className={classes.milestone}>
                        {translate(`case.milestone.EMPTY`)}
                    </Typography>
                </Flex>
            </Flex>
            }
        </Flex>;
    }
}

export default withStyles(styles)(withTranslation()(Milestones));
