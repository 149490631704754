import { sendEmails, printEmails} from './EmailManager.action';
import {fetchCase} from "scenes/case/edit/CaseEdit.action";
import { downloadIndicateDocx } from 'scenes/FileHandler.action';
import {getExistingUser} from 'scenes/user-manager/UserManager.action';

export default {
    mapStateToProps: (state) => {
        return {
            case: state.caseEditReducer.case,
            insuranceCompanies: state.caseReducer.configuration.insuranceCompanies,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            fetchCase: caseBusinessId => dispatch(fetchCase(caseBusinessId)),
            getExistingUser: emailAddress => dispatch(getExistingUser(emailAddress)),
            sendEmails: (mailTypes, emailAddress, personType, userDetailsBusinessId, caseBusinessId) =>
                dispatch(sendEmails(mailTypes, emailAddress, personType, userDetailsBusinessId, caseBusinessId)),
            printEmails: (mailTypes, emailAddress, personType, caseBusinessId, userDetailsBusinessId) =>
                dispatch(printEmails(mailTypes, emailAddress, personType, caseBusinessId, userDetailsBusinessId)),
            downloadIndicateDocx: (caseBusinessId, indication) => dispatch(downloadIndicateDocx(caseBusinessId, indication)),
        };
    }
};
