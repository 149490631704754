import {
    getUser, updateUser
} from './UserManager.action';

export default {
    mapStateToProps: state => {
        return {
            user: state.mainReducer.user,
        }
    },
    mapDispatchToProps: dispatch => {
        return {
            getUser: businessId => dispatch(getUser(businessId)),
            updateUser: props => dispatch(updateUser(props))
        }
    }
}
