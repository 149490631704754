import {Client} from '@stomp/stompjs';
import * as actionTypes from "./Socket.action.types";

export function initializeWebsocket() {
    return (dispatch) => {
        const client = new Client();
        const websocketBrokerUrl = createWebSocketUrl();
        client.configure({
            brokerURL: websocketBrokerUrl,
            onConnect: () => {
                dispatch(socketConnectionSuccess());
            },
            onWebSocketClose: evt => {
                dispatch(socketConnectionClosed());
            },
            onStompError: receipt => {
                dispatch(socketConnectionError());
            }

        });

        client.activate();

        dispatch(socketConnectionInit(client));
    };
}

function createWebSocketUrl() {
    const protocolPrefix = (window.location.protocol === 'https:') ? 'wss:' : 'ws:';
    return protocolPrefix + '//' + window.location.host + "/ws";
}

function socketConnectionInit(client) {
    return {
        type: actionTypes.SOCKET_CONNECTION_INIT,
        client
    };
}

function socketConnectionSuccess() {
    return {
        type: actionTypes.SOCKET_CONNECTION_SUCCESS
    };
}

function socketConnectionError() {
    return {
        type: actionTypes.SOCKET_CONNECTION_ERROR
    };
}

function socketConnectionClosed() {
    return {
        type: actionTypes.SOCKET_CONNECTION_CLOSED
    };

}
