import React, {Component} from 'react';
import {Typography} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import SortIcon from 'components/sort-icon/SortIcon';

const HeaderItem = (props) => {
    const {onSort, sortParameter, sortName, title, reverseSort, item, style} = props;
    return (
            <Flex item={item || '1 0 5%'} container onClick={onSort && onSort(sortName)} center style={style || {width: '5%'}}>
                <Typography style={{color: '#585757', fontSize: 13}}>{title}</Typography>
                {
                    onSort &&
                    <SortIcon isShown={sortParameter === sortName} reverse={reverseSort}/>
                }
            </Flex>
    );
};

export class CareProviderHeader extends Component {
    render() {
        const {t: translate, onSort, sortParameter, reverseSort} = this.props;

        return (
                <Flex item container grow={0} style={{
                    backgroundColor: "#F8F8FA",
                    marginLeft: 70,
                    marginRight: 10,
                    height: 40,
                    borderRadius: '15px 15px 0px 0px',
                    border: '1px solid #E2E1E4'
                }}>

                    <HeaderItem {...{
                        title: translate('global.name'),
                        sortName: 'name',
                        onSort, reverseSort, sortParameter,
                    }}/>

                    <HeaderItem {...{
                        title: translate('global.programs'),
                        sortName: 'programs',
                        onSort, reverseSort, sortParameter,
                    }}/>

                    <HeaderItem {...{
                        title: translate('case.city'),
                        sortName: 'city',
                        onSort, reverseSort, sortParameter,
                    }}/>

                </Flex>
        );
    }
}

export default withTranslation()(CareProviderHeader);
