import React, {Component} from 'react';
import {Dialog, Typography, MenuItem} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined';
import DefaultTextField from 'components/hmo-textfield/DefaultTextField';
import HmoFilledButton from 'components/button/HmoFilledButton';
import HmoBorderedButton from 'components/button/HmoBorderedButton';
import {withRouter} from 'utils/Route.utils';
import {connect} from 'react-redux';
import connector from './Contact.connect';
import {DOCTOR, ASSISTANT} from 'domain/User.model';
import {CARE_PROVIDER} from 'domain/EntityAuthorizationType.model';
import utils from 'utils/Utils';

export class NewContactDialog extends Component {

    state = {
        invitedRoleType: DOCTOR
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    addContact = () => {
        this.props.createUserDetails({
            userDetailsData: {
                businessId: utils.uuid(),
                subordinateToBusinessId: this.props.subordinateToBusinessId || null,
            },
            invitedRoleType: this.state.invitedRoleType,
            entityType: CARE_PROVIDER,
            entityId: this.props.careProviderId
        }).then(() => this.props.refreshContacts());
    }

    render() {
        const {t: translate, cancel} = this.props;
        const {handleChange, addContact} = this;
        const {invitedRoleType} = this.state;
        return (
                <Dialog open={true}>
                    <Flex item container column padding={15} style={{minWidth: 420}}>
                        <Flex item container justifyContent={'space-between'} alignItems={'center'}
                              style={{marginRight: 7}}>
                            <Typography variant={"h5"}>{translate("global.new-contact")}</Typography>
                            <LocalHospitalOutlinedIcon style={{marginLeft: 20}}/>
                        </Flex>
                        <DefaultTextField label={translate("global.invited-role-type")}
                                          style={{width: '100%'}}
                                          select
                                          SelectProps={{
                                              value: invitedRoleType || '',
                                              name: 'invitedRoleType',
                                              onChange: handleChange
                                          }}>
                            <MenuItem key={DOCTOR} value={DOCTOR}>
                                {translate(`global.${DOCTOR}`)}
                            </MenuItem>
                            <MenuItem key={ASSISTANT} value={ASSISTANT}>
                                {translate(`global.${ASSISTANT}`)}
                            </MenuItem>
                        </DefaultTextField>

                        <Flex item container style={{marginTop: 10, marginRight: 7}}>
                            <HmoBorderedButton onClick={cancel} size={'small'} style={{width: '50%', marginRight: 10}}>
                                {translate("global.cancel")}
                            </HmoBorderedButton>
                            <HmoFilledButton onClick={addContact} style={{width: '50%'}}>
                                {translate("global.save")}
                            </HmoFilledButton>
                        </Flex>
                    </Flex>
                </Dialog>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(
        withTranslation()(
                withRouter(NewContactDialog)
        )
);
