import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core";

export const useBlueButtonWithIconStyles = makeStyles(() => ({
    root: {
        display: "flex",
        justifyContent: "center",
        marginBottom: '-16px',
        paddingTop: '4px',
        paddingBottom: '4px',
    },
    button: {
        textTransform: "capitalize",
        alignSelf: "flex-end",
        color: "#256AF6",
        fontWeight: "bold",
    },
    icon: {
        color: "#256AF6",
        borderRadius: "50%",
        backgroundColor: "rgba(37,106,246, 0.24)",
        height: 24,
        width: 24,
        marginLeft: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }
}));

const BlueButtonWithIcon = ({onClick, disabled = false, Icon, label}) => {
    const classes = useBlueButtonWithIconStyles();
    return <div className={classes.root}>
        <Button className={classes.button} style={{visibility: disabled ? 'hidden' : 'visible'}}
                onClick={onClick}>
            {label}
            { Icon ? Icon : <span className={classes.icon}>+</span> }
        </Button>
    </div>;
};

export default BlueButtonWithIcon;
