import { Dialog, DialogContent, DialogActions, Button, DialogTitle } from "@material-ui/core";
import { Trans } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import { useLazyGetCareProviderPatientAccessesQuery } from "scenes/patient/CareProviderApi";
import { useDeleteAccessAssignmentByIdMutation } from "scenes/patient/ProgramAccessApi";
import {MANAGED_CARE} from 'routes/routes';
import {useNavigate} from 'react-router-dom';

export const RemoveFullAccessToPatientDialog = ({
    open,
    patientId,
    onClose,
    accessAssignmentsOfCareProviderIdsToDelete,
    setAlert
}) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [getCareProviderAccessData] = useLazyGetCareProviderPatientAccessesQuery();
    const [deleteAccessAssignments] = useDeleteAccessAssignmentByIdMutation();
    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle style={{ paddingBottom: "2px", color: theme.palette.secondary.main }}>
                <Trans i18nKey={"Remove access rights"} />
            </DialogTitle>
            <DialogContent>
                <Trans i18nKey={"Do you really want to remove the access rights to the patient's data?"} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" size={"small"} variant="outlined">
                    <Trans i18nKey={"global.cancel"} />
                </Button>
                <Button
                    onClick={() => {
                        const accessAlignmentsIdsPromiseArray = accessAssignmentsOfCareProviderIdsToDelete.map(
                            (accessAssignmentCpId) => {
                                return getCareProviderAccessData({
                                    patientId,
                                    careProviderId: accessAssignmentCpId,
                                }).then(({ data }) => data.accessAssignmentId);
                            },
                        );

                        Promise.all(accessAlignmentsIdsPromiseArray)
                            .then((ids) => deleteAccessAssignments({ patientId, ids }))
                            .catch((error) => setAlert("error"))
                            .finally(() => {
                                navigate(MANAGED_CARE.path);
                                onClose();
                            });
                    }}
                    color="secondary"
                    size={"small"}
                    variant="contained"
                >
                    <Trans i18nKey={"deleteCareProviderAccessModal.revoke-access-button"} />
                </Button>
            </DialogActions>
        </Dialog>
    );
};
