import React, {useEffect, useState} from "react";
import {useSelector, shallowEqual} from "react-redux";
import {Card, MenuItem} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {
    useGetPatientAlreadyInvitedEmailsQuery,
    useGetCareProvidersForPatientQuery
} from "scenes/patient/PatientApi";
import {useGetContactsQuery} from "scenes/patient/ProgramAccessApi";
import {useDeleteUnseenMutation} from "scenes/patient/patient-sidebar/UneenApi";
import UploadedFiles from "scenes/patient/patient-sidebar/UploadFiles/UploadedFiles";
import PatientSidebarChat from "scenes/patient/patient-sidebar/PatientSidebarChat";
import PatientSidebarGrantedAccess from "scenes/patient/patient-sidebar/PatientSidebarGrantedAccess";
import {useParams, useLocation} from "react-router-dom";
import PatientSidebarItem from "scenes/patient/patient-sidebar/PatientSidebarIem";
import PatientSidebarDatePicker from "scenes/patient/patient-sidebar/DatePicker";
import {Collapse, IconButton, TextField, Typography} from "@material-ui/core";
import {Field, Formik, useFormikContext} from "formik";
import MultiInput from "components/input/MultiInput";
import {ReactComponent as ChevronArrow} from "assets/img/chevron-arrow.svg";
import AvatarWithUpload from "components/avatar/AvatarWithUpload";
import UserManager from "scenes/user-manager/UserManager";
import AccessManager from "scenes/user-manager/access-manager/AccessManager";
import HMOApi, {UserDetails, PatientInvitedEmails, AccessAssignments} from "store/api/HMOApi";
import {useDispatch} from "react-redux";
import {CONTACT_PERSON, ASSISTANT, DOCTOR, PATIENT} from "domain/User.model";
import Flex from "components/grid/Flex";
import {isCaseManager, isPatient, isManager, isContactPerson} from 'domain/User.model';
import {toEntityType, CASE, CARE_PROGRAM, FULL_PATIENT} from 'domain/EntityAuthorizationType.model';
import _ from 'lodash';
import DeleteUserButton from 'scenes/user-manager/DeleteUserButton';
import {useTranslation} from "react-i18next";
import {PATIENT_PAGE_PROGRAM} from 'routes/routes';
import {
    useFetchPatientByIdQuery,
    useUpdatePatientDetailsMutation
} from "scenes/patient/PatientApi";
import {useFetchPatientProgramsQuery} from 'scenes/patient/ProgramApi';
import {debounce} from "lodash";
import MultiInputEmailAddressEditor from "scenes/user-manager/access-manager/MultiInputEmailAddressEditor";
import CareProviderAccess from "scenes/user-manager/access-manager/CareProviderAccess";
import DefaultTextField from "components/hmo-textfield/DefaultTextField";
import InvitationEditor from "../../user-manager/access-manager/InvitationEditor";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#F8F8FA",
        border: "1px solid #E2E1E4",
        minHeight: "60vh",
        paddingLeft: "14px",
        paddingRight: "14px",
        borderRadius: "0 0 15px 15px",
        paddingBottom: "20px",
    },
    personMainInfoBlock: {
        paddingTop: "18px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    personPhoto: {
        backgroundColor: "#D4D4D4",
        width: "120px",
        height: "120px",
        borderRadius: "50%",
    },
    personName: {
        fontSize: "22px",
        fontWeight: "600",
    },
    personStatus: {
        fontSize: "18px",
        color: "#030303",
    },
    patientDetailsList: {
        "& > :not(:last-child)": {
            marginBottom: "10px",
        },
    },
    chevronArrow: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(1),
        "&.active svg": {
            transform: "rotate(0deg)",
        },
        "& svg": {
            width: "14px",
            height: "14px",
            transform: "rotate(180deg)",
            padding: "10px",
            boxSizing: "content-box",
            cursor: "pointer",
        },
    },
}));

const UserAndAccessManager = (props) => {
    const {translate, user, patientData, excludedUserIds, alreadyExitingInvites, refreshPatient} = props;

    return isManager(user) || isPatient(user)
            ? <Flex item container justifyContent={"flex-end"}>
                {
                    patientData.userBusinessId
                            ? isCaseManager(user) || isPatient(user)
                                    ? <UserManager
                                            {...{
                                                style: {flex: "0 1 auto", minWidth: 'unset'},
                                                userBusinessId: patientData?.userBusinessId,
                                                emailAddresses: patientData?.emailAddresses,
                                            }}
                                    />
                                    : <Typography style={{
                                        color: 'lightgrey'
                                    }}>{translate('global.existing-user')}</Typography>
                            : <AccessManager
                                    {...{
                                        patient: patientData,
                                        person: patientData,
                                        excludedUserIds,
                                        existingInvitesForEntity: alreadyExitingInvites,
                                        invitedRoleType: PATIENT,
                                        callback: refreshPatient,
                                        inviteButtonTextOverride: translate("global.grant-access-patient")
                                    }}
                            />
                }
            </Flex>
            : <></>

};

const PatientDetails = ({classes, translate, handleChange, values, setFieldValue, disabled, patient}) => {

    return (
        <div className={classes.patientDetailsList}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "10px",
                }}
            >
                <Field
                    select
                    InputLabelProps={{ shrink: true }}
                    as={TextField}
                    style={{ flexBasis: "100px" }}
                    onChange={handleChange}
                    disabled={disabled}
                    name="salutation"
                    value={values.salutation || ""}
                    label={translate("case.salutation")}
                >
                    {["Frau", "Herr"].map((salutation) => (
                        <MenuItem key={salutation} value={salutation}>
                            {translate(`global.${salutation}`)}
                        </MenuItem>
                    ))}
                </Field>
                <Field
                    InputLabelProps={{ shrink: true }}
                    as={TextField}
                    fullWidth={true}
                    onChange={handleChange}
                    disabled={disabled}
                    required
                    name="givenName"
                    value={values.givenName}
                    error={values.givenName.length === 0}
                    label={translate("case.first-name")}
                />
            </div>
            <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                required
                disabled={disabled}
                error={values.familyName.length === 0}
                onChange={handleChange}
                name="familyName"
                value={values.familyName}
                label={translate("case.last-name")}
            />
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "20px",
                }}
            >
                <TextField
                    select
                    fullWidth
                    label={translate("case.sex")}
                    InputLabelProps={{ shrink: true }}
                    name="bioSex"
                    disabled={disabled}
                    variant="standard"
                    onChange={handleChange}
                    value={values.bioSex || ""}
                >
                    <MenuItem key="MAN" value="MAN">
                        {translate("global.male-bio-sex")}
                    </MenuItem>
                    <MenuItem key="WOMAN" value="WOMAN">
                        {translate(`global.female-bio-sex`)}
                    </MenuItem>
                </TextField>
                <PatientSidebarDatePicker
                    name="dateOfBirth"
                    disabled={disabled}
                    onChange={setFieldValue}
                    label={translate("case.date-of-birth")}
                    date={values.dateOfBirth ? values.dateOfBirth : null}
                />
            </div>

            {patient?.userEmailAddress && !values.emailAddresses.includes(patient?.userEmailAddress) && (
                <DefaultTextField
                    value={patient.userEmailAddress || ""}
                    standAlone
                    variant={"standard"}
                    fullWidth
                    label={translate("global.user-email-address")}
                    disabled
                    onChange={() => {}}
                />
            )}

            <MultiInputEmailAddressEditor
                {...{
                    emailAddresses: values.emailAddresses,
                    person: patient,
                    invitedRoleType: PATIENT,
                    disabled,
                    defaultTextFieldProps: {
                        variant: "standard",
                        fullWidth: true,
                    },
                    standAlone: true,
                    updateInputValues: ({ emailAddresses }) => {
                        setFieldValue("emailAddresses", emailAddresses);
                    },
                }}
            />

            <MultiInput
                updateInputValues={({ phoneNumbers }) => {
                    setFieldValue("phoneNumbers", phoneNumbers);
                }}
                standAlone={true}
                translate={translate}
                isDisabled={disabled}
                name="phoneNumbers"
                inputValues={values.phoneNumbers}
                defaultTextFieldProps={{
                    variant: "standard",
                    fullWidth: true,
                }}
            />
        </div>
    );
};

const AutoSubmit = () => {
    const {values, submitForm, dirty} = useFormikContext();
    useEffect(() => {
        if (dirty) {
            submitForm();
        }
    }, [values, submitForm, dirty]);
    return null;
};

const PatientPageSidebar = () => {
    const classes = useStyles();
    const {t: translate} = useTranslation();
    const {patientId, programId} = useParams();
    const {pathname} = useLocation();
    const user = useSelector((state) => state.mainReducer.user, shallowEqual);
    const [isDetailsShown, setIsDetailsShown] = useState(false);
    const dispatch = useDispatch();
    const [excludedUserIds, setExcludedUserIds] = useState([]);
    const [dropZoneRef, setDropZoneRef] = useState(null);
    const [anamnesisQuestionId, setAnamnesisQuestionId] = useState([]);
    const exposeDropZoneRef = ref => setDropZoneRef(ref);

    const [deleteUnseen] = useDeleteUnseenMutation();
    const {data: patientData = {}, isSuccess} = useFetchPatientByIdQuery(patientId);
    const [updatePatientDetails] = useUpdatePatientDetailsMutation();
    const {data: patientPrograms = [], isSuccess: isProgramsSuccess} = useFetchPatientProgramsQuery(patientId);
    const selectedProgram = programId && isProgramsSuccess
            ? patientPrograms.find(item => item?.careProgramData.id === Number(programId))?.careProgramData
            : {};
    const submitFormValues = debounce((values) => {
        updatePatientDetails({
            id: patientData.id,
            ...values,
        });
    }, 500);

    const anonymized = patientData.anonymized;

    const getAgeAtAnonymization = (dateOfBirth, creationDate) =>
        dateOfBirth !== "" ? new Date(creationDate).getFullYear() - new Date(dateOfBirth).getFullYear() : "";

    const isProgramSelected = PATIENT_PAGE_PROGRAM.pathWithId(patientId, programId) === pathname
            && isProgramsSuccess && !!selectedProgram;
    //TODO LM: merge
    // useGetPatientAlreadyInvitedEmailsQuery
    // useGetCareProvidersForPatientQuery
    // useGetContactsQuery
    // this can come with filesToShow and linksToShow, useGetMultipleDocumentLinksQuery, useGetMultipleFilesQuery
    // into a single backend call
    const {
        data: alreadyExitingInvites,
        isFetching: isInvitedEmailsDataFetching
    } = useGetPatientAlreadyInvitedEmailsQuery({
        entityType: isProgramSelected ? selectedProgram.name === CASE ? CASE : CARE_PROGRAM : FULL_PATIENT,
        entityId: isProgramSelected ? selectedProgram.id : patientId,
        patientId,
    });

    const patientInvite = alreadyExitingInvites?.find(item => item.invitedRoleType === PATIENT && item.entityType === FULL_PATIENT);

    const {
        data: careProvidersForPatient
    } = useGetCareProvidersForPatientQuery(patientId);
    const {
        data: contacts = [],
        isFetching: isContactsDataFetching
    } = useGetContactsQuery({
        entityType: isProgramSelected ? selectedProgram.name === CASE ? CASE : CARE_PROGRAM : FULL_PATIENT,
        entityId: isProgramSelected ? selectedProgram?.id : patientData?.id,
        patientId: patientId
    });
    useEffect(() => {
        if (!_.isEmpty(contacts)) {
            const excludedUserIds = contacts.filter((userDetail) => userDetail.userId).map((userDetail) => userDetail.userId);
            setExcludedUserIds(excludedUserIds);
        }
    }, [contacts]);

    const refreshPatient = () => {
        dispatch(HMOApi.util.invalidateTags([UserDetails, AccessAssignments, PatientInvitedEmails]));
    };

    const fileStorageType = isProgramSelected
            ? selectedProgram.name === CASE
                    ? CASE
                    : CARE_PROGRAM
            : PATIENT;
    const entityId = isProgramSelected ? selectedProgram?.id : patientData?.id;


    const uploadFilesComponentConfig = {
        fileStorageType,
        entityBusinessId: isProgramSelected ? selectedProgram?.businessId : patientData?.businessId,
        entityId,
    };

    //TODO LM: filesToShow and linksToShow should be coming from the backend
    const filesToShow = isProgramSelected
            ? [
                {
                    fileStorageType: uploadFilesComponentConfig.fileStorageType,
                    entityBusinessId: uploadFilesComponentConfig.entityBusinessId,
                    entityId: uploadFilesComponentConfig.entityId,
                    label: selectedProgram.name,
                },
                {
                    fileStorageType: PATIENT,
                    entityBusinessId: patientData?.businessId,
                    entityId: patientData?.id,
                    label: translate('global.general'),
                }
            ]
            : [
                {
                    fileStorageType: uploadFilesComponentConfig.fileStorageType,
                    entityBusinessId: uploadFilesComponentConfig.entityBusinessId,
                    entityId: uploadFilesComponentConfig.entityId,
                    label: translate('global.general'),
                },
                ...patientPrograms.map((x) => ({
                    fileStorageType: x.careProgramData.name === CASE ? CASE : CARE_PROGRAM,
                    entityBusinessId: x.careProgramData.businessId,
                    entityId: x.careProgramData.id,
                    label: x.careProgramData.name === CASE ? translate('global.second-opinion') : x.careProgramData.name,
                })),
            ];

    const linksToShow = isProgramSelected ? [{
        entityBusinessId: uploadFilesComponentConfig.entityBusinessId,
        storageTag: uploadFilesComponentConfig.fileStorageType,
        label: selectedProgram.name,
        },
        {
            entityBusinessId: patientData.businessId,
            storageTag: PATIENT,
            label: translate('global.general'),
        },
    ] : [
        {
            entityBusinessId: uploadFilesComponentConfig.entityBusinessId,
            storageTag: uploadFilesComponentConfig.fileStorageType,
            label: translate('global.general'),
        },
        ...patientPrograms.map((x) => ({
            entityBusinessId: x.careProgramData.businessId,
            storageTag: x.careProgramData.name === CASE ? CASE : CARE_PROGRAM,
            label: x.careProgramData.name === CASE ? translate('global.second-opinion') : x.careProgramData.name,
        }))
    ];

    const entityType = toEntityType(fileStorageType);
    const userRole = user?.roles.length === 1 ? user?.roles[0] : undefined;

    useEffect(() => {
        if (userRole && patientId && entityType && entityId) {
            const unseenBy = userRole === ASSISTANT ? DOCTOR : userRole;
            deleteUnseen({patientId, entityType, entityId, unseenBy});
        }
    }, [userRole, patientId, entityType, entityId, deleteUnseen]);

    return !isSuccess || !isProgramsSuccess ? (
        <></>
    ) : (
        <Formik
            enableReinitialize
            initialValues={{
                givenName: patientData.givenName,
                familyName: patientData.familyName,
                bioSex: patientData.bioSex,
                phones: patientData.phoneNumbers,
                dateOfBirth: patientData.dateOfBirth,
                emailAddresses: patientData.emailAddresses,
                phoneNumbers: patientData.phoneNumbers,
                salutation: patientData.salutation,
            }}
            onSubmit={(values) => submitFormValues(values)}
        >
            {({ values, handleChange, setFieldValue }) => (
                <div className={classes.root}>
                    <div className={classes.personMainInfoBlock}>
                        <AvatarWithUpload userDetails={patientData} style={{ padding: 0, color: "red" }} />
                        <div className={classes.personName}>{`${patientData.givenName} ${patientData.familyName}`}</div>
                        <div walkthrough-element="personal-data">Patient</div>
                    </div>
                    <Collapse timeout={200} in={isDetailsShown}>
                        <PatientSidebarItem>
                            <Flex item container justifyContent="flex-end" alignItems="center">
                                {patientInvite && (
                                    <Typography style={{ color: "orange", fontStyle: "italic", marginRight: 20 }}>
                                        {translate("access.invitation-sent")}
                                    </Typography>
                                )}
                                {patientInvite ? (
                                    <InvitationEditor
                                        {...{
                                            patient: patientData,
                                            invite: patientInvite,
                                            excludedUserIds,
                                        }}
                                    />
                                ) : (
                                    <UserAndAccessManager
                                        {...{
                                            translate,
                                            user,
                                            patientData,
                                            excludedUserIds,
                                            alreadyExitingInvites,
                                            refreshPatient,
                                        }}
                                    />
                                )}
                            </Flex>

                            <PatientDetails
                                {...{
                                    classes,
                                    translate,
                                    handleChange,
                                    values,
                                    setFieldValue,
                                    disabled: isContactPerson(user),
                                    patient: patientData,
                                }}
                            />
                        </PatientSidebarItem>
                        <DeleteUserButton userDetailsId={patientId} />
                    </Collapse>
                    {!anonymized && (
                        <div className={[classes.chevronArrow, isDetailsShown ? "active" : ""].join(" ")}>
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setIsDetailsShown(!isDetailsShown);
                                }}
                            >
                                <ChevronArrow data-testid="show-hide-patient-info-btn" title="Show/Hide patient info" />
                            </IconButton>
                        </div>
                    )}
                    {anonymized && (
                        <div
                            style={{
                                background: "white",
                                padding: "8px",
                                border: "1px solid silver",
                                marginTop: "8px",
                                borderRadius: "4px",
                            }}
                        >

                            <div>{translate("patient.age-at-anonymization")}: {getAgeAtAnonymization(patientData.dateOfBirth, patientData.creationDate) }</div>
                            <div>{translate("case.sex")}: {patientData.bioSex ? translate(patientData.bioSex === 'MAN' ? `global.male-bio-sex` : `global.male-bio-sex` ) : ""} </div>
                        </div>
                    )}
                    {anonymized && (
                        <div style={{marginTop: "8px"}}>
                            <DeleteUserButton suggestAnonymize={false} userDetailsId={patientId} />
                        </div>
                    )}
                    <div walkthrough-element="upload-data"></div>
                    {!anonymized && (
                        <UploadedFiles
                            {...{
                                filesToShow,
                                linksToShow,
                                fileStorageType: uploadFilesComponentConfig.fileStorageType,
                                entityBusinessId: uploadFilesComponentConfig.entityBusinessId,
                                patientBusinessId: patientData?.businessId,
                                entityId: uploadFilesComponentConfig.entityId,
                                patientId: patientData?.id,
                                label: isProgramSelected
                                    ? translate("global.program-medical-files")
                                    : translate("global.all-medical-files"),
                                patientPrograms,
                                selectedProgramBusinessId: selectedProgram?.businessId,
                                exposeDropZoneRef,
                                anamnesisQuestionId,
                            }}
                        />
                    )}

                    {!anonymized && <PatientSidebarChat patientId={patientId} />}
                    {(isManager(user) || isPatient(user)) && !anonymized && (
                        <PatientSidebarGrantedAccess
                            {...{
                                fetching: isContactsDataFetching || isInvitedEmailsDataFetching,
                                contacts: contacts,
                                alreadyExitingInvites,
                                careProvidersForPatient,
                                user: user,
                                patientId,
                                patientFullName: `${patientData.givenName} ${patientData.familyName}`,
                                selectedProgramId: selectedProgram?.id,
                            }}
                        />
                    )}
                    <AutoSubmit />
                    {(isCaseManager(user) || isPatient(user)) && !anonymized && (
                        <Flex item container column>
                            <Flex item grow={0}>
                                <AccessManager
                                    {...{
                                        patient: patientData,
                                        person: {},
                                        floatingButton: true,
                                        invitedRoleType: CONTACT_PERSON,
                                        excludedUserIds,
                                        existingInvitesForEntity: alreadyExitingInvites,
                                        buttonStyle: { margin: 10 },
                                        callback: refreshPatient,
                                        buttonTextOverride: translate("global.grant-access-contact"),
                                    }}
                                />
                            </Flex>
                            <Flex item grow={0}>
                                <CareProviderAccess
                                    {...{
                                        careProvidersForPatient,
                                        patient: patientData,
                                    }}
                                />
                            </Flex>
                        </Flex>
                    )}
                </div>
            )}
        </Formik>
    );
};

export default PatientPageSidebar;
