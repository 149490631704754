import { useContext } from "react";
import filterState from "../../../filter-state";
import { OrGroupRenderer } from "./node-types-renderers/OrGroupRenderer";
import { useTranslation } from "react-i18next";
import { AddAndClauseButton } from "./node-control-components/AddAndClauseButton";
import { AndGroupRenderer } from "./node-types-renderers/AndGroupRenderer";
import { RuleRenderer } from "./node-types-renderers/RuleRenderer";

export const NodeRenderer = ({ componentData, indexPath, idx }) => {
    const handleUpdateRule = (_idx, updatedProperties) => {
        dispatch({
            type: filterState.actions.UPDATE_NODE,
            indexPath,
            payload: {
                properties: updatedProperties,
            },
        });
    };
    const { dispatch } = useContext(filterState.queryFormContext);
    const { t } = useTranslation();
    const isGroupNode = componentData.node.type === "group";
    const conjunction = componentData.node.properties.conjunction;
    return (
        <div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    // marginTop: isGroupNode && conjunction !== 'and' ? '40px': '0px',
                    marginBottom: isGroupNode ? "12px" : "22px",
                }}
            >
                {/* {<div>indexPath: {JSON.stringify(indexPath)}</div>} */}
                {isGroupNode && conjunction === "and" && (
                    <AndGroupRenderer componentData={componentData} indexPath={indexPath} />
                )}

                {isGroupNode && conjunction !== "and" && (
                    <OrGroupRenderer
                        config={componentData.config.widgetConfig}
                        rule={componentData.node}
                        indexPath={indexPath}
                        idx={componentData.index}
                        widgetType={componentData.config.widgetType}
                    />
                )}
                {!isGroupNode && (
                    <RuleRenderer componentData={componentData} indexPath={indexPath} />

                )}
            </div>
            {componentData.config.widgetConfig.allowAndCondition && indexPath.length === 1 && (
                <AddAndClauseButton
                    nodeIndex={componentData.index}
                    label={t(componentData.config.widgetConfig.andConditionLabel)}
                />
            )}
        </div>
    );
};
