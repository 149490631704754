import Backdrop from "@material-ui/core/Backdrop";
import { useState } from "react";



import { makeStyles } from "@material-ui/core/styles";
import { WalkthroughStep } from "./WalkthroughStep";


const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.modal + 1,
        backgroundColor: "rgb(225, 225, 225, 0.5)",
    },
}));

const isLastStep = (currentStep, steps) => {
    return currentStep + 1 >= steps.length;
}

export const Walkthrough = ({steps = [], toggleWalkthrough, onCompleteCallback = () => {}}) => {
    const [currentStep, setCurrentStep] = useState(0);

    const onNext = () => {
        if (isLastStep(currentStep, steps)) {
            onComplete();
        }
        setCurrentStep(currentStep + 1)
    };

    const onComplete = () => {
        toggleWalkthrough();
        onCompleteCallback();
    };

    const onClose = () => {
        toggleWalkthrough();
    }

    const onLaterHandler = () => {
        setCurrentStep(steps.length - 1);
    }
    const classes = useStyles();
    const currentStepConfig = steps[currentStep];
    return (
       <Backdrop className={classes.backdrop} open={true}>
            <WalkthroughStep showAnimation={currentStep !== 0} onComplete={onComplete} onLater={onLaterHandler} onClose={onClose} onNext={onNext} {...currentStepConfig} />
        </Backdrop>
    );
};
