import React, {useCallback} from 'react';
import FormSection from "../FormSection";
import {Grid, MenuItem, TextField} from "@material-ui/core";
import SubTitle from "../auxiliary/SubTitle";
import ToggleSwitch from "scenes/patient/anamnesis/component/input/ToggleSwitch";
import EpaTextField from 'components/hmo-textfield/EpaTextField';
import {useTranslation} from "react-i18next";
import {Field} from "formik";
import PatientSidebarDatePicker from "scenes/patient/patient-sidebar/DatePicker";
import {isContactPerson} from 'domain/User.model';

const GeneralForm = ({form, patientData = {}, setUserDetails, user, readonly}) => {
    const {t} = useTranslation();
    const lang = useCallback((name) => t(`anamnesis.general.${name}`), [t]);
    const disabled = isContactPerson(user) || readonly;
    const setBirthDate = (name, value) => {
        setUserDetails({[name]: value});
    }
    return (
            <FormSection title={lang('title')}>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <PatientSidebarDatePicker
                                name="dateOfBirth"
                                disabled={disabled}
                                onChange={setBirthDate}
                                label={t("case.date-of-birth")}
                                date={patientData.dateOfBirth}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Field
                                disabled={readonly}
                                name="landOfBirth"
                                type="text"
                                label={lang('landOfBirthLabel')}
                                as={EpaTextField}
                        />
                    </Grid>

                </Grid>

                <Grid container spacing={1}>

                    <Grid item xs={3}>
                        <TextField
                                select
                                fullWidth
                                label={t("case.sex")}
                                InputLabelProps={{shrink: true}}
                                name="bioSex"
                                disabled={disabled}
                                variant="standard"
                                onChange={event => setUserDetails({bioSex: event.target.value})}
                                value={patientData.bioSex}
                        >
                            <MenuItem key="MAN" value="MAN">
                                {t("global.male-bio-sex")}
                            </MenuItem>
                            <MenuItem key="WOMAN" value="WOMAN">
                                {t(`global.female-bio-sex`)}
                            </MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={2}>
                        <Field
                                disabled={readonly}
                                name="height"
                                type="number"
                                label={lang('heightLabel')}
                                as={EpaTextField}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <Field
                                name="weight"
                                disabled={readonly}
                                type="number"
                                label={lang('weightLabel')}
                                as={EpaTextField}
                                inputProps={{
                                    min: 1,
                                    max: 400
                                }}
                        />
                    </Grid>

                    {!readonly && <Grid item xs={5} style={{textAlign: "center", color: '#777777', opacity: 0.54, fontWeight: 500}}>
                        {lang('answerText')}
                    </Grid>}

                </Grid>

                {
                    form.values?.['gender'] !== null && form.values?.['gender'] === 'FEMALE' &&
                    <Grid container spacing={1}>
                        <Grid container spacing={1}>

                            <Grid item xs={3}>
                                <SubTitle>
                                    {lang('hasMenopauseQuestion')}
                                </SubTitle>
                            </Grid>
                            <Grid item xs={9}>

                                <Field
                                        name="hasMenopause"
                                        disabled={readonly}
                                        type="text"
                                        as={ToggleSwitch}
                                        form={form}
                                />

                            </Grid>
                        </Grid>
                    </Grid>
                }

                <Grid container spacing={1}>
                    <Grid item xs={7}>
                        <Field
                                disabled={readonly}
                                name="houseDoctor"
                                type="text"
                                label={lang('houseDoctorLabel')}
                                as={EpaTextField}
                        />
                    </Grid>
                </Grid>

            </FormSection>

    );
};

export default GeneralForm;
