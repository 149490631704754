import React, { useCallback, useEffect, useState } from "react";
import { Box, Checkbox, Grid, TextField } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import { Field } from "formik";
import CommonDialog from "components/common-dialog/CommonDialog";
import {
    useCreateMedicationPlanMutation,
    useUpdateMedicationPlanMutation,
} from "scenes/patient/medication-plan/MedicationPlanApi";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { validationError } from "scenes/patient/anamnesis/component/config/ValidationSchema";
import { NewMedicationWalkthrough } from "scenes/walkthrough/newMedicationWalkthrough/newMedicationWalkthrough";
import Intake from "./Intake";
import MedicationTimeFrame from "./MedicationTimeFrame";
import MedicationDescription from "./MedicationDescription";

const DialogDivider = () => {
    return (
        <Divider
            flexItem
            light
            orientation="horizontal"
            variant="fullWidth"
            style={{ width: "100%", marginBottom: "10px", marginTop: "10px" }}
        />
    );
};

const MedicationFormDialog = ({ open, data, initialValues, handleClose, ...props }) => {
    const { t } = useTranslation();
    const { patientId } = useParams();
    const lang = useCallback((name) => t(`medication.${name}`), [t]);

    const [createItem, { isSuccess: isCreatingItemSuccessful }] = useCreateMedicationPlanMutation();
    const [updateItem, { isSuccess: isUpdatingItemSuccessful }] = useUpdateMedicationPlanMutation();

    const [dialogProperties] = useState({
        fullWidth: true,
        maxWidth: "md",
    });

    const handleMedicineTypeChange = useCallback((event, value, form) => {
        form.setFieldValue("medicinePlanType", value);
    }, []);

    const handleSave = useCallback(
        (formValues) => {
            if (formValues.id != null) {
                updateItem({
                    ...formValues,
                    patientId,
                    selfMedication: formValues.selfMedication ?? false,
                });
            } else {
                createItem({
                    ...formValues,
                    patientId,
                    selfMedication: formValues.selfMedication ?? false,
                });
            }
        },
        [createItem, updateItem],
    );

    useEffect(() => {
        if (isCreatingItemSuccessful || isUpdatingItemSuccessful) {
            handleClose(true);
        }
    }, [isCreatingItemSuccessful, isUpdatingItemSuccessful, handleClose]);

    return (
        <>
            {open && <NewMedicationWalkthrough />}
            <CommonDialog
                dialogTitle={initialValues.id != null ? lang("editTitle") : lang("createTitle")}
                dialogProps={dialogProperties}
                handleClose={handleClose}
                initialValues={initialValues}
                {...{ open }}
                {...props}
                {...{ handleSave }}
            >
                {(form) => (
                    <>
                        <Grid container spacing={1}>
                            <MedicationDescription {...{ lang, form }} />

                            <DialogDivider />

                            <MedicationTimeFrame {...{ lang, form }} />

                            <DialogDivider />

                            <Intake {...{ lang, form, handleMedicineTypeChange }} />

                            <DialogDivider />

                            <Grid item xs={12}>
                                <Field
                                    name="hint"
                                    type="text"
                                    fullWidth
                                    walkthrough-element="medicine-hint"
                                    label={lang("hint")}
                                    as={TextField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...validationError(form, "hint")}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box
                                    display={"flex"}
                                    flexDirection={"row"}
                                    justifyContent={"space-between"}
                                    width={"100%"}
                                    alignItems={"center"}
                                >
                                    <Field
                                        name="reasonForTreatment"
                                        type="text"
                                        walkthrough-element="medicine-indication"
                                        label={lang("reasonForTreatment")}
                                        as={TextField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        {...validationError(form, "reasonForTreatment")}
                                    />

                                    <Box>
                                        {lang("selfMedication")}
                                        <Field
                                            name="selfMedication"
                                            type="checkbox"
                                            label={lang("selfMedication")}
                                            variant="outlined"
                                            color="primary"
                                            walkthrough-element="medicine-self-medication"
                                            as={Checkbox}
                                            {...validationError(form, "selfMedication")}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </>
                )}
            </CommonDialog>
        </>
    );
};

export default MedicationFormDialog;
