import {Box, Button, TextField, MenuItem, Tabs, Tab} from "@material-ui/core";
import moment from "moment";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    DateTextField,
    Field
} from "scenes/patient/program-view/ProgramStepRenderer/ProgramStepRendererCommonComponents";
import DatePicker from "components/calendar/DatePicker";
import {EXACT, RECURRING} from 'domain/ProgramStepDateType.model';
import {MaskedTimeInput} from "scenes/patient/program-view/ProgramStepRenderer/MaskedTimeInput";
import {anyPropertyChanged} from 'utils/Utils';
import StatusSwitch from './StatusSwitch';
import TrueDateSelectorDialog from './TrueDateSelectorDialog';
import {cond, eq, flip} from "lodash/fp";
import {
    EditingTaskRulesSettingsPanel, NotificationsTaskSettingsPanel,
    SchedulingTaskSettingPanel
} from "scenes/managed-care/component/task-creation-settings-panels";
import {SettingsTabs} from "scenes/managed-care/component/ProgramTemplateStepRendererUpsertMode";
import Flex from 'components/grid/Flex';

export const NO_TIME_DEFINED = "00:00";

export function toTime(date) {
    if(!date) return '';
    const momentTime = moment(date);
    if (!momentTime.isValid()) return '';
    const time = momentTime.format('HH:mm');
    return date && time !== NO_TIME_DEFINED ? time : '';
}

export const ProgramStepRendererEditMode = (props) => {
    const {saveStep, cancelStepHandler, otherStepsInProgram, dependencyIsMissing} = props;
    const [haveToFixDependency] = useState(dependencyIsMissing);
    const [step, setStep] = useState(props.step);
    const [isDateSelectorOpen, setIsDateSelectorOpen] = useState(false);
    const [time, setTime] = useState(toTime(step.date));
    const {t} = useTranslation();
    const isEditable = true;
    const [selectedSettingsTab, setSelectedSettingsTab] = useState(SettingsTabs.EditingRules);

    const isChanged = anyPropertyChanged(step, props.step) || (time !== toTime(step.date));

    const onChange = event => {
        setStep({...step, [event.target.name]: event.target.value});
    }

    const toggleStatus = (event, value) => {
        if(!value || moment(step.date).isSame(moment(), 'day')){
            saveStep({
                ...step,
                isCompleted: value
            })
        } else {
            setIsDateSelectorOpen(true);
        }
    }

    const onDateChange = (date) => {
        date = moment(date).set({hour: '00', minute: '00'});
        const dateType = step.dateType === RECURRING ? step.dateType : EXACT;
        setStep({
            ...step,
            dateType,
            dateTimeSpan: step.dateType === RECURRING ? step.dateTimeSpan : null,
            dateTimeSpanValue: step.dateType === RECURRING ? step.dateTimeSpanValue : null,
            date
        });
        setTime('');
    }

    const save = () => {
        let date = step.date;
        if (time) {
            const [hours, minutes] = time.split(":");
            const paddedHours = hours ? hours.padEnd(2, "0") : "00";
            const paddedMinutes = minutes ? minutes.padEnd(2, "0") : "00";
            date = moment(date).set({hour: paddedHours, minute: paddedMinutes});
        }
        saveStep({
            ...step,
            date
        });
    }

    return (
            <Box style={{padding: '10px'}}>
                <Box display="flex" width={"100%"}>
                        <Box
                                flex={"0 0 240px"}
                                display="flex"
                                flexDirection="column"
                                justifyContent="flex-start"
                                marginRight={1}
                        >
                            <Box display={"flex"} flexDirection={"row"} marginBottom={1}>
                                <DatePicker
                                        {...{
                                            smallLabel: true,
                                            style: {flex: "0 1 130px"},
                                            value: step.date,
                                            name: "date",
                                            onChange: onDateChange,
                                            TextFieldComponent: DateTextField,
                                        }}
                                />

                                <TextField
                                        disabled={!step.date}
                                        name="time"
                                        onChange={setTime}
                                        label={t("global.time")}
                                        value={time}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        InputProps={{
                                            inputComponent: MaskedTimeInput,
                                        }}
                                />

                            </Box>
                            <StatusSwitch {...{status: step.isCompleted, toggleStatus}}/>
                        </Box>
                        <Box flex={1}>
                            <Box display="flex">
                                <Box flex={2} marginRight="20px">
                                    <Field
                                            {...{
                                                isEditable,
                                                disabled: !step.skippable,
                                                value: step.description,
                                                label: t("global.description"),
                                                name: "description",
                                                onChange,
                                            }}
                                    />
                                </Box>
                                <Box flex={1}>
                                    <Field
                                            {...{
                                                isEditable,
                                                value: step.place,
                                                label: t("global.wo"),
                                                name: "place",
                                                onChange,
                                            }}
                                    />
                                </Box>
                            </Box>
                            <Box marginTop={1}>
                                <Field
                                        {...{
                                            isEditable,
                                            value: step.comment,
                                            label: t("global.comment"),
                                            name: "comment",
                                            onChange,
                                        }}
                                />
                            </Box>
                            {
                                haveToFixDependency &&
                                <Box>
                                    <TextField
                                            {...{
                                                style: {width: '100%', marginTop: '10px'},
                                                value: step.dependsOnTaskId ?? '',
                                                select: true,
                                                label: t("program.afterTask"),
                                                name: "dependsOnTaskId",
                                                onChange,
                                                error: dependencyIsMissing,
                                                InputLabelProps: {
                                                    shrink: true,
                                                }
                                            }}
                                    >
                                        {
                                            otherStepsInProgram.map((otherStep) =>
                                                    <MenuItem value={otherStep.id}>
                                                        <span style={{fontWeight: 'bold'}}>{otherStep.position}:</span>
                                                        {` ${otherStep.description || ''}`}
                                                    </MenuItem>
                                            )
                                        }
                                    </TextField>
                                </Box>
                            }

                        </Box>
                </Box>
                <Flex item container column>
                    <Tabs value={selectedSettingsTab} onChange={flip(setSelectedSettingsTab)} indicatorColor="primary">
                        {/*<Tab value={SettingsTabs.Scheduling} label={t('program.scheduling')} />*/}
                        <Tab value={SettingsTabs.EditingRules} label={t('program.editing-rules')} />
                        <Tab value={SettingsTabs.Notifications} label={t('program.notifications')} />
                    </Tabs>
                    <div style={{borderTop: '1px solid silver'}}></div>
                    <div style={{ display: "flex", flexDirection: "column", marginLeft: '28px', minHeight: '150px' }}>
                        {cond([
                            // [eq(SettingsTabs.Scheduling), () => <SchedulingTaskSettingPanel setStep={setStep} step={step}
                            //                                                                 onChange={onChange}
                            //                                                                 otherStepsInProgram={otherStepsInProgram} />],
                            [eq(SettingsTabs.EditingRules), () => <EditingTaskRulesSettingsPanel setStep={setStep} step={step}/>],
                            [eq(SettingsTabs.Notifications), () => <NotificationsTaskSettingsPanel setStep={setStep} step={step}
                                                                                                   emailTemplateEntityType={'PROGRAM_STEP'}/>],
                            [() => true, () => null],
                        ])(selectedSettingsTab)}

                    </div>
                </Flex>
                <Box justifyContent={"flex-end"} display={"flex"} flex={"0 1 auto"} sx={{paddingTop: 10}}>
                    <Button onClick={cancelStepHandler} variant={"contained"} size="small" style={{marginRight: 5}}>
                        {t("global.cancel")}
                    </Button>
                    <Button variant={"contained"} size="small" color={"primary"} onClick={save}
                            disabled={!isChanged}>
                        {t("global.save")}
                    </Button>
                </Box>
                {
                    isDateSelectorOpen &&
                    <TrueDateSelectorDialog {...{step, saveStep: setStep, onClose: ()=>setIsDateSelectorOpen(false)}}/>
                }
            </Box>
    );
};
