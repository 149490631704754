import {
    Box,
    Button,
    Tab,
    Tabs,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Field } from "scenes/patient/program-view/ProgramStepRenderer/ProgramStepRendererCommonComponents";
import { useState } from "react";
import { anyPropertyChanged } from "utils/Utils";
import {
    EditingTaskRulesSettingsPanel,
    SchedulingTaskSettingPanel,
    NotificationsTaskSettingsPanel,
} from "./task-creation-settings-panels";
import { cond, flip, eq } from "lodash/fp";

export const SettingsTabs = {
    Scheduling: 1,
    EditingRules: 2,
    Notifications: 3,
};

export const ProgramTemplateStepRendererUpsertMode = (props) => {
    const { cancelStep, saveStep, otherStepsInProgram } = props;
    const [step, setStep] = useState(props.step);
    const onChange = (event) => {
        setStep({ ...step, [event.target.name]: event.target.value });
    };
    const isChanged = anyPropertyChanged(step, props.step);
    const { t } = useTranslation();
    const [selectedSettingsTab, setSelectedSettingsTab] = useState(SettingsTabs.Scheduling);
    return (
        <Box position={"relative"} display="flex" flexDirection="column" style={{ gap: "10px", padding: "10px" }}>
            <Box>
                <Field
                    {...{
                        isEditable: true,
                        value: step?.description,
                        label: t("global.description"),
                        name: "description",
                        onChange,
                    }}
                />
            </Box>
            <Field
                {...{
                    isEditable: true,
                    value: step.place,
                    label: t("global.wo"),
                    name: "place",
                    onChange,
                }}
            />
            <Box>
                <Field
                    {...{
                        isEditable: true,
                        value: step.comment,
                        label: t("global.comment"),
                        name: "comment",
                        onChange,
                    }}
                />
            </Box>

            <Tabs value={selectedSettingsTab} onChange={flip(setSelectedSettingsTab)} indicatorColor="primary">
                <Tab value={SettingsTabs.Scheduling} label={t('program.scheduling')} />
                <Tab value={SettingsTabs.EditingRules} label={t('program.editing-rules')} />
                <Tab value={SettingsTabs.Notifications} label={t('program.notifications')} />
            </Tabs>
            <div style={{borderTop: '1px solid silver'}}></div>
            <div style={{ display: "flex", flexDirection: "column", marginLeft: '28px', minHeight: '150px' }}>
                {cond([
                    [eq(SettingsTabs.Scheduling), () => <SchedulingTaskSettingPanel setStep={setStep} step={step}
                                                                                    onChange={onChange}
                                                                                    otherStepsInProgram={otherStepsInProgram} />],
                    [eq(SettingsTabs.EditingRules), () => <EditingTaskRulesSettingsPanel setStep={setStep} step={step} isTemplate={true}/>],
                    [eq(SettingsTabs.Notifications), () => <NotificationsTaskSettingsPanel setStep={setStep} step={step} isTemplate={true}
                                                                                           emailTemplateEntityType={'PROGRAM_STEP_TEMPLATE'}/>],
                    [() => true, () => null],
                ])(selectedSettingsTab)}

            </div>

            <Box justifyContent={"flex-end"} display={"flex"} flex={"0 1 auto"} sx={{ paddingTop: 10 }}>
                <Button variant={"contained"} size="small" style={{ marginRight: 5 }} onClick={cancelStep}>
                    {t("global.cancel")}
                </Button>
                <Button
                    variant={"contained"}
                    size="small"
                    color={"primary"}
                    onClick={() => {
                        saveStep({ ...step });
                    }}
                    disabled={!isChanged}
                >
                    {t("global.save")}
                </Button>
            </Box>
        </Box>
    );
};
