import { useTranslation } from "react-i18next";
import { isManager } from "domain/User.model";
import { anyPropertyChanged } from "utils/Utils";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import { useState } from "react";
import fp from "lodash/fp";
import { ProgramStepRenderer } from "./ProgramStepRenderer/ProgramStepRenderer";
import { EditReoccurringStepDialog } from "scenes/patient/EditReoccurringStepDialog";

const doesStepHaveDependentSteps = (step, steps) => {
    return steps.some((item) => item.dependsOnTaskId === step.id);
};

const ProgramStep = (props) => {
    const {
        step,
        position,
        user,
        saveStep,
        editableId,
        setEditableId,
        deleteStep,
        dragHandleProps,
        otherStepsInProgram,
        isNew,
        markStepAsSeen,
        isStepCollapsed,
        toggleCollapse,
        isLoading,
        isProgramSuspended,
        refreshPrograms,
    } = props;
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isConfirmCancelEditOpen, setIsConfirmCancelEditOpen] = useState(false);
    const [isConfirmOnEmptyValuesCancelOpen, setConfirmOnEmptyValuesCancelOpen] = useState(false);

    const { t } = useTranslation();
    const isEditable = isManager(user) && editableId === step.id;
    const [isEditReoccurringStepDialogOpen, setIsEditReoccurringStepDialogOpen] = useState(false);
    const isStepSaveDisabled = editableId || step.isCompleted;
    const isDraggable = isManager(user);

    const cancelStep = () => {
        setEditableId("");
    };

    const toggleDelete = () => {
        setIsDeleteDialogOpen(!isDeleteDialogOpen); // triggers modal
    };

    const isStepDependent = doesStepHaveDependentSteps(step, otherStepsInProgram);

    return (
        <>
            <ProgramStepRenderer
                {...{
                    position,
                    step,
                    isEditable,
                    toggleDelete,
                    isDraggable,
                    dragHandleProps,
                    editableId,
                    isStepSaveDisabled,
                    cancelStep,
                    setEditableId,
                    saveStep,
                    openRepeatedTaskConfigurationModal: () => {
                        if (isManager(user)) {
                            setEditableId(step?.id);
                            setIsEditReoccurringStepDialogOpen(true);
                        }
                    },
                    otherStepsInProgram,
                    isNew,
                    isStepCollapsed,
                    markStepAsSeen,
                    toggleCollapse,
                    isProgramSuspended,
                    isLoading,
                    refreshPrograms,
                }}
            />

            <EditReoccurringStepDialog
                {...{
                    isOpen: isEditReoccurringStepDialogOpen,
                    step,
                    saveStep,
                    setIsEditReoccurringStepDialogOpen,
                    cancelStep,
                }}
            />

            <ConfirmationDialog
                {...{
                    dialogOpen: isDeleteDialogOpen,
                    onDialogClose: toggleDelete,
                    onConfirm: () => {
                        toggleDelete();
                        deleteStep(step.id);
                    },
                    confirmationTextKey: isStepDependent
                        ? "global.delete-step-with-dependencies"
                        : "global.delete-step", // ok here should be different text if step is a dependency of other steps, we should calculate if step is a dependency of other steps
                }}
            />

            <ConfirmationDialog
                {...{
                    dialogOpen: isConfirmOnEmptyValuesCancelOpen,
                    onDialogClose: () => {
                        cancelStep();
                        setConfirmOnEmptyValuesCancelOpen(false);
                        deleteStep(step.id);
                    },
                    onConfirm: () => {
                        setConfirmOnEmptyValuesCancelOpen(false);
                    },
                    confirmationTextKey: t("program.cancelEditOfEmptyStep"),
                    alternativeCancelLabel: t("program.deleteNewStep"),
                    alternativeConfirmLabel: t("program.continueEditingStep"),
                }}
            />

            <ConfirmationDialog
                {...{
                    dialogOpen: isConfirmCancelEditOpen,
                    onDialogClose: () => {
                        setIsConfirmCancelEditOpen(false);
                    },
                    onConfirm: () => {
                        cancelStep();
                        setIsConfirmCancelEditOpen(false);
                    },
                    confirmationTextKey: t("global.exit-without-saving"),
                    alternativeCancelLabel: t("global.cancel"),
                    alternativeConfirmLabel: t("global.continue"),
                }}
            />
        </>
    );
};

export default ProgramStep;
