export const htmlBlack = 'black'; //rgb(0, 0, 0)
export const htmlWhite = '#ffffff'; //rgb(255, 255, 255)
export const htmlLightGrey = '#D3D3D3'; //rgb(211, 211, 211)
export const htmlDarkGrey = '#A0A0A0'; //rgb(211, 211, 211)
export const grey = '#8E8E8E'; //rgb(142, 142, 142)
export const htmlGrey = '#808080'; //rgb(128, 128, 128)
export const grey240 = '#F0F0F0'; //rgb(128, 128, 128)
export const grey153 = '#999999'; //rgb(128, 128, 128)
export const grey144 = '#909090'; //rgb(128, 128, 128)
export const htmlGreen = '#008000'; //rgb(0, 128, 0)
export const htmlOrange = '#FFA500'; //rgb(255,165,0)
export const htmlRed = '#ff0000'; //rgb(255, 0, 0)
export const htmlBlue = '#0000FF'; //rgb(0, 0, 255)
export const materialBlue = '#4B8AC9';
export const labelColor = 'rgba(74, 74, 74, 0.6)';
export const mainBackgroundColor = '#4A4A4A';
export const lightCharcoalGrey = '#646464';
export const white = '#FFFFFF';
export const borderColor = '#3E4D5A';
export const fafaWhite = '#FAFAFA';
export const headerBlue = "#2D3F6F";

export const rainForest = '#016F64'; //rgb(1, 111, 100) sidebar
export const swanDown = '#D8F0EE';
export const niagara = '#049F90'; //rgb(4,159,144) sidebar
export const persianGreen = '#00AE9E'; //rgb(0, 174, 158) header
export const persianGreen2 = '#049F90'; //rgb(0, 174, 158) header
// export const iceberg = '#E0F5F3'; //RGB: 224, 245, 243 background
export const iceberg = '#f0f0f0'; //RGB: 224, 245, 243 background
export const linkWater = '#D7E0F4'; //RGB: 215, 224, 244 background
export const alabaster = '#FAFAFA'; //rgb(250, 250, 250) background
export const concrete = '#F2F2F2'; //rgb(242, 242, 242) foreground
export const alto = '#DCDCDC'; //RGB: 220, 220, 220 list headers
export const jaffa = '#ED7D31'; //RGB: 237, 125, 49 background
export const oldLace = '#FDF2EA'; //RGB: 253, 242, 234 foreground
export const doveGrey = '#666666'; //RGB: 102, 102, 102 list header font
export const pictonBlue = '#3CAFEF'; //rgb(60, 175, 239) //tag color, avatar
export const laPalmaGreen = '#16aa16'; //rgb(22, 170, 22)
export const lawngreen = '#7CFC00'	//rgb(124,252,0)
export const goldenFiz = '#EDfD31';
export const ghostGrey = '#CED4DA'; //rgb(206, 212, 218)
export const mercuryGrey = '#e5e5e5';
export const dustyGrey = '#9b9b9b'; //rgb(155, 155, 155)
export const sanMarinoBlue = '#3E51B4';
export const silver2 = '#bfbfbf';
export const navyBlue = '#060066';
export const tallPoppyRed = '#bc2c28';
export const porcelain = '#ECF0F1';
export const jaffaOrange = '#ed7d31'; //rgb(237, 125, 49)
export const electricViolet = '#9900FF';
export const mediumPurple = '#8566D9';
export const BlueDianne = '#245B67'

export const danube = '#4A8AC9'; //RGB: 74, 138, 201 cancer, main indication
export const chambray = '#2C5288'; //RGB: 44, 82, 136 cancer, sub indication
export const burntSienna = '#EC6162'; //RGB: 236, 97, 98 heart
export const lipstick = '#C7017F'; //RGB: 199, 1, 127 Ortho
export const funGreen = '#009933'; //RGB: 0, 153, 51 Payment, assigned
export const guardsmanRed = '#CC0000'; //RGB: 204, 0, 0 delete icon
export const guardsmanRed2 = '#C00000'; //RGB: 192, 0, 0 cancel red
export const monteCarlo = '#8FD8D4'; //RGB: 143, 216, 212 TNE, Document background
export const silver = '#bdbdbd'; //rgb(189, 189, 189)

export const transparentBlack03 = 'rgba(0, 0, 0, 0.3)';
export const transparentBlack02 = 'rgba(0, 0, 0, 0.2)';
export const transparentBlack054 = 'rgba(0, 0, 0, 0.54)';
export const transparentBlack087 = 'rgba(0, 0, 0, 0.87)';

export const sideBarColor = '#245B67';
export const marinerBlue = '#1e6ee2'; //rgb(30, 110, 226) //action urgent prio
export const normal = silver;
export const critical = tallPoppyRed;
export const medium = jaffaOrange;
export const patientThemePrimary = BlueDianne;
