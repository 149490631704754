export const DropZoneAction = Object.freeze({
    SetDropZoneRef: "SET_DROP_ZONE_REF",
    SetAnamnesisQuestionId: "SET_ANAMNESIS_QUESTION_ID",
    setFilesData: "SET_FILES_DATA",
});
export const dropZoneInitialState = {
    dropZoneRef: null,
    anamnesisQuestionId: null,
    filesData: []
};

const DropZoneReducer = (state = dropZoneInitialState, action) => {
    switch (action.type) {
        case DropZoneAction.SetDropZoneRef: {
            return {
                ...state,
                dropZoneRef: action.payload
            };
        }
        case DropZoneAction.SetAnamnesisQuestionId: {
            return {
                ...state,
                anamnesisQuestionId: action.payload
            };
        }
        case DropZoneAction.setFilesData: {
            return {
                ...state,
                filesData: action.payload
            };
        }
        default:
            return state;
    }
};

export default DropZoneReducer;
