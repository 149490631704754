import { TextField, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
export const TermRenderer = ({ config, rule, idx, handleUpdateRule }) => {
    const { t } = useTranslation();
    return (
        <Box key={idx} display="flex" flexDirection="row" style={{ gap: "10px" }}>
            <TextField
                style={{ width: config.width ? config.width + "px" : undefined }}
                size="small"
                InputLabelProps={{ shrink: true }}
                label={config.compact ? t(config.label) || rule.properties.field : ""}
                value={rule.properties.value}
                onChange={(e) => {
                    handleUpdateRule(idx, {
                        ...rule.properties,
                        value: e.target.value,
                    });
                }}
            />
        </Box>
    );
};
