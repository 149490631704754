import React, {Component} from 'react';
import {
    withStyles,
    Typography,
    IconButton,
    Tabs,
    Tab,
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {persianGreen, htmlWhite, htmlBlack, htmlGrey} from 'components/colors/Colors';
import * as colors from 'components/colors/Colors';
import {CLINICS_EDIT} from 'routes/routes';
import {Trans, withTranslation} from 'react-i18next';
import HmoInputWithAdornment from 'components/input/HmoInputWithAdornment';
import _ from 'lodash';
import {Search, CloseOutlined as CloseIcon} from "@material-ui/icons";
import {connect} from 'react-redux';
import connector from './ClinicsList.connect';
import ClinicTile from './ClinicTile';
import ClinicHeader from './ClinicHeader';
import {stringGet} from 'utils/Utils';
import ClinicSidebar from './ClinicSidebar';
import Tag from 'components/tags/Tag';
import update from 'immutability-helper';
import Sidebar from 'components/sidebar/Sidebar';
import {withRouter} from 'utils/Route.utils';
import HmoFilledButton from 'components/button/HmoFilledButton';
import NewClinicDialog from './NewClinicDialog';

const styles = theme => ({
    tabulatorRoot: {
        zIndex: 6,
    },
    tabIndicator: {
        backgroundColor: htmlBlack,
    },
    tabSelected: {
        fontSize: 18,
        fontWeight: 'bold',
        color: 'black !important',
    },
    tabRoot: {
        textTransform: 'uppercase',
        color: persianGreen,
    },
    expansionTitle: {
        fontWeight: 'bold',
        fontSize: 14
    },
    title: {
        color: colors.transparentBlack054,
        fontSize: 12,
        textTransform: 'uppercase'
    },
    titleBarRoot: {
        zIndex: 7,
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        minHeight: 45,
        color: htmlWhite,
    },
    clinicTileContainer: {
        overflow: 'auto',
        overflowScrolling: 'touch',
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        top: 0,
        WebkitUserSelect: 'none', /* Safari */
        msUserSelect: 'none', /* IE 10+ and Edge */
        userSelect: 'none', /* Standard syntax */
    },
    filterTitle: {
        fontSize: 15,
        whiteSpace: 'nowrap',
        width: '100%',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        marginRight: 10,
        color: htmlGrey
    }
});

const Title = (props) => {
    const {classes} = props;
    return (
            <Flex item={'0 1 auto'} container style={{backgroundColor: colors.niagara, paddingLeft: 20, marginLeft: 60}}
                  alignItems={'center'} className={classes.titleBarRoot}>
                <Typography style={{textTransform: 'uppercase', color: colors.htmlWhite}} variant={'h6'}>
                    <Trans i18nKey={'global.clinics'}/>
                </Typography>
            </Flex>
    );
};

export class ClinicsList extends Component {

    state = {
        clinicsType: '',
        positiveSearch: '',
        selectedClinic: {},
        zoom: 5,
        googleApi: {},
        selectedCertifications: [],
        selectedSubIndications: [],
        isNewClinicDialogOpen: false
    };

    changeActivityTab = (event, value) => {
        this.props.setActivityTab(value);
    };

    toggleNewClinicDialogOpen = () =>{
        this.setState({isNewClinicDialogOpen: !this.state.isNewClinicDialogOpen});
    }

    handleDoubleClick = clinic => {
        this.props.navigate(CLINICS_EDIT.pathWithId(clinic.businessId));
    };

    selectClinic = clinic => {
        this.setState({selectedClinic: clinic});
    };

    freeTextFilter = clinic => {
        return this.state.positiveSearch === ''
                || clinic.name.toLowerCase().includes(this.state.positiveSearch.toLowerCase())
                || stringGet(clinic, 'city', '').toLowerCase().includes(this.state.positiveSearch.toLowerCase())
                || stringGet(clinic, 'zipCode', '').toLowerCase().includes(this.state.positiveSearch.toLowerCase())
                || stringGet(clinic, 'street', '').toLowerCase().includes(this.state.positiveSearch.toLowerCase())
    };

    subIndicationFilter = clinic => {
        return this.state.selectedSubIndications.length === 0
                ? true
                : _.every(this.state.selectedSubIndications || [], item => (clinic.subIndications || []).includes(item))
    };

    certificationFilter = clinic => {
        return this.state.selectedCertifications.length === 0
                ? true
                : _.every(this.state.selectedCertifications || [],
                        item => (clinic.centers
                                .reduce((accumulator, center) => ([...accumulator, ...center.certifications]), []) || [])
                                .includes(item))
    };

    activityTabFilter = clinic => {
        return this.props.activityTab === 'OTHER'
                ? !clinic.indications || clinic.indications.length === 0
                : (clinic.indications || []).find(indication => indication === this.props.activityTab)
    }

    toggleCertification = certification => {
        const index = this.state.selectedCertifications.findIndex(cert => cert === certification);
        if (index === -1) {
            this.setState({
                selectedCertifications: update(this.state.selectedCertifications, {
                    $push: [certification]
                })
            })
        } else {
            this.setState({
                selectedCertifications: update(this.state.selectedCertifications, {
                    $splice: [[index, 1]]
                })
            })
        }
    };

    toggleSubIndication = subIndication => {
        const index = this.state.selectedSubIndications.findIndex(subInd => subInd === subIndication);
        if (index === -1) {
            this.setState({
                selectedSubIndications: update(this.state.selectedSubIndications, {
                    $push: [subIndication]
                })
            })
        } else {
            this.setState({
                selectedSubIndications: update(this.state.selectedSubIndications, {
                    $splice: [[index, 1]]
                })
            })
        }
    };

    render() {
        const {classes, t: translate, clinics, clinicCertifications, subIndications, activityTab} = this.props;
        const {selectedCertifications, selectedSubIndications, selectedClinic, isNewClinicDialogOpen} = this.state;
        const { changeActivityTab, toggleCertification, toggleSubIndication, toggleNewClinicDialogOpen} = this;

        let filteredClinics = _.sortBy(clinics
                        .filter(this.freeTextFilter)
                        .filter(this.subIndicationFilter)
                        .filter(this.certificationFilter)
                        .filter(this.activityTabFilter)
                , ['name']);

        return <>
            <Sidebar/>
            <Flex item container style={{height: '100vh', marginLeft: 60}}>
                <Title {...{classes}}/>
                <Flex item container style={{marginTop: 45}}>

                    <Flex container item style={{margin: '0 15px'}} column>
                        <Tabs value={activityTab} onChange={changeActivityTab}
                              style={{margin: '0 -15px'}}
                              classes={{indicator: classes.tabIndicator, root: classes.tabulatorRoot}}>
                            <Tab value={'CANCER'}
                                 classes={{
                                     selected: classes.tabSelected,
                                     root: classes.tabRoot
                                 }}
                                 label={translate("global.cancer")}/>
                            <Tab value={'HEART'}
                                 classes={{
                                     selected: classes.tabSelected,
                                     root: classes.tabRoot
                                 }}
                                 label={translate("global.heart")}/>
                            <Tab value={'ORTHOPEDICS'}
                                 classes={{
                                     selected: classes.tabSelected,
                                     root: classes.tabRoot
                                 }}
                                 label={translate("global.orthopedics")}/>
                            <Tab value={'OTHER'}
                                 classes={{
                                     selected: classes.tabSelected,
                                     root: classes.tabRoot
                                 }}
                                 label={translate("global.other")}/>
                        </Tabs>
                        <Flex item={'0 1 auto'} container justifyContent={'space-between'} alignItems={'center'}>
                            <HmoInputWithAdornment
                                    value={this.state.positiveSearch}
                                    placeholder={translate('global.search')}
                                    onChange={event => this.setState({positiveSearch: event.target.value})}
                                    name={'positiveSearch'}
                                    rootStyle={{padding: 0, paddingLeft: 10, width: 420, marginTop: 15}}
                                    startAdornment={<Search/>}
                                    endAdornment={
                                        !_.isEmpty('') &&
                                        <IconButton
                                                style={{
                                                    padding: 3,
                                                    backgroundColor: colors.silver,
                                                    fontSize: 13,
                                                    marginRight: 3
                                                }}
                                                onClick={() => {
                                                }}>
                                            <CloseIcon style={{color: colors.concrete, fontSize: 'inherit'}}/>
                                        </IconButton>
                                    }
                            />
                            <HmoFilledButton onClick={toggleNewClinicDialogOpen}>+ {translate('clinic.add-new')}</HmoFilledButton>
                        </Flex>
                        <Flex item={'0 1 auto'} container column>
                            <Flex item container direction={'column'} padding={4}>
                                <Typography className={classes.filterTitle}>
                                    {translate('case.certification-filter')}
                                </Typography>
                                <Flex item container
                                      style={{flexWrap: 'wrap', backgroundColor: '#e7f9ff', padding: 10}}>
                                    {
                                        clinicCertifications.map((certification, index) => (
                                                <Tag {...{
                                                    key: index,
                                                    selected: selectedCertifications.includes(certification),
                                                    tag: certification,
                                                    toggleSelection: toggleCertification
                                                }}/>
                                        ))
                                    }
                                </Flex>
                            </Flex>
                            <Flex item container direction={'column'} padding={4}>
                                <Typography className={classes.filterTitle}>
                                    {translate('case.indication-filter')}
                                </Typography>
                                <Flex item container
                                      style={{flexWrap: 'wrap', backgroundColor: '#e7f9ff', padding: 10}}>
                                    {
                                        subIndications.map((subIndication, index) => (
                                                <Tag {...{
                                                    key: index,
                                                    selected: selectedSubIndications.includes(subIndication),
                                                    tag: subIndication,
                                                    toggleSelection: toggleSubIndication
                                                }}/>
                                        ))
                                    }
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex item container column>
                            <Typography className={classes.expansionTitle}>
                                {translate("global.total")}: {filteredClinics.length}
                            </Typography>
                            <Flex item container style={{padding: 10, backgroundColor: colors.swanDown}}
                                  direction={'column'}>
                                <ClinicHeader/>
                                <Flex item container column style={{position: 'relative'}}>
                                    <Flex item container column className={classes.clinicTileContainer}>

                                        {
                                            filteredClinics.map(clinic => (
                                                    <ClinicTile key={clinic.businessId} {...{
                                                        clinic,
                                                        isSelected: clinic.businessId === this.state.selectedClinic.businessId,
                                                        selectClinic: () => this.selectClinic(clinic),
                                                        handleDoubleClick: () => this.handleDoubleClick(clinic),
                                                        selectCertificate: toggleCertification,
                                                        selectSubIndication: toggleSubIndication
                                                    }}/>
                                            ))
                                        }
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                    <ClinicSidebar selectedClinic={selectedClinic} style={{height: 'calc(100vh - 45px)'}} disabled/>
                </Flex>
            </Flex>
            {
                isNewClinicDialogOpen &&
                        <NewClinicDialog toggleNewClinicDialogOpen={toggleNewClinicDialogOpen}/>
            }
        </>
    }
}

export default withStyles(styles)(
        connect(connector.mapStateToProps, connector.mapDispatchToProps)(
                withTranslation()(
                        withRouter(ClinicsList)
                )
        )
);
