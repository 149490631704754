import PatientSidebarItem from "./PatientSidebarIem";
import Contacts from "components/contacts/Contacts";
import {useTranslation} from "react-i18next";
const PatientSidebarGrantedAccess = ({fetching, ...props}) => {
    const { t:translate } = useTranslation();
    return (
        <PatientSidebarItem walkthrough-element="grant-access" fetching={fetching} title={translate("global.access")}>
            <Contacts {...props}/>
        </PatientSidebarItem>
    )
}

export default PatientSidebarGrantedAccess;
