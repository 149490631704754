import React from 'react';
import {useContext} from "react";
import {Grid, Tooltip} from "@material-ui/core";
import SubTitle from "../auxiliary/SubTitle";
import {Field} from "formik";
import ToggleSwitch from "./ToggleSwitch";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {AnamnesisContext} from "../AnamnesisFormWrapper";

const BooleQuestion = ({form, fieldName, info, question, lang, margin = '8px 0px 8px 0px', secondary, disabled}) => {
    return (
        <Grid container spacing={0} alignItems={'baseline'}>

            <Grid item xs={9}>
                <SubTitle style={{display: 'flex', margin, fontWeight: secondary ? '400' : 'bold'}}>
                    { lang != null ? lang(`${fieldName}Question`) : question }

                    {
                        ( info != null || (info === true && lang != null) ) &&
                        <>
                            <div style={{marginLeft: 5}}>
                                <Tooltip arrow title={
                                    <div style={{fontSize: '15px'}}>
                                        {lang == null || info !== true ? info : lang(`${fieldName}Info`)}
                                    </div>
                                }>
                                    <InfoOutlinedIcon color={"info"}/>
                                </Tooltip>
                            </div>
                        </>
                    }

                </SubTitle>
            </Grid>
            <Grid item xs={3}>

                <Field
                    style={{justifyContent: 'flex-end'}}
                    disabled={disabled}
                    name={fieldName}
                    type="text"
                    noMargin
                    as={ToggleSwitch}
                    form={form}
                />

            </Grid>
        </Grid>
    );
};

BooleQuestion.propTypes = {};

export default BooleQuestion;
