import {
    updatePatient, updateCase, saveCase, fetchCase, reset, addCaseLog, addNewPatientQuestionLog, confirmSecondOpinion,
    saveCaseSecondOpinion, cloneCase, cancelCase
} from './CaseEdit.action';
import {fetchConfiguration} from 'scenes/case/Case.action';
import {downloadCaseFile} from 'scenes/FileHandler.action';
import {getAlreadyInvitedEmails, createUserDetails} from 'scenes/user-manager/UserManager.action';
import { endpoints } from "scenes/patient/PatientApi";

export default {
    mapStateToProps: (state) => {
        return {
            user: state.mainReducer.user,
            configuration: state.caseReducer.configuration,
            caseManagers: state.caseReducer.caseManagers,
            case: state.caseEditReducer.case,
            caseOnBackend: state.caseEditReducer.caseOnBackend,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            rejectAccessToEntityForInvitedEmail: (payload) => dispatch(endpoints.rejectAccessToEntityForInvitedEmail.initiate(payload)),
            fetchCase: caseBusinessId => dispatch(fetchCase(caseBusinessId)),
            confirmSecondOpinion: caseBusinessId => dispatch(confirmSecondOpinion(caseBusinessId)),
            fetchConfiguration: () => dispatch(fetchConfiguration()),
            updatePatient: update => dispatch(updatePatient(update)),
            updateCase: update => dispatch(updateCase(update)),
            saveCase: caseInput => dispatch(saveCase(caseInput)),
            saveCaseSecondOpinion: caseInput => dispatch(saveCaseSecondOpinion(caseInput)),
            reset: () => dispatch(reset()),
            addCaseLog: (comment, caseBusinessId) => dispatch(addCaseLog(comment, caseBusinessId)),
            addNewPatientQuestionLog: (text, caseBusinessId) => dispatch(addNewPatientQuestionLog(text, caseBusinessId)),
            createUserDetails: (props) => dispatch(createUserDetails(props)),
            downloadCaseFile: (document) => dispatch(downloadCaseFile(document)),
            getAlreadyInvitedEmails: props => dispatch(getAlreadyInvitedEmails(props)),
            cloneCase: caseBusinessId => dispatch(cloneCase(caseBusinessId)),
            cancelCase: (dataDeletionType, caseInput) => dispatch(cancelCase(dataDeletionType, caseInput)),
        };
    }
};
