import * as Action from "./actions";
import { fields } from "../filter-configs/fields.conf";
import { defaultRulePropertiesByWidgetType } from "../filter-configs/defaultRulePropertiesByWidgetType";
import { addRuleToAndGroup, removeRuleFromAndGroup, transformGroupToRule, transformRuleToAndGroup } from "./andGroup";
import { addRuleToOrGroup, transformRuleToOrGroup } from "./orGroup";

function formStructureReducer(state, action) {
    switch (action.type) {
        case Action.SET_INITIAL_STATE:
            return action.payload;
        case Action.ADD_RULE:
            return [...state, action.payload];
        case Action.UPDATE_NODE:
             return updateNestedRule(state, action.indexPath, action.payload);
        case Action.REMOVE_GROUP_RULE:
            return removeNestedGroupRule(state, action.indexPath);
        case Action.ADD_GROUP_RULE:
            const firstRule = state[action.indexPath[0]].properties.value[action.indexPath[1]];
                const firstRuleField = firstRule.properties.field;
                const defaultProperties = getDefaultFieldProperties(firstRuleField);

                // Create the new rule with default properties
                const newRule = {
                    type: "rule",
                    properties: {
                        field: firstRuleField,
                        disabled: false,
                        ...defaultProperties,
                    },
                };
        return insertNestedGroupRule(state, action.indexPath, newRule);
        case Action.REMOVE_RULE:
            return state.filter((_, index) => index !== action.index);
        case Action.TRANSFORM_RULE_TO_OR_GROUP:
            return transformNestedRuleToOrGroup(state, action.indexPath);
        case Action.ADD_AND_RULE:
            const currentNode = state[action.payload.index];
            const nodeType = currentNode.type;
            const newRuleToAddGroup = {
                type: "rule",
                properties: {
                    ...getDefaultFieldProperties(currentNode.properties.field),
                    field: currentNode.properties.field,
                    disabled: currentNode.properties.disabled,
                    exclude: false,
                },
            };

            if (nodeType === "rule" || (nodeType === "group" && currentNode.properties.conjunction !== "and")) {
                const newNode = addRuleToAndGroup(transformRuleToAndGroup(currentNode), newRuleToAddGroup);
                return state.map((node, index) =>
                    index === action.payload.index
                        ? newNode
                        : node);
            }
            if(nodeType === "group") {
                // problem here when adding a rule to a group
                const newNode = addRuleToAndGroup(currentNode, newRuleToAddGroup);
                return state.map((node, index) =>
                    index === action.payload.index
                        ? newNode
                        : node);
            }
            return state;
        case Action.REMOVE_RULE_FROM_AND_GROUP:
            if(state[action.indexPath[0]].properties.value.length === 2) {
                const newNode = transformGroupToRule(removeRuleFromAndGroup(state[action.indexPath[0]], action.indexPath[1]));
                return state.map((node, index) =>
                    index === action.indexPath[0]
                        ? newNode
                        : node);
            } else {
                const newNode = removeRuleFromAndGroup(state[action.indexPath[0]], action.indexPath[1]);
                return state.map((node, index) =>
                    index === action.indexPath[0]
                        ? newNode
                        : node);
                }
        default:
            return state;
    }
}

const getRulePropertiesFromFieldsConfig = (field) => fields[field].defaultRuleProperties;

const getRulePropertiesFromWidgetType = (field) => defaultRulePropertiesByWidgetType[fields[field].widgetType];

// When you crate a new field some widgets expect particular init value structure
// But some fields may have default init properties that take precedence
// Maybe merging of configs is better so in config of fields you can specify only the properties you want to override
const getDefaultFieldProperties = (field) =>
    getRulePropertiesFromFieldsConfig(field) || getRulePropertiesFromWidgetType(field) || {};

function updateNestedRule(state, indexPath, payload) {
    if (indexPath.length === 1) {
        return state.map((rule, index) =>
            index === indexPath[0] ? { ...rule, ...payload } : rule
        );
    }

    return state.map((rule, index) =>
        index === indexPath[0]
            ? {
                  ...rule,
                  properties: {
                      ...rule.properties,
                      value: updateNestedRule(rule.properties.value, indexPath.slice(1), payload),
                  },
              }
            : rule
    );
}

function transformNestedRuleToOrGroup(state, indexPath) {
    if (indexPath.length === 1) {
        return state.map((rule, index) =>
            index === indexPath[0]
                ? addRuleToOrGroup(transformRuleToOrGroup(rule), {
                      type: "rule",
                      properties: {
                          ...getDefaultFieldProperties(rule.properties.field),
                          field: rule.properties.field,
                          disabled: rule.properties.disabled,
                          exclude: false,
                      },
                  })
                : rule
        );
    }

    return state.map((rule, index) =>
        index === indexPath[0]
            ? {
                  ...rule,
                  properties: {
                      ...rule.properties,
                      value: transformNestedRuleToOrGroup(rule.properties.value, indexPath.slice(1)),
                  },
              }
            : rule
    );
}
function removeNestedGroupRule(state, indexPath) {
    const groupIndex = indexPath[0];
    const innerIndex = indexPath[1];

    if (indexPath.length === 2) {
        if (state[groupIndex].properties.value.length === 2) {
            const groupRuleToKeep = state[groupIndex].properties.value
                .filter((_, idx) => idx !== innerIndex)
                .map((rule) => ({
                    ...rule,
                    properties: {
                        ...rule.properties,
                        disabled: state[groupIndex].properties.disabled,
                        exclude: state[groupIndex].properties.exclude,
                    },
                }))[0];
            return state.map((node, idx) =>
                idx === groupIndex ? groupRuleToKeep : node,
            );
        } else {
            return state.map((rule, idx) =>
                idx === groupIndex
                    ? {
                          ...rule,
                          properties: {
                              ...rule.properties,
                              value: rule.properties.value.filter(
                                  (_, idx) => idx !== innerIndex,
                              ),
                          },
                      }
                    : rule,
            );
        }
    }

    return state.map((rule, idx) =>
        idx === groupIndex
            ? {
                  ...rule,
                  properties: {
                      ...rule.properties,
                      value: removeNestedGroupRule(rule.properties.value, indexPath.slice(1)),
                  },
              }
            : rule
    );
}

function insertNestedGroupRule(state, indexPath, newRule) {
    const groupIndex = indexPath[0];
    const innerIndex = indexPath[1];

    if (indexPath.length === 2) {
        const updatedRules = [
            ...state[groupIndex].properties.value.slice(0, innerIndex + 1),
            newRule,
            ...state[groupIndex].properties.value.slice(innerIndex + 1),
        ];

        return state.map((group, idx) =>
            idx === groupIndex
                ? {
                      ...group,
                      properties: {
                          ...group.properties,
                          value: updatedRules,
                      },
                  }
                : group,
        );
    }

    return state.map((group, idx) =>
        idx === groupIndex
            ? {
                  ...group,
                  properties: {
                      ...group.properties,
                      value: insertNestedGroupRule(group.properties.value, indexPath.slice(1), newRule),
                  },
              }
            : group,
    );
}


export default formStructureReducer;
