import * as actionTypes from './Chat.action.types';

export function getChatLog(entityType, entityId){
    return {
        type: actionTypes.UPSERT_CHAT_LOG,
        payload: {
            client: 'api',
            request: {
                method: 'get',
                url: `chat/${entityType}/${entityId}`,
            }
        }
    };
}

export function upsertChatLog(chatLog){
    return {
        type: actionTypes.UPSERT_CHAT_LOG,
        payload: {
            client: 'api',
            request: {
                method: 'post',
                url: `chat`,
                data: chatLog
            }
        }
    };
}
