import * as actionTypes from './ManagedCare.action.types';
import HMOApi, {PROGRAMS} from 'store/api/HMOApi'

export function getPatientList() {
    return {
        type: actionTypes.GET_PATIENT_LIST,
        payload: {
            client: 'api',
            request: {
                method: 'get',
                url: `program/patients`,
            }
        }
    };
}

//NOTE: Also present as useFetchProgramTemplatesQuery in ProgramApi, do not use this one for new behaviour
//OBSOLETE
export function getProgramTemplates() {
    return {
        type: actionTypes.GET_PROGRAM_TEMPLATE_LIST,
        payload: {
            client: 'api',
            request: {
                method: 'get',
                url: `program/templates`,
            }
        }
    };
}

export function getCareProvidersProgramTemplates() {
    return {
        type: actionTypes.GET_CARE_PROVIDER_PROGRAM_TEMPLATE_LIST,
        payload: {
            client: 'api',
            request: {
                method: 'get',
                url: `program/templates?type=CARE_PROVIDER`,
            }
        }
    };
}

export function saveProgramTemplate(programTemplate) {
    return {
        type: actionTypes.SAVE_PROGRAM_TEMPLATE,
        payload: {
            client: 'api',
            request: {
                method: 'post',
                url: `program/template`,
                data: programTemplate
            }
        }
    };
}

export function saveProgramTemplates(programTemplates) {
    return {
        type: actionTypes.SAVE_PROGRAM_TEMPLATE,
        payload: {
            client: 'api',
            request: {
                method: 'post',
                url: `program/template/batch`,
                data: programTemplates
            }
        }
    };
}

export function deleteProgramTemplates(listOfIds) {
    return {
        type: actionTypes.SAVE_PROGRAM_TEMPLATE,
        payload: {
            client: 'api',
            request: {
                method: 'post',
                url: `program/template/batch-delete`,
                data: listOfIds
            }
        }
    };
}

export function getProgramTemplateForCareProvider(id) {
    return {
        type: actionTypes.SAVE_PROGRAM_TEMPLATE,
        payload: {
            client: 'api',
            request: {
                method: 'get',
                url: `program/templates-by-care-provider/${id}`,
            }
        }
    };
}

//OBSOLETE: use the useUpsertCareProgramMutation from ProgramApi.js
export function upsertCareProgram(careProgram) {
    return dispatch => {
        return dispatch({
            type: actionTypes.UPSERT_CARE_PROGRAM,
            payload: {
                client: 'api',
                request: {
                    method: 'post',
                    url: `program`,
                    data: careProgram
                }
            }
        }).then(response => {
            //TODO LM: This can be removed once all program mutations happen through rtk
            dispatch(HMOApi.util.invalidateTags([PROGRAMS]));
            return response;
        })
    }
}

export function getPatientOverview(patientId) {
    return {
        type: actionTypes.GET_PATIENT_OVERVIEW,
        payload: {
            client: 'api',
            request: {
                method: 'get',
                url: `program/patient/${patientId}`,
            }
        }
    };
}

export function newProgramTemplate({parentTemplateId, careProviderId, name, shortName}) {
    return {
        type: actionTypes.NEW_PROGRAM_TEMPLATE,
        payload: {
            client: 'api',
            request: {
                method: 'put',
                url: `program/template`,
                data: {
                    parentTemplateId,
                    careProviderId,
                    name,
                    shortName
                }
            }
        }
    };
}

export function newProgramTemplateStep(programTemplateId, position) {
    return {
        type: actionTypes.NEW_PROGRAM_TEMPLATE_STEP,
        payload: {
            client: 'api',
            request: {
                method: 'put',
                url: `program/template-step/${programTemplateId}`,
                params: {
                    position
                }
            }
        }
    };
}

export function deleteProgramTemplate(programTemplateId, {unassignPatients, deleteTemplateInstances, unassignTemplateInstancesPatients}) {
    return {
        type: actionTypes.DELETE_TEMPLATE,
        payload: {
            client: 'api',
            request: {
                method: 'delete',
                url: `program/template/${programTemplateId}`,
                params: {
                    unassignPatients,
                    deleteTemplateInstances,
                    unassignTemplateInstancesPatients
                }
            }
        }
    };
}

export function newCareProgramStep(careProgramId, position, payload) {
    return {
        type: actionTypes.NEW_CARE_PROGRAM_STEP,
        payload: {
            client: 'api',
            request: {
                method: 'put',
                url: `program/step/${careProgramId}`,
                params: {
                    position: position
                },
                data: payload
            }
        }
    };
}
