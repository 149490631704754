import Flex from "components/grid/Flex";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export const QueryHitsCounter = ({ isLoading, error, count }) => {
    const { t: translate } = useTranslation();
    return (
        <Flex item container column center style={{ marginTop: 15 }}>
            <Typography style={{ fontWeight: "bold" }}>{translate("cohort.current-hits")}</Typography>

            <Typography style={{ fontWeight: "bold", fontSize: 40 }}>
                {isLoading ? "..." : error ? "Error" : count || 0}
            </Typography>
        </Flex>
    );
};
