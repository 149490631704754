export const getTranslationArgsForTimeSpan = (dateTimeSpan, dateTimeSpanValue, grammarCase = "NOMINATIVE") => {
    if(dateTimeSpan === null || dateTimeSpanValue === null) {
        return "";
    }
    if (grammarCase === "NOMINATIVE") {
        return `timeSpans.${(dateTimeSpanValue > 1
            ? dateTimeSpan
            : dateTimeSpan.slice(0, dateTimeSpan.length - 1)
        ).toLowerCase()}`;
    } else {
        return `timeSpans.${(dateTimeSpanValue > 1
            ? dateTimeSpan + "-dative"
            : dateTimeSpan.slice(0, dateTimeSpan.length - 1)
        ).toLowerCase()}`;
    }
};
