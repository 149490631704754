import _ from "lodash";
import HMOApi, { FILE } from "store/api/HMOApi";
const BASE_URL = "/documents";

export const filesApi = HMOApi.injectEndpoints({
    endpoints: (build) => ({
        getMultipleFiles: build.query({
            async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
                const requests = args.map(data =>
                    fetchWithBQ({ url: `${BASE_URL}/${data.fileStorageType}/${data.entityBusinessId}/${data.entityId}`})
                );
                const responses = await Promise.all(requests).catch(err => {
                    return {error: err}
                })
                const result = responses.flatMap(x => x.data)
                const orderedData = _.orderBy(result, [obj => new Date(obj.uploadTime)], ['asc'])
                return {data: orderedData };
            },
            providesTags: (result, error, args) => {
                return [FILE]
            },
        }),
        deleteFile: build.mutation({
            query: (id) => ({
                url: `${BASE_URL}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [FILE]
        }),
        // NOTE LM: PLEASE USE FileHandler.action.js its rather complicated and I dont want to duplicate every fix all the time
        // This is especially true for downloads!!
        // You can dispatch those actions from functional components just the same.
        // downloadFile: build.query({
        // }),
        updateFileDescription: build.mutation({
            query: (file) => {
                return {
                url: `${BASE_URL}/${file.documentId}`,
                method: 'PUT',
                data: file,
            } },
            invalidatesTags: [FILE]
        }),
    }),
});

export const {
    useGetMultipleFilesQuery, //TODO LM: Only used once
    useDeleteFileMutation,
    useUpdateFileDescriptionMutation
} = filesApi;
