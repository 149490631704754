import HMOApi from "store/api/HMOApi";

export const UserDetailAccessApi = HMOApi.injectEndpoints({
    endpoints: (build) => ({
        fetchExistingPersons: build.query({
            query: (emailAddress) => ({url: `existing-persons/${emailAddress}`}),
        }),
        getAccessesOffered: build.query({
            query: ({patientId, personUserDetailsId, role}) => ({
                url: `access-offered`, method: 'post', data: {patientId, personUserDetailsId, role}
            }),
        }),
        assignPatientToExitingUser: build.mutation({
            query: ({ grantType, existingUserId, currentlyEditedUserDetailId }) => ({
                url: `grant-access/manager-assigns-patient-to-existing-user`,
                method: 'post',
                data: { grantType, existingUserId, currentlyEditedUserDetailId }
            }),
        }),
        assignCaseContactTo: build.mutation({
            query: ({grantType, existingUserId, existingUserDetailsId, currentlyEditedUserDetailId, entityIdToAssign}) => ({
                url: `grant-access/manager-assigns-case-contact`,
                method: 'post',
                data: {grantType, existingUserId, existingUserDetailsId, currentlyEditedUserDetailId, entityIdToAssign}}),
        }),
        assignCareProviderContactTo: build.mutation({
            query: ({grantType, existingUserId, currentlyEditedUserDetailId, currentlyEditedContactType, entityIdToAssign,
                        entityType}) => ({
                url: `grant-access/manager-assigns-care-provider-contact`,
                method: 'post',
                data: {grantType, existingUserId, currentlyEditedUserDetailId, currentlyEditedContactType, entityIdToAssign,
                    entityType}}),
        }),
        grantPatientAccessToContactPerson: build.mutation({
            query: ({grantType, existingUserId, currentlyEditedUserDetailId, accessInputs}) => ({
                url: `grant-access/assign-patient-access-to-contact`,
                method: 'post',
                data: {grantType, existingUserId, currentlyEditedUserDetailId, accessInputs}}),
        }),
        invite: build.mutation({
            query: (accessInputs) => ({url: `invite`, method: 'post', data: accessInputs}),
        }),
    })
});

export const {
    useFetchExistingPersonsQuery,
    useAssignPatientToExitingUserMutation,
    useAssignCaseContactToMutation,
    useAssignCareProviderContactToMutation,
    useGrantPatientAccessToContactPersonMutation,
    useGetAccessesOfferedQuery,
    useLazyGetAccessesOfferedQuery,
    useInviteMutation
} = UserDetailAccessApi;
