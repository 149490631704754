export const positionalRelationShipReducer = (acc, curr, stepPosition, steps) => {
    if (curr.dependsOnTaskId) {
        const dependencyPosition = steps.findIndex((s) => s.id === curr.dependsOnTaskId);
        if (dependencyPosition === -1) return acc;
        return [
            ...acc,
            {
                stepPosition,
                dependencyPosition,
            },
        ];
    } else {
        return acc;
    }
}
