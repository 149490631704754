import * as actionTypes from './FileHandler.action.types';
import cryptoUtils from 'utils/Crypto.utils';
import utils from 'utils/Utils';
import JSZip from "jszip";

export function uploadClinicCenterFile(file, fileName, clinicCenterBusinessId, entityId) {
    return scanAndUpload({
            file,
            fileName,
            entityBusinessId: clinicCenterBusinessId,
            entityId,
            patientId: undefined,
            fileStorageType: 'CLINIC_CENTER',
        },
        actionTypes.UPLOAD_CLINIC_CENTER_FILE
    );
}

export function uploadCaseFile(file, fileName, documentType, caseBusinessId, entityId, patientId) {
    return scanAndUpload({
            file,
            fileName,
            documentType,
            entityBusinessId: caseBusinessId,
            fileStorageType: 'CASE',
            entityId,
            patientId,
        },
        actionTypes.UPLOAD_CASE_FILE
    );
}

export const uploadFileBy = ({fileStorageType, entityBusinessId, entityId, patientId, file, fileName, documentType, questionId}) => {
    return scanAndUpload({
            file,
            fileName,
            documentType,
            entityBusinessId,
            fileStorageType,
            entityId,
            patientId,
            questionId
        },
        actionTypes['UPLOAD_' + fileStorageType + '_FILE']
    );
}

function downloadActionType(fileStorageType) {
    switch (fileStorageType) {
        case 'CASE':
            return actionTypes.DOWNLOAD_CASE_FILE;
        case 'CLINIC_CENTER':
            return actionTypes.DOWNLOAD_CLINIC_CENTER_FILE;
        default:
            return 'DOWNLOAD_FILE';
    }
}

export function downloadCaseFile(document) {
    return downloadFile(document, 'CASE', downloadActionType('CASE'));
}

export function downloadAndReturnCaseFile(document) {
    return downloadAndReturnFile(document, 'CASE', downloadActionType('CASE'));
}

export function listClinicCenterAttachments(clinicCenterBusinessId) {
    return listAttachments('CLINIC_CENTER', clinicCenterBusinessId);
}

export function listAttachments(fileStorageType, entityBusinessId) {
    return {
        type: actionTypes.LIST_ATTACHMENTS,
        payload: {
            client: 'api',
            request: {
                method: 'get',
                url: `documents/${fileStorageType}/${entityBusinessId}`,
            }
        }
    }
}

export function scanAndUpload({file, fileName, documentType, entityBusinessId, fileStorageType, entityId, patientId, questionId}, type) {
    const documentId = utils.uuid();
    const formData = new FormData();
    formData.append('uploadData', new Blob([JSON.stringify({
        fileName,
        fileSize: file.size,
        fileType: file.type,
        documentId,
        entityBusinessId,
        fileStorageType,
        entityId,
        patientId,
        ...(documentType && {documentType}),
        ...(questionId && {questionId}),
    })], {
        type: 'application/json'
    }));
    formData.append('file', new Blob([file]));
    return {
        type,
        payload: {
            client: 'apiMultipart',
            request: {
                method: 'post',
                url: 'scan-and-upload',
                data: formData
            }
        }
    }
}

async function getDownloadFilePromises(document, fileStorageType, downloadActionType, dispatch, props) {
    const promises = [];
    const downloadType = downloadActionType || 'DOWNLOAD_FILE';
    promises.push(dispatch(encryptionKeyQuery(document.documentId))
        .then(response => {
            props.encryptionKey = response.payload.data?.encryptionKey;
            props.iv = response.payload.data?.oldEntityBusinessIdForIv?.substring(0, 16);
            return props.encryptionKey;
        }));
    const downloadUrlResp = await dispatch(downloadUrlQuery(fileStorageType, document.documentId));
    const downloadUrl = downloadUrlResp.payload.data;
    promises.push(dispatch(fileDownloadQuery(downloadUrl, document.documentId, downloadType))
        .then(response => {
            props.file = response.payload.data;
            return props.file;
        }));
    return promises;
}

//TODO LM: The document knows its own storage type this makes no sense
export function downloadFile(document, fileStorageType, downloadActionType) {
    return async dispatch => {
        const props = {
            encryptionKey: '', file: '', iv: ''
        };
        const promises = await getDownloadFilePromises(document, fileStorageType, downloadActionType, dispatch, props);

        return Promise.allSettled(promises).then(results => {
            const rejected = results.filter(result => result.status === 'rejected').map(result => result.reason);
            if (rejected && rejected.length > 0) console.log(rejected);
            if (props.encryptionKey && props.file) {
                return cryptoUtils.decryptAndDownloadDocument(props.file, props.encryptionKey,
                    props.iv || document.entityBusinessId.substring(0, 16),
                    document.fileName)
            } else if (props.file) {
                let url = window.URL.createObjectURL(new Blob([props.file], {type: "application/octet-stream"}));
                utils.triggerNativeDownload(url, document.fileName)
            } else {
                console.error("The file is missing");
            }
        })
    }
}

export function downloadAndReturnFile(document, fileStorageType, downloadActionType) {
    return async dispatch => {
        const props = {
            encryptionKey: '', file: '', iv: ''
        };
        const promises = await getDownloadFilePromises(document, fileStorageType, downloadActionType, dispatch, props);

        return Promise.allSettled(promises).then(results => {
            const rejected = results.filter(result => result.status === 'rejected').map(result => result.reason);
            if (rejected && rejected.length > 0) console.log(rejected);
            if (props.encryptionKey && props.file) {
                return cryptoUtils.decryptAndReturn(props.file, props.encryptionKey,
                    props.iv || document.entityBusinessId.substring(0, 16))
            } else if (props.file) {
                return window.URL.createObjectURL(new Blob([props.file], {type: "application/octet-stream"}));
            } else {
                console.error("The file is missing");
                return Promise.reject();
            }
        })
    }
}

export function downloadSelectedDocumentForCase(documentIds) {
    return downloadSelectedDocument(documentIds, actionTypes.DOWNLOAD_CASE_FILE);
}

export function downloadSelectedDocument(documentIds, actionType) {
    return async dispatch => {
        const promises = [];
        const downloadType = actionType || actionTypes.DOWNLOAD_SELECTED_FILE;
        const downloadAllDataResp = await dispatch(downloadSelectedDataQuery(documentIds));
        const downloadAllData = downloadAllDataResp.payload.data

        const zip = new JSZip();
        const documents = zip.folder("documents");

        for (const documentDownloadData of downloadAllData) {
            await dispatch(fileDownloadQuery(documentDownloadData.downloadUrl, documentDownloadData.documentId,
                downloadType))
                .then(async response => {
                    const file = response.payload.data;
                    if (documentDownloadData.encryptionKey) {
                        await cryptoUtils.decryptDocument(file, documentDownloadData.encryptionKey,
                            documentDownloadData.entityBusinessId.substring(0, 16), documentDownloadData.fileName)
                            .then(async decryptedContent => {
                                await documents.file(documentDownloadData.fileName, decryptedContent, {base64: true});
                            });
                    } else {
                        await documents.file(documentDownloadData.fileName, file, {base64: true});
                    }
                });
        }

        return Promise.allSettled(promises).then(results => {
            const rejected = results.filter(result => result.status === 'rejected').map(result => result.reason);
            if (rejected && rejected.length > 0) console.log(rejected);
            zip.generateAsync({type: "blob"}).then(function (content) {
                let url = window.URL.createObjectURL(new Blob([content], {type: "application/octet-stream"}));
                utils.triggerNativeDownload(url, 'case_documents.zip')
            });
        })

    }
}

function encryptionKeyQuery(documentId) {
    return {
        type: actionTypes.GET_FILE_ENCRYPTION_KEY,
        payload: {
            client: 'api',
            request: {
                method: 'get',
                url: `/documents/${documentId}/key`,
                //LM NOTE:
                // Type selection cannot be omitted here! Otherwise the browser (not axios) will fail silently
                // trying to parse the response as JSON, resulting in empty response data.
                // https://github.com/axios/axios/issues/1409#issuecomment-372497948
                responseType: 'string'
            }
        }
    }
}

function downloadUrlQuery(fileStorageType, documentId) {
    return {
        type: actionTypes.GET_FILE_DOWNLOAD_URL,
        payload: {
            client: 'api',
            request: {
                method: 'get',
                url: `/documents/${fileStorageType}/${documentId}/downloadUrl`,
                //LM NOTE:
                // Type selection cannot be omitted here! Otherwise the browser (not axios) will fail silently
                // trying to parse the response as JSON, resulting in empty response data.
                // https://github.com/axios/axios/issues/1409#issuecomment-372497948
                responseType: 'string'
            }
        }
    }
}

function downloadSelectedDataQuery(documentIds) {
    return {
        type: actionTypes.GET_SELECTED_FILE_DOWNLOAD_URL,
        payload: {
            client: 'api',
            request: {
                method: 'post',
                data: {documentIds},
                url: '/documents/downloadSelected',
            }
        }
    }
}

function fileDownloadQuery(downloadUrl, id, type) {
    return {
        type,
        payload: {
            client: 'empty',
            data: {
                id
            },
            request: {
                withCredentials: false,
                method: 'get',
                url: downloadUrl,
                //LM NOTE:
                // Type selection cannot be omitted here! Otherwise the browser (not axios) will fail silently
                // trying to parse the response as JSON, resulting in empty response data.
                // https://github.com/axios/axios/issues/1409#issuecomment-372497948
                responseType: 'blob',
            }
        }
    }
}

export function downloadFileWithAuthentication(url) {
    return {
        type: '@file/DOWNLOAD_FILE',
        payload: {
            client: 'rest',
            request: {
                url: url?.startsWith('/') ? url : `/${url}`,
                method: 'get',
                responseType: 'blob',
            }
        }
    }
}

export function deleteCaseFile(document) {
    return deleteFile(document, actionTypes.DELETE_CASE_FILE);
}

export function deleteClinicCenterFile(document) {
    return deleteFile(document, actionTypes.DELETE_CENTER_FILE);
}

function deleteFile(document, type) {
    return {
        type,
        payload: {
            client: 'api',
            data: {id: document.documentId},
            request: {
                method: 'delete',
                url: `/documents/${document.documentId}`,
            }
        }
    }
}

export function updateDocument(document, options) {
    const {fileName, description, documentType} = options;
    return {
        type: actionTypes.UPDATE_DOCUMENT,
        payload: {
            client: 'api',
            data: {document, options},
            request: {
                method: 'put',
                url: `/documents/${document.documentId}`,
                data: {
                    documentId: document.documentId,
                    ...(fileName && {fileName}),
                    ...(description && {description}),
                    ...(documentType && {documentType}),
                }
            }
        }
    }
}

export function downloadIndicateDocx(caseBusinessId, indication) {
    return async dispatch => {
        const downloadExcel = await dispatch(down(caseBusinessId, indication === 'HEART' ? 'heart' : 'cancer')
        );

        genericDownloadFile(downloadExcel);
    }
}

function down(caseBusinessId, indication) {
    return {
        type: actionTypes.DOWNLOAD_INDICATE_FILES,
        payload: {
            client: 'api',
            request: {
                url: `/documents/case/${caseBusinessId}/indicate/${indication}/`,
                method: 'get',
                responseType: 'blob',
            }
        }
    }
}

function genericDownloadFile(downloadFile) {

    const downloadAllData = downloadFile.payload.data
    const filename = downloadFile.payload.headers["content-disposition"].split('"')[1];

    let url = window.URL.createObjectURL(new Blob([downloadAllData], {type: "application/octet-stream"}));
    utils.triggerNativeDownload(url, filename);
}
