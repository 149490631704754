import React, {PureComponent} from 'react';
import {Typography, withStyles, Tooltip} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {grey153, htmlBlue, htmlGrey, htmlRed, htmlWhite, normal, critical, medium} from 'components/colors/Colors';
import HmoAvatar from 'components/avatar/HmoAvatar';
import utils from 'utils/Utils';
import { HEADERS_TO_STATUS } from './CaseRowHeaders';
import Tag from 'components/tags/Tag';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import {reminderColor} from 'scenes/case/CaseUtils';
import * as WS from 'domain/WorkflowStatus';
import _ from 'lodash';
import HmoFilledButton from 'components/button/HmoFilledButton';
import FileCounter from 'components/counter/FileCounter';
import 'moment/locale/de';
import {isPatient} from 'domain/User.model';

export function getColorByPriority(priority) {
    switch (priority.toLowerCase()) {
        case 'low':
            return normal;
        case 'medium':
            return medium;
        case 'high':
            return critical;
        default:
            return htmlGrey;
    }
}

const styles = theme => ({
    hoverBackground: {
        backgroundColor: htmlWhite,
        transition: 'background-color 0.1s linear',
    },
    titleBarItem: {
        lineHeight: 1.5,
    },
    ellipsis: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 120
    }
});

const Actions = props => {
    const {translate, onAction} = props;
    return (
        <HmoFilledButton onClick={onAction}>
            {translate('global.accept')}
        </HmoFilledButton>
    )
}

const CaseManager = (props) => {
    const {caseManager} = props;
    return (
        <Flex container alignItems={'center'}
              style={{marginLeft: 10, marginRight: 10}}>
            <HmoAvatar {...{user: caseManager}}/>
        </Flex>
    );
};

const PatientId = (props) => {
    const {classes, id, numberOfUnreadDocuments, translate} = props;
    return (
        <div>
            <Typography className={classes.titleBarItem}
                        style={{marginRight: 5, marginLeft: 5}}>
                {utils.convertReadablePatientId(id)}
            </Typography>
            {
                numberOfUnreadDocuments > 0 &&
                <Tooltip title={translate('global.has-unread-files')}>
                    <FileCounter count={numberOfUnreadDocuments}/>
                </Tooltip>
            }
        </div>
    );
};


const PatientName = (props) => {
    const {classes, formattedName} = props;
    return (
        <Typography className={classes.titleBarItem}
                    style={{marginRight: 5, marginLeft: 5}}>
            {formattedName}
        </Typography>
    );
};

const NumberOfDaysInStatus = (props) => {
    const {classes, numberOfDaysInStatus} = props;
    return (
        <Typography className={classes.titleBarItem}
                    style={{
                        marginRight: 5,
                        marginLeft: 5,
                        color: numberOfDaysInStatus >= 5
                            ? htmlRed
                            : numberOfDaysInStatus >= 3
                                ? grey153
                                : 'inherit'
                    }}>
            {numberOfDaysInStatus}
        </Typography>
    );
};

const Disease = (props) => {
    const {mainIndication, selected, toggleSelection} = props;
    return (
        mainIndication && <Tag {...{
            doTranslate: true,
            selected,
            tag: mainIndication,
            toggleSelection
        }}/>
    );
};

const SubIndications = (props) => {
    const {subIndications, selectedTags, toggleSelection} = props;
    return (
        <div>
            {
                subIndications.map(subIndication => (
                    <Tag key={subIndication} {...{
                        selected: selectedTags.includes(subIndication),
                        tag: subIndication,
                        toggleSelection
                    }}/>
                ))
            }
        </div>
    );
};

const Email = (props) => {
    const {classes, email} = props;
    return (
        <Typography className={classes.titleBarItem}
                    style={{marginRight: 5, marginLeft: 5, color: htmlBlue, textDecoration: 'underline'}}>
            {email}
        </Typography>
    );
};

const Phone = (props) => {
    const {classes, phoneNumber} = props;
    return (
        <Typography className={classes.titleBarItem}
                    style={{marginRight: 5, marginLeft: 5}}>
            {phoneNumber}
        </Typography>
    );
};

const Clinic = (props) => {
    const {classes, clinic} = props;
    return (
        <Typography className={classes.titleBarItem}
                    style={{marginRight: 5, marginLeft: 5}}>
            {clinic}
        </Typography>
    );
};

const Contact = (props) => {
    const {classes, contact} = props;
    return (
        <Typography className={classes.titleBarItem}
                    style={{marginRight: 5, marginLeft: 5}}>
            {contact}
        </Typography>
    );
};

const ReminderDate = (props) => {
    const {classes, reminderDate} = props;
    return (
        <Typography className={classes.titleBarItem}
                    style={{marginRight: 5, marginLeft: 5, color: reminderColor(reminderDate)}}>
            {reminderDate ? moment(reminderDate).format('DD.MMM') : ''}
        </Typography>
    );
};

const PatientAppointmentDate = (props) => {
    const {classes, patientAppointmentDate} = props;
    return (
        <Typography className={classes.titleBarItem}
                    style={{marginRight: 5, marginLeft: 5}}>
            {patientAppointmentDate ? moment(patientAppointmentDate).format('DD.MMM') : ''}
        </Typography>
    );
};

const PatientAppointmentAction = (props) => {
    const {classes, patientAppointmentAction} = props;
    return (
        <Typography className={classes.titleBarItem}
                    style={{marginRight: 5, marginLeft: 5}}>
            {patientAppointmentAction}
        </Typography>
    );
};

const ReminderAction = (props) => {
    const {classes, reminderAction} = props;
    return (
        <Typography className={classes.titleBarItem}
                    style={{marginRight: 5, marginLeft: 5, fontWeight: 'bold'}}>
            {reminderAction}
        </Typography>
    );
};

const LastChangeDate = (props) => {
    const {classes, translate, modificationDate, i18n} = props;
    const date = i18n.language === 'de' ? moment(modificationDate).locale('de') : moment(modificationDate);
    const calendar = modificationDate && date.calendar(null, {
        sameDay: '[' + translate('global.today') + '] HH:mm',
        lastDay: '[' + translate('global.yesterday') + '] HH:mm',
        sameElse: 'DD.MMM HH:mm'
    });
    return (
        <Typography className={classes.titleBarItem}
                    style={{marginRight: 5, marginLeft: 5}}>
            {modificationDate ? calendar : ''}
        </Typography>
    );
};

const CostCoverage = (props) => {
    const {selectedTags, toggleSelection, costCoverageTypes} = props;
    return (
        <div>
            {
                costCoverageTypes.map(costCoverage => (
                    <Tag key={costCoverage} {...{
                        selected: selectedTags.includes(costCoverage),
                        tag: costCoverage,
                        toggleSelection
                    }}/>
                ))
            }
        </div>
    );
};

const Request = (props) => {
    const {classes, request} = props;
    return (
        <Typography className={classes.titleBarItem}
                    style={{marginRight: 5, marginLeft: 5}}>
            {request}
        </Typography>
    );
};

const Created = (props) => {
    const {classes, creationDate} = props;
    return (
        <Typography className={classes.titleBarItem}
                    style={{marginRight: 5, marginLeft: 5}}>
            {creationDate ? moment(creationDate).format('DD.MMM') : ''}
        </Typography>
    );
};

export class CaseRow extends PureComponent {

    state = {
        isLoaded: false,
    };

    ref = React.createRef();

    componentDidMount() {
        this.observer = new IntersectionObserver(this.load);
        if (this.ref.current) {
            this.observer.observe(this.ref.current);
        }
    }

    componentWillUnmount() {
        this.observer.unobserve(this.ref.current);
    }

    load = entries => {
        if (entries[0].isIntersecting) {
            this.setState({isLoaded: true});
            this.observer.unobserve(this.ref.current);
        }
    };

    onAction = event => {
        event.preventDefault();
        event.stopPropagation();
        this.props.onAction(this.props.case);
    }

    render() {
        const {
            classes, t: translate, handleDoubleClick, handleClick, toggleTagSelection, selectedTags, caseManagers,
            specialClinicStatus, i18n, user
        } = this.props;
        const {
            businessId, caseManagerId, patient, modificationDate, reminderDate, reminderAction, numberOfDaysInStatus,
            creationDate, patientAppointmentComment, patientAppointmentDate, indication, subIndications,
            numberOfUnreadDocuments, costCoverageTypes, acceptedDate, primaryTumorLocalized, metastasisLocalized,
            secondOpinionUntil, priority, originationComment
        } = this.props.case;
        const {onAction} = this;
        const caseManager = (caseManagers || []).find(cm => cm.businessId === caseManagerId);
        const status = specialClinicStatus || this.props.case.status;
        const age = patient.dateOfBirth ? moment().diff(moment(patient.dateOfBirth), 'years') : '';
        return (
            <tr id={`${businessId}-observed`} ref={this.ref}
                onDoubleClick={() => handleDoubleClick(businessId)}
                onClick={() => handleClick(businessId)}
                style={{
                    borderLeft: `4px solid ${getColorByPriority(priority)}`,
                    height: 40,
                    backgroundColor: htmlWhite,
                }}
            >
                {
                    this.state.isLoaded &&
                    <>
                        {
                            HEADERS_TO_STATUS[status]?.includes('caseManager') &&
                            <td style={{
                                borderLeft: `4px solid ${getColorByPriority(priority)}`,
                            }}>
                                <CaseManager {...{caseManager}}/>
                            </td>
                        }
                        <td>
                            <PatientId {...{
                                classes,
                                id: patient.id,
                                numberOfUnreadDocuments,
                                translate
                            }}/>
                        </td>
                        <td>
                            <PatientName {...{
                                classes,
                                formattedName: utils.formattedUserNameWithComma(patient)
                            }}/>
                        </td>
                        {
                            HEADERS_TO_STATUS[status].includes('numberOfDaysInStatus') &&
                            <td>
                                <NumberOfDaysInStatus {...{classes, numberOfDaysInStatus}}/>
                            </td>
                        }

                        {
                            HEADERS_TO_STATUS[status].includes('sex') &&
                            <td>
                                <Typography className={classes.titleBarItem}>
                                    {patient?.bioSex ? translate(`global.${patient.bioSex}`) : ''}
                                </Typography>
                            </td>
                        }

                        {
                            HEADERS_TO_STATUS[status].includes('age') &&
                            <td>
                                <Typography className={classes.titleBarItem}>
                                    {age}
                                </Typography>
                            </td>
                        }

                        {
                            HEADERS_TO_STATUS[status].includes('primaryTumor') && <td>
                                <Typography className={classes.ellipsis}>
                                    {primaryTumorLocalized}
                                </Typography>
                            </td>
                        }

                        {
                            HEADERS_TO_STATUS[status].includes('metastases') && <td>
                                <Typography className={classes.ellipsis}>
                                    {metastasisLocalized}
                                </Typography>
                            </td>
                        }

                        {
                            HEADERS_TO_STATUS[status].includes('status') &&
                            <td>
                                <Typography className={classes.titleBarItem}>
                                    {translate(`case.status.${status}`)}
                                </Typography>
                            </td>
                        }

                        {
                            HEADERS_TO_STATUS[status].includes('acceptedDate') &&
                            <td>
                                <Typography className={classes.titleBarItem}>
                                    {acceptedDate ? moment(acceptedDate).format('DD MMM') : ''}
                                </Typography>
                            </td>
                        }

                        {
                            HEADERS_TO_STATUS[status].includes('finishUntil') &&
                            <td>
                                <Typography className={classes.titleBarItem}>
                                    {secondOpinionUntil ? moment(secondOpinionUntil).format('DD MMM') : ''}
                                </Typography>
                            </td>
                        }

                        {
                            HEADERS_TO_STATUS[status].includes('payment') &&
                            <td>
                                <Typography className={classes.titleBarItem}>
                                    85,- EUR
                                </Typography>
                            </td>
                        }

                        {
                            HEADERS_TO_STATUS[status].includes('disease') &&
                            <td>
                                <Disease {...{
                                    mainIndication: indication,
                                    selected: selectedTags.includes(indication),
                                    toggleSelection: toggleTagSelection
                                }}/>
                            </td>
                        }

                        {
                            HEADERS_TO_STATUS[status].includes('subIndication') &&
                            <td>
                                <SubIndications {...{
                                    subIndications,
                                    selectedTags,
                                    toggleSelection: toggleTagSelection
                                }}/>
                            </td>
                        }

                        {
                            HEADERS_TO_STATUS[status].includes('costCoverage') &&
                            <td>
                                <CostCoverage {...{
                                    selectedTags,
                                    toggleSelection: toggleTagSelection,
                                    costCoverageTypes
                                }}/>
                            </td>
                        }

                        {
                            HEADERS_TO_STATUS[status].includes('email') &&
                            <td>
                                <Email {...{classes, email: (patient.emailAddresses || [])[0]}}/>
                            </td>
                        }

                        {
                            HEADERS_TO_STATUS[status].includes('clinic') &&
                            <td>
                                <Clinic {...{
                                    classes,
                                    clinic: 'N/A' //NOTE Task-753: In these statuses we show the contact of the certified center not the patient
                                }}/>
                            </td>
                        }

                        {
                            HEADERS_TO_STATUS[status].includes('contact') &&
                            <td>
                                <Contact {...{
                                    classes,
                                    contact: 'N/A' //NOTE Task-753: In these statuses we show the contact of the certified center not the patient
                                }}/>
                            </td>
                        }

                        {
                            HEADERS_TO_STATUS[status].includes('phone') &&
                            <td>
                                <Phone {...{
                                    classes,
                                    phoneNumber: [WS.REQUEST_EXPERT_TO_TAKE_OVER_CASE, WS.CASE_NOT_REVIEWED_BY_EXPERT, WS.CASE_UNDER_REVIEW_BY_EXPERT].includes(status)
                                        ? 'N/A' //NOTE Task-753: In these statuses we show the contact of the certified center not the patient
                                        : _.get(patient, 'phoneNumbers[0]', 'N/A')
                                }}/>
                            </td>
                        }

                        {
                            HEADERS_TO_STATUS[status].includes('patientAppointmentAction') &&
                            <td>
                                <PatientAppointmentAction {...{
                                    classes, patientAppointmentAction: patientAppointmentComment
                                }} />
                            </td>
                        }

                        {
                            HEADERS_TO_STATUS[status].includes('patientAppointmentDate') &&
                            <td>
                                <PatientAppointmentDate {...{
                                    classes, patientAppointmentDate: patientAppointmentDate
                                }} />
                            </td>
                        }

                        {
                            HEADERS_TO_STATUS[status].includes('reminderAction') &&
                            <td>
                                <ReminderAction {...{classes, reminderAction: isPatient(user) ? '' : reminderAction}}/>
                            </td>
                        }

                        {
                            HEADERS_TO_STATUS[status].includes('reminderDate') &&
                            <td>
                                <ReminderDate {...{classes, reminderDate}}/>
                            </td>
                        }

                        {
                            HEADERS_TO_STATUS[status].includes('lastChange') &&
                            <td>
                                <LastChangeDate {...{classes, translate, modificationDate, i18n}}/>
                            </td>
                        }

                        {
                            HEADERS_TO_STATUS[status].includes('request') &&
                            <td>
                                <Request {...{
                                    classes,
                                    request: isPatient(user) ? '' : originationComment
                                }} />
                            </td>
                        }

                        {
                            HEADERS_TO_STATUS[status].includes('created') &&
                            <td>
                                <Created {...{classes, translate, creationDate}} />
                            </td>
                        }

                        {
                            HEADERS_TO_STATUS[status].includes('actions') &&
                            <td>
                                <Actions {...{classes, translate, onAction}} />
                            </td>
                        }
                    </>
                }
            </tr>
        );
    }
}

export default withStyles(styles)(withTranslation()(CaseRow));
