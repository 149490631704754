export const HEMOGLOBIN = 'hemoglobin';
export const KREATININ = 'kreatinin';
export const TOTAL_CHOLESTEROL = 'totalcholesterol';
export const LDL_CHOLESTEROL = 'ldlcholesterol';
export const HDL_CHOLESTEROL = 'hdlcholesterol';
export const CRP = 'crp';
export const LEUKOZYTEN = 'leukozyten';
export const MCV = 'mcv';
export const RDW = 'rdw';
export const THROMBOZYTEN = 'thrombozyten';
export const GFR = 'gfr';

export const LaboratoryKeys = [
    HEMOGLOBIN,
    KREATININ,
    TOTAL_CHOLESTEROL,
    LDL_CHOLESTEROL,
    HDL_CHOLESTEROL,
    CRP,
    LEUKOZYTEN,
    MCV,
    RDW,
    THROMBOZYTEN,
    GFR
];

/**
 * Leukozyten < 4.0
 * Hämoglobin <  		ATTENTION: <12 for women and <13 for men!!
 * MCV < 82
 * RDW-CV < 11,6
 * Thrombozyten < 150
 */
export const CCUSThresholds = {
    [LEUKOZYTEN]: 4.0,
    [HEMOGLOBIN]: 13,
    [HEMOGLOBIN+'_'+'MALE']: 13,
    [HEMOGLOBIN+'_'+'FEMALE']: 12,
    [MCV]: 82,
    [RDW]: 11.6,
    [THROMBOZYTEN]: 150
}
