import {
    Box,
    TextField,
    Radio,
    MenuItem,
    Select,
} from "@material-ui/core";
import { EXACT, RECURRING, DEPENDENT_ON_PREVIOUS_TASK } from "domain/ProgramStepDateType.model";
import { JUST_ONCE, EVERY_TIME } from "domain/DependentCycle.model";
import { PossibleTimePeriods } from "domain/Program.model";
import { useTranslation } from "react-i18next";
export const SchedulingTaskSettingPanel = ({setStep, step, onChange, otherStepsInProgram}) => {
    const { t } = useTranslation();
    return (
        <Box display={"flex"} flexDirection={"column"}>
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"} marginLeft={"-10px"}>
                <Radio
                    onChange={() => {
                        setStep({
                            ...step,
                            dateType: EXACT,
                            dateTimeSpan: null,
                            dateTimeSpanValue: null,
                        });
                    }}
                    name="dateType"
                    value={step.dateType}
                    checked={step.dateType === EXACT}
                    color="primary"
                />
                <div>{t("program.exactDate")}</div>
            </Box>
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"} marginTop={"10px"} marginLeft={"-10px"}>
                <Radio
                    onChange={() => {
                        setStep({
                            ...step,
                            dateTimeSpan: PossibleTimePeriods.Days,
                            dateType: RECURRING,
                            dateTimeSpanValue: 1,
                        });
                    }}
                    name="dateType"
                    value={step.dateType}
                    checked={step.dateType === RECURRING}
                    color="primary"
                />{" "}
                <span style={{ marginRight: "10px" }}>{t("program.every")}</span>{" "}
                <TextField
                    disabled={step.dateType !== RECURRING}
                    type="number"
                    name="dateTimeSpanValue"
                    onChange={onChange}
                    value={step.dateTimeSpanValue ?? 1}
                    style={{ width: "40px", marginRight: "10px" }}
                />
                {/* separate fields to store month and day and them combining on submit */}
                <TextField
                    disabled={step.dateType !== RECURRING}
                    onChange={onChange}
                    name="dateTimeSpan"
                    value={step.dateTimeSpan ?? PossibleTimePeriods.Days}
                    select
                    style={{ marginRight: "10px" }}
                >
                    {Object.entries(PossibleTimePeriods).map(([label, value]) => (
                        <MenuItem key={value} value={value}>
                            {t(`timeSpans.${label.toLowerCase()}`)}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"} marginLeft={"-10px"} marginTop="10px">
                <Radio
                    name="dateType"
                    onChange={() => {
                        setStep({
                            ...step,
                            dateTimeSpan: PossibleTimePeriods.Days,
                            dateType: DEPENDENT_ON_PREVIOUS_TASK,
                            dateTimeSpanValue: 1,
                        });
                        // should setup time period default and time period default value
                    }}
                    checked={step.dateType === DEPENDENT_ON_PREVIOUS_TASK}
                    value={step.dateType}
                    color="primary"
                />
                <TextField
                    disabled={step.dateType !== DEPENDENT_ON_PREVIOUS_TASK}
                    type="number"
                    name="dateTimeSpanValue"
                    onChange={onChange}
                    value={step.dateTimeSpanValue ?? 1}
                    style={{ width: "40px", marginRight: "10px" }}
                />
                <TextField
                    disabled={step.dateType !== DEPENDENT_ON_PREVIOUS_TASK}
                    onChange={onChange}
                    name="dateTimeSpan"
                    value={step.dateTimeSpan ?? PossibleTimePeriods.Days}
                    select
                    style={{ marginRight: "10px" }}
                >
                    {Object.entries(PossibleTimePeriods).map(([label, value]) => (
                        <MenuItem key={value} value={value}>
                            {t(`timeSpans.${label.toLowerCase()}`)}
                        </MenuItem>
                    ))}
                </TextField>
                <div>{t("program.afterTask")}</div>
                <Select
                    value={step.dependsOnTaskId ?? ""}
                    name="dependsOnTaskId"
                    onChange={onChange}
                    style={{ marginLeft: "10px" }}
                >
                    {otherStepsInProgram.map((_step) => (
                        <MenuItem value={_step.id} disabled={_step.isCompleted}>
                            <span style={{ fontWeight: "bold" }}>{_step.position}:</span>
                            {` ${_step.description || ""}`}
                        </MenuItem>
                    ))}
                </Select>
                {otherStepsInProgram?.find((item) => item.id === step.dependsOnTaskId)?.dateType === RECURRING && (
                    <Select
                        value={step.dependentCycle}
                        name="dependentCycle"
                        onChange={onChange}
                        style={{ marginLeft: "10px" }}
                    >
                        <MenuItem value={JUST_ONCE}>{t("global.JUST_ONCE")}</MenuItem>
                        <MenuItem value={EVERY_TIME}>{t("global.EVERY_TIME")}</MenuItem>
                    </Select>
                )}
            </Box>
        </Box>
    );
};
