import moment from "moment";
import { flow, partial } from "lodash/fp";

// Time till deletion in hours (3 days)
const TIME_TILL_DELETION = 72;

/**
 * Calculates the time difference in milliseconds between the current time and a given time.
 * @param {Date} now - The current date and time
 * @param {string} time - The time to compare with
 * @returns {number} The time difference in milliseconds
 */
const msPassedFromMarkedForDeletionTime = (now, time) => {
    const markedForDeletionAtDate = new Date(time);
    const diff = now - markedForDeletionAtDate;
    return diff > 0 ? diff : 0;
};

/**
 * Calculates the remaining time in milliseconds from a given duration.
 * @param {number} availableDurationInHours - The total available duration in hours
 * @param {number} millisecondsPassed - The milliseconds passed
 * @returns {object} The remaining moment duration in milliseconds
 */
const calculateRemainingTimeAsMomentObj = (availableDurationInHours, millisecondsPassed) => {
    const duration = moment.duration(millisecondsPassed);
    const remainingDuration = moment.duration(availableDurationInHours, "hours").subtract(duration);
    return remainingDuration;
};

/**
 * Transforms the moment duration object to an object with hours and minutes.
 * @param {object} momentDuration - The moment duration object
 * @returns {object} Object with hours and minutes represented as numbers
 */
const momentDurationToHoursMinutesObj = (md) => ({
    hours: md.days() * 24 + md.hours(),
    minutes: md.minutes(),
});

/**
 * Calculates the time left from a given time till the deletion and returns formatted string.
 * @param {string} time - Marked for deletion time
 * @param {string} now - Current time
 * @returns {string} Object with hours and minutes represented as numbers
 */
export const deletionTimeToHrsMins = (now, markedForDeletionAtDate) =>
    flow(
        partial(msPassedFromMarkedForDeletionTime, [now]),
        partial(calculateRemainingTimeAsMomentObj, [TIME_TILL_DELETION]),
        momentDurationToHoursMinutesObj
    )(markedForDeletionAtDate);
