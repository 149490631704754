import React, {Component} from 'react';
import {Dialog, Typography} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import AccountCircleOutlined from '@material-ui/icons/AccountCircleOutlined';
import DefaultTextField from 'components/hmo-textfield/DefaultTextField';
import HmoFilledButton from 'components/button/HmoFilledButton';
import HmoBorderedButton from 'components/button/HmoBorderedButton';
import SidebarSelect from 'components/input/SidebarSelect';

export class NewContactDialog extends Component {

    state = {
        givenName: '',
        familyName: '',
        clinicCenterBusinessId: ''
    }

    addContact = () => {
        if(this.props.contact){
            this.props.addSubordinate({
                givenName: this.state.givenName || '',
                familyName: this.state.familyName || ''
            }, this.props.contact);
        } else {
            this.props.addContact({
                givenName: this.state.givenName || '',
                familyName: this.state.familyName || '',
                clinicCenterBusinessId: this.state.clinicCenterBusinessId || '',
            });
        }
    };

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    render() {
        const {t: translate, toggleNewContactDialog, clinicCenters, contact} = this.props;
        const {handleChange, addContact} = this;
        const {givenName, familyName, clinicCenterBusinessId} = this.state;
        const disabled = !givenName || !familyName || (!contact && !clinicCenterBusinessId);
        return (
                <Dialog open={true}>
                    <Flex item container column padding={15}>
                        <Flex item container justifyContent={'space-between'} alignItems={'center'}
                              style={{marginRight: 7}}>
                            <Typography variant={"h5"}>
                                {translate(contact ? "global.new-secretary" : "global.new-contact")}
                            </Typography>
                            <AccountCircleOutlined/>
                        </Flex>
                        <DefaultTextField value={givenName || ''}
                                          label={translate("case.first-name")}
                                          error={!givenName}
                                          name={'givenName'}
                                          onChange={handleChange}/>
                        <DefaultTextField value={familyName || ''}
                                          label={translate("case.last-name")}
                                          error={!familyName}
                                          name={'familyName'}
                                          onChange={handleChange}/>
                        {
                            !contact && clinicCenters &&
                            <SidebarSelect {...{
                                value: clinicCenters.find(cc => cc.businessId === clinicCenterBusinessId) || '',
                                onChange: handleChange,
                                name: 'clinicCenterBusinessId',
                                inputLabel: translate("global.clinic-center"),
                                options: clinicCenters,
                                renderValue: value => value.name,
                                labelGet: 'name',
                                valueGet: 'businessId',
                                error: !clinicCenterBusinessId
                            }}/>
                        }

                        <Flex item container style={{marginTop: 10, marginRight: 7, minWidth: 300}}>
                            <HmoBorderedButton onClick={toggleNewContactDialog} size={'small'}
                                               style={{width: '50%', marginRight: 10}}>
                                {translate("global.cancel")}
                            </HmoBorderedButton>
                            <HmoFilledButton onClick={addContact} disabled={disabled} style={{width: '50%'}}>
                                {translate(contact ? "global.add-secretary" : "global.add-contact")}
                            </HmoFilledButton>
                        </Flex>
                    </Flex>
                </Dialog>
        );
    }
}

export default withTranslation()(NewContactDialog);
