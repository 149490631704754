import DefaultDialogBody from './DefaultDialogBody';
import {useTranslation} from "react-i18next";
import {useAssignCareProviderContactToMutation} from 'scenes/user-manager/UserDetailAccessApi';
import {useEnqueueSuccess, useEnqueueError} from 'components/alert/SnackbarHooks';
import {ASSISTANT, DOCTOR, CLINIC} from 'domain/User.model';
import moment from 'moment';
import utils from 'utils/Utils';

/**
 * CASE_MANAGER_EDITS_CARE_PROVIDER_CONTACT_EMAIL, CASE_MANAGER_GRANTS_ACCESS_TO_CARE_PROVIDERS_CONTACT_PERSON
 */
const CaseManagerEditsCareProviderEmail = (props) => {
    const {
        toggleOpen, isSaveDisabled, deleteEmail, originalEmail, existingUser, userAlreadyExists, refresh,
        inviteRequiresConfirmation, userDetailsAlreadyExists, existingUserMatchesPersonsUser, personAlreadyHasAUser,
        grantType, existingUserId, currentlyEditedUserDetailId, currentlyEditedContactType, entityIdToAssign, entityType
    } = props;
    const {t: translate} = useTranslation();
    const [assignCareProviderContactTo] = useAssignCareProviderContactToMutation();
    const enqueueError = useEnqueueError();
    const enqueueSuccess = useEnqueueSuccess();
    const triggerGrantAccess = () => assignCareProviderContactTo({grantType, existingUserId, currentlyEditedUserDetailId,
        currentlyEditedContactType, entityIdToAssign, entityType})
            .unwrap().then(() => {
                enqueueSuccess();
                refresh && refresh();
                toggleOpen();
            }).catch(() => {
                enqueueError();
            })
    const userHaveCareProviderRoles = (userAlreadyExists && !existingUserMatchesPersonsUser)
            && !!existingUser?.roles?.find(role => [ASSISTANT, DOCTOR, CLINIC].includes(role));

    let information = '';
    let existingUserString = '';
    let save = props.save;
    let saveButtonLabel = props.saveButtonLabel;
    let disabled = isSaveDisabled;
    const roles = translate('global.roles') + `: ${existingUser?.roles?.toString().toLowerCase()}`;
    if (personAlreadyHasAUser && (userAlreadyExists || userDetailsAlreadyExists)) {
        information = translate('access.already-used');
        disabled = true;
    } else if (userDetailsAlreadyExists && !userHaveCareProviderRoles) {
        information = translate('access.already-used-non-care-provider');
        disabled = true;
    } else if (!entityIdToAssign && (userAlreadyExists && !personAlreadyHasAUser)) {
        information = translate('access.already-used-no-assignment-select-care-provider', {name: `(${existingUser?.givenName}, ${existingUser?.familyName})`});
        disabled = true;
    } else if (userDetailsAlreadyExists && userHaveCareProviderRoles) {
        information = translate('access.already-used-care-provider-user');
        save = triggerGrantAccess;
        saveButtonLabel = translate('access.assign-contact');
        existingUserString = translate('access.existing-user')
                + `: ${existingUser?.givenName} ${existingUser?.familyName} ${roles}`;
    } else if (!userDetailsAlreadyExists && userHaveCareProviderRoles) {
        information = translate('access.already-used-no-assignment-question', {name: `(${existingUser?.givenName}, ${existingUser?.familyName})`});
        save = triggerGrantAccess;
        saveButtonLabel = translate('access.assign-contact');
    } else if (inviteRequiresConfirmation) {
        information = translate('access.invitation-already-sent');
        const date = inviteRequiresConfirmation?.creationDate
                ? moment(inviteRequiresConfirmation?.creationDate).format(utils.VIEW_DATE_FORMAT)
                : '';
        existingUserString = translate('access.existing-invitation')
                + `: ${inviteRequiresConfirmation?.invitedName} - ${date}`;
    }

    return <DefaultDialogBody {...{
        information,
        toggleOpen,
        save,
        saveButtonLabel,
        disabled,
        deleteEmail,
        originalEmail,
        existingUserString
    }}/>;
};

export default CaseManagerEditsCareProviderEmail;
