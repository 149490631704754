/**
 * Function that takes a string and capitalizes file extension at the end
 * @param {string} fileName
 * @returns file name with capitalized file extension
 */
export const capitalizeFileExtension = (fileName) => {
  const parts = fileName.split('.');
  if (parts.length > 1) {
    const extension = parts.pop();
    return `${parts.join('.').toLowerCase()}.${extension.toUpperCase()}`;
  }
  return fileName;
};
