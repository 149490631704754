import {IMaskInput, IMask} from "react-imask";
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((_theme) => ({
    input: {
        border: '0',
        paddingTop: 6,
        paddingBottom: 7,
        fontSize: '16px',
        width: '60px',
        '&:focus-visible': {
            outline: 'none',
        }
    },
}));

export const MaskedTimeInput = (props) => {

    const classes = useStyles();
    const {value, inputRef, onChange, disabled} = props;
    return <div>
        <IMaskInput
                disabled={disabled}
                className={classes.input}
                mask="HH:MM"
                placeholderhar="0"
                lazy={true}
                unmask={false}
                blocks={{
                    HH: {
                        mask: IMask.MaskedRange,
                        from: 0,
                        to: 23,
                    },
                    MM: {
                        mask: IMask.MaskedRange,
                        from: 0,
                        to: 59,
                    },
                }}
                placeholder="00:00"
                value={value}
                inputRef={inputRef}
                onAccept={(value, mask) => {
                    onChange(value);
                }}
        />
    </div>
}
