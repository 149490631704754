import HMOApi, {PROGRAMS} from "store/api/HMOApi";

const BASE_URL = "/program";

export const ProgramApi = HMOApi.injectEndpoints({
    endpoints: (build) => ({
        fetchPatientPrograms: build.query({
            query: (patientId) => ({url: `${BASE_URL}/patient/${patientId}`}),
            transformResponse: response => (response?.programs),
            providesTags: [PROGRAMS],
        }),
        fetchProgramTemplates: build.query({
            query: (type) => ({url: `${BASE_URL}/templates?type=${type}`}),
        }),
        upsertCareProgram: build.mutation({
            query: ({careProgram, position}) => ({
                url: BASE_URL,
                method: "POST",
                params: {
                    position: position
                },
                data: careProgram,
            }),
            invalidatesTags: [PROGRAMS],
        }),
        newCareProgramStep: build.mutation({
            query: ({careProgramId, position, step}) => {
                return {
                    url: `${BASE_URL}/step/${careProgramId}`,
                    method: "PUT",
                    params: {
                        position: position
                    },
                    data: step,
                }
            },
            invalidatesTags: [PROGRAMS],
        }),
        newCareProgramTemplateStep: build.mutation({
            query: ({programTemplateId, position, step}) => {
                return {
                    url: `${BASE_URL}/template-step/${programTemplateId}`,
                    method: "PUT",
                    params: {
                        position: position
                    },
                    data: step,
                }
            }
        }),
        upsertProgramTemplate: build.mutation({
            query: ({programTemplate}) => {
                return {
                    url: `${BASE_URL}/template`,
                    method: "POST",
                    data: programTemplate,
                }
            },
        }),
        checkIfProgramTemplateCanBeSafelyDeleted: build.query({
            query: ({templateId}) => ({url: `${BASE_URL}/template/${templateId}/checkIfCanBeSafelyDeleted`}),
        }),
        requestToLeaveCareProgram: build.mutation({
            query: ({programId: careProgramId, reason}) => ({
                url: `${BASE_URL}/${careProgramId}/request-to-leave`,
                method: "POST",
                data: { reason }
            }),
            invalidatesTags: [PROGRAMS],
        }),
        connectCaseToProgram: build.mutation({
            query: ({caseId, programTemplateId}) => {
                return {
                    url: `${BASE_URL}/connect-case-to-program`,
                    method: "POST",
                    data: {caseId, programTemplateId},
                }
            }
        }),
        createCareProgramInstance: build.mutation({
            query: ({patientId, programTemplateId, careProviderId}) => {
                return {
                    url: `${BASE_URL}`,
                    method: "PUT",
                    data: {patientId, programTemplateId, careProviderId},
                }
            },
            invalidatesTags: [PROGRAMS]
        }),
        sendProgramStepReminder: build.mutation({
            query: ({programStepId}) => ({
                url: `${BASE_URL}/send-program-step-reminder/${programStepId}`,
                method: "POST"
            })
        }),
        sendProgramStepSpecialRequest: build.mutation({
            query: ({programStepId}) => ({
                url: `${BASE_URL}/send-program-step-special-request/${programStepId}`,
                method: "POST"
            })
        })
    }),
});

export const {
    useFetchPatientProgramsQuery,
    useFetchProgramTemplatesQuery,
    useUpsertCareProgramMutation,
    useNewCareProgramStepMutation,
    useNewCareProgramTemplateStepMutation,
    useUpsertProgramTemplateMutation,
    useCheckIfProgramTemplateCanBeSafelyDeletedQuery,
    useRequestToLeaveCareProgramMutation,
    useConnectCaseToProgramMutation,
    useCreateCareProgramInstanceMutation,
    useSendProgramStepReminderMutation,
    useSendProgramStepSpecialRequestMutation,
} = ProgramApi;
