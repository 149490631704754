import Flex from "components/grid/Flex";
import Tag from "components/tags/Tag";
import { useState, useRef } from "react";
import ArrowIcon from "@material-ui/icons/ArrowForwardIos";
import moment from "moment/min/moment-with-locales";
import { useTranslation } from "react-i18next";

const TagsCell = ({ programsData, requestedToUnsubscribeNames, selectedPrograms, toggleProgram }) => {
    const [inContainer, setInContainer] = useState(false);
    const [inPopup, setInPopup] = useState(false);
    const ref = useRef();
    const [isOverflow, setIsOverflow] = useState(false);

    //TODO LM: The flexbox and windowing nudges around the boundingbox enough that overflow detection (like popperjs
    // detectOverflow) just gives up and does all kind of weirdness. A timeout seems to be the best bet
    setTimeout(()=>{
        const scrollWidth = ref?.current?.scrollWidth;
        const clientWidth = ref?.current?.clientWidth;
        setIsOverflow(scrollWidth > clientWidth);
    }, 1000);

    const sortedProgramData = programsData
        ? [...programsData].sort((a, b) => a.suspendedParticipation - b.suspendedParticipation)
        : [];
    const { t, i18n } = useTranslation();
    return (
        <Flex
            item={"1 0 5%"}
            container
            onMouseEnter={() => setInContainer(true)}
            divElementRef={ref}
            onMouseLeave={() => setInContainer(false)}
            style={{
                width: "5%",
                justifyContent: "flex-start",
                alignItems: "center",
                paddingLeft: "10px",
                overflowX: "hidden",
            }}
        >
            {(sortedProgramData || []).map((programData, index) => {
                const { shortName, suspendedParticipation, requestToUnsubscribe } = programData;
                const programName = shortName;
                const terminationIndicator = suspendedParticipation;
                const requestToEndParticipationIndicator = requestToUnsubscribe && !suspendedParticipation;
                return (
                    <Tag
                        key={shortName + index}
                        itemId={shortName + index}
                        {...{
                            selected: selectedPrograms?.includes(shortName),
                            tag: shortName,
                            toggleSelection: terminationIndicator ? () => {} : toggleProgram,
                            selectedBackgroundColor: requestToEndParticipationIndicator
                                ? "#CF3A59"
                                : terminationIndicator
                                  ? "rgba(0,0,0,.22)"
                                  : "",
                            selectedColor: requestedToUnsubscribeNames.includes(programName) ? "white" : "",
                            unSelectedBackgroundColor: requestToEndParticipationIndicator
                                ? "#CF3A59"
                                : terminationIndicator
                                  ? "white"
                                  : "",
                            unSelectedColor: requestToEndParticipationIndicator
                                ? "white"
                                : terminationIndicator
                                  ? "rgba(0,0,0,.3)"
                                  : "",
                        }}
                    />
                );
            })}
            {(inContainer || inPopup) && (
                <div
                    style={{
                        position: "absolute",
                        zIndex: 11,
                        height: 37,
                        display: "flex",
                        alignItems: "center",
                    }}
                    onMouseLeave={() => setInPopup(false)}
                    onMouseEnter={() => setInPopup(true)}
                >
                    {" "}
                    {sortedProgramData.map((programData, index) => {
                        const {
                            shortName,
                            patientTerminationReason,
                            requestToUnsubscribe,
                            clinicTerminationReason,
                            suspendedParticipation,
                            clinicTerminationDate,
                            patientTerminationDate,
                        } = programData;

                        const isTerminatedByClinic = suspendedParticipation && !requestToUnsubscribe; // this should be renamed to patientTerminationReason
                        const patientAskedToTerminate = requestToUnsubscribe && !suspendedParticipation;
                        const patientAskedToTerminateAndClinicTerminated =
                            requestToUnsubscribe && suspendedParticipation;

                        const getFormattedTerminationDate = (date) =>
                            date
                                ? [
                                      i18n.language === "en" ? " at the" : " am",
                                      moment(date)
                                          .locale(i18n.language)
                                          .format(i18n.language === "en" ? "Do [of] MMM YYYY" : "Do MMM YYYY"),
                                  ].join(" ")
                                : "";

                        const patientTerminationReasonWithDefault =
                            patientTerminationReason || t("patient-list.program-status-tooltips.no-patient-comment");
                        const formattedClinicTerminationDate = getFormattedTerminationDate(clinicTerminationDate);
                        const formattedPatientTerminationDate = getFormattedTerminationDate(patientTerminationDate);
                        const terminationText = (() => {
                            /* TODO: refactor this.
                                    Good function is function that works against right data.
                                    change translation json file so it's possible to make this function shorter and without if else statements
                                    example src/components/contacts/DeleteCareProviderAccessModal.jsx contentLabel
                                    */

                            if (isTerminatedByClinic) {
                                return t("patient-list.program-status-tooltips.program-terminated-by-clinic", {
                                    clinicProgramTerminationDate: formattedClinicTerminationDate,
                                    comment: clinicTerminationReason,
                                });
                            } else if (patientAskedToTerminateAndClinicTerminated) {
                                return t(
                                    "patient-list.program-status-tooltips.patient-asked-to-leave-program-and-program-was-terminated",
                                    {
                                        patientProgramTerminationDate: formattedPatientTerminationDate,
                                        clinicProgramTerminationDate: formattedClinicTerminationDate,
                                        comment: patientTerminationReasonWithDefault,
                                    },
                                );
                            } else if (patientAskedToTerminate) {
                                if (patientTerminationReason) {
                                    return t(
                                        "patient-list.program-status-tooltips.patient-asked-to-leave-program-but-program-is-not-yet-terminated",
                                        {
                                            patientProgramTerminationDate: formattedPatientTerminationDate,
                                            comment: patientTerminationReasonWithDefault,
                                        },
                                    );
                                } else {
                                    return t(
                                        "patient-list.program-status-tooltips.patient-asked-to-leave-program-but-program-is-not-yet-terminated-with-no-comment",
                                        { patientProgramTerminationDate: formattedPatientTerminationDate },
                                    );
                                }
                            }
                        })();
                        const requestToEndParticipationIndicator = requestToUnsubscribe && !suspendedParticipation;
                        const terminationIndicator = suspendedParticipation;
                        return (
                            <Tag
                                key={shortName + index}
                                itemId={shortName + index}
                                {...{
                                    selected: selectedPrograms?.includes(shortName),
                                    tag: shortName,
                                    tooltip: terminationText,
                                    toggleSelection: terminationIndicator ? () => {} : toggleProgram,
                                    selectedBackgroundColor: requestToEndParticipationIndicator
                                        ? "#CF3A59"
                                        : terminationIndicator
                                          ? "rgba(0,0,0,.22)"
                                          : "",
                                    selectedColor: requestedToUnsubscribeNames.includes(shortName) ? "white" : "",
                                    unSelectedBackgroundColor: requestToEndParticipationIndicator
                                        ? "#CF3A59"
                                        : terminationIndicator
                                          ? "white"
                                          : "",
                                    unSelectedColor: requestToEndParticipationIndicator
                                        ? "white"
                                        : terminationIndicator
                                          ? "rgba(0, 0, 0, .3)"
                                          : "",
                                }}
                            />
                        );
                    })}
                </div>
            )}
            {isOverflow && !(inContainer || inPopup) && (
                <div
                    style={{
                        position: "absolute",
                        width: ref?.current?.clientWidth + 3,
                        float: "right",
                        zIndex: 11,
                    }}
                >
                    <ArrowIcon
                        style={{
                            float: "right",
                            fill: "grey",
                            fontSize: 15,
                        }}
                    />
                </div>
            )}
        </Flex>
    );
};

export default TagsCell;
