import { ReactComponent as KrebsZweitmeinungLogo } from "assets/img/krebszweitmeinung-logo.svg";
import Flex from "components/grid/Flex";
import CHIP_icon from "assets/img/CHIP_clinic_icon.png";
import LanguageSelector from "components/language-selector/LanguageSelector";
import { useTheme, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const InvalidTokenView = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <Flex item container column center style={{ width: "100%", padding: '20px' }}>
                    <Flex item container justifyContent={'flex-start'} style={{width: '100%'}}>
                        <Flex item container justifyContent={'flex-end'} padding={8}>
                            <LanguageSelector/>
                        </Flex>
                    </Flex>
            <Flex style={{ maxWidth: "460px", marginBottom: theme.spacing(3)}}>
                <Flex item container style={{ marginBottom: 10 }}>
                    <KrebsZweitmeinungLogo style={{ width: 220, height: 70, marginBottom: 40 }} />
                    <img
                        src={CHIP_icon}
                        alt={""}
                        style={{
                            marginLeft: 20,
                            marginTop: 6,
                            width: 176,
                            height: 48.8, //The chip icon is 3.6 ratio
                        }}
                    />
                </Flex>

                <Flex style={{ maxWidth: "450px" }}>
                    <span style={{color: theme.palette.error.dark, fontWeight: 'bold', fontSize: '18px'}}>
                        {(t('register.invalid-invitation-token.title'))}
                    </span>
                    <p>
                        {(t('register.invalid-invitation-token.text-1'))}
                    </p>
                    <p>
                        {(t('register.invalid-invitation-token.text-2'))}
                    </p>


                </Flex>
            </Flex>
            <Button variant="contained" onClick={() => navigate('/')} style={{width: "100px"}} color="primary">OK</Button>
        </Flex>
    );
};
