import React, {Component} from 'react';
import {withStyles, Dialog, Button, Typography} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import * as colors from 'components/colors/Colors';
import {withTranslation} from 'react-i18next';
import Title from 'components/title/Title';
import HmoFilledButton from 'components/button/HmoFilledButton';
import EmailTypeRow from './EmailTypeRow';
import EmailTypeHeader from './EmailTypeHeader';
import {rainForest} from 'components/colors/Colors';

const styles = theme => ({
    cancelButton: {
        fontSize: 13,
        textTransform: "unset",
        color: colors.htmlGrey,
    },
    checkBoxIcon: {
        fill: colors.niagara,
    },
    checkBox: {
        padding: 2
    },
    paper: {
        minWidth: 650
    },
    commaSeparated: {
        '& span ~ span:before':{
            content: '", "'
        }
    }
});

export class PrintEmailsDialog extends Component {
    render() {
        const {
            classes, t: translate, toggleOpen, person, printEmails, open, insuranceCompanyId, caseLogs,
            selectedEmailTypes, selectEmailType, isActOfAssignmentEnabled, isInsuranceCovered
        } = this.props;

        const notEnoughData = !insuranceCompanyId ||
            !(person?.bioSex || person?.salutation) ||
            !person?.givenName ||
            !person?.familyName ||
            !person?.street ||
            !person?.zipCode ||
            !person?.city ||
            !person?.country ||
            !person?.dateOfBirth;
        return (
            <Dialog open={open} maxWidth={'md'} classes={{paper: classes.paper}}>
                <Flex item container column padding={20}>
                    <Flex id={'header'} item container alignItems={"center"} justifyContent={"space-between"}
                          style={{paddingBottom: 10, borderBottom: `1px solid ${colors.grey}`}}
                    >
                        <Flex item container alignItems={"center"}>
                            <Title style={{color: colors.niagara, textTransform: 'none'}}>
                                {translate("global.print-letters")}
                            </Title>
                        </Flex>
                        <Flex item container justifyContent={"flex-end"}>
                            <Button onClick={toggleOpen} className={classes.cancelButton} style={{marginRight: 10}}>
                                {translate("global.cancel")}
                            </Button>

                            <HmoFilledButton
                                style={{backgroundColor: colors.niagara, color: 'white'}}
                                disabled={notEnoughData}
                                onClick={printEmails}
                            >
                                {translate("global.print-email")}
                            </HmoFilledButton>
                        </Flex>
                    </Flex>
                    {
                        notEnoughData &&
                        <Flex item column container style={{marginTop: 10, color: 'orange', fontWeight: 'bold'}}>
                            <Typography>{translate('global.attention')}!:</Typography>
                            <Typography>{translate('global.minimum-patient-data-pdf')}</Typography>
                        </Flex>
                    }
                    <Flex item container style={{ color: 'orange', fontWeight: 'bold'}} className={classes.commaSeparated}>
                        {
                            insuranceCompanyId ? '' : <span>{translate("case.insurance-company")}</span>
                        }
                        {
                            person &&
                            [
                                ...(!person.bioSex ? [['bioSex', 'sex']] : []),
                                ...(!person.givenName ? [['givenName', 'first-name']] : []),
                                ...(!person.familyName ? [['familyName', 'last-name']] : []),
                                ...(!person.street ? [['street', 'street']] : []),
                                ...(!person.zipCode ? [['zipCode', 'zip']] : []),
                                ...(!person.city ? [['city', 'city']] : []),
                                ...(!person.country ? [['country', 'country']] : []),
                                ...(!person.dateOfBirth ? [['dateOfBirth', 'date-of-birth']] : [])
                            ].map(pair => <span key={pair[1]}> {translate(`case.${pair[1]}`)} </span>)
                        }
                    </Flex>
                    <Flex item container style={{borderBottom: '1px solid lightgrey', margin: '10px 0px'}}>
                        <Typography style={{textTransform: 'uppercase', color: rainForest}}>
                            {translate('global.print-letter-comment')}
                        </Typography>
                    </Flex>
                    <Flex item container column style={{
                        marginBottom: 10
                    }}
                    >
                        <EmailTypeHeader/>
                        {
                            (['WELCOME', 'COST_NOTE', 'POWER_OF_ATTORNEY', 'ACT_OF_ASSIGNMENT', 'QUESTIONNAIRE']).map((type, i) => (
                                <EmailTypeRow key={i} {...{
                                    classes, translate, type, caseLogs, isActOfAssignmentEnabled, selectedEmailTypes,
                                    selectEmailType, notEnoughData, isInsuranceCovered, doesUserExists: true
                                }}/>
                            ))
                        }
                    </Flex>

                </Flex>
            </Dialog>
        );
    }
}

export default withStyles(styles)(withTranslation()(PrintEmailsDialog));
