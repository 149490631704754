import * as actionTypes from './CaseEdit.action.types';

export function fetchNewCase() {
    return {
        type: actionTypes.FETCH_NEW_CASE,
        payload: {
            client: 'api',
            request: {
                url: '/new-case',
                method: 'get',
            }
        }
    };
}

export function fetchCase(caseBusinessId) {
    return {
        type: actionTypes.FETCH_CASE,
        payload: {
            client: 'api',
            request: {
                url: `/case/${caseBusinessId}`,
                method: 'get',
            }
        }
    };
}

export function updateCase(caseInput) {
    return {
        type: actionTypes.UPDATE_CASE,
        data: caseInput
    }
}

export function updatePatient(patientInput) {
    return {
        type: actionTypes.UPDATE_PATIENT,
        data: patientInput
    }
}

export function saveCase(data) {
    return {
        type: actionTypes.SAVE_CASE,
        payload: {
            client: 'api',
            request: {
                url: '/case',
                method: 'post',
                data
            }
        }
    }
}

export function saveCaseSecondOpinion(data) {
    return {
        type: actionTypes.SAVE_CASE,
        payload: {
            client: 'api',
            request: {
                url: '/update-case-second-opinion',
                method: 'post',
                data
            }
        }
    }
}

export function addCaseLog(comment, caseBusinessId) {
    return {
        type: actionTypes.ADD_LOG,
        payload: {
            client: 'api',
            request: {
                url: '/case-log',
                method: 'post',
                data: {
                    comment,
                    caseBusinessId
                }
            }
        }
    }
}

export function addNewPatientQuestionLog(text, caseBusinessId) {
    return {
        type: actionTypes.ADD_NEW_PATIENT_QUESTION_LOG,
        payload: {
            client: 'api',
            request: {
                url: '/patient-case-log',
                method: 'post',
                data: {
                    text,
                    caseBusinessId
                }
            }
        }
    }
}

export function fetchPatientCoordinates(patientBusinessId) {
    return {
        type: actionTypes.FETCH_PATIENT_COORDINATES,
        payload: {
            client: 'api',
            request: {
                url: `/patient/${patientBusinessId}/coordinates`,
                method: 'get',
            }
        }
    }
}

export function reset() {
    return {
        type: actionTypes.RESET
    }
}

export function deleteEmptyCase(caseBusinessId) {
    return {
        type: actionTypes.DELETE_EMPTY_CASE,
        payload: {
            client: 'api',
            request: {
                url: `/delete-empty-case/${caseBusinessId}`,
                method: 'delete',
            }
        }
    }
}

export function cancelCase(dataDeletionType, caseInput) {
    return {
        type: actionTypes.CANCEL_CASE,
        payload: {
            client: 'api',
            request: {
                url: '/cancel-case',
                method: 'post',
                data: {
                    caseInput: caseInput,
                    dataDeletionType
                }
            }
        }
    }
}

export function reopenCase(caseBusinessId) {
    return {
        type: actionTypes.REOPEN_CASE,
        payload: {
            client: 'api',
            request: {
                url: `/reopen-case/${caseBusinessId}`,
                method: 'post',
            }
        }
    }
}

export function cloneCase(caseBusinessId) {
    return {
        type: actionTypes.CLONE_CASE,
        payload: {
            client: 'api',
            request: {
                url: `/clone-case/${caseBusinessId}`,
                method: 'put',
            }
        }
    }
}

export function updateSecondOpinionDetail(props, caseBusinessId) {
    return dispatch => {
        return dispatch({
            type: actionTypes.UPDATE_SECOND_OPINION_DETAIL,
            payload: {
                client: 'api',
                request: {
                    url: '/second-opinion',
                    method: 'post',
                    data: {
                        ...props,
                        caseBusinessId
                    }
                }
            }
        }).then(() => {
            return dispatch(fetchCase(caseBusinessId))
        })
    }
}

export function deleteSecondOpinionDetail(secondOpinionBusinessId, caseBusinessId) {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.DELETE_SECOND_OPINION_DETAIL,
            payload: {
                client: 'api',
                request: {
                    url: `/second-opinion/${secondOpinionBusinessId}`,
                    method: 'delete',
                }
            }
        }).then(() => {
            return dispatch(fetchCase(caseBusinessId));
        })
    }
}

export function confirmSecondOpinion(caseBusinessId) {
    return dispatch => {
        return dispatch({
            type: actionTypes.CONFIRM_SECOND_OPINION,
            payload: {
                client: 'api',
                request: {
                    url: `/confirm-second-opinion/${caseBusinessId}`,
                    method: 'put',
                }
            }
        }).then(() => {
            return dispatch(fetchCase(caseBusinessId))
        })
    }
}

export function listCaseEditorMatches(caseBusinessId) {
    return {
        type: actionTypes.LIST_CASE_EDITOR_MATCHES,
        payload: {
            client: 'api',
            request: {
                url: `/case-editor-matches/${caseBusinessId}`,
                method: 'get',
            }
        }
    }
}

export function upsertCaseEditorMatch(caseBusinessId, caseManagerBusinessId) {
    return {
        type: actionTypes.UPSERT_CASE_EDITOR_MATCH,
        payload: {
            client: 'api',
            request: {
                url: `/case-editor-match`,
                method: 'post',
                data: {
                    caseBusinessId,
                    caseManagerBusinessId
                }
            }
        }
    }
}

export function removeCaseEditorMatch(caseBusinessId, caseManagerBusinessId) {
    return {
        type: actionTypes.REMOVE_CASE_EDITOR_MATCH,
        payload: {
            client: 'api',
            request: {
                url: `/case-editor-match`,
                method: 'delete',
                data: {
                    caseBusinessId,
                    caseManagerBusinessId
                }
            }
        }
    }
}
