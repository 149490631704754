import CustomMonthPicker from "./CustomMonthPicker";
import React from "react";

const DataListMonthPicker = ({label, item, onChange, fieldName='date', disabled, ...props}) => {
    return <CustomMonthPicker
            name={fieldName}
            disabled={disabled}
            type="date"
            label={label}
            value={item?.[fieldName] || ""}
            disableFuture
            onChange={(e) => {
                onChange({
                    id: item.id,
                    field: fieldName,
                    props: {value: e.target.value},
                });
            }}
            {...props}
    />;
};

export default DataListMonthPicker;
