import {makeStyles} from "@material-ui/core/styles";
import {Switch, Box} from '@material-ui/core';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    switchLabel: {
        transition: theme.transitions.create("all", {duration: ".2s"}),
        fontSize: "10px",
        textTransform: "uppercase",
    },
    activeLabel: {
        color: theme.palette.text.primary,
    },
    inactiveLabel: {
        color: theme.palette.text.disabled,
    },
}));

const StatusSwitch = ({status, disabled, toggleStatus}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    return <Box>
        {" "}
        <span
                className={[
                    !status
                            ? classes.activeLabel
                            : classes.inactiveLabel,
                    classes.switchLabel,
                ].join(" ")}
        > {t("global.program-step-status-pending")} </span>
        {" "}
        <Switch
                disabled={disabled}
                onChange={toggleStatus}
                size="small"
                checked={status}
                color="primary"
        />
        {" "}
        <span
                className={[
                    status
                            ? classes.activeLabel
                            : classes.inactiveLabel,
                    classes.switchLabel,
                ].join(" ")}
        > {" "} {t("global.program-step-status-completed")} </span>
    </Box>;
};

export default StatusSwitch;
