import React, {Component} from 'react';
import {connect} from 'react-redux';
import connector from './CaseList.connect';
import {withTranslation} from 'react-i18next';
import {Typography} from '@material-ui/core';
import moment from 'moment';

export class CaseListTimer extends Component {
    state = {
        time: ''
    }

    timeRefresher = null;

    componentDidMount() {
        this.timeRefresher = setInterval(() => this.setState({
                time: moment().subtract(this.props.lastCaseListRefresh).format('mm:ss')
            }), 1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.timeRefresher);
    }

    render() {
        const {t: translate} = this.props;
        const {time} = this.state;

        return (
            <Typography>
                {translate('global.last-updated')}: {time}
            </Typography>
        );
    }
}

export default withTranslation()(connect(connector.mapStateToProps, connector.mapDispatchToProps)(CaseListTimer));
