import React, {Component} from 'react';
import {Typography, Button, MenuItem} from "@material-ui/core";
import {withTranslation} from 'react-i18next';
import Flex from 'components/grid/Flex';
import utils from 'utils/Utils';
import MultiInput from 'components/input/MultiInput';
import ConfirmationDialog from 'components/confirmation-dialog/ConfirmationDialog';
import EpiAlert from 'components/alert/EpiAlert';
import DefaultTextField from 'components/hmo-textfield/DefaultTextField';
import Accordion from 'components/grid/Accordion';
import {connect} from 'react-redux';
import connector from './Contact.connect';
import update from 'immutability-helper';
import MultiInputEmailAddressEditor from 'scenes/user-manager/access-manager/MultiInputEmailAddressEditor';
import {ASSISTANT} from 'domain/User.model';
import {CARE_PROVIDER} from 'domain/EntityAuthorizationType.model';

export class Subordinate extends Component {

    state = {
        contact: {},
        oldContact: {},
        isDeleteDialogOpen: false,
    }

    componentDidMount() {
        this.reset();
    }

    reset = () => {
        if (this.props.userDetailsId) {
            this.props.getUserDetails(this.props.userDetailsId)
                    .then(response => {
                        this.setState({
                            contact: response?.payload?.data,
                            oldContact: response?.payload?.data
                        });
                    });
        }
    }

    save = event => {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.props.updateUserDetails(this.state.contact).then(() => {
            this.reset();
        });
    }

    changeState = event => {
        this.setState({
            contact: update(this.state.contact, {
                [event.target.name]: {$set: event.target.value},
            }),
        });
    }

    handlePhoneNumbersChange = (props) => {
        this.changeState({
            target: {
                name: 'phoneNumbers',
                value: props.phoneNumbers
            }
        });
    }

    handleEmailAddressesChange = (props) => {
        this.changeState({
            target: {
                name: 'emailAddresses',
                value: props.emailAddresses
            }
        });
    }

    toggleDeleteDialog = () => {
        this.setState({
            isDeleteDialogOpen: !this.state.isDeleteDialogOpen
        });
    }

    deleteUserDetails = () => {
        this.props.deleteUserDetails(this.state.contact.id).then(() => {
            this.props.refresh();
        });
    }

    closeSnackbar = () => {
        this.setState({alert: ''});
    }

    render() {
        const {t: translate, configuration, style, refresh, careProviderId} = this.props;
        const {changeState, toggleDeleteDialog, deleteUserDetails, save} = this;
        const {isDeleteDialogOpen, contact} = this.state;
        const isContactChanged = utils.anyPropertyChanged(this.state.oldContact, contact);
        return (
                <Flex item container style={style}>
                    <Accordion openByDefault={!contact.givenName} titleComponent={
                        <Flex item container justifyContent={'space-between'} alignItems={'center'}>
                            <Typography style={{
                                fontWeight: 'bold'
                            }}>
                                {translate('global.secretary')}: {utils.formattedUserName(contact)}
                            </Typography>

                            <Flex item={'0 1 auto'} container alignItems={'center'}>
                                <Button size={'small'} variant={'outlined'} onClick={toggleDeleteDialog}
                                        style={{margin: '0px 10px'}}>
                                    {translate('global.delete')}
                                </Button>
                                {
                                    isContactChanged &&
                                    <Button size={'small'}
                                            style={{backgroundColor: 'orange'}}
                                            onClick={save}>
                                        {translate('global.save')}
                                    </Button>
                                }
                            </Flex>
                        </Flex>
                    }>
                        <Flex container column>
                            <Flex id={'first-line'} item container wrap>
                                <Flex item container>
                                    <DefaultTextField label={translate("case.salutation")}
                                                      style={{width: '100%'}}
                                                      select
                                                      SelectProps={{
                                                          value: contact.salutation || '',
                                                          name: 'salutation',
                                                          onChange: changeState
                                                      }}>
                                        {configuration.salutations && configuration.salutations.map(salutation => (
                                                <MenuItem key={salutation} value={salutation}>
                                                    {translate(`global.${salutation}`)}
                                                </MenuItem>
                                        ))}
                                    </DefaultTextField>
                                    <DefaultTextField value={contact.title}
                                                      name={'title'}
                                                      style={{width: '100%'}}
                                                      label={translate("case.title")}
                                                      onChange={changeState}/>
                                </Flex>
                                <Flex item container>
                                    <DefaultTextField value={contact.givenName || ''}
                                                      label={translate("case.first-name")}
                                                      style={{width: '100%'}}
                                                      name={'givenName'}
                                                      onChange={changeState}/>

                                    <DefaultTextField label={translate("case.last-name")}
                                                      value={contact.familyName || ''}
                                                      onChange={changeState}
                                                      style={{width: '100%'}}
                                                      name={'familyName'}/>

                                </Flex>
                            </Flex>

                            <Flex id={'third-line'} item container>
                                <Flex item container column>
                                    <MultiInput {...{
                                        inputValues: contact.phoneNumbers,
                                        translate,
                                        name: 'phoneNumbers',
                                        updateInputValues: this.handlePhoneNumbersChange
                                    }}/>
                                </Flex>
                                <Flex item container column>
                                    {
                                        contact.userEmailAddress && !contact.emailAddresses.includes(contact.userEmailAddress) &&
                                        <DefaultTextField value={contact.userEmailAddress || ''}
                                                          label={translate("global.user-email-address")}
                                                          disabled
                                                          onChange={() => {
                                                          }}/>
                                    }
                                    <MultiInputEmailAddressEditor {...{
                                        entityIdToAssign: careProviderId,
                                        entityType: CARE_PROVIDER,
                                        emailAddresses: contact.emailAddresses,
                                        invitedRoleType: ASSISTANT,
                                        person: contact,
                                        updateInputValues: this.handleEmailAddressesChange,
                                        refresh
                                    }}/>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Accordion>
                    <ConfirmationDialog {...{
                        dialogOpen: isDeleteDialogOpen,
                        onDialogClose: toggleDeleteDialog,
                        onConfirm: deleteUserDetails,
                        confirmationTextKey: "clinic.delete-contact",
                        title: translate("clinic.delete-contact-title")
                    }}/>
                    <EpiAlert
                            {...{
                                isOpen: !!this.state.alert,
                                close: this.closeSnackbar,
                                severity: this.state.alert === 'error' ? this.state.alert : 'success',
                                message:
                                        this.state.alert === 'error'
                                                ? translate('global.backend-call-failed')
                                                : this.state.alert
                                                        ? translate(`global.${this.state.alert}`)
                                                        : '',
                            }}
                    />
                </Flex>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(Subordinate));
