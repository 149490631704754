import React, {Component} from 'react';
import {
    withStyles,
    Button
} from "@material-ui/core";
import * as colors from 'components/colors/Colors';

const styles = theme => ({
    root: {
        backgroundColor: '#245B67',
        fontWeight: 'bold',
        height: 34,
        fontSize: 13,
        textTransform: 'none',
        color: colors.white,
        borderRadius: 10,
        '&:hover': {
            backgroundColor: colors.lightCharcoalGrey,
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: colors.lightCharcoalGrey,
        },
    },
});

export class HmoFilledButton extends Component {

    render() {
        const {classes, children, fullWidth, ...other} = this.props;
        return (
            <Button
                fullWidth={fullWidth}
                size={'small'}
                {...{
                    variant: "contained",
                    classes: {
                        ...classes
                    },
                    ...other,
                }}
            >
                {children}
            </Button>
        );
    }
}

export default withStyles(styles)(HmoFilledButton);
