import React, {Component, Fragment} from 'react';
import {
    Typography,
    IconButton,
    Dialog,
    CircularProgress, TextareaAutosize
} from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import Flex from 'components/grid/Flex';
import {
    grey,
    htmlBlue,
    htmlWhite,
    htmlLightGrey,
    concrete,
    htmlGrey
} from 'components/colors/Colors';
import {withTranslation} from 'react-i18next';
import HmoTextField from 'components/hmo-textfield/HmoTextField';
import HmoAvatar from 'components/avatar/HmoAvatar';
import HmoInputLabel from 'components/input-label/HmoInputLabel';
import moment from 'moment';
import _ from 'lodash';

let QuestionLog = props => {
    const {translate, questions} = props;
    return (
        <Flex container direction={'column'} padding={15}>
            <Flex item container style={{padding: 7, marginBottom: 5, borderBottom: `1px solid ${grey}`}}
                  alignItems={'center'}>
                <Typography variant={'h5'}
                            style={{
                                marginLeft: 10,
                                color: htmlGrey
                            }}
                >
                    {translate('case.patient-question')} - Log
                </Typography>
            </Flex>
            {
                questions.map(question => (
                    <Flex item container direction={'column'} key={question.timestamp} style={{margin: 7}}>
                        <Flex item container alignItems={'center'}>
                            <Typography style={{fontWeight: 'bold', fontSize: '1rem'}}>
                                {moment(question.timestamp).format('DD. MMM YYYY')}
                            </Typography>
                            <Typography style={{marginLeft: 10, marginRight: 10, color: grey}}>
                                {moment(question.timestamp).format('HH:mm')}
                            </Typography>
                            <HmoAvatar {...{user: question.user}}
                                       placement={'bottom-start'}
                                       style={{
                                           width: 20,
                                           height: 20,
                                           fontSize: 12,
                                       }}/>
                        </Flex>
                        <Flex item container style={{
                            padding: 6,
                            paddingBottom: 11,
                            border: `1px solid ${htmlLightGrey}`,
                            borderRadius: 4,
                            backgroundColor: concrete,
                            width: '100%'
                        }}>
                            <Typography>{question.text}</Typography>
                        </Flex>
                    </Flex>
                ))
            }
        </Flex>
    )
};

export class Question extends Component {

    state = {
        newQuestion: '',
        isQuestionLogOpen: false,
        isQuestionLogBeingSaved: false
    };

    componentDidMount() {
        this.setState({
            newQuestion: _.get(this.props, 'patientQuestionLogs[0].text')
        })
    }

    componentWillUnmount() {
        if (this.state.newQuestion !== _.get(this.props, 'patientQuestionLogs[0].text')) {
            this.props.addNewPatientQuestionLog(this.state.newQuestion, this.props.caseBusinessId);
        }
    }

    toggleQuestionLog = () => {
        this.setState({
            isQuestionLogOpen: !this.state.isQuestionLogOpen
        })
    };

    handleQuestionChange = event => {
        this.setState({
            newQuestion: event.target.value
        })
    };

    handleQuestionSave = () => {
        this.setState({
                isQuestionLogBeingSaved: true
            },
            () => this.props.addNewPatientQuestionLog(this.state.newQuestion, this.props.caseBusinessId)
                .then(
                    () => this.setState({isQuestionLogBeingSaved: false})
                )
        )
    };

    render() {
        const {t: translate, patientQuestionLogs, disabled, isEditDisabled} = this.props;
        const {handleQuestionChange, toggleQuestionLog, handleQuestionSave} = this;
        const {newQuestion, isQuestionLogOpen, isQuestionLogBeingSaved} = this.state;

        return (
            <Fragment>
                <Flex item container column>
                    <Flex item container justifyContent={'space-between'}>
                        <HmoInputLabel>{translate('case.questions-of-patient')}</HmoInputLabel>
                        <Typography onClick={toggleQuestionLog}
                                    style={{color: htmlBlue, textDecoration: 'underline'}}>Log...</Typography>
                    </Flex>
                    <Dialog open={isQuestionLogOpen} onClose={toggleQuestionLog}>
                        <QuestionLog {...{
                            translate, questions: patientQuestionLogs
                        }}/>
                    </Dialog>
                </Flex>

                <Flex item container style={{position: 'relative'}}>
                    {
                        !isQuestionLogBeingSaved && newQuestion !== _.get(patientQuestionLogs, '[0].text') &&
                        <IconButton
                            onClick={handleQuestionSave}
                            disabled={isEditDisabled}
                            style={{padding: 4, position: "absolute", right: 3, top: 3, zIndex: 1}}
                        >
                            <CheckIcon/>
                        </IconButton>
                    }
                    {
                        isQuestionLogBeingSaved &&
                        <CircularProgress
                            style={{padding: 4, position: "absolute", right: 3, top: 3, zIndex: 1}}
                        />
                    }
                    <HmoTextField
                        value={newQuestion || ''}
                        multiline
                        variant={'outlined'}
                        minRows={4}
                        onChange={handleQuestionChange}
                        fullWidth
                        disabled={disabled || isEditDisabled}
                        name={'newQuestion'}
                        inputPropsStyle={{
                            paddingRight: 30
                        }}
                        InputProps={{
                            style: {
                                padding: 5
                            },
                            as: TextareaAutosize
                        }}
                        style={{
                            backgroundColor: htmlWhite,
                            marginBottom: 10
                        }}
                    />
                </Flex>
            </Fragment>
        );
    }
}

export default withTranslation()(Question);
