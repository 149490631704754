import React, {Component} from 'react';
import {
    withStyles,
    Typography,
    IconButton,
    Dialog,
    TextField
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {
    grey,
    htmlWhite,
    rainForest,
    transparentBlack03
} from 'components/colors/Colors';
import {withTranslation} from 'react-i18next';
import _ from 'lodash';
import MapIcon from '@material-ui/icons/Map';
import Map from 'scenes/case/edit/component/clinic/Map';
import {connect} from 'react-redux';
import connector from './ClinicPicker.connect';
import {Autocomplete} from '@material-ui/lab';

const styles = theme => ({
    clinicSelectRoot: {
        backgroundColor: htmlWhite,
        color: rainForest,
        borderRadius: 4,
        paddingLeft: 7,
    },
    clinicSelectRootLight: {
        backgroundColor: htmlWhite,
        color: transparentBlack03,
        borderRadius: 4,
        paddingLeft: 7,
    },
    inputRoot: {
        backgroundColor: htmlWhite,
        borderRadius: 4
    }
});

export class ClinicPicker extends Component {

    state = {
        isMapVisible: false
    };

    handleClinicCenterChange = clinicCenter => {
        this.props.updateCase({clinicCenterBusinessId: clinicCenter.businessId});
        this.toggleMap();
    };

    selectClinicCenter = (event, clinicCenter) => {
        this.props.updateCase({clinicCenterBusinessId: clinicCenter.businessId});
    };

    toggleMap = () => {
        this.setState({isMapVisible: !this.state.isMapVisible});
    };

    render() {
        const {
            classes, t: translate, clinicCertifications, subIndications, clinicCenters : origClinicCenters,
            clinicCenterBusinessId, fetchPatientCoordinates, style, disabled, selectedIndication, patientBusinessId
        } = this.props;
        const clinicCenters = selectedIndication != null ? origClinicCenters.filter(e => e.indications.indexOf( selectedIndication) !== -1 ) : origClinicCenters;
        const {isMapVisible} = this.state;
        const {handleClinicCenterChange, selectClinicCenter, toggleMap} = this;
        const center = clinicCenters.find(clinicInArray => clinicInArray.businessId === clinicCenterBusinessId) || '';
        return (
            <Flex item container style={style}>
                <Flex item container direction={'column'}>
                    <Typography style={{
                        textTransform: 'uppercase',
                        color: grey,
                        fontSize: 12
                    }}>{translate('case.assigned-clinic')}</Typography>
                    <Flex item container>
                        <Autocomplete
                            options={clinicCenters || []}
                            onChange={selectClinicCenter}
                            name={'clinic'}
                            disabled={disabled}
                            value={center || null}
                            style={{width: '100%'}}
                            getOptionSelected={(option, value) => {
                                return option.businessId === value.businessId
                            }}
                            getOptionLabel={clinic => clinic.name}
                            renderOption={clinic => clinic.name}
                            renderInput={params => (<TextField
                                {...params}
                                fullWidth
                                classes={{root: classes.inputRoot}}
                                inputProps={{...params.inputProps, style: {marginLeft: 5}}}
                                placeholder={translate('global.please-select')}/>)}
                        />
                        {
                            !_.isEmpty(clinicCenters) &&
                            <IconButton onClick={toggleMap} style={{padding: 5}} disabled={disabled}>
                                <MapIcon/>
                            </IconButton>
                        }
                    </Flex>
                </Flex>
                <Dialog open={isMapVisible} onClose={toggleMap} maxWidth={false}>
                    <Map {...{
                        fetchPatientCoordinates,
                        clinicCenters,
                        clinicCenter: clinicCenters.find(item => item.businessId === clinicCenterBusinessId),
                        handleClinicCenterChange,
                        translate,
                        toggleMap,
                        certifications: clinicCertifications,
                        subIndications,
                        patientBusinessId
                    }}/>
                </Dialog>
            </Flex>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(ClinicPicker)));
