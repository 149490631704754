import * as actionTypes from './Case.action.types';
import {LOAD_SETUP, LOAD_SETUP_DONE} from 'scenes/Main.action.types';
import {listClinics} from 'scenes/clinics/Clinics.action';
import _ from 'lodash';
import {getOutstandingRegisteredInvites} from 'scenes/user-manager/UserManager.action';
import {listCareProviders} from 'scenes/care-provider/CareProvider.action';

export function setupCaseConfigurations() {
    return dispatch => {
        dispatch({type: LOAD_SETUP});
        return Promise.allSettled([
                dispatch(fetchCaseManagers()),
                dispatch(fetchConfiguration()),
                dispatch(listClinics()),
                dispatch(listCareProviders()),
                dispatch(getOutstandingRegisteredInvites())
            ]
        ).then(response => {
            dispatch({type: LOAD_SETUP_DONE});
            return response;
        });
    }
}

export function fetchConfiguration() {
    return {
        type: actionTypes.FETCH_CONFIGURATION,
        payload: {
            client: 'api',
            request: {
                url: '/configuration',
                method: 'get'
            }
        }
    }
}

export function fetchCaseManagers() {
    return {
        type: actionTypes.LIST_CASE_MANAGERS,
        payload: {
            client: 'api',
            request: {
                url: '/case-managers',
                method: 'get'
            }
        }
    }
}

export function listCases() {
    return (dispatch) => {
        return dispatch({
            type: actionTypes.LIST_CASES,
            payload: {
                client: 'api',
                request: {
                    url: '/cases',
                    method: 'post',
                }
            }
        })
    }
}

export function updateFilters(filters) {
    return {
        type: actionTypes.UPDATE_CASE_LIST_FILTERS,
        data: {filters}
    }
}

export function updatePanels(panels) {
    return {
        type: actionTypes.UPDATE_CASE_LIST_PANELS,
        data: {panels}
    }
}

export function acceptCase(_case) {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.ACCEPT_CASE,
            payload: {
                client: 'api',
                request: {
                    url: '/accept-case',
                    method: 'post',
                    data: {
                        caseBusinessId: _case.businessId,
                        clinicCenterId: _.get(getState(), 'mainReducer.user.clinicCenterId')
                    }
                }
            }
        });
    }
}

