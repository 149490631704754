import FormSection from "../FormSection";
import {Grid, IconButton, Modal} from "@material-ui/core";
import styled from "styled-components";
import React, {useCallback, useState} from "react";
import ChipDataTable from "../input/ChipDataTable";
import Plus from "@material-ui/icons/Add";
import {makeStyles} from "@material-ui/core/styles";
import update from "immutability-helper";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import BooleQuestion from "../input/BooleQuestion";
import {MutationModalForm} from "./MutationModalForm";
import { QuestionWithFiltering } from "./QuestionWithFiltering";

const QuestionSubTitle = styled.h4`
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
`;

const useStyles = makeStyles((_theme) => ({
    iconPlusLabel: {
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "14px",
        color: "#256AF6",
        borderRadius: "7px",
        marginTop: "1rem",
        marginBotom: "1rem",
        paddingTop: "5px",
        paddingRight: "8px",
        paddingBottom: "3px",
        paddingLeft: "13px",
        backgroundColor: "#c7eaf9",
        letterSpacing: "0.6px",
    },

    iconPlus: {
        marginLeft: "10px",
    },
    "& .MuiIconButton-root": {},
    IconPlus_Text: {
        paddingBottom: "0.2rem",
    },
}));

const ChipForm = ({form, readonly, filters}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const lang = useCallback((name) => t(`anamnesis.chip.${name}`), [t]);

    const [isModelOpen, setModelOpen] = useState(false);
    const [editedRowId, setEditedRowId] = useState(null);

    const handleOnRowSubmit = ({gene, dna, protein, vaf, date, exon, source}) => {
        let modifiedVaf;
        try {
            const fVaf = parseFloat(vaf);
            modifiedVaf = fVaf < 1 ? fVaf * 100 : fVaf;
        } catch (e) {
            modifiedVaf = "NaN";
        }

        if (editedRowId) {
            // Update entry logic
            const index = form.values["chipMutationData"].findIndex((item) => item.id === editedRowId);
            const updatedTable = update(form.values["chipMutationData"], {
                [index]: {
                    gene: {$set: gene},
                    dna: {$set: dna},
                    protein: {$set: protein},
                    vaf: {$set: modifiedVaf},
                    date: {$set: date},
                    source: {$set: source },
                    exon: {$set: String(Number(exon))},
                },
            });
            form.setFieldValue("chipMutationData", updatedTable);
            setEditedRowId(null);
        } else {
            // Create entry login
            const data = update(form.values, {
                chipMutationData: {
                    $push: [{
                        id: _.uniqueId(""),
                        gene,
                        dna,
                        protein,
                        vaf: modifiedVaf,
                        date,
                        exon: String(Number(exon)),
                        source
                    }],
                },
            });
            form.setFieldValue("chipMutationData", data.chipMutationData);
        }
        setModelOpen(false);
    };
    const onEditRowHandler = (rowId) => {
        setEditedRowId(rowId);
        setModelOpen(true);
    };

    const rowToEdit = form.values?.["chipMutationData"]?.find((row) => row.id === editedRowId);

    return (
            <FormSection title={lang("title")}>
                <QuestionWithFiltering filters={filters} filterValue={form.values?.hasChipMutation}>
                <BooleQuestion form={form} disabled={readonly} fieldName="hasChipMutation" lang={lang} info/>

                {form.values?.["hasChipMutation"] !== null && form.values?.["hasChipMutation"] === true && (
                        <>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <QuestionSubTitle>{lang("hasChipMutationSubQuestion")}</QuestionSubTitle>
                                </Grid>
                            </Grid>

                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <ChipDataTable {...{form, onEditRow: onEditRowHandler, disabled: readonly}} />
                                </Grid>
                            </Grid>

                            <Grid container style={{marginTop: "8px"}} justifyContent={"flex-end"}>
                                <div>
                                    {!readonly && <IconButton onClick={() => setModelOpen(true)} className={classes.iconPlusLabel}>
                                        <div>{t("global.add-mutation")}</div>
                                        <div className={classes.iconPlus}>
                                            <Plus/>
                                        </div>
                                    </IconButton>}
                                </div>
                            </Grid>
                        </>
                )}
                </QuestionWithFiltering>

                <Modal open={isModelOpen}>
                    <div>
                        <MutationModalForm
                                onOpen={() => {
                                    setModelOpen(true);
                                }}
                                onClose={() => {
                                    setEditedRowId(null);
                                    setModelOpen(false);
                                }}
                                onSubmit={handleOnRowSubmit}
                                rowToEdit={rowToEdit}
                        />
                    </div>
                </Modal>
            </FormSection>
    );
};

export default ChipForm;
