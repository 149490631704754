import {logout, setHandledOutstandingInvites} from 'scenes/Main.action';
import {fetchCurrentUser, confirmAccess, rejectAccess, getOutstandingRegisteredInvites} from 'scenes/user-manager/UserManager.action';

export default {
    mapStateToProps: (state) => {
        return {
            user: state.mainReducer.user,
            outstandingInvites: state.mainReducer.outstandingInvites,
            clinicCenters: state.clinicsReducer.clinicCentersWithClinicData,
            careProviders: state.careProviderReducer.careProviders,
            handledOutstandingInvites: state.mainReducer.handledOutstandingInvites,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            logout: () => dispatch(logout()),
            fetchCurrentUser: () => dispatch(fetchCurrentUser()),
            getOutstandingRegisteredInvites: () => dispatch(getOutstandingRegisteredInvites()),
            confirmAccess: (invitedEmail) => dispatch(confirmAccess(invitedEmail)),
            rejectAccess: (invitedEmail) => dispatch(rejectAccess(invitedEmail)),
            setHandledOutstandingInvites: (status) => dispatch(setHandledOutstandingInvites(status))
        };
    }
};
