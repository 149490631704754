import {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {deletePatient} from './UserManager.action';
import {useTranslation} from "react-i18next";
import ConfirmationDialog from 'components/confirmation-dialog/ConfirmationDialog';
import {Button} from '@material-ui/core';
import {useParams } from "react-router-dom";
import {useNavigate} from 'react-router-dom';
import {MANAGED_CARE} from 'routes/routes';
import EpiAlert from 'components/alert/EpiAlert';
import { PatientDeletionTypeDialog } from './PatientDeletionTypeDialog';
import { useIsManager } from '../../utils';
import { useIsDoctor } from '../../utils/useIsDoctor';
import {
    useGetCareProvidersForPatientQuery
} from "scenes/patient/PatientApi";
import {RemoveFullAccessToPatientDialog} from './RemoveFullAccessToPatientDialog';

const DeleteUserButton = ({userDetailsId, suggestAnonymize=true}) => {
    const [deleteTypeOpen, setDeleteTypeOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const { patientId } = useParams();
    const [removePatientAccess, setRemovePatientAccess] = useState(false);
    const [alert, setAlert] = useState('');
    const toggle = () => setOpen(open => !open);
    const closeSnackbar = () => setAlert('');
    const {t: translate} = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.mainReducer.user);
    const navigate = useNavigate();
    const doctorRoleSelected = useIsDoctor();
    const isManager = useIsManager();

    const { careProviderIds } = user;
    const {
        data: careProvidersForPatient
    } = useGetCareProvidersForPatientQuery(patientId);

    return (
            isManager
                    ?
                    <div style={{float: 'right'}}>
                        <Button onClick={() => {
                            if(doctorRoleSelected) {
                                setRemovePatientAccess(true);
                            }else if(suggestAnonymize) {
                                setDeleteTypeOpen(true);
                            }else {
                                setOpen(true);
                            }
                        }} color={'secondary'}>
                            {translate('global.delete-patient-button')}
                        </Button>
                        <PatientDeletionTypeDialog open={deleteTypeOpen} onDelete={() => {
                            setDeleteTypeOpen(false);
                            setOpen(true);
                        }}
                        onClose={() => setDeleteTypeOpen(false)}
                        onAnonymize={() => {
                            dispatch(deletePatient(userDetailsId))
                                    .then(()=> navigate(MANAGED_CARE.path))
                                    .catch(()=> setAlert('error'));
                        }}
                         />
                        <ConfirmationDialog {...{
                            dialogOpen: open,
                            onDialogClose: toggle,
                            onConfirm: () => dispatch(deletePatient(userDetailsId, 'HARD_DELETE'))
                                    .then(()=> navigate(MANAGED_CARE.path))
                                    .catch(()=> setAlert('error')),
                            confirmationTextKey: "global.delete-patient",
                            alternativeConfirmLabel: translate('global.delete'),
                            alternativeCancelLabel: translate('global.cancel')
                        }}/>
                        <RemoveFullAccessToPatientDialog
                                patientId={patientId}
                                open={removePatientAccess}
                                onClose={() => setRemovePatientAccess(false)}
                                accessAssignmentsOfCareProviderIdsToDelete={careProviderIds.filter(id => careProvidersForPatient?.find(cp => cp.id === id))}
                                setAlert={setAlert}
                                />

                        <EpiAlert
                                {...{
                                    isOpen: !!alert,
                                    close: closeSnackbar,
                                    severity: alert,
                                    message: alert === 'error'
                                            ? translate('global.backend-call-failed')
                                            : translate(`global.${alert}`)
                                }}
                        />
                    </div>
                    : null
    );
};

export default DeleteUserButton;
