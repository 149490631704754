import {axiosClients} from "store/Clients";

export const AxiosClient = axiosClients.api.client;

export const AxiosBaseQuery =
    ({baseUrl} = {baseUrl: ''}) =>
        async ({url, method, data, params}) => {
            try {
                const result = await AxiosClient({url: baseUrl + url, method, data, params});
                return {data: result.data};
            } catch (axiosError) {
                const err = axiosError;
                return {
                    error: {status: err.response?.status, data: err.response?.data},
                };
            }
        };
