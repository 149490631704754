import { useTheme } from "@material-ui/core/styles";
import { NodeRenderer } from "../NodeRenderer";
import { fields } from "../../../../filter-configs/fields.conf";

export const AndGroupRenderer = ({componentData, indexPath}) => {
    const theme = useTheme();
    return <div style={{ marginTop: theme.spacing(2) }}>
        {componentData.node.properties.value.map((node, innerIdx) => {
            const fieldConfig = fields[node.properties.field];
            const componentData = {
                node,
                index: innerIdx,
                config: fieldConfig,
            };
            return (
                <NodeRenderer
                    key={indexPath.concat(innerIdx).join("-")}
                    componentData={componentData}
                    indexPath={indexPath.concat(innerIdx)}
                    idx={innerIdx}
                    inner={true}
                />
            );
        })}
    </div>;
};
