import { useState, useContext } from "react";
import { useFormik } from "formik";
import {
    Grid,
    Modal,
    Card,
    Box,
    CardHeader,
    CardContent,
    Radio,
    RadioGroup,
    FormControlLabel,
    Button,
    TextField,
    IconButton,
    MenuItem,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { UploadFilesDispatchContext, UploadFilesAction } from "./uploadFilesState";

const UploadTypeModal = ({ isOpen, ...props }) => {
    return isOpen && <UploadTypeModalForm {...props} />;
};

const UploadTypeModalForm = ({ onFilesSelect, onLinkUpload, assignmentTypes, assignmentType }) => {
    const dispatchAction = useContext(UploadFilesDispatchContext);
    const { t } = useTranslation();
    const [selectedUploadVariant, setSelectedUploadVariant] = useState("DOCUMENT");
    //TODO LM: The entire Formik fuckery is only in play if the docuType is LINK otherwise this window is moot as soon as
    // the files are uploading. That's why we can always call onLinkUpload whenever formik submits. This is fragile as shit.
    // Extract the form into its own components, this is confusing.
    const formik = useFormik({
        initialValues: {
            description: "",
            url: "",
            comment: "",
            assignment: assignmentType,
        },
        validationSchema: Yup.object({
            description: Yup.string().required(),
            url: Yup.string().url().required(),
        }),
        onSubmit: (values) => {
            if (formik.isValid) {
                onLinkUpload(values);
                formik.resetForm();
                dispatchAction({ type: UploadFilesAction.ToggleFileUploadTypeDialog });
            }
        },
    });

    const renderAssignmentValue = (assignment) => {
        switch (assignment.name) {
            case "PATIENT":
                return t("global.general-medical-files");
            case "CASE":
                return t("global.second-opinion");
            default:
                return assignment.name;
        }
    };

    return (
        <Modal open={true}>
            <Box mt={20}>
                <Grid container justifyContent="center">
                    <Grid item xs={4}>
                        <Card>
                            <CardHeader
                                title={t("documentUpload.addFile")}
                                action={
                                    <>
                                        <IconButton
                                            onClick={() => {
                                                dispatchAction({ type: UploadFilesAction.ToggleFileUploadTypeDialog })
                                                formik.resetForm();
                                            }}
                                            size="small"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        <IconButton onClick={formik.handleSubmit} size="small">
                                            <CheckIcon />
                                        </IconButton>
                                    </>
                                }
                            />
                            <CardContent>
                                <RadioGroup
                                    value={selectedUploadVariant}
                                    onChange={(_, value) => setSelectedUploadVariant(value)}
                                >
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <FormControlLabel
                                            style={{ width: "50%" }}
                                            value="DOCUMENT"
                                            control={<Radio color="primary" />}
                                            label={t("documentUpload.file")}
                                        />
                                        {selectedUploadVariant === "DOCUMENT" && (
                                            <>
                                                <input
                                                    id="contained-button-file"
                                                    multiple
                                                    type="file"
                                                    style={{ display: "none" }}
                                                    onChange={(e, files) => {
                                                        onFilesSelect(Array.from(e.target.files));
                                                        dispatchAction({ type: UploadFilesAction.ToggleFileUploadTypeDialog })
                                                    }}
                                                />
                                                <label htmlFor="contained-button-file">
                                                    <Button variant="contained" color="primary" component="span">
                                                        {t("documentUpload.chooseFile")}
                                                    </Button>
                                                </label>
                                            </>
                                        )}
                                    </div>
                                    <FormControlLabel value="LINK" control={<Radio color="primary" />} label="Link" />
                                </RadioGroup>
                                {selectedUploadVariant === "LINK" && (
                                    <form>
                                        <TextField
                                            style={{ marginBottom: "10px" }}
                                            fullWidth
                                            name="description"
                                            label={t("documentUpload.description")}
                                            required
                                            error={formik.touched.description && Boolean(formik.errors.description)}
                                            helperText={formik.touched.description && formik.errors.description}
                                            InputLabelProps={{ shrink: true }}
                                            value={formik.values.description}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        <TextField
                                            style={{ marginBottom: "10px" }}
                                            fullWidth
                                            name="url"
                                            label={t("documentUpload.url")}
                                            InputLabelProps={{ shrink: true }}
                                            value={formik.values.url}
                                            required
                                            error={formik.touched.url && Boolean(formik.errors.url)}
                                            helperText={formik.touched.url && formik.errors.url}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                        />
                                        <TextField
                                            fullWidth
                                            style={{ marginBottom: "10px" }}
                                            name="comment"
                                            label={t("documentUpload.comment")}
                                            multiline
                                            InputLabelProps={{ shrink: true }}
                                            value={formik.values.comment}
                                            onChange={formik.handleChange}
                                        />
                                        <TextField
                                            fullWidth
                                            label={t("documentUpload.assignment")}
                                            name="assignment"
                                            select
                                            InputLabelProps={{ shrink: true }}
                                            value={formik.values.assignment}
                                            onChange={formik.handleChange}
                                        >
                                            {assignmentTypes.map((assignmentType) => (
                                                <MenuItem key={assignmentType.businessId} value={assignmentType}>
                                                    {renderAssignmentValue(assignmentType)}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </form>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default UploadTypeModal;
