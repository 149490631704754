
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { isContactPerson } from "domain/User.model";

/**
 * Hook to determine if the current user is a contact person.
 *
 * @returns {boolean} - Returns true if the user is a contactPerson, false otherwise.
 */
export const useIsContactPerson = () => {
    const user = useSelector((state) => state.mainReducer.user);
    return useMemo(() => isContactPerson(user), [user]);
}
