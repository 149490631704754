import Flex from "components/grid/Flex";
import { Dialog, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import DefaultTextField from "components/hmo-textfield/DefaultTextField";
import utils from "utils/Utils";
import { useFetchExistingPersonsQuery } from "scenes/user-manager/UserDetailAccessApi";
import { useSelector } from "react-redux";
import _ from "lodash";
import EmailOutlined from "@material-ui/icons/EmailOutlined";
import {
    CASE_MANAGER_EDITS_CARE_PROVIDER_CONTACT_EMAIL,
    CASE_MANAGER_EDITS_CASE_CONTACT_PERSON_EMAIL,
    CASE_MANAGER_GRANTS_ACCESS_TO_CARE_PROVIDERS_CONTACT_PERSON,
    CASE_MANAGER_GRANTS_ACCESS_TO_PATIENT,
    CASE_MANAGER_GRANTS_ACCESS_TO_PATIENTS_CONTACT_PERSON,
    DOCTOR_CASE_MANAGER_EDITS_PATIENT_EMAIL,
    EDIT,
    getGrantTypeScenario,
    GRANT,
    PATIENT_EDITS_OWN_EMAIL,
    PATIENT_GRANTS_ACCESS_TO_CONTACT_PERSON,
} from "domain/GrantType";
import { Autocomplete } from "@material-ui/lab";
import DefaultDialogBody from "./DefaultDialogBody";
import PatientEditsPatientEmail from "scenes/user-manager/access-manager/PatientEditsPatientEmail";
import ManagerEditsPatientEmail from "scenes/user-manager/access-manager/ManagerEditsPatientEmail";
import CaseManagerEditsCareProviderEmail from "scenes/user-manager/access-manager/CaseManagerEditsCareProviderEmail";
import GrantPatientAccessToContactPerson from "scenes/user-manager/access-manager/GrantPatientAccessToContactPerson";
import CaseManagerEditsCaseContactPerson from "scenes/user-manager/access-manager/CaseManagerEditsCaseContactPerson";
import { isCaseManager } from "domain/User.model";
import Accordion from "components/grid/Accordion";

const DebugWindowForCaseManagers = (props) => {
    const { user, existingPersons } = props;
    return isCaseManager(user) ? (
        <Accordion title={"Information for case manager"}>
            <Flex item container direction={"column"}>
                <Flex item container column style={{ marginBottom: 10 }}>
                    <Typography style={{ fontWeight: "bold" }}>Existing User Account:</Typography>
                    <Typography>
                        Name:{" "}
                        {existingPersons?.existingUser?.givenName + " " + existingPersons?.existingUser?.familyName}
                    </Typography>
                    <Typography>User Id: {existingPersons?.existingUser?.userId}</Typography>
                    <Typography>User Details Id: {existingPersons?.existingUser?.userDetailsId}</Typography>
                </Flex>
                <Flex item container column style={{ marginBottom: 10 }}>
                    <Typography style={{ fontWeight: "bold" }}>Existing Persons:</Typography>
                    {existingPersons?.existingUserDetails?.map((item) => (
                        <Flex key={item?.id} item container style={{ borderBottom: "1px solid grey" }}>
                            <Typography>Name: {item?.givenName + " " + item?.familyName} &nbsp;</Typography>
                            <Typography>User Details Id: {item?.id}</Typography>
                        </Flex>
                    ))}
                </Flex>
                <Flex item container column style={{ marginBottom: 10 }}>
                    <Typography style={{ fontWeight: "bold" }}>Invites:</Typography>
                    {existingPersons?.invites?.map((item, index) => (
                        <Flex key={item?.invitedName+index} item container style={{ borderBottom: "1px solid grey" }}>
                            <Typography>Invited Name: {item?.invitedName} &nbsp;</Typography>
                            <Typography>Creation Date: {item?.creationDate}</Typography>
                        </Flex>
                    ))}
                </Flex>
            </Flex>
        </Accordion>
    ) : null;
};

const AssignmentNotPossible = (props) => {
    const {
        toggleOpen,
        deleteEmail,
        originalEmail,
        saveButtonLabel,
        multipleUserDetails,
        nonMatchingExistingUserAndDetails,
        userAlreadyGrantedAccess,
        differentUserDetailsAlreadyInvited,
        personAlreadyUsingTheEmail,
    } = props;
    const { t: translate } = useTranslation();
    let information = translate("access.general-denied");

    if (multipleUserDetails) {
        information = translate("access.already-used-multiple");
    } else if (nonMatchingExistingUserAndDetails) {
        information = translate("access.already-used");
    } else if (personAlreadyUsingTheEmail) {
        information = translate("access.already-used-self");
    } else if (userAlreadyGrantedAccess) {
        information = translate("access.grant-not-necessary");
    } else if (differentUserDetailsAlreadyInvited) {
        information = translate("access.invitation-denied");
    }

    return (
        <DefaultDialogBody
            {...{
                information,
                toggleOpen,
                save: () => {},
                saveButtonLabel,
                disabled: true,
                deleteEmail,
                originalEmail,
            }}
        />
    );
};

/**
 *
 * person: The person under edit
 * invitedRoleType: The roleType of the person to be edited, it decides what scenario are we working with!
 *
 * CASE_MANAGER_EDITS_CARE_PROVIDER_CONTACT_EMAIL, CASE_MANAGER_EDITS_CASE_CONTACT_PERSON_EMAIL:
 * entityIdToAssign: care provider or case id
 *
 * CASE_MANAGER_EDITS_CARE_PROVIDER_CONTACT_EMAIL, CASE_MANAGER_GRANTS_ACCESS_TO_CARE_PROVIDERS_CONTACT_PERSON:
 * entityType: CARE_PROVIDER or CLINIC_CENTER
 *
 * PATIENT_GRANTS_ACCESS_TO_DOCTOR, PATIENT_GRANTS_ACCESS_TO_CONTACT_PERSON, CASE_MANAGER_GRANTS_ACCESS_TO_PATIENTS_CONTACT_PERSON:
 * accessInputs: granted accesses selected by the user
 * @param props
 */
const EmailAddressEditorDialog = (props) => {
    const {
        onEmailChange,
        deleteEmail,
        originalEmail,
        toggleOpen,
        invitedRoleType,
        person,
        entityIdToAssign,
        entityType,
        refresh,
        scenarioOverride,
        patient,
        title,
        editorType,
        excludedUserIds,
    } = props;
    const { t: translate } = useTranslation();
    const user = useSelector((state) => state.mainReducer.user);
    const scenario = scenarioOverride || getGrantTypeScenario(invitedRoleType, user, editorType);
    const [emailAddress, setEmailAddress] = useState(originalEmail?.toLowerCase() || "");

    //NOTE LM: React cannot handle changing input state without losing the cursor... Dan "wrong mental model" Abramov be praised...
    const [cursorPosition, setCursorPosition] = useState(0);
    let emailAddressInputRef = useRef();
    useEffect(() => {
        if (!emailAddressInputRef?.current) return;
        emailAddressInputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }, [emailAddress]);

    const { data: existingPersons, refetch } = useFetchExistingPersonsQuery(emailAddress, {
        skip:
            (editorType === EDIT && originalEmail && originalEmail === emailAddress) ||
            !utils.isEmailWellFormed(emailAddress),
    });

    const delayedFetchExistingPersons = _.debounce(refetch, 600);
    const handleEmailChange = (event, value) => {
        const email = value || event?.target?.value;
        const selectionStart = event?.target?.selectionStart;
        setCursorPosition(selectionStart);
        setEmailAddress(email?.toLowerCase());
        if (email?.toLowerCase() && utils.isEmailWellFormed(email?.toLowerCase())) {
            delayedFetchExistingPersons();
        }
    };
    const stopPropagation = (event) => event.stopPropagation();
    const save = () => {
        onEmailChange(emailAddress);
    };
    let isSaveDisabled =
        !emailAddress ||
        !utils.isEmailWellFormed(emailAddress) ||
        (editorType === EDIT && emailAddress === originalEmail);
    let saveButtonLabel = editorType === EDIT ? translate("global.save") : translate("walkthrough.next");
    const existingUser = existingPersons?.existingUser;
    const existingUserDetails = existingPersons?.existingUserDetails || [];
    const invites = existingPersons?.invites || [];
    const grantType = scenario;
    const existingUserId = existingUser?.userId;
    const existingUserDetailsId = existingUser?.userDetailsId || existingUserDetails?.[0]?.id;
    const currentlyEditedUserDetailId = person?.id;
    const currentlyEditedContactType = invitedRoleType;

    const personAlreadyHasAUser = !!person?.userId;
    const userAlreadyExists = !!existingUser?.userId;
    const userDetailsAlreadyExists = !!(
        (existingUserDetails.length > 0 && existingUserDetailsId !== person?.id) ||
        (existingUser?.userDetailsId && existingUser?.userDetailsId !== person?.id)
    );
    const existingUserMatchesPersonsUser = userAlreadyExists && existingUser?.userId === person?.userId;

    //NOTE LM:
    // If we EDIT a contact persons/patient email, then existing invitations do not matter if they only exist for
    // the edited person, however completely prohibitive(!) if they exist for other userdetails.
    // If we GRANT a patient or existing contact person (person input not null) then existing invitation have
    // to indicated and resending of the invitation have to be confirmed. However its prohibited(!) if invitations exist
    // for other userdetails.
    // If we GRANT a non-specific contact person (person input is null) then the existing invitations are allowed,
    // if they are all for the same person but have to be confirmed for resend. Otherwise it is prohibited.
    // In summary differentUserDetailsAlreadyInvited indicates that by adding this email address or granting it again
    // would result in a state where different userdetails have the same email address. This is to be prevented.
    const differentUserDetailsAlreadyInvited = person?.id
        ? invites?.some((invite) => invite.invitedUserDetailsId !== person?.id)
        : invites?.some((invite) => invite.invitedUserDetailsId !== invites?.[0]?.invitedUserDetailsId);
    const inviteRequiresConfirmation =
        editorType === GRANT && invites?.length > 0 ? invites?.[invites?.length - 1] : false;

    //NOTE LM: userAlreadyGrantedAccess indicates that during the grant process we got an email address that is already
    // connected to a user with assignment to this entity. Adding it again is useless.
    const userAlreadyGrantedAccess =
        editorType === GRANT ? excludedUserIds.filter((id) => id).includes(existingUser?.userId) : false;
    //NOTE LM: multipleUserDetails indicates something went wrong and somehow we ended up with more than one userdetails
    // for the same person. More accurately we have the same emailAddress at multiple userdetails.
    // This is to be prevented, but in the past it was allowed.
    const multipleUserDetails = existingUserDetails.length > 1;
    const multipleUserDetailsButOneCanBeGrantedAccessTo =
        multipleUserDetails &&
        [PATIENT_GRANTS_ACCESS_TO_CONTACT_PERSON, CASE_MANAGER_GRANTS_ACCESS_TO_PATIENTS_CONTACT_PERSON].includes(
            scenario,
        ) &&
        existingUserDetails.filter((item) => item.userId).length === 1 &&
        existingUserDetails.filter((item) => item.userId)?.[0]?.userId === existingUser?.userId;
    //NOTE LM: nonMatchingExistingUserAndDetails indicates that we have a user and userdetails for this email address
    // but they are not connected. This is to be prevented, but in the past it was allowed.
    const nonMatchingExistingUserAndDetails =
        existingUser?.userDetailsId &&
        existingUserDetails.length === 1 &&
        existingUserDetails[0]?.id !== existingUser?.userDetailsId;

    const personAlreadyUsingTheEmail =
        emailAddress !== originalEmail?.toLowerCase() &&
        (person?.emailAddresses?.map((item) => item?.toLowerCase())?.includes(emailAddress) ||
            person?.userEmailAddress?.toLowerCase() === emailAddress);

    const assignmentNotPossible =
        differentUserDetailsAlreadyInvited ||
        userAlreadyGrantedAccess ||
        (multipleUserDetails && !multipleUserDetailsButOneCanBeGrantedAccessTo) ||
        nonMatchingExistingUserAndDetails ||
        personAlreadyUsingTheEmail;

    return (
        <Dialog open={true} maxWidth={"sm"} onClick={stopPropagation} onClose={toggleOpen}>
            <Flex item container column padding={15} style={{ width: 450 }}>
                <Flex item container justifyContent={"space-between"} alignItems={"center"} style={{ marginRight: 7 }}>
                    <Typography variant={"h5"}>{title ? title : translate("global.email-manager")}</Typography>
                    <EmailOutlined />
                </Flex>

                {(editorType === EDIT || !person?.emailAddresses || _.isEmpty(person?.emailAddresses)) && (
                    <DefaultTextField
                        value={emailAddress || ""}
                        error={!emailAddress || !utils.isEmailWellFormed(emailAddress)}
                        label={translate("case.email")}
                        style={{ marginRight: 0, marginBottom: 10 }}
                        name={"emailAddress"}
                        autoFocus
                        onChange={handleEmailChange}
                        inputProps={{ ref: emailAddressInputRef }}
                    />
                )}
                {editorType === GRANT && person?.emailAddresses && !_.isEmpty(person?.emailAddresses) && (
                    <Autocomplete
                        options={person?.emailAddresses || []}
                        onChange={handleEmailChange}
                        onInputChange={handleEmailChange}
                        value={emailAddress || null}
                        freeSolo
                        renderInput={(params) => (
                            <DefaultTextField
                                {...params}
                                autoFocus
                                inputRef={input => {
                                    emailAddressInputRef = input;
                                }}
                                error={!emailAddress || !utils.isEmailWellFormed(emailAddress)}
                                label={translate("case.email")}
                                // inputProps={{ ref: emailAddressInputRef }}
                            />
                        )}
                    />
                )}

                <DebugWindowForCaseManagers {...{ user, existingPersons }} />

                {assignmentNotPossible && (
                    <AssignmentNotPossible
                        {...{
                            toggleOpen,
                            deleteEmail,
                            originalEmail,
                            saveButtonLabel,
                            multipleUserDetails,
                            personAlreadyUsingTheEmail,
                            nonMatchingExistingUserAndDetails,
                            userAlreadyGrantedAccess,
                            differentUserDetailsAlreadyInvited,
                        }}
                    />
                )}

                {!assignmentNotPossible && (
                    <>
                        {scenario === PATIENT_EDITS_OWN_EMAIL && (
                            <PatientEditsPatientEmail
                                {...{
                                    toggleOpen,
                                    save,
                                    isSaveDisabled,
                                    userAlreadyExists,
                                    userDetailsAlreadyExists,
                                    deleteEmail,
                                    originalEmail,
                                    saveButtonLabel,
                                }}
                            />
                        )}
                        {[DOCTOR_CASE_MANAGER_EDITS_PATIENT_EMAIL, CASE_MANAGER_GRANTS_ACCESS_TO_PATIENT].includes(
                            scenario,
                        ) && (
                            <ManagerEditsPatientEmail
                                {...{
                                    toggleOpen,
                                    isSaveDisabled,
                                    userAlreadyExists,
                                    userDetailsAlreadyExists,
                                    deleteEmail,
                                    originalEmail,
                                    existingUser,
                                    personAlreadyHasAUser,
                                    save,
                                    saveButtonLabel,
                                    inviteRequiresConfirmation,
                                    refresh,
                                    grantType,
                                    existingUserId,
                                    currentlyEditedUserDetailId,
                                }}
                            />
                        )}
                        {[
                            CASE_MANAGER_EDITS_CARE_PROVIDER_CONTACT_EMAIL,
                            CASE_MANAGER_GRANTS_ACCESS_TO_CARE_PROVIDERS_CONTACT_PERSON,
                        ].includes(scenario) && (
                            <CaseManagerEditsCareProviderEmail
                                {...{
                                    toggleOpen,
                                    isSaveDisabled,
                                    deleteEmail,
                                    originalEmail,
                                    existingUser,
                                    userAlreadyExists,
                                    inviteRequiresConfirmation,
                                    userDetailsAlreadyExists,
                                    existingUserMatchesPersonsUser,
                                    refresh,
                                    personAlreadyHasAUser,
                                    grantType,
                                    existingUserId,
                                    currentlyEditedUserDetailId,
                                    save,
                                    saveButtonLabel,
                                    currentlyEditedContactType,
                                    entityIdToAssign,
                                    entityType,
                                }}
                            />
                        )}
                        {[
                            PATIENT_GRANTS_ACCESS_TO_CONTACT_PERSON,
                            CASE_MANAGER_GRANTS_ACCESS_TO_PATIENTS_CONTACT_PERSON,
                        ].includes(scenario) && (
                            <GrantPatientAccessToContactPerson
                                {...{
                                    toggleOpen,
                                    existingUser,
                                    isSaveDisabled,
                                    userAlreadyExists,
                                    deleteEmail,
                                    originalEmail,
                                    inviteRequiresConfirmation,
                                    person,
                                    invitedRoleType,
                                    refresh,
                                    emailAddress,
                                    save,
                                    patient,
                                    saveButtonLabel,
                                    grantType,
                                    existingUserId,
                                    currentlyEditedUserDetailId,
                                }}
                            />
                        )}
                        {scenario === CASE_MANAGER_EDITS_CASE_CONTACT_PERSON_EMAIL && (
                            <CaseManagerEditsCaseContactPerson
                                {...{
                                    toggleOpen,
                                    isSaveDisabled,
                                    userAlreadyExists,
                                    userDetailsAlreadyExists,
                                    refresh,
                                    grantType,
                                    deleteEmail,
                                    originalEmail,
                                    existingUser,
                                    personAlreadyHasAUser,
                                    existingUserMatchesPersonsUser,
                                    existingUserDetailsId,
                                    currentlyEditedUserDetailId,
                                    entityIdToAssign,
                                    existingUserId,
                                    save,
                                    saveButtonLabel,
                                    existingUserDetails,
                                }}
                            />
                        )}
                    </>
                )}
            </Flex>
        </Dialog>
    );
};

export default EmailAddressEditorDialog;
