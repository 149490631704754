import utils from 'utils/Utils';

export function emptyClinic() {
    return {
        businessId: utils.uuid(),
        name: '',
        website: '',
        active: '',
        certifications: [],
        street: '',
        zipCode: '',
        country: '',
        state: '',
        city: '',
        latitude: '',
        longitude: '',
        centers: [],
    }
}

export function emptyClinicCenter(clinicBusinessId, name) {
    return {
        businessId: utils.uuid(),
        clinicBusinessId,
        name: name || '',
        manager: '',
        rating: '',
        certifications: [],
        subIndications: [],
        indications: [],
        tumorBoardDays: [],
        collaborationTypes: [],
        prerequisites: [],
        contractStatus: '',
        caseAmount: '',
        caseFrequency: '',
        tumorBoardUnavailable: [],
        collaborationComment: '',
        tumorBoardComment: '',
    }
}

export function emptyContact(clinicBusinessId) {
    return {
        businessId: utils.uuid(),
        clinicBusinessId,
        subordinateToBusinessId: null,
        clinicCenterBusinessId: null,
        salutation: "",
        title: "",
        givenName: "",
        familyName: "",
        occupation: "",
        comment: "",
        phoneNumbers: [],
        emailAddresses: [],
        types: [],
        personTypes: []
    }
}
