import _ from 'lodash';

export const CASE_MANAGER = 'CASE_MANAGER';
export const CLINIC = 'CLINIC';
export const DOCTOR = 'DOCTOR';
export const ASSISTANT = 'ASSISTANT';
export const PATIENT = 'PATIENT';
export const CONTACT_PERSON = 'CONTACT_PERSON';

export function isCaseManager(user){
    return (_.get(user, 'roles') || []).includes(CASE_MANAGER)
}

export function isManager(user){
    return (_.get(user, 'roles') || []).filter(role => [CASE_MANAGER, DOCTOR, ASSISTANT].includes(role)).length > 0;
}

export function isPatient(user){
    return (_.get(user, 'roles') || []).includes(PATIENT)
}

export function isDoctor(user){
    return (_.get(user, 'roles') || []).includes(DOCTOR)
}

export function isClinic(user){
    return (_.get(user, 'roles') || []).includes(CLINIC)
}

export function isContactPerson(user){
    return (_.get(user, 'roles') || []).includes(CONTACT_PERSON)
}

export function mapUser(user) {
    return user && {
        businessId: user.businessId,
        username: user.username,
        email: user.email,
        givenName: user.givenName,
        familyName: user.familyName,
        avatar: {
            downloadUrl: user.avatar && user.avatar.downloadUrl
        }
    }
}

export function getNameInitials(user = {}) {
    if(user?.initials) return user?.initials;
    const firstLetter = user.givenName ? user.givenName.charAt(0).toUpperCase() : '';
    const secondLetter = user.familyName ? user.familyName.charAt(0).toUpperCase() : '';
    return (firstLetter || secondLetter) ? firstLetter + secondLetter : '?';
}

export function getName(user) {
    const firstName = user.givenName ? user.givenName : '';
    const lastName = user.familyName ? user.familyName : '';
    return (firstName || lastName) ? firstName + ' ' + lastName : '?';
}

export const SYSTEM_USER = {
    businessId: '',
    givenName: 'System',
};
