import {makeStyles, useTheme, Box, IconButton} from "@material-ui/core";
import {DragIndicator, Edit, Delete} from "@material-ui/icons";
import {getStepStatus} from "scenes/patient/functions";
import {ItemStatusEnum} from 'domain/Step.model';
import {ProgramStepRendererViewMode} from "./ProgramStepRendererViewMode";
import {ProgramStepRendererEditMode} from "./ProgramStepRendererEditMode";
import {hexToRgba, rgbToRgba} from "./utils";
import { useIsCaseManager, useIsManager } from "utils";

const useStyles = makeStyles((theme) => ({
    position: {
        fontSize: "40px",
        minWidth: "44px",
        marginRight: theme.spacing(1)
    },
    content: {
        background: "white",
        transition: theme.transitions.create("all", {duration: ".4s"}),
        borderStyle: "solid",
        borderWidth: "2px",
        borderRadius: "10px",
        borderColor: theme.palette.grey[400],
        flex: 1,
        // minHeight: 100,
    },
    programStepContainerRoot: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
    }
}));

/**
 *
 * @param {{id:number}[]} dependencies
 * @param {number} stepDependsOn
 * @returns {boolean}
 */
const isStepDependencyMissing = (dependencies, stepDependsOn) => {
    return stepDependsOn !== null ? !dependencies.some((dependency) => dependency.id === stepDependsOn) : false;
}

export const ProgramStepRenderer = (props) => {
    const {
        position,
        step,
        isEditable,
        toggleDelete,
        isDraggable,
        dragHandleProps,
        editableId,
        isStepSaveDisabled,
        cancelStep,
        setEditableId,
        saveStep,
        openRepeatedTaskConfigurationModal,
        otherStepsInProgram,
        isNew,
        isStepCollapsed,
        toggleCollapse,
        markStepAsSeen,
        isProgramSuspended,
        isLoading,
        refreshPrograms
    } = props;
    const stepDependencyIsMissing = isStepDependencyMissing(otherStepsInProgram, step.dependsOnTaskId);
    const classes = useStyles();
    const theme = useTheme();
    const managerRoleSelected = useIsManager();
    const caseManagerRoleSelected = useIsCaseManager();

    if(!managerRoleSelected && !step.patientCanView) {
        return null;
    }

    const stepStatus = getStepStatus(step);
    const stepColor = (() => {
        switch (stepStatus) {
            case ItemStatusEnum.Completed:
                return "#00AE9E";
            case ItemStatusEnum.Upcoming:
                return theme.palette.warning.light;
            case ItemStatusEnum.Overdue:
                return theme.palette.error.light;
            case ItemStatusEnum.Normal:
            default:
                return theme.palette.grey[500];
        }
    })();


    /**
     *
     * @param {String} cssColorValue like #ffffff or rgb(255, 255, 255)
     * @returns {Object} boxShadow style object
     */
    const getBoxShadow = (cssColorValue) => {
        const getRgbaOfaColor = cssColorValue.startsWith('#') ?  hexToRgba(cssColorValue) : rgbToRgba(cssColorValue);
        const xShadowOffset = 0;
        const yShadowOffset = 0;
        const blurShadow = '10px'
        const spreadShadow = '2px'
        const shadowColor = getRgbaOfaColor(.8);
        const boxShadowValue = [xShadowOffset, yShadowOffset, blurShadow, spreadShadow, shadowColor].join(' ');

        return {
            boxShadow: boxShadowValue,
            transition: theme.transitions.create("box-shadow", {duration: ".2s"}),
        }
    }

    const boxShadowColor = stepStatus === "NORMAL" ? theme.palette.info.main : stepColor;
    const boxShadow = isNew ? getBoxShadow(boxShadowColor) : {};

    const hasEditingPermission = caseManagerRoleSelected || step.editable;
    const hasDeletePermission = caseManagerRoleSelected || step.skippable;

    return <div className={classes.programStepContainerRoot}>
        <Box display={"flex"} alignItems={"center"}>
            {
                isDraggable &&
                    <div style={{display: "flex"}} {...dragHandleProps}>
                        <DragIndicator/>
                    </div>
            }
            {
                !isDraggable &&
                    <div style={{display: "flex"}}>
                        <DragIndicator style={{fill: 'lightgrey'}}/>
                    </div>
            }
            <div className={classes.position}>{position}</div>
        </Box>

        <Box
                display={"flex"}
                flexDirection={"column"}
                flex={"1 1 auto"}
                style={{borderColor: stepColor, borderWidth: step.isCompleted ? '3px': '', ...boxShadow}}
                className={classes.content}
                onMouseOver={() => {
                        markStepAsSeen(step.id);
                } }
        >
            {
                    isEditable &&
                    <ProgramStepRendererEditMode
                            {...{
                                step,
                                saveStep,
                                otherStepsInProgram,
                                cancelStepHandler: cancelStep,
                                dependencyIsMissing: stepDependencyIsMissing,
                            }}
                    />
            }

            {
                !isEditable &&
                <ProgramStepRendererViewMode
                        {...{
                            isEditable,
                            step,
                            saveStep,
                            otherStepsInProgram,
                            openRepeatedTaskConfigurationModal,
                            dependencyIsMissing: stepDependencyIsMissing,
                            isStepCollapsed,
                            toggleCollapse,
                            isProgramSuspended,
                            isLoading,
                            refreshPrograms
                        }}
                />
            }

        </Box>
        {
            managerRoleSelected && !isProgramSuspended &&
            <Box display={"flex"} flexDirection={"column"} flex={"0 1 auto"}>
                {(
                        <IconButton
                                onClick={() => setEditableId(step.id)}
                                disabled={!!(isStepSaveDisabled || !hasEditingPermission)}
                                style={{opacity: isStepSaveDisabled ? 0 : 1}}
                        >
                            <Edit/>
                        </IconButton>
                )}
                {
                    <IconButton
                            style={{
                                opacity: isStepSaveDisabled ? 0 : 1,
                            }}
                            onClick={toggleDelete}
                            disabled={!!(editableId || step.isCompleted || !hasDeletePermission)}
                    >
                        <Delete/>
                    </IconButton>
                }
            </Box>
        }
    </div>;
};
