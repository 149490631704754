import React, {Component} from 'react';
import {
    withStyles,
    IconButton
} from "@material-ui/core";
import {withTranslation} from 'react-i18next';
import Flex from 'components/grid/Flex';
import HmoInputLabel from 'components/input-label/HmoInputLabel';
import {Check} from '@material-ui/icons';
import DatePicker from 'components/calendar/DatePicker';

const styles = theme => ({});

export class TumorBoardUnavailable extends Component {

    state = {
        from: '',
        to: '',
        isAddNewUnavailableDate: true
    }

    onNewDatesChange = name => value => {
        this.setState({
            [name]: value
        });
    }

    addNewUnavailableDate = () => {
        const {from, to} = this.state;
        this.setState({
            from: '',
            to: '',
        }, () => {
            this.props.addNewRange({from, to});
        });
    }

    onExistingChange = (index, name) => value => {
        this.props.onExistingChange({index, name, value});
    }

    render() {
        const {t: translate, tumorBoardUnavailable} = this.props;
        const {from, to} = this.state;
        const {onNewDatesChange, addNewUnavailableDate, onExistingChange} = this;

        return (
            <>
                <HmoInputLabel style={{color: 'red', marginTop: 10}}>
                    {translate('clinic.not-available')}
                </HmoInputLabel>
                <>
                    {
                        tumorBoardUnavailable.map((range, index) => (
                            <Flex item={'0 1 auto'} container alignItems={'center'} style={{marginTop: 10}}>
                                <DatePicker {...{
                                    value: range.from,
                                    name: 'from',
                                    onChange: onExistingChange(index, 'from'),
                                    label: translate('global.from'),
                                    style: {flex: '0 1 auto'}
                                }}/>
                                <DatePicker {...{
                                    value: range.to,
                                    name: 'to',
                                    onChange: onExistingChange(index, 'to'),
                                    label: translate('global.to'),
                                    style: {flex: '0 1 auto'}
                                }}/>
                            </Flex>
                        ))
                    }
                    <Flex item={'0 1 auto'} container alignItems={'center'} style={{marginTop: 10}}>
                        <DatePicker {...{
                            value: from || null,
                            name: 'from',
                            onChange: onNewDatesChange('from'),
                            label: translate('global.from'),
                            style: {flex: '0 1 auto'}
                        }}/>
                        <DatePicker {...{
                            value: to || null,
                            name: 'to',
                            onChange: onNewDatesChange('to'),
                            label: translate('global.to'),
                            style: {flex: '0 1 auto'}
                        }}/>
                        <IconButton style={{padding: 3}} onClick={addNewUnavailableDate}>
                            <Check/>
                        </IconButton>
                    </Flex>
                </>
            </>
        );
    }
}

export default withStyles(styles)(withTranslation()(TumorBoardUnavailable));
