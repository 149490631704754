import {ClickAwayListener, MenuItem, Tooltip} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { useRef, useState } from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {
    useAddPatientsToCohortMutation,
    useListCohortsQuery,
    useRemovePatientsFromCohortMutation,
} from "../cohort-filters/CohortApi";
import _ from "lodash";

const PatientRowContextMenu = (props) => {
    const { t: translate } = useTranslation();
    const { x, y, resetPosition, toggleNewProgramDialog, checkedPatientIds, selectedCohortId } = props;
    const [showCohorts, setShowCohorts] = useState(false);
    const parentRef = useRef(null);
    const onMouseEnter = (event) => {
        setShowCohorts(true);
    };

    const onMouseLeave = (event) => {
        setShowCohorts(false);
    };

    const [addPatientsToCohort] = useAddPatientsToCohortMutation();
    const [removePatientsFromCohort] = useRemovePatientsFromCohortMutation();
    const { data: cohorts = [] } = useListCohortsQuery();

    const addToCohort = (cohortId) => {
        if (cohortId && checkedPatientIds.length) {
            addPatientsToCohort({ cohortId, patientIds: checkedPatientIds });
            resetPosition();
        }
    };

    const removeFromCohort = () => {
        if (selectedCohortId && checkedPatientIds.length) {
            removePatientsFromCohort({ cohortId: selectedCohortId, patientIds: checkedPatientIds });
            resetPosition();
        }
    };
    return (
        <ClickAwayListener onClickAway={resetPosition}>
            <Tooltip title={_.isEmpty(checkedPatientIds) ? translate('cohort.please-select-patients') : ''}>
            <div
                style={{
                    position: "absolute",
                    backgroundColor: "white",
                    border: "1px solid lightgrey",
                    borderRadius: 4,
                    left: x,
                    top: y,
                }}
            >
                <MenuItem disabled={_.isEmpty(checkedPatientIds)} onClick={toggleNewProgramDialog}>
                    {translate("global.add-new-program")}
                </MenuItem>
                <MenuItem
                    disabled={_.isEmpty(checkedPatientIds)}
                    style={{ justifyContent: "space-between", overflow: "unset" }}
                    {...{
                        onMouseLeave,
                        onMouseEnter,
                    }}
                    ref={parentRef}
                >
                    {translate("cohort.context-menu-add-to-cohort")}
                    <ArrowForwardIosIcon style={{ color: "grey" }} />
                    {showCohorts && (
                        <div
                            style={{
                                position: "absolute",
                                backgroundColor: "white",
                                border: "1px solid lightgrey",
                                borderRadius: 4,
                                top: -1,
                                left: parentRef?.current?.getBoundingClientRect()?.width,
                            }}
                        >
                            {cohorts.map((cohort) => (
                                <MenuItem onClick={() => addToCohort(cohort.id)}>{cohort.name}</MenuItem>
                            ))}
                        </div>
                    )}
                </MenuItem>

                {!!selectedCohortId && (
                    <MenuItem disabled={_.isEmpty(checkedPatientIds)} onClick={removeFromCohort}>
                        {translate("cohort.context-menu-remove-from-cohort")}
                    </MenuItem>
                )}
            </div>
            </Tooltip>
        </ClickAwayListener>
    );
};

export default PatientRowContextMenu;
