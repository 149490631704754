import * as actionTypes from './Case.action.types';
import _ from 'lodash';
import utils from 'utils/Utils';
import {DONE, CANCELLATION} from 'domain/WorkflowStatus';
import moment from 'moment';

const initialState = {
    cases: [],
    filteredCases: [],
    isFilterActive: false,
    tagSuggestions: [],
    caseManagerSuggestions: [],
    expandedPanels: [],
    configuration: {},
    caseManagers: [],
    lastCaseListRefresh: '',
    filters: {
        selectedCaseManagerIds: [],
        searchText: '',
        selectedTags: [],
        reminderOnly: false,
        isHistoryActive: false,
        tagFilterRelation: 'OR'
    }
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_CONFIGURATION_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.configuration = action.payload.data;
            return newState;
        }
        case actionTypes.LIST_CASE_MANAGERS_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.caseManagers = action.payload.data;
            newState.caseManagerSuggestions = mapCaseManagers(state.cases, action.payload.data);
            return newState;
        }
        case actionTypes.LIST_CASES_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.cases = action.payload.data;
            newState.lastCaseListRefresh = moment();
            newState.tagSuggestions = mapTagSuggestions(action.payload.data);
            newState.caseManagerSuggestions = mapCaseManagers(action.payload.data, state.caseManagers);
            return newState;
        }
        case actionTypes.UPDATE_CASE_LIST_FILTERS: {
            const newState = {...state};
            newState.filters = {
                ...state.filters,
                ...action.data.filters
            };
            if (
                _.isEmpty(newState.filters.selectedCaseManagerIds)
                && _.isEmpty(newState.filters.selectedTags)
                && _.isEmpty(newState.filters.searchText)
                && !newState.filters.reminderOnly
                && !newState.filters.isHistoryActive
            ) {
                newState.filteredCases = [];
                newState.isFilterActive = false;
            } else {
                newState.filteredCases = filterCases(newState);
                newState.isFilterActive = true;
            }
            return newState;
        }
        case actionTypes.UPDATE_CASE_LIST_PANELS: {
            const newState = {...state};
            newState.expandedPanels = action.data.panels;
            return newState;
        }
        default:
            return state;
    }
}

function mapTagSuggestions(casesFromBackend) {
    const result = new Set();
    casesFromBackend.forEach(caseInArray => {
        result.add(caseInArray.indication);
        caseInArray.subIndications.forEach(subIndication => {
            result.add(subIndication);
        });
        caseInArray.costCoverageTypes.forEach(type => {
            result.add(type)
        });
    });
    return [...result].filter(item => item);
}

function mapCaseManagers(casesFromBackend, caseManagersFromBackend) {
    if (!_.isEmpty(casesFromBackend) && !_.isEmpty(caseManagersFromBackend)) {
        return _.uniqBy(casesFromBackend, 'caseManagerId')
            .map(casesFromBackend => casesFromBackend.caseManagerId)
            .map(caseManagerId => caseManagersFromBackend.find(cm => cm.businessId === caseManagerId))
            .filter(cm => !_.isEmpty(cm));
    }
    return [];
}

function filterCases(state) {
    return state.cases
        .filter(caseManagerFilter(state.filters.selectedCaseManagerIds))
        .filter(freeTextFilter(state.filters.searchText, state.configuration.insuranceCompanies))
        .filter(reminderFilter(state.filters.reminderOnly))
        .filter(historyFilter(state.filters.isHistoryActive))
        .filter(tagFilter(state.filters.selectedTags, state.filters.tagFilterRelation));
}

const freeTextFilter = (searchText, insuranceCompanies) => caseInArray => {
    const insuranceCompany = insuranceCompanies.find(insuranceCompany => insuranceCompany.insuranceCompanyId === caseInArray.insuranceCompanyId);
    const text = searchText.toLowerCase();
    return caseInArray.businessId.includes(text)
        || utils.convertReadablePatientId(caseInArray.patient.id).toLowerCase().includes(searchText)
        || _.some(caseInArray.patient.phoneNumbers, phoneNumber => phoneNumber.includes(text))
        || _.some(caseInArray.patient.emailAddresses, email => email.includes(text))
        || (caseInArray.reminderAction || '').toLowerCase().includes(text)
        || (caseInArray.patientAppointmentAction || '').toLowerCase().includes(text)
        || (utils.formattedUserName(caseInArray.patient) || '').toLowerCase().includes(text)
        || caseInArray?.contactPersons?.map(person => person.givenName + person.familyName)?.join(',')?.toLowerCase()
            ?.includes(text)
        || insuranceCompany?.name?.toLowerCase()?.includes(text)
};

const caseManagerFilter = selectedCaseManagerIds => caseInArray => {
    return selectedCaseManagerIds.length === 0
        ? true
        : selectedCaseManagerIds.includes(caseInArray.caseManagerId)
};

const reminderFilter = reminderOnly => caseInArray => {
    return reminderOnly
        ? !_.isEmpty(caseInArray.reminderAction) || !_.isEmpty(caseInArray.reminderDate)
        : true
};

const historyFilter = isHistoryActive => caseInArray => {
    return isHistoryActive
        ? caseInArray.status === DONE || caseInArray.status === CANCELLATION
        : caseInArray.status !== DONE && caseInArray.status !== CANCELLATION
};

const tagFilter = (selectedTags, tagFilterRelation) => caseInArray => {

    if (tagFilterRelation === 'OR') {
        return selectedTags.length === 0
            ? true
            : selectedTags.includes(caseInArray.indication)
            || _.intersection(selectedTags, caseInArray.subIndications).length
            || _.intersection(selectedTags, caseInArray.costCoverageTypes).length
    } else {
        let filterResult = true;
        selectedTags.forEach(selectedTag => {
            if (!(selectedTag === caseInArray.indication || caseInArray.subIndications.includes(selectedTag) || caseInArray.costCoverageTypes.includes(selectedTag))) {
                filterResult = false;
            }
        })
        return filterResult;
    }
};
