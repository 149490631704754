import React from 'react';
import {InputLabel} from "@material-ui/core";
import {Field} from "formik";
import {validationError} from "../config/ValidationSchema";
import FieldSelect from './FieldSelect';

const AnamnesisSelectbox = ({form, fieldName, label, children, ...props}) => {
    return (
            <>
                {label != null && <InputLabel id={`${fieldName}Label`} shrink>{label}</InputLabel>}
                <Field
                        name={fieldName}
                        fullWidth
                        labelId={`${fieldName}Label`}
                        component={FieldSelect}
                        value={form.values[fieldName] || ''}
                        displayEmpty
                        {...validationError(form, fieldName)}
                        {...props}
                >
                    {children}
                </Field>

            </>
    );
};

AnamnesisSelectbox.propTypes = {};

export default AnamnesisSelectbox;
