import React, {Component} from 'react';
import Flex from 'components/grid/Flex';
import utils from 'utils/Utils';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import HmoInputLabel from 'components/input-label/HmoInputLabel';
import HmoTextField from 'components/hmo-textfield/HmoTextField';
import {withTranslation} from 'react-i18next';
import 'moment/locale/de';

export class DatePicker extends Component {
    render() {
        const {
            t: translate, label, value, onChange, disabled, name, style, color, onBlur, disablePast, smallLabel,
            TextFieldComponent
        } = this.props;

        return (
            <Flex item={'0 1 200px'} container column style={{marginRight: 5, ...style}}>
                {
                    !TextFieldComponent &&
                    <HmoInputLabel small={smallLabel}>{label || translate('global.date')}</HmoInputLabel>
                }
                <MuiPickersUtilsProvider utils={MomentUtils} locale="de">
                    <KeyboardDatePicker
                        style={{color}}
                        value={value}
                        name={name}
                        disabled={disabled}
                        disablePast={disablePast}
                        minDateMessage={''}
                        format={utils.VIEW_DATE_FORMAT}
                        onChange={onChange}
                        onBlur={onBlur}
                        TextFieldComponent={TextFieldComponent || HmoTextField}
                        KeyboardButtonProps={{
                            tabIndex: -1,
                            style: {padding: 0}
                        }}
                        InputAdornmentProps={{
                            tabIndex: -1,
                            style: {backgroundColor: 'white', height: '100%', marginLeft: 0}
                        }}
                    />
                </MuiPickersUtilsProvider>
            </Flex>
        );
    }
}

export default withTranslation()(DatePicker);
