import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((_theme) => ({
    dialog: {
        minWidth: 400,
    },
}));

function SimpleConfirmDialog(props) {
    const { title, message, open, onConfirm, onCancel } = props;
    const classes = useStyles();
    const {t} = useTranslation();
    const handleConfirm = () => {
        if (onConfirm) {
            onConfirm();
        }
    };

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        }
    };

    return (
        <Dialog open={open} onClose={handleCancel} className={classes.dialog}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{message}</DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    {t('global.cancel')}
                </Button>
                <Button onClick={handleConfirm} color="primary" autoFocus>
                    {t('global.confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default SimpleConfirmDialog;
