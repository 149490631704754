import React, { Component } from 'react';
import { Typography } from "@material-ui/core";
import Flex from 'components/grid/Flex';
import PostAdd from '@material-ui/icons/PostAdd';

export class FileCounter extends Component {
    render() {
        const {color, count, style} = this.props;

        return (
            <Flex container item grow={0} style={{height: 24}}>
                <PostAdd style={{fill:'orange', position: 'absolute', zIndex: 0}}/>
                <Flex item grow={0} container center
                      style={{marginLeft: 12,
                          zIndex: 1,
                          backgroundColor: color || 'rgb(112,48,160)',
                          borderRadius: '50%',
                          maxHeight: 14,
                          minHeight: 14,
                          minWidth: 14,
                          ...style}}>
                    <Typography style={{color: 'white', fontSize: 10}}>{count}</Typography>
                </Flex>
            </Flex>
        );
    }
}

export default FileCounter;
