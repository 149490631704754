import { Box } from "@material-ui/core";
import { fields } from "../../../filter-configs/fields.conf";
import { inputRendererMap } from "../../../filter-configs/inputRendererMap";
import { useTranslation } from "react-i18next";
import { ExcludeToggle } from "../node-renderers/node-control-components/ExcludeToggle";

export const NestedMatch = ({ config, rule, idx, handleUpdateRule, handleUpdateGroup, inOrGroup, groupExclude }) => {
    const { t } = useTranslation();

    const firstInGroup = inOrGroup && idx === 0;
    const firstElement = !inOrGroup || firstInGroup;
    const excludeValue = inOrGroup ? groupExclude : rule.properties.exclude;
    const showOrLabel = !firstElement;
    const excludeToggleIsVisible = firstElement;
    return (
        <Box display="flex" alignItems="center" style={{'gap': '8px'}}>
            {config.canExclude && <div style={{position: 'relative', display: 'flex', alignItems: 'center'}}>
                {showOrLabel && <span style={{position: 'absolute', right: 0}}>
                    {t('global.or').toUpperCase()}
                </span> }
                <div style={{visibility: excludeToggleIsVisible ? 'visible': 'hidden' }}>
                    <ExcludeToggle onChange={(e) => {
                        inOrGroup ? handleUpdateGroup({
                            exclude: e.target.value
                        }) :
                        handleUpdateRule(idx, {
                            ...rule.properties,
                            exclude: e.target.value
                        });
                        }} value={excludeValue} />
                </div>
            </div>}

            {rule.properties.value.nestedValues.map((innerRule, innerRuleIdx) => {
                const field = fields[innerRule.properties.field];
                const RenderField = inputRendererMap[field.widgetType];
                return RenderField ? (
                    <RenderField
                        key={innerRuleIdx}
                        nestedField={true}
                        config={{ ...field.widgetConfig, compact: true }}
                        rule={innerRule}
                        idx={innerRuleIdx}
                        handleUpdateRule={(nestedIdx, updatedProperties) => {
                            const updatedRule = { type: "rule", properties: updatedProperties };
                            handleUpdateRule(idx, {
                                ...rule.properties,
                                value: {
                                    ...rule.properties.value,
                                    nestedValues: rule.properties.value.nestedValues.map((innerRule, innerIdx) => {
                                        return innerIdx === nestedIdx ? updatedRule : innerRule;
                                    }),
                                },
                            });
                        }}
                    />
                ) : null;
            })}
        </Box>
    );
};
