import {fetchCase} from 'scenes/case/edit/CaseEdit.action';
import {deleteUserDetails, updateUserDetails} from 'scenes/user-manager/UserManager.action';

export default {
    mapStateToProps: (state) => {
        return {
            configuration: state.caseReducer.configuration,
            user: state.mainReducer.user,
            case: state.caseEditReducer.case,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            updateUserDetails: (props) => dispatch(updateUserDetails(props)),
            deleteUserDetails: userDetailsBusinessId => dispatch(deleteUserDetails(userDetailsBusinessId)),
            fetchCase: caseBusinessId => dispatch(fetchCase(caseBusinessId))
        };
    }
};
