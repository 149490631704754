import React, {Component} from 'react';
import {
    withStyles, Tooltip, CircularProgress,
    IconButton
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import * as colors from 'components/colors/Colors';
import {connect} from 'react-redux';
import connector from './EmailManager.connect';
import {withTranslation} from 'react-i18next';
import {
    Mail as MailIcon,
    Print as PrintIcon
} from "@material-ui/icons";
import _ from 'lodash';
import EpiAlert from 'components/alert/EpiAlert';
import EmailManagerDialog from './EmailManagerDialog';
import PrintEmailsDialog from './PrintEmailsDialog';
import utils from 'utils/Utils';
import moment from 'moment';
import {ReactComponent as MicrosoftWordGrey} from 'assets/img/microsoft-word-grey.svg';
import {ReactComponent as MicrosoftWord} from 'assets/img/microsoft-word-2019.svg';

const styles = theme => ({
    cancelButton: {
        fontSize: 13,
        textTransform: "unset",
        color: colors.htmlGrey,
    },
});

export class EmailManager extends Component {

    state = {
        selectedEmailTypes: [],
        emailAddress: '',
        alert: '',
        isPrintDialogOpen: false,
        isMailDialogOpen: false,
        isRegisterLoading: false,
    }

    printEmails = () => {
        this.props.printEmails(this.state.selectedEmailTypes, this.state.emailAddress, this.props.personType,
            this.props.case.businessId, this.props.case.patient.businessId)
            .then((response) => {
                const filename = this.props.person?.givenName + '-' + this.props.person?.familyName
                    + '-' + moment().format(utils.VIEW_DATE_FORMAT) + '.pdf';
                utils.triggerNativeDownload(
                    window.URL.createObjectURL(new Blob([response.payload.data], {
                        type: 'application/pdf',
                        responseType: 'arraybuffer',
                    })),
                    filename
                )
            })
            .catch((e) => {
                console.log('ERROR: ', e);
                this.setState({
                    alert: 'error',
                });
            })
            .finally(() => {
                this.togglePrintDialogOpen();
            });
    }

    sendEmails = () => {
        this.props.sendEmails(this.state.selectedEmailTypes, this.state.emailAddress, this.props.personType,
            this.props.person.businessId, this.props.case.businessId)
            .then(() => {
                this.props.fetchCase(this.props.case.businessId);
                this.toggleMailDialogOpen();
            })
    }

    togglePrintDialogOpen = () => {
        if (!this.state.isPrintDialogOpen) {
            this.props.saveCase(false);
        }
        this.setState({
            isPrintDialogOpen: !this.state.isPrintDialogOpen,
            selectedEmailTypes: [],
            alert: '',
        })
    }

    toggleMailDialogOpen = () => {
        if (!this.state.isMailDialogOpen) {
            this.props.saveCase(false);
        }
        this.setState({
            isMailDialogOpen: !this.state.isMailDialogOpen,
            emailAddress: this.props.person.emailAddresses[0] || '',
            selectedEmailTypes: [],
            alert: '',
        })
    }

    onDownloadIndikateDocx = () => {
        this.props.downloadIndicateDocx(this.props.case.businessId, this.props.case.indication);
    }

    setEmailAddress = event => {
        this.setState({
            emailAddress: event.target.value
        })
    }

    closeSnackbar = () => {
        this.setState({
            alert: '',
        });
    };

    selectEmailType = type => event => {
        this.setState({
            selectedEmailTypes: this.state.selectedEmailTypes.includes(type)
                ? _.without(this.state.selectedEmailTypes, type)
                : [...this.state.selectedEmailTypes, type]
        });
    }

    render() {
        const {classes, t: translate, isEditDisabled, insuranceCompanies, person, personType} = this.props;
        const {
            selectedEmailTypes, emailAddress, isMailDialogOpen, isPrintDialogOpen, isRegisterLoading
        } = this.state;
        const {
            printEmails, sendEmails, setEmailAddress, selectEmailType, togglePrintDialogOpen, toggleMailDialogOpen,
            closeSnackbar, onDownloadIndikateDocx
        } = this;
        const {caseLogs, insuranceCompanyId, indication} = this.props.case;
        const insuranceCompany = insuranceCompanies.find(company => company.insuranceCompanyId === insuranceCompanyId);
        const isActOfAssignmentEnabled = insuranceCompany?.name === 'mhPlus';
        const isUserAssignmentDisabled = false;
        const emailSentLog = caseLogs.find(log => log.type === 'REGISTRATION_EMAIL_SENT');
        const tooltipText = _.isEmpty(emailSentLog)
            ? isUserAssignmentDisabled
                ? translate('case.disabled-user-creation')
                : translate('case.enabled-user-creation')
            : translate('case.email-sent', {
                address: _.get(emailSentLog, 'data'),
            });
        const isdocxGenNeed = 'HEART' === indication || 'CANCER' === indication;

        return (
            <Flex item container>
                {
                    isRegisterLoading
                        ?
                        <CircularProgress classes={{root: classes.circularProgressRoot}}/>
                        :
                        <Flex item container justifyContent={'flex-end'}>

                            {!(isdocxGenNeed || isEditDisabled) &&
                            <Tooltip
                                title={translate("global.word-template-indication-alert")}>
                                        <span>
                                            <MicrosoftWordGrey style={{
                                                width: 30,
                                                height: 30,
                                                marginTop: 2,
                                                marginRight: -2
                                            }}/>
                                        </span>
                            </Tooltip>
                            }

                            <IconButton style={{padding: 2, marginRight: 20}}
                                        tabIndex={-1}
                                        disabled={!isdocxGenNeed || isEditDisabled}
                                        onClick={onDownloadIndikateDocx}>
                                {(isdocxGenNeed || isEditDisabled) &&
                                <MicrosoftWord style={{width: 30, height: 30}}/>
                                }


                            </IconButton>
                            {
                                personType === 'PATIENT' &&
                                <IconButton style={{padding: 2, marginRight: 20}}
                                            tabIndex={-1}
                                            disabled={isUserAssignmentDisabled || isEditDisabled}
                                            onClick={togglePrintDialogOpen}>
                                    <PrintIcon style={{fontSize: 30, color: colors.rainForest}}/>
                                </IconButton>
                            }
                            {
                                personType === 'PATIENT' &&
                                <Tooltip
                                    title={tooltipText}>
                                        <span>
                                            <IconButton style={{padding: 2}}
                                                        tabIndex={-1}
                                                        disabled={isUserAssignmentDisabled || isEditDisabled}
                                                        onClick={toggleMailDialogOpen}>
                                                <MailIcon style={{
                                                    fontSize: 30,
                                                    color: colors.rainForest
                                                }}
                                                />
                                            </IconButton>
                                        </span>
                                </Tooltip>
                            }
                        </Flex>

                }
                <EmailManagerDialog {...{
                    toggleOpen: toggleMailDialogOpen,
                    open: isMailDialogOpen,
                    sendEmails,
                    emailAddress,
                    setEmailAddress,
                    selectedEmailTypes,
                    selectEmailType,
                    isActOfAssignmentEnabled,
                    caseLogs,
                    person,
                    isInsuranceCovered: insuranceCompanyId
                }}/>
                {
                    personType === 'PATIENT' &&
                    <PrintEmailsDialog {...{
                        toggleOpen: togglePrintDialogOpen, person, printEmails, open: isPrintDialogOpen,
                        insuranceCompanyId, caseLogs, selectedEmailTypes, selectEmailType, isActOfAssignmentEnabled,
                        isInsuranceCovered: insuranceCompanyId
                    }}/>
                }

                <EpiAlert
                    {...{
                        isOpen: !!this.state.alert,
                        close: closeSnackbar,
                        severity: this.state.alert === 'error' ? this.state.alert : 'success',
                        message:
                            this.state.alert === 'error'
                                ? translate('global.backend-call-failed')
                                : this.state.alert
                                    ? translate(`global.${this.state.alert}`)
                                    : '',
                    }}
                />
            </Flex>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(EmailManager)));
