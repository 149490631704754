import React, {useCallback} from 'react';
import FormSection from "../FormSection";
import {useTranslation} from "react-i18next";
import BooleQuestion from "../input/BooleQuestion";
import {Field} from "formik";
import EpaTextField from 'components/hmo-textfield/EpaTextField';
import { QuestionWithFiltering } from './QuestionWithFiltering';

const OtherDiseaseForm = ({form, readonly, filters}) => {
    const {t} = useTranslation();
    const lang = useCallback((name) => t(`anamnesis.other.${name}`), [t]);

    return (
            <FormSection title={lang('title')}>
                <QuestionWithFiltering filters={filters} filterValue={form.values?.haveOtherDisease}>

                <BooleQuestion
                        disabled={readonly}
                        form={form}
                        fieldName="haveOtherDisease"
                        question={lang('haveOtherDisease')}
                />

                {
                    form.values?.['haveOtherDisease'] !== null && form.values?.['haveOtherDisease'] === true &&
                    <Field
                            disabled={readonly}
                            name="otherDiseaseText"
                            multiline
                            minRows={6}
                            label={lang("if-yes-specify")}
                            as={EpaTextField}
                    />
                }

                </QuestionWithFiltering>
            </FormSection>
    );
};


export default OtherDiseaseForm;
