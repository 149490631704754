import React, {Component} from 'react';
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import connector from './RoleSelector.connect';
import {
    Button,
    Typography
} from "@material-ui/core";
import {ReactComponent as KrebsZweitmeinungLogo} from 'assets/img/krebszweitmeinung-logo.svg';
import LanguageSelector from 'components/language-selector/LanguageSelector';
import {withRouter} from 'utils/Route.utils';

export class RoleSelector extends Component {

    state = {
        selectedRole: ''
    }

    selectRole = role => event => {
        this.setState({selectedRole: role});
    }

    submit = () => {
        window.localStorage.setItem("selectedRole", this.state.selectedRole);
        this.props.navigate('/');
        this.props.fetchCurrentUser();
    }

    render() {
        const {roles=['CONTACT_PERSON', 'PATIENT'], t: translate, logout} = this.props;
        const {selectedRole} = this.state;
        const {selectRole, submit} = this;

        return (
            <Flex item container column center>
                <Flex item container justifyContent={'flex-start'} style={{width: '100%', padding: 10}}>
                    <KrebsZweitmeinungLogo style={{width: 150, height: 50}}/>
                    <Flex item container justifyContent={'flex-end'} padding={8}>
                        <LanguageSelector/>
                    </Flex>
                </Flex>
                <Flex item container column style={{width: '100%', maxWidth: 450, padding: 10}}>
                    <Typography variant={"h5"}>{translate('global.role-selector-title')}</Typography>
                    <Typography style={{margin: '10px 0px'}}>{translate('global.role-selector-text')}</Typography>
                    {
                        (roles || []).map(role => (
                            <Button onClick={selectRole(role)}
                                    key={role}
                                    style={{
                                        border: selectedRole === role ? '1px solid blue' : '1px solid lightgrey',
                                        boxShadow: selectedRole === role ?'blue 0px 0px 3px 0px': 'unset',
                                        margin: '2px 0px'
                                    }}
                                    data-testid={`login-role-type-${role.toLowerCase()}`}
                                    >
                                {translate(`role-type.${role}`)}
                            </Button>
                        ))
                    }
                    <Flex item container style={{marginTop: 20}}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={logout}
                        >
                            {translate('global.logout')}
                        </Button>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            style={{marginLeft: 10}}
                            onClick={submit}
                            data-testid={'apply-role-selection'}
                        >
                            {translate('global.apply-selection')}
                        </Button>

                    </Flex>
                </Flex>
            </Flex>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(withRouter(RoleSelector)));
