/**
 * An object representing a medication.
 *
 * @typedef {Object} Medication
 * @property {string} deletionDate - The date the medication was deleted, in ISO 8601 format.
 * @property {string} id - The sequential ID of the medication. (newer medications have higher IDs)
 */

/**
 * Compares two medication objects and returns a value indicating their order.
 *
 * @param {Medication} a - The first medication object to compare.
 * @param {Medication} b - The second medication object to compare.
 * @returns {number}
 */
export const medicationTableOrderComparator = (a, b) => {
    if (a.deletionDate && b.deletionDate) {
        return a.deletionDate.localeCompare(b.deletionDate);
    } else if (a.deletionDate) {
        return -1;
    } else if (b.deletionDate) {
        return 1;
    } else {
        return a.id - b.id;
    }
}
