import {Select, Typography} from '@material-ui/core';
import {useTranslation} from "react-i18next";

export const FieldSelect = selectProps => {
    const {t: translate} = useTranslation();
    return <Select {...selectProps}
            {...selectProps?.field}
            value={selectProps?.field?.value || ''}
            renderValue={selectProps?.field?.value
                    ? undefined
                    : () => <Typography style={{color: '#d7d7d7'}}>
                        {translate('anamnesis.selectNotification')}
                    </Typography>}
    />
}

export const PlaceholderOrValue = (value, t) => {
    return value
            ? undefined
            : () => <Typography style={{color: '#d7d7d7'}}>
                {t('anamnesis.selectNotification')}
            </Typography>
}

export default FieldSelect;
