import { cond, constant, isBoolean, isString, isEqual, negate, includes, __ } from "lodash/fp";

export const FILTER_KEYS = {
    YES: "yes",
    NO: "no",
    NOT_ANSWERED: "notAnswered",
};

export const NEGATIVE_STRING_ANSWERS = ["NO_PROBLEM", "NONE", "NO"];

const SHOW_FORM_ON_UNHANDLED_VALUE_TYPE = true;

const stringValueConditions = [
    { filter: FILTER_KEYS.NO, matchesCond: includes(__, NEGATIVE_STRING_ANSWERS) },
    { filter: FILTER_KEYS.YES, matchesCond: negate(includes(__, NEGATIVE_STRING_ANSWERS)) },
];

const boolValueConditions = [
    { filter: FILTER_KEYS.NO, matchesCond: isEqual(false) },
    { filter: FILTER_KEYS.YES, matchesCond: isEqual(true) },
];

const isEmptyValue = (value) => value === null || value === undefined || value === "";

const satisfiesValueConditions = (appliedFilters, conditions) => (value) =>
    conditions.some(({ filter, matchesCond }) => appliedFilters[filter] && matchesCond(value));

/**
 * Determines if a form question should be visible based on the provided value and applied filters.
 *
 * @param {Object} params - The parameters for determining visibility.
 * @param {*} params.value - The value of the question answer. Function handles boolean, string, null, and undefined values.
 * Other types will be handled based on the SHOW_FORM_ON_UNHANDLED_VALUE_TYPE constant.
 * @param {{yes: boolean, no: boolean, notAnswered: boolean}} params.appliedFilters - The filters that have been applied.
 * These filters determine the visibility of the form questions.
 * @returns {boolean}
 */
export const isFormQuestionVisible = ({ value, appliedFilters }) =>
    cond([
        [isEmptyValue, () => appliedFilters[FILTER_KEYS.NOT_ANSWERED]],
        [isBoolean, satisfiesValueConditions(appliedFilters, boolValueConditions)],
        [isString, satisfiesValueConditions(appliedFilters, stringValueConditions)],
        [constant(true), constant(SHOW_FORM_ON_UNHANDLED_VALUE_TYPE)],
    ])(value);
