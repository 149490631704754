import { deleteSecondOpinionDetail } from 'scenes/case/edit/CaseEdit.action';
export default {
    mapStateToProps: (state) => {
        return {
            caseBusinessId: state.caseEditReducer.case.businessId
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            deleteSecondOpinionDetail: (secondOpinionBusinessId, caseBusinessId) =>
                dispatch(deleteSecondOpinionDetail(secondOpinionBusinessId, caseBusinessId)),
        };
    }
};
