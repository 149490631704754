import { Typography } from "@material-ui/core";
import Flex from "components/grid/Flex";
import HmoInputLabel from "components/input-label/HmoInputLabel";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import moment from "moment";
import IconWithCounter from "scenes/managed-care/component/IconWithCounter";
import StopIcon from "@material-ui/icons/HighlightOff";
import { getNextStep, getStepStatus } from "./functions";
import { ItemStatusEnum } from "domain/Step.model";
import { useNavigate } from "react-router-dom";
import { PATIENT_PAGE_PROGRAM } from "routes/routes";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import { isManager, isPatient } from "../../domain/User.model";
import { useSelector } from "react-redux";

const Program = ({ program, canBeOpened = true }) => {
    const user = useSelector((state) => state.mainReducer.user);
    const { name, supervisorName, steps, stepsOrder, suspendedParticipation, requestToUnsubscribe, patientId, id } =
        program?.careProgramData;
    const patientRoleSelected = isPatient(user);
    const patientPendingSuspendedParticipationViewActive = requestToUnsubscribe && !suspendedParticipation;
    const showUserRequestedToLeave = requestToUnsubscribe && !suspendedParticipation && isManager(user);
    const leftSideIsInactive = isManager(user) ? suspendedParticipation : (suspendedParticipation || requestToUnsubscribe)
    const theme = useTheme();
    const { t: translate } = useTranslation();
    const navigate = useNavigate();
    const selectProgram = () => {
        if(!canBeOpened) return;
        navigate(PATIENT_PAGE_PROGRAM.pathWithId(patientId, id));
    };
    const nextStep = getNextStep(steps, stepsOrder);
    const stepStatus = getStepStatus(nextStep);
    const color = (() => {
        switch (stepStatus) {
            case ItemStatusEnum.Completed:
                return theme.palette.primary.light;
            case ItemStatusEnum.Upcoming:
                return theme.palette.warning.dark;
            case ItemStatusEnum.Overdue:
                return theme.palette.error.main;
            case ItemStatusEnum.Normal:
            default:
                return theme.palette.common.black;
        }
    })();

    const ProgramDetailsLeftSide = ({inactive}) => {
        return (
            <Flex
                item
                container
                column
                style={{
                    width: "33%",
                    borderRight: "1px solid #E2E1E4",
                    paddingRight: 20,
                    opacity: inactive ? 0.3 : 1
                }}
            >
                <Flex item container justifyContent={"space-between"}>
                    <Typography data-testid={`patient-program-item-id-${id}`} style={{ fontWeight: "bold", fontSize: 30 }}>
                        {name}
                    </Typography>
                </Flex>
                {showUserRequestedToLeave && (
                    <small style={{ fontSize: "13px", fontWeight: "bold", color: theme.palette.error.main }}>
                        {translate("program.user-requested-to-unsubscribe")}
                    </small>
                )}

                <HmoInputLabel small>{translate("global.supervisor")}</HmoInputLabel>
                <Typography style={{ fontWeight: "bold" }}>{supervisorName}</Typography>
            </Flex>
        );
    };

    const ProgramDetailsRightSide = (props) => {

        const theme = useTheme();

        const { message, inactive } = props;
        return (
            <Flex
                item
                container
                style={{
                    width: "66%",
                    marginLeft: 5,
                    paddingLeft: 20,
                    opacity: (inactive && !message) ? 0.3 : 1,

                }}
            >
                {!message ? (
                    <>
                        <Flex item container column grow={0} style={{ marginRight: 30 }}>
                            <HmoInputLabel small>{translate("global.date")}</HmoInputLabel>
                            <Typography style={{ fontWeight: "bold", color, fontSize: 17 }}>
                                {nextStep?.date ? moment(nextStep?.date).format("DD MMMM YYYY") : "-"}
                            </Typography>
                        </Flex>
                        <Flex item container column>
                            <HmoInputLabel small>{translate("global.next-step")}</HmoInputLabel>
                            <Typography style={{ fontWeight: "bold", color, fontSize: 17 }}>
                                {nextStep?.description}
                            </Typography>
                            <div style={{ marginTop: "auto" }}>
                                <HmoInputLabel small>{translate("global.wo")}</HmoInputLabel>
                                <Typography style={{ fontWeight: "bold" }}>{nextStep?.place || "-"}</Typography>
                            </div>
                        </Flex>
                        {suspendedParticipation && (
                            <Flex item container grow={0} center>
                                <StopIcon style={{ height: 100, width: 100, fill: "red" }} />
                            </Flex>
                        )}
                        {!suspendedParticipation && (
                            <Flex item container grow={0} column>
                                <IconWithCounter
                                    style={{ margin: "5px 0px" }}
                                    count={program?.numberOfUnreadDocuments}
                                    Icon={InsertDriveFileOutlinedIcon}
                                />
                                <IconWithCounter
                                    style={{ margin: "5px 0px" }}
                                    count={program?.numberOfUnseenChats}
                                    Icon={ChatBubbleOutlineIcon}
                                />
                                <IconWithCounter
                                    style={{ margin: "5px 0px" }}
                                    count={program?.numberOfContacts}
                                    Icon={VisibilityOutlinedIcon}
                                />
                            </Flex>
                        )}
                    </>
                ) : (
                    <div style={{color: theme.palette.error.main}}>
                        <div style={{marginTop: theme.spacing(1)}}>{message.title}</div>
                        <div style={{marginTop: theme.spacing(1)}}>{message.subtitle}</div>
                    </div>
                )}
            </Flex>
        );
    };

    return (
        <Flex
            item
            container
            role="listitem"
            style={{
                padding: 10,
                margin: '10px 0px 10px 10px',
                borderRadius: 10,
                border: `2px solid ${color}`,
                backgroundColor: suspendedParticipation ? "#F8F8FA" : "#E3F2F5",
                cursor: canBeOpened ? "pointer" : "default",
            }}
            grow={0}
            onClick={selectProgram}
        >
            <ProgramDetailsLeftSide inactive={leftSideIsInactive} />
            <ProgramDetailsRightSide inactive={suspendedParticipation || patientPendingSuspendedParticipationViewActive} message={patientPendingSuspendedParticipationViewActive && patientRoleSelected ? {
                title: translate("program.patient-request-to-unsub-message.title"),
                subtitle: translate("program.patient-request-to-unsub-message.subtitle"),

            }: null} />
        </Flex>
    );
};

export default Program;
