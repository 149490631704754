import * as actionTypes from './Clinics.action.types';
import _ from 'lodash';

const initialState = {
    clinics: [],
    clinicCentersWithClinicData: [],
    activityTab: 'CANCER'
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.LIST_CLINICS_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.clinics = action.payload.data;
            newState.clinicCentersWithClinicData = mapToClinicCenters(action.payload.data);
            return newState;
        }
        case actionTypes.SET_ACTIVITY_TAB:{
            const newState = {...state};
            newState.activityTab = action.data.activityTab;
            return newState;
        }
        default:
            return state;
    }
}

function mapToClinicCenters(clinics) {
    let result = [];
    clinics.forEach(clinic => {
        const enrichedCenters = clinic.centers.map(center => enrichWithClinicData(center, clinic));
        result = result.concat(enrichedCenters);
    });
    return result;
}

function enrichWithClinicData(center, clinic){
    return {
        ...center,
        name: `${clinic.name}; ${center.name}`,
        street: clinic.street,
        zipCode: clinic.zipCode,
        country: clinic.country,
        state: clinic.state,
        city: clinic.city,
        latitude: clinic.latitude,
        longitude: clinic.longitude,
    }
}
