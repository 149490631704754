import React, { Component } from "react";
import Flex from "components/grid/Flex";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import CloseIcon from "@material-ui/icons/Close";
import { Button, Dialog, Typography, IconButton, Tooltip } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import connector from "./Profile.connect";
import { head } from "lodash/fp";

export class OutstandingInvites extends Component {
    state = {
        openedInvites: this.props.outstandingInvites,
        alert: "warning",
    };

    openAll = () => {
        this.props.setHandledOutstandingInvites(false);
        this.setState({ openedInvites: this.props.outstandingInvites });
    };

    removeDialog = (inviteToken) => () => {
        const openedInvites = this.state.openedInvites.filter((x) => x.inviteToken !== inviteToken);
        if(openedInvites.length === 0) {
            this.props.setHandledOutstandingInvites(true);
        }
        this.setState({ openedInvites });
    };

    closeSnackbar = () => {
        this.setState({ alert: "" });
    };

    yes = (invite) => () => {
        this.props
            .confirmAccess(invite.invitedEmail)
            .then(() => {
                this.props.getOutstandingRegisteredInvites();
                this.removeDialog(invite.inviteToken)();
                this.setState({ alert: "success" });
            })
            .catch(() => this.setState({ alert: "error" }));
    };

    no = (invite) => () => {
        this.props
            .rejectAccess(invite.invitedEmail)
            .then(() => {
                this.props.getOutstandingRegisteredInvites();
                this.removeDialog(invite.inviteToken)();
            })
            .catch(() => this.setState({ alert: "error" }));
    };

    render() {
        const { t: translate, outstandingInvites } = this.props;
        const { openedInvites } = this.state;
        const { yes, no, removeDialog } = this;
        const inviteToShow = head(openedInvites);
        return this.props.handledOutstandingInvites  ? (
            <Tooltip title={`Pending Invites (${outstandingInvites.length || ''})`}>
            <Flex
                container
                center
                style={{
                    borderRadius: "50%",
                    backgroundColor: "orange",
                    width: 30,
                    height: 30,
                    margin: "auto",
                    userSelect: "none",
                    cursor: "pointer",
                }}
                onClick={this.openAll}
            >
                !
            </Flex>
            </Tooltip>
        ) : !!inviteToShow && (
            <Dialog onClose={(e) => {
                if(e.code === "Escape") {
                    this.removeDialog(inviteToShow.inviteToken)();
                }
            }} key={inviteToShow.inviteToken} open={true} maxWidth={"sm"}>
                <Flex item container column padding={20} style={{ backgroundColor: "#f8f8fa" }}>
                    <Flex item container alignItems={"center"}>
                        <ReportProblemIcon />
                        <Typography variant={"h6"} style={{ marginLeft: 10 }}>
                            {translate("global.invite-confirm-title")}
                        </Typography>
                        <IconButton
                            onClick={removeDialog(inviteToShow.inviteToken)}
                            style={{ marginLeft: "auto", padding: 5 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Flex>
                    <Typography>{translate("global.invite-confirm-text")}</Typography>

                    <Flex
                        item
                        container
                        column
                        style={{
                            border: "1px solid grey",
                            borderRadius: 4,
                            padding: 5,
                            marginTop: 10,
                            backgroundColor: "white",
                        }}
                    >
                        <Typography>{translate("global.invited-person")}</Typography>
                        <Typography style={{ fontWeight: "bold" }}>{inviteToShow.invitedEmail}</Typography>
                        <Typography style={{ fontWeight: "bold" }}>{inviteToShow.invitedName}</Typography>
                    </Flex>

                    <Flex
                        item
                        container
                        column
                        style={{
                            border: "1px solid darkgreen",
                            borderRadius: 4,
                            padding: 5,
                            margin: "10px 0px",
                            backgroundColor: "white",
                        }}
                    >
                        <Typography>{translate("global.registered-person")}</Typography>
                        <Typography style={{ color: "darkgreen" }}>{inviteToShow.registeredEmail}</Typography>
                        <Typography
                            style={{ color: "darkgreen" }}
                        >{`${inviteToShow.registeredGivenName} ${inviteToShow.registeredFamilyName}`}</Typography>
                    </Flex>

                    <Typography>{translate("global.invite-confirm-question")}</Typography>
                    <Flex item container center style={{ marginTop: 10 }}>
                        <Button
                            variant={"contained"}
                            size={"small"}
                            onClick={no(inviteToShow)}
                            color={"secondary"}
                            style={{ margin: "0px 5px" }}
                        >
                            {translate("global.no")}
                        </Button>
                        <Button
                            variant={"contained"}
                            size={"small"}
                            onClick={yes(inviteToShow)}
                            color={"primary"}
                            style={{ margin: "0px 5px" }}
                        >
                            {translate("global.yes")}
                        </Button>
                    </Flex>
                </Flex>
            </Dialog>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(OutstandingInvites));
