import { Switch, Box } from "@material-ui/core";
import { Trans } from "react-i18next";

export const SwitchRenderer = ({ config, rule, idx, handleUpdateRule }) => (
    <Box key={idx} display="flex" style={{ gap: "10px" }}>
        <Box display="flex" alignItems="center">
            <Trans i18nKey="global.no" />
            <Switch
                checked={rule.properties.value}
                size="small"
                onChange={(e) =>
                    handleUpdateRule(idx, {
                        ...rule.properties,
                        value: e.target.checked,
                    })
                }
                color="primary"
            />
            <Trans i18nKey="global.yes" />
        </Box>
    </Box>
);
