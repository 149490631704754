import { useContext } from 'react';
import { Grid, Modal, Card, Box, CardHeader, CardContent, TextField, IconButton } from "@material-ui/core";
import { useFormik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { UploadFilesAction, UploadFilesDispatchContext } from './uploadFilesState';
import { useUpdateDocumentLinkMutation } from './documentLInks';

const EditDocumentLinkModalForm = ({ editedDocumentLink }) => {
    const dispatchAction = useContext(UploadFilesDispatchContext);
    const [updateDocumentLinkMutation] = useUpdateDocumentLinkMutation();

    const formik = useFormik({
        initialValues: {
            description: editedDocumentLink.description,
            url: editedDocumentLink.url,
            comment: editedDocumentLink.comment,
            businessId: editedDocumentLink.businessId,
        },
        validationSchema: Yup.object({
            description: Yup.string().required(),
            url: Yup.string().url().required(),
        }),
    });
    const { t } = useTranslation();
    return (
        <Modal open={true}>
            <Box mt={20}>
                <Grid container justifyContent="center">
                    <Grid item xs={4}>
                        <Card>
                            <CardHeader
                                title={t("global.edit-link-attributes")}
                                action={
                                    <>
                                        <IconButton size="small" onClick={() => {
                                            dispatchAction({ type: UploadFilesAction.CloseDocumentLinkEditDialog})
                                        }}>
                                            <CloseIcon />
                                        </IconButton>
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                if(!formik.isValid) return;
                                                const { description, url, comment, businessId } = formik.values;
                                                updateDocumentLinkMutation({
                                                    id: businessId,
                                                    documentLinkDTO: {
                                                        url,
                                                        description,
                                                        comment,
                                                    },
                                                });
                                                dispatchAction({ type: UploadFilesAction.CloseDocumentLinkEditDialog });
                                            }}
                                        >
                                            <CheckIcon />
                                        </IconButton>
                                    </>
                                }
                            />
                            <CardContent>
                                <form>
                                    <TextField
                                        style={{ marginBottom: "10px" }}
                                        fullWidth
                                        name="description"
                                        label={t("global.description")}
                                        required
                                        error={formik.touched.description && Boolean(formik.errors.description)}
                                        helperText={formik.touched.description && formik.errors.description}
                                        InputLabelProps={{ shrink: true }}
                                        value={formik.values.description}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <TextField
                                        style={{ marginBottom: "10px" }}
                                        fullWidth
                                        name="url"
                                        label="URL"
                                        placeholder="https://example.com"
                                        required
                                        error={formik.touched.url && Boolean(formik.errors.url)}
                                        helperText={formik.touched.url && formik.errors.url}
                                        onBlur={formik.handleBlur}
                                        InputLabelProps={{ shrink: true }}
                                        value={formik.values.url}
                                        onChange={formik.handleChange}
                                    />
                                    <TextField
                                        fullWidth
                                        style={{ marginBottom: "10px" }}
                                        name="comment"
                                        label={t("global.comment")}
                                        multiline
                                        InputLabelProps={{ shrink: true }}
                                        value={formik.values.comment}
                                        onChange={formik.handleChange}
                                    />
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

const EditDocumentLinkModal = ({ isOpen, editedDocumentLink }) => {
    return (
        isOpen && (
            <EditDocumentLinkModalForm
                editedDocumentLink={editedDocumentLink}
            />
        )
    );
};

export default EditDocumentLinkModal;
