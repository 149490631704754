import {
    PATIENT,
    DOCTOR,
    ASSISTANT,
    CLINIC,
    CONTACT_PERSON,
    isManager,
    isPatient,
    isCaseManager
} from 'domain/User.model';

export const PATIENT_EDITS_OWN_EMAIL = 'PATIENT_EDITS_OWN_EMAIL';
export const DOCTOR_CASE_MANAGER_EDITS_PATIENT_EMAIL = 'DOCTOR_CASE_MANAGER_EDITS_PATIENT_EMAIL';
export const CASE_MANAGER_EDITS_CARE_PROVIDER_CONTACT_EMAIL = 'CASE_MANAGER_EDITS_CARE_PROVIDER_CONTACT_EMAIL';
export const CASE_MANAGER_EDITS_CASE_CONTACT_PERSON_EMAIL = 'CASE_MANAGER_EDITS_CASE_CONTACT_PERSON_EMAIL';
export const PATIENT_GRANTS_ACCESS_TO_DOCTOR = 'PATIENT_GRANTS_ACCESS_TO_DOCTOR';
export const PATIENT_GRANTS_ACCESS_TO_CONTACT_PERSON = 'PATIENT_GRANTS_ACCESS_TO_CONTACT_PERSON';
export const CASE_MANAGER_GRANTS_ACCESS_TO_PATIENT = 'CASE_MANAGER_GRANTS_ACCESS_TO_PATIENT';
export const CASE_MANAGER_GRANTS_ACCESS_TO_PATIENTS_CONTACT_PERSON = 'CASE_MANAGER_GRANTS_ACCESS_TO_PATIENTS_CONTACT_PERSON';
export const CASE_MANAGER_GRANTS_ACCESS_TO_CARE_PROVIDERS_CONTACT_PERSON = 'CASE_MANAGER_GRANTS_ACCESS_TO_CARE_PROVIDERS_CONTACT_PERSON';

export const GRANT = 'GRANT';
export const EDIT = 'EDIT';

export function getGrantTypeScenario(invitedRoleType, currentUser, editorType = EDIT) {
    if(editorType === EDIT){
        if (invitedRoleType === PATIENT && isPatient(currentUser)) {
            return PATIENT_EDITS_OWN_EMAIL;
        } else if (invitedRoleType === PATIENT && isManager(currentUser)) {
            return DOCTOR_CASE_MANAGER_EDITS_PATIENT_EMAIL;
        } else if ([DOCTOR, ASSISTANT, CLINIC].includes(invitedRoleType) && isCaseManager(currentUser)) {
            return CASE_MANAGER_EDITS_CARE_PROVIDER_CONTACT_EMAIL;
        } else if (invitedRoleType === CONTACT_PERSON && isCaseManager(currentUser)) {
            return CASE_MANAGER_EDITS_CASE_CONTACT_PERSON_EMAIL;
        }
    } else {
        if (invitedRoleType === CONTACT_PERSON && isPatient(currentUser)) {
            return PATIENT_GRANTS_ACCESS_TO_CONTACT_PERSON;
        } else if (invitedRoleType === PATIENT && isManager(currentUser)) {
            return CASE_MANAGER_GRANTS_ACCESS_TO_PATIENT;
        } else if (invitedRoleType === CONTACT_PERSON && isManager(currentUser)) {
            return CASE_MANAGER_GRANTS_ACCESS_TO_PATIENTS_CONTACT_PERSON;
        } else if ([DOCTOR, ASSISTANT, CLINIC].includes(invitedRoleType) && isCaseManager(currentUser)) {
            return CASE_MANAGER_GRANTS_ACCESS_TO_CARE_PROVIDERS_CONTACT_PERSON;
        }
    }

}
