import { FETCH_ICD10 } from "./SecondOpinionDetailDialog.action.types";

export function fetchIcd10(icdFilter) {
    return {
        type: FETCH_ICD10,
        payload: {
            client: 'api',
            request: {
                url: `/icds?icdFilter=${icdFilter}`,
                method: 'get',
            }
        }
    };
}
