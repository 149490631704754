import React, { useCallback, useState } from "react";
import { Button, Grid, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
    MedicationPlanType,
    useDeleteMedicationPlanMutation,
    useQueryPageMedicationPlanQuery,
} from "scenes/patient/medication-plan/MedicationPlanApi";
import { tableColumnsForRegularType, tableColumnsForSpecialType } from "./config";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import MedicationFormDialog from "./MedicationFormDialog";
import moment from "moment";
import SimpleConfirmDialog from "components/confirmation-dialog/SimpleConfirmDialog";
import { useParams } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { MedicineApplicationTimeSpanType } from "scenes/patient/medication-plan/medicationDurationPlan.enum";
import { medicationTableOrderComparator } from "scenes/patient/functions/comparators/medicationTableOrderComparator";
import { MedicationWalkthrough } from "scenes/walkthrough/medicationWalkthrough/medicationWalkthrough";
import { useDispatch, useSelector } from "react-redux";
import { SET_NEW_MEDICATION_BTN_HIGHLIGHT } from "scenes/Main.action.types";
import { MedicationListWalkthrough } from "scenes/walkthrough/medicationListWalkthrough/MedicationListWalkthrough";
import { useFetchPatientByIdQuery } from "scenes/patient/PatientApi";
import { datePartsToDate } from "utils/Utils";
import classNames from "classnames";

const initializePlan = (patientId) => ({
    medicinePlanType: MedicationPlanType.REGULAR,
    creationDate: moment(),
    medicineApplicationTimeSpanType: MedicineApplicationTimeSpanType.Continuous,
    patientId,
});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: "500px",
        padding: "10px",
    },

    hideFooterPagination: {
        "& .MuiDataGrid-footerContainer": {
            display: "none",
        },
    },

    dataTableGrid: {
        "& .MuiDataGrid-main": {
            borderRadius: "15px",
        },

        "& .MuiDataGrid-columnHeaders": {
            background: "#F8F8FA",
        },
        "& .MuiDataGrid-columnHeader": {
            background: "#F8F8FA",
        },
        "& .MuiDataGrid-cellContent": {
            lineHeight: "37px",
            color: "#030303",
            fontWeight: 400,
            whiteSpace: "pre-wrap",
            wordBreak: "break-all",
        },
    },

    redRow: {
        fontWeight: "bold",
        color: theme.palette.error.main,

        "& .MuiDataGrid-cellContent": {
            color: theme.palette.error.main,
        },
    },

    deletedRow: {
        fontWeight: "bold",
        color: theme.palette.grey[500],

        "& .MuiDataGrid-cellContent": {
            color: theme.palette.grey[500],
        },
    },

    noEntries: {
        marginTop: theme.spacing(9),
        fontStyle: "normal",
        fontSize: "18px",
        color: theme.palette.text.hint,
    },

    row: {
        padding: theme.spacing(1),
        "&>h2": {
            marginBottom: theme.spacing(2),
        },
    },
}));

const CustomDataGrid = (props) => {
    const { classes, hideFooterPagination, ...otherProps } = props;
    const [pageSize, setPageSize] = useState(20);
    return (
        <DataGrid
            sx={{
                border: "1px solid #E2E1E4",
                borderRadius: "15px",
            }}
            className={classNames(classes.dataTableGrid, hideFooterPagination ? classes.hideFooterPagination : "")}
            getRowHeight={() => "auto"}
            autoHeight
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            disableColumnMenu={!!hideFooterPagination}
            disableSelectionOnClick
            hideFooterPagination={!!hideFooterPagination}
            {...otherProps}
        />
    );
};

const NoEntries = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <div className={classes.noEntries} style={{ textAlign: "center" }}>
            {t("medication.no-medication-yet")}
        </div>
    );
};

const MedicationPlanList = () => {
    const shouldNewMedicationButtonGlow = useSelector((state) => state.mainReducer.glowUpNewMedicationButton);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();
    const { patientId } = useParams();
    const lang = useCallback((name) => t(`medication.${name}`), [t]);
    const medicationUnitTranslation = useCallback((name) => (name ? t(`medication.medicationUnit.${name}`) : ""), [t]);
    const langTable = useCallback((name) => t(`medication.table.${name}`), [t]);

    const [selectedDataRow, setSelectedDataRow] = useState({
        ...initializePlan(patientId),
    });
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [isConfirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [dataRowForDelete, setDataRowForDelete] = useState(null);

    const { data: tableData, isFetching: tableDataFetching } = useQueryPageMedicationPlanQuery({
        sort: "medicine,asc",
        size: 5000,
        patientId,
    });

    const { data: patientData = {} } = useFetchPatientByIdQuery(patientId);
    const anonymized = patientData.anonymized;

    const [deleteItem] = useDeleteMedicationPlanMutation();

    const handleCellDoubleClick = useCallback(
        (params, _event) => {
            if (anonymized) {
                return;
            }
            const { ingredient, medicine, creationDate, medicineApplicationTimeSpanType } = params.row;
            setSelectedDataRow({
                ...params.row,
                ingredientId: ingredient?.id ?? 0,
                medicineId: medicine?.id ?? 0,
                patientId: patientId,
                creationDate: creationDate ? moment(creationDate).startOf("day").toISOString() : creationDate,
                medicineApplicationTimeSpanType: medicineApplicationTimeSpanType,
            });
            setDialogOpen(true);
        },
        [patientId],
    );

    const handleDialogClose = useCallback(() => {
        setDialogOpen(false);
    }, [setDialogOpen]);

    const handleCreateItemButton = useCallback(
        (_e) => {
            setSelectedDataRow(initializePlan(patientId));
            dispatch({ type: SET_NEW_MEDICATION_BTN_HIGHLIGHT, payload: false });
            setDialogOpen(true);
        },
        [setDialogOpen],
    );

    const handleDeleteRow = useCallback(
        (params) => {
            if (anonymized) return;
            setDataRowForDelete(params.row);
            setConfirmDeleteOpen(true);
        },
        [setDataRowForDelete, setConfirmDeleteOpen, anonymized],
    );

    const handleConfirmDelete = useCallback(() => {
        if (dataRowForDelete != null) {
            deleteItem(dataRowForDelete);
        }
        setDataRowForDelete(null);
        setConfirmDeleteOpen(false);
    }, [dataRowForDelete, setDataRowForDelete, deleteItem, setConfirmDeleteOpen]);

    const handleCancelDelete = useCallback(
        (_params) => {
            setConfirmDeleteOpen(false);
            setDataRowForDelete(null);
        },
        [setConfirmDeleteOpen, setDataRowForDelete],
    );

    const getRowClassName = useCallback(
        (params) => {
            if (params.row.deletionDate != null) {
                return classes.deletedRow;
            }
            const to = datePartsToDate(params.row.toDateYear, params.row.toDateMonth, params.row.toDateDay);
            if (to.date && moment().isAfter(to.date)) {
                return classes.redRow;
            }
            return "";
        },
        [classes],
    );

    const theme = useTheme();
    const isLowerThanLgSize = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.lg));
    const regularMedications =
        tableData?.content?.filter((item) => item.medicinePlanType === MedicationPlanType.REGULAR) || [];
    const irregularMedications =
        tableData?.content?.filter((item) => item.medicinePlanType === MedicationPlanType.SPECIAL_TIMES) || [];
    const selfMedications = tableData?.content?.filter((item) => item.selfMedication === true) || [];

    return (
            <>
                <MedicationWalkthrough/>
                {tableData?.content.some((row) => row.deletionDate === null) && <MedicationListWalkthrough/>}
                <SimpleConfirmDialog
                        title={lang("confirmDeleteTitle")}
                        message={lang("confirmDelete")}
                        open={isConfirmDeleteOpen}
                        onConfirm={handleConfirmDelete}
                        onCancel={handleCancelDelete}
                />

                <MedicationFormDialog
                        dialogProps={{
                            maxWidth: "sm",
                        }}
                        initialValues={selectedDataRow}
                        open={isDialogOpen}
                        data={tableData}
                        handleClose={handleDialogClose}
                />

                <div className={classes.root}>
                    {!anonymized && (
                            <Box justifyContent="space-between" alignItems="center" p="10px" display="flex">
                                <Typography
                                        component="h1"
                                        data-testid="medication-plan-welcome-title"
                                        variant="h3"
                                        color="primary"
                                        className={classes.mainTitle}
                                >
                                    {lang("medicationPlanTitle")}
                                </Typography>
                                <Button
                                        ///  need glowing style
                                        style={{
                                            boxShadow: shouldNewMedicationButtonGlow
                                                    ? `0px 0px 10px 3px ${theme.palette.info.main}`
                                                    : "",
                                        }}
                                        walkthrough-element="add-medication-btn"
                                        size={isLowerThanLgSize ? "small" : "medium"}
                                        className={classes.primaryButton}
                                        variant="contained"
                                        color="primary"
                                        onClick={handleCreateItemButton}
                                >
                                    {lang("addMedicationButton")}
                                </Button>
                            </Box>
                    )}

                    {tableData?.content.length === 0 && <NoEntries/>}

                    {regularMedications.length !== 0 && (
                            <Grid container>
                                <Grid className={classes.row} item xs={12}>
                                    <Typography component="h2" variant="h4">
                                        {lang("subscribedByDoctor")}
                                    </Typography>
                                    <CustomDataGrid
                                            classes={classes}
                                            rows={regularMedications
                                                    .map((row) => ({
                                                        ...row,
                                                        getRowClassName,
                                                        handleDeleteRow,
                                                    }))
                                                    .sort(medicationTableOrderComparator)}
                                            columns={tableColumnsForRegularType.map((column) => {
                                                return {
                                                    ...column,
                                                    headerName: langTable(column.headerName),
                                                    sortable: regularMedications?.length > 20,
                                                    valueFormatter: (params) => {
                                                        if (params.field === "medicineForm") {
                                                            return medicationUnitTranslation(params.value) ?? "";
                                                        }
                                                    },
                                                };
                                            })}
                                            getRowClassName={getRowClassName}
                                            onCellDoubleClick={handleCellDoubleClick}
                                            loading={tableDataFetching}
                                            hideFooterPagination={regularMedications?.length <= 20}
                                    />
                                </Grid>
                            </Grid>
                    )}

                    {irregularMedications.length !== 0 && (
                            <Grid container>
                                <Grid className={classes.row} item xs={12}>
                                    <Typography component="h2" variant="h4">
                                        {lang("medicationSpecialTime")}
                                    </Typography>
                                    <CustomDataGrid
                                            classes={classes}
                                            rows={irregularMedications
                                                    .map((row) => ({
                                                        ...row,
                                                        handleDeleteRow,
                                                        getRowClassName,
                                                    }))
                                                    .sort(medicationTableOrderComparator)}
                                            columns={tableColumnsForSpecialType.map((column) => ({
                                                ...column,
                                                headerName: langTable(column.headerName),
                                                sortable: irregularMedications?.length > 20,
                                                valueFormatter: (params) => {
                                                    if (params.field === "medicineForm") {
                                                        return medicationUnitTranslation(params.value) ?? "";
                                                    }
                                                },
                                            }))}
                                            onCellDoubleClick={handleCellDoubleClick}
                                            getRowClassName={getRowClassName}
                                            loading={tableDataFetching}
                                            sortable={false}
                                            hideFooterPagination={irregularMedications?.length <= 20}
                                    />
                                </Grid>
                            </Grid>
                    )}

                    {selfMedications.length !== 0 && (
                            <Grid container>
                                <Grid className={classes.row} item xs={12}>
                                    <Typography component="h2" variant="h4">
                                        {lang("selfMedicationHomeopaty")}
                                    </Typography>
                                    <CustomDataGrid
                                            classes={classes}
                                            rows={selfMedications.map((row) => ({
                                                ...row,
                                                handleDeleteRow,
                                                getRowClassName,
                                            }))}
                                            columns={tableColumnsForSpecialType
                                                    .reduce((acc, curr) => {
                                                        if (!acc.some((el) => el.field === curr.field)) {
                                                            return [...acc, curr];
                                                        }
                                                        return acc;
                                                    }, tableColumnsForRegularType)
                                                    .sort((a, b) => {
                                                        return b.field === "delete" ? -1 : 1;
                                                    })
                                                    .map((col) => ({
                                                        ...col,
                                                        headerName: langTable(col.headerName),
                                                        sortable: selfMedications?.length > 20,
                                                    }))}
                                            getRowClassName={getRowClassName}
                                            onCellDoubleClick={handleCellDoubleClick}
                                            loading={tableDataFetching}
                                            hideFooterPagination={selfMedications?.length <= 20}
                                    />
                                </Grid>
                            </Grid>
                    )}
                </div>
            </>
    );
};

export default MedicationPlanList;
