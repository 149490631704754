import React, {Component} from 'react';
import {withStyles, Dialog, Button, Typography} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import * as colors from 'components/colors/Colors';
import {withTranslation} from 'react-i18next';
import Title from 'components/title/Title';
import HmoFilledButton from 'components/button/HmoFilledButton';
import EmailTypeRow from './EmailTypeRow';
import DefaultTextField from 'components/hmo-textfield/DefaultTextField';
import EmailTypeHeader from './EmailTypeHeader';

const styles = theme => ({
    cancelButton: {
        fontSize: 13,
        textTransform: "unset",
        color: colors.htmlGrey,
    },
    checkBoxIcon: {
        fill: colors.niagara,
    },
    checkBox: {
        padding: 2
    },
    paper: {
        minWidth: 650
    },
    commaSeparated: {
        //TODO LM: Keeping these working examples of just how asinine jss can be
        // '& span':{
        //     color: 'blue',
        // },
        // '& span:hover':{
        //     color: 'red'
        // },
        // '& span:after':{
        //     content: '"ß "'
        // },
        '& span ~ span:before':{
            content: '", "'
        }
    }
});

export class EmailManagerDialog extends Component {
    render() {
        const {
            classes, t: translate, toggleOpen, sendEmails, emailAddress, setEmailAddress, open,
            selectedEmailTypes, selectEmailType, caseLogs, isActOfAssignmentEnabled, person, isInsuranceCovered
        } = this.props;
        const emailTypes = ['WELCOME', 'COST_NOTE', 'POWER_OF_ATTORNEY', 'ACT_OF_ASSIGNMENT', 'QUESTIONNAIRE'];
        const notEnoughData = !emailAddress ||
                !person?.bioSex ||
                !person?.givenName ||
                !person?.familyName ||
                !person?.street ||
                !person?.zipCode ||
                !person?.city ||
                !person?.country ||
                !person?.dateOfBirth;
        const doesUserExists = person?.userBusinessId;
        return (
                <Dialog open={open} maxWidth={'md'} classes={{paper: classes.paper}}>
                    <Flex item container column padding={20}>
                        <Flex id={'header'} item container alignItems={"center"} justifyContent={"space-between"}
                              style={{paddingBottom: 10, borderBottom: `1px solid ${colors.grey}`}}
                        >
                            <Flex item container alignItems={"center"}>
                                <Title style={{color: colors.niagara}}>
                                    {translate("global.email-send-out")}
                                </Title>
                            </Flex>
                            <Flex item container justifyContent={"flex-end"}>
                                <Button onClick={toggleOpen} className={classes.cancelButton} style={{marginRight: 10}}>
                                    {translate("global.cancel")}
                                </Button>

                                <HmoFilledButton
                                        style={{backgroundColor: colors.niagara, color: 'white'}}
                                        disabled={notEnoughData}
                                        onClick={sendEmails}
                                >
                                    {translate("global.send-email")}
                                </HmoFilledButton>
                            </Flex>
                        </Flex>
                        {
                            notEnoughData &&
                            <Flex item column container style={{marginTop: 10, color: 'orange', fontWeight: 'bold'}}>
                                <Typography>{translate('global.attention')}!</Typography>
                                <Typography>{translate('global.minimum-patient-data-email')}</Typography>
                                <Flex item container className={classes.commaSeparated}>
                                    {
                                        emailAddress ? '' : <span>{translate('global.emailAddresses')}</span>
                                    }
                                    {
                                        person &&
                                        [
                                            ...(!person.bioSex ? [['bioSex', 'sex']] : []),
                                            ...(!person.givenName ? [['givenName', 'first-name']] : []),
                                            ...(!person.familyName ? [['familyName', 'last-name']] : []),
                                            ...(!person.street ? [['street', 'street']] : []),
                                            ...(!person.zipCode ? [['zipCode', 'zip']] : []),
                                            ...(!person.city ? [['city', 'city']] : []),
                                            ...(!person.country ? [['country', 'country']] : []),
                                            ...(!person.dateOfBirth ? [['dateOfBirth', 'date-of-birth']] : [])
                                        ].map(pair => <span key={pair[1]}> {translate(`case.${pair[1]}`)} </span>)
                                    }
                                </Flex>


                            </Flex>
                        }
                        <Flex item grow={0} container justifyContent={'center'} style={{marginTop: 10}}>
                            <DefaultTextField
                                    label={translate('global.recipient-email')}
                                    value={emailAddress || ''}
                                    onChange={setEmailAddress}
                                    fullWidth
                                    name={'emailAddress'}
                            />
                        </Flex>
                        <Flex item container column style={{
                            marginTop: 10,
                            marginBottom: 10,
                            borderRadius: 4,
                        }}
                        >
                            <EmailTypeHeader/>
                            {
                                (emailTypes || []).map((type, i) => (
                                        <EmailTypeRow key={i} {...{
                                            classes,
                                            translate,
                                            type,
                                            caseLogs,
                                            isActOfAssignmentEnabled,
                                            selectedEmailTypes,
                                            selectEmailType,
                                            notEnoughData,
                                            isInsuranceCovered,
                                            doesUserExists
                                        }}/>
                                ))
                            }
                        </Flex>
                    </Flex>
                </Dialog>
        );
    }
}

export default withStyles(styles)(withTranslation()(EmailManagerDialog));
