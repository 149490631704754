import HMOApi from "store/api/HMOApi";

export const TagApi = HMOApi.injectEndpoints({
    endpoints: (build) => ({
        addTag: build.mutation({
            query: ({tag, entityId, entityType}) => ({
                url: "tag",
                method: "PUT",
                data: {tag, entityId, entityType},
            }),
        }),
        removeTag: build.mutation({
            query: ({id}) => ({
                url: `tag/${id}`,
                method: "DELETE"
            }),
        }),
    }),
});

export const {
    useAddTagMutation,
    useRemoveTagMutation
} = TagApi;
