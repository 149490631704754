/**
 * Comparator function that can be used to sort programs by suspendedParticipation
 * items with suspendedParticipation should come after items without suspendedParticipation
 */
export const compareBySuspendedParticipation = (programA, programB) => {
    if (programA.careProgramData.suspendedParticipation && !programB.careProgramData.suspendedParticipation) {
        return 1;
    } else if (!programA.careProgramData.suspendedParticipation && programB.careProgramData.suspendedParticipation) {
        return -1;
    }
    return 0;
};
