import moment from "moment";
import {
    accumulateLaboratoryValuesWithUnit,
    convertHemoglobinUnits,
    getLaboratoryValuesWithConvertedUnits, isAcceptableValue
} from "./mapperUtils";

import {HEMOGLOBIN, LEUKOZYTEN, MCV, RDW, THROMBOZYTEN} from "domain/Laboratory.model";

/**
 * @typedef {Object} HematologyRiskProfile
 * @property {boolean} singleDNMT3A
 * @property {boolean} highRiskMutation
 * @property {number} mutationNumber
 * @property {number} varianAlleleFractionAboveThreshold
 * @property {number} redCellDistributionWidth
 * @property {number} meanCorpuscularVolume
 * @property {'CHIP' | 'CCUS'} cythopenia
 * @property {number} age
 */

/**
 * @typedef {Object} MutationData
 * @property {string} date
 * @property {string} dna
 * @property {string} exon
 * @property {string} gene
 * @property {string} protein
 * @property {string} vaf
 */

/**
 * @typedef {Object} LaboratoryData
 * @property {number} id
 * @property {number} patientId
 * @property {number} hemoglobin
 * @property {number} kreatinin
 * @property {number} totalcholesterol
 * @property {number} ldlcholesterol
 * @property {number} hdlcholesterol
 * @property {number} crp
 * @property {number} leukozyten
 * @property {number} mcv
 * @property {number} rdw
 * @property {number} thrombozyten
 * @property {number} gfr
 * @property {string} recordDate
 * @property {string} hemoglobinUnit
 * @property {string} kreatininUnit
 * @property {string} totalcholesterolUnit
 * @property {string} ldlcholesterolUnit
 * @property {string} hdlcholesterolUnit
 * @property {string} crpUnit
 * @property {string} leukozytenUnit
 * @property {string} mcvUnit
 * @property {string} rdwUnit
 * @property {string} thrombozytenUnit
 * @property {string} gfrUnit
 */


/**
 * Maps the internal representation of the HematologyRiskProfile to the representation needed for the hematology risk calculator
 * @param {{dateOfBirth: number}} patient
 * @param {MutationData[]} mutationData
 * @param {LaboratoryData[]} laboratoryData
 * @returns {HematologyRiskProfile}
 */
export const hematologyRiskProfileMapper = (patient = {}, laboratoryData = [], mutationData = []) => {
    const convertedLabValues = getLaboratoryValuesWithConvertedUnits(accumulateLaboratoryValuesWithUnit(laboratoryData),
        {
            [HEMOGLOBIN]: (value, unit) => convertHemoglobinUnits(value, unit)
        });
    return {
        age: dateOfBirthToAge(patient.dateOfBirth),
        singleDNMT3A: isGeneMutationSingle("DNMT3A", mutationData),
        highRiskMutation: isHighRiskMutationPresent(mutationData),
        mutationNumber: uniqueMutationsNumber(mutationData),
        varianAlleleFractionAboveThreshold: isMutationWithVAFAboveThresholdExists(mutationData, 20),
        cythopenia: laboratoryDataToCythopeniaValue(convertedLabValues),
        meanCorpuscularVolume: convertedLabValues.mcv?.value,
        redCellDistributionWidth: convertedLabValues.rdw?.value,
    };
};

/**
 *
 * @param {string} dateOfBirth - A date-like string (e.g., "2023-10-27T12:07:26.868Z")
 * @returns {number} The age calculated from the date of birth
 */
function dateOfBirthToAge(dateOfBirth) {
    return moment().year() - moment(dateOfBirth).year();
}


const highRiskMutationGenes = Object.freeze(["SRSF2", "SF3B1", "ZRSR2", "IDH1", "IDH2", "FLT3", "RUNX1", "JAK2"]);


/**
 *
 * @param {MutationData} mutationData
 * @returns {boolean}
 */
export function isHighRiskMutationPresent(mutationData) {
    return mutationData.some((mutation) => {
        return highRiskMutationGenes.includes(mutation.gene);
    });
}

/**
 *
 * @param {MutationData[]} mutationData
 * @returns {MutationData[]}
 */
function getUniqueMutations(mutationData) {
    return mutationData.reduce((accumulator, current) => {
        const isDuplicate = accumulator.some(
            item => item.protein === current.protein && item.dna === current.dna
        );
        if (!isDuplicate) {
            return [...accumulator, current];
        }
        return accumulator;
    }, []);
}

/**
 *
 * @param {string} gene
 * @param {MutationData[]} mutationData
 * @returns {boolean}
 */
export function isGeneMutationSingle(gene, mutationData) {
    const filteredData = mutationData.filter((mutation) => mutation.gene === gene);
    return getUniqueMutations(filteredData).length === 1;
}

/**
 *
 * @param {MutationData[]} mutationData
 * @returns {number}
 */
function uniqueMutationsNumber(mutationData) {
    return getUniqueMutations(mutationData).length;
}

/**
 *
 * @param {MutationData[]} mutationData
 * @param {number} threshold
 * @returns {boolean}
 */
function isMutationWithVAFAboveThresholdExists(mutationData, threshold) {
    return mutationData.some((mutation) => {
        return mutation.vaf > threshold;
    });
}

/**
 *
 * @param {LaboratoryData} latestLabValues
 * @returns "CHIP" | "CCUS"
 */
function laboratoryDataToCythopeniaValue(latestLabValues) {

    const thresholdValues = {
        leukozyten: 4,
        hemoglobin: 14,
        mcv: 82,
        rdw: 11.6,
        thrombozyten: 150,
    }

    return Object.keys(latestLabValues).some(key => latestLabValues[key]?.value < thresholdValues[key]) ? "CCUS" : "CHIP";

}

export function missingHematoAnswers({patientData, mutationData, laboratoryData}) {
    const laboratoryWithUnit = accumulateLaboratoryValuesWithUnit(laboratoryData);
    const lab = Object.keys(laboratoryWithUnit)
        .filter(key => [MCV, RDW].includes(key))
        .reduce((accumulator, key) => ({
            ...accumulator,
            [key]: laboratoryWithUnit[key]?.value
        }), {});
    const answerMap = {
        "my-risk.missing-mcv": lab.mcv,
        "my-risk.missing-rdw": lab.rdw,
        "my-risk.missing-chip": mutationData?.length,
        "my-risk.missing-birthday": patientData.dateOfBirth,
    }

    return Object.keys(answerMap).reduce((accumulator, current) => {
        if (isAcceptableValue(answerMap[current])) {
            return accumulator;
        } else {
            return [...accumulator, current];
        }
    }, []);
}
