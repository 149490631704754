import React, {useCallback} from 'react';
import FormSection from "../FormSection";
import {Grid, MenuItem, TextField} from "@material-ui/core";
import SubTitle from "../auxiliary/SubTitle";
import {Field } from "formik";
import {validationError} from "../config/ValidationSchema";
import DiscomfortSlider from "../input/DiscomfortSlider";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import InputDivider from "../input/InputDivider";
import { PlaceholderOrValue } from "../input/FieldSelect";
import { DiscomfortLevelEnum, ProblemSeverityEnum } from "../../enums";
import { MentalDiscomfortLevelEnum } from '../../enums/MentalDiscomfortLevel.copy';
import { QuestionWithFiltering } from './QuestionWithFiltering';

const useStyles = makeStyles((theme) => ({
    selectboxGrid: {
        marginTop: 6
    }
}));

const QualityOfLife = ({form, readonly, filters}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const lang = useCallback((name) => t(`anamnesis.quality.${name}`), [t]);

    return (
            <FormSection title={lang('title')}>
                <QuestionWithFiltering filterValue={form.values.mobileValue} filters={filters}>
                <Grid container spacing={1}>
                    <Grid item xs={9}>
                        <SubTitle>
                            {lang('mobileValueQuestion')}
                        </SubTitle>
                    </Grid>
                    <Grid item xs={3} className={classes.selectboxGrid}>

                        <TextField
                                disabled={readonly}
                                name="mobileValue"
                                fullWidth
                                select
                                SelectProps={{
                                    value: form.values.mobileValue || '',
                                    renderValue: PlaceholderOrValue(form.values.mobileValue, t),
                                    onChange: form.handleChange,
                                    displayEmpty: true,
                                    ...validationError(form, 'mobileValue')
                                }}
                        >
                        {Object.values(ProblemSeverityEnum).map((value) => (
                            <MenuItem key={value} value={value}>{lang(value)}</MenuItem>
                        ))}
                        </TextField>
                    </Grid>
                </Grid>
                <InputDivider/>
                </QuestionWithFiltering>
                <QuestionWithFiltering filterValue={form.values.washAndDressValue} filters={filters}>
                <Grid container spacing={1}>
                    <Grid item xs={9}>
                        <SubTitle>
                            {lang('washAndDressValueQuestion')}
                        </SubTitle>
                    </Grid>
                    <Grid item xs={3} className={classes.selectboxGrid}>
                        <TextField
                                disabled={readonly}
                                name="washAndDressValue"
                                fullWidth
                                select
                                SelectProps={{
                                    value: form.values.washAndDressValue|| '',
                                    renderValue: PlaceholderOrValue(form.values.washAndDressValue, t),
                                    onChange: form.handleChange,
                                    displayEmpty: true,
                                    ...validationError(form, 'washAndDressValue')
                                }}
                        >
                        {Object.values(ProblemSeverityEnum).map((value) => (
                            <MenuItem key={value} value={value}>{lang(value)}</MenuItem>
                        ))}
                        </TextField>
                    </Grid>
                </Grid>
                <InputDivider/>
                </QuestionWithFiltering>
                <QuestionWithFiltering filterValue={form.values.dailyActivityValue} filters={filters}>
                <Grid container spacing={1}>
                    <Grid item xs={9}>
                        <SubTitle>
                            {lang('dailyActivityValueSubTitle')}
                        </SubTitle>
                    </Grid>
                    <Grid item xs={3} className={classes.selectboxGrid}>
                        <TextField
                            disabled={readonly}
                            name="dailyActivityValue"
                            fullWidth
                            select
                            SelectProps={{
                                value: form.values.dailyActivityValue|| '',
                                renderValue: PlaceholderOrValue(form.values.dailyActivityValue, t),
                                onChange: form.handleChange,
                                displayEmpty: true,
                                ...validationError(form, 'dailyActivityValue')
                            }}
                        >
                        {Object.values(ProblemSeverityEnum).map((value) => (
                            <MenuItem key={value} value={value}>{lang(value)}</MenuItem>
                        ))}
                        </TextField>
                    </Grid>
                </Grid>
                <InputDivider/>
                </QuestionWithFiltering>
                <QuestionWithFiltering filterValue={form.values.discomfortValue} filters={filters}>
                <Grid container spacing={1}>
                    <Grid item xs={9}>
                        <SubTitle>
                            {lang('discomfortValueQuestion')}
                        </SubTitle>
                    </Grid>
                    <Grid item xs={3} className={classes.selectboxGrid}>
                        <TextField
                            disabled={readonly}
                            name="discomfortValue"
                            fullWidth
                            select
                            SelectProps={{
                                value: form.values.discomfortValue|| '',
                                renderValue: PlaceholderOrValue(form.values.discomfortValue, t),
                                onChange: form.handleChange,
                                displayEmpty: true,
                                ...validationError(form, 'discomfortValue')
                            }}
                        >
                        {Object.values(DiscomfortLevelEnum).map((value) => (
                            <MenuItem key={value} value={value}>{lang(value)}</MenuItem>
                        ))}
                        </TextField>
                    </Grid>
                </Grid>
                <InputDivider/>
                </QuestionWithFiltering>
                <QuestionWithFiltering filterValue={form.values.anxiousValue} filters={filters}>
                <Grid container spacing={1}>
                    <Grid item xs={9}>
                        <SubTitle>
                            {lang('anxiousValueQuestion')}
                        </SubTitle>
                    </Grid>
                    <Grid item xs={3} className={classes.selectboxGrid}>
                        <TextField
                            disabled={readonly}
                            name="anxiousValue"
                            fullWidth
                            select
                            SelectProps={{
                                value: form.values.anxiousValue|| '',
                                renderValue: PlaceholderOrValue(form.values.anxiousValue, t),
                                onChange: form.handleChange,
                                displayEmpty: true,
                                ...validationError(form, 'anxiousValue')
                            }}
                        >
                        {Object.values(MentalDiscomfortLevelEnum).map((value) => (
                            <MenuItem key={value} value={value}>{lang(value)}</MenuItem>
                        ))}
                        </TextField>
                    </Grid>
                </Grid>
                <InputDivider/>
                </QuestionWithFiltering>
                <Grid container spacing={1}>
                    <Grid item xs={8}>
                        <SubTitle>
                            {lang('scaleRateValueQuestion')}
                        </SubTitle>
                    </Grid>
                    <Grid item xs={4} className={classes.selectboxGrid}>
                        <Field
                                props={{
                                    disabled: readonly,
                                }}
                                name="scaleRateValue"
                                fullWidth
                                as={DiscomfortSlider}
                                form={form}
                                {...validationError(form, 'scaleRateValue')}
                        >
                        </Field>
                    </Grid>
                </Grid>
            </FormSection>
    );
};

export default QualityOfLife;
