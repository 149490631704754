import React, { PureComponent } from 'react';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowUpwardIcon from '@material-ui/icons/KeyboardArrowUp';

export class SortIcon extends PureComponent {
    render() {
        const {isShown, reverse} = this.props;
        return (
            <>
                {
                    isShown &&
                    <>
                        {
                            reverse
                                ?
                                <ArrowDownIcon style={{width: 16, height: 16}}/>
                                :
                                <ArrowUpwardIcon style={{width: 16, height: 16}}/>
                        }
                    </>
                }
            </>
        );
    }
}

export default SortIcon;
