import { useContext } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { UploadFilesDispatchContext, UploadFilesAction } from "./uploadFilesState";
import { useDeleteDocumentLinkMutation } from "./documentLInks";
import { useDeleteFileMutation } from "./filesApi";
import { useTranslation } from "react-i18next";


const DeleteConfirmationModalForm = ({ documentType, documentName, id }) => {
    const dispatchAction = useContext(UploadFilesDispatchContext);
    const { t } = useTranslation();
    const [deleteDocumentLinkMutation] = useDeleteDocumentLinkMutation();
    const [deleteFileMutation] = useDeleteFileMutation();
    return (
        <>
            <DialogTitle>{t('global.confirmDeleteTitle')}</DialogTitle>
            <DialogContent>
                {t('global.delete-from-file-list-confirmation-text', {documentName})}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={() => {
                        dispatchAction({ type: UploadFilesAction.CloseDeleteConfirmationDialog });
                    }}
                >
                    {t('global.cancel')}
                </Button>
                <Button
                    onClick={() => {
                        if (documentType === "LINK") {
                            deleteDocumentLinkMutation(id);
                        } else if (documentType === "FILE") {
                            deleteFileMutation(id);
                        }
                        dispatchAction({ type: UploadFilesAction.CloseDeleteConfirmationDialog });
                    }}
                    type="submit"
                    variant="contained"
                    color="primary"
                >
                    {t('global.confirm-delete')}
                </Button>
            </DialogActions>
        </>
    );
};

const DeleteConfirmationModal = ({ isOpen, ...restOfProps }) => {
    return (
        <Dialog open={isOpen}>
            <DeleteConfirmationModalForm {...restOfProps} />
        </Dialog>
    );
};

export default DeleteConfirmationModal;
