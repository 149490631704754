import { flow, join, truncate } from 'lodash/fp';
import { splitFilenameByFileExtension } from './splitFilenameByFileExtension';

/**
 * Curried function that takes a length and returns a new function that truncates the filename in a given string to the specified length.
 *
 * @param {number} length - The maximum length of the truncated filename.
 * @returns {function(string): string} A function that takes a string as input and returns a string with the filename truncated to the specified length.
 * @example
 * truncateFilename(10)("example_filename.txt"); // example_fi...txt"
 */
export const truncateFilename = length => flow(
    splitFilenameByFileExtension,
    ([name, extension]) => [truncate({ length })(name), extension],
    join('.')
  );
