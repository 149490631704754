import React, {Component} from 'react';
import {Typography, withStyles} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {Trans, withTranslation} from 'react-i18next';
import {isCaseManager} from 'domain/User.model';
import {niagara} from 'components/colors/Colors';
import CaseManagerActivity from './CaseManagerActivity';
import CaseListTimer from 'scenes/case/list/CaseListTimer';

const styles = theme => ({
    titleText: {
        fontSize: 15,
        marginRight: 10,
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        textTransform: 'uppercase',
        color: 'white'
    },
    titleBarRoot: {
        zIndex: 7,
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        minHeight: 45,
        color: 'white',
        paddingLeft: 20,
        paddingRight: 20,
    }
});

export class Title extends Component {
    render() {
        const {classes, user, fetchCaseManagers, caseManagers} = this.props;

        return (
            <Flex container justifyContent={'space-between'} alignItems={'center'} className={classes.titleBarRoot}
                  style={{
                      backgroundColor: niagara,
                      marginLeft: 60
                  }}>
                <Flex item container column>
                    <Typography className={classes.titleText}>
                        <Trans i18nKey={'global.case-list'}/>
                    </Typography>
                    {
                        isCaseManager(user) &&
                        <CaseListTimer/>
                    }
                </Flex>
                {
                    isCaseManager(user) &&
                    <CaseManagerActivity {...{fetchCaseManagers, caseManagers}}/>
                }

            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(Title));
