import {useParams} from "react-router-dom";
import {Formik} from "formik";
import React, {useEffect, useRef, useLayoutEffect} from "react";
import {useFetchAnamnesisByPatientQuery, useSaveAnamnesisMutation} from "scenes/patient/anamnesis/AnamnesisApi";
import {useTranslation} from "react-i18next";
import AnamnesisFormWrapper from "./AnamnesisFormWrapper";
import {useSnackbar} from 'notistack';
import {useSelector} from "react-redux";
import {
    useFetchPatientByIdQuery,
    useUpdatePatientDetailsMutation
} from "scenes/patient/PatientApi";
import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';

const AnamnesisForm = () => {
    const {patientId} = useParams();
    const {data: patientData} = useFetchPatientByIdQuery(patientId);
    const [updatePatientDetails] = useUpdatePatientDetailsMutation();
    const setUserDetails = (values) => {
        updatePatientDetails({
                id: patientData.id,
                ...values,
            });
    };

    const anonymized = patientData?.anonymized;

    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    const anamnesisForFormikRef = useRef(null);
    const previousAnamnesesRef = useRef(null);
    const user = useSelector(state => state.mainReducer.user);
    const [initialValues] = React.useState({
        gender: 'MALE',
        hasChipMutation: null,
        chipMutationData: [],
    });

    const {
        data: anamnesisData,
        isSuccess: anamnesisDataLoadedSuccessfully,
        isLoading: anamnesisDataIsLoading,
    } = useFetchAnamnesisByPatientQuery({patientId}, {skip: !patientId});

    const [
        saveAnamnesis,
        {
            isSuccess: isSavingItemSuccessful,
            isError: isSavingItemError,
        },
    ] = useSaveAnamnesisMutation();

    //NOTE LM: Formik does NOT update the anamnesisForFormikRef immediately on change. There is a few hundred millisecond
    // difference between what you see on the screen and when the innerRef actually updates.
    // So you can still close the page or navigate away with unsaved stuff if you are quick enough.
    // Also using anamnesisForFormikRef.current.onSubmit doesnt help because the ref itself is not refreshed.
    // ALso using refs because hooks...
    const saveSnapshot = () => {
        if (previousAnamnesesRef?.current && !_.isEmpty(anamnesisForFormikRef.current?.values)
                && previousAnamnesesRef?.current !== JSON.stringify(anamnesisForFormikRef.current.values)) {
            saveAnamnesis(anamnesisForFormikRef.current.values);
        }
    }

    const handleSave = values => {
        if (anamnesisDataLoadedSuccessfully && previousAnamnesesRef?.current
                && previousAnamnesesRef?.current !== JSON.stringify(values)) {
            previousAnamnesesRef.current = JSON.stringify(values);
            saveAnamnesis(values);
        }
    }

    //NOTE LM: componentWillUnmount and Litany of Horrors to Dan 'wrong mental model' Abramov.
    // Since react hooks try to imitate some form of fucked up immutable state, they work with closures such as that
    // you shouldn't use values "outside" of their arrow functions scope. You totally can - this is JS - but it will be
    // a stale value. Hooks - such as useEffect - inject particular values to be used when they update. This doesn't
    // work when you want to use CURRENT values to clean up BEFORE a component unloads. The reason for this is that to
    // "imitate" the former one-off behaviour of componentWillUnmount you have to leave the injection array empty.
    // The function below is the result of the retardation above.
    // We useLayoutEffect which is a version of useEffect that runs BEFORE render. Ergo during component unload it runs
    // before the new component renders. And we use ref-s to get current values instead of stale ones.
    useLayoutEffect(() => {
        return saveSnapshot
    }, [])

    //NOTE LM: beforeunload To save when the user closes the page
    useEffect(() => {
        window.addEventListener('beforeunload', saveSnapshot);
        return () => {
            window.removeEventListener('beforeunload', saveSnapshot);
        };
    }, [anamnesisForFormikRef, previousAnamnesesRef]);

    useEffect(() => {
        if (anamnesisDataLoadedSuccessfully && anamnesisForFormikRef && previousAnamnesesRef) {
            const anamneses = {...anamnesisData, patientId, gender: anamnesisData.gender ?? initialValues.gender};
            anamnesisForFormikRef.current.setValues(anamneses);
            previousAnamnesesRef.current = JSON.stringify(anamneses);
        }
    }, [anamnesisDataLoadedSuccessfully, anamnesisData, anamnesisForFormikRef, initialValues, patientId, previousAnamnesesRef]);

    useEffect(() => {
        if (isSavingItemSuccessful) {
            enqueueSnackbar(t('global.data.saved.success'), {variant: 'success'})
        }
        if (isSavingItemError) {
            enqueueSnackbar(t('global.data.saved.error'), {variant: 'error'})
        }
    }, [isSavingItemSuccessful, isSavingItemError]);

    const loadingSkeletton = (
        <div style={{padding: '10px'}}>
            <Skeleton variant="text" height={46} />
            <Skeleton variant="text" height={46} />
            <Skeleton variant="rect" height={274} />
            <Skeleton variant="text" height={46} />
            <Skeleton variant="rect" height={444} />
            <Skeleton variant="text" height={46} />
            <Skeleton variant="rect" height={97} />
            <Skeleton variant="text" height={46} />
            <Skeleton variant="rect" height={500} />
        </div>
    )

    return anamnesisDataIsLoading
            ? loadingSkeletton
            :
            <Formik
                    innerRef={form => (anamnesisForFormikRef.current = form)}
                    initialValues={initialValues}
                    onSubmit={(values, {setSubmitting}) => {
                        //TODO LM: This onSubmit is only used manually, remove it and use handleSave directly like we
                        // do everywhere else
                        setTimeout(() => {
                            handleSave(values);
                            setSubmitting(false);
                        }, 400);
                    }}
            >
                {({isSubmitting, ...formProps}) => <AnamnesisFormWrapper {...{
                    onSave: handleSave,
                    form: formProps,
                    patientData,
                    setUserDetails,
                    user,
                    readonly: anonymized,
                }}/>}
            </Formik>;
};

export default AnamnesisForm;
