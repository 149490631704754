import {downloadFileWithAuthentication} from 'scenes/FileHandler.action';
import {uploadProfileImage} from 'scenes/user-manager/UserManager.action';
import HMOApi, {UserDetails} from "store/api/HMOApi";

export default {
    mapStateToProps: (state) => {
        return {};
    },
    mapDispatchToProps: (dispatch) => {
        return {
            downloadFileWithAuthentication: url => dispatch(downloadFileWithAuthentication(url)),
            uploadProfileImage: (userDetailsBusinessId, file) =>
                dispatch(uploadProfileImage(userDetailsBusinessId, file)),
            refreshUserDetails: () => dispatch(HMOApi.util.invalidateTags([UserDetails]))
        };
    }
};
