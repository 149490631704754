import {updateDate, update} from 'utils/Utils';
import {mapPatientInputForBackend, emptyPatient} from 'domain/Patient.model';
import _ from 'lodash';
/**
 * hu.h4software.hmo.cases.model.CaseInput
 */
export function mapCaseInputForBackend(caseFromFrontend, caseOnBackend) {
    const patient = mapPatientInputForBackend(caseFromFrontend, caseOnBackend);
    return {
        businessId: caseFromFrontend.businessId,
        caseManagerId: update('caseManagerId', caseFromFrontend, caseOnBackend),
        clinicCenterBusinessId: update('clinicCenterBusinessId', caseFromFrontend, caseOnBackend),
        comment: update('comment', caseFromFrontend, caseOnBackend),
        reminderAction: update('reminderAction', caseFromFrontend, caseOnBackend),
        patientAppointmentComment: update('patientAppointmentComment', caseFromFrontend, caseOnBackend),
        diseaseDetails: update('diseaseDetails', caseFromFrontend, caseOnBackend),
        furtherTherapy: update('furtherTherapy', caseFromFrontend, caseOnBackend),
        additionalInformation: update('additionalInformation', caseFromFrontend, caseOnBackend),
        priority: update('priority', caseFromFrontend, caseOnBackend),
        status: update('status', caseFromFrontend, caseOnBackend),
        furtherDiagnostic: update('furtherDiagnostic', caseFromFrontend, caseOnBackend),
        whoAsks: update('whoAsks', caseFromFrontend, caseOnBackend),
        indication: update('indication', caseFromFrontend, caseOnBackend),
        insuranceCompanyId: update('insuranceCompanyId', caseFromFrontend, caseOnBackend),
        otherInsurance: update('otherInsurance', caseFromFrontend, caseOnBackend),
        insuranceCompanyRegionalOffice: update('insuranceCompanyRegionalOffice', caseFromFrontend, caseOnBackend),
        insuranceNumber: update('insuranceNumber', caseFromFrontend, caseOnBackend),
        costCoverageValue: update('costCoverageValue', caseFromFrontend, caseOnBackend),
        deviationComment: update('deviationComment', caseFromFrontend, caseOnBackend),
        deviationType: update('deviationType', caseFromFrontend, caseOnBackend),
        affected: update('affected', caseFromFrontend, caseOnBackend),
        examined: update('examined', caseFromFrontend, caseOnBackend),
        content: update('content', caseFromFrontend, caseOnBackend),
        primaryTumorLocalized: update('primaryTumorLocalized', caseFromFrontend, caseOnBackend),
        metastasisLocalized: update('metastasisLocalized', caseFromFrontend, caseOnBackend),
        reminderDate: updateDate('reminderDate', caseFromFrontend, caseOnBackend),
        patientAppointmentDate: updateDate('patientAppointmentDate', caseFromFrontend, caseOnBackend),
        secondOpinionUntil: updateDate('secondOpinionUntil', caseFromFrontend, caseOnBackend),
        primaryTumorDiagnosed: updateDate('primaryTumorDiagnosed', caseFromFrontend, caseOnBackend),
        metastasisDiagnosed: updateDate('metastasisDiagnosed', caseFromFrontend, caseOnBackend),
        cancellationComment: update('cancellationComment', caseFromFrontend, caseOnBackend),
        cancellationReason: update('cancellationReason', caseFromFrontend, caseOnBackend),
        cancellationDate: updateDate('cancellationDate', caseFromFrontend, caseOnBackend),
        subIndications: update('subIndications', caseFromFrontend, caseOnBackend),
        costCoverageTypes: update('costCoverageTypes', caseFromFrontend, caseOnBackend),
        involvedDoctors: update('involvedDoctors', caseFromFrontend, caseOnBackend),
        rating: update('rating', caseFromFrontend, caseOnBackend),
        milestones: update('milestones', caseFromFrontend, caseOnBackend), //[{id, date}]
        patient: _.isEmpty(patient) ? undefined : patient,
        satisfaction: update('satisfaction', caseFromFrontend, caseOnBackend),
        cdType: update('cdType', caseFromFrontend, caseOnBackend)
    }
}

export function mapNewCase(newCase) {
    return {
        ...newCase,
        patient: {
            ...newCase.patient,
            phoneNumbers: [''],
            emailAddresses: []
        },
        diagnosis: {},
        secondOpinionDetails: [],
        secondOpinion: {}
    }
}

export function emptyCase(){
    return {
        status: '',
        priority: '',
        patient: emptyPatient(),
        diagnosis: {},
        secondOpinionDetails: [],
        documents: [],
    }
}
