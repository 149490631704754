import React, {Component} from 'react';
import {
    MenuItem, withStyles
} from "@material-ui/core";
import _ from 'lodash';
import DefaultTextField from 'components/hmo-textfield/DefaultTextField';

export const styles = theme => ({
    root: {
        '& .MuiSelect-selectMenu': {
            whiteSpace: 'unset',
        },
    }
});

export class SidebarSelect extends Component {
    render() {
        const {
            value, onChange, name, disabled, inputLabel, style, warning, options, renderValue, labelGet, valueGet, classes,
            ...other
        } = this.props;

        return (
                <DefaultTextField
                    {...other}
                    select
                    classes={classes}
                    warning={warning}
                    disabled={disabled}
                    label={inputLabel}
                    SelectProps={{
                        value: value || '',
                        onChange: onChange,
                        name: name,
                        ...(renderValue && {renderValue})
                    }}
                >
                    {
                        (options || []).map(option =>
                            <MenuItem key={labelGet ? _.get(option, labelGet) : option}
                                      value={valueGet ? _.get(option, valueGet) : option}
                            >
                                {labelGet ? _.get(option, labelGet) : option}
                            </MenuItem>
                        )
                    }
                </DefaultTextField>
        )
    }
}

export default withStyles(styles)(SidebarSelect);
