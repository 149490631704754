import React, {Component} from 'react';
import {Typography} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import * as colors from "components/colors/Colors";
import moment from 'moment';
import _ from 'lodash';

const EMPTY_ARRAY = [];

export class UsedDocuments extends Component {
    render() {
        const {t: translate, downloadCaseFile} = this.props;
        let documents = _.orderBy((this.props.case.documents || EMPTY_ARRAY), ['documentData.fileName'], ['desc']);
        documents = this.props.case.documents.filter(d => d.documentData.type !== 'SECOND_OPINION');

        return (
            <Flex item container column padding={15}
                  style={{margin: 20, backgroundColor: colors.grey240, borderRadius: 4}}>
                <Flex item={'0 1 auto'} container alignItems={'center'} style={{
                    paddingBottom: 20,
                    borderBottom: `1px solid darkgrey`
                }}>
                    <Typography style={{
                        textTransform: 'uppercase',
                        fontSize: 15,
                        fontWeight: 'bold',
                        color: colors.htmlBlack
                    }}
                    >
                        {translate('global.documents')}
                    </Typography>
                    <div style={{
                        marginLeft: 10,
                        fontSize: 12,
                        minWidth: 21,
                        textAlign: 'center',
                        color: colors.htmlWhite,
                        padding: 3,
                        backgroundColor: colors.grey153,
                        borderRadius: '50%'
                    }}>
                        {documents.length}
                    </div>
                </Flex>

                <Flex item continer column padding={10} style={{backgroundColor: colors.iceberg}}>
                    <table style={{tableLayout: 'auto'}}>
                        <tbody>
                        <tr id={'header'}>
                            <th>
                                <Typography style={{
                                    textTransform: 'uppercase',
                                    color: colors.grey144,
                                    marginLeft: 10,
                                    fontSize: 12
                                }}>
                                    {translate('global.name')}
                                </Typography>
                            </th>
                            <th>
                                <Typography style={{textTransform: 'uppercase', color: colors.grey144, fontSize: 12}}>
                                    {translate('global.comment')}
                                </Typography>
                            </th>
                            <th>
                                <Typography style={{textTransform: 'uppercase', color: colors.grey144, fontSize: 12}}>
                                    {translate('global.date')}
                                </Typography>
                            </th>
                        </tr>
                        {
                            documents.sort((fileA, fileB) => fileA?.documentData?.fileName?.localeCompare(fileB?.documentData?.fileName)).map(extendedDocumentData => {
                                    const document = extendedDocumentData.documentData;
                                    return (
                                        <tr key={document.documentId}
                                            id={'document-tile' + document.documentId}
                                            style={{
                                                backgroundColor: 'white',
                                                height: 45,
                                                boxShadow: '0px 0px 8px #0000001A',
                                                borderRadius: 4
                                            }}
                                            onClick={event => {
                                                event.stopPropagation();
                                                downloadCaseFile(document);
                                            }}>
                                            <td>
                                                <Typography style={{
                                                    color: colors.htmlBlue,
                                                    wordBreak: 'break-all',
                                                    marginLeft: 10,
                                                    minWidth: 270
                                                }}>
                                                    {document.fileName}
                                                </Typography>
                                            </td>
                                            <td style={{width: '75%'}}>
                                                <Typography style={{
                                                    color: colors.htmlGrey,
                                                    wordBreak: 'break-all',
                                                    marginRight: 10
                                                }}>
                                                    {document.description || ''}
                                                </Typography>
                                            </td>
                                            <td style={{minWidth: 85}}>
                                                <Typography style={{textTransform: 'uppercase', color: colors.htmlGrey}}>
                                                    {moment(document.uploadTime).format('DD.MM.YYYY')}
                                                </Typography>
                                            </td>
                                        </tr>
                                    )
                                }
                            )
                        }
                        </tbody>
                    </table>
                </Flex>
            </Flex>
        );
    }
}

export default withTranslation()(UsedDocuments);
