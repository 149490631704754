import React, {Component} from 'react';
import {
    withStyles,
    Typography
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import Profile from 'components/profile/Profile';
import DocumentsSidebar from 'scenes/case/edit/component/document/DocumentsSidebar';

const styles = theme => ({});

export class MobileUserFileUpload extends Component {

    render() {
        const {classes, t: translate, indication} = this.props;

        return (
            <Flex item container direction={"column"} padding={10}>
                <Flex item container alignItems={'center'} justifyContent={'space-between'}>
                    <Flex item container direction={'column'} justifyContent={'flex-start'}>
                        <Typography variant={'h6'}>{translate("global.second-opinion")}</Typography>
                        <Typography>{indication}</Typography>
                    </Flex>
                    <Profile classes={{avatar: classes.avatar}} positionStyle={{
                        top: 50,
                        right: 32,
                        bottom: 'unset',
                        left: 'unset',
                    }}/>
                </Flex>

                <Flex item container>
                    <DocumentsSidebar/>
                </Flex>
            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(MobileUserFileUpload));
