import React from "react";
import { Slider } from "@material-ui/core";
import {useTranslation} from "react-i18next";

const DiscomfortSlider = ({ form, name, value, props }) => {
    const handleChangeSlider = (e, v) => {
        form.setFieldValue(name, v);
    };
    const {t} = useTranslation();
    return (
        <>
            <div
                style={{
                    display: "flex",
                    marginLeft: "5px",
                    marginRight: "5px",
                    marginTop: "40px",
                    flexDirection: "column",
                }}
            >
                <Slider
                    value={value || 50}
                    valueLabelDisplay="on"
                    {...props}
                    onChange={handleChangeSlider}
                />

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <div>{t('global.worst-ever')}</div>
                    <div>{t('global.best-ever')}</div>
                </div>
            </div>
        </>
    );
};

export default DiscomfortSlider;
