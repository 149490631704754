import {
    upsertCareProvider, listCareProviders, getCareProvider, deleteCareProvider, fetchContacts
} from './CareProvider.action'
import {getProgramTemplates} from 'scenes/managed-care/ManagedCare.action';
import {fetchCareProviderContacts, getAlreadyInvitedEmails} from 'scenes/user-manager/UserManager.action';

export default {
    mapStateToProps: (state) => {
        return {
            user: state.mainReducer.user,
            careProviders: state.careProviderReducer.careProviders
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            upsertCareProvider: careProvider => dispatch(upsertCareProvider(careProvider)),
            listCareProviders: () => dispatch(listCareProviders()),
            getCareProvider: id => dispatch(getCareProvider(id)),
            deleteCareProvider: id => dispatch(deleteCareProvider(id)),
            fetchContacts: id => dispatch(fetchContacts(id)),
            getProgramTemplates: () => dispatch(getProgramTemplates()),
            fetchCareProviderContacts: careProviderId => dispatch(fetchCareProviderContacts(careProviderId)),
            getAlreadyInvitedEmails: props => dispatch(getAlreadyInvitedEmails(props))
        };
    }
};
