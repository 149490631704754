import Flex from "components/grid/Flex";
import { Button } from "@material-ui/core";
import { useState } from "react";
import { FiltersByCategoryRenderer } from "./filter-components/FiltersByCategoryRenderer";
import { AddFilterModal } from "./filter-components/AddFilterModal";

export const FilterBuilder = ({translate, formTree}) => {
    const [addCriteriaModalOpen, setAddCriteriaModalOpen] = useState(false);
    return (
        <Flex item container style={{ paddingLeft: 34 }}>
            <div style={{ width: "100%" }}>
                <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                    <FiltersByCategoryRenderer
                      formTree={formTree}
                    />
                </div>
                <div>
                    <Button onClick={() => setAddCriteriaModalOpen(true)} style={{ textTransform: "none" }}>
                        +&nbsp;{translate("cohort.add-criteria")}
                    </Button>
                    <AddFilterModal formTree={formTree} isOpen={addCriteriaModalOpen} onClose={() => setAddCriteriaModalOpen(false)} />
                </div>
            </div>
        </Flex>
    );
};
