import {getProgramTemplates} from 'scenes/managed-care/ManagedCare.action';
import {createUserDetails} from 'scenes/user-manager/UserManager.action';
import {listCareProviders} from 'scenes/care-provider/CareProvider.action';

export default {
    mapStateToProps: (state) => {
        return {
            configuration: state.caseReducer.configuration,
            clinicCenters: state.clinicsReducer.clinicCentersWithClinicData,
            user: state.mainReducer.user,
            programTemplates: state.managedCareReducer.programTemplates,
            careProviders: state.careProviderReducer.careProviders,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            getProgramTemplates: () => dispatch(getProgramTemplates()),
            createUserDetails: (props) => dispatch(createUserDetails(props)),
            listCareProviders: () => dispatch(listCareProviders()),
        };
    }
};
