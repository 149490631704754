import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import configs from 'configs/Configs';

import translationEN from './locales/en/translation.json';
import translationDE from './locales/de/translation.json';

const resources = {
    en: {
        translation: translationEN
    },
    de: {
        translation: translationDE
    }
};
const selectedLanguage = window.localStorage.getItem("selectedLanguage");
i18n
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: configs.getAllowedLanguages().includes(selectedLanguage) ? selectedLanguage : 'de',
        fallbackLng: 'en',
        resources,
        saveMissing: true, // send not translated keys to endpoint
        whitelist: configs.getAllowedLanguages(),
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        preload: configs.getAllowedLanguages()
    });

export default i18n;
