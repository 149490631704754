import React from 'react';
import {InputLabel, MenuItem} from "@material-ui/core";
import {Field} from "formik";
import {useTranslation} from "react-i18next";
import FieldSelect from './FieldSelect';

const StateInput = ({name, text = '', form, ...props}) => {
    const {t} = useTranslation();

    return (
            <>
                <InputLabel id={`${name}Label`}>{text}</InputLabel>
                <Field
                        name={name}
                        fullWidth
                        labelId={`${name}Label`}
                        displayEmpty
                        component={FieldSelect}
                        {...props}
                >
                    <MenuItem value={"TREATED"}>{t('global.treated')}</MenuItem>
                    <MenuItem value={"UNTREATED"}>{t('global.untreated')}</MenuItem>
                </Field>
            </>
    );
};

export default StateInput;
