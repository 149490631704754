import { Walkthrough } from "../Walkthrough"
import { mainWalkthroughSteps } from "./mainWalkthroughStepsConfig";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SET_APP_INTRO_WALKTHROUGH_COMPLETED, TOGGLE_SHOW_APP_WALKTHROUGH } from 'scenes/Main.action.types';
import { updateUserWalkthroughs } from "../../user-manager/UserManager.action";

export const MainWalkthrough = () => {
    const dispatch = useDispatch();
    const walkthroughsCompleted = useSelector((state) => state.mainReducer.walkthroughsCompleted);
    const { t } = useTranslation();
    const translateStep = (stepName, translationPart) => {
        return t(`walkthrough.main-walkthrough-steps-content.${stepName}.${translationPart}`);
    }

    const toggleWalkthrough = () => {
        dispatch({type: TOGGLE_SHOW_APP_WALKTHROUGH})
    }

    // for optimization we can omit request if user already completed walkthrough
    const onCompleteCallback = () => {
        dispatch({type: SET_APP_INTRO_WALKTHROUGH_COMPLETED, payload: true})
        dispatch(updateUserWalkthroughs({...walkthroughsCompleted, appIntroWalkthrough: true}));
    }

    const translatedSteps = mainWalkthroughSteps.map(step => {

    return {
        ...step,
        title: translateStep(step.title, "title"),
        content: translateStep(step.title, "content"),
        questionBeforeStart: step.type === 'start' ? translateStep(step.title, "question-before-start") : undefined,
    }
});
    return <Walkthrough steps={translatedSteps}  toggleWalkthrough={toggleWalkthrough} onCompleteCallback={onCompleteCallback} />
}
