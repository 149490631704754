import React, {Component} from 'react';
import {Dialog, Typography} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import AccountCircleOutlined from '@material-ui/icons/AccountCircleOutlined';
import DefaultTextField from 'components/hmo-textfield/DefaultTextField';
import HmoFilledButton from 'components/button/HmoFilledButton';
import HmoBorderedButton from 'components/button/HmoBorderedButton';

export class NewCenterDialog extends Component {

    state = {
        name: '',
    }

    addClinicCenter = () => {
        this.props.addClinicCenter(this.state.name);
    };

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    render() {
        const {t: translate, toggleNewCenterDialog} = this.props;
        const {handleChange, addClinicCenter} = this;
        const {name} = this.state;
        return (
                <Dialog open={true}>
                    <Flex item container column padding={15}>
                        <Flex item container justifyContent={'space-between'} alignItems={'center'}
                              style={{marginRight: 7}}>
                            <Typography variant={"h5"}>{translate("clinic.new-clinic-center")}</Typography>
                            <AccountCircleOutlined/>
                        </Flex>
                        <DefaultTextField value={name || ''}
                                          label={translate("global.name")}
                                          error={!name}
                                          name={'name'}
                                          onChange={handleChange}/>

                        <Flex item container style={{marginTop: 10, marginRight: 7}}>
                            <HmoBorderedButton onClick={toggleNewCenterDialog} size={'small'}
                                               style={{width: '50%', marginRight: 10}}>
                                {translate("global.cancel")}
                            </HmoBorderedButton>
                            <HmoFilledButton onClick={addClinicCenter} disabled={!name} style={{width: '50%'}}>
                                {translate("clinic.add-clinic-center")}
                            </HmoFilledButton>
                        </Flex>
                    </Flex>
                </Dialog>
        );
    }
}

export default withTranslation()(NewCenterDialog);
