import React, {useEffect, useState} from 'react';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import _ from "lodash";
import moment from 'moment';
import {useTranslation} from "react-i18next";

const CustomDatePicker = ({form, value, name, component, as, type, format = "DD.MM.YYYY", ...props}) => {
    const [selectedDate, setSelectedDate] = useState('');
    const [t] = useTranslation();
    const handleDateChange = (date) => {
        setSelectedDate(date);

        if (props.onChange != null) {
            props.onChange( {target: {value: date}} );
        } else {
            throw new Error('onChange is not defined in CustomDatePicker');
        }
    };


    useEffect(() => {
        if (value != null && !_.isEmpty(value) && value !== 'UNDEFINED') {
            setSelectedDate(value);
        }
    }, [value]);


    return (
        <>
            <MuiPickersUtilsProvider key={`mui-picker-${name}`} utils={MomentUtils}>
                <KeyboardDatePicker
                    {...props}
                    disableFuture
                    format={format}
                    placeholder={t('global.please-select')}
                    value={selectedDate ? moment(selectedDate) : null}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}

                />
            </MuiPickersUtilsProvider>
        </>
    );
};

export default CustomDatePicker;
