import {makeStyles} from "@material-ui/core/styles";
import Flex from 'components/grid/Flex';
import {DETAIL_TYPE_FIELDS} from './DetailsType.model';
import {htmlBlue, htmlRed} from 'components/colors/Colors';
import {downloadAndReturnCaseFile} from 'scenes/FileHandler.action';
import {useDispatch} from "react-redux";
import {Fragment, useState} from 'react';
import {IconButton, Typography} from "@material-ui/core";
import {HighlightOff as DeleteIcon,} from '@material-ui/icons';
import {Markup} from 'interweave';
import moment from 'moment';
import utils from 'utils/Utils';
import PdfViewer from 'components/pdf-viewer/PdfViewer';

const useStyles = makeStyles((theme) => ({
    cell: {
        padding: 2,
        alignItems: 'flex-start'
    },
    document: {
        textDecoration: 'underline',
        color: htmlBlue,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    cellText: {
        fontWeight: 'bold'
    },
    markup: {
        '& p': {
            marginBlockStart: 'unset',
            marginBlockEnd: 'unset',
        }
    },
    rowContainer: {
        backgroundColor: 'white',
        margin: 2,
        padding: 5,
        boxShadow: `0px 0px 8px #0000001A`,
        borderRadius: 4
    }
}));

const SecondOpinionDetailsRow = ({
                                     secondOpinionDetail,
                                     documents,
                                     openDeleteDialogFor,
                                     openEditDialogFor,
                                     disabled
                                 }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [file, setFile] = useState('');
    const [isPdfOpen, setIsPdfOpen] = useState(false);
    const downloadAndShowFile = document => event => {
        event.stopPropagation();
        if (file) {
            setIsPdfOpen(true);
        } else {
            dispatch(downloadAndReturnCaseFile(document)).then(caseFile => {
                setIsPdfOpen(true);
                setFile(caseFile);
            });
        }
    };
    const toggleIsPdfOpen = () => {
        setIsPdfOpen(!isPdfOpen);
    }
    return <Flex item container className={classes.rowContainer}>
        <Flex item={`0 0 30px`} container className={classes.cell}>
            <IconButton style={{padding: 0}} onClick={disabled ? () => {
            } : openDeleteDialogFor(secondOpinionDetail)}
                        disabled={disabled}>
                <DeleteIcon style={{color: htmlRed, fontSize: 20}}/>
            </IconButton>
        </Flex>

        {
            DETAIL_TYPE_FIELDS[secondOpinionDetail.detailType].includes('from') &&
            <Flex item={`0 0 100px`} container className={classes.cell}
                  onClick={disabled ? () => {
                  } : openEditDialogFor(secondOpinionDetail)}>
                <Typography className={classes.cellText}>
                    {secondOpinionDetail.from ? moment(secondOpinionDetail.from).format(utils.VIEW_DATE_FORMAT) : ''}
                </Typography>
            </Flex>
        }

        {
            DETAIL_TYPE_FIELDS[secondOpinionDetail.detailType].includes('to') &&
            <Flex item={`0 0 100px`} container className={classes.cell}
                  onClick={disabled ? () => {
                  } : openEditDialogFor(secondOpinionDetail)}>
                <Typography className={classes.cellText}>
                    {secondOpinionDetail.to ? moment(secondOpinionDetail.to).format(utils.VIEW_DATE_FORMAT) : ''}
                </Typography>
            </Flex>
        }

        {
            DETAIL_TYPE_FIELDS[secondOpinionDetail.detailType].includes('code') &&
            <Flex item={`0 0 120px`} container className={classes.cell}
                  onClick={disabled ? () => {
                  } : openEditDialogFor(secondOpinionDetail)}>
                <Typography className={classes.cellText}>
                    {secondOpinionDetail.code}
                </Typography>
            </Flex>
        }

        {
            DETAIL_TYPE_FIELDS[secondOpinionDetail.detailType].includes('freeText') &&
            <Flex item={`0 0 10%`} container className={classes.cell}
                  onClick={disabled ? () => {
                  } : openEditDialogFor(secondOpinionDetail)}>
                <Typography className={classes.cellText}>
                    {secondOpinionDetail.freeText}
                </Typography>
            </Flex>
        }

        <Flex item container className={classes.cell}
              onClick={disabled ? () => {
              } : openEditDialogFor(secondOpinionDetail)}>
            <Flex item container className={classes.markup}>
                <Markup content={secondOpinionDetail.content}/>
            </Flex>
        </Flex>

        <Flex item={`0 0 200px`} container direction={'column'} className={classes.cell}>
            {
                secondOpinionDetail.assignedDocumentIds.map(id => {
                            const assignedDocument = documents
                                    .find(document => document.documentData.documentId === id);
                            return assignedDocument
                                    ?
                                    <Typography className={classes.document} key={id}
                                                onClick={downloadAndShowFile(assignedDocument.documentData)}>
                                        {assignedDocument.documentData.fileName}
                                    </Typography>
                                    :
                                    <Fragment/>
                        }
                )
            }
        </Flex>

        {
            file && isPdfOpen &&
            <PdfViewer fileBlobUrl={file} onClose={toggleIsPdfOpen}/>
        }
    </Flex>
};

export default SecondOpinionDetailsRow;
