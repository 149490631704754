export const transformRuleToAndGroup = (rule) => {
    return {
        type: "group",
        properties: {
            ...rule.properties,
            conjunction: "and",
            value: [rule],
        },
    };
};

export const addRuleToAndGroup = (group, rule) => {
    return {
        ...group,
        properties: {
            ...group.properties,
            value: [...group.properties.value, rule],
        },
    };
};

export const removeRuleFromAndGroup = (group, ruleIndex) => {
    return {
        ...group,
        properties: {
            ...group.properties,
            value: group.properties.value.filter((_, index) => index !== ruleIndex),
        },
    };
};

export const transformGroupToRule = (ruleGroup) => {
    return ruleGroup.properties.value[0];
}
