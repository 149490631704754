import { Box, FormControlLabel, Grid, MenuItem, Radio, Typography } from "@material-ui/core";
import { MedicineApplicationTimeSpanType } from "scenes/patient/medication-plan/medicationDurationPlan.enum";
import { Field } from "formik";
import { months } from "domain/Months.model";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import EpaTextField from "components/hmo-textfield/EpaTextField";

const DateParts = ({ form, t, disabled, yearName, monthName, dayName }) => {
    return (
        <>
            <Box display={'flex'} flexDirection={"row"}>
                <Field
                    name={yearName}
                    onChange={form.handleChange}
                    disabled={disabled}
                    placeholder="Jahr*"
                    as={EpaTextField}
                    style={{ maxWidth: "65px", marginRight: "2px" }}
                />
                <EpaTextField
                    style={{ marginRight: "2px" }}
                    select
                    disabled={disabled}
                    name={monthName}
                    value={form.values[monthName]}
                    onChange={form.handleChange}
                    SelectProps={{
                        displayEmpty: true,
                        renderValue: (value) => {
                            return value ? (
                                t(`global.monthShort.${months[value - 1]}`)
                            ) : (
                                <span style={{ color: "rgba(0, 0, 0, 0.38)" }}> {t("global.month-label")} </span>
                            );
                        },
                    }}
                >
                    {months.map((month, index) => (
                        <MenuItem key={index} value={index + 1}>
                            {month}
                        </MenuItem>
                    ))}
                </EpaTextField>
                <Field
                    name={dayName}
                    onChange={form.handleChange}
                    disabled={disabled}
                    placeholder="Tag"
                    as={EpaTextField}
                    style={{ maxWidth: "50px" }}
                />
            </Box>
        </>
    );
};

const MedicationTimeFrame = ({ lang, form }) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const handleContinuousRadioClick = () => {
        form.setFieldValue("fromDateYear", "");
        form.setFieldValue("fromDateMonth", "");
        form.setFieldValue("fromDateDay", "");
        form.setFieldValue("toDateYear", "");
        form.setFieldValue("toDateMonth", "");
        form.setFieldValue("toDateDay", "");
        form.setFieldValue("medicineApplicationTimeSpanType", MedicineApplicationTimeSpanType.Continuous);
    };

    const handleTemporaryRadioClick = () => {
        form.setFieldValue("fromDateYear", "");
        form.setFieldValue("fromDateMonth", "");
        form.setFieldValue("fromDateDay", "");
        form.setFieldValue("medicineApplicationTimeSpanType", MedicineApplicationTimeSpanType.Temporary);
    };
    const continuousError = !!form.errors.fromDateYear || !!form.errors.fromDateDay;
    const temporaryError =
        !!form.errors.fromDateYear || !!form.errors.fromDateDay || !!form.errors.toDateYear || !!form.errors.toDateDay;
    const isContinuous = form.values.medicineApplicationTimeSpanType === MedicineApplicationTimeSpanType.Continuous;
    const isTemporary = form.values.medicineApplicationTimeSpanType === MedicineApplicationTimeSpanType.Temporary;

    return (
        <>
            <Grid item xs={12}>
                <Typography color="primary" component="h2" variant="h4">
                    {lang("whatPeriod")}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Box display={"flex"} flexDirection={"column"}>
                    <Box
                        id={"continuesTimeFrame"}
                        display={"flex"}
                        walkthrough-element={"medicine-timeframe"}
                        flexDirection={"row"}
                        alignItems={"flex-end"}
                        marginBottom={"6px"}
                    >
                        <FormControlLabel
                            checked={isContinuous}
                            value={form.values.medicineApplicationTimeSpanType}
                            style={{ minWidth: "114px", marginRight: "5px" }}
                            onChange={handleContinuousRadioClick}
                            control={<Radio color="primary" />}
                            label={lang("continuous")}
                        />
                        <DateParts
                            {...{
                                form,
                                t,
                                disabled: !isContinuous,
                                yearName: "fromDateYear",
                                monthName: "fromDateMonth",
                                dayName: "fromDateDay",
                            }}
                        />
                    </Box>
                    <Box
                        id={"temporaryTimeFrame"}
                        display={"flex"}
                        walkthrough-element={"medicine-application"}
                        flexDirection={"row"}
                        alignItems={"flex-end"}
                    >
                        <FormControlLabel
                            style={{ minWidth: "114px", marginRight: "5px" }}
                            checked={isTemporary}
                            value={form.values.medicineApplicationTimeSpanType}
                            onChange={handleTemporaryRadioClick}
                            control={<Radio color="primary" />}
                            label={lang("temporary")}
                        />

                        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                            <Box>
                                <Box
                                    style={{
                                        color: continuousError ? theme.palette.error.main : "rgba(0, 0, 0, 0.54)",
                                        fontSize: "12px",
                                    }}
                                >
                                    {lang("dateFrom")}
                                </Box>
                                <DateParts
                                    {...{
                                        form,
                                        t,
                                        disabled: !isTemporary,
                                        yearName: "fromDateYear",
                                        monthName: "fromDateMonth",
                                        dayName: "fromDateDay",
                                    }}
                                />
                            </Box>
                            <Box
                                style={{
                                    marginTop: "10px",
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                }}
                            >
                                -
                            </Box>
                            <Box>
                                <Box
                                    style={{
                                        color: temporaryError ? theme.palette.error.main : "rgba(0, 0, 0, 0.54)",
                                        fontSize: "12px",
                                    }}
                                >
                                    {lang("dateTo")}
                                </Box>

                                <DateParts
                                    {...{
                                        form,
                                        t,
                                        disabled: !isTemporary,
                                        yearName: "toDateYear",
                                        monthName: "toDateMonth",
                                        dayName: "toDateDay",
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </>
    );
};

export default MedicationTimeFrame;
