import { makeStyles } from "@material-ui/core/styles";
import SyncIcon from "@mui/icons-material/Sync";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "18px",
        position: "relative",
    },
    item: {
        transition: "all .5s",
        borderRadius: "10px",
        backgroundColor: "white",
        border: "1px solid #E2E1E4",
        padding: "12px 14px",
        filter: ({ fetching }) => (fetching ? "opacity(.38)" : "none"),
        pointerEvents: ({ fetching }) => (fetching ? "none" : "auto"),
        userSelect: ({ fetching }) => (fetching ? "none" : "auto"),
    },
    infoHeader: {
        color: "#030303",
        fontSize: "18px",
        fontWeight: "600",
        marginBottom: "8px",
        alignItems: "center",
        display: "flex",
        justifyContent: 'space-between'
    },
    syncIcon: {
        transition: 'opacity .5s ease-in-out !important',
        animation: "$rotate 1.4s linear infinite",
        marginLeft: 'auto',
        marginRight: '4px',
        opacity: ({ fetching }) => (fetching ? "1" : "0"),
    },
    "@keyframes rotate": {
        from: {
            transform: "rotate(360deg)",
        },
        to: {
            transform: "rotate(0deg)",
        },
    },
}));

const PatientSidebarItem = ({ children, title, style, fetching, titleElements, infoHeaderStyle, ...rest }) => {
    const classes = useStyles({ fetching });

    return (
        <div {...rest} className={classes.root} style={style}>
            <div className={classes.infoHeader} style={infoHeaderStyle}>
                {title}
                <SyncIcon fontSize="small" color="info" className={[classes.syncIcon, classes.syncIconActive].join(' ')} />
                {titleElements}
            </div>
            <div className={classes.item}>{children}</div>
        </div>
    );
};

export default PatientSidebarItem;
