import {Grid, Typography} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {DropZoneAction} from "scenes/patient/patient-sidebar/UploadFiles/DropZone.reducer";

const UploadDocumentPrompt = ({questionId}) => {
    const {t} = useTranslation();
    const dropZoneRef = useSelector((state) => state.DropZoneReducer.dropZoneRef);
    const filesData = useSelector((state) => state.DropZoneReducer.filesData);
    const dispatch = useDispatch();
    const openFileDialog = () => {
        dispatch({type: DropZoneAction.SetAnamnesisQuestionId, payload: questionId});
        dropZoneRef?.open();
    }
    const numberOfQuestionRelatedDocuments = filesData?.filter(doc => doc.questionId === questionId)?.length
    return (
            <Grid container item xs={12} style={{padding: '10px 10px'}}>
                <Typography style={{fontSize: 13, color: 'grey'}}>
                    {t('anamnesis.doctors-letter')}: &nbsp;
                </Typography>
                <Typography style={{fontSize: 13, color: 'blue', margin: '0px 10px', cursor: 'pointer'}} onClick={openFileDialog}>
                    {t('anamnesis.upload-letter')}
                </Typography>
                {
                        !!numberOfQuestionRelatedDocuments &&
                        <Typography style={{fontSize: 13, color: 'darkgrey', fontStyle: 'italic'}}>
                            {numberOfQuestionRelatedDocuments}&nbsp;{t('anamnesis.letter-uploaded')}
                        </Typography>
                }
            </Grid>
    );
};

export default UploadDocumentPrompt;
