import React from 'react';
import {TextField} from '@material-ui/core';
import {styled} from '@material-ui/core';

export default styled((props) => {
    const {InputLabelProps, size, variant, onEnterKey, onKeyPress, standAlone, ...other} = props;

    const handleKeyPress = (onEnterKey, onKeyPress) => event => {
        const isEnterPressed = (event.key && event.key === 'Enter') || (event.keyCode && event.keyCode === 13);
        const isShiftPressed = event.shiftKey;
        if (isEnterPressed && !isShiftPressed){
            onEnterKey && onEnterKey(event);
        } else {
            onKeyPress && onKeyPress(event);
        }
    };

    return <TextField {...other}
                      onKeyPress={handleKeyPress(onEnterKey, onKeyPress)}
                      size={'small'}
                      InputLabelProps={{shrink: true, ...InputLabelProps}}
    />
})(({ theme, standAlone }) => ({
    marginRight: standAlone ? 0 : 7,
    marginTop: standAlone ? 0 : 12,
    '& .MuiSelect-select': {
        paddingTop: 9.5,
        paddingBottom: 9.5
    },
    '& .MuiListItemText-root': {
        margin: 0
    }
}));
