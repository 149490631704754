import React, {Component} from 'react';
import {Typography, withStyles, Accordion, AccordionSummary, AccordionDetails} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {concrete, htmlGrey, htmlLightGrey, persianGreen, grey153} from 'components/colors/Colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CaseRow from './component/CaseRow';
import CaseRowHeaders from './component/CaseRowHeaders';
import Filters from './component/Filters';
import _ from 'lodash';
import Title from './component/Title';
import History from './component/History';
import {NEW_FOR_CLINIC, ACCEPTED_FOR_CLINIC} from './component/CaseRowHeaders';
import {REQUEST_EXPERT_TO_TAKE_OVER_CASE} from 'domain/WorkflowStatus';
import {withTranslation} from 'react-i18next';
import CounterCircle from 'components/counter/CounterCircle';
import Sidebar from 'components/sidebar/Sidebar';

const styles = theme => ({
    expansionGroupStatusTitle: {
        fontWeight: 'bold',
        fontSize: 18,
        color: htmlGrey
    },
    expansionStatusTitle: {
        color: persianGreen
    },
    expansionStatusSummary: {
        backgroundColor: concrete,
    },
    expansionStatusSummaryContent: {
        '&.Mui-expanded': {
            borderBottom: `1px solid ${htmlLightGrey}`
        }
    },
    expansionPanelDetails: {
        backgroundColor: concrete,
        padding: 5,
        display: 'flex',
        flexDirection: 'column'
    },
    expansionPanelStatusDetails: {
        backgroundColor: concrete,
        padding: 'unset',
        paddingLeft: 24,
        display: 'flex',
        flexDirection: 'column'
    },
    expansionPanelRoot: {
        boxShadow: 'unset',
        border: 'unset',
        '&.Mui-expanded': {
            margin: 'unset',
        },
        '&.Mui-disabled': {
            opacity: 0.5,
            backgroundColor: 'unset'
        },
        '&:before': {
            backgroundColor: 'unset'
        }
    },
    accordionSummary: {
        flexDirection: 'row-reverse',
        padding: 0,
    },
    expandIcon: {
        padding: 0,
        margin: 0
    }
});

const StatusPanel = (props) => {
    const {
        classes, translate, specialClinicStatus, filteredCases, handleDoubleClick, handleClick, toggleTagSelection,
        selectedTags, count, acceptCase, sortBy, byStatusProperty, statusSortMap
    } = props;
    return (
        <Accordion
            disabled={count < 1}
            defaultExpanded={true}
            classes={{root: classes.expansionPanelRoot}}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                style={{
                    height: count > 0 ? 35 : 25,
                    minHeight: count > 0 ? 35 : 25
                }}
                className={classes.accordionSummary}
                classes={{
                    expandIcon: classes.expandIcon,
                    root: classes.expansionStatusSummary,
                    content: classes.expansionStatusSummaryContent,
                }}
            >
                <Typography className={classes.expansionStatusTitle}>
                    {translate(`case.status.${specialClinicStatus}`)}
                </Typography>
                {
                    count > 0 &&
                    <CounterCircle {...{count, color: grey153}}/>
                }
            </AccordionSummary>
            <AccordionDetails className={classes.expansionPanelStatusDetails}>
                {
                    _.isEmpty(filteredCases) &&
                    <Typography style={{
                        fontSize: 21,
                        opacity: 0.7,
                        margin: 10,
                        lineHeight: '14px',
                        textAlign: 'center',
                        whiteSpace: 'pre-line'
                    }}>{specialClinicStatus === ACCEPTED_FOR_CLINIC
                            ? translate('global.no-patients-yet-clinic-1')
                            : translate('global.no-patients-yet-clinic-2')
                    }</Typography>
                }
                {
                    !_.isEmpty(filteredCases) &&
                    <table style={{tableLayout: 'auto'}}>
                        <tbody>
                        <CaseRowHeaders status={specialClinicStatus}
                                        sortBy={sortBy} {...{...(statusSortMap[specialClinicStatus] || {})}}/>
                        {
                            filteredCases
                                    .sort(byStatusProperty(specialClinicStatus))
                                    .map(caseInArray => (
                                            <CaseRow
                                                    key={caseInArray.businessId}
                                                    {...{
                                                        handleDoubleClick,
                                                        handleClick,
                                                        case: caseInArray,
                                                        toggleTagSelection,
                                                        selectedTags,
                                                        specialClinicStatus,
                                                        onAction: acceptCase
                                                    }}/>
                                    ))
                        }
                        </tbody>
                    </table>
                }
            </AccordionDetails>
        </Accordion>
    );
};

export class CaseListForClinic extends Component {
    render() {
        const {
            classes, t: translate, user, searchText, selectedTags, tagSuggestions, caseManagers, tagFilterRelation,
            filteredCases, isFilterActive, isHistoryActive, handleDoubleClick, handleClick, handleSearchTextChange,
            statuses, resetSearchText, toggleTagSelection, handleTagFilterRelationChange, resetSelectedTags,
            togglePanel, setHistory, notCancelledCases, acceptCase, sortBy, byStatusProperty, statusSortMap
        } = this.props;

        const cases = isFilterActive ? filteredCases : notCancelledCases;
        const newCases = cases.filter(c => c.status === REQUEST_EXPERT_TO_TAKE_OVER_CASE);
        const acceptedCases = cases.filter(c => c.status !== REQUEST_EXPERT_TO_TAKE_OVER_CASE)

        return (
            <>
                <Sidebar/>
                <Flex container item column
                      style={{padding: 60, paddingBottom: 0}}
                >
                    <Title {...{user}}/>
                    <Filters {...{
                        user,
                        searchText,
                        tagFilterRelation,
                        tagSuggestions,
                        selectedTags,
                        isHistoryActive,
                        setHistory,
                        handleSearchTextChange,
                        resetSearchText,
                        resetSelectedTags,
                        toggleTagSelection,
                        handleTagFilterRelationChange,
                    }}/>
                    <Flex item children style={{marginTop: 10}}>
                        {
                            isFilterActive && _.isEmpty(filteredCases) &&
                            <Typography variant={'h5'} style={{
                                color: htmlLightGrey,
                                textAlign: 'center'
                            }}>
                                {translate('case.empty-list')}
                            </Typography>
                        }
                        {
                            isHistoryActive
                                ? <History
                                    {...{
                                        filteredCases, selectedTags, handleDoubleClick, handleClick,
                                        toggleTagSelection, caseManagers, sortBy, statusSortMap
                                    }}
                                />
                                : <>
                                    <StatusPanel
                                        key={NEW_FOR_CLINIC}
                                        {...{
                                            specialClinicStatus: NEW_FOR_CLINIC,
                                            classes,
                                            translate,
                                            filteredCases: newCases,
                                            count: newCases.length,
                                            statuses,
                                            isFilterActive,
                                            selectedTags,
                                            handleDoubleClick,
                                            handleClick,
                                            togglePanel,
                                            toggleTagSelection,
                                            acceptCase,
                                            sortBy,
                                            byStatusProperty,
                                            statusSortMap
                                        }} />
                                    <StatusPanel
                                        key={ACCEPTED_FOR_CLINIC}
                                        {...{
                                            specialClinicStatus: ACCEPTED_FOR_CLINIC,
                                            classes,
                                            translate,
                                            filteredCases: acceptedCases,
                                            count: acceptedCases.length,
                                            statuses,
                                            isFilterActive,
                                            selectedTags,
                                            handleDoubleClick,
                                            handleClick,
                                            togglePanel,
                                            toggleTagSelection,
                                            sortBy,
                                            byStatusProperty,
                                            statusSortMap,
                                            onAction: () => {
                                            }
                                        }} />
                                </>
                        }

                    </Flex>

                </Flex>
            </>
        );
    }
}

export default withStyles(styles)(withTranslation()(CaseListForClinic));
