import React, {Component} from 'react';
import {Tooltip, withStyles} from "@material-ui/core";
import {pictonBlue, htmlWhite, htmlGrey} from 'components/colors/Colors';
import {withTranslation} from 'react-i18next';

const styles = theme => ({
    unSelected: {
        fontSize: 13,
        margin: 2,
        borderRadius: 7,
        color: htmlGrey,
        height: 25,
        textTransform: 'capitalize',
        padding: 3,
        whiteSpace: 'nowrap',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    selected: {
        fontSize: 13,
        margin: 2,
        borderRadius: 7,
        color: htmlWhite,
        height: 25,
        fontWeight: 'bold',
        textTransform: 'capitalize',
        display: 'flex',
        alignItems: 'center',
        padding: 3,
        '&:hover': {
            cursor: 'pointer'
        }
    },
});

export class Tag extends Component {
    handleTagSelection = event => {
      event.preventDefault();
      event.stopPropagation();
      if(!this.props.isEditDisabled) {
          if(this.props.toggleSelection) this.props.toggleSelection(this.props.tag);
      }
    };

    render() {
        const {
            classes, tag, selected, selectedColor, selectedBackgroundColor, unSelectedColor, noBorder,
            unSelectedBackgroundColor, t: translate, doTranslate, style, tooltip, tooltipProps
        } = this.props;
        const colorSelected = selectedColor ? selectedColor : htmlWhite;
        const backgroundColorSelected = selectedBackgroundColor ? selectedBackgroundColor : pictonBlue;
        const backgroundColorUnselected = unSelectedBackgroundColor ? unSelectedBackgroundColor : 'white';
        const colorUnSelected = unSelectedColor ? unSelectedColor : htmlGrey;
        const label = typeof tag === 'string' ? tag : tag?.name;
        return (tag
                ?  <Tooltip title={tooltip ?? ""} placement='top' arrow {...tooltipProps}>

                 <span className={selected ? classes.selected : classes.unSelected}
                  style={{
                      border: noBorder ? 'none' : `1px dashed ${unSelectedColor ? unSelectedColor : pictonBlue}`,
                      color: selected ? colorSelected : colorUnSelected,
                      backgroundColor: selected ? backgroundColorSelected : backgroundColorUnselected,
                      ...style
                  }}
                  onClick={this.handleTagSelection}>
                    {doTranslate ? translate(`global.${label}`) : label}
                </span>
                </Tooltip>
                : <span></span>
        )
    }
}

export default withStyles(styles)(withTranslation()(Tag));
