import * as actionTypes from './CareProvider.action.types';
import _ from 'lodash';

const initialState = {
    careProviders: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.LIST_CARE_PROVIDERS_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.careProviders = action.payload.data;
            return newState;
        }
        default:
            return state;
    }
}
