import { useMemo } from "react";
import { useSelector } from "react-redux";
import { isManager } from "domain/User.model";

/**
 * Hook to determine if the current user is a manager.
 *
 * @returns {boolean} - Returns true if the user is a manager, false otherwise.
 */
export const useIsManager = () => {
    const user = useSelector((state) => state.mainReducer.user);
    return useMemo(() => isManager(user), [user]);
}
