import React, {useCallback} from 'react';
import FormSection from "../FormSection";
import {useTranslation} from "react-i18next";
import BooleQuestion from "../input/BooleQuestion";
import {Field} from "formik";
import EpaTextField from 'components/hmo-textfield/EpaTextField';

const AllergiesForm = ({form, readonly}) => {
    const {t} = useTranslation();
    const lang = useCallback((name) => t(`anamnesis.allergies.${name}`), [t]);

    return (
            <FormSection title={lang('title')}>
                <BooleQuestion
                        disabled={readonly}
                        form={form}
                        fieldName="haveAllergies"
                        question={lang('haveAllergiesQuestion')}
                />

                {
                    form.values?.['haveAllergies'] !== null && form.values?.['haveAllergies'] === true &&
                    <Field
                            disabled={readonly}
                            name="allergiesText"
                            multiline
                            minRows={6}
                            label={lang("if-yes-specify")}
                            as={EpaTextField}
                    />
                }

            </FormSection>
    );
};


export default AllergiesForm;
