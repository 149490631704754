import * as yup from 'yup';

const propertiesShape = yup.object().shape({
    field: yup.string().required(),
    disabled: yup.boolean().required(),
    value: yup.mixed().required()
});

const mainObjectShape = yup.object().shape({
    type: yup.string().oneOf(['rule', 'group']).required(),
    properties: propertiesShape.required(),
});

export const queryFormStateSchema = yup.array().of(mainObjectShape).required();
