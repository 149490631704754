import Flex from 'components/grid/Flex';
import {Tooltip, Typography} from "@material-ui/core";

const IconWithCounter = props => {
    return props.count ? <Tooltip title={props.tooltip || ''}>
                <Flex container item grow={0} style={{height: 24, opacity: props.count ? 1 : 0, ...props.style}}>
                    <props.Icon style={{position: 'absolute', zIndex: 0, color: '#777777'}}/>
                    <Flex item grow={0} container center
                          style={{
                              marginLeft: 12,
                              marginTop: 10,
                              zIndex: 1,
                              backgroundColor: '#9B51C6',
                              borderRadius: '50%',
                              maxHeight: 14,
                              minHeight: 14,
                              minWidth: 14
                          }}>
                        <Typography style={{color: 'white', fontSize: 10}}>{props.count || '0'}</Typography>
                    </Flex>
                </Flex>
            </Tooltip>
            : null
}

export default IconWithCounter;
