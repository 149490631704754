import { LaboratoryKeys } from "domain/Laboratory.model";

function isIrrelevantLaboratoryValue(value) {
    return value === "0" || value === "0,0" || value === "0.0" || value === " ";
}

export const shouldDeleteWholeLaboratoryEntry = (laboratoryEntry) =>
    Object.keys(laboratoryEntry)
        .filter((key) => LaboratoryKeys.includes(key))
        .every((key) => !laboratoryEntry[key] || isIrrelevantLaboratoryValue(laboratoryEntry[key]));
