export const SPECIAL_STEP_TYPE = Object.freeze({
    START: "start",
    END: "end",
    LATER: "later"
});

export const mainWalkthroughSteps = [
    {
        title: "welcome",
        type: SPECIAL_STEP_TYPE.START
    },
    {
        title: "settings",
        selector: "[walkthrough-element=\"profile\"]",
        positionX: "top",
        positionY: "right",
        offsetX: 20,
        offsetY: -80,
    },
    {
        title: "views",
        selector: "[walkthrough-element=\"program-overview\"]",
        positionX: "bottom",
        positionY: "right",
        offsetX: -50,
        offsetY: 100,
    },
    {
        title: "personal-data",
        selector: "[walkthrough-element=\"personal-data\"]",
        positionX: "bottom",
        positionY: "left",
        offsetX: -130,
        offsetY: 55,
    },
    {
        title: "upload-data",
        selector: "[walkthrough-element=\"upload-data\"]",
        positionX: "middle",
        positionY: "left",
        offsetX: -170,
        offsetY: 0,
    },
    {
        title: "ask-questions",
        selector: "[walkthrough-element=\"chat\"]",
        positionX: "top",
        positionY: "left",
        offsetX: -110,
        offsetY: 170,
    },
    {
        title: "manage-access",
        selector: "[walkthrough-element=\"grant-access\"]",
        positionX: "top",
        positionY: "left",
        offsetX: -100,
        offsetY: 280,
    },
    {
        title: "congratulation",
        type: SPECIAL_STEP_TYPE.END
    },
    {
        title: "later",
        selector: "[walkthrough-element=\"profile\"]",
        positionX: "top",
        positionY: "right",
        offsetX: 20,
        offsetY: -80,
        type: SPECIAL_STEP_TYPE.LATER
    }];
