const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

/**
 * Converts bytes to a human readable string
 * @param {number} bytes
 * @returns {string}
 */
export const formatFileSize = (bytes) => {
  if(bytes === 0) return `0 ${units[0]}`;
  const index = Math.round(Math.log(bytes) / Math.log(1024));
  return `${(bytes / Math.pow(1024, index)).toFixed(2)} ${units[index]}`;
};
