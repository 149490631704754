import React, {useCallback, useEffect, Fragment, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import update from "immutability-helper";
import _ from "lodash";
import {Tooltip, IconButton} from "@material-ui/core";
import PlusIcon from '@material-ui/icons/Add'
import DeleteIcon from "@mui/icons-material/Delete";
import {useTranslation} from 'react-i18next';
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";

const DeleteComponent = ({item, onDeleteRow, ...props}) => {
    const [isOpen, setIsOpen] = useState(false);
    const {t} = useTranslation();
    return <IconButton onClick={() => setIsOpen(true)} {...props} style={{padding: 2, ...props.style}}>
        <DeleteIcon/>
        <ConfirmationDialog
                {...{
                    dialogOpen: isOpen,
                    onDialogClose: (event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setIsOpen(false)
                    },
                    onConfirm: () => {
                        onDeleteRow({id: item.id});
                    },
                    confirmationTextKey: t('case.delete-second-opinion-dialog')
                }}
        />
    </IconButton>
};

const useStyles = makeStyles((_theme) => ({

    iconPlusLabel: {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '14px',
        color: '#256AF6',
        padding: 2,
        borderRadius: '7px',
        marginTop: '1rem',
        marginBotom: '1rem',
        paddingTop: '5px',
        paddingRight: '8px',
        paddingBottom: '3px',
        paddingLeft: '13px',
        backgroundColor: '#c7eaf9',
        letterSpacing: '0.6px'
    },
    iconPlus: {
        marginLeft: '10px',
        fontWeight: '600',
        fontSize: '14px',
    },
    IconPlusLabel_Text: {
        paddingBottom: '0.2rem'
    }

}));

const addRow = (form, fieldName, rowData) => {
    const data = update(form.values, {
        [fieldName]: {
            $push: [{id: _.uniqueId(''), ...rowData}]
        }
    });
    form.setFieldValue(fieldName, data[fieldName]);
}

const DataListInputField = ({form, fieldName, children, rowData, customIcon: CustomIcon, customHasEmptyCell, disabled}) => {

    useEffect(() => {
        if (Array.isArray(form.values[fieldName]) && form.values[fieldName].length === 0) {
            addRow(form, fieldName, rowData);
        }
    }, [fieldName, form, rowData]);

    const classes = useStyles();
    const {t} = useTranslation();

    const onAddRow = useCallback(() => {
        addRow(form, fieldName, rowData);
    }, [form, fieldName, rowData]);

    const onDeleteRow = (e) => {
        const updated = update(form.values[fieldName], {
            $apply: data => data.filter(item => item.id !== e.id)
        });
        form.setFieldValue(fieldName, updated);
    }

    const onChange = useCallback((params) => {
        const index = form.values[fieldName].findIndex(item => item.id === params.id);
        const updated = update(form.values[fieldName], {
            [index]: {
                [params.field]: {$set: params.props.value}
            }
        });
        form.setFieldValue(fieldName, updated);
    }, [form, fieldName]);


    // const CustomDeleteIcon = useCallback(({item, ...props}) => , [onDeleteRow]);

    const hasEmptyCell = customHasEmptyCell ? customHasEmptyCell() : form?.values[fieldName]
            ?.some(row => Object.keys(row)
                    .some(key => key === 'value' && rowData?.value?.length > 1
                            ? false
                            : !row[key])
            );
    return <>
        {
                _.isArray(form.values[fieldName]) &&
                form.values[fieldName].map((item, _idx) => <Fragment key={item.id}> {
                    children({
                        onChange,
                        onDeleteRow,
                        key: item.id,
                        onAddRow,
                        form,
                        item,
                        fieldName,
                        classes,
                        DefaultDeleteIcon: props => <DeleteComponent {...{item, onDeleteRow}} {...props}/>
                    })
                } </Fragment>)
        }
        {!disabled ? <Tooltip title={hasEmptyCell ? t('global.has-empty-cell') : ''}>
                    <span>
                        <IconButton onClick={onAddRow} className={classes.iconPlusLabel} disabled={hasEmptyCell}>
                            <div className={classes.IconPlusLabel_Text}>{t('global.add')}</div>
                            <div className={classes.iconPlus} style={{color: hasEmptyCell ? 'lightgrey' : '#256AF6'}}>
                                <PlusIcon/>
                            </div>
                        </IconButton>
                    </span>
        </Tooltip> : <div style={{marginBottom: '8px'}}></div> }
    </>;
};

export default DataListInputField;
