import Flex from "components/grid/Flex";
import { Button, IconButton, MenuItem, TextField, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { queryFormContext } from "./filter-state/context";
import { FilterBuilder } from "./filter-builder/FilterBuilder";
import DefaultTextField from "components/hmo-textfield/DefaultTextField";
import { QueryHitsCounter } from "./QueryHitsCounter";
import CohortPatientList from "./CohortPatientList";
import React from "react";
import { HmoFilledButton } from "components/button/HmoFilledButton";
import { ADD_RULE, UPDATE_NODE } from "./filter-state/actions";

const CohortFilter = (props) => {
    const { t: translate } = useTranslation();
    const {
        name,
        setName,
        save,
        saveButtonText,
        toggleIsFilterOpen,
        formTree,
        dispatch,
        data,
        clinics,
        isLoading,
        showResultsForQuery,
        showResults,
        error,
    } = props;
    const onNameChange = (event) => {
        setName(event.target.value);
    };

    const clinicRuleIndexInFormTree = formTree.findIndex((rule) => rule.properties.field === "assignments.entity_id");

    const setClinicId = (id) => {
        if (clinicRuleIndexInFormTree !== -1) {
            dispatch({
                type: UPDATE_NODE,
                indexPath: [clinicRuleIndexInFormTree],
                payload: {
                    properties: {
                        ...formTree[clinicRuleIndexInFormTree].properties,
                        value: id,
                    },
                },
            });
        } else {
            dispatch({
                type: ADD_RULE,
                payload: {
                    type: "rule",
                    properties: {
                        field: "assignments.entity_id",
                        value: id,
                        disabled: false,
                    },
                },
            });
        }
    };

    const selectedClinicValue = formTree[clinicRuleIndexInFormTree]?.properties.value || "";

    return (
        <Flex item container column>
            <Flex item container center>
                <TextField
                    style={{ paddingTop: 10, paddingBottom: 10, marginRight: 20 }}
                    value={name}
                    fullWidth
                    onChange={onNameChange}
                    placeholder={translate("cohort.name-placeholder")}
                />
                <HmoFilledButton style={{ width: 150 }} onClick={save} disabled={!name}>
                    {saveButtonText}
                </HmoFilledButton>
            </Flex>
            <Flex
                item
                container
                style={{
                    border: "1px solid lightgrey",
                    backgroundColor: "#F6F8FA",
                }}
            >
                <Flex item grow={1} container column style={{ flex: "0 0 82%" }}>
                    <Flex item={"0 0 0"} container style={{padding: '6px 0'}}>
                        <IconButton onClick={toggleIsFilterOpen} style={{ padding: 3 }}>
                            <KeyboardArrowDownIcon />
                        </IconButton>
                        <Typography style={{ fontWeight: "bold", paddingTop: "4px" }}>
                            {translate("cohort.create-filter-according")}
                        </Typography>
                    </Flex>
                    <queryFormContext.Provider value={{ dispatch }}>
                        <FilterBuilder formTree={formTree} {...{ translate }} />
                    </queryFormContext.Provider>
                    <Button
                        style={{ margin: 15, width: 200, textTransform: "none", textWrap: "nowrap" }}
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!data}
                        onClick={showResults}
                    >
                        {translate("cohort.show-result")}
                    </Button>
                </Flex>
                <Flex item container column center >
                    <DefaultTextField
                        label=""
                        select

                        SelectProps={{
                            displayEmpty: true,
                            value: selectedClinicValue,
                            onChange: (event) => {
                                const clinicId = event.target.value;
                                setClinicId(clinicId);
                            },
                        }}
                        style={{ width: 200, backgroundColor: "white" }}
                    >
                        <MenuItem key={"Empty"} value={""}>
                            {translate("cohort.all-clinics")}
                        </MenuItem>
                        {clinics.map((clinic) => (
                            <MenuItem key={clinic.id} value={clinic.id}>
                                {clinic.name}
                            </MenuItem>
                        ))}
                    </DefaultTextField>
                    <QueryHitsCounter {...{ isLoading, error, count: data }} />
                </Flex>
            </Flex>
            {showResultsForQuery && <CohortPatientList {...{ query: showResultsForQuery }} />}
        </Flex>
    );
};

export default CohortFilter;
