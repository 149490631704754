import React, {Component} from 'react';
import {
    Snackbar,
    IconButton,
    withStyles
} from "@material-ui/core";
import {} from 'components/colors/Colors';
import {
    Close as CloseIcon
} from '@material-ui/icons';
import {Alert} from '@material-ui/lab';

const styles = theme => ({
    huge: {
        fontSize: 40
    },
    alertMessageHuge: {
        fontSize: 40,
    },
    alertRoot: {
        width: '70vw',
        alignItems: 'center'
    }
});

export class EpiAlert extends Component {

    render() {
        const {isOpen, close, severity, message, style, huge, autoHideDuration, classes} = this.props;

        return (
                <Snackbar open={isOpen} autoHideDuration={autoHideDuration || (huge ? 6000 : 3000)} onClose={close}
                          style={style} classes={{
                    ...(huge && {root: classes.huge})
                }}>
                    <Alert severity={severity}
                           classes={{
                               ...(huge && {
                                   icon: classes.huge,
                                   message: classes.alertMessageHuge,
                                   action: classes.huge,
                                   root: classes.alertRoot
                               })
                           }}
                           action={
                               <IconButton style={{padding: 3}} onClick={() => close()}>
                                   <CloseIcon/>
                               </IconButton>
                           }>
                        {message}
                    </Alert>

                </Snackbar>
        );
    }
}

export default withStyles(styles)(EpiAlert);
