import React, {Component} from 'react';
import utils from 'utils/Utils';
import moment from "moment";
import DatePicker from 'components/calendar/DatePicker';

export class CaseDatePicker extends Component {

    state = {
        date: null
    };

    handleChange = date => {
        this.setState({date}, ()=>{
            if (date?.isValid()) {
                this.handleParentChange();
            }
            else {
                //This is a horrible hack, to prevent autosave from triggering during typing.
                this.props.updateParent({
                    [this.props.name]: this.props.date
                });
            }
        });
    };

    handleParentChange = event => {
        event?.preventDefault && event.preventDefault();
        event?.stopPropagation && event.stopPropagation();
        const date = moment(this.state.date, utils.VIEW_DATE_FORMAT, true);
        if (date?.isValid()) {
            this.props.updateParent({
                [this.props.name]: date.format(utils.API_LOCAL_DATE_FORMAT)
            });
        }
    };

    render() {
        const {isEditDisabled, name, label, ...other} = this.props;
        const date = this.state.date !== null
            ? this.state.date
            : this.props.date;
        return (
            <DatePicker {...{
                value: date || null,
                name: name,
                label: label,
                onChange: this.handleChange,
                onBlur: this.handleParentChange,
                disable: isEditDisabled,
                ...other
            }}/>
        );
    }
}

export default CaseDatePicker;
