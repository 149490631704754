import { fetchIcd10 } from "./SecondOpinionDetailDialog.action";
import { updateSecondOpinionDetail } from 'scenes/case/edit/CaseEdit.action';
export default {
    mapStateToProps: (state) => {
        return {
            caseBusinessId: state.caseEditReducer.case.businessId,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            fetchIcd10: (icdFilter) => dispatch(fetchIcd10(icdFilter)),
            updateSecondOpinionDetail: (props, caseBusinessId) => dispatch(updateSecondOpinionDetail(props, caseBusinessId)),
        };
    }
};
