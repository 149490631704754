import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {FormControl, InputLabel, MenuItem, OutlinedInput, Select, withStyles,} from "@material-ui/core";
import {ExpandMore} from '@material-ui/icons';
import {withTranslation} from "react-i18next";
import {translateLabel} from "i18n/Translation.utils";
import _ from "lodash";

const styles = theme => ({
    input: {
        fontSize: 16,
        padding: '8px 10px 8px 10px',
    }
});

export class MaterialSelect extends Component {
    state = {
        labelWidth: 0
    };

    componentDidMount() {
        this.setState({
            labelWidth: ReactDOM.findDOMNode(this.inputLabelRef).offsetWidth,
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.label !== prevProps.label) {
            this.setState({
                labelWidth: ReactDOM.findDOMNode(this.inputLabelRef).offsetWidth,
            });
        }
    }

    render() {
        const {classes, options, value, name, label, handleChange, disabled, t: translate, translationPath} = this.props;
        const {labelWidth} = this.state;

        return (
            <FormControl variant="outlined" fullWidth>
                <InputLabel ref={ref => {
                    this.inputLabelRef = ref;
                }} htmlFor={name}>
                    {label}
                </InputLabel>
                <Select
                    value={value}
                    onChange={handleChange}
                    IconComponent={ExpandMore}
                    input={
                        <OutlinedInput disabled={disabled} name={name} classes={
                            {input: classes.input}
                        } labelWidth={labelWidth} id={name} fullWidth/>
                    }
                >
                    {
                        options.map((option, index) => {
                            const optionLabel = _.isEmpty(translationPath) ? translateLabel(translate, option) : translate(translationPath + option);
                            return (
                                <MenuItem key={index} value={option}>{optionLabel}</MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
        );
    }
}

export default withStyles(styles)(withTranslation()(MaterialSelect));
