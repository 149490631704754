export const FULL_PATIENT = 'FULL_PATIENT';
export const CASE = 'CASE';
export const CARE_PROGRAM = 'CARE_PROGRAM';
export const CLINIC_CENTER = 'CLINIC_CENTER';
export const CARE_PROVIDER = 'CARE_PROVIDER';
export const MEDICATION_PLAN = 'MEDICATION_PLAN';
export const ANAMNESE = 'ANAMNESE';
export const LABORATORY = 'LABORATORY';

export function toEntityType(fileStorageType){
    switch (fileStorageType){
        case CASE: return CASE;
        case CARE_PROGRAM: return CARE_PROGRAM;
        case CLINIC_CENTER: return CLINIC_CENTER;
        case CARE_PROVIDER: return CARE_PROVIDER;
        case 'PATIENT': return FULL_PATIENT;
    }
}

export function toFileStorageType(entityType){
    switch (entityType){
        case CASE: return CASE;
        case CARE_PROGRAM: return CARE_PROGRAM;
        case CLINIC_CENTER: return CLINIC_CENTER;
        case CARE_PROVIDER: return CARE_PROVIDER;
        case FULL_PATIENT: return 'PATIENT';
    }
}
