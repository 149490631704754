import DefaultDialogBody from './DefaultDialogBody';
import {useTranslation} from "react-i18next";

const PatientEditsPatientEmail = (props) => {
    const {
        toggleOpen, save, isSaveDisabled, userAlreadyExists, userDetailsAlreadyExists, deleteEmail, originalEmail,
        saveButtonLabel
    } = props;
    const {t: translate} = useTranslation();
    return (
            <DefaultDialogBody {...{
                information: (userAlreadyExists || userDetailsAlreadyExists)
                        ? translate('access.already-used') : '',
                toggleOpen,
                save: save,
                saveButtonLabel,
                disabled: isSaveDisabled
                        || userAlreadyExists
                        || userDetailsAlreadyExists,
                deleteEmail,
                originalEmail
            }}/>
    );
};

export default PatientEditsPatientEmail;
