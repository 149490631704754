import React, {Component} from 'react';
import {Typography, withStyles} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import * as colors from 'components/colors/Colors';

const styles = theme => ({
    title: {
        color: colors.transparentBlack054,
        fontSize: 12,
        textTransform: 'uppercase'
    }
});

export const HEADER_BASES = {
    name: '250px',
    rating: '100px',
    city: '150px',
    cancerType: '30%',
    certificate: '15%',
    tag: '5%',
    comment: '10%',
};

export class ClinicHeader extends Component {
    render() {
        const {
            classes, sortBy = () => {
            }, t: translate
        } = this.props;

        return (
            <Flex item={'0 1 auto'} container alignItems={'center'} style={{minHeight: 25}} padding={5}>
                <Flex item={`0 0 ${HEADER_BASES.name}`} container style={{marginLeft: 10}} onClick={sortBy('name')} padding={3}>
                    <Typography className={classes.title}>
                        {translate('global.name')}
                    </Typography>
                </Flex>

                <Flex item={`0 0 ${HEADER_BASES.rating}`} container onClick={sortBy('rating')} padding={3}>
                    <Typography className={classes.title}>
                        {translate('global.rating')}
                    </Typography>
                </Flex>

                <Flex item={`0 0 ${HEADER_BASES.city}`} container onClick={sortBy('city')} padding={3}>
                    <Typography className={classes.title}>
                        {translate('case.city')}
                    </Typography>
                </Flex>

                <Flex item={`0 0 ${HEADER_BASES.cancerType}`} container onClick={sortBy('cancerType')} padding={3}>
                    <Typography className={classes.title}>
                        {translate('clinic.cancer-type')}
                    </Typography>
                </Flex>

                <Flex item={`0 0 ${HEADER_BASES.certificate}`} container onClick={sortBy('certificate')} padding={3}>
                    <Typography className={classes.title}>
                        {translate('clinic.certificate')}
                    </Typography>
                </Flex>

                <Flex item={`0 0 ${HEADER_BASES.tag}`} container onClick={sortBy('tag')} padding={3}>
                    <Typography className={classes.title}>
                        {translate('clinic.tag')}
                    </Typography>
                </Flex>

                <Flex item={`0 0 ${HEADER_BASES.comment}`} container onClick={sortBy('comment')} padding={3}>
                    <Typography className={classes.title}>
                        {translate('global.comment')}
                    </Typography>
                </Flex>

            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(ClinicHeader));
