import {IconButton, Tooltip, useTheme} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import React, {useState} from "react";
import {useSendProgramStepReminderMutation} from "scenes/patient/ProgramApi";
import {useEnqueueError, useEnqueueSuccess} from "components/alert/SnackbarHooks";

const ReminderEmail = ({step, refreshPrograms}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const enqueueError = useEnqueueError();
    const enqueueSuccess = useEnqueueSuccess();
    const [sendProgramStepReminder] = useSendProgramStepReminderMutation();
    const [isReminderDialogOpen, setIsReminderDialogOpen] = useState(false);
    const toggleReminderDialogOpen = () => setIsReminderDialogOpen(!isReminderDialogOpen);
    const sendStepReminder = () => {
        sendProgramStepReminder({programStepId: step.id}).unwrap().then(() => {
            enqueueSuccess(t('program.email-sent'));
            refreshPrograms();
        }).catch(() => {
            enqueueError(t('program.email-sent-error'));
        }).finally(() => {
            toggleReminderDialogOpen();
        });
    }
    return <>
        <Tooltip
                title={!step.reminderSent
                        ? t(`program.reminder-tooltip`)
                        : moment(step.reminderSent).isBefore(moment('2010-01-01'))
                                ? t('program.reminder-tooltip-resend-no-date')
                                : t('program.reminder-tooltip-resend', {date: moment(step.reminderSent).format('DD MMM YYYY, HH:mm')})}>
            <IconButton onClick={toggleReminderDialogOpen} style={{padding: 3}}>
                <NotificationsNoneIcon style={{
                    color: !step.date
                            ? theme.palette.error.main
                            : step.reminderSent
                                    ? theme.palette.primary.main
                                    : theme.palette.warning.main
                }}/>
            </IconButton>
        </Tooltip>
        <ConfirmationDialog {...{
            singleConfirmationButton: !step?.date,
            onConfirm: step?.date ? sendStepReminder : toggleReminderDialogOpen,
            confirmationTextKey: step?.date
                    ? moment(step.reminderSent).isBefore(moment('2010-01-01'))
                            ? 'program.resend-reminder-text-no-date'
                            : `program.${step.reminderSent ? "re" : ""}send-reminder-text`
                    : 'program.no-date-email',
            confirmationTextValues: {date: moment(step.reminderSent).format('DD MMM YYYY, HH:mm')},
            dialogOpen: isReminderDialogOpen,
            onDialogClose: toggleReminderDialogOpen,
            title: t(`program.${step.reminderSent ? "re" : ""}send-reminder-title`),
        }}/>
    </>;
};

export default ReminderEmail;
