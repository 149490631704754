import React from 'react';
import './ToggleSwitch.css';
import {useTranslation} from "react-i18next";
import _ from 'lodash';

const ToggleSwitch = ({form, value, name, onChange, noMargin, style, setForm, disabled}) => {
    const { t } = useTranslation();
    const toggleStatusTextColor = {
        enabled: '#030303',
        disabled: '#8b8b8b',
    };

    const setFieldValue = newValue => {
        if (setForm){
            setForm(newValue);
        } else {
            form.setFieldValue(name, newValue);
        }
    }

    const handleOnClickSwitch = (e) => {
        e.preventDefault();
        setFieldValue(!value);
        onChange(e);
    };

    const handleSwitchChange = (position) => {
        if (position === 'left') {
            setFieldValue(false);
        } else if (position === 'right') {
            setFieldValue(true);
        } else if (position === 'center'){
            if(value === null){
                setFieldValue(true);
            } else {
                setFieldValue(null);
            }
        }
    }

    return (
        <>
            <div
                style={{
                    pointerEvents: disabled ? "none" : "auto",
                    opacity: disabled ? 0.6 : 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    margin: noMargin ? "0" : "15px auto 0px",
                    ...style,
                }}
            >
                <div
                    style={{
                        fontWeight: 600,
                        color: value === false ? toggleStatusTextColor.enabled : toggleStatusTextColor.disabled,
                        marginTop: 4,
                        marginRight: 5,
                        cursor: "pointer",
                        textTransform: "uppercase",
                    }}
                    onClick={() => handleOnClickSwitch("left")}
                >
                    {t("anamnesis.no")}
                </div>
                <div
                    style={{
                        display: "flex",
                    }}
                >
                    <label className={_.isBoolean(value) ? "toggleSwitch" : "toggleSwitch neutral"}>
                        <input type="checkbox" onChange={() => false} checked={value === true} />
                        <i
                            className={value === false ? "toggleFalse" : value === true ? "toggleTrue" : ""}
                            onClick={handleOnClickSwitch}
                        />
                        <div className="toggleSwitchLeft" onClick={() => handleSwitchChange("left")} />
                        <div
                            className="toggleSwitchLeft"
                            onClick={() => handleSwitchChange("center")}
                            style={{ marginLeft: 20 }}
                        />
                    </label>
                </div>
                <div
                    style={{
                        fontWeight: 600,
                        color: value === true ? toggleStatusTextColor.enabled : toggleStatusTextColor.disabled,
                        marginTop: 4,
                        marginLeft: 5,
                        cursor: "pointer",
                        textTransform: "uppercase",
                    }}
                    onClick={() => {
                        handleSwitchChange("right");
                    }}
                >
                    {t("anamnesis.yes")}
                </div>
            </div>
        </>
    );
}

export default ToggleSwitch;
