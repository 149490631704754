import { makeStyles } from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import { ReactComponent as FileUploadIcon } from "assets/img/file-upload.svg";

const useDropZoneStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        border: "1px dashed #030303",
        borderRadius: "10px",
        padding: "12px",
        alignItems: "center",
        cursor: "pointer",
    },
    textExplanation: {
        marginTop: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        lineHeight: "1.2em",
    },
    icon: {
        width: "54px",
        height: "auto",
    },
});

const DragndropZone = () => {
    const classes = useDropZoneStyles();
    const {t} = useTranslation();
    return (
        <div className={classes.root}>
            <FileUploadIcon className={classes.icon} />
            <div className={classes.textExplanation}>
                <div>{t('global.drag-and-drop')}</div>
                <div>{t('global.or')}</div>
                <div>{t('global.click-to-upload')}</div>
            </div>
        </div>
    );
};

export default DragndropZone;
