import React from 'react';
import {Grid, Box} from "@material-ui/core";
import {SimpleSubInnerQuestionSection} from "../auxiliary/SubTitle";
import {Field} from "formik";
import ToggleSwitch from "./ToggleSwitch";

const BooleSubQuestion = ({form, fieldName, question, disabled}) => {
    return (
        <Box mt={1}>
            <Grid container spacing={8} style={{alignItems: 'center'}}>
                <Box mr={2}>
                    <SimpleSubInnerQuestionSection>{question}</SimpleSubInnerQuestionSection>
                </Box>
                    <Field
                        disabled={disabled}
                        name={fieldName}
                        type="text"
                        noMargin
                        as={ToggleSwitch}
                        form={form}
                    />
            </Grid>
        </Box>

    );
};

export default BooleSubQuestion;
