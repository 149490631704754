import React, {useCallback} from "react";
import FormSection from "../FormSection";
import {Grid, InputLabel, MenuItem, Tooltip, TextField, Box} from "@material-ui/core";
import {Field} from "formik";
import BloodPressureInput from "../input/BloodPressureInput";
import StateInput from "../input/StateInput";
import {validationError} from "../config/ValidationSchema";
import EpaTextField from 'components/hmo-textfield/EpaTextField';
import {useTranslation} from "react-i18next";
import BooleQuestion from "../input/BooleQuestion";
import InputDivider from "../input/InputDivider";
import {makeStyles} from "@material-ui/core/styles";
import FieldSelect from '../input/FieldSelect';
import { QuestionWithFiltering } from "./QuestionWithFiltering";

const useStyles = makeStyles((_theme) => ({
    packageYears: {
        '& [class*="MuiInput-root-"]': {
            backgroundColor: "transparent",
            borderColor: "transparent !important",
            paddingLeft: "0",
        },
        '& [class*="MuiInputLabel-root-"]': {
            color: "#0000008a",
        },
        "& input": {
            backgroundColor: "transparent",
            color: "black",
        },
    },
}));

/**
 * Calculates package years based on the number of packs smoked per day, week or month
 * @param {number} years
 * @param {{value: number, unit: 'DAY' | 'WEEK' | 'MONTH'}} smokeNumberOfPacks
 * @returns {number}
 */
const packageYears = (years, smokeNumberOfPacks) => {
    const unitToDaysMap = {
        DAY: 1,
        WEEK: 7,
        MONTH: 30,
    };
    return years * (smokeNumberOfPacks.value / unitToDaysMap[smokeNumberOfPacks.unit]);
};

const CardioVascularForm = ({form, readonly, filters}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const lang = useCallback((name) => t(`anamnesis.cardiovascular.${name}`), [t]);
    return (
            <FormSection title={lang("title")}>
                <QuestionWithFiltering filters={filters} filterValue={form.values?.hasTimePressureAndStress}>
                    <BooleQuestion form={form} fieldName="hasTimePressureAndStress" lang={lang} disabled={readonly} info/>
                    <InputDivider/>
                </QuestionWithFiltering>

                <QuestionWithFiltering filters={filters} filterValue={form.values?.hasHealthyDiet}>
                    <BooleQuestion form={form} fieldName="hasHealthyDiet" disabled={readonly} lang={lang} info/>
                    <InputDivider/>
                </QuestionWithFiltering>

                <QuestionWithFiltering filters={filters} filterValue={form.values?.hasCardiovascularDisease}>
                    <BooleQuestion form={form} fieldName="hasCardiovascularDisease" disabled={readonly} lang={lang} info/>
                    {
                        form.values?.["hasCardiovascularDisease"] !== null &&
                        form.values?.["hasCardiovascularDisease"] === true && <div style={{marginLeft: '28px'}}>
                            <QuestionWithFiltering filters={filters} filterValue={form.values?.hasNoteCurrentPulse}>
                                <BooleQuestion secondary form={form} fieldName="hasNoteCurrentPulse" disabled={readonly} lang={lang} info/>
                                {
                                    form.values?.["hasNoteCurrentPulse"] !== null &&
                                    form.values?.["hasNoteCurrentPulse"] === true &&
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <Field
                                                    disabled={readonly}
                                                    name="currentPulseCount"
                                                    type="number"
                                                    label={lang("currentPulseCountPlaceholder")}
                                                    as={EpaTextField}
                                                    {...validationError(form, "currentPulseCount")}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            </QuestionWithFiltering>
                            <QuestionWithFiltering filters={filters} filterValue={form.values?.hasNoteBloodPressure}>
                                <BooleQuestion secondary form={form} disabled={readonly} fieldName="hasNoteBloodPressure" lang={lang} info/>

                                {
                                    form.values?.["hasNoteBloodPressure"] !== null &&
                                    form.values?.["hasNoteBloodPressure"] === true &&
                                    <Box mb={2}>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <Field
                                                        disabled={readonly}
                                                        name="currentBloodPressure"
                                                        type="text"
                                                        fullWidth
                                                        label={lang("currentBloodPressureLabel")}
                                                        as={BloodPressureInput}
                                                        form={form}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                }
                            </QuestionWithFiltering>
                        </div>
                    }

                    <InputDivider/>
                </QuestionWithFiltering>

                <QuestionWithFiltering filters={filters} filterValue={form.values?.hasSufferingFromHypercholesterolemia}>
                <BooleQuestion form={form} disabled={readonly} fieldName="hasSufferingFromHypercholesterolemia" lang={lang}
                               info/>

                {
                    form.values?.["hasSufferingFromHypercholesterolemia"] !== null &&
                    form.values?.["hasSufferingFromHypercholesterolemia"] === true &&
                    <Box mb={2}>
                        <Grid container justifyContent="flex-end">
                            <Grid item xs={3}>
                                <StateInput form={form} disabled={readonly} name="hypercholesterolemiaState"/>
                            </Grid>
                        </Grid>
                    </Box>
                }

                <InputDivider/>
                </QuestionWithFiltering>

                <QuestionWithFiltering filters={filters} filterValue={form.values?.hasSufferingFromDiabetes}>
                <BooleQuestion form={form} fieldName="hasSufferingFromDiabetes" disabled={readonly} lang={lang} info/>

                {
                    form.values?.["hasSufferingFromDiabetes"] !== null &&
                    form.values?.["hasSufferingFromDiabetes"] === true &&
                    <Box mb={2}>
                        <Grid container justifyContent="flex-end">
                            <Grid item xs={3}>
                                <StateInput form={form} name="diabetesState" disabled={readonly}/>
                            </Grid>
                        </Grid>
                    </Box>
                }

                <InputDivider/>
                </QuestionWithFiltering>

                <QuestionWithFiltering filters={filters} filterValue={form.values?.smokeStatus}>
                <BooleQuestion form={form} fieldName="smokeStatus" lang={lang} info disabled={readonly}/>

                {
                    form.values?.["smokeStatus"] !== null && form.values?.["smokeStatus"] === true &&
                    <Box mb={2}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={3}>
                                <Field
                                        disabled={readonly}
                                        name="smokeYear"
                                        type="number"
                                        label={lang("smokeYearLabel")}
                                        as={EpaTextField}
                                        inputProps={{
                                            min: 1,
                                            max: 120,
                                        }}
                                        {...validationError(form, "smokeYear")}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <Tooltip arrow title={lang("smokeNumberOfPacksTooltip")} placement={"top"}>
                                        <span>
                                            <Field
                                                    disabled={readonly}
                                                    name="smokeNumberOfPacks"
                                                    type="number"
                                                    label={lang("numberOfPacks")}
                                                    as={EpaTextField}
                                                    inputProps={{
                                                        min: 1,
                                                        max: 100,
                                                    }}
                                                    {...validationError(form, "smokeNumberOfPacks")}
                                            />
                                        </span>
                                </Tooltip>
                            </Grid>

                            <Grid item xs={3}>
                                <InputLabel shrink id={`smokeNumberUnitLabel`}>Per</InputLabel>
                                <Field
                                        disabled={readonly}
                                        name={"smokeNumberUnit"}
                                        fullWidth
                                        labelId={`smokeNumberUnitLabel`}
                                        component={FieldSelect}
                                        displayEmpty
                                        {...validationError(form, "smokeNumberUnit")}
                                >
                                    <MenuItem value={"DAY"}>{lang("smokeNumberUnit.DAY")}</MenuItem>
                                    <MenuItem value={"WEEK"}>{lang("smokeNumberUnit.WEEK")}</MenuItem>
                                    <MenuItem value={"MONTH"}>{lang("smokeNumberUnit.MONTH")}</MenuItem>
                                </Field>
                            </Grid>

                            <Grid item xs={3}>
                                {
                                    form.values.smokeNumberUnit &&
                                    form.values.smokeNumberOfPacks &&
                                    form.values.smokeYear &&
                                    <TextField
                                            label={"Packungsjahre"}
                                            value={
                                                "= " +
                                                packageYears(form.values.smokeYear, {
                                                    value: form.values.smokeNumberOfPacks,
                                                    unit: form.values.smokeNumberUnit,
                                                }).toFixed(2)
                                            }
                                            disabled={true} // disabled because it's a calculated field
                                            className={classes.packageYears}>
                                    </TextField>
                                }
                            </Grid>
                        </Grid>
                    </Box>
                }
                </QuestionWithFiltering>


                <QuestionWithFiltering filters={filters} filterValue={form.values?.oldSmokeStatus}>
                {
                    form.values?.["smokeStatus"] !== null && form.values?.["smokeStatus"] === false &&
                    <>
                            <InputDivider/>
                            <BooleQuestion form={form} fieldName="oldSmokeStatus" lang={lang} info disabled={readonly}/>
                    </>
                }

                {
                    form.values?.["oldSmokeStatus"] !== null && form.values?.["oldSmokeStatus"] === true &&
                    form.values?.["smokeStatus"] === false &&
                    <Box mb={2}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={2}>
                                <Field
                                        disabled={readonly}
                                        name="oldSmokeYear"
                                        type="number"
                                        label={lang("smokeYearLabel")}
                                        as={EpaTextField}
                                        inputProps={{
                                            min: 1,
                                            max: 120,
                                        }}
                                        {...validationError(form, "oldSmokeYear")}
                                />
                            </Grid>

                            <Grid item xs={2}>
                                <Tooltip arrow title={lang("smokeNumberOfPacksTooltip")} placement={"top"}>
                                        <span>
                                            <Field
                                                    disabled={readonly}
                                                    name="oldSmokeNumberOfPacks"
                                                    type="number"
                                                    label={lang("numberOfPacks")}
                                                    as={EpaTextField}
                                                    inputProps={{
                                                        min: 1,
                                                        max: 100,
                                                    }}
                                                    {...validationError(form, "oldSmokeNumberOfPacks")}
                                            />
                                        </span>
                                </Tooltip>
                            </Grid>

                            <Grid item xs={2}>
                                <InputLabel shrink id={`smokeNumberUnitLabel`}>Per</InputLabel>
                                <Field
                                        disabled={readonly}
                                        name={"oldSmokeNumberUnit"}
                                        fullWidth
                                        labelId={`smokeNumberUnitLabel`}
                                        component={FieldSelect}
                                        displayEmpty
                                        {...validationError(form, "oldSmokeNumberUnit")}
                                >
                                    <MenuItem value={"DAY"}>{lang("smokeNumberUnit.DAY")}</MenuItem>
                                    <MenuItem value={"WEEK"}>{lang("smokeNumberUnit.WEEK")}</MenuItem>
                                    <MenuItem value={"MONTH"}>{lang("smokeNumberUnit.MONTH")}</MenuItem>
                                </Field>
                            </Grid>

                            <Grid item xs={1}>
                                {
                                    form.values.oldSmokeNumberUnit &&
                                    form.values.oldSmokeNumberOfPacks &&
                                    form.values.oldSmokeYear &&
                                    <TextField
                                            label={"Packungsjahre"}
                                            value={
                                                "= " +
                                                packageYears(form.values.oldSmokeYear, {
                                                    value: form.values.oldSmokeNumberOfPacks,
                                                    unit: form.values.oldSmokeNumberUnit,
                                                }).toFixed(2)
                                            }
                                            disabled={true} // disabled because it's a calculated field
                                            className={classes.packageYears}>
                                    </TextField>
                                }
                            </Grid>

                            <Grid item xs={2}>
                                <Field
                                        disabled={readonly}
                                        name="exsmokerSince"
                                        type="number"
                                        label={lang("exsmokerSinceLabel")}
                                        as={EpaTextField}
                                        placeholder={t('global.number')}
                                        inputProps={{
                                            min: 1,
                                            max: 10000,
                                        }}
                                        {...validationError(form, "exsmokerSince")}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                }

                <InputDivider/>

                </QuestionWithFiltering>
                <QuestionWithFiltering filters={filters} filterValue={form.values?.familyHeartAttackOrStrokeStatus}>
                    <BooleQuestion disabled={readonly} form={form} fieldName="familyHeartAttackOrStrokeStatus" lang={lang} info/>
                    <InputDivider/>
                </QuestionWithFiltering>
                <QuestionWithFiltering filters={filters} filterValue={form.values?.alcoholDrinkingStatus}>
                    <BooleQuestion disabled={readonly} form={form} fieldName="alcoholDrinkingStatus" lang={lang} info/>

                    {
                        form.values?.["alcoholDrinkingStatus"] !== null &&
                        form.values?.["alcoholDrinkingStatus"] === true &&
                        <Grid container spacing={0}>
                            <Grid item xs={3}>
                                <Field
                                        disabled={readonly}
                                        name="alcoholDrinkCountPerWeek"
                                        type="number"
                                        label={lang("alcoholDrinkCountPerWeekLabel")}
                                        as={EpaTextField}
                                        inputProps={{
                                            min: 1,
                                            max: 10000,
                                        }}
                                        {...validationError(form, "alcoholDrinkCountPerWeek")}
                                />
                            </Grid>
                        </Grid>
                    }

                    <InputDivider/>
                </QuestionWithFiltering>

                <QuestionWithFiltering filters={filters} filterValue={form.values?.sportStatus}>
                    <BooleQuestion disabled={readonly} form={form} fieldName="sportStatus" lang={lang} info/>
                </QuestionWithFiltering>
            </FormSection>
    );
};

CardioVascularForm.propTypes = {};

export default CardioVascularForm;
