import React, {Component} from 'react';
import {
    withStyles, Typography, Select, MenuItem, ListItemText, IconButton, FormControl,
    RadioGroup, FormControlLabel, Radio, Button
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import * as colors from 'components/colors/Colors';
import HmoInputLabel from 'components/input-label/HmoInputLabel';
import HmoTextField from 'components/hmo-textfield/HmoTextField';
import {withTranslation} from 'react-i18next';
import moment from 'moment';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Delete';
import Question from 'components/question/Question';
import {SECOND_OPINION_READY} from 'domain/Milestone.model';
import {isCaseManager} from 'domain/User.model';
import Rating from 'components/rating/Rating';
import DatePicker from 'components/calendar/DatePicker';
import EpiAlert from 'components/alert/EpiAlert';

const styles = theme => ({
    inputRoot: {
        backgroundColor: colors.htmlWhite,
        borderRadius: 4,
        paddingLeft: 7
    },
    title: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: 'black',
        fontSize: 15
    },
    calendarColor: {
        color: colors.htmlWhite
    }
});

const QuestionOfPatient = (props) => {
    const {patientQuestionLogs, addNewPatientQuestionLog, disabled, caseBusinessId} = props;
    return (
        <Flex id={'questions-of-patient'} item container>
            <Flex item container column>
                <Question {...{
                    patientQuestionLogs,
                    addNewPatientQuestionLog,
                    disabled,
                    color: colors.htmlWhite,
                    caseBusinessId
                }}/>
            </Flex>
            <Flex item container center style={{maxWidth: 100, width: 100}}>
                <Typography style={{
                    fontSize: 160,
                    position: 'absolute',
                    fontWeight: 'bold',
                    color: '#C9EEEA'
                }}>?</Typography>
            </Flex>
        </Flex>
    );
};

const InvolvedDoctors = (props) => {
    const {
        classes, translate, changeState, addDoctor, removeDoctor, involvedDoctors, selectedName, selectedSubject
    } = props;

    return (
        <Flex id={'involved-doctors'} item container style={{marginTop: 20}}>
            <Flex item container column grow={9}>
                <HmoInputLabel>
                    {translate('case.involved-doctors')}
                </HmoInputLabel>
                <Flex id={'doctor-list'} item container column style={{
                    backgroundColor: colors.alto,
                    borderRadius: 5
                }} padding={5}>
                    <Flex item={'0 1 auto'} container>
                        <Flex item={'1 0 0'} padding={5}>
                            <HmoInputLabel>
                                {translate('case.subject')}
                            </HmoInputLabel>
                        </Flex>
                        <Flex item={'4 0 0'} padding={5}>
                            <HmoInputLabel>
                                {translate('case.name')}
                            </HmoInputLabel>
                        </Flex>
                    </Flex>
                    {
                        (involvedDoctors || []).map(doctor => (
                                <Flex item={'0 1 auto'} container key={'' + doctor.name + doctor.type}>
                                    <Flex item={'1 0 0'} padding={5} container alignItems={'center'}>
                                        <Typography style={{fontWeight: 'bold'}}>
                                            {translate(`case.doctor-type.${doctor.type}`)}
                                        </Typography>
                                    </Flex>
                                    <Flex item={'4 0 0'} padding={5} container>
                                        <Flex item>
                                            <Typography style={{
                                                fontSize: 16,
                                                paddingTop: 3,
                                                paddingLeft: 3
                                            }}>{doctor.name || ''}</Typography>
                                        </Flex>
                                        <Flex item={'0 1 auto'}>
                                            <IconButton
                                                onClick={removeDoctor(doctor)}
                                                style={{padding: 4}}>
                                                <RemoveIcon/>
                                            </IconButton>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            )
                        )
                    }
                    <Flex id={'doctor-creator'} item={'0 1 auto'} container>
                        <Flex item={'1 0 0'} padding={5}>
                            <Select
                                value={selectedSubject || ''}
                                classes={{root: classes.inputRoot}}
                                onChange={changeState}
                                fullWidth
                                name={'selectedSubject'}
                                renderValue={value => translate(`case.doctor-type.${value}`)}
                            >
                                {(["RADIOLOGY", "SURGERY", "DERMATOLOGY", "ENDOCRINOLOGY", "GASTROENTEROLOGY",
                                    "GYNECOLOGY", "HEMATOLOGY", "NEUROSURGERY", "UROLOGY", "ONCOLOGY", "OTHER",
                                ]).map(subject => (
                                    <MenuItem key={subject} value={subject}>
                                        <ListItemText primary={translate(`case.doctor-type.${subject}`)}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </Flex>
                        <Flex item={'4 0 0'} padding={5} container>
                            <Flex item>
                                <HmoTextField
                                    value={selectedName || ''}
                                    onChange={changeState}
                                    name={'selectedName'}
                                    fullWidth
                                />
                            </Flex>
                            <Flex item={'0 1 auto'}>
                                <IconButton
                                    onClick={addDoctor}
                                    style={{padding: 4}}>
                                    <AddIcon/>
                                </IconButton>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

const TherapyProposal = (props) => {
    const {translate, handleCaseChange, furtherDiagnostic} = props;
    return (
        <Flex item container column style={{marginTop: 20}}>
            <HmoInputLabel>{translate('case.therapy-proposal')}</HmoInputLabel>
            <HmoTextField
                value={furtherDiagnostic || ''}
                multiline
                variant={'outlined'}
                rows={6}
                onChange={handleCaseChange}
                fullWidth
                name={'furtherDiagnostic'}
                InputProps={{
                    style: {
                        padding: 5
                    }
                }}
                style={{
                    backgroundColor: colors.htmlWhite,
                }}
            />
        </Flex>
    );
};

const Deviation = (props) => {
    const {deviationComment, deviationType, translate, handleCaseChange, user, rating} = props;
    return (
        <Flex item container column style={{marginTop: 20}}>
            <HmoInputLabel style={{
                borderBottom: `2px solid ${colors.grey}`
            }}>
                {translate('case.deviation')}
            </HmoInputLabel>

            <FormControl component="fieldset">
                <RadioGroup row aria-label="position" name="deviationType"
                            value={deviationType || ''}
                            onChange={handleCaseChange}>
                    <FormControlLabel
                        value="PRESENT"
                        control={<Radio color="primary"/>}
                        label={translate('global.yes')}
                    />
                    <FormControlLabel
                        value="NOT_PRESENT"
                        control={<Radio color="primary"/>}
                        label={translate('global.no')}
                    />
                    <FormControlLabel
                        value="PARTIALLY_PRESENT"
                        control={<Radio color="primary"/>}
                        label={translate('global.partially')}
                    />
                </RadioGroup>
            </FormControl>

            <Flex item container column>
                <HmoInputLabel>
                    {translate('global.comment')}
                </HmoInputLabel>
                <HmoTextField
                    value={deviationComment || ''}
                    variant={'outlined'}
                    onChange={handleCaseChange}
                    fullWidth
                    name={'deviationComment'}
                    InputProps={{
                        style: {
                            padding: 5
                        }
                    }}
                    style={{
                        backgroundColor: colors.htmlWhite,
                    }}
                />
            </Flex>

            {
                isCaseManager(user) &&
                <Flex item container column>
                    <HmoInputLabel>
                        {translate('global.rating')}
                    </HmoInputLabel>
                    <Rating rating={rating} onChange={handleCaseChange}
                            name={'rating'}/>
                </Flex>
            }

        </Flex>
    );
};

export class ReleaseSecondOpinion extends Component {

    state = {
        selectedName: '',
        selectedSubject: '',
        confirmSecondOpinionDisabled: false
    }

    changeState = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    addDoctor = event => {
        if (this.state.selectedName && this.state.selectedSubject) {
            this.props.updateCase({
                involvedDoctors: [
                    ...(this.props._case.involvedDoctors || []),
                    ...[`${this.state.selectedSubject};${this.state.selectedName}`]
                ]
            })
            this.setState({
                selectedName: '',
                selectedSubject: ''
            });
        }
    }

    removeDoctor = doctor => event => {
        const index = this.props._case.involvedDoctors.findIndex(item => {
            return item === `${doctor.type};${doctor.name}`;
        });
        let newInvolvedDoctors = [...this.props._case.involvedDoctors];
        newInvolvedDoctors.splice(index, 1);
        this.props.updateCase({
            involvedDoctors: newInvolvedDoctors
        })
    }

    closeSnackbar = () => {
        this.setState({
            alert: '',
        });
    };

    render() {
        const {
            classes, t: translate, confirmSecondOpinion, disabled, milestones, _case, handleCaseChange,
            addNewPatientQuestionLog, user, save
        } = this.props;
        const {addDoctor, changeState, removeDoctor, closeSnackbar} = this;
        const {selectedName, selectedSubject, confirmSecondOpinionDisabled} = this.state;
        const {
            businessId, patientQuestionLogs, involvedDoctors, furtherDiagnostic, deviationComment,
            deviationType, rating
        } = _case;
        const involvedDoctorsViewMap = involvedDoctors.map(item => {
            const typeAndName = item.split(';');
            return {
                type: typeAndName[0],
                name: typeAndName[1]
            }
        });
        const readyMilestone = milestones.find(milestone => milestone.id === SECOND_OPINION_READY);
        const releaseDate = readyMilestone && readyMilestone.date ? moment(readyMilestone.date) : moment();
        return (
            <Flex item container direction={'column'}
                  style={{
                      backgroundColor: colors.iceberg,
                      borderRadius: 6,
                      margin: 10
                  }}
            >
                <Flex item container style={{backgroundColor: colors.niagara, padding: 15, borderRadius: 3}}>
                    <Typography className={classes.title} style={{color: colors.htmlWhite}}>
                        {translate('global.second-opinion')}
                    </Typography>
                </Flex>

                <Flex item container style={{
                    backgroundColor: colors.iceberg,
                    borderRadius: 5,
                    marginTop: 10,
                    padding: 10
                }} column>
                    <QuestionOfPatient {...{
                        translate,
                        patientQuestionLogs,
                        addNewPatientQuestionLog,
                        disabled,
                        caseBusinessId: _case.businessId
                    }}/>
                    <DatePicker {...{
                        value: releaseDate,
                        name: 'finishedAt',
                        label: translate('case.finished-at-date'),
                        disabled: true,
                        style: {maxWidth: 250, flex: '1 1 auto'}
                    }}/>
                    <InvolvedDoctors {...{
                        classes,
                        translate,
                        involvedDoctors: involvedDoctorsViewMap,
                        addDoctor,
                        removeDoctor,
                        selectedName,
                        selectedSubject,
                        changeState
                    }}/>
                    <TherapyProposal {...{classes, translate, furtherDiagnostic, handleCaseChange}}/>
                    <Deviation {...{
                        classes,
                        translate,
                        deviationComment,
                        deviationType,
                        handleCaseChange,
                        user,
                        rating
                    }}/>
                    <Flex container item justifyContent={'flex-end'}>
                        <Button
                            style={{marginTop: 15, width: 300, backgroundColor: colors.jaffa, color: colors.htmlWhite}}
                            variant={'contained'}
                            onClick={() => save(false)}>
                            {translate('case.save-second-opinion')}
                        </Button>
                        <Button disabled={confirmSecondOpinionDisabled} style={{marginLeft: 15, marginTop: 15, width: 300}} variant={'contained'}
                                onClick={() => {
                                    this.setState({
                                        confirmSecondOpinionDisabled: true,
                                    });
                                    confirmSecondOpinion(businessId).then(() => {
                                        this.setState({
                                            alert: 'success',
                                            confirmSecondOpinionDisabled: false,
                                        });
                                    });
                                }} color="primary">
                            {translate('case.release-second-opinion')}
                        </Button>
                        <EpiAlert
                        {...{
                            isOpen: !!this.state.alert,
                            close: closeSnackbar,
                            severity: this.state.alert,
                        }}
                        message={<><span>{translate('case.second-opinion-pdf-success')}</span><br/><br/>
                        <span>{translate('case.second-opinion-pdf-success-second-line')}</span></>}
                    />
                    </Flex>
                </Flex>
            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(ReleaseSecondOpinion));
