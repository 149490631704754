import React, {Component} from 'react';
import {
    withStyles,
    IconButton,
    Typography,
    TextField,
    InputAdornment,
    MenuItem,
    ListItemText,
    Select,
    Button
} from "@material-ui/core";
import {Autocomplete} from '@material-ui/lab';
import EpiAlert from 'components/alert/EpiAlert';
import Flex from 'components/grid/Flex';
import {
    htmlWhite,
    htmlRed,
    rainForest,
    htmlLightGrey,
    alto,
    htmlOrange,
    persianGreen2
} from 'components/colors/Colors';
import {
    ArrowBackOutlined as BackIcon, Save
} from '@material-ui/icons';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import connector from './CaseEditTitleBar.connect';
import _ from 'lodash';
import moment from 'moment';
import Profile from 'components/profile/Profile';
import utils from 'utils/Utils';
import HmoAvatar from 'components/avatar/HmoAvatar';
import {isCaseManager, isClinic, isPatient} from 'domain/User.model';
import CancelCase from 'scenes/case/edit/component/CancelCase';
import ConnectCaseToProgram from 'scenes/case/edit/component/ConnectCaseToProgram';
import DeleteEmptyCase from 'scenes/case/edit/component/DeleteEmptyCase';
import ConfirmationDialog from 'components/confirmation-dialog/ConfirmationDialog';
import {CANCELLATION} from 'domain/WorkflowStatus';

const styles = theme => ({
    avatar: {
        margin: 0
    },
    inputLabel: {
        textTransform: 'uppercase',
        marginRight: 10,
        color: htmlWhite,
        fontSize: 11
    },
    selectRoot: {
        backgroundColor: 'white',
        padding: '6px !important'
    },
    autoCompleteInput: {
        padding: '6px !important'
    },
    autoCompleteInputRoot: {
        backgroundColor: 'white',
        padding: 'unset !important'
    },
    autocompleteInput: {
        backgroundColor: htmlWhite
    },
    titlePropBase: {
        flex: '0 0 auto',
        flexDirection: 'column',
        display: 'flex',
        marginRight: 30,
        height: 50,
        maxHeight: 50,
        justifyContent: 'center'
    }
});

const PRIORITY_TO_COLOR = {
    LOW: 'black',
    MEDIUM: htmlOrange,
    HIGH: htmlRed
};

export class CaseEditTitleBar extends Component {

    intervalId = null;

    state = {
        isReopenDialogOpen: false,
        currentlyEditingCaseManagers: [],
        currentlyEditingCaseManagersPupUp: false
    };

    componentDidMount() {
        if (isCaseManager(this.props.user)) {
            this.refreshCurrentlyEditingCaseManagers(true);
            this.intervalId = setInterval(() => {
                this.refreshCurrentlyEditingCaseManagers(false);
            }, 1000 * 60);
        }
    }

    componentWillUnmount() {
        if (isCaseManager(this.props.user)) {
            clearInterval(this.intervalId);
            this.intervalId = null;
            this.props.removeCaseEditorMatch(this.props.case.businessId, this.props.user.businessId);
        }
    }

    turnOffCurrentlyEditingCaseManagersPupUp = () => {
        this.setState({
            currentlyEditingCaseManagersPupUp: false
        })
    }

    refreshCurrentlyEditingCaseManagers = (onMount) => {
        this.props.upsertCaseEditorMatch(this.props.case.businessId, this.props.user.businessId);
        this.props.listCaseEditorMatches(this.props.case.businessId).then(response => {
            const managersFromBackend = response.payload.data.map(entry => entry.caseManagerBusinessId);
            const currentlyEditingCaseManagers = this.props.caseManagers
                .filter(cm => managersFromBackend.includes(cm.businessId))
                .filter(cm => this.props.user.businessId !== cm.businessId);
            this.setState({
                currentlyEditingCaseManagers,
                ...(onMount && {currentlyEditingCaseManagersPupUp: currentlyEditingCaseManagers.length > 0})
            });
        });
    }

    openReopenDialog = () => {
        this.setState({isReopenDialogOpen: true});
    }

    closeReopenDialog = () => {
        this.setState({isReopenDialogOpen: false});
    }

    reopenCase = () => {
        this.props.reopenCase(this.props.case.businessId)
            .then(() => {
                this.setState({
                    alert: "saved",
                    isReopenDialogOpen: false
                });
                this.props.updateFilters({
                    isHistoryActive: false
                });
            })
            .catch(() => {
                this.setState({
                    alert: 'error',
                    isReopenDialogOpen: false
                });
            });
    }

    render() {
        const { classes, t: translate, save, user, handleCaseChange, caseManagers, handleCaseManagerChange,
            configuration, deleteEmptyCase, cancelCase, reset, fetchCase
        } = this.props;
        const {openReopenDialog, closeReopenDialog, reopenCase, turnOffCurrentlyEditingCaseManagersPupUp} = this;
        const {isReopenDialogOpen, currentlyEditingCaseManagersPupUp, currentlyEditingCaseManagers} = this.state;
        const {status, priority, caseManagerId, patient} = this.props.case;
        const {priorities} = configuration;
        const selectedCaseManager = caseManagers.find(cm => cm.businessId === caseManagerId);
        const name = utils.formattedUserName(this.props?.case?.patient);
        const age = patient.dateOfBirth ? moment().diff(moment(patient.dateOfBirth), 'years') : '';
        return (
            <Flex container item grow={0} wrap justifyContent={'space-between'} alignItems={'center'} padding={5}
                  style={{backgroundColor: persianGreen2}}>
                <Flex item={'0 1 auto'} container alignItems={"flex-end"} wrap>
                    <Flex item={'0 1 auto'} style={{marginLeft: 4, marginRight: 11, marginBottom: 8}}>
                        {
                            (isCaseManager(user) || isClinic(user)) &&
                            <IconButton
                                onClick={() => {
                                    save(true)
                                }}
                                style={{padding: 3, backgroundColor: 'white'}}
                            >
                                <BackIcon style={{color: rainForest, fontSize: 12}}/>
                            </IconButton>
                        }
                    </Flex>

                    <Flex className={classes.titlePropBase}>
                        <Typography className={classes.inputLabel}
                                    style={{color: alto}}>{translate("case.list-headers.patient-name")}</Typography>
                        <Typography style={{color: htmlWhite, fontSize: 18}}>
                            {name || "-"}
                        </Typography>
                    </Flex>

                    <Flex className={classes.titlePropBase}>
                        <Typography className={classes.inputLabel}
                                    style={{color: alto}}>{translate("global.age")}</Typography>
                        <Typography style={{color: htmlWhite, fontSize: 18}}>
                            {age || "-"}
                        </Typography>
                    </Flex>

                    {
                        status && isCaseManager(user) &&
                        <Flex className={classes.titlePropBase}>
                            <Typography className={classes.inputLabel}
                                        style={{color: alto}}>{translate("global.workflow")}</Typography>
                            <Typography style={{color: htmlWhite, fontSize: 18}}>
                                {translate(`case.status.${status}`)}
                            </Typography>
                        </Flex>
                    }

                </Flex>

                {
                    isCaseManager(user) && caseManagerId &&
                    <Flex item container justifyContent={'flex-end'} style={{marginLeft: 10, marginRight: 11}}>
                        {
                            isCaseManager(user) && !_.isEmpty(priorities) &&
                            <Flex className={classes.titlePropBase} item={'0 1 100px'}>
                                <Typography className={classes.inputLabel}
                                            style={{color: htmlLightGrey}}>{translate("global.priority")}</Typography>
                                <Select
                                    value={priority}
                                    classes={{root: classes.selectRoot}}
                                    onChange={handleCaseChange}
                                    variant={'outlined'}
                                    name={'priority'}
                                    renderValue={value => (
                                        <Typography style={{color: PRIORITY_TO_COLOR[value]}}>
                                            {translate(`global.priorities.${value}`)}
                                        </Typography>
                                    )}
                                >
                                    {
                                        priorities.map(priorityInArray => (
                                            <MenuItem key={priorityInArray} value={priorityInArray}>
                                                <ListItemText primary={translate(`global.priorities.${priorityInArray}`)}/>
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </Flex>
                        }
                        {
                            isCaseManager(user) && caseManagerId &&
                            <Flex className={classes.titlePropBase}>
                                <Typography className={classes.inputLabel}
                                            style={{color: alto}}>{translate("global.case-manager")}</Typography>
                                <Autocomplete
                                    options={caseManagers.sort((managerA, managerB) => managerA.fullName.localeCompare(managerB.fullName))}
                                    onChange={handleCaseManagerChange}
                                    value={selectedCaseManager}
                                    disableClearable
                                    classes={{
                                        input: classes.autoCompleteInput,
                                        inputRoot: classes.autoCompleteInputRoot
                                    }}
                                    style={{width: 250}}
                                    getOptionSelected={(option, value) => {
                                        return option.businessId === value.businessId
                                    }}
                                    getOptionLabel={user => utils.formattedUserName(user)}
                                    renderInput={params =>
                                        <TextField {...params}
                                                   fullWidth
                                                   variant={'outlined'}
                                                   InputProps={{
                                                       ...params.InputProps,
                                                       startAdornment: <InputAdornment position={'end'}
                                                                                       disablePointerEvents>
                                                           <HmoAvatar small user={selectedCaseManager}/>
                                                       </InputAdornment>
                                                   }}
                                        />}
                                    renderOption={user =>
                                        <Flex item container key={user.businessId}>
                                            <HmoAvatar {...{user}} style={{marginRight: 10}}/>
                                            <ListItemText primary={utils.formattedUserName(user)}/>
                                        </Flex>
                                    }
                                />
                            </Flex>
                        }
                        {
                            isCaseManager(user) && !_.isEmpty(currentlyEditingCaseManagers) &&
                            <Flex container item={'0 0 auto'} justifyContent={'flex-end'} column
                                  style={{flexWrap: 'nowrap'}}>
                                <Typography className={classes.inputLabel}
                                            style={{color: alto}}>{translate("global.also-here")}</Typography>
                                <Flex item container style={{marginBottom: 2}}>
                                    {
                                        currentlyEditingCaseManagers.map((caseManager, index) => (
                                            <Flex item={'0 0 auto'} key={index}
                                                  style={{position: 'relative', marginLeft: -7}}>
                                                <HmoAvatar
                                                    {...{user: caseManager}}
                                                    style={{
                                                        margin: 0,
                                                        border: `3px solid ${htmlWhite}`,
                                                        zIndex: caseManagers.length - index,
                                                        width: 34,
                                                        height: 34
                                                    }}
                                                />
                                            </Flex>
                                        ))
                                    }
                                </Flex>
                            </Flex>
                        }
                        <Flex item grow={0} container alignItems={'flex-end'} justifyContent={'space-between'}
                              style={{minWidth: 120}}>
                            {
                                _.isEmpty(name) && status !== CANCELLATION &&
                                <DeleteEmptyCase {...{
                                    isCaseCanceled: status === CANCELLATION,
                                    configuration,
                                    _case: this.props.case,
                                    handleCaseChange,
                                    deleteEmptyCase,
                                    reset
                                }}/>
                            }
                            <ConnectCaseToProgram {...{
                                patientId: patient?.id,
                                caseId: this.props.case?.id,
                                careProgramId: this.props.case?.careProgramId,
                                refreshCase: () => fetchCase(this.props.case?.businessId)
                            }}/>
                            {
                                !_.isEmpty(name) && status !== CANCELLATION &&
                                <CancelCase {...{
                                    isCaseCanceled: status === CANCELLATION,
                                    configuration,
                                    _case: this.props.case,
                                    handleCaseChange,
                                    cancelCase,
                                    reset,
                                }}/>
                            }
                            {
                                status === CANCELLATION &&
                                <Button onClick={openReopenDialog}>{translate('global.reopen')}</Button>
                            }
                            <IconButton style={{padding: 3}} onClick={() => {
                                save(false)
                            }}>
                                <Save style={{fill: 'white'}}/>
                            </IconButton>
                        </Flex>
                    </Flex>
                }
                {
                    (isPatient(user) || isClinic(user)) &&
                    <Profile classes={{avatar: classes.avatar}} positionStyle={{
                        top: 50,
                        right: 400,
                        bottom: 'unset',
                        left: 'unset',
                    }}/>
                }
                <ConfirmationDialog {...{
                    dialogOpen: isReopenDialogOpen,
                    onDialogClose: closeReopenDialog,
                    onConfirm: reopenCase,
                    confirmationTextKey: 'global.reopen-case'
                }}/>
                <EpiAlert
                    {...{
                        autoHideDuration: 5000,
                        isOpen: !!currentlyEditingCaseManagersPupUp,
                        close: turnOffCurrentlyEditingCaseManagersPupUp,
                        severity: 'warning',
                        message: translate(`global.multiple-editing-managers`)
                    }}
                />
            </Flex>
        );
    }
}

export default withStyles(styles)(
    connect(connector.mapStateToProps, connector.mapDispatchToProps)(
        withTranslation()(CaseEditTitleBar)
    )
);
