import {FormControlLabel, Checkbox} from "@material-ui/core";
import {
    useCreatePatientAgreementMutation,
    useDeletePatientAgreementByIdMutation,
    useFetchPatientAgreementsQuery,
} from "./PatientApi";
import {useTranslation} from "react-i18next";

const PatientAgreements = ({patientId}) => {
    const {t} = useTranslation();
    const {data: patientData, isFetching} = useFetchPatientAgreementsQuery(patientId);
    const [createNewAgreement, {isLoading: isCreating}] = useCreatePatientAgreementMutation();
    const [deleteAgreement, {isLoading: isDeleting}] = useDeletePatientAgreementByIdMutation();
    const isAgreedToProvideBioSamples = !!patientData?.find((item) => item.agreement === "BIO_SAMPLES");
    const isAgreedToProvideForStudy = !!patientData?.find((item) => item.agreement === "STUDY");
    const toggleAgreedToAgreement = (type) => {
        const isAlreadyAgreed = !!patientData?.find((item) => item.agreement === type);
        if (isAlreadyAgreed) {
            const agreementId = patientData?.find((item) => item.agreement === type)?.id;
            agreementId && deleteAgreement(agreementId);
        } else {
            createNewAgreement({userDetailsId: patientId, agreement: type});
        }
    };
    return <>
        <FormControlLabel
                control={
                    <Checkbox
                            disabled={isFetching || isDeleting || isCreating}
                            checked={isAgreedToProvideBioSamples}
                            onChange={() => toggleAgreedToAgreement("BIO_SAMPLES")}
                            color="primary"
                    />
                }
                label={t("agreements.BIO_SAMPLES")}
        />

        <FormControlLabel
                control={
                    <Checkbox
                            disabled={isFetching || isDeleting || isCreating}
                            checked={isAgreedToProvideForStudy}
                            onChange={() => toggleAgreedToAgreement("STUDY")}
                            color="primary"
                    />
                }
                label={t("agreements.STUDY")}
        />
    </>;
};

export default PatientAgreements;
