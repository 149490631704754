/**
 * 0. NEU
 * @type {string}
 */
export const NEW = "NEW";

/**
 * In Aufnahme
 * 1. Patient annehmen
 * @type {string}
 */
export const REGISTER_PATIENT = "REGISTER_PATIENT";

/**
 * In Aufnahme
 * 2. Kostenübernahme klären
 * @type {string}
 */
export const CHECK_FOR_COST_COVERAGE = "CHECK_FOR_COST_COVERAGE";

/**
 * In Aufnahme
 * 3. Entscheidung abfragen
 * @type {string}
 */
export const WAIT_FOR_DECISION = "WAIT_FOR_DECISION";

/**
 * Unterlagen erwartet
 * 4. Warten auf TNE
 * @type {string}
 */
export const WAIT_FOR_GDPR_CONSENT_FORM = "WAIT_FOR_GDPR_CONSENT_FORM";

/**
 * Unterlagen erwartet
 * 5. Warten auf Geldeingang
 * @type {string}
 */
export const WAIT_FOR_PAYMENT = "WAIT_FOR_PAYMENT";

/**
 * Unterlagen erwartet
 * 6. Fehlende Unterlagen anfordern
 * @type {string}
 */
export const ASK_FOR_MISSING_DOCUMENTS = "ASK_FOR_MISSING_DOCUMENTS";

/**
 * Fall dokumentieren
 * 7. Unterlagen prüfen
 * @type {string}
 */
export const CHECK_DOCUMENTS = "CHECK_DOCUMENTS";

/**
 * Fall dokumentieren
 * 8. Fall für Experten vorbereiten
 * @type {string}
 */
export const PREPARE_CASE_FOR_EXPERT = "PREPARE_CASE_FOR_EXPERT";

/**
 * Fall dokumentieren
 * 9. Anfrage an Experten stellen
 * @type {string}
 */
export const REQUEST_EXPERT_TO_TAKE_OVER_CASE = "REQUEST_EXPERT_TO_TAKE_OVER_CASE";

/**
 * Experte liegt Fall vor
 * 10. Fall noch nicht von Experte bearbeitet
 * @type {string}
 */
export const CASE_NOT_REVIEWED_BY_EXPERT = "CASE_NOT_REVIEWED_BY_EXPERT";

/**
 * Experte liegt Fall vor
 * 11.Fall in Bearbeitung durch Expert
 * @type {string}
 */
export const CASE_UNDER_REVIEW_BY_EXPERT = "CASE_UNDER_REVIEW_BY_EXPERT";

/**
 * ZM übermitteln
 * 12. ZM als PDF generieren
 * @type {string}
 */
export const GENERATE_REPORT_OF_THE_SECOND_OPINION = "GENERATE_REPORT_OF_THE_SECOND_OPINION";

/**
 * ZM übermitteln
 * 13. ZM an Patienten übermitteln
 * @type {string}
 */
export const CALL_PATIENT_TO_TELL_THE_RESULT = "CALL_PATIENT_TO_TELL_THE_RESULT";

/**
 * Abrechnung
 * 14. Mit Kostenträger abrechnen
 * @type {string}
 */
export const INVOICE_COST_COVERAGE_INSTANCE = "INVOICE_COST_COVERAGE_INSTANCE";

/**
 * Abrechnung
 * 15. Mit Experten abrechnen
 * @type {string}
 */
export const NOTIFY_EXPERT_TO_SEND_INVOICE = "NOTIFY_EXPERT_TO_SEND_INVOICE";

/**
 * Nachbearbeitung
 * 16. Nachfrage an Experten
 * @type {string}
 */
export const FURTHER_QUESTIONS_FOR_THE_EXPERTS = "FURTHER_QUESTIONS_FOR_THE_EXPERTS";

/**
 * Nachbearbeitung
 * 17. Feedback abfragen
 * @type {string}
 */
export const GET_FEEDBACK_FROM_PATIENT = "GET_FEEDBACK_FROM_PATIENT";

/**
 * Nachbearbeitung
 * 18. Studienbefragung
 * @type {string}
 */
export const STUDY_QUESTIONNAIRE = "STUDY_QUESTIONNAIRE";

/**
 * 19. CANCELLATION
 * @type {string}
 */
export const CANCELLATION = "CANCELLATION";

/**
 * 20. Done
 * @type {string}
 */
export const DONE = "DONE";
