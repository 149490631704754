import React, { Component } from 'react';
import { Typography } from "@material-ui/core";
import Flex from 'components/grid/Flex';

export class CounterCircle extends Component {
    render() {
        const {color, count, style} = this.props;

        return (
            <Flex item grow={0} container center
                  style={{marginLeft: 5,
                      backgroundColor: color || 'rgb(112,48,160)',
                      borderRadius: '50%',
                      maxHeight: 18,
                      minHeight: 18,
                      minWidth: 18,
                      ...style}}>
                <Typography style={{color: 'white', fontSize: 12}}>{count}</Typography>
            </Flex>
        );
    }
}

export default CounterCircle;
