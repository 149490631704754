import {
    reset, deleteEmptyCase, reopenCase, listCaseEditorMatches, upsertCaseEditorMatch, removeCaseEditorMatch
} from 'scenes/case/edit/CaseEdit.action';
import {updateFilters} from 'scenes/case/Case.action';

export default {
    mapStateToProps: (state) => {
        return {
            user: state.mainReducer.user,
            configuration: state.caseReducer.configuration,
            caseManagers: state.caseReducer.caseManagers,
            case: state.caseEditReducer.case,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            updateFilters: update => dispatch(updateFilters(update)),
            reset: () => dispatch(reset()),
            deleteEmptyCase: caseBusinessId => dispatch(deleteEmptyCase(caseBusinessId)),
            reopenCase: caseBusinessId => dispatch(reopenCase(caseBusinessId)),
            listCaseEditorMatches: caseBusinessId => dispatch(listCaseEditorMatches(caseBusinessId)),
            upsertCaseEditorMatch: (caseBusinessId, caseManagerBusinessId) => dispatch(upsertCaseEditorMatch(caseBusinessId, caseManagerBusinessId)),
            removeCaseEditorMatch: (caseBusinessId, caseManagerBusinessId) => dispatch(removeCaseEditorMatch(caseBusinessId, caseManagerBusinessId)),
        };
    }
};
