import React, {Component} from 'react';
import {Typography, withStyles, Accordion, AccordionSummary, AccordionDetails} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import {concrete, htmlLightGrey, persianGreen, grey153} from 'components/colors/Colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {connect} from 'react-redux';
import connector from './CaseList.connect';
import CaseRow from './component/CaseRow';
import CaseRowHeaders from './component/CaseRowHeaders';
import Filters from './component/Filters';
import _ from 'lodash';
import {withNavigation} from 'utils/Route.utils';
import Sidebar from 'components/sidebar/Sidebar';
import CounterCircle from 'components/counter/CounterCircle';
import Title from './component/Title';
import History from './component/History';

const styles = theme => ({
    expansionGroupStatusTitle: {
        fontWeight: 'bold',
        fontSize: 15,
        color: 'black'
    },
    expansionStatusTitle: {
        color: persianGreen
    },
    expansionStatusSummary: {
        backgroundColor: concrete,
    },
    expansionStatusSummaryContent: {
        '&.Mui-expanded': {
            borderBottom: `1px solid ${htmlLightGrey}`
        }
    },
    expansionPanelDetails: {
        backgroundColor: concrete,
        padding: 5,
        display: 'flex',
        flexDirection: 'column'
    },
    expansionPanelStatusDetails: {
        backgroundColor: concrete,
        padding: 'unset',
        paddingLeft: 24,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto'
    },
    expansionPanelRoot: {
        boxShadow: 'unset',
        border: 'unset',
        '&.Mui-expanded': {
            margin: 'unset',
        },
        '&.Mui-disabled': {
            opacity: 0.5,
            backgroundColor: 'unset'
        },
        '&:before': {
            backgroundColor: 'unset'
        }
    },
    accordionSummary: {
        flexDirection: 'row-reverse',
        padding: 0,
    },
    expandIcon: {
        padding: 0,
        margin: 0
    }
});

const StatusGroupPanel = (props) => {
    const {
        classes, translate, groupId, statuses, cases, handleClick, handleDoubleClick, isExpanded, togglePanel,
        expandedPanels, toggleTagSelection, selectedTags, isAllExpanded, caseManagers, sortBy, byStatusProperty,
        statusSortMap
    } = props;
    const statusesInGroup = statuses.filter(status => status.groupId === groupId);
    const count = cases.filter(
            caseInArray => statusesInGroup.map(status => status.id).includes(caseInArray.status)
    ).length;
    return (
            <Accordion
                    disabled={count < 1}
                    classes={{root: classes.expansionPanelRoot}}
                    expanded={isExpanded && count > 0}
                    onChange={togglePanel(groupId)}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon/>} className={classes.accordionSummary}
                                  classes={{expandIcon: classes.expandIcon}}
                                  style={{
                                      height: count > 0 ? 45 : 30,
                                      minHeight: count > 0 ? 45 : 30
                                  }}>
                    <Flex container item alignItems={'center'}>
                        <Typography className={classes.expansionGroupStatusTitle}>
                            {translate(`case.statusGroup.${groupId}`)}
                        </Typography>
                        {
                            count > 0 &&
                            <CounterCircle {...{count, color: grey153}}/>
                        }
                    </Flex>
                </AccordionSummary>
                <AccordionDetails className={classes.expansionPanelDetails}>
                    {
                        count > 0 && statusesInGroup
                                .map(status => (
                                        <StatusPanel
                                                key={status.groupId + status.id}
                                                {...{
                                                    classes,
                                                    translate,
                                                    cases,
                                                    status,
                                                    isExpanded: (isExpanded
                                                            && expandedPanels.includes(status.groupId + status.id)) || isAllExpanded,
                                                    selectedTags,
                                                    handleClick,
                                                    handleDoubleClick,
                                                    togglePanel,
                                                    toggleTagSelection,
                                                    caseManagers,
                                                    sortBy,
                                                    byStatusProperty,
                                                    statusSortMap
                                                }}
                                        />
                                ))
                    }
                </AccordionDetails>
            </Accordion>
    );
};

const StatusPanel = (props) => {
    const {
        classes, translate, status, cases, handleDoubleClick, handleClick, isExpanded, togglePanel, toggleTagSelection,
        selectedTags, caseManagers, sortBy, byStatusProperty, statusSortMap
    } = props;
    const count = cases.filter(
            caseInArray => status.id === caseInArray.status
    ).length;
    return (
            <Accordion
                    disabled={count < 1}
                    expanded={isExpanded && count > 0}
                    onChange={togglePanel(status.groupId + status.id)}
                    classes={{root: classes.expansionPanelRoot}}
            >
                <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        style={{
                            height: count > 0 ? 35 : 25,
                            minHeight: count > 0 ? 35 : 25
                        }}
                        className={classes.accordionSummary}
                        classes={{
                            expandIcon: classes.expandIcon,
                            root: classes.expansionStatusSummary,
                            content: classes.expansionStatusSummaryContent,
                        }}
                >
                    <Typography className={classes.expansionStatusTitle}>
                        {translate(`case.status.${status.id}`)}
                    </Typography>
                    {
                        count > 0 &&
                        <CounterCircle {...{count, color: grey153}}/>
                    }
                </AccordionSummary>
                <AccordionDetails className={classes.expansionPanelStatusDetails}>
                    <table style={{tableLayout: 'auto'}}>
                        <tbody>
                        <CaseRowHeaders status={status.id} sortBy={sortBy} {...{...(statusSortMap[status.id] || {})}}/>
                        {
                            cases
                                    .filter(caseInArray => caseInArray.status === status.id)
                                    .sort(byStatusProperty(status.id))
                                    .map(caseInArray => (
                                            <CaseRow
                                                    key={caseInArray.businessId}
                                                    {...{
                                                        handleDoubleClick,
                                                        handleClick,
                                                        case: caseInArray,
                                                        toggleTagSelection,
                                                        selectedTags,
                                                        caseManagers
                                                    }}/>
                                    ))
                        }
                        </tbody>
                    </table>
                </AccordionDetails>
            </Accordion>
    );
};

export class CaseListForManager extends Component {

    render() {
        const {
            classes, t: translate, user, selectedCaseManagerIds, searchText, selectedTags, tagSuggestions, caseManagers,
            caseManagerSuggestions, tagFilterRelation, filteredCases, isFilterActive, expandedPanels, reminderOnly,
            isHistoryActive, handleDoubleClick, handleClick, create, loggedInUsers, handleSearchTextChange, statuses,
            resetSearchText, toggleTagSelection, toggleSelectedCaseManagerId, handleTagFilterRelationChange,
            resetSelectedTags, togglePanel, setHistory, toggleReminderOnly, notCancelledCases, filteredStatusGroups,
            isAllExpanded, sortBy, byStatusProperty, statusSortMap, fetchCaseManagers
        } = this.props;
        return <>
            <Sidebar/>
            <Flex container item column
                  style={{margin: 25, marginLeft: 80, marginTop: 60}}
            >
                <Title {...{user, fetchCaseManagers, caseManagers}}/>
                <Filters {...{
                    user, searchText, tagFilterRelation, tagSuggestions, caseManagerSuggestions, selectedTags,
                    selectedCaseManagerIds, reminderOnly, isHistoryActive, setHistory, toggleReminderOnly,
                    handleSearchTextChange, resetSearchText, resetSelectedTags, toggleTagSelection,
                    toggleSelectedCaseManagerId, handleTagFilterRelationChange, loggedInUsers, create,
                    fetchCaseManagers
                }}/>
                <Flex item children style={{marginTop: 10}}>
                    {
                        isFilterActive && _.isEmpty(filteredCases) &&
                        <Typography variant={'h5'} style={{
                            color: htmlLightGrey,
                            textAlign: 'center'
                        }}>
                            {translate('case.empty-list')}
                        </Typography>
                    }
                    {
                        isHistoryActive &&
                        <History
                                {...{
                                    filteredCases, selectedTags, handleDoubleClick, handleClick,
                                    toggleTagSelection, caseManagers, sortBy, statusSortMap
                                }}
                        />
                    }
                    {
                        !isHistoryActive && filteredStatusGroups.map(groupId =>
                                <StatusGroupPanel
                                        key={groupId}
                                        {...{
                                            classes, translate, groupId, statuses, isFilterActive, expandedPanels,
                                            selectedTags, handleDoubleClick, handleClick, togglePanel, statusSortMap,
                                            toggleTagSelection, isAllExpanded, caseManagers, sortBy, byStatusProperty,
                                            isExpanded: expandedPanels.includes(groupId) || isAllExpanded,
                                            cases: isFilterActive ? filteredCases : notCancelledCases,
                                        }} />
                        )
                    }
                </Flex>
            </Flex>
        </>
    }
}

export default withStyles(styles)(
        connect(connector.mapStateToProps, connector.mapDispatchToProps)(
                withTranslation()(
                        withNavigation(CaseListForManager)
                )
        )
);
