import React, {Component} from 'react';
import {Typography, IconButton} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import Accordion from 'components/grid/Accordion';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {FULL_PATIENT} from 'domain/EntityAuthorizationType.model';
import moment from 'moment/min/moment-with-locales';
import 'moment/locale/de';

export class InvitedContactPerson extends Component {

    render() {
        const {invite = {}, t: translate, i18n, onDelete} = this.props;
        const { invitedEmail, invitedName, inviteToken, entityType, creationDate } = invite;
        const formattedCreationDate = moment(creationDate).locale(i18n.language).format('DD. MMM YYYY, H:mm')
        return (
                <Accordion
                        key={inviteToken}
                        accordionOpen={false}
                        containerStyle={{
                            boxShadow: "unset",
                            border: "1px solid orange",
                            borderRadius: 10,
                        }}
                        style={{
                            borderRadius: 10,
                            backgroundColor: "white",
                        }}
                        titleComponent={
                            <Flex item style={{alignItems: "center"}} container
                                  justifyContent={"space-between"}>
                                <Flex item container column>
                                    <Typography style={{fontWeight: "bold"}}>
                                        {invitedName ? invitedName : invitedEmail}
                                    </Typography>
                                    <Flex item container justifyContent={'space-between'}>
                                        <Typography style={{fontSize: 11}}>
                                            {
                                                entityType === FULL_PATIENT
                                                        ? translate(`access-level.top`)
                                                        : translate(`access-level.program`)
                                            }
                                        </Typography>
                                        <Typography style={{fontSize: 11}}>
                                            {translate('global.not-yet-accepted')}
                                        </Typography>
                                    </Flex>
                                </Flex>
                                <IconButton
                                        size="small"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onDelete(e);
                                        }}
                                >
                                    <DeleteOutlineIcon style={{fontSize: "21px"}}/>
                                </IconButton>
                            </Flex>
                        }
                >
                    <div style={{marginLeft: 34}}>
                        <div>E-Mail: {invitedEmail}</div>
                        <div style={{fontSize: 12}}>{translate('global.invited')} {formattedCreationDate  }</div>
                    </div>
                </Accordion>
        );
    }
}

export default withTranslation()(InvitedContactPerson);
