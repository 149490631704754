import {listCases, updateFilters, updatePanels, acceptCase, fetchCaseManagers} from 'scenes/case/Case.action';
import {fetchNewCase} from 'scenes/case/edit/CaseEdit.action';
import {reset} from 'scenes/case/edit/CaseEdit.action';
export default {
    mapStateToProps: (state) => {
        return {
            user: state.mainReducer.user,
            isConfigurationLoaded: state.mainReducer.isConfigurationLoaded,
            workflowStatuses: state.caseReducer.configuration.workflowStatuses || [],
            cases: state.caseReducer.cases,
            filteredCases: state.caseReducer.filteredCases,
            isFilterActive: state.caseReducer.isFilterActive,
            expandedPanels: state.caseReducer.expandedPanels,
            selectedCaseManagerIds: state.caseReducer.filters.selectedCaseManagerIds,
            searchText: state.caseReducer.filters.searchText,
            selectedTags: state.caseReducer.filters.selectedTags,
            tagFilterRelation: state.caseReducer.filters.tagFilterRelation,
            reminderOnly: state.caseReducer.filters.reminderOnly,
            isHistoryActive: state.caseReducer.filters.isHistoryActive,
            tagSuggestions: state.caseReducer.tagSuggestions,
            caseManagerSuggestions: state.caseReducer.caseManagerSuggestions,
            caseManagers: state.caseReducer.caseManagers,
            websocketClient: state.socketReducer.client,
            websocketClientConnected: state.socketReducer.connected,
            lastCaseListRefresh: state.caseReducer.lastCaseListRefresh,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            fetchNewCase: () => dispatch(fetchNewCase()),
            acceptCase: caseInput => dispatch(acceptCase(caseInput)),
            listCases: () => dispatch(listCases()),
            updateFilters: filters => dispatch(updateFilters(filters)),
            updatePanels: panels => dispatch(updatePanels(panels)),
            fetchCaseManagers: () => dispatch(fetchCaseManagers()),
            reset: () => dispatch(reset())
        };
    }
};
