import { useState } from "react";
import {
    Modal,
    Grid,
    Card,
    Box,
    CardContent,
    CardHeader,
    IconButton,
    Button,
    CardActions,
    FormGroup,
    FormControlLabel,
    Typography,
    CircularProgress,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { Checkbox } from "@material-ui/core";

const DeleteContactModalContent = ({ onClose, onSubmit, contact, loadAccesses, accesses, accessesLoading }) => {
        const { t } = useTranslation();
        const [checkedItems, setCheckedItems] = useState([]);
        const onCheckboxChangeHandler = (e, isChecked) => {
            if (e.target.value === "") return;
            if (isChecked) {
                setCheckedItems([...checkedItems, e.target.value]);
            } else {
                setCheckedItems(checkedItems.filter((item) => item !== e.target.value));
            }
        };
        useEffect(() => {
            loadAccesses(contact.id);
        }, []);

        const isReadyToSubmit = checkedItems.length > 0;
        return (
                <Box mt={"40vh"}>
                    <Grid container justifyContent="center">
                        <Grid item xs={11} sm={10} md={8} lg={4} xl={3}>
                            <Card>
                                <CardHeader
                                    title={t("global.remove-contact-rights")}
                                    action={
                                        <>
                                            <IconButton
                                                onClick={() => {
                                                    onClose();
                                                }}
                                                size="small"
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </>
                                    }
                                />
                                <CardContent>
                                    {accessesLoading ? (
                                        <Box display={"flex"} justifyContent={"center"}>
                                            <CircularProgress />
                                        </Box>
                                    ) : (
                                        <>
                                            <Typography style={{ display: "inline-block", marginBottom: 10 }}>
                                                {t("global.which-rights-to-remove-1")}
                                                <span style={{ fontWeight: "bold" }}>
                                                    {" "}
                                                    {contact.givenName} {contact.familyName}
                                                </span>{" "}
                                                {t("global.which-rights-to-remove-2")}
                                            </Typography>
                                            <Box>
                                                <FormGroup column>
                                                    {accesses.map((access) => (
                                                        <FormControlLabel
                                                            style={{ marginLeft: -6 }}
                                                            value={access.accessAssignmentId}
                                                            control={<Checkbox style={{ padding: 4 }} />}
                                                            label={
                                                                access.entityType === "FULL_PATIENT"
                                                                    ? t("global.FULL_PATIENT_DATA")
                                                                    : access.description
                                                            }
                                                            labelPlacement="end"
                                                            name={access.description}
                                                            onChange={onCheckboxChangeHandler}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            </Box>
                                        </>
                                    )}
                                </CardContent>
                                <CardActions>
                                    <Box marginLeft={"auto"} display={"flex"} style={{ gap: "8px" }}>
                                        <Button onClick={onClose} variant="contained">
                                            {t("global.cancel")}
                                        </Button>
                                        <Button
                                            disabled={!isReadyToSubmit}
                                            onClick={() => {
                                                onSubmit(checkedItems);
                                            }}
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                        >
                                            {t("global.delete")}
                                        </Button>
                                    </Box>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
        );
    }
;

export const DeleteContactModal = ({ open, ...params }) => {
    return (
        <Modal open={open}>
            <DeleteContactModalContent {...params} />
        </Modal>
    );
};
