export const LOAD_ACCESS_TOKEN = '@main/LOAD_ACCESS_TOKEN';
export const LOAD_REFRESH_TOKEN = '@main/LOAD_REFRESH_TOKEN';

export const LOGIN = '@main/LOGIN';
export const LOGIN_SUCCESS = '@main/LOGIN_SUCCESS';
export const LOGIN_FAIL = '@main/LOGIN_FAIL';

export const SILENT_LOGIN = '@main/SILENT_LOGIN';
export const SILENT_LOGIN_SUCCESS = '@main/SILENT_LOGIN_SUCCESS';
export const SILENT_LOGIN_FAIL = '@main/SILENT_LOGIN_FAIL';

export const REQUEST_NEW_PASSWORD = '@main/REQUEST_NEW_PASSWORD';
export const REQUEST_NEW_PASSWORD_SUCCESS = '@main/REQUEST_NEW_PASSWORD_SUCCESS';
export const REQUEST_NEW_PASSWORD_FAIL = '@main/REQUEST_NEW_PASSWORD_FAIL';

export const SAVE_NEW_PASSWORD = '@main/SAVE_NEW_PASSWORD';
export const SAVE_NEW_PASSWORD_SUCCESS = '@main/SAVE_NEW_PASSWORD_SUCCESS';
export const SAVE_NEW_PASSWORD_FAIL = '@main/SAVE_NEW_PASSWORD_FAIL';

export const LOGOUT = '@main/LOGOUT';
export const LOGOUT_SUCCESS = '@main/LOGOUT_SUCCESS';
export const LOGOUT_FAIL = '@main/LOGOUT_FAIL';

export const HEART_BEAT = '@main/HEART_BEAT';
export const HEART_BEAT_SUCCESS = '@main/HEART_BEAT_SUCCESS';
export const HEART_BEAT_FAIL = '@main/HEART_BEAT_FAIL';

export const LOAD_SETUP = '@main/LOAD_SETUP';
export const LOAD_SETUP_DONE = '@main/LOAD_SETUP_DONE';

export const BLOCK_APP_SCREEN = '@main/BLOCK_APP_SCREEN';

export const TOGGLE_SHOW_APP_WALKTHROUGH = '@main/TOGGLE_SHOW_APP_WALKTHROUGH';

export const SET_APP_INTRO_WALKTHROUGH_COMPLETED  = '@main/SET_APP_INTRO_WALKTHROUGH_COMPLETED';

export const SET_SHOW_MEDICATION_PLAN_WALKTHROUGH = '@main/SET_SHOW_MEDICATION_PLAN_WALKTHROUGH';

export const SET_SHOW_NEW_MEDICATION_WALKTHROUGH = '@main/SET_SHOW_NEW_MEDICATION_WALKTHROUGH';

export const SET_MEDICATION_LIST_WALKTHROUGH_COMPLETED = '@main/SET_MEDICATION_LIST_WALKTHROUGH_COMPLETED';

export const SET_NEW_MEDICATION_BTN_HIGHLIGHT = '@main/SET_NEW_MEDICATION_BTN_HIGHLIGHT';

export const SET_HANDLED_OUTSTANDING_INVITES = '@main/SET_HANDLED_OUTSTANDING_INVITES';
