import Flex from 'components/grid/Flex';
import {Typography, Button} from '@material-ui/core';
import { useTranslation } from "react-i18next";

const DefaultDialogBody = (props) => {
    const {
        information, toggleOpen, disabled, save, saveButtonLabel, deleteEmail, originalEmail, existingUserString
    } = props;
    const {t: translate} = useTranslation();
    return (
            <Flex item container column>
                {
                    information &&
                    <Typography style={{
                        border: '1px solid black',
                        borderRadius: 6,
                        margin: '10px 0px',
                        padding: 5,
                        backgroundColor: 'white',
                        fontSize: 16
                    }}>
                        {information}
                        {
                            existingUserString &&
                            <>
                                <br/>
                                <b>{existingUserString}</b>
                            </>
                        }
                    </Typography>
                }

                {props.children}

                <Flex item container justifyContent={'space-between'}>
                    <Button variant={'outlined'} size={'small'} style={{margin: 5}}
                            onClick={toggleOpen}>
                        {translate('global.close')}
                    </Button>
                    {
                        deleteEmail &&
                        <Button variant={'outlined'} size={'small'} style={{margin: 5}}
                                disabled={!originalEmail}
                                onClick={deleteEmail}>
                            {translate('global.delete')}
                        </Button>
                    }
                    <Button color={'secondary'} variant={'contained'} size={'small'} style={{margin: 5}}
                            data-testid="email-editor-save-button"
                            disabled={disabled}
                            onClick={save}>
                        {saveButtonLabel || translate('global.save')}
                    </Button>
                </Flex>
            </Flex>
    );
};

export default DefaultDialogBody;
