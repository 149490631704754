import React, {Component} from 'react';
import {
    withStyles,
    Typography,
} from "@material-ui/core";
import {
    transparentBlack054,
} from 'components/colors/Colors';

const styles = theme => ({
    label: {
        textTransform: 'uppercase',
        color: transparentBlack054,
        fontSize: 12
    },
    labelSmall: {
        color: transparentBlack054,
        fontSize: 12
    },
    requiredAsterisk: {
        position: 'relative',
        top: '-3px',
        left: '-1px'
    }

});

export class HmoInputLabel extends Component {
    render() {
        const {classes, children, required, small, ...other} = this.props;
        return <Typography className={small ? classes.labelSmall : classes.label} {...other}>{children} {required ? <span className={classes.requiredAsterisk}>*</span>: ''}</Typography>;
    }
}

export default withStyles(styles)(HmoInputLabel);
