import Flex from 'components/grid/Flex';
import {Button, Dialog, Typography} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import {FULL_PATIENT} from "domain/EntityAuthorizationType.model";
import utils from "utils/Utils";
import EyeIcon from "@material-ui/icons/VisibilitySharp";
import DefaultTextField from "components/hmo-textfield/DefaultTextField";
import React, {useState} from "react";
import moment from 'moment';
import EmailAddressEditorDialog from "./EmailAddressEditorDialog";
import {GRANT} from "domain/GrantType";
import {useEditInviteMutation} from "scenes/patient/PatientApi";
import {useEnqueueError, useEnqueueSuccess} from "components/alert/SnackbarHooks";

const InvitationEditor = props => {
    const {patient, invite, excludedUserIds} = props;
    const {t: translate} = useTranslation();
    const stopPropagation = (event) => event.stopPropagation();
    const [emailAddress, setEmailAddress] = React.useState(invite.invitedEmail);
    const [phoneNumber, setPhoneNumber] = React.useState(invite.invitedPhoneNumber);
    const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => {
        setIsOpen(!isOpen);
    }
    const [editInvite] = useEditInviteMutation();
    const enqueueError = useEnqueueError();
    const enqueueSuccess = useEnqueueSuccess();
    const onEditInvite = () => {
        editInvite({id: invite.id, invitedEmail: emailAddress, invitedPhoneNumber: phoneNumber})
                .unwrap()
                .then(() => {
                    enqueueSuccess();
                })
                .catch((error) => {
                    enqueueError();
                })
                .finally(() => {
                    toggleOpen();
                });
    }
    const onEmailChange = (email) => {
        setEmailAddress(email);
    };

    const toggleEmailEditorOpen = () => {
        setIsEmailDialogOpen(!isEmailDialogOpen);
    };

    return <>
        {
                !isOpen &&
                <Button size={"small"}
                        color="primary"
                        variant="contained" onClick={toggleOpen}>
                    {translate('access.check-invite')}
                </Button>
        }
        {
                isOpen &&
                <Dialog open={true} maxWidth={"sm"} onClick={stopPropagation}>
                    <Flex item container column padding={20} style={{minWidth: 400, backgroundColor: "#f8f8fa"}}>
                        <Typography>
                            {translate(`global.patient`)}: &nbsp; {utils.formattedUserName(patient)}
                        </Typography>
                        <Flex id={"TITLE"} item container alignItems={"center"} justifyContent={"space-between"}>
                            <Typography variant={"h6"}>
                                {translate("access.invitation-sent-title")}
                            </Typography>
                            <EyeIcon/>
                        </Flex>

                        <Flex id={"DESCRIPTION"} item container column style={{marginBottom: 10}}>
                            <Typography>
                                {translate("access.invitation-sent-description")}
                            </Typography>
                            <Typography style={{marginTop: 10}}>
                                {translate("access.invitation-sent-datum")}:&nbsp;{moment(invite.creationDate).format("DD-MMMM-YYYY")}
                            </Typography>
                            <Typography style={{marginBottom: 10}}>
                                {translate("access.invitation-sent-time")}:&nbsp;{moment(invite.creationDate).format("HH:mm")}
                            </Typography>
                            <Typography>
                                {translate("access.invitation-sent-email")}
                            </Typography>
                        </Flex>

                        <DefaultTextField
                                value={emailAddress || ""}
                                label={translate("case.email")}
                                style={{marginRight: 0, marginBottom: 10, backgroundColor: "white"}}
                                name={"emailAddress"}
                                onClick={toggleEmailEditorOpen}
                                onChange={toggleEmailEditorOpen}
                        />

                        <DefaultTextField
                                value={phoneNumber || ""}
                                label={translate("case.phone")}
                                helperText={translate("global.invite-phone-number")}
                                error={!phoneNumber}
                                style={{marginRight: 0, backgroundColor: "white"}}
                                name={"phoneNumber"}
                                onChange={(event) => setPhoneNumber(event.target.value)}
                        />

                        <Typography style={{color: "orange", padding: 10, fontSize: 14}}>
                            {translate("access.invitation-sent-warning")}
                        </Typography>

                        <Flex item container justifyContent={"space-between"}>
                            <Button variant={"outlined"} size={"small"} style={{margin: 5}}
                                    onClick={toggleOpen}>
                                {translate("global.close")}
                            </Button>

                            <Button
                                    color={"secondary"}
                                    variant={"contained"}
                                    size={"small"}
                                    style={{margin: 5}}
                                    onClick={onEditInvite}
                            >
                                {translate("access.invite-again")}
                            </Button>
                        </Flex>
                    </Flex>

                    {
                            isEmailDialogOpen &&
                            <EmailAddressEditorDialog {...{
                                title: translate('global.select-email'),
                                editorType: GRANT,
                                invitedRoleType: "PATIENT",
                                person: patient,
                                patient,
                                entityType: FULL_PATIENT,
                                toggleOpen: toggleEmailEditorOpen,
                                onEmailChange: email => {
                                    onEmailChange(email);
                                    toggleEmailEditorOpen();
                                },
                                originalEmail: invite.invitedEmail,
                                excludedUserIds
                            }}/>
                    }
                </Dialog>
        }
    </>;
};

export default InvitationEditor;
