import React, {Component} from 'react';
import {
    MenuItem,
    ListItemText,
} from "@material-ui/core";
import DefaultTextField from 'components/hmo-textfield/DefaultTextField';

export class InsuranceCompany extends Component {

    handleInsuranceCompanyChange = event => {
        let updateCase = {
            insuranceCompanyId: event.target.value.insuranceCompanyId,
            insuranceCompanyRegionalOffice: '',
            insuranceNumber: ''
        };
        if(event.target.value.insuranceCompanyId !== '0028' && event.target.value.insuranceCompanyId !== '0029') {
            updateCase = {...updateCase, otherInsurance: ''}
        }
        this.props.updateCase(updateCase)
    };

    render() {
        const {translate, insuranceCompanyId, insuranceCompanies, isEditDisabled} = this.props;
        const insuranceCompany = insuranceCompanies.find(company => company.insuranceCompanyId === insuranceCompanyId) || '';
        return (
            <DefaultTextField label={translate("case.insurance-company")}
                              select
                              error={!insuranceCompany}
                              disabled={isEditDisabled}
                              SelectProps={{
                                  value: insuranceCompany,
                                  onChange: this.handleInsuranceCompanyChange,
                                  name: 'insuranceCompany'
                              }}>
                {insuranceCompanies && insuranceCompanies.map(company => (
                    <MenuItem key={company.name} value={company}>
                        <ListItemText primary={company.name}/>
                    </MenuItem>
                ))}
            </DefaultTextField>
        )
    }
}

export default InsuranceCompany;
