import HMOApi, {
    Patient,
    UserDetails,
    PatientInvitedEmails,
    AGREEMENT,
    PatientCareProviders,
    PatientList,
} from "store/api/HMOApi";

const BASE_URL = "/patient";

export const PatientApi = HMOApi.injectEndpoints({
    endpoints: (build) => ({
        listPatients: build.query({
            query: () => ({
                url: "program/patients",
                method: "GET",
            }),
            transformResponse: (response) => {
                const patients = response.map(({ patient, ...other }) => ({
                    ...patient,
                    ...other,
                }));
                const programNamesFromPatients = [...new Set(patients.flatMap((item) => item?.programs?.split(",")))];
                return {
                    patients,
                    programNamesFromPatients,
                };
            },
            providesTags: ["PatientList"],
        }),
        fetchPatientAsOption: build.query({
            query: (params) => ({ url: BASE_URL + "/page-query", params }),
            transformResponse: (response) => {
                return response.content.map((item) => ({
                    value: item.id,
                    label: `${item.givenName} ${item.familyName}`,
                }));
            },
            providesTags: [{ type: Patient, id: "PatientPageQuery" }],
        }),
        fetchPatientById: build.query({
            query: (id) => ({ url: `user-details/${id}` }),
            providesTags: [UserDetails],
        }),
        updatePatientDetails: build.mutation({
            query: (data) => ({
                url: "user-details",
                method: "POST",
                data,
            }),
            invalidatesTags: [UserDetails],
        }),
        fetchPatientAgreements: build.query({
            query: (id) => ({
                url: `user-agreements/${id}`,
                method: "GET",
            }),
            providesTags: [AGREEMENT],
        }),
        deletePatientAgreementById: build.mutation({
            query: (id) => ({
                url: `user-agreements/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [AGREEMENT],
        }),
        createPatientAgreement: build.mutation({
            query: (data) => ({
                url: "user-agreements",
                method: "POST",
                data,
            }),
            invalidatesTags: [AGREEMENT],
        }),
        getPatientAlreadyInvitedEmails: build.query({
            query: (props) => ({ url: "/invited-emails", method: "post", data: props }),
            method: "POST",
            providesTags: [PatientInvitedEmails],
        }),
        editInvite: build.mutation({
            query: (props) => ({ url: "/edit-invite", method: "post", data: props }),
            method: "POST",
            invalidatesTags: [PatientInvitedEmails],
        }),
        getCareProvidersForRegistration: build.query({
            query: (patientId) => ({
                url: `auth/care-providers-for-registration`,
                method: "GET",
            }),
        }),
        getCareProvidersForPatient: build.query({
            query: (patientId) => ({
                url: `care-providers-for-patient/${patientId}`,
                method: "GET",
            }),
            providesTags: [PatientCareProviders],
        }),
        grantAccessToCareProvider: build.mutation({
            query: (data) => ({
                url: "grant-access-to-care-provider",
                method: "POST",
                data,
            }),
            invalidatesTags: [PatientCareProviders],
        }),
        rejectAccessToEntityForInvitedEmail: build.mutation({
            query: (props) => ({
                url: `/reject-access-to-entity-for-invited-email/${props.email}/${props.entityType}/${props.entityId}`,
                method: "DELETE",
            }),
            invalidatesTags: [PatientInvitedEmails],
        }),
        contactConfirmationOfPatientConfidentiality: build.query({
            query: () => ({
                url: "/contact-confirmation-of-patient-confidentiality",
            }),
            providesTags: ["CP_CONFIDENTIALITY_AGREEMENT"],
        }),
        acceptContactConfirmationOfPatientConfidentiality: build.mutation({
            query: ({ patientUserDetailsId }) => ({
                url: `/contact-confirmation-of-patient-confidentiality/${patientUserDetailsId}`,
                method: "POST",
            }),
            invalidatesTags: ["CP_CONFIDENTIALITY_AGREEMENT"],
        }),
        getRisk: build.query({
            query: (patientId) => ({
                url: `/risk/${patientId}`,
                method: 'GET'
            }),
        })
    }),
});

export const {
    useListPatientsQuery,
    useGetPatientAlreadyInvitedEmailsQuery,
    useFetchPatientByIdQuery,
    useUpdatePatientDetailsMutation,
    useRejectAccessToEntityForInvitedEmailMutation,
    useDeletePatientAgreementByIdMutation,
    useFetchPatientAgreementsQuery,
    useCreatePatientAgreementMutation,
    useGetCareProvidersForPatientQuery,
    useGetCareProvidersForRegistrationQuery,
    useGrantAccessToCareProviderMutation,
    useEditInviteMutation,
    useContactConfirmationOfPatientConfidentialityQuery,
    useAcceptContactConfirmationOfPatientConfidentialityMutation,
    endpoints,
    useGetRiskQuery
} = PatientApi;
