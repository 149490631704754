import React, {useCallback} from 'react';
import FormSection from "../FormSection";
import {useTranslation} from "react-i18next";
import BooleQuestion from "../input/BooleQuestion";
import {Field} from "formik";
import EpaTextField from 'components/hmo-textfield/EpaTextField';
import {isPropertySelected} from "../config/ValidationSchema";
import DataListInputField from "../input/DataListInputField";
import {Grid, MenuItem, Typography} from "@material-ui/core";
import DataListMonthPicker from "../input/DataListMonthPicker";
import {PlaceholderOrValue} from "../input/FieldSelect";
import InputDivider from "../input/InputDivider";
import UploadDocumentPrompt from "../input/UploadDocumentPrompt";
import Flex from 'components/grid/Flex';
import { QuestionWithFiltering } from './QuestionWithFiltering';

const DataListRow = ({children, title}) => {
    return <Grid container spacing={1} item wrap={'nowrap'} alignItems={'center'}>
        <Grid container item xs={3}>
            <Typography>{title}</Typography>
        </Grid>
        <Grid container item xs={8}>
            {children}
        </Grid>
    </Grid>;
};

const DataListSelector = ({lang, label, item, options, fieldName, t, disabled, onChange}) => {
    return <EpaTextField
            disabled={disabled}
            name={fieldName || "value"}
            {...(label === '' ? {label: ''} : label ? {label} : {label: lang('please-select')})}
            select
            SelectProps={{
                displayEmpty: true,
                value: item?.[fieldName || 'value'] ?? null,
                onChange: e => onChange({
                    id: item.id,
                    field: fieldName || 'value',
                    props: {value: e.target.value}
                }),
                renderValue: PlaceholderOrValue(item?.[fieldName || 'value'], t)
            }}
    >
        {
            options?.map(option => <MenuItem value={option} key={option}>
                {lang(option)}
            </MenuItem>)
        }
    </EpaTextField>;
};


const BloodCountSelector = ({lang, onChange, item, disabled, t}) => {
    return <DataListSelector {...{
        lang, onChange, item, t,
        disabled,
        options: [
            'LEUKOCYTE_INCREASE',
            'LEUKOCYTE_REDUCTION',
            'ANAMIE',
            'INCREASE_IN_HEMATOCRIT',
            'INCREASE_IN_PLATELETS',
            'REDUCTION_IN_PLATELETS'
        ]
    }}/>
};

const FamilialSyndromsOrGeneticPredispositions = ({form, lang, readonly}) => {
    return (
            <>
                <BooleQuestion
                        disabled={readonly}
                        form={form}
                        fieldName="haveHematoFamilialSyndromOrGenetic" //FIELD boolean
                        question={lang('haveHematoFamilialSyndromOrGeneticQuestion')}
                />

                {
                        form.values?.['haveHematoFamilialSyndromOrGenetic'] !== null && form.values?.['haveHematoFamilialSyndromOrGenetic'] === true &&
                        <Field
                                name="haveHematoFamilialSyndromOrGeneticText" //FIELD String
                                disabled={readonly}
                                multiline
                                minRows={6}
                                label={lang("if-yes-specify")}
                                as={EpaTextField}
                        />
                }
            </>
    );
};

const HematoDisease = ({lang, form, fieldName, readonly}) => {
    return (
            <>
                <BooleQuestion
                        disabled={readonly}
                        form={form}
                        fieldName={fieldName}
                        question={lang(`${fieldName}Question`)}
                />

                {
                        isPropertySelected(form, fieldName) &&
                        <DataListInputField
                                form={form}
                                disabled={readonly}
                                fieldName={`${fieldName}Data`}
                                rowData={{date: null, value: ''}}
                        >
                            {({onChange, item, DefaultDeleteIcon}) =>
                                    <Flex container key={item.id} alignItems={'center'} justifyContent={'flex-start'} gap={15}>
                                        <Flex item grow={0}>
                                            <DataListMonthPicker {...{
                                                disabled: readonly, item, onChange, label: lang("when-diagnosed")
                                            }}/>
                                        </Flex>

                                        <Flex item grow={2}>
                                            <EpaTextField
                                                    disabled={readonly}
                                                    name="value"
                                                    placeholder={lang('specify')}
                                                    label={lang('what-kind')}
                                                    value={item?.value ?? ''}
                                                    onChange={(e) => {
                                                        onChange({
                                                            id: item.id,
                                                            field: 'value',
                                                            props: {value: e.target.value}
                                                        })
                                                    }}
                                            />

                                        </Flex>
                                        <Flex item>
                                            {!readonly && <DefaultDeleteIcon style={{
                                                marginTop: '22px'
                                            }} item={item}/> }
                                        </Flex>

                                    </Flex>

                            }
                        </DataListInputField>
                }
            </>
    );
};

const ActiveDiseaseOfTheHematopoieticSystem = ({form, lang, readonly}) => {
    return <HematoDisease {...{form, lang, fieldName: "haveActiveHematoDisease", readonly}}/>;
};

const FormerDiseaseOfTheHematopoieticSystem = ({form, lang, readonly}) => {
    return <HematoDisease {...{form, lang, fieldName: "haveFormerHematoDisease", readonly: readonly}}/>;
};

const UltrasoundOfYourAbdomen = ({form, lang, readonly}) => {
    return (
            <>
                <BooleQuestion
                        disabled={readonly}
                        form={form}
                        fieldName="haveAbdomenUltrasound"
                        question={lang('haveAbdomenUltrasoundQuestion')}
                />

                {
                        isPropertySelected(form, 'haveAbdomenUltrasound') &&
                        <DataListInputField
                                disabled={readonly}
                                form={form}
                                fieldName="haveAbdomenUltrasoundData"
                                rowData={{date: null, value: ''}}
                        >
                            {({onChange, item, DefaultDeleteIcon}) =>
                                    <Flex container key={item.id} alignItems={'center'} justifyContent={'flex-start'} gap={15}>
                                        <Flex item grow={0}>
                                            <DataListMonthPicker {...{
                                                item, onChange, disabled: readonly,
                                            }}/>
                                        </Flex>

                                        <Flex item grow={2}>
                                            <EpaTextField
                                                    disabled={readonly}
                                                    name="value"
                                                    label={lang('spleen')}
                                                    placeholder={lang('size')}
                                                    value={item?.value ?? ''}
                                                    onChange={(e) => {
                                                        onChange({
                                                            id: item.id,
                                                            field: 'value',
                                                            props: {value: e.target.value}
                                                        })
                                                    }}
                                            />

                                        </Flex>
                                        <Flex item>
                                            {!readonly && <DefaultDeleteIcon style={{
                                                marginTop: '22px'
                                            }} item={item}/> }
                                        </Flex>

                                    </Flex>

                            }
                        </DataListInputField>
                }
            </>
    );
};

const ChangeInBloodCounts = ({form, lang, fieldName, readonly, t}) => {
    return <>
        <BooleQuestion
                disabled={readonly}
                form={form}
                fieldName={fieldName}
                question={lang(`${fieldName}Question`)}
        />

        {
                isPropertySelected(form, fieldName) &&
                <DataListInputField
                        disabled={readonly}
                        form={form}
                        fieldName={`${fieldName}Data`}
                        rowData={{date: null, value: ''}}
                >
                    {({onChange, item, DefaultDeleteIcon}) =>
                            <Flex container key={item.id} alignItems={'center'} justifyContent={'flex-start'} gap={15}>
                                <Flex item grow={0}>
                                    <DataListMonthPicker {...{
                                        disabled: readonly,
                                        item, onChange, label: lang("when-discovered")
                                    }}/>
                                </Flex>

                                <Flex item grow={2}>
                                    <BloodCountSelector {...{
                                        lang, item, onChange, disabled: readonly, t
                                    }}/>
                                </Flex>
                                <Flex item>
                                    {!readonly && <DefaultDeleteIcon style={{
                                        marginTop: '22px'
                                    }} item={item}/>}
                                </Flex>

                            </Flex>

                    }
                </DataListInputField>
        }
    </>;
};

const CurrentChangeInBloodCounts = ({form, lang, t, readonly}) => {
    return <ChangeInBloodCounts {...{form, lang, t, readonly, fieldName: "haveCurrentChangeInBloodCount"}}/>;
};

const PreviousChangeInBloodCounts = ({form, lang, t, readonly}) => {
    return <ChangeInBloodCounts {...{form, lang, t, readonly, fieldName: "havePreviousChangeInBloodCount"}}/>;
};

const HasYourBoneMarrowBeenPunctured = ({form, lang, t, readonly}) => {
    const fieldName = 'haveBoneMarrowPunctured';
    return <>
        <BooleQuestion
                disabled={readonly}
                form={form}
                fieldName={fieldName}
                question={lang(`${fieldName}Question`)}
        />

        {
                isPropertySelected(form, fieldName) &&
                <DataListInputField
                        disabled={readonly}
                        form={form}
                        fieldName={`${fieldName}Data`}
                        rowData={{date: null, value: ''}}
                        customHasEmptyCell={
                            () => {
                                const rows = form.values[`${fieldName}Data`];
                                return !rows?.every(
                                        row => row?.date
                                                && Object.keys(row)
                                                        .filter(key => !key?.toLowerCase()?.includes('id'))
                                                        .filter(key => row[key])
                                                        .length > 1
                                )
                            }
                        }
                >
                    {({onChange, item, DefaultDeleteIcon}) =>
                            <Grid container key={item.id} spacing={2}>
                                <Grid item xs={2} style={{minWidth: 160}}>
                                    <DataListMonthPicker {...{
                                        item, onChange, disabled: readonly,
                                    }}/>
                                </Grid>

                                <Grid direction={'column'} container item xs={8} style={{
                                    marginTop: '15px'
                                }}>
                                    <DataListRow title={lang('cellularity')}>
                                        <DataListSelector {...{
                                            disabled: readonly,
                                            lang, onChange, item, t,
                                            label: '',
                                            fieldName: 'cellularity',
                                            options: [
                                                'HYPER',
                                                'NORMO',
                                                'HYPOCELLULAR'
                                            ]
                                        }}/>
                                    </DataListRow>
                                    <DataListRow title={lang('degree-of-fibrosis')}>
                                        <DataListSelector {...{
                                            disabled: readonly,
                                            lang, onChange, item, t,
                                            label: '',
                                            fieldName: 'fibrosis',
                                            options: [
                                                'GRADE_0',
                                                'GRADE_1',
                                                'GRADE_2',
                                                'GRADE_3',
                                                'GRADE_4'
                                            ]
                                        }}/>
                                    </DataListRow>
                                    <DataListRow title={lang('signs-of-dysplasia')}>
                                        <EpaTextField
                                                disabled={readonly}
                                                name="dysplasia"
                                                placeholder={lang('specify')}
                                                label={''}
                                                value={item?.dysplasia ?? ''}
                                                onChange={(e) => {
                                                    onChange({
                                                        id: item.id,
                                                        field: 'dysplasia',
                                                        props: {value: e.target.value}
                                                    })
                                                }}
                                        />
                                    </DataListRow>
                                    <DataListRow title={lang('atypical-cells')}>
                                        <EpaTextField
                                                disabled={readonly}
                                                name="atypical"
                                                placeholder={lang('specify')}
                                                label={''}
                                                value={item?.atypical ?? ''}
                                                onChange={(e) => {
                                                    onChange({
                                                        id: item.id,
                                                        field: 'atypical',
                                                        props: {value: e.target.value}
                                                    })
                                                }}
                                        />
                                    </DataListRow>
                                    <DataListRow title={lang('blast-count')}>
                                        <EpaTextField
                                                disabled={readonly}
                                                name="blastCount"
                                                placeholder={lang('specify')}
                                                label={''}
                                                type={'number'}
                                                value={item?.blastCount ?? ''}
                                                error={item?.blastCount && (item?.blastCount > 100 || item?.blastCount < 0)}
                                                onChange={(e) => {
                                                    onChange({
                                                        id: item.id,
                                                        field: 'blastCount',
                                                        props: {value: e.target.value}
                                                    })
                                                }}
                                        />
                                    </DataListRow>
                                    <DataListRow title={lang('other-abnormalities')}>
                                        <EpaTextField
                                                disabled={readonly}
                                                name="abnormalities"
                                                placeholder={lang('specify')}
                                                label={''}
                                                value={item?.abnormalities ?? ''}
                                                onChange={(e) => {
                                                    onChange({
                                                        id: item.id,
                                                        field: 'abnormalities',
                                                        props: {value: e.target.value}
                                                    })
                                                }}
                                        />
                                    </DataListRow>
                                </Grid>

                                <Grid item xs={1}>
                                    {!readonly && <DefaultDeleteIcon style={{
                                        marginTop: '22px'
                                    }} item={item}/>}
                                </Grid>
                                {!readonly && <UploadDocumentPrompt questionId={item.id}/> }
                            </Grid>

                    }
                </DataListInputField>
        }
    </>;
};

const HematologyForm = ({form, readonly, filters}) => {
    const {t} = useTranslation();
    const lang = useCallback((name) => t(`anamnesis.hematology.${name}`), [t]);

    return (
            <FormSection title={lang('title')}>
                <QuestionWithFiltering filters={filters} filterValue={form.values?.haveHematoFamilialSyndromOrGenetic}>
                    <FamilialSyndromsOrGeneticPredispositions {...{form, lang, t, readonly}}/>
                    <InputDivider/>
                </QuestionWithFiltering>
                <QuestionWithFiltering filters={filters} filterValue={form.values?.haveActiveHematoDisease}>
                    <ActiveDiseaseOfTheHematopoieticSystem {...{form, lang, t, readonly}}/>
                    <InputDivider/>
                </QuestionWithFiltering>
                <QuestionWithFiltering filters={filters} filterValue={form.values?.haveFormerHematoDisease}>
                    <FormerDiseaseOfTheHematopoieticSystem {...{form, lang, t, readonly}}/>
                    <InputDivider/>
                </QuestionWithFiltering>
                <QuestionWithFiltering filters={filters} filterValue={form.values?.haveAbdomenUltrasound}>
                    <UltrasoundOfYourAbdomen {...{form, lang, t, readonly}}/>
                    <InputDivider/>
                </QuestionWithFiltering>
                <QuestionWithFiltering filters={filters} filterValue={form.values?.haveCurrentChangeInBloodCount}>
                    <CurrentChangeInBloodCounts {...{form, lang, t, readonly}}/>
                    <InputDivider/>
                </QuestionWithFiltering>
                <QuestionWithFiltering filters={filters} filterValue={form.values?.havePreviousChangeInBloodCount}>
                    <PreviousChangeInBloodCounts {...{form, lang, t, readonly}}/>
                    <InputDivider/>
                </QuestionWithFiltering>
                <QuestionWithFiltering filters={filters} filterValue={form.values?.haveBoneMarrowPunctured}>
                    <HasYourBoneMarrowBeenPunctured {...{form, lang, t, readonly}}/>
                </QuestionWithFiltering>

            </FormSection>
    );
};


export default HematologyForm;
