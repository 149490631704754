import { Tooltip } from "@material-ui/core";
import shieldIconSrc from "assets/img/shield.png";
import { useTranslation } from "react-i18next";
import moment from 'moment/min/moment-with-locales';
import 'moment/locale/de';

export const IngredientRowRenderer = ({updatedBy, updatedAt, lastModifierUserFullName, className, value}) => {
                const { t, i18n } = useTranslation();
                const formattedDate = moment(updatedAt).locale(i18n.language).format('dddd, DD. MMMM YYYY');
                const localizedDate = updatedAt ? (i18n.language === 'de' ? 'am ': 'on ') + formattedDate : '';
                return <>
                    {updatedBy === "DOCTOR" && (
                    <Tooltip placement="top" title={t('medication.table.verifiedMedication', { person: `Dr. ${lastModifierUserFullName ?? ''}`, date: localizedDate})}>
                        <img
                            src={shieldIconSrc}
                            alt="Verified by Doctor"
                            style={{ width: "20px", height: "20px", marginRight: "5px" }}
                        />
                    </Tooltip>
                    )}
                    <div
                        className={className}
                        style={{
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-all",
                            color: updatedBy === "DOCTOR" ? "#4DAF4E" : "",
                            fontWeight: updatedBy === "DOCTOR" ? "bold" : "",
                        }}
                    >
                        {value}
                    </div>
                </>
             }
