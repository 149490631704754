import React, {Component} from 'react';
import {
    withStyles,
    Typography,
    Dialog,
    Select,
    MenuItem,
    ListItemText,
    TextField,
    FormHelperText,
    Button
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {grey, htmlWhite, htmlBlue, niagara, pictonBlue} from 'components/colors/Colors';
import {withTranslation} from 'react-i18next';
import _ from 'lodash';
import HmoInputLabel from 'components/input-label/HmoInputLabel';
import QuillEditor from 'components/quill-editor/QuillEditor';
import DatePicker from 'components/calendar/DatePicker';
import utils from 'utils/Utils';
import {DETAIL_TYPE_FIELDS} from '../DetailsType.model';
import HmoFilledButton from 'components/button/HmoFilledButton';
import {Autocomplete} from '@material-ui/lab';
import HmoTextField from 'components/hmo-textfield/HmoTextField';
import connector from './SecondOpinionDetailDialog.connect';
import {connect} from 'react-redux';

const styles = theme => ({
    inputRoot: {
        backgroundColor: htmlWhite,
        borderRadius: 4,
        paddingLeft: 7
    },
    inputItem: {
        padding: '7px 7px',
    },
    document: {
        textDecoration: 'underline',
        color: htmlBlue,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    title: {
        fontSize: 15,
        color: niagara,
        fontWeight: 'bold'
    },
    cancel: {
        fontSize: 12,
        fontWeight: 'bold',
        color: niagara,
        textTransform: 'none'
    },

});

export class SecondOpinionDetailDialog extends Component {

    state = {
        code: '',
        content: '',
        from: null,
        to: null,
        freeText: '',
        assignedDocuments: [],
        selectedDocument: '',
        quillJsCanSafelyMount: false,
        isNoDocumentSelected: false,
        icds: [],
        quillJsForceUpdateId: ''
    };

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    };

    handleDocumentChange = (event, value) => {
        this.setState({
            assignedDocuments: value
        })
    };

    handleIcdChange = (event, value) => {
        if (value == null) {
            this.setState({
                code: null,
                icds: []
            })
        } else if (value.icd) {
            this.setState({
                code: value.icd,
                content: value.name ? value.name.replace(value.icd, '') : value.icd + '<br>' + this.state.content,
                quillJsForceUpdateId: utils.uuid()
            })
        }

    };

    handleIcdValueChange = (event, value) => {
        this.props.fetchIcd10(value).then(response => {
            const icds = response.payload.data;
            this.setState({icds});
        })
    };

    handleContentChange = htmlToCommit => {
        this.setState({
            content: htmlToCommit
        })
    };

    save = () => {
        //NOTE LM: QuillJs commits changes async and outside of the scope of react, its possible to type something into
        // the editor fast and then immediately click save, which will result in save called before the last
        // handleContentChange commits the latest changes in the edit.
        setTimeout(() => {
            const businessId = _.get(this.props, 'selectedSecondOpinionDetail.businessId');
            this.props.updateSecondOpinionDetail({
                businessId,
                code: this.state.code,
                content: this.state.content,
                ...(this.state.from && {from: this.state.from}),
                ...(this.state.to && {to: this.state.to}),
                freeText: this.state.freeText,
                assignedDocumentIds: this.state.assignedDocuments.map(doc => doc.documentData.documentId),
                detailType: this.props.detailType
            }, this.props.caseBusinessId);
            this.handleClose();
        }, 300)
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open === true && prevProps.open === false) {
            this.reset();
        }
    }

    reset = () => {
        this.setState({
            code: this.props.selectedSecondOpinionDetail.code || '',
            content: this.props.selectedSecondOpinionDetail.content || '',
            from: this.props.selectedSecondOpinionDetail.from || null,
            to: this.props.selectedSecondOpinionDetail.to || null,
            freeText: this.props.selectedSecondOpinionDetail.freeText || '',
            assignedDocuments: (this.props.documents || [])
                .filter(doc => (this.props.selectedSecondOpinionDetail.assignedDocumentIds || [])
                    .includes(doc.documentData.documentId)) || [],
            selectedDocument: '',
            isNoDocumentSelected: false,
        }, () => {
            this.setState({quillJsCanSafelyMount: true});
            if (this.props.detailType === 'ICD10') {
                this.handleIcdValueChange(undefined, '');
            }
        });
    };

    handleClose = () => {
        this.setState({quillJsCanSafelyMount: false});
        this.props.closeEditDialog();
    };

    handleFromChange = date => {
        this.setState({
            from: date
        })
    };

    handleToChange = date => {
        this.setState({
            to: date
        })
    };

    render() {
        const {
            classes, t: translate, open, documents, selectedSecondOpinionDetail, detailType, types
        } = this.props;
        const {
            handleChange, save, handleContentChange, handleClose, handleFromChange, handleToChange,
            handleDocumentChange
        } = this;
        const {
            icds, code, content, assignedDocuments, quillJsCanSafelyMount, freeText, from, to, isNoDocumentSelected,
            quillJsForceUpdateId
        } = this.state;

        return (
            <Dialog open={open} onClose={handleClose} maxWidth="md" style={{width: '100%'}}>

                <Flex item container direction={'column'} padding={20}>
                    <Flex item container
                          style={{marginBottom: 10, paddingBottom: 10, borderBottom: `1px solid ${grey}`}}
                          alignItems={'center'} justifyContent={'space-between'}>
                        <Flex item container alignItems={'center'}>
                            <Typography className={classes.title}>{
                                _.isEmpty(selectedSecondOpinionDetail)
                                    ? translate('case.add-entry', {for: translate(`case.detail-type.${detailType}`)})
                                    : translate('case.edit-entry', {for: translate(`case.detail-type.${detailType}`)})
                            }</Typography>
                        </Flex>
                        <Flex item container justifyContent={'flex-end'}>
                            <Button onClick={handleClose} classes={{root: classes.cancel}}>
                                {translate('global.cancel')}
                            </Button>
                            <HmoFilledButton onClick={save} style={{fontSize: 14}}>
                                {translate('global.save')}
                            </HmoFilledButton>
                        </Flex>
                    </Flex>

                    {
                        selectedSecondOpinionDetail &&
                        <Flex item container justifyContent={'space-between'}>

                            {
                                DETAIL_TYPE_FIELDS[detailType].includes('from') &&
                                <DatePicker {...{
                                    value: from || null,
                                    name: 'from',
                                    label: DETAIL_TYPE_FIELDS[detailType].includes('to')
                                        ? translate('global.from')
                                        : translate('global.date'),
                                    onChange: handleFromChange,
                                    style: {marginRight: 10, maxWidth: 135}
                                }}/>
                            }

                            {
                                DETAIL_TYPE_FIELDS[detailType].includes('to') &&
                                <DatePicker {...{
                                    value: to || null,
                                    name: 'to',
                                    label: translate('global.to'),
                                    onChange: handleToChange,
                                    style: {marginRight: 10, maxWidth: 135}
                                }}/>
                            }

                            {
                                DETAIL_TYPE_FIELDS[detailType].includes('code') &&
                                <Flex item container direction={'column'} style={{
                                    marginRight: 10, minWidth: detailType === 'ICD10' ? 300 : undefined,
                                    maxWidth: detailType === 'ICD10' ? undefined : 100
                                }}>
                                    <HmoInputLabel>{_.isEmpty(types) ? translate('case.code') : translate('case.type')}</HmoInputLabel>
                                    {detailType === 'ICD10' &&
                                    <Autocomplete
                                        options={icds || []}
                                        onChange={this.handleIcdChange}
                                        onInputChange={this.handleIcdValueChange}
                                        value={code || null}
                                        getOptionSelected={(option, value) => {
                                            return option.icd === value
                                        }}
                                        style={{width: '100%'}}
                                        getOptionLabel={value => value.icd ? value.name : value}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                classes={{root: classes.inputRoot}}
                                                fullWidth
                                            />
                                        )}
                                    />}

                                    {
                                        detailType !== 'ICD10' &&
                                        (_.isEmpty(types)
                                            ?
                                            <HmoTextField
                                                value={code || ''}
                                                onChange={handleChange}
                                                fullWidth
                                                name={'code'}
                                            />
                                            :
                                            <Select
                                                value={code}
                                                classes={{root: classes.inputRoot}}
                                                onChange={handleChange}
                                                fullWidth
                                                name={'code'}
                                                renderValue={value => value}
                                            >
                                                {types && types.map(type => (
                                                    <MenuItem key={type} value={type}>
                                                        <ListItemText primary={type}/>
                                                    </MenuItem>
                                                ))}
                                            </Select>)
                                    }

                                </Flex>
                            }

                            {
                                DETAIL_TYPE_FIELDS[detailType].includes('freeText') &&
                                <Flex item container direction={'column'} style={{marginRight: 10, maxWidth: 100}}>
                                    <HmoInputLabel>{translate(`case.freeText.${detailType}`)}</HmoInputLabel>
                                    <HmoTextField
                                        value={freeText || ''}
                                        onChange={handleChange}
                                        fullWidth
                                        name={'freeText'}
                                    />

                                </Flex>
                            }

                            <Flex item grow={5} container style={{paddingLeft: 30}}
                                  direction={'column'}>
                                <HmoInputLabel>{translate('case.add-document')}</HmoInputLabel>
                                <Flex item container alignItems={'flex-end'}>
                                    <Autocomplete
                                        multiple
                                        options={documents.filter(doc =>
                                            !assignedDocuments
                                                .some(assigned => assigned.documentData.documentId === doc.documentData.documentId)) || []}
                                        onChange={handleDocumentChange}
                                        value={assignedDocuments || ''}
                                        getOptionSelected={(option, value) => {
                                            return option.documentData.documentId === value.documentData.documentId
                                        }}
                                        style={{width: '100%'}}
                                        getOptionLabel={value => value.documentData.fileName}
                                        ChipProps={{
                                            style: {
                                                height: 25,
                                                color: htmlWhite,
                                                backgroundColor: pictonBlue,
                                                borderRadius: 4
                                            },
                                            color: 'secondary'
                                        }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                classes={{root: classes.inputRoot}}
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Flex>
                                <Flex item container alignItems={'flex-end'}>{
                                    isNoDocumentSelected &&
                                    <FormHelperText error>
                                        {translate('global.no-document-uploaded-error')}
                                    </FormHelperText>
                                }
                                </Flex>
                            </Flex>
                        </Flex>
                    }

                    <Flex item container style={{marginTop: 20, minHeight: 200}}>
                        {
                            quillJsCanSafelyMount &&
                            <QuillEditor {...{
                                text: content,
                                updateId: quillJsForceUpdateId,
                                handleTextChange: handleContentChange,
                                containerStyle: {
                                    borderRadius: 4,
                                    backgroundColor: htmlWhite
                                }
                            }}/>
                        }
                    </Flex>

                </Flex>
            </Dialog>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(SecondOpinionDetailDialog)));
