import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import IconButton from "@material-ui/core/IconButton";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import PdfViewer from "components/pdf-viewer/PdfViewer";
import {useListOfFilesStyles} from "./ListOfFiles";
import {useState} from "react";
import utils from "utils/Utils";

const PermanentDocument = ({name, label, downloadPermanentDocument}) => {
    const classNames = useListOfFilesStyles();
    const [pdfFile, setPdfFile] = useState(null);
    const [isPdfOpen, setIsPdfOpen] = useState(false);
    const toggleIsPdfOpen = () => {
        setIsPdfOpen(!isPdfOpen);
    };

    const downloadAndShowPdf = () => {
        if (pdfFile) {
            setIsPdfOpen(true);
        } else {
            downloadPermanentDocument().then((pdf) => {
                setPdfFile(pdf);
                setIsPdfOpen(true);
            });
        }
    }

    const downloadDocument = () => {
        if (pdfFile) {
            utils.triggerNativeDownload(pdfFile, `${name}.pdf`);
        } else {
            downloadPermanentDocument().then((pdf) => {
                utils.triggerNativeDownload(pdf, `${name}.pdf`);
            });
        }
    }

    return <li key={name} className={classNames.item} style={{paddingBottom: 0}}>
        <InsertDriveFileOutlinedIcon className={classNames.icon} style={{fill: 'black'}}/>
        <div onClick={downloadAndShowPdf}
             className={classNames.textDescription}>
            <div className={classNames.documentTitle}>
                <span>{name}</span>
            </div>
            <div className={classNames.additionalInfo}>{label}</div>
        </div>
        <div className={classNames.actionButtons}>
            <IconButton style={{padding: 3, marginLeft: 5}} onClick={downloadDocument}>
                <DownloadIcon/>
            </IconButton>
        </div>
        {
                pdfFile && isPdfOpen &&
                <PdfViewer fileBlobUrl={pdfFile} onClose={toggleIsPdfOpen}/>
        }
    </li>;
};

export default PermanentDocument;
