import HMOApi, {MedicationPlan} from "store/api/HMOApi";

const BASE_URL = "/medication-plan";

export const MedicationPlanType =  {
        REGULAR : 'REGULAR',
        SPECIAL_TIMES: 'SPECIAL_TIMES'
}

export const MedicationPlanApi = HMOApi.injectEndpoints({
    endpoints: (build) => ({
        queryPageMedicationPlan : build.query({
            query: (params) => ({ url: BASE_URL + '/page-query', params }),
            providesTags: [MedicationPlan],
        }),

        createMedicationPlan: build.mutation({
            query: (data) => ({
                url: BASE_URL,
                method: 'POST',
                data,
            }),
            invalidatesTags: [MedicationPlan],
        }),

        updateMedicationPlan: build.mutation({
            query: (data) => ({
                url: BASE_URL + '/' + data.id,
                method: 'PUT',
                data,
            }),
            invalidatesTags: [MedicationPlan],
        }),

        deleteMedicationPlan: build.mutation({
            query: (data) => ({
                url: BASE_URL + '/' + data.id,
                method: 'DELETE',
            }),
            invalidatesTags: [MedicationPlan],
        }),
    }),
});

export const {
    useQueryPageMedicationPlanQuery,
    useCreateMedicationPlanMutation,
    useUpdateMedicationPlanMutation,
    useDeleteMedicationPlanMutation
} = MedicationPlanApi;
