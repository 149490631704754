import * as actionTypes from './CareProvider.action.types';

export function upsertCareProvider(careProvider) {
    return {
        type: actionTypes.UPSERT_CARE_PROVIDER,
        payload: {
            client: 'api',
            request: {
                method: 'post',
                url: `care-provider`,
                data: careProvider
            }
        }
    }
}

export function listCareProviders() {
    return {
        type: actionTypes.LIST_CARE_PROVIDERS,
        payload: {
            client: 'api',
            request: {
                method: 'get',
                url: `care-provider/all`,
            }
        }
    }
}

export function getCareProvider(id) {
    return {
        type: actionTypes.GET_CARE_PROVIDER,
        payload: {
            client: 'api',
            request: {
                method: 'get',
                url: `care-provider/${id}`,
            }
        }
    }
}

export function deleteCareProvider(id) {
    return {
        type: actionTypes.DELETE_CARE_PROVIDER,
        payload: {
            client: 'api',
            request: {
                method: 'delete',
                url: `care-provider/${id}`,
            }
        }
    }
}

export function fetchContacts(id) {
    return {
        type: actionTypes.FETCH_CONTACTS,
        payload: {
            client: 'api',
            request: {
                method: 'get',
                url: `care-provider/${id}/contacts`,
            }
        }
    }
}
