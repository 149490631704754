import React, {Component} from 'react';
import {withStyles, Typography} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import {grey} from 'components/colors/Colors';
import * as WS from 'domain/WorkflowStatus';
import SortIcon from 'components/sort-icon/SortIcon';

export const NEW_FOR_CLINIC = 'NEW_FOR_CLINIC';
export const ACCEPTED_FOR_CLINIC = 'ACCEPTED_FOR_CLINIC';

/**
 * Parsed from https://internal.production.h4-software.hu/task/753
 * The arrays DO NOT represent ordering, only inclusion.
 */
export const HEADERS_TO_STATUS = {
    [undefined]: ['patientName', 'disease', 'subIndication', 'clinic', 'email', 'phone', 'created', 'request'],
    [null]: ['patientName', 'disease', 'subIndication', 'clinic', 'email', 'phone', 'created', 'request'],
    ['']: ['patientName', 'disease', 'subIndication', 'clinic', 'email', 'phone', 'created', 'request'],
    [NEW_FOR_CLINIC]: ['sex', 'age', 'primaryTumor', 'metastases', 'finishUntil', 'payment', 'actions'],
    [ACCEPTED_FOR_CLINIC]: ['patientName', 'disease', 'status', 'acceptedDate', 'finishUntil', 'lastChange'],
    [WS.NEW]: ['patientName', 'disease', 'email', 'phone', 'created', 'request'],
    [WS.REGISTER_PATIENT]: ['caseManager', 'patientName', 'numberOfDaysInStatus', 'disease', 'email', 'phone', 'reminderDate', 'reminderAction', 'lastChange'],
    [WS.CHECK_FOR_COST_COVERAGE]: ['caseManager', 'patientName', 'numberOfDaysInStatus', 'disease', 'subIndication', 'costCoverage', 'email', 'phone', 'reminderDate', 'reminderAction', 'lastChange'],
    [WS.WAIT_FOR_DECISION]: ['caseManager', 'patientName', 'numberOfDaysInStatus', 'disease', 'subIndication', 'costCoverage', 'email', 'phone', 'reminderDate', 'reminderAction', 'lastChange'],
    [WS.WAIT_FOR_GDPR_CONSENT_FORM]: ['caseManager', 'patientName', 'numberOfDaysInStatus', 'disease', 'subIndication', 'email', 'phone', 'reminderDate', 'reminderAction', 'lastChange'],
    [WS.WAIT_FOR_PAYMENT]: ['caseManager', 'patientName', 'numberOfDaysInStatus', 'disease', 'subIndication', 'email', 'phone', 'reminderDate', 'reminderAction', 'lastChange'],
    [WS.ASK_FOR_MISSING_DOCUMENTS]: ['caseManager', 'patientName', 'numberOfDaysInStatus', 'disease', 'subIndication', 'email', 'phone', 'patientAppointmentAction', 'patientAppointmentDate', 'reminderDate', 'reminderAction', 'lastChange'],
    [WS.CHECK_DOCUMENTS]: ['caseManager', 'patientName', 'numberOfDaysInStatus', 'disease', 'subIndication', 'email', 'phone', 'patientAppointmentAction', 'patientAppointmentDate', 'reminderDate', 'reminderAction', 'lastChange'],
    [WS.PREPARE_CASE_FOR_EXPERT]: ['caseManager', 'patientName', 'numberOfDaysInStatus', 'disease', 'subIndication', 'email', 'phone', 'patientAppointmentAction', 'patientAppointmentDate', 'reminderDate', 'reminderAction', 'lastChange'],
    [WS.REQUEST_EXPERT_TO_TAKE_OVER_CASE]: ['caseManager', 'patientName', 'numberOfDaysInStatus', 'disease', 'subIndication', 'phone', 'clinic', 'contact', 'reminderDate', 'reminderAction', 'lastChange'],
    [WS.CASE_NOT_REVIEWED_BY_EXPERT]: ['caseManager', 'patientName', 'numberOfDaysInStatus', 'disease', 'subIndication', 'phone', 'clinic', 'contact', 'reminderDate', 'reminderAction', 'lastChange'],
    [WS.CASE_UNDER_REVIEW_BY_EXPERT]: ['caseManager', 'patientName', 'numberOfDaysInStatus', 'disease', 'subIndication', 'phone', 'clinic', 'contact', 'reminderDate', 'reminderAction', 'lastChange'],
    [WS.GENERATE_REPORT_OF_THE_SECOND_OPINION]: ['caseManager', 'patientName', 'numberOfDaysInStatus', 'disease', 'subIndication', 'email', 'phone', 'clinic', 'reminderDate', 'reminderAction', 'lastChange'],
    [WS.CALL_PATIENT_TO_TELL_THE_RESULT]: ['caseManager', 'patientName', 'numberOfDaysInStatus', 'disease', 'subIndication', 'email', 'phone', 'clinic', 'reminderDate', 'reminderAction', 'lastChange'],
    [WS.INVOICE_COST_COVERAGE_INSTANCE]: ['caseManager', 'patientName', 'numberOfDaysInStatus', 'disease', 'subIndication', 'costCoverage', 'reminderDate', 'reminderAction', 'lastChange'],
    [WS.NOTIFY_EXPERT_TO_SEND_INVOICE]: ['caseManager', 'patientName', 'numberOfDaysInStatus', 'disease', 'subIndication', 'clinic', 'reminderDate', 'reminderAction', 'lastChange'],
    [WS.FURTHER_QUESTIONS_FOR_THE_EXPERTS]: ['caseManager', 'patientName', 'numberOfDaysInStatus', 'disease', 'subIndication', 'phone', 'clinic', 'contact', 'reminderDate', 'reminderAction', 'lastChange'],
    [WS.GET_FEEDBACK_FROM_PATIENT]: ['caseManager', 'patientName', 'numberOfDaysInStatus', 'disease', 'subIndication', 'costCoverage', 'email', 'phone', 'reminderDate', 'reminderAction', 'lastChange'],
    [WS.STUDY_QUESTIONNAIRE]: ['caseManager', 'patientName', 'numberOfDaysInStatus', 'disease', 'subIndication', 'costCoverage', 'reminderDate', 'reminderAction', 'lastChange'],
    [WS.CANCELLATION]: ['caseManager', 'patientName', 'numberOfDaysInStatus', 'disease', 'subIndication', 'costCoverage', 'clinic', 'lastChange'],
    [WS.DONE]: ['caseManager', 'patientName', 'numberOfDaysInStatus', 'disease', 'subIndication', 'costCoverage', 'clinic', 'lastChange'],
};

const styles = theme => ({
    title: {
        color: grey,
        userSelect: 'none'
    }
});

export class CaseRowHeaders extends Component {
    render() {
        const {classes, t: translate, status, sortBy, sortParameter, reverseSort} = this.props;

        return (
            <tr style={{height: 25}}>
                {
                    HEADERS_TO_STATUS[status]?.includes('caseManager') &&
                    <th style={{textAlign: 'center'}}>
                        <Typography className={classes.title}>
                            {translate('case.list-headers.case-manager')}
                        </Typography>
                    </th>
                }
                <th>
                    <Flex container onClick={sortBy(status, 'patient.id')}>
                        <Typography className={classes.title}>
                            {translate('case.list-headers.patient-id')}
                        </Typography>
                        <SortIcon isShown={sortParameter === 'patient.id'} reverse={reverseSort}/>
                    </Flex>
                </th>

                <th>
                    <Flex container onClick={sortBy(status, 'patient.familyName')}>
                        <Typography className={classes.title} onClick={sortBy(status, 'patient.familyName')}>
                            {translate('case.list-headers.patient-name')}
                        </Typography>
                        <SortIcon isShown={sortParameter === 'patient.familyName'} reverse={reverseSort}/>
                    </Flex>
                </th>
                {
                    HEADERS_TO_STATUS[status].includes('numberOfDaysInStatus') &&
                    <th>
                        <Flex container onClick={sortBy(status, 'numberOfDaysInStatus')}>
                            <Typography className={classes.title}>
                                {translate('case.list-headers.days')}
                            </Typography>
                            <SortIcon isShown={sortParameter === 'numberOfDaysInStatus'} reverse={reverseSort}/>
                        </Flex>
                    </th>
                }

                {
                    HEADERS_TO_STATUS[status].includes('sex') &&
                    <th>
                        <Flex container onClick={sortBy(status, 'patient.bioSex')}>
                            <Typography className={classes.title}>
                                {translate('case.sex')}
                            </Typography>
                            <SortIcon isShown={sortParameter === 'patient.bioSex'} reverse={reverseSort}/>
                        </Flex>
                    </th>
                }

                {
                    HEADERS_TO_STATUS[status].includes('age') &&
                    <th>
                        <Flex container onClick={sortBy(status, 'patient.dateOfBirth')}>
                            <Typography className={classes.title}>
                                {translate('global.age')}
                            </Typography>
                            <SortIcon isShown={sortParameter === 'patient.dateOfBirth'} reverse={reverseSort}/>
                        </Flex>
                    </th>
                }

                {
                    HEADERS_TO_STATUS[status].includes('primaryTumor') &&
                    <th>
                        <Flex container onClick={sortBy(status, 'primaryTumorLocalized')}>
                            <Typography className={classes.title}>
                                {translate('global.primaryTumor')}
                            </Typography>
                            <SortIcon isShown={sortParameter === 'primaryTumorLocalized'} reverse={reverseSort}/>
                        </Flex>
                    </th>
                }

                {
                    HEADERS_TO_STATUS[status].includes('metastases') &&
                    <th>
                        <Flex container onClick={sortBy(status, 'metastasisLocalized')}>
                            <Typography className={classes.title}>
                                {translate('global.metastases')}
                            </Typography>
                            <SortIcon isShown={sortParameter === 'metastasisLocalized'} reverse={reverseSort}/>
                        </Flex>
                    </th>
                }

                {
                    HEADERS_TO_STATUS[status].includes('status') &&
                    <th>
                        <Flex container onClick={sortBy(status, 'status')}>
                            <Typography className={classes.title}>
                                {translate('global.status')}
                            </Typography>
                            <SortIcon isShown={sortParameter === 'status'} reverse={reverseSort}/>
                        </Flex>
                    </th>
                }

                {
                    HEADERS_TO_STATUS[status].includes('acceptedDate') &&
                    <th>
                        <Flex container onClick={sortBy(status, 'acceptedDate')}>
                            <Typography className={classes.title}>
                                {translate('global.acceptedDate')}
                            </Typography>
                            <SortIcon isShown={sortParameter === 'acceptedDate'} reverse={reverseSort}/>
                        </Flex>
                    </th>
                }

                {
                    HEADERS_TO_STATUS[status].includes('finishUntil') &&
                    <th>
                        <Flex container onClick={sortBy(status, 'secondOpinionUntil')}>
                            <Typography className={classes.title}>
                                {translate('global.finishUntil')}
                            </Typography>
                            <SortIcon isShown={sortParameter === 'secondOpinionUntil'} reverse={reverseSort}/>
                        </Flex>
                    </th>
                }

                {
                    HEADERS_TO_STATUS[status].includes('payment') &&
                    <th>
                        <Flex container>
                            <Typography className={classes.title}>
                                {translate('global.payment')}
                            </Typography>
                        </Flex>
                    </th>
                }

                {
                    HEADERS_TO_STATUS[status].includes('disease') &&
                    <th>
                        <Flex container onClick={sortBy(status, 'indication')}>
                            <Typography className={classes.title}>
                                {translate('case.list-headers.disease')}
                            </Typography>
                            <SortIcon isShown={sortParameter === 'indication'} reverse={reverseSort}/>
                        </Flex>
                    </th>
                }

                {
                    HEADERS_TO_STATUS[status].includes('subIndication') &&
                    <th>
                        <Typography className={classes.title}>
                            {translate('case.sub-indication')}
                        </Typography>
                    </th>
                }

                {
                    HEADERS_TO_STATUS[status].includes('costCoverage') &&
                    <th>
                        <Typography className={classes.title}>
                            {translate('case.list-headers.cost-coverage')}
                        </Typography>
                    </th>
                }

                {
                    HEADERS_TO_STATUS[status].includes('email') &&
                    <th>
                        <Flex container onClick={sortBy(status, 'patient.emailAddresses[0]')}>
                            <Typography className={classes.title}>
                                {translate('case.list-headers.email')}
                            </Typography>
                            <SortIcon isShown={sortParameter === 'patient.emailAddresses[0]'} reverse={reverseSort}/>
                        </Flex>
                    </th>
                }

                {
                    HEADERS_TO_STATUS[status].includes('clinic') &&
                    <th>
                        <Typography className={classes.title}>
                            {translate('case.list-headers.clinic')}
                        </Typography>
                    </th>
                }

                {
                    HEADERS_TO_STATUS[status].includes('contact') &&
                    <th>
                        <Typography className={classes.title}>
                            {translate('case.list-headers.contact')}
                        </Typography>
                    </th>
                }

                {
                    HEADERS_TO_STATUS[status].includes('phone') &&
                    <th>
                        <Flex container onClick={sortBy(status, 'patient.phoneNumbers[0]')}>
                            <Typography className={classes.title}>
                                {translate('case.list-headers.phone')}
                            </Typography>
                            <SortIcon isShown={sortParameter === 'patient.phoneNumbers[0]'} reverse={reverseSort}/>
                        </Flex>
                    </th>
                }

                {
                    HEADERS_TO_STATUS[status].includes('patientAppointmentAction') &&
                    <th>
                        <Flex container onClick={sortBy(status, 'patientAppointmentComment')}>
                            <Typography className={classes.title}>
                                {translate('case.list-headers.patient-appointment')}
                            </Typography>
                            <SortIcon isShown={sortParameter === 'patientAppointmentComment'} reverse={reverseSort}/>
                        </Flex>
                    </th>
                }

                {
                    HEADERS_TO_STATUS[status].includes('patientAppointmentDate') &&
                    <th>
                        <Flex container onClick={sortBy(status, 'patientAppointmentDate')}>
                            <Typography className={classes.title}>
                                {translate('case.list-headers.patient-appointment-date')}
                            </Typography>
                            <SortIcon isShown={sortParameter === 'patientAppointmentDate'} reverse={reverseSort}/>
                        </Flex>
                    </th>
                }

                {
                    HEADERS_TO_STATUS[status].includes('reminderAction') &&
                    <th>
                        <Flex container onClick={sortBy(status, 'reminderAction')}>
                            <Typography className={classes.title}>
                                {translate('case.list-headers.reminder-action')}
                            </Typography>
                            <SortIcon isShown={sortParameter === 'reminderAction'} reverse={reverseSort}/>
                        </Flex>
                    </th>
                }

                {
                    HEADERS_TO_STATUS[status].includes('reminderDate') &&
                    <th>
                        <Flex container onClick={sortBy(status, 'reminderDate')}>
                            <Typography className={classes.title}>
                                {translate('case.list-headers.reminder-date-short')}
                            </Typography>
                            <SortIcon isShown={sortParameter === 'reminderDate'} reverse={reverseSort}/>
                        </Flex>
                    </th>
                }

                {
                    HEADERS_TO_STATUS[status].includes('lastChange') &&
                    <th>
                        <Flex container onClick={sortBy(status, 'modificationDate')}>
                            <Typography className={classes.title}>
                                {translate('case.list-headers.last-change')}
                            </Typography>
                            <SortIcon isShown={sortParameter === 'modificationDate'} reverse={reverseSort}/>
                        </Flex>
                    </th>
                }

                {
                    HEADERS_TO_STATUS[status].includes('request') &&
                    <th>
                        <Flex container onClick={sortBy(status, 'originationComment')}>
                            <Typography className={classes.title}>
                                {translate('case.list-headers.request')}
                            </Typography>
                            <SortIcon isShown={sortParameter === 'originationComment'} reverse={reverseSort}/>
                        </Flex>
                    </th>
                }

                {
                    HEADERS_TO_STATUS[status].includes('created') &&
                    <th>
                        <Flex container onClick={sortBy(status, 'creationDate')}>
                            <Typography className={classes.title}>
                                {translate('case.list-headers.created')}
                            </Typography>
                            <SortIcon isShown={sortParameter === 'creationDate'} reverse={reverseSort}/>
                        </Flex>
                    </th>
                }

                {
                    HEADERS_TO_STATUS[status].includes('actions') &&
                    <th>
                        <Typography className={classes.title}>
                            {translate('global.actions')}
                        </Typography>
                    </th>
                }

            </tr>
        );
    }
}

export default withStyles(styles)(withTranslation()(CaseRowHeaders));
