import DefaultDialogBody from './DefaultDialogBody';
import {useTranslation} from "react-i18next";
import {useAssignCaseContactToMutation} from 'scenes/user-manager/UserDetailAccessApi';
import {useEnqueueSuccess, useEnqueueError} from 'components/alert/SnackbarHooks';

/**
 * CASE_MANAGER_EDITS_CASE_CONTACT_PERSON_EMAIL
 */
const CaseManagerEditsCaseContactPerson = (props) => {
    const {
        toggleOpen, isSaveDisabled, userAlreadyExists, userDetailsAlreadyExists, existingUserMatchesPersonsUser,
        deleteEmail, originalEmail, existingUser, personAlreadyHasAUser, grantType, existingUserId,
        existingUserDetailsId, currentlyEditedUserDetailId, entityIdToAssign, refresh, existingUserDetails
    } = props;
    const {t: translate} = useTranslation();
    const [assignCaseContactTo] = useAssignCaseContactToMutation();
    const enqueueError = useEnqueueError();
    const enqueueSuccess = useEnqueueSuccess();
    const triggerGrantAccess = () => assignCaseContactTo({grantType, existingUserId, existingUserDetailsId,
        currentlyEditedUserDetailId, entityIdToAssign})
            .unwrap().then(() => {
                enqueueSuccess();
                refresh && refresh();
                toggleOpen();
            }).catch(() => {
                enqueueError();
            })
    let information = '';
    let existingUserString = '';
    let save = props.save;
    let saveButtonLabel = props.saveButtonLabel;
    let disabled = isSaveDisabled;

    if (personAlreadyHasAUser && (userAlreadyExists || userDetailsAlreadyExists)) {
        information = translate('access.already-used');
        disabled = true;
    } else if (userAlreadyExists && !existingUserMatchesPersonsUser && !userDetailsAlreadyExists) {
        information = translate('access.already-used-no-assignment-question', {name: `(${existingUser?.givenName}, ${existingUser?.familyName})`});
        save = triggerGrantAccess;
        saveButtonLabel = translate('access.assign-contact');
    } else if (userDetailsAlreadyExists && existingUserDetails.length === 1) {
        information = translate('access.grant-access-to-case-contact');
        save = triggerGrantAccess;
        existingUserString = translate('access.existing-user')
                + `: ${existingUserDetails[0]?.givenName} ${existingUserDetails[0]?.familyName}`;
        saveButtonLabel = translate('access.assign-contact');
    }

    return <DefaultDialogBody {...{
        information,
        toggleOpen,
        save,
        saveButtonLabel,
        disabled,
        deleteEmail,
        originalEmail,
        existingUserString
    }}/>;
};

export default CaseManagerEditsCaseContactPerson;
