import React, { Component } from 'react';
import Flex from 'components/grid/Flex';

export class ButtonSwitch extends Component {
    render() {
        const {item, style, labelRight, labelLeft, selectLeft, selectRight, isLeftSelected} = this.props;
        return (
            <Flex item={item || '0 1 auto'} container style={{minWidth: 250, minHeight: 36, cursor: 'pointer',
                ...style}}
                  justifyContent={'space-between'}>
                <Flex item basis={'50%'} container center style={{
                    minWidth: 120,
                    border: '1px solid #00000034',
                    borderRadius: '4px 0px 0px 4px',
                    backgroundColor: isLeftSelected ? '#F1F1F1' : 'white'
                }} onClick={selectLeft}>
                    {labelLeft}
                </Flex>
                <Flex item basis={'50%'} container center style={{
                    minWidth: 120,
                    border: '1px solid #00000034',
                    borderRadius: '0px 4px 4px 0px',
                    backgroundColor: isLeftSelected ? 'white' : '#F1F1F1'
                }} onClick={selectRight}>
                    {labelRight}
                </Flex>
            </Flex>
        );
    }
}

export default ButtonSwitch;
