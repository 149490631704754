import HMOApi, { AccessAssignments, PatientCareProviders } from "store/api/HMOApi";

const BASE_URL = "/program";

export const ProgramAccessApi = HMOApi.injectEndpoints({
    endpoints: (build) => ({
        getContacts: build.query({
            async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
                const query =
                    args.entityType === "FULL_PATIENT"
                        ? `${BASE_URL}/all-contacts-grouped-by-access-level/${args.patientId}`
                        : `${BASE_URL}/contacts/${args.patientId}/${args.entityType}/${args.entityId}`;
                const result = await fetchWithBQ({ url: query });
                if (result.error) return { error: result.error };
                if (args.entityType === "FULL_PATIENT") {
                    return { data: combineAccessesGroupedByLevel(result.data) };
                }
                return { data: result.data };
            },
            providesTags: [AccessAssignments],

            method: "GET",
        }),
        // TODO: have to change names of these mutations
        // on relates to contacts and the other to care providers
        // but they are both named access assignments
        deleteAccessAssignmentsByIds: build.mutation({
            query: (props) => ({
                url: `contacts-access-assignment/${props.patientId}?ids=${props.ids.join(",")}`,
                method: "DELETE",
            }),
            invalidatesTags: [AccessAssignments],
        }),
        deleteAccessAssignmentById: build.mutation({
            query: (props) => ({
                url: `care-provider-access-assignment/${props.patientId}?ids=${props.ids.join(",")}`,
                method: "DELETE",
            }),
            invalidatesTags: [AccessAssignments, PatientCareProviders],
        }),
    }),
});

export const {
    useGetContactsQuery,
    useDeleteAccessAssignmentsByIdsMutation,
    useDeleteAccessAssignmentByIdMutation
} = ProgramAccessApi;

function combineAccessesGroupedByLevel(contactsGroupedByAccessLevel) {
    return [
        ...contactsGroupedByAccessLevel.topLevelAccess.map((access) => ({ ...access, accessLevel: "top" })),
        ...contactsGroupedByAccessLevel.programLevelAccess.map((access) => ({ ...access, accessLevel: "program" })),
    ];
}
