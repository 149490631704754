import React, {Component, Fragment} from 'react';
import {
    withStyles,
    MenuItem,
    ListItemText,
    Select,
    FormControl,
    Divider,
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {
    htmlWhite,
    linkWater,
} from 'components/colors/Colors';
import _ from 'lodash';
import {reminderColor} from 'scenes/case/CaseUtils';
import HmoFilledButton from 'components/button/HmoFilledButton';
import HmoInputLabel from 'components/input-label/HmoInputLabel';
import Title from 'components/title/Title';
import DatePicker from 'components/calendar/DatePicker';
import HmoTextField from 'components/hmo-textfield/HmoTextField';

const styles = theme => ({
    inputRoot: {
        backgroundColor: htmlWhite,
        borderRadius: 4,
    },
    avatar: {
        margin: 0
    },
    entryContainer: {
        borderRadius: 6,
        margin: 10,
        marginTop: 20,
        padding: 15
    },
    inputLabel: {
        textTransform: 'uppercase',
        marginRight: 10,
        color: htmlWhite,
        fontSize: 11
    },
    todoSelectRoot: {
        backgroundColor: htmlWhite,
        borderRadius: 4,
        paddingLeft: 7,
    },
});

const Todo = (props) => {
    const {classes, status, handleStatusChange, translate, statuses, isEditDisabled} = props;
    return (
        <Flex item container>
            <FormControl fullWidth>
                <Title>TODO</Title>
                <Select
                    value={status || ''}
                    classes={{root: classes.todoSelectRoot}}
                    onChange={handleStatusChange}
                    fullWidth
                    disabled={isEditDisabled}
                    renderValue={value => translate(`case.status.${value}`)}
                    name={'status'}
                >
                    {statuses && statuses.map(statusInArray => (
                        <MenuItem key={statusInArray.id} value={statusInArray.id}>
                            <ListItemText primary={translate(`case.status.${statusInArray.id}`)}/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Flex>
    );
};

const Comment = (props) => {
    const {classes, isCommentVisible, comment, translate, handleCaseChange, toggleComments, isEditDisabled} = props;
    return (
        <Flex item container column fullWidth style={{marginTop: 15, marginBottom: 0}}>
                {
                    isCommentVisible || !_.isEmpty(comment)
                        ?
                        <Fragment>
                            <HmoInputLabel>                                {translate('global.comment')}                            </HmoInputLabel>
                            <HmoTextField
                                classes={{root: classes.inputRoot}}
                                value={comment || ''}
                                disabled={isEditDisabled}
                                onChange={handleCaseChange}
                                fullWidth
                                name={'comment'}
                            />
                        </Fragment>
                        :
                        <Flex container item style={{marginBottom: 10}}>
                            <HmoFilledButton onClick={toggleComments} disabled={isEditDisabled}>
                                + {translate('global.add-new-comment')}
                            </HmoFilledButton>
                        </Flex>
                }

        </Flex>
    );
};

const Reminder = (props) => {
    const {
        classes, translate, reminderDate, handleCaseChange, handleReminderDateChange,
        reminderAction, isEditDisabled
    } = props;
    let reminderDateColor = reminderColor(reminderDate);
    return (
        <Flex item container column style={{marginTop: 10, marginBottom: 10}}>
            <Title>{translate('global.reminder')}</Title>
            <Flex item container>
                <DatePicker {...{
                    value: reminderDate,
                    name: 'reminderDate',
                    disabled: isEditDisabled,
                    onChange: handleReminderDateChange,
                    color: reminderDateColor
                }}/>
                <Flex item container column>
                    <HmoInputLabel>{translate('global.action')}</HmoInputLabel>
                    <HmoTextField
                        classes={{root: classes.inputRoot}}
                        style={{...(reminderDateColor && {color: reminderDateColor})}}
                        disabled={isEditDisabled}
                        value={reminderAction || ''}
                        onChange={handleCaseChange}
                        fullWidth
                        name={'reminderAction'}
                    />
                </Flex>
            </Flex>
        </Flex>
    );
};

const Termin = (props) => {
    const {
        classes, patientAppointmentDate, handlePatientAppointmentDateChange, translate,
        patientAppointmentComment, handleCaseChange, isEditDisabled
    } = props;
    return (
        <Flex item container column style={{marginTop: 10, marginBottom: 10}}>
            <Title>{translate('global.patient-termin')}</Title>
            <Flex item container>
                <DatePicker {...{
                    value: patientAppointmentDate,
                    name: 'patientAppointmentDate',
                    disabled: isEditDisabled,
                    onChange: handlePatientAppointmentDateChange,
                }}/>
                <Flex item container column>
                    <HmoInputLabel>{translate('global.comment')}</HmoInputLabel>
                    <HmoTextField
                        classes={{root: classes.inputRoot}}
                        disabled={isEditDisabled}
                        value={patientAppointmentComment || ''}
                        onChange={handleCaseChange}
                        fullWidth
                        name={'patientAppointmentComment'}
                    />
                </Flex>
            </Flex>
        </Flex>
    );
};

export class TodoSection extends Component {

    state = {
        isCommentVisible: false,
    };

    handleCaseChange = event => {
        this.props.updateCase({
            [event.target.name]: event.target.value
        });
        if (event.target.name === 'comment' && event.target.value === '') {
            this.setState({
                isCommentVisible: false
            })
        }
    };

    handleStatusChange = event => {
        this.props.handleStatusChange(event.target.value);
    }

    handleReminderDateChange = date => {
        this.props.updateCase({
            reminderDate: date
        })
    };

    handlePatientAppointmentDateChange = date => {
        this.props.updateCase({
            patientAppointmentDate: date
        })
    };

    toggleComments = () => {
        this.setState({isCommentVisible: !this.state.isCommentVisible})
    };

    render() {
        const {
            handleCaseChange, toggleComments, handleReminderDateChange, handlePatientAppointmentDateChange,
            handleStatusChange
        } = this;
        const {classes, translate, statuses, isEditDisabled} = this.props;
        const {
            status, comment, reminderDate, reminderAction, patientAppointmentDate, patientAppointmentComment
        } = this.props.case;
        const isCommentVisible = this.state.isCommentVisible || !_.isEmpty(comment);

        return (
            <Flex item grow={0} container className={classes.entryContainer}
                  style={{
                      backgroundColor: linkWater,
                      minHeight: 200,
                      height: 'fit-content'
                  }}
                  direction={'column'}>

                <Todo {...{classes, status, handleStatusChange, translate, statuses, isEditDisabled}}/>
                <Comment {...{
                    classes, isCommentVisible, comment, translate, handleCaseChange, toggleComments, isEditDisabled
                }}/>

                <Divider/>

                <Reminder {...{
                    classes, reminderAction, translate, reminderDate, handleCaseChange, isEditDisabled,
                    handleReminderDateChange,
                }}/>

                <Divider/>

                <Termin {...{
                    classes, patientAppointmentDate, handlePatientAppointmentDateChange, translate, isEditDisabled,
                    patientAppointmentComment, handleCaseChange
                }}/>
            </Flex>
        );
    }
}

export default withStyles(styles)(TodoSection);
