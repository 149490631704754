import {listClinicCenterAttachments, uploadClinicCenterFile, deleteClinicCenterFile} from 'scenes/FileHandler.action';
import {addClinicLog, fetchClinicLogs} from 'scenes/clinics/Clinics.action';

export default {
    mapStateToProps: (state) => {
        return {
            certifications: state.caseReducer.configuration.clinicCertifications,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            listClinicCenterAttachments: clinicCenterBusinessId => dispatch(listClinicCenterAttachments(clinicCenterBusinessId)),
            fetchClinicLogs: id => dispatch(fetchClinicLogs(id)),
            addClinicLog: (comment, clinicBusinessId, type) => dispatch(addClinicLog(comment, clinicBusinessId, type)),
            deleteClinicCenterFile: document => dispatch(deleteClinicCenterFile(document)),
            uploadClinicCenterFile: (file, fileName, clinicCenterBusinessId, entityId) =>
                dispatch(uploadClinicCenterFile(file, fileName, clinicCenterBusinessId, entityId)),
        };
    }
};
