import { useState, useEffect } from "react";
import {
    Modal,
    Box,
    Grid,
    Card,
    CardHeader,
    IconButton,
    CardContent,
    CardActions,
    Button,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import CloseIcon from "@mui/icons-material/Close";
import { useCheckIfProgramTemplateCanBeSafelyDeletedQuery } from "../../patient/ProgramApi";
import { useTranslation } from "react-i18next";

const DeleteProgramTemplateConfirmationModalContent = ({ onClose, onDelete, templateId, templateName }) => {
    const {
        data: canBeSafelyDeletedInfo = {},
        isFetching,
        isSuccess,
    } = useCheckIfProgramTemplateCanBeSafelyDeletedQuery({ templateId }, { refetchOnMountOrArgChange: true });
    const { t } = useTranslation();
    const [state, setState] = useState({
        unassignPatients: false,
        deleteTemplateInstances: false,
        unassignTemplateInstancesPatients: false,
    });
    const { unassignPatients, deleteTemplateInstances, unassignTemplateInstancesPatients } = state;
    const canDeleteWithoutQuestions = canBeSafelyDeletedInfo && Object.values(canBeSafelyDeletedInfo).every((x) => !x);

    const onlyHasPatients =
        canBeSafelyDeletedInfo.hasPatients &&
        !canBeSafelyDeletedInfo.hasTemplateInstances &&
        !canBeSafelyDeletedInfo.templateInstancesHavePatients;

    useEffect(() => {
        if (isSuccess) {
            if (onlyHasPatients) {
                setState({ ...state, unassignPatients: true });
            }
            // Perform your action here
        }
    }, [isSuccess, onlyHasPatients]);

    const modalContentBody = (
        <>
            {isFetching ? (
                <>
                    <Skeleton />
                    <div style={{ marginBottom: "10px" }}></div>
                    <Skeleton variant="rect" height={100} />
                </>
            ) : (
                <>
                    {onlyHasPatients ? (
                        <div>{t("delete-program-template-dialog.stop-program", {templateName})}</div>
                    ) : (
                        <>
                            <div>
                                {canDeleteWithoutQuestions
                                    ? t("delete-program-template-dialog.can-be-safely-deleted")
                                    : t("delete-program-template-dialog.choose-delete-options")}
                            </div>
                            {canBeSafelyDeletedInfo.hasPatients && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={unassignPatients}
                                            onChange={() => {
                                                setState({ ...state, unassignPatients: !unassignPatients });
                                            }}
                                            color="primary"
                                        />
                                    }
                                    label={t("delete-program-template-dialog.unsubscribe-patients")}
                                />
                            )}
                            {canBeSafelyDeletedInfo.hasTemplateInstances && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={deleteTemplateInstances}
                                            onChange={() => {
                                                setState({
                                                    ...state,
                                                    deleteTemplateInstances: !deleteTemplateInstances,
                                                });
                                            }}
                                            color="primary"
                                        />
                                    }
                                    label={t("delete-program-template-dialog.remove-templates")}
                                />
                            )}

                            {canBeSafelyDeletedInfo.templateInstancesHavePatients && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={unassignTemplateInstancesPatients}
                                            onChange={() => {
                                                setState({
                                                    ...state,
                                                    unassignTemplateInstancesPatients:
                                                        !unassignTemplateInstancesPatients,
                                                });
                                            }}
                                            color="secondary"
                                        />
                                    }
                                    label={t(
                                        "delete-program-template-dialog.unsubscribe-patients-from-derived-templates"
                                    )}
                                />
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );

    return (
        <Modal open={true}>
            <Box height={"100%"} alignItems={"center"} display={"flex"}>
                <Grid container justifyContent="center">
                    <Grid item xs={2}>
                        <Card>
                            <CardHeader
                                title={t("delete-program-template-dialog.title")}
                                action={
                                    <>
                                        <IconButton onClick={onClose} size="small">
                                            <CloseIcon />
                                        </IconButton>
                                    </>
                                }
                            />

                            <CardContent>{modalContentBody}</CardContent>
                            <CardActions style={{ justifyContent: "flex-end" }}>
                                <Button disabled={isFetching} onClick={onClose}>
                                    {t("global.cancel")}
                                </Button>
                                <Button
                                    disabled={isFetching}
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => {
                                        onDelete({
                                            unassignPatients,
                                            deleteTemplateInstances,
                                            unassignTemplateInstancesPatients,
                                        });
                                    }}
                                >
                                    {t("global.confirm-delete")}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};
export const DeleteProgramTemplateConfirmationModal = ({ isOpen, ...props }) => {
    return isOpen && <DeleteProgramTemplateConfirmationModalContent {...props} />;
};
