import {Container} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import styled from 'styled-components';

const Wrapper = styled.section``;


const Box = styled.div`
box-sizing: border-box;
flex-direction: row;
align-items: center;
padding: 28px 20px 28px 28px;
height: fit-content;
background: #F8F8FA;
border: 1px solid #E2E1E4;
border-radius: 15px;
`

const Title = styled.h1`
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 27px;
text-transform: capitalize;
color: #030303;
flex: none;
order: 0;
flex-grow: 0;
`;


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {}, '& .MuiGrid-container': {
            margin: theme.spacing(1),
        }, '& .MuiFormLabel-root': {
            marginLeft: '5px'
        }, '& .MuiInput-root': {
            border: '1px solid #E2E1E4 !important', borderRadius: '10px', padding: '5px', paddingLeft: '15px',
        }, '& .MuiInput-underline:before': {
            borderBottom: 'none',
        }, '& .MuiInput-underline:after': {
            borderBottom: 'none',
        }, '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 'none',
        }, '& input': {
            backgroundColor: '#FFFFFF',
        }, '& .MuiInputLabel-root': {
            transform: 'translate(0, 1.5px) scale(0.75)', transformOrigin: 'top left'
        }, '& .MuiInputBase-root': {
            backgroundColor: '#FFFFFF',
        },
         '& .MuiSlider-root': {
            color: '#245B67',
        },
        '& .MuiToggleButton-root': {
            '&.Mui-selected, &.Mui-selected:hover' : {
                backgroundColor: '#245B67',
                color: 'white',
            }
        }
    },
}));


const FormSection = ({title, children}) => {

    const styles = useStyles();

    return (<Wrapper className={styles.root}>
        <Container maxWidth={false}>
            <Title>{title}</Title>
            <Box>
                {children}
            </Box>
        </Container>
    </Wrapper>)
}

export default FormSection;
