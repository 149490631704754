import { WidgetType } from "./widgetTypes";

// Note: By default if value is not present here or in the field config, it will be an empty string

export const defaultRulePropertiesByWidgetType = {
    [WidgetType.RANGE_FILTER]: {
      value : {
        type: "range",
        from: "",
        to: "",
      },
    },
    [WidgetType.SWITCH]: {
        value: true,
    },
    [WidgetType.SIMPLE_NUMBER]: {
        operator: "",
        value: "",
    }
};

