import { Button, Tooltip } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { ReactComponent as MorningIcon } from "assets/img/morning.svg";
import { ReactComponent as NightIcon } from "assets/img/night.svg";
import { ReactComponent as EveningIcon } from "assets/img/evening.svg";
import { ReactComponent as NoonIcon } from "assets/img/noon.svg";
import moment from "moment";
import { IngredientRowRenderer } from "scenes/patient/medication-plan/ingredientRowRenderer";
import {datePartsToDate} from 'utils/Utils';

const DeleteTooltip = (params) => {
    const from = datePartsToDate(params.row.fromDateYear, params.row.fromDateMonth, params.row.fromDateDay);
    return params.row.deletionDate ? (
        <Tooltip
            title={`Prescribed from ${from.date ? from.date.format("DD MMM YYYY") : from.text} to ${moment(
                params.row.deletionDate
            ).format("DD MMM YYYY")}`}
            placement="top"
        >
            <div style={{ whiteSpace: "pre-wrap", wordBreak: "break-all" }}>
                <span>{from.date ? from.date.format("DD MMM") : from.text}</span> -{" "}
                <span>{moment(params.row.deletionDate).format("DD MMM")}</span>
            </div>
        </Tooltip>
    ) : (
        <Button walkthrough-element="remove-medication-btn" disabled={params.row.deletionDate}
                onClick={() => params.row.handleDeleteRow(params)}>
            <CancelIcon />
        </Button>
    );
}

export const tableColumnsForSpecialType = [
    {
        field: "ingredient",
        headerName: "ingredient",
        valueGetter: (e) => `${e.row?.ingredient ?? "(UNDEFINED)"}`,
        renderCell: (params) => {
            const { updatedAt, updatedBy, lastModifierUserFullName, getRowClassName } = params.row;
            const { value } = params;
            return <IngredientRowRenderer
                    updatedBy={updatedBy}
                    updatedAt={updatedAt}
                    className={getRowClassName(params)}
                    lastModifierUserFullName={lastModifierUserFullName}
                    value={value}
                />
        },

        minWidth: 220,
        flex: 1,
        width: 10,
    },
    {
        field: "strength",
        flex: 1,
        headerName: "strength",
    },
    {
        field: "medicineForm",
        flex: 1,
        headerName: "form",
    },
    {
        field: "irregularIntakeWhen",
        flex: 1,
        headerName: "when",
    },
    {
        field: "irregularIntakeUnit",
        flex: 1,
        headerName: "unit",
    },
    {
        field: "reasonForTreatment",
        flex: 1,
        headerName: "treatment for",
    },
    {
        field: "delete",
        flex: 1,
        maxWidth: 140,
        headerName: "delete",
        renderCell: DeleteTooltip,
    },
];

export const tableColumnsForRegularType = [
    {
        field: "ingredient",
        flex: 1,
        headerName: "ingredient",
        valueGetter: (e) => `${e.row?.ingredient ?? "(UNDEFINED)"}`,
        renderCell: (params) => {
            const { updatedAt, updatedBy, lastModifierUserFullName, getRowClassName } = params.row;
            const { value } = params;
            return <IngredientRowRenderer
                    updatedBy={updatedBy}
                    updatedAt={updatedAt}
                    className={getRowClassName(params)}
                    lastModifierUserFullName={lastModifierUserFullName}
                    value={value}
                />
        },
        width: 50,
        minWidth: 220,
        type: 'string'
    },
    {
        field: "strength",
        flex: 1,
        headerName: "strength",
        type: 'string'
    },
    {
        field: "medicineForm",
        flex: 1,
        headerName: "form",
        type: 'string'
    },
    {
        field: "morning",
        maxWidth: 100,
        headerName: "morning",
        customIcon: true,
        customComponent: <MorningIcon />,
        renderHeader: () => <MorningIcon />,
        valueGetter: (params) => {
            if (!params.value || params.value === 0) {
                return "-";
            }
            return params.value;
        },
        type: 'string'
    },
    {
        field: "noon",
        maxWidth: 100,
        flex: 1,
        headerName: "noon",
        customIcon: true,
        customComponent: <NoonIcon />,
        renderHeader: () => <NoonIcon />,
        valueGetter: (params) => {
            if (!params.value || params.value === 0) {
                return "-";
            }
            return params.value;
        },
        type: 'string'
    },
    {
        field: "evening",
        maxWidth: 100,
        flex: 1,
        headerName: "evening",
        customIcon: true,
        customComponent: <EveningIcon />,
        renderHeader: () => <EveningIcon />,
        valueGetter: (params) => {
            if (!params.value || params.value === 0) {
                return "-";
            }
            return params.value;
        },
        type: 'string'
    },
    {
        field: "night",
        maxWidth: 100,
        flex: 1,
        headerName: "night",
        customIcon: true,
        customComponent: <NightIcon />,
        renderHeader: () => <NightIcon />,
        valueGetter: (params) => {
            if (!params.value || params.value === 0) {
                return "-";
            }
            return params.value;
        },
    },
    {
        flex: 1,
        field: "reasonForTreatment",
        headerName: "treatment",
    },
    {
        flex: 1,
        maxWidth: 80,
        field: "delete",
        headerName: "delete",
        alignHeader: "right",
        align: "right",
        headerAlign: "center",
        renderCell: DeleteTooltip,
    },
];
