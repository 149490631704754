import ProgramStep from "./ProgramStep";
import {makeStyles} from "@material-ui/core";
import {isManager} from "domain/User.model";
import {Draggable} from "react-beautiful-dnd";
import AddNewStepButton from "./AddNewStepButton";
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: 1,
        position: "relative",
        background: "white",
    },
    ilRoot: {
        display: "block",
    },
}));

const Step = props => {
    const {
        step, index, editableId, setEditableId, program, saveStep, deleteStep, addStep,
        addStepButtonShouldBeShown, dragged, position, otherStepsInProgram, isNew, isStepCollapsed, toggleCollapse,
        markStepAsSeen, isLoading, refreshPrograms, isDragDisabled
    } = props;
    const classes = useStyles();
    const user = useSelector((state) => state.mainReducer.user);
    return <Draggable
            draggableId={"step_id_" + step.id}
            index={index}
            isDragDisabled={!isManager(user) || isDragDisabled}
            canDragInteractiveElements={true}
            key={"step_id_" + step.id}
    >
        {(provided, snapshot) => (
                <li ref={provided.innerRef} {...provided.draggableProps} className={classes.ilRoot}>
                    <ProgramStep
                            {...{
                                step,
                                position: index + 1,
                                user,
                                saveStep,
                                editableId,
                                setEditableId,
                                deleteStep,
                                dragHandleProps: provided.dragHandleProps,
                                otherStepsInProgram,
                                isNew,
                                isStepCollapsed,
                                markStepAsSeen,
                                toggleCollapse,
                                isLoading,
                                isProgramSuspended: program.suspendedParticipation,
                                refreshPrograms
                            }}
                    />
                    {addStepButtonShouldBeShown && (
                            <AddNewStepButton
                                    onClick={() => {
                                        addStep(position).then(() => {
                                        });
                                    }}
                                    disabled={dragged}
                            />
                    )}
                </li>
        )}
    </Draggable>
};

export default Step;
