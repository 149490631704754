import LogoutIcon from '@material-ui/icons/ExitToApp';
import {makeStyles} from "@material-ui/core/styles";
import Flex from 'components/grid/Flex';
import {Typography} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import {concrete} from 'components/colors/Colors';
import {logout} from 'scenes/Main.action';
import {useDispatch} from "react-redux";

const useStyles = makeStyles((theme) => ({
    profileContent: {
        paddingTop: 5,
        paddingBottom: 5,
        paddingRight: 9,
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: concrete,
            cursor: 'pointer'
        },
        whiteSpace: 'nowrap'
    },
    icon: {
        width: 29,
        height: 18,
        margin: 4
    },
    buttonText: {
        textTransform: 'none',
        '&:hover': {
            cursor: 'pointer'
        },
    }
}));

const LogoutWithIcon = () => {
    const classes = useStyles();
    const {t: translate} = useTranslation();
    const dispatch = useDispatch();
    const onLogout = ()=> dispatch(logout());
    return (
            <Flex item container
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  onClick={onLogout}
                  className={classes.profileContent}>
                <LogoutIcon className={classes.icon}/>
                <Typography className={classes.buttonText}>
                    {translate("login.logout")}
                </Typography>
            </Flex>
    );
};

export default LogoutWithIcon;
