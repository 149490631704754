import { useEffect, useRef } from 'react';

/**
 * This hook is used to scroll the newly created step into view
 * @param {*} editableId
 * @param {*} program
 *
 */
export const useScrollNewlyCreatedStepIntoView = (editableId, program) => {
    const stepsContainerRef = useRef(null);
    useEffect(() => {
        if(!editableId) return;
        const query =  `[data-rbd-draggable-id='step_id_${editableId}']`;
        const elementToScroll = stepsContainerRef.current.querySelector(query);
        if(elementToScroll) {
            elementToScroll.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [editableId, program, stepsContainerRef]);
    return { stepsContainerRef }
}
