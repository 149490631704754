import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/EditOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import { makeStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { useContext } from "react";
import { UploadFilesAction, UploadFilesDispatchContext } from "./uploadFilesState";
import DocumentListTile from "./DocumentListTile";
import { useSelector } from "react-redux";
import { isManager } from "domain/User.model";
import { useTranslation } from "react-i18next";
import { useMarkDocumentLinkAsReadMutation } from "./documentLInks";
import PermanentDocument from "./PermanentDocument";

export const useListOfFilesStyles = makeStyles((theme) => {
    return {
        root: {
            margin: "10px 0 0",
            padding: "0",
        },
        item: {
            paddingLeft: "6px",
            paddingTop: "8px",
            paddingBottom: "8px",
            marginTop: "4px",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            marginBottom: "6px",
            "&:hover": {
                outline: `1px solid ${theme.palette.primary.main}`,
            },
            borderRadius: "10px",
        },
        textDescription: {
            display: "flex",
            flexBasis: "70%",
            flexGrowth: 1,
            flexDirection: "column",
            lineHeight: "15px",
            fontSize: "14px",
            [theme.breakpoints.down("lg")]: {
                fontSize: "13px",
            },
            cursor: "pointer",
            color: "#030303",
            textDecoration: "none",
            overflow: "hidden",
            whiteSpace: "nowrap",
        },
        icon: {
            display: "flex",
            marginRight: "8px",
            color: theme.palette.info.main,
        },
        actionButtons: {
            flexShrink: 0,
            display: "flex",
            flexDirection: "row",
            marginLeft: "auto",
            marginTop: "2px",
        },
        deleteIcon: {
            "& path": {
                fill: theme.palette.error.main,
            },
        },
        primaryInfo: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            gap: "10px",
            marginTop: "4px",
            color: theme.palette.grey[800],
            fontSize: "12px",
        },
        documentTitle: {
            fontWeight: "bold",
            fontSize: "inherit",
        },
        additionalInfo: {
            marginTop: "4px",
            whiteSpace: "normal",
            fontSize: "12px",
            color: theme.palette.text.secondary,
        },
        linkUrl: {
            maxWidth: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
    };
});

/**
 * Component that displays a list of files.
 * @param {Object} props Component props.
 * @param {{ fullFileName: string, size: string, documentId: string, label: string, description, readStatus: "READ" | "UNREAD" }[]} props.files List of files to display.
 * @param {{ label: string, businessId: string, entityBusinessId: string, id: number, url: string, comment: string, fileStorageType: string, status: "VISITED" | "NEW"}[]} props.links List of links to display.
 * @param {Function} props.onDelete Callback to delete a file.
 * @returns {JSX.Element} Rendered component.
 */
const ListOfFiles = ({ files, links, locallyReadDocumentIds, permanentDocuments }) => {
    const { t } = useTranslation();
    const dispatchAction = useContext(UploadFilesDispatchContext);
    const [markDocumentLInkAsReadMutation] = useMarkDocumentLinkAsReadMutation();
    const classNames = useListOfFilesStyles();
    const user = useSelector((state) => state.mainReducer.user);
    return (
        <ul className={classNames.root}>
            {permanentDocuments.map((permanentDocument) => (
                <PermanentDocument key={permanentDocument.name + permanentDocument.label} {...permanentDocument} />
            ))}
            {files.map((file) => (
                <DocumentListTile
                    file={file}
                    key={file.documentId}
                    isFileShownAsNew={
                        isManager(user) && file.status === "UNREAD" && !locallyReadDocumentIds.includes(file.documentId)
                    }
                />
            ))}
            {links.map((link) => {
                const isLinkShownAsNew = isManager(user) && link.status === "NEW";
                return (
                    <li key={link.id} className={classNames.item}>
                        <LanguageIcon
                            style={{ color: isLinkShownAsNew ? "#9a50c6" : "" }}
                            className={classNames.icon}
                        />
                        <a
                            onClick={() => {
                                markDocumentLInkAsReadMutation(link.id);
                            }}
                            href={link.url}
                            target="_blank"
                            rel="noreferrer"
                            className={classNames.textDescription}
                        >
                            <div className={[classNames.documentTitle, classNames.linkUrl].join(" ")}>
                                <span style={{ color: "#9a50c6" }}>{isLinkShownAsNew && t("global.new") + ": "}</span>
                                <span style={{ textDecoration: "underline" }}>{link.description}</span>
                            </div>
                            <div className={classNames.primaryInfo}>
                                <time>{link.formattedUploadTime}</time>
                                <span style={{ marginLeft: "auto" }}>{link.label}</span>
                            </div>
                            {link.comment && <div className={classNames.additionalInfo}>{link.comment}</div>}
                        </a>
                        <div className={classNames.actionButtons}>
                            <IconButton
                                size="small"
                                onClick={() => {
                                    dispatchAction({
                                        type: UploadFilesAction.OpenDocumentLinkEditDialog,
                                        payload: link,
                                    });
                                }}
                            >
                                <EditIcon style={{ fontSize: "21px" }} />
                            </IconButton>
                            <IconButton
                                size="small"
                                onClick={() => {
                                    dispatchAction({
                                        type: UploadFilesAction.OpenDeleteConfirmationDialog,
                                        payload: {
                                            documentType: "LINK",
                                            id: link.id,
                                            documentName: link.description,
                                        },
                                    });
                                }}
                            >
                                <CancelIcon style={{ fontSize: "21px" }} className={classNames.deleteIcon} />
                            </IconButton>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

export default ListOfFiles;
