import DefaultDialogBody from './DefaultDialogBody';
import {useTranslation} from "react-i18next";
import {useAssignPatientToExitingUserMutation} from 'scenes/user-manager/UserDetailAccessApi';
import {useEnqueueSuccess, useEnqueueError} from 'components/alert/SnackbarHooks';
import moment from 'moment';
import utils from 'utils/Utils';

/**
 * Task-2357
 * Later we can offer a way to
 *      - Merge two patients
 *      - Add patient role to existing userdetail
 *      - Throw away the currently edited patient in favor of an exiting one (in case the manager realise they created
 *        a new patient by mistake)
 */
/**
 * DOCTOR_CASE_MANAGER_EDITS_PATIENT_EMAIL
 * CASE_MANAGER_GRANTS_ACCESS_TO_PATIENT
 */
const ManagerEditsPatientEmail = (props) => {
    const {
        toggleOpen, isSaveDisabled, userAlreadyExists, userDetailsAlreadyExists, deleteEmail, originalEmail,
        existingUser, personAlreadyHasAUser, inviteRequiresConfirmation, refresh, grantType, existingUserId,
        currentlyEditedUserDetailId
    } = props;
    const {t: translate} = useTranslation();
    const enqueueError = useEnqueueError();
    const enqueueSuccess = useEnqueueSuccess();

    let saveButtonLabel = props.saveButtonLabel;
    let information = '';
    let existingUserString = '';
    let save = props.save;
    let disabled = isSaveDisabled;
    const [assignPatientToExitingUser] = useAssignPatientToExitingUserMutation();

    const triggerGrantAccess = () => assignPatientToExitingUser({grantType, existingUserId, currentlyEditedUserDetailId})
            .unwrap().then(() => {
                enqueueSuccess();
                refresh && refresh();
                toggleOpen();
            }).catch(() => {
                enqueueError();
            })
            // (personAlreadyHasAUser && (userAlreadyExists || userDetailsAlreadyExists))
    if (userDetailsAlreadyExists || (personAlreadyHasAUser && userAlreadyExists)) {
        information = translate('access.already-used');
        disabled = true;
    } else if (userAlreadyExists && !personAlreadyHasAUser && !userDetailsAlreadyExists) {
        information = translate('access.already-used-no-assignment-question', {name: `(${existingUser?.givenName}, ${existingUser?.familyName})`});
        save = triggerGrantAccess;
        saveButtonLabel = translate('access.assign-patient');
    } else if (inviteRequiresConfirmation) {
        information = translate('access.invitation-already-sent');
        const date = inviteRequiresConfirmation?.creationDate
                ? moment(inviteRequiresConfirmation?.creationDate).format(utils.VIEW_DATE_FORMAT)
                : '';
        existingUserString = translate('access.existing-invitation') + `: ${inviteRequiresConfirmation?.invitedName} - ${date}`;
    }

    return (
            <DefaultDialogBody {...{
                information,
                toggleOpen,
                save,
                saveButtonLabel,
                disabled: disabled,
                deleteEmail,
                originalEmail,
                existingUserString
            }}/>
    );
};

export default ManagerEditsPatientEmail;
