import React, {Component, useState} from 'react';
import Flex from 'components/grid/Flex';
import {Button, CircularProgress, Dialog, IconButton, MenuItem, Typography} from '@material-ui/core';
import * as colors from 'components/colors/Colors';
import {withTranslation} from 'react-i18next';
import HmoInputWithAdornment from 'components/input/HmoInputWithAdornment';
import _ from 'lodash';
import {connect, useSelector} from 'react-redux';
import connector from './CareProviders.connect';
import {stringGet} from 'utils/Utils';
import Tag from 'components/tags/Tag';
import update from 'immutability-helper';
import Sidebar from 'components/sidebar/Sidebar';
import Title from 'scenes/managed-care/component/Title';
import {Link} from 'react-router-dom';
import {Search, CloseOutlined as CloseIcon, Settings, ArrowBack as BackIcon} from "@material-ui/icons";
import {MANAGED_CARE, MANAGED_CARE_PROGRAM_TEMPLATES, CARE_PROVIDERS} from 'routes/routes';
import {FixedSizeList} from 'react-window';
import {useResizeDetector} from 'react-resize-detector';
import CareProviderRow from './CareProviderRow';
import CareProviderHeader from './CareProviderHeader';
import NewCareProviderDialog from './NewCareProviderDialog';
import CareProviderEdit from './CareProviderEdit';
import utils from 'utils/Utils';
import EpiAlert from 'components/alert/EpiAlert';
import {withNavigation} from "utils/Route.utils";
import EmailTemplateManager from "./EmailTemplateManager";
import DefaultTextField from "../../components/hmo-textfield/DefaultTextField";
import {rainForest} from "components/colors/Colors";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        minWidth: '80vw',
        width: '80vw'
    },
}));

const Filters = (props) => {
    const {
        translate, toggleNewCareProviderDialog, programNamesFromCareProviders, selectedPrograms, toggleProgram,
        positiveSearch, stateChange
    } = props;
    return (
            <>
                <Flex item={'0 1 auto'} container alignItems={'center'} justifyContent={'space-between'} style={{
                    marginBottom: 10,
                    marginTop: 10,
                    marginLeft: 70,
                    marginRight: 10,
                }}>
                    <Flex item container alignItems={'center'}>
                        <HmoInputWithAdornment
                                value={positiveSearch}
                                placeholder={translate('global.search')}
                                onChange={stateChange}
                                name={'positiveSearch'}
                                rootStyle={{
                                    padding: 0,
                                    marginRight: 15,
                                    paddingLeft: 10,
                                    width: 420,
                                    border: '1px solid #859EC2',
                                    backgroundColor: 'unset'
                                }}
                                startAdornment={<Search/>}
                                endAdornment={
                                        !_.isEmpty(positiveSearch) &&
                                        <IconButton
                                                style={{
                                                    padding: 3,
                                                    backgroundColor: colors.silver,
                                                    fontSize: 13,
                                                    marginRight: 3
                                                }}
                                                onClick={() => {
                                                }}>
                                            <CloseIcon style={{color: colors.concrete, fontSize: 'inherit'}}/>
                                        </IconButton>
                                }
                        />
                        {
                            programNamesFromCareProviders.map((item, index) => (
                                    <Tag {...{
                                        key: index,
                                        selected: selectedPrograms?.includes(item),
                                        tag: item,
                                        toggleSelection: toggleProgram
                                    }}/>
                            ))
                        }
                        <IconButton component={Link} to={MANAGED_CARE_PROGRAM_TEMPLATES.path}>
                            <Settings/>
                        </IconButton>

                    </Flex>

                    <RegistryEmailTemplateManager translate={translate}/>

                    <Button onClick={toggleNewCareProviderDialog} style={{
                        backgroundColor: '#245B67', textTransform: 'capitalize', padding: '0px 30px',
                        color: colors.htmlWhite, maxHeight: 37, height: 37, borderRadius: 10
                    }} size={'small'}>
                        + {translate('global.add-care-provider')}
                    </Button>

                </Flex>
            </>
    );
};

function CareProviderWindow(props) {
    const {width, height, ref} = useResizeDetector();
    const {careProviders, selectCareProvider, selectedPrograms, toggleProgram} = props;
    return <div ref={ref} style={{
        height: '80vh',
        marginLeft: 70,
        marginRight: 10,
    }}>
        <FixedSizeList
                height={height || 300}
                itemCount={careProviders.length}
                itemSize={40}
                width={width || 500}
                itemData={{rows: careProviders}}
        >
            {({index, style}) => (
                    <div key={index} style={style}>
                        <CareProviderRow  {...{
                            careProvider: careProviders[index],
                            selectCareProvider,
                            selectedPrograms,
                            toggleProgram
                        }}/>
                    </div>
            )}
        </FixedSizeList>
    </div>;
}

const RegistryEmailTemplateManager = (props) => {
    const {translate} = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const toggleOpen = () => setOpen(!open);
    const [registry, setRegistry] = useState('');
    const careProviderRegistries = useSelector(state => state.caseReducer.configuration.registries) || [];
    return <>
        <Button variant={'outlined'} onClick={toggleOpen} style={{marginRight: 10}}>{translate('email-manager.edit-defaults')}</Button>
        <Dialog maxWidth={'xl'} open={open} onClose={toggleOpen} classes={{paper: classes.dialogPaper}}>
            <Flex item container column>
                <Flex item container grow={0} justifyContent={'flex-end'}>
                    <IconButton
                            disableRipple={true}
                            onClick={toggleOpen}
                    >
                        <CloseIcon style={{color: rainForest}}/>
                    </IconButton>

                </Flex>

                <EmailTemplateManager
                        {...{
                            emailTemplateEntityType: 'REGISTRY',
                            entityId: registry?.id,
                            additionalFilters: <DefaultTextField
                                    name={"registry"}
                                    onChange={event => setRegistry(event.target.value)}
                                    value={registry}
                                    label={translate('email-manager.registry')}
                                    select
                            >
                                {
                                    careProviderRegistries.map(item =>
                                            <MenuItem value={item} key={item.id}>
                                                {item.name}
                                            </MenuItem>)
                                }
                            </DefaultTextField>
                        }}
                />
            </Flex>
        </Dialog>
    </>;
};

export class CareProviders extends Component {

    state = {
        selectedCareProvider: {},
        sortParameter: 'name',
        reverseSort: 'false',
        positiveSearch: '',
        selectedPrograms: [],
        isNewCareProviderDialogOpen: false,
        programs: [],
        contacts: [],
        alert: "",
        selectedCareProviderHasBeenChanged: false
    }

    componentDidMount() {
        this.props.listCareProviders();
        this.props.getProgramTemplates()
                .then(response => this.setState({programs: response.payload.data.map(item => item.shortName)}));
    }

    handleSelectedCareProviderChange = (event, value) => {
        const target = event.target;
        this.setState((oldState) => {
            return {
                selectedCareProvider: update(oldState.selectedCareProvider, {
                    [target.name]: {$set: target.value},
                }),
                selectedCareProviderHasBeenChanged: true
            };
        });
    };

    saveSelectedCareProvider = () => {
        this.props.upsertCareProvider(this.state.selectedCareProvider)
                .then(() => {
                    this.setState({alert: 'success', selectedCareProviderHasBeenChanged: false});
                })
                .catch(() => this.setState({alert: 'error'}));
    }

    closeSnackbar = () => {
        this.setState({alert: ''});
    }

    onSort = sortParameter => event => {
        this.setState({
            sortParameter,
            reverseSort: this.state.sortParameter === sortParameter ? !this.state.reverseSort : false
        });
    };

    toggleProgram = program => {
        const index = this.state.selectedPrograms.findIndex(item => item === program);
        this.setState({
            selectedPrograms: index === -1
                    ? update(this.state.selectedPrograms, {
                        $push: [program]
                    })
                    : update(this.state.selectedPrograms, {
                        $splice: [[index, 1]]
                    })
        })
    }

    freeTextFilter = patient => {
        return this.state.positiveSearch === ''
                || stringGet(patient, 'name', '').toLowerCase().includes(this.state.positiveSearch.toLowerCase())
                || stringGet(patient, 'website', '').toLowerCase().includes(this.state.positiveSearch.toLowerCase())
                || stringGet(patient, 'city', '').toLowerCase().includes(this.state.positiveSearch.toLowerCase())
    };

    programsFilter = careProvider => {
        return this.state.selectedPrograms.length === 0
                ? true
                : _.every(this.state.selectedPrograms || [], item => (utils.toArray(careProvider.programs, ',')).includes(item))
    };

    stateChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    selectCareProvider = careProvider => event => {
        this.setState({selectedCareProvider: careProvider, isNewCareProviderDialogOpen: false});
    }

    toggleNewCareProviderDialog = () => {
        this.setState({
            isNewCareProviderDialogOpen: !this.state.isNewCareProviderDialogOpen
        })
    }

    cancel = () => {
        this.setState({selectedCareProvider: {}, selectedCareProviderHasBeenChanged: false});
    }

    render() {
        const {t: translate, fetchCareProviderContacts, careProviders, getAlreadyInvitedEmails, navigate} = this.props;
        const {
            selectedCareProvider, sortParameter, reverseSort, positiveSearch, selectedPrograms,
            isNewCareProviderDialogOpen, alert, programs, selectedCareProviderHasBeenChanged
        } = this.state;
        const {
            onSort, toggleProgram, freeTextFilter, programsFilter, stateChange, selectCareProvider, cancel,
            toggleNewCareProviderDialog, handleSelectedCareProviderChange, saveSelectedCareProvider
        } = this;
        const filteredCareProviders = careProviders
                .filter(freeTextFilter)
                .filter(programsFilter)
                .sort(utils.by({sortParameter, reverseSort}));

        const programNamesFromCareProviders = [...new Set(careProviders.flatMap(item => utils.toArray(item.programs, ',')))];

        return <>
            <Sidebar/>
            <Flex item container column>
                <Title title={translate(_.isEmpty(selectedCareProvider)
                        ? "global.care-providers"
                        : "global.care-provider"
                )} style={{marginLeft: 60}} onBack={() => {
                    navigate(CARE_PROVIDERS.path, {replace: true});
                    window.location.reload();
                }}/>
                {
                        !_.isEmpty(selectedCareProvider) &&
                        <CareProviderEdit {...{
                            selectedCareProvider, handleSelectedCareProviderChange, saveSelectedCareProvider, cancel,
                            programs, fetchCareProviderContacts, getAlreadyInvitedEmails, selectedCareProviderHasBeenChanged
                        }}/>
                }
                {
                        _.isEmpty(selectedCareProvider) &&
                        <>
                            <Filters {...{
                                translate,
                                toggleNewCareProviderDialog,
                                programNamesFromCareProviders,
                                selectedPrograms,
                                toggleProgram,
                                positiveSearch,
                                stateChange,
                            }}/>
                            <CareProviderHeader {...{
                                onSort, sortParameter, reverseSort
                            }}/>
                            {
                                    _.isEmpty(careProviders) &&
                                    <Flex item container center padding={20}>
                                        <Typography style={{fontSize: 20, fontWeight: "bold", opacity: 0.5}}>
                                            {translate("global.no-care-providers-yet")}
                                        </Typography>
                                    </Flex>
                            }
                            {
                                    !_.isEmpty(careProviders) &&
                                    <CareProviderWindow {...{
                                        careProviders: filteredCareProviders,
                                        selectCareProvider,
                                        selectedPrograms,
                                        toggleProgram
                                    }}/>
                            }
                            {
                                    isNewCareProviderDialogOpen &&
                                    <NewCareProviderDialog
                                            cancel={toggleNewCareProviderDialog} {...{selectCareProvider}}/>
                            }
                        </>
                }
                <EpiAlert
                        {...{
                            isOpen: !!alert,
                            close: this.closeSnackbar,
                            severity: alert,
                            message: alert === 'error'
                                    ? translate('global.backend-call-failed')
                                    : translate(`global.${alert}`)
                        }}
                />
            </Flex>
        </>;
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(withNavigation(CareProviders)));
