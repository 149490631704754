import { useEffect, useState } from "react";
import {
    Box,
    Grid,
    Modal,
    Card,
    CardHeader,
    IconButton,
    CardContent,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    Paper,
    TableBody,
    TablePagination,
    Checkbox,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchCareProviderContacts } from "scenes/user-manager/UserManager.action";

const AssignmentModalRow = ({ careProvider, handleClinicRemove, handleClinicSelect, isSelected }) => {
    const dispatch = useDispatch();
    const getContactDisplayName = (contact) => {
        if (!contact) {
            return '-';
        }
        return (contact.title || '') + ' ' + (contact.givenName || '') + ' ' + (contact.familyName || '').trim();
    };
    const [careProviderMainContact, setCareProviderMainContact] = useState();
    useEffect(() => {
        dispatch(fetchCareProviderContacts(careProvider.id)).then((response) => {
            const contacts = response?.payload?.data ?? [];
            const mainContact = contacts[0];
            if (mainContact) {
                setCareProviderMainContact(mainContact);
            }
    })
}, []);
    return <TableRow data-testid={`assignment-row-care-provider-id-${careProvider.id}`}>
        <TableCell padding="checkbox">
            <Checkbox
                checked={isSelected(careProvider.id)}
                onChange={(a, b) => {
                    b
                        ? handleClinicSelect(careProvider.id)
                        : handleClinicRemove(careProvider.id);
                }}
                color="primary"
            />
        </TableCell>
        <TableCell>{careProvider.name}</TableCell>
        <TableCell> {getContactDisplayName(careProviderMainContact)} </TableCell>
        <TableCell>{careProvider.city}</TableCell>
    </TableRow>;
};

const AssignmentModelContent = props => {
    const { onClose, save, programName} = props;
    const { t } = useTranslation();
    const careProviders = useSelector((state) => state.careProviderReducer.careProviders);
    const [selectedCareProviderIds, setSelectedCareProviderIds] = useState(props.selectedCareProvidersIds);

    const itemsPerPage = 10;
    const [page, setPage] = useState(0);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const isSelected = (careProvider) => selectedCareProviderIds.indexOf(careProvider) !== -1;
    const handleClinicSelect = (careProvider) => {
        setSelectedCareProviderIds([...selectedCareProviderIds, careProvider]);
    };
    const handleClinicRemove = (careProvider) => {
        setSelectedCareProviderIds(selectedCareProviderIds.filter((c) => c !== careProvider));
    };
    const onSave = () => save(Array.from(new Set(selectedCareProviderIds)));
    return (
        <Modal open={true}>
            <Box height={"100%"} alignItems={"center"} display={"flex"}>
                <Grid container justifyContent="center">
                    <Grid item xs={6}>
                        <Card>
                            <CardHeader
                                title={t('template-access-assignment.modal-title', {templateName: programName})}
                                action={
                                    <>
                                        <IconButton onClick={onClose} size="small">
                                            <CloseIcon />
                                        </IconButton>
                                        <IconButton data-testid="cp-program-assignment-modal-submit" onClick={onSave} size="small">
                                            <CheckIcon />
                                        </IconButton>
                                    </>
                                }
                            />

                            <CardContent>
                                {t('template-access-assignment.modal-subtitle', {templateName: programName})}
                                <TableContainer style={{ marginTop: "8px" }} variant="outlined" component={Paper}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell padding="checkbox"/>
                                                <TableCell>{t('template-access-assignment.name')}</TableCell>
                                                <TableCell>{t('template-access-assignment.main-contact')}</TableCell>
                                                <TableCell>{t('template-access-assignment.town')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {careProviders
                                                .slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)
                                                .map((careProvider) => ( <AssignmentModalRow {...{
                                                    key: careProvider.id,
                                                    careProvider,
                                                    handleClinicRemove,
                                                    handleClinicSelect,
                                                    isSelected
                                                }} /> ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {careProviders.length > itemsPerPage && <TablePagination
                                    component="div"
                                    count={careProviders.length}
                                    rowsPerPageOptions={[itemsPerPage]}
                                    rowsPerPage={itemsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                /> }
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export const AssignmentModel = ({ isOpen, ...props }) => {
    return isOpen && <AssignmentModelContent {...props} />;
};
