export function asyncQuerySelector(selector, maxAttempts = 10, interval = 250) {
  return new Promise((resolve, reject) => {
    let attempts = 0;

    function attemptQuery() {
      const element = document.querySelector(selector);

      if (element) {
        // Element found, resolve the promise
        resolve(element);
      } else {
        attempts++;

        if (attempts < maxAttempts) {
          // Retry after a delay
          setTimeout(attemptQuery, interval);
        } else {
          // Max attempts reached, reject the promise
          reject(new Error(`Element with selector ${selector} not found after ${maxAttempts} attempts`));
        }
      }
    }

    // Start the initial attempt
    attemptQuery();
  });
}
