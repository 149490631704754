import {Button, Dialog, DialogActions, DialogTitle, IconButton, Tooltip, useTheme} from '@material-ui/core';
import moment from "moment/moment";
import React, {useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {useSendProgramStepSpecialRequestMutation} from "scenes/patient/ProgramApi";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import {useEnqueueError, useEnqueueSuccess} from "components/alert/SnackbarHooks";
import {useGenerateEmailPreviewQuery} from "../../../EmailTemplateApi";
import Flex from "../../../../components/grid/Flex";
import Typography from "@material-ui/core/Typography";
import EmailTemplateManager, {EmailTemplateManagerWithButton} from "../../../care-provider/EmailTemplateManager";

const DialogContent = ({t, step, setDisabled}) => {
    const emailTemplateEntityType = 'PROGRAM_STEP';
    const emailTemplateType = 'Special_Request_For_Program_Step';
    const {data} = useGenerateEmailPreviewQuery({
        emailTemplateEntityType,
        entityId: step.id,
        language: 'de',
        emailTemplateType
    });
    const emailTemplate = data?.emailTemplate;
    useEffect(() => {
        setDisabled(!emailTemplate);
    }, [emailTemplate]);
    return <Flex item container column>
        <Typography style={{fontWeight: 'bold'}}>-------------------------------</Typography>
        {
                !emailTemplate &&
                <Typography style={{fontWeight: 'bold'}}>{t('program.template-missing')}</Typography>
        }
        <EmailTemplateManagerWithButton {...{
            emailTemplateEntityType,
            entityId: step.id,
            emailTypeFilter: item => item === emailTemplateType,
            warning: !emailTemplate ? ' ' : ''
        }}/>
    </Flex>;
};

const SpecialRequestEmail = ({step, refreshPrograms}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const [disabled, setDisabled] = useState(false);
    const enqueueError = useEnqueueError();
    const enqueueSuccess = useEnqueueSuccess();
    const [sendProgramStepSpecialRequest] = useSendProgramStepSpecialRequestMutation();
    const [isSpecialRequestDialogOpen, setIsSpecialRequestDialogOpen] = useState(false);
    const toggleSpecialRequestDialogOpen = () => setIsSpecialRequestDialogOpen(!isSpecialRequestDialogOpen);

    const sendStepSpecialRequest = () => {
        sendProgramStepSpecialRequest({programStepId: step.id}).unwrap().then(() => {
            enqueueSuccess(t('program.email-sent'));
            refreshPrograms();
        }).catch(() => {
            enqueueError(t('program.email-sent-error'));
        }).finally(() => {
            toggleSpecialRequestDialogOpen();
        });
    }
    return <>
        <Tooltip
                title={t(`program.special-request-tooltip${step.specialRequestSent ? "-resend" : ""}`,
                        {...(step.specialRequestSent && {date: moment(step.specialRequestSent).format('DD MMM YYYY, HH:mm')})})}>
            <IconButton onClick={toggleSpecialRequestDialogOpen} style={{padding: 3}}>
                <MailOutlineIcon style={{
                    color: !step.date
                            ? theme.palette.error.main
                            : step.specialRequestSent
                                    ? theme.palette.primary.main
                                    : theme.palette.warning.main
                }}/>
            </IconButton>
        </Tooltip>

        <ConfirmationDialog {...{
            singleConfirmationButton: !step?.date,
            onConfirm: step?.date ? sendStepSpecialRequest : toggleSpecialRequestDialogOpen,
            confirmationTextKey: step?.date
                    ? `program.${step.specialRequestSent ? "re" : ""}send-special-request-text`
                    : 'program.no-date-email',
            confirmationTextValues: {date: moment(step.specialRequestSent).format('DD MMM YYYY, HH:mm')},
            dialogOpen: isSpecialRequestDialogOpen,
            onDialogClose: toggleSpecialRequestDialogOpen,
            title: t(`program.${step.specialRequestSent ? "re" : ""}send-special-request-title`),
            confirmationDisabled: disabled
        }}>
            <DialogContent {...{t, step, setDisabled}}/>
        </ConfirmationDialog>
    </>;
};

export default SpecialRequestEmail;
