import {
    saveProgramTemplate,
    getProgramTemplates,
    newProgramTemplate,
    newProgramTemplateStep,
    deleteProgramTemplate
} from 'scenes/managed-care/ManagedCare.action';

export default {
    mapStateToProps: (state) => {
        return {
            configuration: state.caseReducer.configuration,
            user: state.mainReducer.user,
            programTemplates: state.managedCareReducer.programTemplates,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            saveProgramTemplate: programTemplate => dispatch(saveProgramTemplate(programTemplate)),
            getProgramTemplates: () => dispatch(getProgramTemplates()),
            newProgramTemplate: props => dispatch(newProgramTemplate(props)),
            newProgramTemplateStep: (programTemplateId, position) => dispatch(newProgramTemplateStep(programTemplateId, position)),
            deleteProgramTemplate: (programTemplateId, deleteOptions) => dispatch(deleteProgramTemplate(programTemplateId, deleteOptions))
        };
    }
};
