import Flex from "components/grid/Flex";
import { IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useAddCohortMutation, useListCohortsQuery, useRemoveCohortMutation } from "./CohortApi";
import React, { useState } from "react";
import HeaderItem from "../../components/header-item/HeaderItem";
import moment from "moment/moment";
import EditIcon from "@material-ui/icons/Edit";
import CloneIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import { useResizeDetector } from "react-resize-detector";
import { FixedSizeList } from "react-window";
import ConfirmationDialog from "../../components/confirmation-dialog/ConfirmationDialog";
import utils from "../../utils/Utils";
import { useEnqueueError, useEnqueueSuccess } from "../../components/alert/SnackbarHooks";
import { Cell } from "../managed-care/PatientRow";
import { Link, useNavigate } from "react-router-dom";
import { MANAGED_CARE } from "../../routes/routes";

const CohortHeaders = (props) => {
    const { t: translate } = useTranslation();
    const { onSort, sortParameter, reverseSort } = props;
    return (
        <Flex
            item
            container
            grow={0}
            style={{
                backgroundColor: "#F8F8FA",
                marginLeft: 20,
                marginRight: 10,
                height: 40,
                borderRadius: "15px 15px 0px 0px",
                border: "1px solid #E2E1E4",
            }}
        >
            <HeaderItem
                {...{
                    left: true,
                    title: translate("cohort.description-cohort"),
                    sortName: "name",
                    onSort,
                    reverseSort,
                    sortParameter,
                }}
            />

            <HeaderItem
                {...{
                    left: true,
                    title: translate("cohort.creator"),
                    sortName: "creator",
                    onSort,
                    reverseSort,
                    sortParameter,
                }}
            />

            <HeaderItem
                {...{
                    left: true,
                    title: translate("cohort.count-cohort"),
                    sortName: "count",
                    onSort,
                    reverseSort,
                    sortParameter,
                }}
            />

            <HeaderItem
                {...{
                    left: true,
                    title: translate("cohort.creationDate"),
                    sortName: "creationDate",
                    onSort,
                    reverseSort,
                    sortParameter,
                }}
            />

            <HeaderItem
                {...{
                    left: true,
                    title: "",
                    sortName: "",
                    onSort,
                    reverseSort,
                    sortParameter,
                }}
            />
        </Flex>
    );
};

const CohortRow = (props) => {
    const { cohort, cloneCohort, removeCohort } = props;
    const navigate = useNavigate();
    const onEdit = event => {
        navigate(MANAGED_CARE.path, {state: {cohortIdFromLocation: cohort.id}})
    }
    return (
        <Flex
            item
            container
            style={{ border: "1px solid lightgrey", borderTop: "unset", height: 40, paddingLeft: 10 }}
            center
        >
            <Cell left center={false} {...{ text: cohort.name }}/>
            <Cell left center={false} {...{ text: cohort.creator }}/>
            <Cell left center={false} {...{ text: cohort?.patientIds?.length }}/>
            <Cell left center={false} {...{ text: moment(cohort.creationDate).format("DD.MMM YY") }}/>
            <Cell left center={false}>
                <IconButton onClick={onEdit} style={{ padding: 3 }}>
                    <EditIcon />
                </IconButton>
                <IconButton onClick={()=>cloneCohort(cohort?.id)} style={{ padding: 3 }}>
                    <CloneIcon />
                </IconButton>
                <IconButton onClick={()=>removeCohort(cohort?.id)} style={{ padding: 3 }}>
                    <DeleteIcon />
                </IconButton>
            </Cell>
        </Flex>
    );
};

function CohortListWindow(props) {
    const { width, height, ref } = useResizeDetector();
    const { cohorts, cloneCohort, removeCohort } = props;
    return (
        <div
            ref={ref}
            style={{
                height: "80vh",
                marginLeft: 20,
                marginRight: 10,
            }}
        >
            <FixedSizeList
                height={height || 300}
                itemCount={cohorts.length}
                itemSize={40}
                width={width || 500}
                itemData={{ rows: cohorts }}
            >
                {({ index, style }) => (
                    <div key={index} style={style}>
                        <CohortRow
                            {...{
                                cohort: cohorts[index],
                                cloneCohort,
                                removeCohort,
                            }}
                        />
                    </div>
                )}
            </FixedSizeList>
        </div>
    );
}

const Cohorts = () => {
    const { data: cohorts = [] } = useListCohortsQuery();
    const [addCohort] = useAddCohortMutation();
    const [removeCohort] = useRemoveCohortMutation();
    const [idToClone, setIdToClone] = useState("");
    const [idToDelete, setIdToDelete] = useState("");
    const resetIdToClone = () => setIdToClone("");
    const resetIdToDelete = () => setIdToDelete("");
    const enqueueError = useEnqueueError();
    const enqueueSuccess = useEnqueueSuccess();
    const clone = () => {
        if (idToClone) {
            const cohort = cohorts?.find((item) => item.id === idToClone);
            addCohort({
                ...cohort,
                name: "Clone " + cohort.name,
                id: null,
            })
                .unwrap()
                .then(() => {
                    enqueueSuccess();
                })
                .catch((error) => {
                    enqueueError();
                })
                .finally(() => resetIdToClone());
        }
    };
    const remove = () => {
        removeCohort(idToDelete).unwrap()
                .then(() => {
                    enqueueSuccess();
                })
                .catch((error) => {
                    enqueueError();
                })
                .finally(() => resetIdToDelete());
    };

    const [sortParameter, setSortParameter] = useState("");
    const [reverseSort, setReverseSort] = useState(false);
    const onSort = (newSortParameter) => (event) => {
        setReverseSort(newSortParameter === sortParameter ? !reverseSort : false);
        setSortParameter(newSortParameter);
    };
    const orderedCohorts = sortParameter
        ? utils.orderByFalsyLast(cohorts, [sortParameter], [reverseSort ? "desc" : "asc"])
        : utils.orderByFalsyLast(cohorts, ["creationDate"], ["desc"]);

    return (
        <Flex item container column style={{ marginTop: 15 }}>
            <CohortHeaders
                {...{
                    onSort,
                    sortParameter,
                    reverseSort,
                }}
            />
            <CohortListWindow
                {...{
                    cohorts: orderedCohorts,
                    cloneCohort: setIdToClone,
                    removeCohort: setIdToDelete,
                }}
            />
            <ConfirmationDialog
                {...{
                    dialogOpen: idToClone,
                    onDialogClose: resetIdToClone,
                    onConfirm: clone,
                    confirmationTextKey: "cohort.cohort-clone-dialog",
                }}
            />
            <ConfirmationDialog
                {...{
                    dialogOpen: idToDelete,
                    onDialogClose: resetIdToDelete,
                    onConfirm: remove,
                    confirmationTextKey: "cohort.cohort-delete-dialog",
                }}
            />
        </Flex>
    );
};

export default Cohorts;
