import moment from 'moment';

const addDaysIfDateIsOnWeekend = (momentDate) => {
    const isSaturday = momentDate.isoWeekday() === 6;
    const isSunday = momentDate.isoWeekday() === 7;
    if(isSaturday) {
        return momentDate.add(2, 'days');
    } else if(isSunday) {
        return momentDate.add(1, 'days');
    } else {
        return momentDate;
    }

}

export const getStepDateWithAddedTimeSpan = ({fromDate, dateTimeSpan, timeSpanValue, skipWeekends = true}) => {
  if(dateTimeSpan === null || timeSpanValue === null) {
    return moment(fromDate).toISOString();
  }
  const newDate = moment(fromDate).add(timeSpanValue, dateTimeSpan.toLowerCase());
  const withAddedWeekends = skipWeekends ? addDaysIfDateIsOnWeekend(newDate) : newDate;
  return withAddedWeekends.toISOString();
};
