import { useTranslation } from "react-i18next";
import { useGetCountFromElasticQuery, useGetFilterQuery, useUpdateFilterMutation } from "./CohortApi";
import { useParams } from "react-router-dom";
import React, { useEffect, useReducer, useState } from "react";
import formStructureReducer from "./filter-state/reducer";
import { SET_INITIAL_STATE } from "./filter-state/actions";
import formStructureToElasticQuery from "./formStructureToElasticQuery";
import { useSelector } from "react-redux";
import { queryFormStateSchema } from "./filter-state/stateSchema";
import { throwableToPromise } from "../../utils/Utils";
import { useEnqueueError, useEnqueueSuccess, useEnqueueWarning } from "components/alert/SnackbarHooks";
import CohortFilter from "./CohortFilter";
import { useGetCareProvidersQuery } from "../patient/CareProviderApi";

const CohortFilterEdit = ({ route }) => {
    const { t: translate } = useTranslation();
    const { filterId } = useParams();
    const [updateFilter] = useUpdateFilterMutation();
    const [name, setName] = useState("");
    const [formTree, dispatch] = useReducer(formStructureReducer, []);
    const [isFilterOpen, setIsFilterOpen] = useState(true);

    const {data: clinics = []} = useGetCareProvidersQuery();
    const elasticQuery = formStructureToElasticQuery(formTree);
    const { data: filter = {}, isLoading: isLoadingFilter } = useGetFilterQuery(filterId);
    const { data, error, isLoading } = useGetCountFromElasticQuery(elasticQuery, { cacheTime: 30 });
    const currentUserId = useSelector((state) => state.mainReducer.user.id);
    const enqueueWarning = useEnqueueWarning();
    const enqueueError = useEnqueueError();
    const enqueueSuccess = useEnqueueSuccess();

    useEffect(() => {
        if (filter?.filterText && !isLoadingFilter) {
            throwableToPromise(JSON.parse)(filter.filterText)
            .then(filterJSON => queryFormStateSchema.validate(filterJSON))
            .then((validatedState) => {
                dispatch({ type: SET_INITIAL_STATE, payload: validatedState });
            })
            .catch((err) => {
                enqueueWarning(translate('cohort.filter-parse-error'));
                console.warn("Failed to parse/validate incoming filter. Error:\n", err);
            })
        }
        setName(filter?.name || "");
    }, [filter, isLoadingFilter]);
    const save = () =>
        updateFilter({
            id: filterId,
            name,
            filterText: JSON.stringify(formTree),
            esQuery: JSON.stringify(elasticQuery),
            creatorUserId: currentUserId,
            creationDate: new Date()
        }).then(() => {
            enqueueSuccess();
        })
        .catch(() => {
            enqueueError();
        })
    const toggleIsFilterOpen = () => setIsFilterOpen(!isFilterOpen);
    const [showResultsForQuery, setShowResultsForQuery] = useState("");
    useEffect(() => {
        if(JSON.stringify(elasticQuery) !== JSON.stringify(showResultsForQuery)){
            setShowResultsForQuery("");
        }
    }, [elasticQuery]);
    const showResults = () => {
        setShowResultsForQuery(elasticQuery);
    };
    return (
            <CohortFilter {...{
                name,
                setName,
                save,
                saveButtonText: translate("cohort.update"),
                toggleIsFilterOpen,
                formTree,
                dispatch,
                data,
                clinics,
                isLoading,
                showResultsForQuery,
                showResults,
                error
            }}/>
    );
};

export default CohortFilterEdit;
