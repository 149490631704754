import React, {Component} from 'react';
import {Dialog, Typography} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import AccountCircleOutlined from '@material-ui/icons/AccountCircleOutlined';
import DefaultTextField from 'components/hmo-textfield/DefaultTextField';
import HmoFilledButton from 'components/button/HmoFilledButton';
import HmoBorderedButton from 'components/button/HmoBorderedButton';
import utils from 'utils/Utils';
import {CLINICS_EDIT} from 'routes/routes';
import {withRouter} from 'utils/Route.utils';
import {connect} from 'react-redux';
import connector from 'scenes/clinics/Clinics.connect';

export class NewClinicDialog extends Component {

    state = {
        name: '',
        website: '',
        street: '',
        zipCode: '',
        country: '',
        state: '',
        city: '',
    }

    addClinic = () => {
        this.props.upsertClinic({
            businessId: utils.uuid(),
            name: this.state.name,
            website: this.state.website,
            street: this.state.street,
            zipCode: this.state.zipCode,
            country: this.state.country,
            state: this.state.state,
            city: this.state.city,
            latitude: '',
            longitude: '',
            centers: [],
            active: '',
            certifications: [],
        }).then(response => {
            this.props.navigate(CLINICS_EDIT.pathWithId(response.payload.data.businessId));
        })
    };

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    render() {
        const {t: translate, toggleNewClinicDialogOpen} = this.props;
        const {handleChange, addClinic} = this;
        const {name, website, street, zipCode, country, state, city} = this.state;
        return (
                <Dialog open={true}>
                    <Flex item container column padding={15}>
                        <Flex item container justifyContent={'space-between'} alignItems={'center'}
                              style={{marginRight: 7}}>
                            <Typography variant={"h5"}>{translate("global.new-clinic")}</Typography>
                            <AccountCircleOutlined/>
                        </Flex>
                        <DefaultTextField value={name || ''}
                                          label={translate("global.name")}
                                          error={!name}
                                          name={'name'}
                                          onChange={handleChange}/>

                        <DefaultTextField label={translate("case.street")}
                                          value={street || ''}
                                          name={'street'}
                                          onChange={handleChange}/>

                        <DefaultTextField label={translate("case.zip")}
                                          value={zipCode || ''}
                                          name={'zipCode'}
                                          onChange={handleChange}/>

                        <DefaultTextField label={translate("case.city")}
                                          value={city || ''}
                                          name={'city'}
                                          onChange={handleChange}/>

                        <DefaultTextField label={translate("case.state")}
                                          value={state || ''}
                                          name={'state'}
                                          onChange={handleChange}/>

                        <DefaultTextField label={translate("case.country")}
                                          value={country || ''}
                                          name={'country'}
                                          onChange={handleChange}/>

                        <DefaultTextField label={translate("case.website")}
                                          value={website || ''}
                                          name={'website'}
                                          onChange={handleChange}/>

                        <Flex item container style={{marginTop: 10, marginRight: 7}}>
                            <HmoBorderedButton onClick={toggleNewClinicDialogOpen} size={'small'}
                                               style={{width: '50%', marginRight: 10}}>
                                {translate("global.cancel")}
                            </HmoBorderedButton>
                            <HmoFilledButton onClick={addClinic} disabled={!name} style={{width: '50%'}}>
                                {translate("global.add-clinic")}
                            </HmoFilledButton>
                        </Flex>
                    </Flex>
                </Dialog>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(
        withTranslation()(
                withRouter(NewClinicDialog)
        )
);
