import { InputLabel } from "@material-ui/core";
import React from "react";

const Label = ({ Icon, label }) => {
    return (
        <InputLabel style={{ display: "flex", alignItems: "bottom", marginTop: -25 }}>
            <Icon style={{ height: 16, width: 16 }} /> &nbsp;{label}
        </InputLabel>
    );
};

export default Label;
