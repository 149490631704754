import Flex from 'components/grid/Flex';
import {Typography} from '@material-ui/core';
import SortIcon from "../sort-icon/SortIcon";
import React from "react";

const HeaderItem = (props) => {
    const {onSort, sortParameter, sortName, title, reverseSort, item, style, left } = props;
    const positioningStyles = left ? { paddingLeft: '14px', alignItems: 'center' } : {};
    return (
            <Flex item={item || '1 0 5%'} container onClick={onSort && onSort(sortName)} center={left ? false : true}
                  justifyContent='flex-start' style={style || {width: '5%', ...positioningStyles}}>
                <Typography style={{color: '#585757', fontSize: 13, textAlign: 'left', userSelect: 'none'}}>{title}</Typography>
                {
                        onSort &&
                        <SortIcon isShown={sortParameter === sortName} reverse={reverseSort}/>
                }
            </Flex>
    );
};

export default HeaderItem;
