export const CONFIRM_USER = '@confirmation_email/CONFIRM_USER';
export const CONFIRM_USER_SUCCESS = '@confirmation_email/CONFIRM_USER_SUCCESS';
export const CONFIRM_USER_FAIL = '@confirmation_email/CONFIRM_USER_FAIL';

export const SEND_PATIENT_EMAILS = '@confirmation_email/SEND_PATIENT_EMAILS';
export const SEND_PATIENT_EMAILS_SUCCESS = '@confirmation_email/SEND_PATIENT_EMAILS_SUCCESS';
export const SEND_PATIENT_EMAILS_FAIL = '@confirmation_email/SEND_PATIENT_EMAILS_FAIL';

export const PRINT_PATIENT_EMAILS = '@confirmation_email/PRINT_PATIENT_EMAILS';
export const PRINT_PATIENT_EMAILS_SUCCESS = '@confirmation_email/PRINT_PATIENT_EMAILS_SUCCESS';
export const PRINT_PATIENT_EMAILS_FAIL = '@confirmation_email/PRINT_PATIENT_EMAILS_FAIL';
