import React, { useState } from "react";
import { Dialog, Button, Typography } from "@material-ui/core";
import Flex from "components/grid/Flex";
import DefaultTextField from "components/hmo-textfield/DefaultTextField";
import utils from "utils/Utils";
import Divider from "components/divider/Divider";
import { isPatient } from "domain/User.model";
import EyeIcon from "@material-ui/icons/VisibilitySharp";
import { CLINIC, DOCTOR, ASSISTANT, PATIENT } from "domain/User.model";
import AccessInputSelector from "scenes/user-manager/access-manager/AccessInputSelector";
import { useInviteMutation } from "scenes/user-manager/UserDetailAccessApi";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useEnqueueSuccess, useEnqueueError } from "components/alert/SnackbarHooks";
import { cond, isEqual } from "lodash/fp";

/**
 *
 * @param {string} invitedRoleType
 * @param {number} textPart - Text part of translation string (1 or 2 or ..)
 * @param {boolean} loggedInUserIsPatient - Logged in user is patient
 * @returns {string} - Translation string
 */
const getInvitedRoleTranslationString = (invitedRoleType, loggedInUserIsPatient, textPart) =>
    cond([
        [(role) => [CLINIC, DOCTOR, ASSISTANT].includes(role), () => `global.invite-clinic-text-${textPart}`],
        [isEqual(PATIENT), () => `global.invite-text-${textPart}`],
        [() => true, () => `global.invite-text-${textPart}-${loggedInUserIsPatient ? "patient" : "manager"}`],
    ])(invitedRoleType);

const InviteManagerDialog = (props) => {
    const {
        person, toggleOpen, patient, invitedRoleType, emailAddress, refresh, onEmailClick, entityIdToAssign
    } = props;
    const { t: translate } = useTranslation();
    const user = useSelector((state) => state.mainReducer.user);
    const [phoneNumber, setPhoneNumber] = useState(person?.phoneNumbers?.[0]);
    const [name, setName] = useState(utils.formattedUserName(person));
    const [selectedAccesses, setSelectedAccesses] = useState([]);
    const enqueueError = useEnqueueError();
    const enqueueSuccess = useEnqueueSuccess();
    const stopPropagation = (event) => event.stopPropagation();
    const [invite] = useInviteMutation();
    const triggerInvite =
        selectedAccesses?.length > 0
            ? () => {
                  invite(
                      selectedAccesses.map((access) => ({
                          invitedUserDetailsId: person?.id,
                          inviteToken: utils.uuid(),
                          invitedEmail: emailAddress,
                          invitedName: name,
                          invitedPhoneNumber: phoneNumber,
                          invitedRoleType,
                          patronUserId: access.patronUserId,
                          patronRoleType: access.patronRoleType,
                          entityType: access.entityType,
                          entityId: access.entityId,
                      }))
                  )
                      .unwrap()
                      .then(() => {
                          enqueueSuccess();
                          refresh && refresh();
                          toggleOpen();
                      })
                      .catch((error) => {
                          enqueueError();
                      });
              }
            : () => {
                  console.log("Cannot invite! There are no selected accesses");
                  enqueueError();
              };

    const _isPatient = isPatient(user);

    const modalBodyContentParts = [
        getInvitedRoleTranslationString(invitedRoleType, _isPatient, 1),
        getInvitedRoleTranslationString(invitedRoleType, _isPatient, 2),
        getInvitedRoleTranslationString(invitedRoleType, _isPatient, 3),
        getInvitedRoleTranslationString(invitedRoleType, _isPatient, 4),
    ].map((v) => translate(v, {defaultValue: ''}));

    return (
        <Dialog open={true} maxWidth={"sm"} onClick={stopPropagation}>
            <Flex item container column padding={20} style={{ minWidth: 400, backgroundColor: "#f8f8fa" }}>
                {!_isPatient && (
                    <Flex
                        item={"0 1 auto"}
                        container
                        style={{
                            width: "50%",
                            borderBottom: "1px solid black",
                        }}
                    >
                        {[CLINIC, DOCTOR, ASSISTANT].includes(invitedRoleType) ? (
                            <Typography>
                                {translate(`global.contact`)}: &nbsp; {utils.formattedUserName(person)}
                            </Typography>
                        ) : (
                            <Typography>
                                {translate(`global.patient`)}: &nbsp; {utils.formattedUserName(patient)}
                            </Typography>
                        )}
                    </Flex>
                )}
                <Flex id={"TITLE"} item container alignItems={"center"} justifyContent={"space-between"}>
                    <Typography variant={"h6"}>
                        {[DOCTOR, ASSISTANT].includes(invitedRoleType)
                            ? translate("global.invite-care-provider")
                            : invitedRoleType === CLINIC
                            ? translate(`global.invite-clinic`)
                            : invitedRoleType === PATIENT
                            ? translate(`global.invite-title`)
                            : translate(`global.invite-title-${_isPatient ? "patient" : "manager"}`)}
                    </Typography>
                    <EyeIcon />
                </Flex>

                <Flex id={"DESCRIPTION"} item container column style={{ marginBottom: 10 }}>
                    {modalBodyContentParts.map((text) => (
                        text && <Typography gutterBottom={true}>{text}</Typography>
                    ))}
                </Flex>

                <DefaultTextField
                    value={emailAddress || ""}
                    label={translate("case.email")}
                    style={{ marginRight: 0, marginBottom: 10 }}
                    name={"emailAddress"}
                    onClick={onEmailClick}
                    onChange={onEmailClick}
                />

                {invitedRoleType !== PATIENT && (
                    <>
                        <Divider />
                        <DefaultTextField
                            value={name || ""}
                            label={translate("global.name")}
                            error={!name}
                            style={{ marginRight: 0 }}
                            name={"name"}
                            onChange={(event) => setName(event.target.value)}
                        />
                    </>
                )}

                <DefaultTextField
                    value={phoneNumber || ""}
                    label={translate("case.phone")}
                    helperText={translate("global.invite-phone-number")}
                    error={!phoneNumber}
                    style={{ marginRight: 0 }}
                    name={"phoneNumber"}
                    onChange={(event) => setPhoneNumber(event.target.value)}
                />

                <AccessInputSelector
                    {...{
                        invitedRoleType,
                        patientId: patient?.id,
                        personUserDetailsId: person?.id,
                        setSelectedAccesses,
                        entityId: entityIdToAssign,
                    }}
                />

                <Flex item container justifyContent={"space-between"}>
                    <Button variant={"outlined"} size={"small"} style={{ margin: 5 }} onClick={toggleOpen}>
                        {translate("global.close")}
                    </Button>

                    <Button
                        data-testid="send-grant-access-invite-button"
                        color={"secondary"}
                        variant={"contained"}
                        size={"small"}
                        style={{ margin: 5 }}
                        onClick={triggerInvite}
                    >
                        {translate("global.invite")}
                    </Button>
                </Flex>
            </Flex>
        </Dialog>
    );
};

export default InviteManagerDialog;
