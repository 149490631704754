import React, {Component} from 'react';
import CaseRow from './CaseRow';
import CaseRowHeaders from './CaseRowHeaders';
import {DONE} from 'domain/WorkflowStatus';
import Flex from 'components/grid/Flex';
import _ from 'lodash';

export class History extends Component {
    render() {
        const {
            filteredCases, handleDoubleClick, handleClick, toggleTagSelection, selectedTags, caseManagers,
            sortBy, statusSortMap
        } = this.props;
        const {sortParameter, reverseSort} = statusSortMap[DONE] || {};
        return (
            <Flex item container direction={'column'}>
                <table style={{tableLayout: 'auto'}}>
                    <tbody>
                {
                    !_.isEmpty(filteredCases) &&
                    <CaseRowHeaders status={DONE} {...{sortBy, sortParameter, reverseSort}}/>
                }
                {
                    filteredCases.map(caseInArray => (
                        <CaseRow
                            key={caseInArray.businessId}
                            {...{
                                handleDoubleClick,
                                handleClick,
                                case: caseInArray,
                                toggleTagSelection,
                                selectedTags,
                                caseManagers
                            }}/>
                    ))
                }
                    </tbody>
                </table>
            </Flex>
        );
    }
}

export default History;
