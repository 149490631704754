import * as actions from './actions';
import { queryFormContext } from './context';
import reducer from './reducer';
import { queryFormStateSchema } from './stateSchema';

const module = {
    actions,
    queryFormContext,
    reducer,
    queryFormStateSchema,
}

export default module;
