import React, {Component} from 'react';
import {Dialog, Typography} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import AccountCircleOutlined from '@material-ui/icons/AccountCircleOutlined';
import DefaultTextField from 'components/hmo-textfield/DefaultTextField';
import HmoFilledButton from 'components/button/HmoFilledButton';
import HmoBorderedButton from 'components/button/HmoBorderedButton';
import {connect} from 'react-redux';
import connector from 'scenes/managed-care/program-template/ProgramTemplateManager.connect';

export class NewProgramTemplateDialog extends Component {

    state = {
        name: '',
        tag: '',
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    addProgramTemplate = () => {
        this.props.newProgramTemplate({
            parentTemplateId: null,
            careProviderId: null,
            name: this.state.name,
            shortName: this.state.tag
        }).then(response => {
            this.props.toggleNewProgramTemplateDialog();
            this.props.selectProgram(response?.payload?.data)();
        });
    }

    render() {
        const {t: translate, toggleNewProgramTemplateDialog} = this.props;
        const {handleChange, addProgramTemplate} = this;
        const {name, tag} = this.state;
        const disabled = !name || !tag || tag?.length > 9;
        return (
                <Dialog open={true} >
                    <Flex item container column padding={15}>
                        <Flex item container justifyContent={'space-between'} alignItems={'center'}
                              style={{marginRight: 7}}>
                            <Typography variant={"h5"}>{translate("global.new-program-template")}</Typography>
                            <AccountCircleOutlined/>
                        </Flex>
                        <DefaultTextField value={name || ''}
                                          label={translate("global.name")}
                                          name={'name'}
                                          onChange={handleChange}/>

                        <DefaultTextField label={translate("global.tag")}
                                          value={tag || ''}
                                          error={tag && tag.length > 9}
                                          helperText={
                                              tag && tag.length > 9
                                                      ? translate('global.tag-character-limit')
                                                      : ''
                                          }
                                          name={'tag'}
                                          onChange={handleChange}/>

                        <Flex item container style={{marginTop: 10, marginRight: 7, minWidth: 500}}>
                            <HmoBorderedButton onClick={toggleNewProgramTemplateDialog} size={'small'}
                                               style={{width: '50%', marginRight: 10}}>
                                {translate("global.cancel")}
                            </HmoBorderedButton>
                            <HmoFilledButton data-testid="add-new-program-template-submit-btn" onClick={addProgramTemplate} disabled={disabled} style={{width: '50%'}}>
                                {translate("global.add-program-template")}
                            </HmoFilledButton>
                        </Flex>
                    </Flex>
                </Dialog>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(
        withTranslation()(NewProgramTemplateDialog)
);
