/** @typedef {import('./HematologyRiskProfile.mapper').LaboratoryData} LaboratoryData */
/** @typedef {'mmolperl' | 'gperdl' | 'mikromolperll' | 'nmolperlml'} Unit */
/** @typedef {'hemoglobin' | 'kreatinin' | 'totalcholesterol' | 'ldlcholesterol' | 'hdlcholesterol' } Substance */

/**
 * Represents a blood analysis report.
 * @typedef {Object} BloodAnalysisValueUnitTuple
 * @property {[number, Unit]} hemoglobin - The hemoglobin level in the blood. Array containing level and unit.
 * @property {[number, Unit]} leukozyten - The count of leukocytes in the blood. Array containing count and unit.
 * @property {[number, Unit]} mcv - The mean corpuscular volume (MCV) in the blood. Array containing volume and unit.
 * @property {[number, Unit]} rdw - The red cell distribution width (RDW) in the blood. Array containing width and unit.
 * @property {[number, Unit]} thrombozyten - The count of thrombocytes in the blood. Array containing count and unit.
 */

import _ from 'lodash';
import {LaboratoryKeys} from "domain/Laboratory.model";


export function isAcceptableValue(value) {
    return value !== undefined && value !== null && value !== 0 && value !== '';
}

export function accumulateLaboratoryValuesWithUnit(laboratoryArray) {
    return _.sortBy([...laboratoryArray], 'recordDate')?.reduceRight((accumulator, lab) => {
        const keysWithoutValue = LaboratoryKeys.filter(key => !isAcceptableValue(accumulator[key]?.value));
        if (keysWithoutValue.length === 0) return accumulator;

        return {
            ...accumulator,
            ...keysWithoutValue.reduce((acc, key) => (
                {
                    ...acc,
                    [key]: {
                        value: lab[key],
                        unit: lab[key + 'Unit'],
                        recordDate: lab.recordDate
                    }
                }
            ), {})
        };
    }, {});
}

/**
 *
 * @param {Function.<string, Function>} mapper
 */
export function getLaboratoryValuesWithConvertedUnits(latestLaboratoryValues, mapper) {
    return Object.keys(latestLaboratoryValues).reduce((accumulator, key) => {
        const {value, unit, recordDate} = latestLaboratoryValues[key];
        return {
            ...accumulator,
            [key]: {
                value: mapper[key] ? mapper[key](value, unit) : value,
                recordDate
            }
        };
    }, {});

}

/**
 * Converts a value from one unit to another.
 *
 * @param {number} value - The value to be converted.
 * @param {Unit} fromUnit - The unit of the input value.
 * @param {Unit} toUnit - The unit to convert the value to.
 * @returns {number | null} The converted value, or null if the 'fromUnit' and 'toUnit' combination is not supported.
 */
export const convertHemoglobinUnits = (value, fromUnit, toUnit = 'gperdl') => {
    if (fromUnit === toUnit) return value;

    const molecularWeights = 62.05;

    const conversions = {
        'gperdl': {
            'mmolperl': v => molecularWeights ? v * (molecularWeights / 100) : null,
        },
        'mmolperl': {
            'gperdl': v => molecularWeights ? (v * 100) / molecularWeights : null,
        },
    };

    const convertTo2DecimalPointsIfNotNull = (v) => v ? parseFloat(v.toFixed(2)) : null;

    return conversions[fromUnit]?.[toUnit]
        ? convertTo2DecimalPointsIfNotNull(conversions[fromUnit][toUnit](value))
        : null;
};
