import React, {Component} from 'react';
import {
    withStyles,
    Button,
    Dialog,
    Typography,
    IconButton,
    Select,
    MenuItem,
    ListItemText,
    CircularProgress,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {htmlRed, grey, htmlLightGrey, htmlGrey} from 'components/colors/Colors';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import {
    Delete as DeleteIcon
} from "@material-ui/icons";
import HmoTextField from 'components/hmo-textfield/HmoTextField';
import Title from 'components/title/Title';
import HmoFilledButton from 'components/button/HmoFilledButton';
import HmoInputLabel from 'components/input-label/HmoInputLabel';
import _ from 'lodash';
import DatePicker from 'components/calendar/DatePicker';
import {withRouter} from 'utils/Route.utils';
import ConfirmationDialog from 'components/confirmation-dialog/ConfirmationDialog';

const styles = () => ({
    inputItem: {
        padding: '7px 7px',
    },
    cancelButton: {
        fontSize: 13,
        textTransform: 'unset',
        color: htmlLightGrey
    },
    selectRoot: {
        backgroundColor: '#F1F1F1',
        borderRadius: 4,
        paddingLeft: 7,
    },
});

const Reason = props => {
    const {classes, translate, reason, reasons, handleChange} = props;
    let reasonsWithEmpty = ["", ...reasons];
    return (
        <Flex item container className={classes.inputItem} column>
            <HmoInputLabel>{translate("case.reason")}</HmoInputLabel>
            <Select
                value={reason ?? ''}
                classes={{root: classes.selectRoot}}
                onChange={handleChange}
                fullWidth
                renderValue={value => translate(`case.cancel-reasons.${value}`)}
                name={'cancellationReason'}
            >
                {reasonsWithEmpty && reasonsWithEmpty.map(reasonInArray => (
                    <MenuItem key={reasonInArray} value={reasonInArray ? reasonInArray : null}>
                        <ListItemText
                            primary={reasonInArray ? translate(`case.cancel-reasons.${reasonInArray}`) : "-"}/>
                    </MenuItem>
                ))}
            </Select>
        </Flex>
    )
};

const Amount = props => {
    const {classes, translate, costCoverageValue, costCoverageAmounts, handleCaseChange} = props;
    return (
        <Flex container className={classes.inputItem}>
            <FormControl>
                <HmoInputLabel>{translate("case.amount")}</HmoInputLabel>
                <Select
                    value={costCoverageValue ?? ''}
                    classes={{root: classes.selectRoot, select: classes.selectAmount}}
                    onChange={handleCaseChange}
                    renderValue={value => value}
                    name={'costCoverageValue'}
                >
                    {costCoverageAmounts && costCoverageAmounts.map(amountInArray => (
                        <MenuItem key={amountInArray.value} value={amountInArray.value}>
                            <ListItemText primary={amountInArray.value + " " + amountInArray.description}/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Flex>
    )
};

const Comment = props => {
    const {classes, translate, comment, handleChange} = props;
    return (
        <Flex item container direction={'column'} className={classes.inputItem}>
            <Typography style={{
                textTransform: 'uppercase',
                color: grey,
                fontSize: '0.75rem',
            }}>{translate('global.comment')}</Typography>
            <HmoTextField
                value={comment || ''}
                multiline
                variant={'outlined'}
                rows={3}
                onChange={handleChange}
                fullWidth
                name={'cancellationComment'}
                inputPropsStyle={{
                    backgroundColor: '#F1F1F1'
                }}
                InputProps={{
                    style: {
                        padding: 5,
                    }
                }}
                style={{
                    marginBottom: 10
                }}
            />
        </Flex>

    )
};

const DataDeleteType = props => {
    const {translate, dataDeletionType, changeDataDeletionType} = props;
    return (
        <Flex item container column style={{marginTop: 20}}>
            <HmoInputLabel style={{
                borderBottom: `2px solid grey`
            }}>
                {translate('global.data-delete-type')}
            </HmoInputLabel>

            <FormControl component="fieldset">
                <RadioGroup aria-label="position" name="dataDeleteType"
                            value={dataDeletionType || ''}
                            onChange={changeDataDeletionType}>
                    <FormControlLabel
                        value="CANCEL"
                        control={<Radio color="primary"/>}
                        label={translate('global.cancel-case-keep-data')}
                    />
                    <FormControlLabel
                            value="ANONYMIZE_AND_KEEP_DOCUMENTS"
                            control={<Radio color="primary"/>}
                            label={translate('global.cancel-case-anonymize-data-keep-docs')}
                    />
                    <FormControlLabel
                        value="ANONYMIZE"
                        control={<Radio color="primary"/>}
                        label={translate('global.cancel-case-anonymize-data')}
                    />
                    <FormControlLabel
                        value="DELETE"
                        control={<Radio color="secondary"/>}
                        label={<Typography
                            style={{color: 'red'}}>{translate('global.cancel-case-delete-data')}</Typography>}
                    />
                </RadioGroup>
            </FormControl>

        </Flex>
    )
};

export class CancelCase extends Component {

    state = {
        date: moment(),
        isLoading: false,
        dataDeletionType: 'CANCEL',
        hardDeleteConfirmation: false,
        open: false
    };

    handleDateChange = date => {
        this.setState({
            date
        })
    };

    toggleOpen = () => {
        this.setState({
            open: !this.state.open
        })
    };

    changeDataDeletionType = (event, value) => {
        this.setState({
            dataDeletionType: value,
            hardDeleteConfirmation: value === 'DELETE'
        })
    };

    turnOffHardDeleteConfirmation = () => {
        this.setState({
            hardDeleteConfirmation: false
        })
    }

    cancelCase = () => {
        this.setState({
            isLoading: true
        }, ()=>{
            this.props.cancelCase(this.state.dataDeletionType)
        });
    };

    render() {
        const {classes, t: translate, isCaseCanceled, configuration, _case, handleCaseChange} = this.props;
        const {date, open, dataDeletionType, isLoading, hardDeleteConfirmation} = this.state;
        const {toggleOpen, changeDataDeletionType, cancelCase, turnOffHardDeleteConfirmation} = this;

        return (
            <>
                <IconButton style={{padding: 3}} onClick={toggleOpen}
                            disabled={isCaseCanceled}>
                    <DeleteIcon style={{fill: 'white'}}/>
                </IconButton>
                <Dialog open={open} onClose={toggleOpen} maxWidth="sm" fullWidth>
                    <Flex item container direction={'column'} padding={20}>
                        <Flex item container style={{paddingBottom: 10, borderBottom: `1px solid ${grey}`}}
                              alignItems={'center'} justifyContent={'space-between'}>
                            <Flex item container alignItems={'center'}>
                                <Title style={{color: htmlRed}}>{translate('case.cancel-case-title')}</Title>
                            </Flex>
                            <Flex item container justifyContent={'flex-end'}>
                                <Button onClick={toggleOpen} className={classes.cancelButton}>
                                    {translate('global.cancel')}
                                </Button>
                                {
                                    isLoading
                                        ?
                                        <Flex item={'0 0 0'} container padding={3}>
                                            <CircularProgress/>
                                        </Flex>
                                        :

                                        <HmoFilledButton disabled={
                                            _.isEmpty(_case.cancellationReason) || _.isEmpty(_case.costCoverageValue)
                                        }
                                                         style={{backgroundColor:
                                                                 _.isEmpty(_case.cancellationReason) || _.isEmpty(_case.costCoverageValue) ? htmlGrey : htmlRed
                                                             , color: 'white'}}
                                                         onClick={cancelCase}>
                                            {translate('case.cancel-case-button')}
                                        </HmoFilledButton>
                                }
                            </Flex>
                        </Flex>
                        <Flex item container column>
                            <DatePicker {...{
                                value: date,
                                name: 'cancellationDate',
                                label: translate("case.date"),
                                disabled: true,
                                style: {width: '100%', flex: '0 1 auto', padding: '7px 7px'}
                            }}/>
                            <Flex item container>
                                <Reason {...{
                                    classes,
                                    translate,
                                    reason: _case.cancellationReason,
                                    reasons: configuration.cancellationReasons,
                                    isEditable: true,
                                    handleChange: handleCaseChange
                                }} />
                            </Flex>
                            <Flex item container>
                                <Amount {...{
                                    classes,
                                    translate,
                                    costCoverageValue: _case.costCoverageValue,
                                    costCoverageAmounts: configuration.costCoverageAmounts,
                                    isEditable: true,
                                    handleCaseChange
                                }} />
                            </Flex>
                            <Comment
                                {...{
                                    classes,
                                    translate,
                                    comment: _case.cancellationComment,
                                    handleChange: handleCaseChange
                                }}
                            />
                            <DataDeleteType
                                {...{
                                    translate,
                                    dataDeletionType,
                                    changeDataDeletionType
                                }}
                            />
                        </Flex>
                    </Flex>
                    <ConfirmationDialog {...{
                        dialogOpen: hardDeleteConfirmation,
                        onDialogClose: turnOffHardDeleteConfirmation,
                        onConfirm: turnOffHardDeleteConfirmation,
                        singleConfirmationButton: true,
                        confirmationTextKey: 'global.delete-all-confirmation'
                    }}/>
                </Dialog>
            </>
        );
    }
}

export default withStyles(styles)(withTranslation()(withRouter(CancelCase)));
