import { useContext } from "react";
import { Grid, Modal, Card, Box, CardHeader, CardContent, TextField, IconButton, MenuItem } from "@material-ui/core";
import { useFormik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";
import { preventDotsInInput, splitFilenameByFileExtension } from "./utils";
import * as Yup from "yup";
import { UploadFilesAction, UploadFilesDispatchContext } from "./uploadFilesState";
import { useUpdateFileDescriptionMutation } from "./filesApi";

const EditDocumentModalForm = ({
    documentTypes,
    editedDocument,
    assignmentType,
}) => {
    const dispatchAction = useContext(UploadFilesDispatchContext);
    const [updateFileDescriptionMutation] = useUpdateFileDescriptionMutation();
    const { t } = useTranslation();
    const [name, extension] = splitFilenameByFileExtension(editedDocument.fileName);
    const formik = useFormik({
        initialValues: {
            fileName: name,
            type: documentTypes.find((docType) => docType.enumName === editedDocument.type),
            description: editedDocument.description,
            documentId: editedDocument.documentId,
            assignment: assignmentType,
        },
        validationSchema: Yup.object({
            fileName: Yup.string().required(),
        }),
        onSubmit: (values) => {
            if (!formik.isValid) return;
            updateFileDescriptionMutation({
                ...values,
                fileName: [values.fileName, extension].join("."),
            }).finally(() => {
                dispatchAction({ type: UploadFilesAction.CloseDocumentEditDialog})
            })
        },
    });

    return (
        <Modal open={true}>
            <Box mt={20}>
                <Grid container justifyContent="center">
                    <Grid item xs={4}>
                        <Card>
                            <CardHeader
                                title="Additional document description"
                                subheader={t("case.document-update-text")}
                                action={
                                    <>
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                dispatchAction({ type: UploadFilesAction.CloseDocumentEditDialog });
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        <IconButton size="small" onClick={formik.handleSubmit}>
                                            <CheckIcon />
                                        </IconButton>
                                    </>
                                }
                            />
                            <CardContent>
                                <form>
                                    <TextField
                                        style={{ marginBottom: "10px" }}
                                        fullWidth
                                        name="fileName"
                                        label={t("case.descriptive-name")}
                                        placeholder={t("case.document-upload-name-placeholder")}
                                        InputLabelProps={{ shrink: true }}
                                        onKeyPress={preventDotsInInput}
                                        value={formik.values.fileName}
                                        error={formik.touched.fileName && Boolean(formik.errors.fileName)}
                                        required
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <TextField
                                        style={{ marginBottom: "10px" }}
                                        fullWidth
                                        select
                                        name="type"
                                        label={t("case.type")}
                                        InputLabelProps={{ shrink: true }}
                                        value={formik.values.type}
                                        onChange={formik.handleChange}
                                    >
                                        {documentTypes.map((docType) => (
                                            <MenuItem key={docType.enumName} value={docType}>
                                                {docType.longName}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {/*
                                        This field is dissabled until file encryption process is rewritten
                                    */}
                                    {/* <TextField
                                        fullWidth
                                        label={t("documentUpload.assignment")}
                                        name="assignment"
                                        style={{ marginBottom: "10px" }}
                                        select
                                        InputLabelProps={{ shrink: true }}
                                        value={formik.values.assignment}
                                        onChange={formik.handleChange}
                                    >
                                        {assignmentTypes.map((assignmentType) => (
                                            <MenuItem key={assignmentType.businessId} value={assignmentType}>{renderAsignmentValue(assignmentType)}</MenuItem>
                                        ))}
                                    </TextField> */}
                                    <TextField
                                        fullWidth
                                        name="description"
                                        label={t("case.document-update-description")}
                                        multiline
                                        InputLabelProps={{ shrink: true }}
                                        value={formik.values.description}
                                        onChange={formik.handleChange}
                                    />
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

const EditDocumentModal = ({ isOpen, ...restOfProps }) => {
    return isOpen && restOfProps.editedDocument ? <EditDocumentModalForm {...restOfProps} /> : null;
};

export default EditDocumentModal;
