import { FormGroup } from "@material-ui/core";
import { Field } from "formik";
import Label from "components/input-label/Label";
import { ReactComponent as MorningIcon } from "assets/img/morning.svg";
import { ReactComponent as NightIcon } from "assets/img/night.svg";
import { ReactComponent as EveningIcon } from "assets/img/evening.svg";
import { ReactComponent as NoonIcon } from "assets/img/noon.svg";
import EpaTextField from "components/hmo-textfield/EpaTextField";

const RegularIntake = ({ lang, classes }) => {
    return (
        <FormGroup
            style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "10px",
            }}
            row
        >
            <Field
                className={`${classes["take-row"]} ${classes["take-shrink"]}`}
                name="morning"
                label={<Label Icon={MorningIcon} label={lang("morning")} />}
                as={EpaTextField}
            />
            <Field
                className={`${classes["take-row"]} ${classes["take-shrink"]}`}
                name="noon"
                label={<Label Icon={NoonIcon} label={lang("noon")} />}
                as={EpaTextField}
            />
            <Field
                className={`${classes["take-row"]} ${classes["take-shrink"]}`}
                name="evening"
                label={<Label Icon={EveningIcon} label={lang("evening")} />}
                as={EpaTextField}
            />
            <Field
                name="night"
                className={`${classes["take-row"]} ${classes["take-shrink"]}`}
                label={<Label Icon={NightIcon} label={lang("night")} />}
                as={EpaTextField}
            />
        </FormGroup>
    );
};

export default RegularIntake;
