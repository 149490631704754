import React, {Component} from 'react';
import {connect} from 'react-redux';
import connector from './Login.connect';
import Flex from 'components/grid/Flex';
import {ReactComponent as KrebsZweitmeinungLogo} from 'assets/img/krebszweitmeinung-logo.svg';
import CHIP_icon from 'assets/img/CHIP_clinic_icon.png';
import _ from 'lodash';
import LoginPage from './component/LoginPage';
import ForgotPassword from './component/ForgotPassword';
import LanguageSelector from 'components/language-selector/LanguageSelector';
import ResetPassword from './component/ResetPassword';
import {withRouter} from 'utils/Route.utils';
import {REGISTRATION} from 'routes/routes';

export class Login extends Component {
    state = {
        isPasswordVisible: false,
        userName: window.location.href.includes('localhost') ? 'case-manager' : '',
        password: window.location.href.includes('localhost') ? 'case-manager' : '',
        verifyCode: '',
        mfa: false,
        warning: '',
        view: 'LOGIN'
    };
    passwordInputRef = React.createRef();

    componentDidMount() {
        const token = this.props.routeParams.token;
        if (!_.isEmpty(token)) {
            this.changeView('RESET')
        }
    }

    changeState = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleSubmit = event => {
        this.props.login(this.state.userName?.toLowerCase(), this.state.password, this.state.verifyCode)
                .then((response) => {
                    this.setState({
                        mfa: response.payload.data.mfaRequired,
                        warning: ''
                    })
                })
                .catch((e) => {
                    const {error} = e;
                    this.setState(
                            {
                                warning: error.response.status,
                                verifyCode: ''
                            });
                });
        event.preventDefault();
    };

    togglePasswordVisibility = () => {
        this.setState(state => ({isPasswordVisible: !state.isPasswordVisible}));
    };

    onEnter = event => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.passwordInputRef.current.focus();
        }
    };

    changeView = view => {
        this.setState({view});
        if (view === 'LOGIN') {
            this.setState({
                mfa: false,
                warning: '',
            })
            this.props.navigate('/', {replace: true});
        }
    };

    goToRegister = () => {
        this.props.navigate(REGISTRATION.path, {replace: true});
    };

    render() {
        const {handleSubmit, onEnter, changeState, togglePasswordVisibility, changeView, goToRegister} = this;
        const {saveNewPassword, requestNewPassword} = this.props;
        return (
                <Flex item container direction={'column'} alignItems={'center'} padding={20}>
                    <Flex item container justifyContent={'flex-start'} style={{width: '100%'}}>
                        <Flex item container justifyContent={'flex-end'} padding={8}>
                            <LanguageSelector/>
                        </Flex>
                    </Flex>
                    <Flex item container style={{marginBottom: 10}}>
                        <KrebsZweitmeinungLogo style={{width: 220, height: 70, marginBottom: 40}}/>
                        <img src={CHIP_icon} alt={''} style={{
                            marginLeft: 20, marginTop: 6, width: 220, height: 61 //The chip icon is 3.6 ratio
                        }}/>
                    </Flex>

                    {
                        this.state.view === 'LOGIN' &&
                        <LoginPage {...{
                            warning: this.state.warning,
                            userName: this.state.userName,
                            password: this.state.password,
                            verifyCode: this.state.verifyCode,
                            mfa: this.state.mfa,
                            isPasswordVisible: this.state.isPasswordVisible,
                            passwordInputRef: this.passwordInputRef,
                            handleSubmit,
                            onEnter,
                            changeView,
                            changeState,
                            togglePasswordVisibility,
                            goToRegister
                        }}/>
                    }
                    {
                        this.state.view === 'FORGOT' && !this.state.mfa &&
                        <ForgotPassword {...{
                            warning: this.state.warning,
                            userName: this.state.userName,
                            password: this.state.password,
                            isPasswordVisible: this.state.isPasswordVisible,
                            passwordInputRef: this.passwordInputRef,
                            handleSubmit,
                            requestNewPassword,
                            changeView,
                            changeState,
                            togglePasswordVisibility
                        }}/>
                    }
                    {
                        this.state.view === 'RESET' && !this.state.mfa &&
                        <ResetPassword {...{
                            saveNewPassword,
                            token: this.props.routeParams.token,
                            changeView,
                        }}/>
                    }

                </Flex>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(withRouter(Login));
