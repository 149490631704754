
export default {
    mapStateToProps: (state) => {
        return {
            user: state.mainReducer.user
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
        };
    }
};
