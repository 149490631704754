export const LIST_CARE_PROVIDERS = '@care-providers/LIST_CARE_PROVIDERS';
export const LIST_CARE_PROVIDERS_SUCCESS = '@care-providers/LIST_CARE_PROVIDERS_SUCCESS';
export const LIST_CARE_PROVIDERS_FAIL = '@care-providers/LIST_CARE_PROVIDERS_FAIL';

export const GET_CARE_PROVIDER = '@care-providers/GET_CARE_PROVIDER';
export const GET_CARE_PROVIDER_SUCCESS = '@care-providers/GET_CARE_PROVIDER_SUCCESS';
export const GET_CARE_PROVIDER_FAIL = '@care-providers/GET_CARE_PROVIDER_FAIL';

export const DELETE_CARE_PROVIDER = '@care-providers/DELETE_CARE_PROVIDER';
export const DELETE_CARE_PROVIDER_SUCCESS = '@care-providers/DELETE_CARE_PROVIDER_SUCCESS';
export const DELETE_CARE_PROVIDER_FAIL = '@care-providers/DELETE_CARE_PROVIDER_FAIL';

export const UPSERT_CARE_PROVIDER = '@care-providers/UPSERT_CARE_PROVIDER';
export const UPSERT_CARE_PROVIDER_SUCCESS = '@care-providers/UPSERT_CARE_PROVIDER_SUCCESS';
export const UPSERT_CARE_PROVIDER_FAIL = '@care-providers/UPSERT_CARE_PROVIDER_FAIL';

export const FETCH_CONTACTS = '@care-providers/FETCH_CONTACTS_LOGS';
export const FETCH_CONTACTS_SUCCESS = '@care-providers/FETCH_CONTACTS_SUCCESS';
export const FETCH_CONTACTS_FAIL = '@care-providers/FETCH_CONTACTS_FAIL';
