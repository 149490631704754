import Flex from "components/grid/Flex";
import { IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useAddFilterMutation, useListFiltersQuery, useRemoveFilterMutation } from "./CohortApi";
import { useResizeDetector } from "react-resize-detector";
import { FixedSizeList } from "react-window";
import React, { useState } from "react";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import CloneIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import HeaderItem from "components/header-item/HeaderItem";
import ConfirmationDialog from "../../components/confirmation-dialog/ConfirmationDialog";
import { Link } from "react-router-dom";
import { FILTER_EDIT } from "../../routes/routes";
import utils from "../../utils/Utils";
import { useEnqueueError, useEnqueueSuccess } from "../../components/alert/SnackbarHooks";
import {Cell} from "../managed-care/PatientRow";

const FilterHeaders = (props) => {
    const { t: translate } = useTranslation();
    const { onSort, sortParameter, reverseSort } = props;
    return (
        <Flex
            item
            container
            grow={0}
            style={{
                backgroundColor: "#F8F8FA",
                marginLeft: 20,
                marginRight: 10,
                height: 40,
                borderRadius: "15px 15px 0px 0px",
                border: "1px solid #E2E1E4",
            }}
        >
            <HeaderItem
                {...{
                    left: true,
                    title: translate("cohort.description-filter"),
                    sortName: "name",
                    onSort,
                    reverseSort,
                    sortParameter,
                }}
            />

            <HeaderItem
                {...{
                    left: true,
                    title: translate("cohort.creator"),
                    sortName: "creator",
                    onSort,
                    reverseSort,
                    sortParameter,
                }}
            />

            <HeaderItem
                {...{
                    left: true,
                    title: translate("cohort.count-filter"),
                    sortName: "count",
                    onSort,
                    reverseSort,
                    sortParameter,
                }}
            />

            <HeaderItem
                {...{
                    left: true,
                    title: translate("cohort.creationDate"),
                    sortName: "creationDate",
                    onSort,
                    reverseSort,
                    sortParameter,
                }}
            />

            <HeaderItem
                {...{
                    left: true,
                    title: "",
                    sortName: "",
                    onSort,
                    reverseSort,
                    sortParameter,
                }}
            />
        </Flex>
    );
};

const FilterRow = (props) => {
    const { filter, editFilter, cloneFilter, removeFilter } = props;

    return (
        <Flex
            item
            container
            style={{ border: "1px solid lightgrey", borderTop: "unset", height: 40, paddingLeft: 10 }}
            center
        >
            <Cell left center={false} {...{ text: filter.name }}/>
            <Cell left center={false} {...{ text: filter.creator }}/>
            <Cell left center={false} {...{ text: filter.count }}/>
            <Cell left center={false} {...{ text: moment(filter.creationDate).format("DD.MMM YY") }}/>
            <Cell left center={false}>
                <IconButton
                    onClick={editFilter}
                    style={{ padding: 3 }}
                    to={FILTER_EDIT.pathWithId(filter?.id)}
                    component={Link}
                >
                    <EditIcon />
                </IconButton>
                <IconButton onClick={() => cloneFilter(filter?.id)} style={{ padding: 3 }}>
                    <CloneIcon />
                </IconButton>
                <IconButton onClick={() => removeFilter(filter?.id)} style={{ padding: 3 }}>
                    <DeleteIcon />
                </IconButton>
            </Cell>
        </Flex>
    );
};

function FilterListWindow(props) {
    const { width, height, ref } = useResizeDetector();
    const { filters, cloneFilter, removeFilter } = props;
    return (
        <div
            ref={ref}
            style={{
                height: "80vh",
                marginLeft: 20,
                marginRight: 10,
            }}
        >
            <FixedSizeList
                height={height || 300}
                itemCount={filters.length}
                itemSize={40}
                width={width || 500}
                itemData={{ rows: filters }}
            >
                {({ index, style }) => (
                    <div key={index} style={style}>
                        <FilterRow
                            {...{
                                filter: filters[index],
                                cloneFilter,
                                removeFilter,
                            }}
                        />
                    </div>
                )}
            </FixedSizeList>
        </div>
    );
}

const CohortFilters = () => {
    const { data: filters = [], isLoading } = useListFiltersQuery();
    const [addFilter] = useAddFilterMutation();
    const [removeFilter] = useRemoveFilterMutation();
    const [idToClone, setIdToClone] = useState("");
    const [idToDelete, setIdToDelete] = useState("");
    const resetIdToClone = () => setIdToClone("");
    const resetIdToDelete = () => setIdToDelete("");
    const enqueueError = useEnqueueError();
    const enqueueSuccess = useEnqueueSuccess();
    const clone = () => {
        if (idToClone) {
            const filterToClone = filters?.find((item) => item.id === idToClone);
            addFilter({
                ...filterToClone,
                name: "Clone " + filterToClone?.name,
                id: null,
            })
                .unwrap()
                .then(() => {
                    enqueueSuccess();
                })
                .catch((error) => {
                    enqueueError();
                })
                .finally(() => resetIdToClone());
        }
    };
    const remove = () => {
        removeFilter(idToDelete)
            .unwrap()
            .then(() => {
                enqueueSuccess();
            })
            .catch((error) => {
                enqueueError();
            })
            .finally(() => resetIdToDelete());
    };

    const [sortParameter, setSortParameter] = useState("");
    const [reverseSort, setReverseSort] = useState(false);
    const onSort = (newSortParameter) => (event) => {
        setReverseSort(newSortParameter === sortParameter ? !reverseSort : false);
        setSortParameter(newSortParameter);
    };
    const orderedFilters = sortParameter
        ? utils.orderByFalsyLast(filters, [sortParameter], [reverseSort ? "desc" : "asc"])
        : utils.orderByFalsyLast(filters, ["creationDate"], ["desc"]);

    return (
        <Flex item container column style={{ marginTop: 15 }}>
            <FilterHeaders
                {...{
                    onSort,
                    sortParameter,
                    reverseSort,
                }}
            />
            <FilterListWindow
                {...{
                    filters: orderedFilters,
                    cloneFilter: setIdToClone,
                    removeFilter: setIdToDelete,
                }}
            />
            <ConfirmationDialog
                {...{
                    dialogOpen: idToClone,
                    onDialogClose: resetIdToClone,
                    onConfirm: clone,
                    confirmationTextKey: "cohort.filter-clone-dialog",
                }}
            />
            <ConfirmationDialog
                {...{
                    dialogOpen: idToDelete,
                    onDialogClose: resetIdToDelete,
                    onConfirm: remove,
                    confirmationTextKey: "cohort.filter-delete-dialog",
                }}
            />
        </Flex>
    );
};

export default CohortFilters;
