import React, {useCallback} from 'react';
import {Grid, InputLabel, MenuItem} from "@material-ui/core";
import {SubQuestionSection} from "../auxiliary/SubTitle";
import {Field} from "formik";
import FormSection from "../FormSection";
import {validationError} from "../config/ValidationSchema";
import StandaloneToggleButton from "../input/StandaloneToggleButton";
import {useTranslation} from "react-i18next";
import BooleQuestion from "../input/BooleQuestion";
import InputDivider from "../input/InputDivider";
import Box from '@material-ui/core/Box';
import FieldSelect from '../input/FieldSelect';
import {QuestionWithFiltering} from './QuestionWithFiltering';

const ClinicalSymptomsForm = ({form, readonly, filters}) => {
    const {t} = useTranslation();
    const lang = useCallback((name) => t(`anamnesis.clinical.${name}`), [t]);

    return (
            <FormSection title={lang('title')}>
                <QuestionWithFiltering filters={filters} filterValue={form.values?.hasNYHA}>
                <BooleQuestion
                        disabled={readonly}
                        form={form}
                        fieldName="hasNYHA"
                        lang={lang}
                        info
                />

                {
                    form.values?.['hasNYHA'] !== null && form.values?.['hasNYHA'] === true &&
                    <Box mb={2}>
                        <Grid container spacing={0}>

                            <Grid item xs={5}>
                                <InputLabel shrink id="nyhaValueLabel">{lang('nyhaValueLabel')}</InputLabel>
                                <Field
                                        disabled={readonly}
                                        name="nyhaValue"
                                        fullWidth
                                        labelId="nyhaValueLabel"
                                        component={FieldSelect}
                                        displayEmpty
                                        {...validationError(form, 'nyhaValue')}
                                >
                                    <MenuItem value={"NYHA2"}>{lang('nyhaValue.NYHA2')}</MenuItem>
                                    <MenuItem value={"NYHA3"}>{lang('nyhaValue.NYHA3')}</MenuItem>
                                    <MenuItem value={"NYHA4"}>{lang('nyhaValue.NYHA4')}</MenuItem>
                                </Field>
                            </Grid>

                        </Grid>

                        <Box mt={1}>
                            <Grid container spacing={0}>
                                <Grid item xs={6}>
                                    <SubQuestionSection>{lang('periphereOdemeQuestion')}</SubQuestionSection>
                                </Grid>
                            </Grid>
                        </Box>


                        <Grid container spacing={0}>
                            <Box style={{display: 'flex', flexDirection: 'row', gap: '20px'}} mt={1}>
                                <Field
                                        disabled={readonly}
                                        name="periphereOdeme"
                                        as={StandaloneToggleButton}
                                        form={form}
                                >
                                    <span>{lang('periphereOdemeButton')}</span>
                                </Field>


                                <Field
                                        disabled={readonly}
                                        name="neckVeinCongestion"
                                        as={StandaloneToggleButton}
                                        form={form}
                                >
                                    <span>{lang('neckVeinCongestionButton')}</span>
                                </Field>


                                <Field
                                        disabled={readonly}
                                        name="ruhedyspnose"
                                        as={StandaloneToggleButton}
                                        form={form}
                                >
                                    <span>{lang('ruhedyspnoseButton')}</span>
                                </Field>


                                <Field
                                        disabled={readonly}
                                        name="rattlingNoises"
                                        as={StandaloneToggleButton}
                                        form={form}
                                >
                                    <span>{lang('rattlingNoisesButton')}</span>
                                </Field>
                            </Box>
                        </Grid>
                    </Box>
                }

                <InputDivider/>
                </QuestionWithFiltering>

                <QuestionWithFiltering filters={filters} filterValue={form.values?.hasCCS}>
                <BooleQuestion
                        disabled={readonly}
                        form={form}
                        fieldName="hasCCS"
                        lang={lang}
                        info
                />

                {
                    form.values?.['hasCCS'] !== null && form.values?.['hasCCS'] === true &&
                    <Grid container spacing={0}>

                        <Grid item xs={5}>
                            <Field
                                    disabled={readonly}
                                    name="ccsValue"
                                    fullWidth
                                    component={FieldSelect}
                                    displayEmpty
                                    {...validationError(form, 'ccsValue')}
                            >
                                <MenuItem value={"CCS2"}>{lang('CCS.CCS2')}</MenuItem>
                                <MenuItem value={"CCS3"}>{lang('CCS.CCS3')}</MenuItem>
                                <MenuItem value={"CCS4"}>{lang('CCS.CCS4')}</MenuItem>
                            </Field>
                        </Grid>
                    </Grid>
                }
                </QuestionWithFiltering>

            </FormSection>
    );
};

export default ClinicalSymptomsForm;
