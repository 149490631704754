import React, {useCallback} from 'react';
import {Grid, MenuItem, Select, TextField, Box} from "@material-ui/core";
import {SimpleSubInnerQuestionSection} from "../auxiliary/SubTitle";
import {Field} from "formik";
import FormSection from "../FormSection";
import {isPropertySelected} from "../config/ValidationSchema";
import DataListInputField from "../input/DataListInputField";
import EpaTextField from 'components/hmo-textfield/EpaTextField';
import BooleQuestion from "../input/BooleQuestion";
import {useTranslation} from "react-i18next";
import BooleSubQuestion from "../input/BooleSubQuestion";
import AnamnesisSelectbox from "../input/AnamnesisSelectbox";
import CustomYearPicker from "../input/CustomYearPicker";
import InputDivider from "../input/InputDivider";
import {PlaceholderOrValue} from '../input/FieldSelect';
import SubTitle from "../auxiliary/SubTitle";
import StandaloneToggleButton from "../input/StandaloneToggleButton";
import { QuestionWithFiltering } from './QuestionWithFiltering';

const CardioDiseaseForm = ({form, readonly, filters}) => {
    const {t} = useTranslation();
    const lang = useCallback((name) => t(`anamnesis.cardio.${name}`), [t]);

    return (
            <FormSection title={t('anamnesis.cardio.cardiovasclar-disase')}>

                <QuestionWithFiltering filters={filters} filterValue={form.values?.firstCardioDiseaseDate}>
                    <Grid container spacing={1}>
                        <Grid item xs={9}>
                            <SubTitle>
                                {lang('firstCardioDiseaseDateQuestion')}
                            </SubTitle>
                        </Grid>
                        <Grid item xs={3} style={{marginTop: 6}}>
                            <TextField
                                    disabled={readonly}
                                    name="firstCardioDiseaseDate"
                                    fullWidth
                                    autoComplete='off'
                                    onChange={form.handleChange}
                                    value={form.values.firstCardioDiseaseDate || ''}
                            />
                        </Grid>
                    </Grid>
                    <InputDivider/>
                </QuestionWithFiltering>

                <QuestionWithFiltering filters={filters} filterValue={form.values?.hasEnlargementOfTheAorta}>
                    <BooleQuestion disabled={readonly} form={form} fieldName="hasEnlargementOfTheAorta" lang={lang} info/>
                    <InputDivider/>
                </QuestionWithFiltering>
                <QuestionWithFiltering filters={filters} filterValue={form.values?.coronaryArteryDisease}>
                <BooleQuestion
                        disabled={readonly}
                        form={form}
                        fieldName="coronaryArteryDisease"
                        info={t('anamnesis.cardio.cardiovasclar-disase-info')}
                        question={t('anamnesis.cardio.cardiovasclar-disase-question')}
                />

                {
                    isPropertySelected(form, 'coronaryArteryDisease') &&
                    <>
                        <Grid container spacing={0}>
                            <Grid item xs={9}/>
                            <Grid item xs={3}>
                                <AnamnesisSelectbox disabled={readonly} form={form} fieldName="coronaryArteryDiseaseState">
                                    <MenuItem value={"1_VESSEL_AFFECTED"}>
                                        {t('anamnesis.cardio.1_VESSEL_AFFECTED')}
                                    </MenuItem>
                                    <MenuItem value={"2_VESSELS_AFFECTED"}>
                                        {t('anamnesis.cardio.2_VESSELS_AFFECTED')}
                                    </MenuItem>
                                    <MenuItem value={"3_VESSELS_AFFECTED"}>
                                        {t('anamnesis.cardio.3_VESSELS_AFFECTED')}
                                    </MenuItem>
                                </AnamnesisSelectbox>
                            </Grid>
                        </Grid>

                        <BooleSubQuestion
                                disabled={readonly}
                                form={form}
                                fieldName="haveBypassSurgery"
                                question={t('anamnesis.cardio.haveBypassSurgeryQuestion')}
                        />

                        {
                            isPropertySelected(form, 'haveBypassSurgery') &&
                            <DataListInputField
                                    disabled={readonly}
                                    form={form}
                                    fieldName="bypassSurgeryData"
                                    rowData={{date: '', value: ''}}
                            >
                                {({onChange, item, DefaultDeleteIcon}) =>
                                        <Grid container key={item.id} spacing={1}>
                                            <Grid item xs={3}>
                                                <CustomYearPicker
                                                        disabled={readonly}
                                                        fullWidth
                                                        name="date"
                                                        type="date"
                                                        label={t('anamnesis.date')}
                                                        value={item?.date ?? ''}
                                                        onChange={(e) => {
                                                            onChange({
                                                                id: item.id,
                                                                field: 'date',
                                                                props: {value: e.target.value}
                                                            })
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                />
                                            </Grid>

                                            <Grid item xs={3}>
                                                <TextField
                                                        disabled={readonly}
                                                        fullWidth
                                                        label={t('global.number')}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        select
                                                        SelectProps={{
                                                            value: item?.value || '',
                                                            name: 'bypassSurgeryData',
                                                            onChange: e => onChange({
                                                                id: item.id,
                                                                field: 'value',
                                                                props: {value: e.target.value},
                                                                displayEmpty: true,
                                                                labelId: "coronaryArteryDiseaseStateLabel",
                                                                renderValue: PlaceholderOrValue(item?.value, t)
                                                            })
                                                        }}
                                                >
                                                    <MenuItem value="BYPASS_1">
                                                        {t('anamnesis.cardio.bypassOption1')}
                                                    </MenuItem>
                                                    <MenuItem value="BYPASS_2">
                                                        {t('anamnesis.cardio.bypassOption2')}
                                                    </MenuItem>
                                                    <MenuItem value="BYPASS_3">
                                                        {t('anamnesis.cardio.bypassOption3')}
                                                    </MenuItem>
                                                    <MenuItem value="BYPASS_4">
                                                        {t('anamnesis.cardio.bypassOption4')}
                                                    </MenuItem>
                                                    <MenuItem value="BYPASS_5">
                                                        {t('anamnesis.cardio.bypassOption5')}
                                                    </MenuItem>
                                                </TextField>

                                            </Grid>
                                            <Grid item xs={2}>
                                                {!readonly && <DefaultDeleteIcon style={{
                                                    marginTop: '25px'
                                                }} item={item}/>}
                                            </Grid>

                                        </Grid>
                                }
                            </DataListInputField>
                        }

                        <BooleSubQuestion
                                disabled={readonly}
                                form={form}
                                fieldName="haveStentImplantation"
                                question={t('anamnesis.cardio.haveStentImplantationQuestion')}
                        />
                            {
                                isPropertySelected(form, 'haveStentImplantation') &&
                                <DataListInputField
                                        disabled={readonly}
                                        form={form}
                                        fieldName="haveStentImplantationData"
                                        rowData={{date: '', value: ''}}
                                >
                                    {({onChange, item, DefaultDeleteIcon}) =>
                                        <Grid container key={item.id} spacing={1}>
                                            <Grid item xs={3}>
                                                <CustomYearPicker
                                                        disabled={readonly}
                                                        name="date"
                                                        type="date"
                                                        fullWidth
                                                        label={t('anamnesis.date')}
                                                        value={item?.date ?? ''}
                                                        onChange={(e) => {
                                                            onChange({
                                                                id: item.id,
                                                                field: 'date',
                                                                props: {value: e.target.value}
                                                            })
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                />
                                            </Grid>

                                            <Grid item xs={3}>
                                                <EpaTextField
                                                        disabled={readonly}
                                                        name="value"
                                                        type="number"
                                                        placeholder={t('anamnesis.cardio.stentImplantationPlaceholder')}
                                                        label={t('global.number')}
                                                        value={item?.value ?? ''}
                                                        onChange={(e) => {
                                                            onChange({
                                                                id: item.id,
                                                                field: 'value',
                                                                props: {value: e.target.value}
                                                            })
                                                        }}
                                                />

                                            </Grid>
                                            <Grid item xs={2}>
                                                {!readonly && <DefaultDeleteIcon style={{
                                                    marginTop: '25px'
                                                }} item={item}/>}
                                            </Grid>

                                        </Grid>

                                    }
                                </DataListInputField>
                            }
                            <Box mt={2} />
                        </>
                    }
                    <InputDivider/>
                </QuestionWithFiltering>
                <QuestionWithFiltering filters={filters} filterValue={form.values?.haveMiocardialHeartAttack}>
                <BooleQuestion
                        disabled={readonly}
                        form={form}
                        fieldName={"haveMiocardialHeartAttack"}
                        question={t('anamnesis.cardio.haveMiocardialHeartAttackQuestion')}
                        info={t('anamnesis.cardio.haveMiocardialHeartAttackInfo')}
                />
                {
                    isPropertySelected(form, 'haveMiocardialHeartAttack') &&
                    <Box mb={2}>
                        <DataListInputField
                                disabled={readonly}
                                form={form}
                                fieldName="haveMiocardialHeartAttackData"
                                rowData={{date: null, value: 'HEART_ATTACK'}}
                        >
                            {({onChange, item, DefaultDeleteIcon}) => (
                                    <>
                                        <Grid container key={item.id} spacing={0}>
                                            <Grid item xs={3} style={{display: 'flex'}}>
                                                <CustomYearPicker
                                                        disabled={readonly}
                                                        name="date"
                                                        type="date"
                                                        fullWidth
                                                        label={t('anamnesis.date')}
                                                        value={item?.date ?? ''}
                                                        onChange={(e) => {
                                                            onChange({
                                                                id: item.id,
                                                                field: 'date',
                                                                props: {value: e.target.value}
                                                            })
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                {!readonly && <DefaultDeleteIcon style={{
                                                    marginLeft: 7,
                                                    marginTop: '25px'
                                                }} item={item}/>}
                                            </Grid>
                                        </Grid>
                                    </>
                            )}
                        </DataListInputField>
                    </Box>
                }

                <InputDivider/>
                </QuestionWithFiltering>
                <QuestionWithFiltering filters={filters} filterValue={form.values?.haveAtherosclerosis}>
                <BooleQuestion
                        disabled={readonly}
                        form={form}
                        fieldName={"haveAtherosclerosis"}
                        question={t('anamnesis.cardio.haveAtherosclerosisQuestion')}
                />

                {
                    isPropertySelected(form, 'haveAtherosclerosis') &&
                    <Box mb={2}>
                        <Grid container spacing={0}>
                            <Grid item xs={3}>
                                <AnamnesisSelectbox disabled={readonly} form={form} fieldName="haveAtherosclerosisValue"
                                                    label={t('anamnesis.cardio.haveAtherosclerosisValueLabel')}>
                                    <MenuItem value={"MEDICINAL"}>{t('anamnesis.cardio.MEDICINAL')}</MenuItem>
                                    <MenuItem value={"ZNSURGERY"}>{t('anamnesis.cardio.ZNSURGERY')}</MenuItem>
                                    <MenuItem value={"ZNSTENT"}>{t('anamnesis.cardio.ZNSTENT')}</MenuItem>
                                </AnamnesisSelectbox>

                            </Grid>
                        </Grid>

                        <Grid container spacing={0}>

                            <BooleSubQuestion
                                    disabled={readonly}
                                    form={form}
                                    fieldName="haveAtherosclerosisStentImplantation"
                                    question={t('anamnesis.cardio.haveStentImplantationOrSurgeryQuestion')}
                            />
                        </Grid>

                        {
                            isPropertySelected(form, 'haveAtherosclerosisStentImplantation') &&
                            <DataListInputField
                                    disabled={readonly}
                                    form={form}
                                    fieldName="haveAtherosclerosisStentImplantationData"
                                    rowData={{date: '', value: ''}}
                            >
                                {({onChange, item, DefaultDeleteIcon}) => (
                                        <Grid container key={item.id} spacing={1}>
                                            <Grid item xs={3}>
                                                <CustomYearPicker
                                                        disabled={readonly}
                                                        name="date"
                                                        fullWidth
                                                        type="date"
                                                        label={t('anamnesis.date')}
                                                        value={item?.date ?? ''}
                                                        onChange={(e) => {
                                                            onChange({
                                                                id: item.id,
                                                                field: 'date',
                                                                props: {value: e.target.value}
                                                            })
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                />
                                            </Grid>

                                            <Grid item xs={3}>
                                                <EpaTextField
                                                        disabled={readonly}
                                                        name="value"
                                                        type="number"
                                                        placeholder={t('anamnesis.cardio.howManyStentsQuestion')}
                                                        label={t('global.number')}
                                                        value={item?.value ?? ''}
                                                        onChange={(e) => {
                                                            onChange({
                                                                id: item.id,
                                                                field: 'value',
                                                                props: {value: e.target.value}
                                                            })
                                                        }}
                                                />

                                            </Grid>
                                            <Grid item xs={2}>
                                                {! readonly && <DefaultDeleteIcon style={{
                                                    marginTop: '25px'
                                                }} item={item}/>}
                                            </Grid>

                                        </Grid>
                                )}
                            </DataListInputField>
                        }
                    </Box>
                }

                <InputDivider/>
                </QuestionWithFiltering>
                <QuestionWithFiltering filters={filters} filterValue={form.values?.haveStroke}>
                <BooleQuestion
                        disabled={readonly}
                        form={form}
                        fieldName="haveStroke"
                        question={t('anamnesis.cardio.haveStrokeQuestion')}
                        info={t('anamnesis.cardio.haveStrokeQuestionInfo')}
                />

                {
                    isPropertySelected(form, 'haveStroke') &&
                    <Box mb={2}>
                        <Grid container spacing={0}>
                            <SimpleSubInnerQuestionSection>
                                {t('anamnesis.cardio.haveStrokeSubInnerQuestion')}
                            </SimpleSubInnerQuestionSection>
                        </Grid>

                        <DataListInputField
                                disabled={readonly}
                                form={form}
                                fieldName="haveStrokeData"
                                rowData={{date: '', value: ''}}
                        >
                            {({onChange, item, DefaultDeleteIcon}) => (
                                    <>
                                        <Grid container key={item.id} spacing={1}>
                                            <Grid item xs={3}>
                                                <CustomYearPicker
                                                        disabled={readonly}
                                                        name="date"
                                                        type="date"
                                                        fullWidth
                                                        label={t('anamnesis.date')}
                                                        value={item?.date ?? ''}
                                                        onChange={(e) => {
                                                            onChange({
                                                                id: item.id,
                                                                field: 'date',
                                                                props: {value: e.target.value}
                                                            })
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                        disabled={readonly}
                                                        fullWidth
                                                        label={t('global.value')}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        select
                                                        SelectProps={{
                                                            displayEmpty: true,
                                                            value: item?.value ?? null,
                                                            onChange: e => onChange({
                                                                id: item.id,
                                                                field: 'value',
                                                                props: {value: e.target.value}
                                                            }),
                                                            renderValue: PlaceholderOrValue(item?.value, t)
                                                        }}

                                                >
                                                    <MenuItem value="Ischaemic">
                                                        {t('anamnesis.cardio.Ischaemic')}
                                                    </MenuItem>
                                                    <MenuItem value="Bleeding">
                                                        {t('anamnesis.cardio.Bleeding')}
                                                    </MenuItem>
                                                    <MenuItem value="Unknown">
                                                        {t('anamnesis.cardio.Unknown')}
                                                    </MenuItem>
                                                </TextField>

                                            </Grid>
                                            <Grid item xs={2}>
                                                {!readonly && <DefaultDeleteIcon style={{
                                                    marginTop: 25
                                                }} item={item}/>}
                                            </Grid>

                                        </Grid>
                                    </>
                            )}
                        </DataListInputField>

                        <BooleSubQuestion
                                disabled={readonly}
                                form={form}
                                fieldName="haveStrokePermanentRestriction"
                                question={t('anamnesis.cardio.haveStrokePermanentRestrictionQuestion')}
                        />
                        {
                            isPropertySelected(form, 'haveStrokePermanentRestriction') &&
                            <Grid container spacing={0}>
                                <Grid item xs={6}>
                                    <Field
                                            disabled={readonly}
                                            name="haveStrokePermanentRestrictionDescription"
                                            type="text"
                                            label={t('global.describe-restriction')}
                                            as={EpaTextField}
                                    />
                                </Grid>
                            </Grid>
                        }
                    </Box>
                }

                <InputDivider/>
                </QuestionWithFiltering>

                <QuestionWithFiltering filters={filters} filterValue={form.values?.haveTransientIschemicAttack}>
                <BooleQuestion
                        disabled={readonly}
                        form={form}
                        fieldName="haveTransientIschemicAttack"
                        question={t('anamnesis.cardio.haveTransientIschemicAttackQuestion')}
                />
                {
                    isPropertySelected(form, 'haveTransientIschemicAttack') &&
                    <Box mb={2}>
                        <DataListInputField
                                disabled={readonly}
                                form={form}
                                fieldName="haveTransientIschemicAttackData"
                                rowData={{date: null, value: 'ISCHEMIC_HEART_ATTACK'}}
                        >
                            {({onChange, item, DefaultDeleteIcon}) => (
                                    <>
                                        <Grid container key={item.id} spacing={0}>
                                            <Grid item xs={3} style={{display: 'flex', alignItems: 'end'}}>
                                                <CustomYearPicker
                                                        disabled={readonly}
                                                        name="date"
                                                        fullWidth
                                                        type="date"
                                                        value={item?.date ?? ''}
                                                        onChange={(e) => {
                                                            onChange({
                                                                id: item.id,
                                                                field: 'date',
                                                                props: {value: e.target.value}
                                                            })
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                />
                                                {!readonly && <DefaultDeleteIcon item={item} style={{paddingBottom: 10}}/>}
                                            </Grid>
                                        </Grid>
                                    </>
                            )}
                        </DataListInputField>
                    </Box>
                }

                <InputDivider/>
                </QuestionWithFiltering>
                <QuestionWithFiltering filters={filters} filterValue={form.values?.haveAtherosclerosisLegArteries}>
                <BooleQuestion
                        disabled={readonly}
                        form={form}
                        fieldName="haveAtherosclerosisLegArteries"
                        lang={lang}
                        info
                        question={t('anamnesis.cardio.haveAtherosclerosisLegArteriesQuestion')}
                />
                {
                    isPropertySelected(form, 'haveAtherosclerosisLegArteries') &&
                    <Box mb={2}>
                        <Grid container spacing={0}>
                            <Grid item xs={4}>
                                <AnamnesisSelectbox
                                        disabled={readonly}
                                        form={form}
                                        fieldName={'haveAtherosclerosisLegArteriesStage'}
                                        label={t('anamnesis.cardio.haveAtherosclerosisLegArteriesStageLabel')}
                                >
                                    <MenuItem value="STAGE_I">{t('anamnesis.cardio.STAGE_I')}</MenuItem>
                                    <MenuItem value="STAGE_IIa">{t('anamnesis.cardio.STAGE_IIa')}r</MenuItem>
                                    <MenuItem value="STAGE_IIb">{t('anamnesis.cardio.STAGE_IIb')}</MenuItem>
                                    <MenuItem value="STAGE_III">{t('anamnesis.cardio.STAGE_III')}</MenuItem>
                                    <MenuItem value="STAGE_IV">{t('anamnesis.cardio.STAGE_IV')}</MenuItem>
                                    <MenuItem value="ZNOP">{t('anamnesis.cardio.ZNOP')}</MenuItem>
                                    <MenuItem value="ZNSTENT">{t('anamnesis.cardio.ZNSTENT')}</MenuItem>
                                </AnamnesisSelectbox>
                            </Grid>
                        </Grid>

                        <BooleSubQuestion
                                disabled={readonly}
                                form={form}
                                fieldName="haveAtherosclerosisLegArteriesStentImplantation"
                                question={t('anamnesis.cardio.haveAtherosclerosisLegArteriesStentImplantationQuestion')}
                        />
                        {
                            isPropertySelected(form, 'haveAtherosclerosisLegArteriesStentImplantation') &&
                            <DataListInputField
                                    disabled={readonly}
                                    form={form}
                                    fieldName="haveAtherosclerosisLegArteriesStentImplantationData"
                                    rowData={{date: '', value: ''}}
                            >
                                {({onChange, item, DefaultDeleteIcon}) => (
                                        <>
                                            <Grid container key={item.id} spacing={1}>
                                                <Grid item xs={3}>
                                                    <CustomYearPicker
                                                            disabled={readonly}
                                                            name="date"
                                                            type="date"
                                                            fullWidth
                                                            label={t('anamnesis.date')}
                                                            value={item?.date ?? ''}
                                                            onChange={(e) => {
                                                                onChange({
                                                                    id: item.id,
                                                                    field: 'date',
                                                                    props: {value: e.target.value}
                                                                })
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                    />
                                                </Grid>

                                                <Grid item xs={3}>
                                                    <EpaTextField
                                                            disabled={readonly}
                                                            name="value"
                                                            type="number"
                                                            placeholder={t('anamnesis.cardio.stentsPlaceholder')}
                                                            label={t('global.value')}
                                                            value={item?.value ?? ''}
                                                            onChange={(e) => {
                                                                onChange({
                                                                    id: item.id,
                                                                    field: 'value',
                                                                    props: {value: e.target.value}
                                                                })
                                                            }}
                                                    />

                                                </Grid>
                                                <Grid item xs={2}>
                                                    {!readonly && <DefaultDeleteIcon style={{
                                                        marginTop: '25px'
                                                    }} item={item}/>}
                                                </Grid>

                                            </Grid>
                                        </>
                                )}
                            </DataListInputField>
                        }
                    </Box>
                }

                <InputDivider/>
                </QuestionWithFiltering>
                <QuestionWithFiltering filters={filters} filterValue={form.values?.havePlumonaryArteryEmbolism}>
                <BooleQuestion
                        disabled={readonly}
                        form={form}
                        fieldName="havePlumonaryArteryEmbolism"
                        question={t('anamnesis.cardio.havePlumonaryArteryEmbolismQuestion')}
                />

                {
                    isPropertySelected(form, 'havePlumonaryArteryEmbolism') &&
                    <Box mb={2}>
                        <DataListInputField
                                disabled={readonly}
                                form={form}
                                fieldName="havePlumonaryArteryEmbolismData"
                                rowData={{date: null, value: 'PLUMONARY_ARTERY_EMBOLISM'}}
                        >
                            {({onChange, item, DefaultDeleteIcon}) => (
                                    <>
                                        <Grid container key={item.id} spacing={0}>
                                            <Grid item xs={3} style={{display: 'flex', alignItems: 'end'}}>
                                                <CustomYearPicker
                                                        disabled={readonly}
                                                        name="date"
                                                        type="date"
                                                        fullWidth
                                                        value={item?.date ?? ''}
                                                        onChange={(e) => {
                                                            onChange({
                                                                id: item.id,
                                                                field: 'date',
                                                                props: {value: e.target.value}
                                                            })
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                />
                                                {!readonly && <DefaultDeleteIcon item={item} style={{paddingBottom: 10}}/>}
                                            </Grid>
                                        </Grid>
                                    </>
                            )}
                        </DataListInputField>
                    </Box>
                }

                <InputDivider/>
                </QuestionWithFiltering>
                <QuestionWithFiltering filters={filters} filterValue={form.values?.haveCardiacEjectionFraction}>

                <BooleQuestion
                        disabled={readonly}
                        form={form}
                        info={t('anamnesis.cardio.haveCardiacEjectionFractionInfo')}
                        fieldName="haveCardiacEjectionFraction"
                        question={t('anamnesis.cardio.haveCardiacEjectionFractionQuestion')}
                />
                {
                    isPropertySelected(form, 'haveCardiacEjectionFraction') &&
                    <Box mb={2}>
                        <Grid container spacing={0}>
                            <Grid item xs={5}>
                                <SimpleSubInnerQuestionSection>{t('anamnesis.cardio.haveCardiacEjectionFractionSubSection')}</SimpleSubInnerQuestionSection>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                            <Grid item xs={4} style={{display: 'flex'}}>
                                <Field
                                        disabled={readonly}
                                        name="haveCardiacEjectionFractionPercent"
                                        type="number"
                                        placeholder={t('anamnesis.cardio.haveCardiacEjectionFractionPercentPlaceholder')}
                                        as={EpaTextField}
                                />
                                <div style={{margin: '10px', whiteSpace: 'nowrap'}}>
                                    % ODER
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <AnamnesisSelectbox
                                        disabled={readonly}
                                        form={form}
                                        displayEmpty
                                        fieldName={'haveCardiacEjectionFractionDescription'}
                                >
                                    <MenuItem value="NORMAL">
                                        {t('anamnesis.cardio.haveCardiacEjectionFractionDescription.NORMAL')}
                                    </MenuItem>
                                    <MenuItem value="SLIGHTLY_IMPAIRED">
                                        {t('anamnesis.cardio.haveCardiacEjectionFractionDescription.SLIGHTLY_IMPAIRED')}
                                    </MenuItem>
                                    <MenuItem value="MODERATELY_IMPAIRED">
                                        {t('anamnesis.cardio.haveCardiacEjectionFractionDescription.MODERATELY_IMPAIRED')}
                                    </MenuItem>
                                    <MenuItem value="HIGHLY_RESTRICTED">
                                        {t('anamnesis.cardio.haveCardiacEjectionFractionDescription.HIGHLY_RESTRICTED')}
                                    </MenuItem>
                                </AnamnesisSelectbox>
                            </Grid>
                        </Grid>
                    </Box>
                }

                <InputDivider/>

                </QuestionWithFiltering>
                <QuestionWithFiltering filters={filters} filterValue={form.values?.haveCardiomypathy}>
                <BooleQuestion
                        disabled={readonly}
                        form={form}
                        info={t('anamnesis.cardio.haveCardiomypathyInfo')}
                        fieldName="haveCardiomypathy"
                        question={t('anamnesis.cardio.haveCardiomypathyQuestion')}
                />
                {
                    isPropertySelected(form, 'haveCardiomypathy') &&
                    <Box mb={2}>
                        <Grid container spacing={0}>
                            <Grid item xs={4}>
                                <AnamnesisSelectbox
                                        disabled={readonly}
                                        form={form}
                                        label={t('anamnesis.cardio.haveCardiomypathyDiagnoseValue.label')}
                                        displayEmpty
                                        fieldName={'haveCardiomypathyDiagnoseValue'}
                                >
                                    <MenuItem value="DILATIVE">
                                        {t('anamnesis.cardio.haveCardiomypathyDiagnoseValue.DILATIVE')}
                                    </MenuItem>
                                    <MenuItem value="ISCHAEMIC">
                                        {t('anamnesis.cardio.haveCardiomypathyDiagnoseValue.ISCHAEMIC')}
                                    </MenuItem>
                                    <MenuItem value="OTHER">
                                        {t('anamnesis.cardio.haveCardiomypathyDiagnoseValue.OTHER')}
                                    </MenuItem>
                                    <MenuItem value="UNCLEAR">
                                        {t('anamnesis.cardio.haveCardiomypathyDiagnoseValue.UNCLEAR')}
                                    </MenuItem>
                                </AnamnesisSelectbox>
                            </Grid>
                        </Grid>
                    </Box>
                }

                <InputDivider/>
                </QuestionWithFiltering>

                <QuestionWithFiltering filters={filters} filterValue={form.values?.haveSufferFromHeartFailure}>
                <BooleQuestion
                        disabled={readonly}
                        form={form}
                        info={t('anamnesis.cardio.haveSufferFromHeartFailureInfo')}
                        fieldName="haveSufferFromHeartFailure"
                        question={t('anamnesis.cardio.haveSufferFromHeartFailureQuestion')}
                />
                {
                    isPropertySelected(form, 'haveSufferFromHeartFailure') &&
                    <Box mb={2}>
                        <Grid container spacing={0}>
                            <Grid item xs={4}>
                                <AnamnesisSelectbox
                                        disabled={readonly}
                                        form={form}
                                        label={t('anamnesis.cardio.haveSufferFromHeartFailureDecompensation.label')}
                                        displayEmpty
                                        fieldName={'haveSufferFromHeartFailureDecompensation'}
                                >
                                    <MenuItem value="<3">&lt; 3</MenuItem>
                                    <MenuItem value="3-5">3-5</MenuItem>
                                    <MenuItem value=">5">&gt; 5</MenuItem>
                                </AnamnesisSelectbox>
                            </Grid>
                        </Grid>
                    </Box>
                }

                <InputDivider/>
                </QuestionWithFiltering>

                <QuestionWithFiltering filters={filters} filterValue={form.values?.haveValvularHeartDisease}>
                <BooleQuestion
                        disabled={readonly}
                        form={form}
                        fieldName="haveValvularHeartDisease"
                        question={t('anamnesis.cardio.haveValvularHeartDiseaseQuestion')}
                />
                {
                    isPropertySelected(form, 'haveValvularHeartDisease') &&
                    <Box mb={2}>
                        <Grid container spacing={0}>
                            <Grid item xs={8}>
                                <Box style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '10px'}} mt={1}>
                                {[
                                "hasAorticInsufficiency",
                                "hasAorticStenosis",
                                "hasMitralValveInsufficiency",
                                "hasMitralValveStenosis",
                                "hasPulmonicValveInsufficiency",
                                "hasPulmonaryValveStenosis",
                                "hasTricuspidValveInsufficiency",
                                "hasTricuspidValveStenosis",
                            ].map((item) => {
                                    return (
                                        <Field
                                                disabled={readonly}
                                                key={item}
                                                name={item}
                                                as={StandaloneToggleButton}
                                                form={form}
                                        >
                                            <span>{t(`anamnesis.cardio.valvularHeartDiseaseType.${item}`)}</span>
                                        </Field>
                                    )
                                })}

                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                }

                <InputDivider/>
                </QuestionWithFiltering>

                <QuestionWithFiltering filters={filters} filterValue={form.values?.haveHeartValveTreatment}>
                <BooleQuestion
                        disabled={readonly}
                        form={form}
                        info={t('anamnesis.cardio.haveHeartValveTreatmentInfo')}
                        fieldName="haveHeartValveTreatment"
                        question={t('anamnesis.cardio.haveHeartValveTreatmentQuestion')}
                />
                {
                    isPropertySelected(form, 'haveHeartValveTreatment') &&
                    <Box mb={2}>
                        <Grid container spacing={0}>
                            <Grid item xs={5}>
                                <SimpleSubInnerQuestionSection>{t('global.all-events-specify')}</SimpleSubInnerQuestionSection>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>

                            <DataListInputField
                                    disabled={readonly}
                                    form={form}
                                    fieldName="haveHeartValveTreatmentData"
                                    rowData={{date: null, type1: '', type2: '', type3: ''}}
                            >
                                {({onChange, item, DefaultDeleteIcon}) => <>
                                    <Grid container key={item.id} spacing={1}>
                                        <Grid item xs={3}>
                                            <CustomYearPicker
                                                    disabled={readonly}
                                                    name="date"
                                                    type="date"
                                                    fullWidth
                                                    value={item?.date ?? ''}
                                                    onChange={(e) => {
                                                        onChange({
                                                            id: item.id,
                                                            field: 'date',
                                                            props: {value: e.target.value}
                                                        })
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                            />
                                        </Grid>

                                        <Grid item xs={2}>
                                            <TextField
                                                    disabled={readonly}
                                                    fullWidth
                                                    label={t('global.intervention')}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    select
                                                    SelectProps={{
                                                        value: item?.[`type1`] ?? null,
                                                        onChange: e => onChange({
                                                            id: item.id,
                                                            field: `type1`,
                                                            props: {value: e.target.value}
                                                        }),
                                                        renderValue: PlaceholderOrValue(item?.[`type1`], t)
                                                    }}
                                            >
                                                {[
                                                    {
                                                        value: "OPERATIVE",
                                                        label: t('anamnesis.cardio.haveHeartValveTreatmentData.OPERATIVE')
                                                    },
                                                    {
                                                        value: "INTERVENTIONAL",
                                                        label: t('anamnesis.cardio.haveHeartValveTreatmentData.INTERVENTIONAL')
                                                    }
                                                ].map((item) =>
                                                        <MenuItem key={item.value} value={item.value}>
                                                            {item.label}
                                                        </MenuItem>)}
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={2}>
                                            <TextField
                                                    disabled={readonly}
                                                    fullWidth
                                                    label={t('global.valve-involved')}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    select
                                                    SelectProps={{
                                                        value: item?.[`type2`] ?? null,
                                                        onChange: e => onChange({
                                                            id: item.id,
                                                            field: `type2`,
                                                            props: {value: e.target.value}
                                                        }),
                                                        renderValue: PlaceholderOrValue(item?.[`type2`], t)
                                                    }}
                                            >
                                                {[
                                                    {
                                                        value: "AORTIC_VALVE",
                                                        label: t('anamnesis.cardio.haveHeartValveTreatmentData.AORTIC_VALVE')
                                                    },
                                                    {
                                                        value: "MITRAL_VALVE",
                                                        label: t('anamnesis.cardio.haveHeartValveTreatmentData.MITRAL_VALVE')
                                                    },
                                                    {
                                                        value: "PULMONIC_VALVE",
                                                        label: t('anamnesis.cardio.haveHeartValveTreatmentData.PULMONIC_VALVE')
                                                    },
                                                    {
                                                        value: "TRICUSPID_VALVE",
                                                        label: t('anamnesis.cardio.haveHeartValveTreatmentData.TRICUSPID_VALVE')
                                                    }
                                                ].map((item) =>
                                                        <MenuItem key={item.value} value={item.value}>
                                                            {item.label}
                                                        </MenuItem>)}
                                            </TextField>
                                        </Grid>

                                        <Grid container item xs={2} style={{alignItems: 'end'}}>
                                            {!readonly && <DefaultDeleteIcon style={{
                                                marginBottom: '10px'
                                            }} item={item}/>}
                                        </Grid>

                                    </Grid>
                                </>
                                }
                            </DataListInputField>
                        </Grid>
                    </Box>
                }

                <InputDivider/>
                </QuestionWithFiltering>

                <QuestionWithFiltering filters={filters} filterValue={form.values?.haveCardiacArrhythmia}>
                <BooleQuestion
                        disabled={readonly}
                        form={form}
                        info={t('anamnesis.cardio.haveCardiacArrhythmiaInfo')}
                        fieldName="haveCardiacArrhythmia"
                        question={t('anamnesis.cardio.haveCardiacArrhythmiaQuestion')}
                />
                {
                    isPropertySelected(form, 'haveCardiacArrhythmia') &&
                    <Box mb={2}>
                        <Grid container spacing={0}>
                            <Grid item xs={4}>
                                <AnamnesisSelectbox
                                        disabled={readonly}
                                        form={form}
                                        label={t('anamnesis.specify')}
                                        displayEmpty
                                        fieldName={'haveCardiacArrhythmiaType'}
                                >
                                    {[
                                        {
                                            value: "ATRIAL_FIBRILLATION",
                                            label: t('anamnesis.cardio.haveCardiacArrhythmiaType.ATRIAL_FIBRILLATION')
                                        },
                                        {
                                            value: "ATRIAL_FLUTTER",
                                            label: t('anamnesis.cardio.haveCardiacArrhythmiaType.ATRIAL_FLUTTER')
                                        },
                                        {
                                            value: "VENTRICULAR_EXTRASYSTOLE",
                                            label: t('anamnesis.cardio.haveCardiacArrhythmiaType.VENTRICULAR_EXTRASYSTOLE')
                                        },
                                        {
                                            value: "VENTRICULAR_TACHYCARDIA",
                                            label: t('anamnesis.cardio.haveCardiacArrhythmiaType.VENTRICULAR_TACHYCARDIA')
                                        },
                                        {
                                            value: "AVNRT",
                                            label: t('anamnesis.cardio.haveCardiacArrhythmiaType.AVNRT')
                                        },
                                        {
                                            value: "OTHER",
                                            label: t('anamnesis.cardio.haveCardiacArrhythmiaType.OTHER')
                                        },
                                        {
                                            value: "UNKNOWN",
                                            label: t('anamnesis.cardio.haveCardiacArrhythmiaType.UNKNOWN')
                                        }
                                    ].map((item) => <MenuItem key={item.value}
                                                              value={item.value}> {item.label} </MenuItem>)}
                                </AnamnesisSelectbox>
                            </Grid>
                        </Grid>


                        <BooleSubQuestion
                                disabled={readonly}
                                form={form}
                                fieldName="haveResuscitated"
                                question={t('anamnesis.cardio.haveResuscitatedQuestion')}
                        />

                        {
                            isPropertySelected(form, 'haveResuscitated') &&
                            <DataListInputField
                                    disabled={readonly}
                                    form={form}
                                    fieldName="haveResuscitatedData"
                                    rowData={{date: null, value: 'RESUSCIATED_DATA'}}
                            >
                                {({onChange, item, DefaultDeleteIcon}) => (
                                        <>
                                            <Grid container key={item.id} spacing={0}>
                                                <Grid item xs={3} style={{display: 'flex'}}>
                                                    <CustomYearPicker
                                                            disabled={readonly}
                                                            name="date"
                                                            type="date"
                                                            fullWidth
                                                            label={t('anamnesis.date')}
                                                            value={item?.date ?? ''}
                                                            onChange={(e) => {
                                                                onChange({
                                                                    id: item.id,
                                                                    field: 'date',
                                                                    props: {value: e.target.value}
                                                                })
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                    />
                                                    {!readonly && <DefaultDeleteIcon style={{
                                                        marginTop: '20px'
                                                    }} item={item}/>}
                                                </Grid>
                                            </Grid>
                                        </>
                                )}
                            </DataListInputField>
                        }
                    </Box>
                }

                <InputDivider/>
                </QuestionWithFiltering>

                <QuestionWithFiltering filters={filters} filterValue={form.values?.haveCongenitalHeartDefect}>
                <BooleQuestion
                        disabled={readonly}
                        form={form}
                        fieldName="haveCongenitalHeartDefect"
                        info={t('anamnesis.cardio.haveCongenitalHeartDefectInfo')}
                        question={t('anamnesis.cardio.haveCongenitalHeartDefectQuestion')}
                />
                {
                    isPropertySelected(form, 'haveCongenitalHeartDefect') &&
                    <Box mb={2}>

                        <Grid container spacing={0}>
                            <Grid item xs={4}>
                                <AnamnesisSelectbox
                                        disabled={readonly}
                                        form={form}
                                        label={t("anamnesis.specify")}
                                        displayEmpty
                                        fieldName={'haveCongenitalHeartDefectType'}
                                >
                                    <MenuItem value="VSD">VSD - Ventrikelseptumdefekt
                                        (Kammerseptumdefekt)</MenuItem>
                                    <MenuItem value="ASD">ASD - Atriumseptumdefekt (Vorhofseptumdefekt)</MenuItem>
                                    <MenuItem value="PDA">PDA - Persistierender Ductus arteriosus</MenuItem>
                                    <MenuItem value="PaV">PaV - Pulmonalklappenstenose</MenuItem>
                                    <MenuItem value="ISTA">ISTA - Aortenisthmusstenose</MenuItem>
                                    <MenuItem value="AoV">AoV - Aortenklappenstenose</MenuItem>
                                    <MenuItem value="TOF">TOF - Fallot-Tetralogie</MenuItem>
                                    <MenuItem value="AVSD">AVSD - Atrioventrikulärer Septumdefekt</MenuItem>
                                    <MenuItem value="TGA">TGA - Transposition der großen Gefäße</MenuItem>
                                    <MenuItem value="HLHS">HLHS - Hypoplastisches Linksherzsyndrom</MenuItem>
                                    <MenuItem value="PAVSD">PA+VSD - Pulmonalatresie mit
                                        Ventrikelseptumdefekt</MenuItem>
                                    <MenuItem value="PA">PA - Pulmonalatresie ohne Ventrikelseptumdefekt</MenuItem>
                                    <MenuItem value="TrA">TrA - Trikuspidalatresie</MenuItem>
                                    <MenuItem value="DIV">DIV - Double inlet ventricle (singulärer
                                        Ventrikel)</MenuItem>
                                    <MenuItem value="DORV">DORV - Double outlet right ventricle</MenuItem>
                                    <MenuItem value="ccTGA">ccTGA - Angeborene korrigierte Transposition der großen
                                        Gefäße</MenuItem>
                                    <MenuItem value="TAC">TAC - Truncus arteriosus communis</MenuItem>
                                    <MenuItem value="HOCM">HOCM - Hypertrophe obstruktive Kardiomyopathie</MenuItem>
                                    <MenuItem value="SupravAS">Suprav. AS - Supravalvuläre Aortenstenose
                                        (Williams-Beuren-Syndrom)</MenuItem>

                                </AnamnesisSelectbox>
                            </Grid>
                        </Grid>
                    </Box>
                }

                <InputDivider/>
                </QuestionWithFiltering>
                <QuestionWithFiltering filters={filters} filterValue={form.values?.havePacemakerOrDefibrillator}>
                <BooleQuestion
                        disabled={readonly}
                        form={form}
                        info={t('anamnesis.cardio.havePacemakerOrDefibrillatorInfo')}
                        fieldName="havePacemakerOrDefibrillator"
                        question={t('anamnesis.cardio.havePacemakerOrDefibrillatorQuestion')}
                />
                {
                    isPropertySelected(form, 'havePacemakerOrDefibrillator') &&
                    <Box mb={2}>
                        <Grid container spacing={0}>
                            <Grid item xs={5}>
                                <SimpleSubInnerQuestionSection>
                                {t("anamnesis.specify")}
                                </SimpleSubInnerQuestionSection>
                            </Grid>
                        </Grid>

                        <DataListInputField
                                disabled={readonly}
                                form={form}
                                fieldName="havePacemakerOrDefibrillatorData"
                                rowData={{date: '', type1: '', type2: ''}}
                        >
                            {({onChange, item, DefaultDeleteIcon}) =>
                                    <Grid container key={item.id} spacing={1}>
                                        <Grid item xs={3}>
                                            <CustomYearPicker
                                                    disabled={readonly}
                                                    fullWidth
                                                    name="date"
                                                    type="date"
                                                    label={''}
                                                    value={item?.date ?? ''}
                                                    onChange={(e) => {
                                                        onChange({
                                                            id: item.id,
                                                            field: 'date',
                                                            props: {value: e.target.value}
                                                        })
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                            />
                                        </Grid>

                                        {[{typeId: 1}, {typeId: 2}].map((pitem) => (
                                                <Grid item xs={3} key={pitem.typeId}>
                                                    <Select
                                                            disabled={readonly}
                                                            fullWidth
                                                            displayEmpty
                                                            value={item?.[`type${pitem.typeId}`] ?? null}
                                                            onChange={e => onChange({
                                                                id: item.id,
                                                                field: `type${pitem.typeId}`,
                                                                props: {value: e.target.value}
                                                            })}
                                                            renderValue={PlaceholderOrValue(item?.[`type${pitem.typeId}`], t)}
                                                    >
                                                        {pitem.typeId === 1 && [
                                                            {
                                                                value: "PACEMAKER",
                                                                label: t('anamnesis.cardio.havePacemakerOrDefibrillatorData.PACEMAKER')
                                                            },
                                                            {
                                                                value: "DEFIBRILLATOR",
                                                                label: t('anamnesis.cardio.havePacemakerOrDefibrillatorData.DEFIBRILLATOR')
                                                            },
                                                        ].map((item) =>
                                                                <MenuItem key={item.value}
                                                                          value={item.value}> {item.label} </MenuItem>)}

                                                        {pitem.typeId === 2 &&
                                                        [
                                                            {
                                                                value: "SCH_PACEMAKER_ICD",
                                                                label: t('anamnesis.cardio.havePacemakerOrDefibrillatorData.SCH_PACEMAKER_ICD')
                                                            },
                                                            {
                                                                value: "SCH_1_CHAMBER",
                                                                label: t('anamnesis.cardio.havePacemakerOrDefibrillatorData.SCH_1_CHAMBER')
                                                            },
                                                            {
                                                                value: "SCH_2_CHAMBER",
                                                                label: t('anamnesis.cardio.havePacemakerOrDefibrillatorData.SCH_2_CHAMBER')
                                                            },
                                                            {
                                                                value: "SCH_BIVENTRICULAR_CRT",
                                                                label: t('anamnesis.cardio.havePacemakerOrDefibrillatorData.SCH_BIVENTRICULAR_CRT')
                                                            },
                                                            {
                                                                value: "SCH_UNKNOWN",
                                                                label: t('anamnesis.cardio.havePacemakerOrDefibrillatorData.SCH_UNKNOWN')
                                                            }
                                                        ].map((item) =>
                                                                <MenuItem key={item.value}
                                                                          value={item.value}> {item.label} </MenuItem>)
                                                        }
                                                    </Select>
                                                </Grid>
                                        ))}


                                        <Grid item xs={2}>
                                            {!readonly && <DefaultDeleteIcon style={{
                                                marginTop: '10px'
                                            }} item={item}/>}
                                        </Grid>

                                    </Grid>
                            }
                        </DataListInputField>
                    </Box>
                }

                <InputDivider/>
                </QuestionWithFiltering>

                <QuestionWithFiltering filters={filters} filterValue={form.values?.haveMyocardialBiopsy}>
                <BooleQuestion
                        disabled={readonly}
                        form={form}
                        fieldName="haveMyocardialBiopsy"
                        question={t('anamnesis.cardio.haveMyocardialBiopsyQuestion')}
                />

                {
                    isPropertySelected(form, 'haveMyocardialBiopsy') &&
                    <DataListInputField
                            disabled={readonly}
                            form={form}
                            fieldName="haveMyocardialBiopsyData"
                            rowData={{date: null, value: 'MYOCARDIAL_BIOPSY'}}
                    >
                        {({onChange, item, DefaultDeleteIcon}) => (
                                <>
                                    <Grid container key={item.id} spacing={0}>
                                        <Grid item xs={3} style={{display: 'flex'}}>
                                            <CustomYearPicker
                                                    disabled={readonly}
                                                    name="date"
                                                    type="date"
                                                    label={t('anamnesis.date')}
                                                    value={item?.date ?? ''}
                                                    onChange={(e) => {
                                                        onChange({
                                                            id: item.id,
                                                            field: 'date',
                                                            props: {value: e.target.value}
                                                        })
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                            />
                                            {!readonly && <DefaultDeleteIcon style={{
                                                marginTop: '25px'
                                            }} item={item}/>}
                                        </Grid>
                                    </Grid>
                                </>
                        )}
                    </DataListInputField>
                }
                </QuestionWithFiltering>

            </FormSection>
    );
};

CardioDiseaseForm.propTypes = {};

export default CardioDiseaseForm;
