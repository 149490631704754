/**
 * Takes a filename as a string and returns an array containing the filename's base name and extension as separate string.
 * If the filename has no extension, the function returns an array with the original filename as the first element and an empty string as the second.
 *
 * @param {string} filename
 * @returns {[string, string]}
 */
export const splitFilenameByFileExtension = (filename) =>
  ((parts) =>
    parts.length === 1 ? [filename, ""] : [parts.slice(0, -1).join("."), parts[parts.length - 1]])
  (filename.split("."));
