import styled from "styled-components";


const SubTitle = styled.h3`
    color: #245B67;
`;

export const SubQuestionSection = styled.h4`
color: #030303;
font-weight: 500;
font-style: normal;
`


export const SubInnerQuestionSection = styled.h4`
color: #030303;
font-weight: 500;
font-style: normal;
margin-left: 20px;
`

export const SimpleSubInnerQuestionSection = styled.h4`
color: #030303;
font-style: normal;
font-weight: normal;
margin-left: 20px;
`


export default SubTitle;
