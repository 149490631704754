import React, {Component} from 'react';
import {
    Button,
    FormControl,
    withStyles,
    Stepper,
    Step,
    StepLabel,
    Typography
} from "@material-ui/core";
import {transparentBlack054} from 'components/colors/Colors';
import HmoTextField from 'components/hmo-textfield/HmoTextField';
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';

const styles = theme => ({});

export class ForgotPassword extends Component {

    state = {
        isEmailBeingRequested: false,
        isEmailRequested: false
    };

    requestNewPassword = event => {
        this.setState({
            isEmailBeingRequested: true,
            isEmailRequested: false
        }, () => this.props.requestNewPassword(this.props.userName?.toLowerCase())
            .then(() => {
                this.setState({
                    isEmailBeingRequested: false,
                    isEmailRequested: true
                });
            })
            .catch((error) => {
                this.setState({
                    warning: '' + _.get(error, 'error.response.data.status'),
                    isEmailBeingRequested: false,
                    isEmailRequested: false
                });
            }));
        event.preventDefault();
    };

    render() {
        const {classes, t: translate, warning, userName, changeState, changeView} = this.props;
        const {isEmailBeingRequested, isEmailRequested} = this.state;
        const {requestNewPassword} = this;

        return (
            <form style={{width: '100%', maxWidth: '450px'}}>
                <Stepper activeStep={0}>
                    <Step completed={false}>
                        <StepLabel/>
                    </Step>
                    <Step completed={false}>
                        <StepLabel/>
                    </Step>
                    <Step completed={false}>
                        <StepLabel/>
                    </Step>
                </Stepper>
                <Typography variant={'h4'} style={{marginBottom: 8}}>{isEmailRequested ? translate('login.reset-password') : translate('login.forgot-password')}</Typography>
                <Typography style={{color: transparentBlack054, marginBottom: 30}}>
                    {isEmailRequested ? translate('login.password-has-been-reset-text') : translate('login.forgot-password-text')}
                </Typography>
                <FormControl margin="normal" required fullWidth>
                    <HmoTextField
                        name={'userName'}
                        label={translate('login.forgot-password-email')}
                        autoFocus
                        inputProps={{
                            autoCapitalize: 'off'
                        }}
                        helperText={(warning?.toString() || '').startsWith('5') ? translate("login.incorrect-username") : ''}
                        error={(warning?.toString() || '').startsWith('5')}
                        type="text"
                        value={userName}
                        onEnterKey={requestNewPassword}
                        onChange={changeState}
                    />
                </FormControl>
                <Flex item container style={{marginTop: 20}}>
                    <Button
                        style={{
                            width: '40%',
                            marginRight: 10
                        }}
                        variant="contained"
                        color="default"
                        onClick={() => changeView('LOGIN')}
                    >
                        {isEmailRequested ? translate('global.cancel') : translate('global.cancel')}
                    </Button>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={!userName}
                        data-testid="forgot-password-submit"
                        className={classes.submit}
                        onClick={requestNewPassword}
                    >
                        {isEmailRequested ? translate('login.send-reset-password-again') : translate('login.forgot-password-request')}
                    </Button>
                </Flex>
                {
                    isEmailBeingRequested &&
                    <Flex item container style={{marginTop: 20}} justifyContent={'center'} alignItems={'center'}>
                        <CircularProgress/>
                    </Flex>
                }
            </form>
        );
    }
}

export default withStyles(styles)(withTranslation()(ForgotPassword));
