import moment from 'moment';
import {htmlRed, htmlOrange} from 'components/colors/Colors';

export function reminderColor(date) {
    if(!date) return '';
    const tomorrow = moment().add(1, 'day').endOf('day');
    const today = moment().startOf('day');
    let reminderColor = '';
    if (moment(date).isAfter(today) && moment(date).isBefore(tomorrow)) {
        reminderColor = htmlOrange;
    } else if (moment().isAfter(moment(date))) {
        reminderColor = htmlRed;
    }
    return reminderColor;
}
