import {
  Button,
  Checkbox,
  Dialog,
  Typography,
  withStyles,
} from "@material-ui/core";
import { CloudDownloadOutlined } from "@material-ui/icons";
import HmoFilledButton from "components/button/HmoFilledButton";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {
  htmlWhite,
  iceberg,
  grey,
  niagara,
  htmlGrey,
  htmlBlue,
} from "components/colors/Colors";
import Flex from "components/grid/Flex";
import Title from "components/title/Title";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import connector from "./DocumentSidebar.connect";
import moment from "moment";
import HmoInputLabel from "components/input-label/HmoInputLabel";
import {isCaseManager} from "domain/User.model";
import utils from "utils/Utils";

const styles = () => ({
  inputItem: {
    padding: "7px 7px",
  },
  inputRoot: {
    backgroundColor: htmlWhite,
    borderRadius: 4,
    paddingLeft: 7,
  },
  checkBox: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  checkBoxIcon: {
    fill: niagara,
  },
  calendarTitle: {
    fontSize: "0.75rem",
  },
  calendarRoot: {
    minWidth: 0,
  },
  cancelButton: {
    fontSize: 13,
    textTransform: "unset",
    color: htmlGrey,
  },
  selectRoot: {
    backgroundColor: "#F1F1F1",
    borderRadius: 4,
    paddingLeft: 7,
  },
  rowContainer: {
    backgroundColor: "white",
    margin: 2,
    boxShadow: `0px 0px 8px #0000001A`,
    borderRadius: 4,
  },
  cell: {
    padding: 2,
    alignItems: "flex-start",
  },
  cellText: {
    marginTop: "auto",
    marginBottom: "auto",
    color: niagara,
    fontWeight: 'bold',
   },
   notSelectedCellText: {
    marginTop: "auto",
    marginBottom: "auto",
    color: htmlGrey
   },
   cellTextSpace: {
    marginTop: "auto",
    marginBottom: "auto",
   },
   countText: {
    fontWeight: 'bold',
    color: niagara
   },
   label: {
    width: '100%',
    textAlign: 'right',
    paddingRight: 3
   }
});

export class DocumentDownload extends Component {
  state = {
    isLoading: false,
    dataDeletionFlag: false,
    open: false,
  };

  toggleOpen = () => {
    const open = !this.state.open;

    let checkboxDoc;
    if (open) {
      checkboxDoc = this.props.documents?.map((doc) => {
        return { ...doc, isChecked: false };
      });
    } else {
      checkboxDoc = this.state.checkboxDoc;
    }

    this.setState({
      open,
      checkboxDoc,
    });
  };

  onAddingItem = (i) => (event) => {
    this.setState((state, props) => {
      const { checkboxDoc } = state;
      checkboxDoc[i].isChecked = !checkboxDoc[i].isChecked;
      const selectedCount = checkboxDoc.reduce(
        (prevValue, currValue) => (prevValue += currValue.isChecked ? 1 : 0),
        0
      );
      return {
        checkboxDoc,
        selectedCount,
      };
    });
  };

  downloadSelectedFileForCase = () => {
    this.setState({
      allFileDownloadDialogOpened: true,
    });

    const documentIds = this.state.checkboxDoc
      .filter((d) => d.isChecked)
      .map((d) => d.documentData.documentId);

    if (documentIds.length > 0) {
      this.props
        .downloadSelectedDocument(documentIds)
        .then(() => {
          this.setState({ allFileDownloadDialogOpened: false, open: false })
          this.props.fetchCase(this.props.caseBusinessId)
        });
    }
  };

  render() {
    const { classes, t: translate, user, documents } = this.props;
    const { open, checkboxDoc, selectedCount } = this.state;
    const { toggleOpen, onAddingItem, downloadSelectedFileForCase } = this;

    return (
      <>
        <Typography variant={'h6'}>{translate('case.list-of-documents')}</Typography>
            {
                isCaseManager(user) && (documents || []).length && (
                <CloudDownloadOutlined fontSize={'large'} onClick={toggleOpen}/>)
            }
        <Typography variant={'h5'} style={{color: htmlBlue}}>{(documents || []).length}</Typography>
        <Dialog open={open} onClose={toggleOpen} maxWidth="sm" fullWidth={true}>
          <Flex item container direction={"column"} padding={20}>
            <Flex
              item
              container
              style={{ paddingBottom: 10, borderBottom: `1px solid ${grey}` }}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Flex item container alignItems={"center"}>
                <Title style={{ color: niagara }}>
                  {translate("case.download-doc.create-zip")}
                </Title>
              </Flex>
              <Flex item container justifyContent={"flex-end"}>
                <Button onClick={toggleOpen} className={classes.cancelButton}>
                  {translate("global.cancel")}
                </Button>

                <HmoFilledButton
                  style={{ backgroundColor: niagara }}
                  onClick={downloadSelectedFileForCase}
                >
                  {translate("case.download-doc.download")}
                </HmoFilledButton>
              </Flex>
            </Flex>
            <Flex
              item
              container
              direction={"column"}
              style={{
                backgroundColor: iceberg,
                padding: 15,
                marginTop: 10,
                marginBottom: 10,
                borderRadius: 4,
              }}
            >
              <Flex container spaceBetween>
                <div>
                  <HmoInputLabel>{translate("case.download-doc.included")}</HmoInputLabel>
                </div>
                <div>
                  <Typography className={classes.countText}>{selectedCount ? selectedCount : 0}</Typography>
                </div>
              </Flex>

              {/* Header */}
              <Flex
                item
                container
                style={{
                  borderRadius: "5px 5px 0 0",
                  marginBottom: 5,
                  paddingLeft: 7,
                }}
              >
                <Flex item={`0 0 45px`} container className={classes.cell} />
                <Flex item={`0 0 280px`} container className={classes.cell}>
                  <HmoInputLabel>{translate("case.download-doc.name")}</HmoInputLabel>
                </Flex>
                <Flex item={`0 0 200px`} container className={classes.cell}>
                  <HmoInputLabel classes={classes}>{translate("case.download-doc.last")}</HmoInputLabel>
                </Flex>
              </Flex>

              {/* Body */}
              {checkboxDoc &&
                checkboxDoc.map((file, i) => (
                  <Flex item container className={classes.rowContainer} key={i}>
                    <Flex item={`0 0 50px`} container className={classes.cell}>
                      <Checkbox
                        className={classes.checkBox}
                        checked={file.isChecked}
                        onChange={onAddingItem(i)}
                        checkedIcon={
                          <CheckBoxIcon className={classes.checkBoxIcon} />
                        }
                      />
                    </Flex>
                    <Flex item={`0 0 280px`} container className={classes.cell}>
                        <Typography className={file.isChecked ? classes.cellText : classes.notSelectedCellText}>{file.documentData.fileName}</Typography>
                    </Flex>
                    <Flex item={`0 0 120px`} container className={classes.cell}>
                      <div style={{width: "100%", textAlign: "right", color: htmlGrey}} className={classes.cellTextSpace}>{file.documentData.lastDownloadBy}</div>
                    </Flex>
                    <Flex item={`0 0 80px`} container className={classes.cell}>
                      <div style={{width: "100%", paddingRight: 10, textAlign: "right", color: htmlGrey}} className={classes.cellTextSpace}>{file.documentData.lastDownloadTime
                        ? moment(file.documentData.lastDownloadTime).format(
                          utils.VIEW_SHORT_DATE_FORMAT
                          )
                        : ""}</div>
                      </Flex>
                  </Flex>
                ))}
            </Flex>
          </Flex>
        </Dialog>
      </>
    );
  }
}

export default withTranslation()(
  connect(
    connector.mapStateToProps,
    connector.mapDispatchToProps
  )(withStyles(styles)(DocumentDownload))
);
