import {makeStyles} from "@material-ui/core/styles";
import Flex from 'components/grid/Flex';
import {Typography} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import {concrete} from 'components/colors/Colors';
import {useDispatch} from "react-redux";
import { SET_APP_INTRO_WALKTHROUGH_COMPLETED } from '../../scenes/Main.action.types';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    profileContent: {
        paddingTop: 5,
        paddingBottom: 5,
        paddingRight: 9,
        paddingLeft: 9,
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: concrete,
            cursor: 'pointer'
        },
        whiteSpace: 'nowrap'
    },
    buttonText: {
        textTransform: 'none',
        '&:hover': {
            cursor: 'pointer'
        },
    }
}));

const WalkthroughBtn = () => {
    const navigation = useNavigate();
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const showWalkthrough = () => {
        dispatch({type: SET_APP_INTRO_WALKTHROUGH_COMPLETED, payload: false});
        navigation('/');
    }
    return (
            <Flex item container
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  onClick={() => {}}
                  className={classes.profileContent}>
                <Typography onClick={() => showWalkthrough()} className={classes.buttonText}>
                    {t('walkthrough.walkthrough')}
                </Typography>
            </Flex>
    );
};

export default WalkthroughBtn;
