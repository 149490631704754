import React, {useCallback} from 'react';
import FormSection from "../FormSection";
import {Grid, MenuItem, TextField, Box, Typography, Button} from "@material-ui/core";
import DataListInputField from "../input/DataListInputField";
import {useTranslation} from "react-i18next";
import BooleQuestion from "../input/BooleQuestion";
import InputDivider from "../input/InputDivider";
import {PlaceholderOrValue} from '../input/FieldSelect';
import {SimpleSubInnerQuestionSection, SubQuestionSection} from "../auxiliary/SubTitle";
import StandaloneToggleButton from "../input/StandaloneToggleButton";
import {Field} from "formik";
import EpaTextField from 'components/hmo-textfield/EpaTextField';
import ToggleSwitch from "../input/ToggleSwitch";
import DataListMonthPicker from "../input/DataListMonthPicker";
import Flex from 'components/grid/Flex';
import UploadDocumentPrompt from '../input/UploadDocumentPrompt';
import moment from 'moment';
import { QuestionWithFiltering } from './QuestionWithFiltering';

const customHasEmptyCell = (form, fieldName, mandatoryKeys) => () => {
    const rows = form.values[fieldName];
    const mandatoryProps = ['date', 'value', ...mandatoryKeys]
    return rows?.some(
            row => Object.keys(row)
                    .filter(key => mandatoryProps.includes(key))
                    .filter(key => row[key] === null || row[key] === '' || row[key] === undefined)
                    ?.length !== 0
    )
}

const CancerSubQuestion = ({item, form, onChange, fieldName, question, readonly}) => {
    const value = item?.[fieldName];
    const setForm = (flag) => {
        onChange({
            id: item.id,
            field: fieldName,
            props: {value: flag}
        });
    }
    const onSwitch = () => form.setFieldValue(fieldName, !value);
    return <Grid item container xs={5} alignItems={'baseline'} justifyContent={'space-between'}
                 style={{
                     marginTop: 6,
                     marginRight: 10,
                 }}>
        <Box mr={2}>
            <SimpleSubInnerQuestionSection>{question}</SimpleSubInnerQuestionSection>
        </Box>
        <Box>
            <ToggleSwitch {...{
                disabled: readonly,
                form,
                noMargin: true,
                name: fieldName,
                value,
                onChange: onSwitch,
                setForm
            }} />
        </Box>

    </Grid>;
};

const DataListTextField = ({fieldName, label, type = 'text', item, onChange, ...props}) => {
    return <EpaTextField
            name={fieldName}
            type={type}
            label={label}
            value={item?.[fieldName] ?? ""}
            onChange={(e) => {
                onChange({
                    id: item.id,
                    field: fieldName,
                    props: {value: e.target.value},
                });
            }}
            {...props}
    />;
};

const Radiotherapy = ({item, form, onChange, lang, fieldName, readonly}) => {
    return <Grid id={fieldName + 'Radiation'} container style={{padding: '10px 20px'}} direction={'column'} item
                 sx={12}>

        <CancerSubQuestion {...{
            item, form, onChange, fieldName,
            readonly,
            question: lang(`${fieldName}Question`)
        }}/>
        <Grid container style={{marginTop: 10}} direction={'column'}>
            {
                    item?.[fieldName] === true &&
                    <>
                        <Grid container item sx={12} spacing={1}>
                            <Grid item sx={4}>
                                <DataListMonthPicker {...{
                                    disabled: readonly, item, onChange, fieldName: 'radiotherapyDate',
                                    label: fieldName === 'haveActiveRadiotherapy' ? lang('since-when') : lang('from')
                                }}/>
                            </Grid>
                            {
                                    fieldName === 'havePastRadiotherapy' &&
                                    <Grid item sx={4}>
                                        <DataListMonthPicker {...{
                                            disabled: readonly,
                                            minDate: moment(item?.['radiotherapyDate']),
                                            item, onChange, fieldName: 'radiotherapyToDate', label: lang('to')
                                        }}/>
                                    </Grid>
                            }
                        </Grid>
                        <Grid container item sx={12} spacing={1}>
                            <Grid item sx={4}>
                                <Grid container wrap={'nowrap'} alignItems={'center'}>
                                    <DataListTextField {...{
                                        disabled: readonly,
                                        fieldName: 'radiationDose',
                                        item, onChange, label: lang('single-radiation-dose')
                                    }}/>
                                    <Typography style={{marginTop: 20}}>&nbsp;Gy</Typography>
                                </Grid>
                            </Grid>
                            {
                                    fieldName === 'haveActiveRadiotherapy' &&
                                    <Grid item sx={4}>
                                        <DataListTextField {...{
                                            disabled: readonly,
                                            fieldName: 'radiationSessionsPerWeek',
                                            item, onChange, label: lang('sessions-per-week')
                                        }}/>
                                    </Grid>
                            }
                            <Grid item sx={4}>
                                <DataListTextField {...{
                                    disabled: readonly,
                                    fieldName: 'radiationSessionsInTotal',
                                    item, onChange, label: lang('sessions-in-total')
                                }}/>
                            </Grid>
                        </Grid>
                    </>
            }
        </Grid>
    </Grid>;
};

const Chemotherapy = ({item, form, onChange, lang, fieldName, readonly}) => {
    return <Grid id={fieldName + 'Chemotherapy'} container style={{padding: '10px 20px'}} direction={'column'} item
                 sx={12}>

        <CancerSubQuestion {...{
            readonly,
            item, form, onChange, fieldName,
            question: lang(`${fieldName}Question`)
        }}/>
        <Grid container style={{marginTop: 10}} direction={'column'}>
            {
                    item?.[fieldName] === true &&

                    <>
                        <Grid container item sx={12} spacing={1}>
                            <Grid item sx={4}>
                                <DataListMonthPicker {...{
                                    disabled: readonly,
                                    item, onChange, fieldName: 'chemotherapyFromDate',
                                    label: fieldName === 'haveActiveChemotherapy' ? lang('since-when') : lang('from')
                                }}/>
                            </Grid>
                            {
                                    fieldName === 'havePastChemotherapy' &&
                                    <Grid item sx={4}>
                                        <DataListMonthPicker {...{
                                            disabled: readonly,
                                            minDate: moment(item?.['chemotherapyFromDate']),
                                            item, onChange, fieldName: 'chemotherapyToDate', label: lang('to')
                                        }}/>
                                    </Grid>
                            }
                        </Grid>
                        <Grid container item sx={12} spacing={1}>
                            <Grid item sx={4}>
                                <DataListTextField {...{
                                    disabled: readonly,
                                    fieldName: 'chemoSubstance', placeholder: lang('chemoSubstance-placeholder'),
                                    item, onChange, label: lang('chemoSubstance')
                                }}/>
                            </Grid>
                            <Grid item sx={4}>
                                <DataListTextField {...{
                                    disabled: readonly,
                                    fieldName: 'chemotherapyCycle', placeholder: lang('cycle-placeholder'),
                                    item, onChange, label: lang('cycle')
                                }}/>
                            </Grid>
                            <Grid item sx={4}>
                                <DataListTextField {...{
                                    disabled: readonly,
                                    fieldName: 'chemotherapySessionsInTotal',
                                    placeholder: lang('sessions-in-total-placeholder'),
                                    item,
                                    onChange,
                                    label: lang('sessions-in-total')
                                }}/>
                            </Grid>
                        </Grid>
                    </>
            }

        </Grid>
    </Grid>;
};

const Surgery = ({item, form, onChange, question, lang, readonly}) => {
    return <Grid id={question} container style={{padding: '10px 20px'}} direction={'column'} item sx={12}>

        <CancerSubQuestion {...{
            readonly,
            item, form, onChange,
            fieldName: 'haveSurgery',
            question
        }}/>
        <Grid container item sx={12} spacing={1} style={{marginTop: 10}}>
            {
                    item?.['haveSurgery'] === true &&
                    <Grid item sx={4}>
                        <DataListMonthPicker {...{item, onChange, fieldName: 'surgeryDate', label: lang('when'), disableFuture: false, disabled: readonly}}/>
                    </Grid>
            }
        </Grid>

    </Grid>;
};

const CancerQuestion = ({DefaultDeleteIcon, lang, item, onChange, readonly}) => {
    return <Grid container item spacing={1} xs={12}>
        <Grid item xs={3}>
            <DataListMonthPicker disabled={readonly} {...{
                item, onChange, label: lang('when-diagnosed')
            }}/>
        </Grid>
        <Grid item xs={5}>
            <DataListTextField {...{
                disabled: readonly,
                fieldName: 'value',
                item, onChange, label: lang('which-cancer')
            }}/>
        </Grid>
        <Flex item container justifyContent={'flex-end'}>
            {!readonly && <DefaultDeleteIcon style={{
                marginTop: 25, marginRight: 10
            }} item={item}/>}
        </Flex>
    </Grid>;
};

const ActiveCancerDataListInput = ({form, lang, readonly}) => {
    return (
            <DataListInputField form={form} disabled={readonly} fieldName={"activeCancerData"} rowData={{date: null, value: ""}}
                                customHasEmptyCell={customHasEmptyCell(form, "activeCancerData",
                                        ['haveActiveChemotherapy', 'haveActiveRadiotherapy', 'haveSurgery'])}>
                {({onChange, item, DefaultDeleteIcon}) => (
                        <Grid container key={item.id} direction={'column'} style={{
                            border: '1px solid lightgrey',
                            padding: 5,
                            margin: 2,
                            borderRadius: 6
                        }}>
                            <CancerQuestion {...{DefaultDeleteIcon, lang, item, onChange, readonly}}/>
                            <InputDivider style={{margin: '5px 0px'}}/>
                            <Radiotherapy {...{item, form, onChange, lang, fieldName: 'haveActiveRadiotherapy', readonly}}/>
                            <InputDivider style={{margin: '5px 0px'}}/>
                            <Chemotherapy {...{item, form, onChange, lang, fieldName: 'haveActiveChemotherapy', readonly}}/>
                            <InputDivider style={{margin: '5px 0px'}}/>
                            <Surgery {...{item, form, onChange, lang, question: lang('haveActiveSurgeryQuestion'), readonly}}/>
                            {!readonly && <UploadDocumentPrompt questionId={item.id}/>}
                        </Grid>
                )}
            </DataListInputField>
    );
};

const PastCancerDataListInput = ({form, lang, readonly}) => {
    return (
            <DataListInputField disabled={readonly} form={form} fieldName={"pastCancerData"} rowData={{date: null, value: ""}}
                                customHasEmptyCell={customHasEmptyCell(form, "pastCancerData",
                                        ['havePastRadiotherapy', 'havePastChemotherapy', 'havePastSurgeryQuestion'])}>
                {({onChange, item, DefaultDeleteIcon}) => (
                        <Grid container key={item.id} spacing={1} direction={'column'} style={{
                            border: '1px solid lightgrey',
                            padding: 5,
                            margin: 2,
                            borderRadius: 6
                        }}>
                            <CancerQuestion {...{DefaultDeleteIcon, lang, item, onChange, readonly}}/>
                            <InputDivider style={{margin: '5px 0px'}}/>
                            <Radiotherapy {...{item, form, onChange, lang, fieldName: 'havePastRadiotherapy', readonly}}/>
                            <InputDivider style={{margin: '5px 0px'}}/>
                            <Chemotherapy {...{item, form, onChange, lang, fieldName: 'havePastChemotherapy', readonly}}/>
                            <InputDivider style={{margin: '5px 0px'}}/>
                            <Surgery {...{item, form, onChange, lang, question: lang('havePastSurgeryQuestion'), readonly}}/>
                            {!readonly && <UploadDocumentPrompt questionId={item.id}/>}
                        </Grid>
                )}
            </DataListInputField>
    );
};

const AreYouCurrentlyUndergoingCancerTherapy = ({form, lang, readonly}) => {
    return <>
        <BooleQuestion
                disabled={readonly}
                form={form}
                fieldName="activeCancer"
                question={lang('activeCancerQuestion')}
        />

        {
                form.values?.['activeCancer'] !== null && form.values?.['activeCancer'] === true &&
                <>
                    <ActiveCancerDataListInput {...{form, lang, readonly}}/>

                    <Box mt={1}>
                        <Grid container spacing={0}>
                            <Grid item xs={6}>
                                <SubQuestionSection>{lang('bSymptomQuestion')}</SubQuestionSection>
                            </Grid>
                        </Grid>
                    </Box>

                    <Grid container spacing={1} style={{marginBottom: 10}}>
                        <Box style={{display: 'flex', flexDirection: 'row', gap: '20px'}} mt={1}>
                            <Field disabled={readonly} name="nightSweats" as={StandaloneToggleButton} form={form}>
                                <span>{lang('nightSweatsButton')}</span>
                            </Field>

                            <Field disabled={readonly} name="weightLoss" as={StandaloneToggleButton} form={form}>
                                <span>{lang('weightLossButton')}</span>
                            </Field>

                            <Field disabled={readonly} name="fever" as={StandaloneToggleButton} form={form}>
                                <span>{lang('feverButton')}</span>
                            </Field>
                        </Box>
                    </Grid>
                </>
        }
    </>;
};

const HaveYouBeenTreatedForCancerInThePast = ({form, lang, readonly}) => {
    return <>
        <BooleQuestion
                disabled={readonly}
                form={form}
                fieldName="pastCancer"
                question={lang('pastCancerQuestion')}
        />

        {
                form.values?.['pastCancer'] === true && <PastCancerDataListInput {...{form, lang, readonly}}/>
        }
    </>;
};

const FamilyCancerHistory = ({form, lang, t, readonly}) => {
    return <>
        <BooleQuestion
                disabled={readonly}
                form={form}
                fieldName="familyCancer"
                lang={lang}
                info
        />

        {
                form.values?.['familyCancer'] !== null && form.values?.['familyCancer'] === true &&
                <DataListInputField
                        disabled={readonly}
                        form={form}
                        fieldName="familyCancerData"
                        rowData={{who: '', kind: '', when: ''}}
                >
                    {({onChange, item, DefaultDeleteIcon}) =>
                            <Grid container key={item.id} spacing={1}>

                                <Grid item xs={3}>
                                    <TextField
                                            disabled={readonly}
                                            fullWidth
                                            label={t('global.grade-of-family')}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            select
                                            SelectProps={{
                                                value: item?.who ?? null,
                                                displayEmpty: true,
                                                onChange: e => onChange({
                                                    id: item.id,
                                                    field: 'who',
                                                    props: {value: e.target.value}
                                                }),
                                                renderValue: PlaceholderOrValue(item?.who, t)
                                            }}
                                    >
                                        <MenuItem
                                                value={"GRANDPARENT"}> {lang('familyCancerData.GRANDPARENT')} </MenuItem>
                                        <MenuItem value={"PARENT"}>{lang('familyCancerData.PARENT')}</MenuItem>
                                        <MenuItem value={"SIBLING"}>{lang('familyCancerData.SIBLING')}</MenuItem>
                                        <MenuItem value={"CHILDREN"}>{lang('familyCancerData.CHILDREN')}</MenuItem>
                                    </TextField>

                                </Grid>
                                <Grid item xs={3}>
                                    <EpaTextField
                                            disabled={readonly}
                                            name="kind"
                                            label={t('anamnesis.cancer.kind')}
                                            value={item?.kind ?? ''}
                                            onChange={(e) => {
                                                onChange({
                                                    id: item.id,
                                                    field: 'kind',
                                                    props: {value: e.target.value}
                                                })
                                            }}
                                    />

                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                            disabled={readonly}
                                            fullWidth
                                            label={t('global.at-age')}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            select
                                            SelectProps={{
                                                value: item?.when ?? null,
                                                displayEmpty: true,
                                                onChange: e => onChange({
                                                    id: item.id,
                                                    field: 'when',
                                                    props: {value: e.target.value}
                                                }),
                                                renderValue: PlaceholderOrValue(item?.when, t)
                                            }}
                                    >
                                        <MenuItem value={"<30"}> &lt; 30</MenuItem>
                                        <MenuItem value={"30-50"}>30-50</MenuItem>
                                        <MenuItem value={"50-70"}>50-70</MenuItem>
                                        <MenuItem value={">70"}>&gt; 70</MenuItem>
                                    </TextField>

                                </Grid>
                                <Grid item xs={2} container style={{alignItems: 'end'}}>
                                    {!readonly && <DefaultDeleteIcon style={{
                                        marginBottom: '10px'
                                    }} item={item}/>}
                                </Grid>

                            </Grid>
                    }
                </DataListInputField>
        }
    </>;
};

const CancerForm = ({form, readonly, filters}) => {
    const {t} = useTranslation();
    const lang = useCallback((name) => t(`anamnesis.cancer.${name}`), [t]);
    return (
            <FormSection title={lang('title')}>
                <QuestionWithFiltering filters={filters} filterValue={form.values?.activeCancer}>
                    <AreYouCurrentlyUndergoingCancerTherapy {...{form, lang, readonly}}/>
                    <InputDivider/>
                </QuestionWithFiltering>
                <QuestionWithFiltering filters={filters} filterValue={form.values?.pastCancer}>
                    <HaveYouBeenTreatedForCancerInThePast {...{form, lang, readonly}}/>
                    <InputDivider style={{marginTop: 10}}/>
                </QuestionWithFiltering>
                <QuestionWithFiltering filters={filters} filterValue={form.values?.familyCancer}>
                    <FamilyCancerHistory {...{form, lang, t, readonly}}/>
                </QuestionWithFiltering>
            </FormSection>
    );
};


export default CancerForm;
