import DefaultDialogBody from './DefaultDialogBody';
import {useTranslation} from "react-i18next";
import {useGrantPatientAccessToContactPersonMutation} from 'scenes/user-manager/UserDetailAccessApi';
import {useEnqueueSuccess, useEnqueueError} from 'components/alert/SnackbarHooks';
import {useState} from 'react';
import utils from 'utils/Utils';
import AccessInputSelector from './AccessInputSelector';
import moment from 'moment';

/**
 * PATIENT_GRANTS_ACCESS_TO_CONTACT_PERSON, CASE_MANAGER_GRANTS_ACCESS_TO_PATIENTS_CONTACT_PERSON
 */
const GrantPatientAccessToContactPerson = (props) => {
    const {
        toggleOpen, existingUser, isSaveDisabled, userAlreadyExists, deleteEmail, originalEmail, refresh,
        invitedRoleType, patient, inviteRequiresConfirmation, grantType, existingUserId, emailAddress,
        currentlyEditedUserDetailId
    } = props;
    const {t: translate} = useTranslation();
    const [selectedAccesses, setSelectedAccesses] = useState([]);
    const [grantPatientAccessToContactPerson] = useGrantPatientAccessToContactPersonMutation();
    const enqueueError = useEnqueueError();
    const enqueueSuccess = useEnqueueSuccess();

    const triggerGrantAccess = selectedAccesses?.length > 0
            ? () => grantPatientAccessToContactPerson({
                grantType, existingUserId, currentlyEditedUserDetailId,
                accessInputs: selectedAccesses?.map(access => ({
                    invitedUserDetailsId: existingUser?.userDetailsId,
                    patientId: patient?.id,
                    inviteToken: utils.uuid(),
                    invitedEmail: emailAddress,
                    invitedName: existingUser?.givenName + ' ' + existingUser?.familyName,
                    invitedPhoneNumber: 'N/A',
                    invitedRoleType: invitedRoleType,
                    patronUserId: access.patronUserId,
                    patronRoleType: access.patronRoleType,
                    entityType: access.entityType,
                    entityId: access.entityId,
                    }))
            })
                    .unwrap().then(() => {
                        enqueueSuccess();
                        refresh && refresh();
                        toggleOpen();
                    }).catch(() => {
                        enqueueError();
                    })
            : () => enqueueError();
    let information = '';
    let existingUserString = '';
    let save = props.save;
    let saveButtonLabel = props.saveButtonLabel;
    let disabled = isSaveDisabled;
    if (userAlreadyExists) {
        information = translate('access.grant-access-to-user', {name: `(${existingUser?.givenName}, ${existingUser?.familyName})`});
        save = triggerGrantAccess;
        saveButtonLabel = translate('access.grant-access');
        disabled = isSaveDisabled || selectedAccesses?.length === 0;
    } else if (inviteRequiresConfirmation) {
        information = translate('access.invitation-already-sent');
        const date = inviteRequiresConfirmation?.creationDate
                ? moment(inviteRequiresConfirmation?.creationDate).format(utils.VIEW_DATE_FORMAT)
                : '';
        existingUserString = translate('access.existing-invitation')
                + `: ${inviteRequiresConfirmation?.invitedName} - ${date}`;
    }
    return (
            <DefaultDialogBody {...{
                information,
                toggleOpen,
                save,
                saveButtonLabel,
                disabled,
                deleteEmail,
                originalEmail,
                existingUserString
            }}>
                {
                    userAlreadyExists &&
                    <AccessInputSelector {...{
                        invitedRoleType: invitedRoleType,
                        patientId: patient?.id,
                        personUserDetailsId: existingUser?.userDetailsId,
                        setSelectedAccesses
                    }}/>
                }
            </DefaultDialogBody>
    );
};
export default GrantPatientAccessToContactPerson;
