import {
    Modal,
    Grid,
    Card,
    Box,
    CardContent,
    CardHeader,
    IconButton,
    Button,
    CardActions,
    Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import CloseIcon from "@mui/icons-material/Close";

const DeleteContactInvitationModalContent = ({ patientFullName, email, onClose, onDelete }) => {
    const { t } = useTranslation();
    return (
        <Box mt={"40vh"}>
            <Grid container justifyContent="center">
                <Grid item xs={11} sm={10} md={8} lg={4} xl={3}>
                    <Card>
                        <CardHeader
                            title={t("global.remove-contact-rights")}
                            action={
                                <>
                                    <IconButton
                                        onClick={() => {
                                            onClose();
                                        }}
                                        size="small"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </>
                            }
                        />
                        <CardContent>
                            <Typography style={{ display: "inline-block", marginBottom: 10 }}>
                                {t('global.withdraw-invitation-text-1')}
                                <span style={{fontWeight: 'bold'}}>{email}</span>
                                {t('global.withdraw-invitation-text-2')}
                                <span style={{fontWeight: 'bold'}}>{patientFullName}</span>
                                {t('global.withdraw-invitation-text-3')}
                                {/* {t("global.which-rights-to-remove-1")}
                                <span style={{ fontWeight: "bold" }}>
                                    {" "}
                                    {contact.givenName} {contact.familyName}
                                </span>{" "}
                                {t("global.which-rights-to-remove-2")} */}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Box marginLeft={"auto"} display={"flex"} style={{ gap: "8px" }}>
                                <Button onClick={onClose} variant="contained">
                                    {t("global.cancel")}
                                </Button>
                                <Button onClick={onDelete} type="submit" variant="contained" color="primary">
                                    {t("global.delete")}
                                </Button>
                            </Box>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export const DeleteContactInvitationModal = ({ open, ...params }) => {
    return (
        <Modal open={open}>
            <DeleteContactInvitationModalContent {...params} />
        </Modal>
    );
};
