import moment from 'moment';
import _ from 'lodash';
import { accumulateLaboratoryValuesWithUnit, isAcceptableValue} from './mapperUtils';

export function riskProfileMapper(patient = {}, anamnese = {}, laboratoryData = []){
    const laboratory = mapLaboratoryValues(accumulateLaboratoryValuesWithUnit(laboratoryData));
    const firstPartOfBloodPressure = _.isString(anamnese.currentBloodPressure)
    ? anamnese.currentBloodPressure?.split('/')[0] : undefined;
    return {
        sex: patient.bioSex === 'WOMAN' ? 0 : 1, //usex 0=woman, 1=man
        age: moment().year() - moment(patient.dateOfBirth).year(), //uage
        riskValues: {
            Cholesterol: laboratory.Cholesterol, //uchol | mg/dl | Gesamtcholesterin
            HdlCholesterol: laboratory.HdlCholesterol, //uhdl | mg/dl | HDL-Cholesterin
            Renal: laboratory.Renal, //ugfr | ml/min | relating to the kidneys??
            Inflammation: laboratory.Inflammation, //ucrp | mg/dl ??

            //GHF: Question 4. Have you already had a heart attack, bypass surgery or coronary artery stenting?
            //EPA: anamnese.haveMiocardialHeartAttack - Did you had one of more myocardial infarctions?
            //EPA: anamnese.coronaryArteryDisease - cardiovasclar-disase-question ˇˇ ;; NOTE LM: the main question is not relevant in the calculation
            //EPA: anamnese.haveBypassSurgery - Did you have a bypass surgery?
            //EPA: anamnese.haveStentImplantation - Did you get a stent implantation?
            //Risk calculator: uCAD | boolean
            HeartAttack: !!(anamnese.haveMiocardialHeartAttack
                || (anamnese.coronaryArteryDisease && (anamnese.haveBypassSurgery || anamnese.haveStentImplantation))),

            //GHF: Question 3. Have you already had a stroke, a pre-stroke event (TIA), or surgery or stenting of your carotid arteries?
            //EPA: anamnese.haveStroke - Have you had a stroke?
            //EPA: anamnese.haveAtherosclerosisStentImplantation - Do you have atherosclerosis of the carotids? -> Did you get a stent implantation or a surgery?
            //Risk calculator: uCBD | boolean
            Stroke: !!(anamnese.haveStroke || (anamnese.haveAtherosclerosis && anamnese.haveAtherosclerosisStentImplantation)),

            //GHF: Question 6. Are your leg arteries narrowed (intermittent claudication) or have you already had bypass surgery or stenting?
            //EPA: anamnese.haveAtherosclerosisLegArteries - Do you have atherosclerosis of the leg arteries? ((PAD - intermittent claudication))
            //Risk calculator: uPAD | boolean
            Window: !!anamnese.haveAtherosclerosisLegArteries,

            //GHF: Question 5. Do you have an enlargement of the main artery (e.g. abdominal aortic aneurysm)?
            //EPA: anamnese.hasEnlargementOfTheAorta - Do you have an enlargement of the aorta (e.g. abdominal aortic aneurysm)?
            //Risk calculator: uAAA | boolean
            Extension: !!anamnese.hasEnlargementOfTheAorta,

            HeartDisease: anamnese.firstCardioDiseaseDate ? moment().year() - Number(anamnese.firstCardioDiseaseDate) : 0, //uyears | integer | years since the first diagnosed heart disease
            Smoker: !!anamnese.smokeStatus , //usmoker | boolean
            BloodPressureSystolic: firstPartOfBloodPressure ? Number(firstPartOfBloodPressure) : false, //usys | first part of blood pressure string | Leiden Sie an Arterieller Hypertonie? Möchten Sie Ihren aktuellen Blutdruck notieren?
            Diabetes: !!anamnese.hasSufferingFromDiabetes, //udiabetes | boolean
        }
    }
}

export function missingAnswers(laboratoryData, anamnese){
    const laboratoryWithUnit = accumulateLaboratoryValuesWithUnit(laboratoryData);
    const lab = mapLaboratoryValues(laboratoryWithUnit);
    const answerMap = {
        "laboratory.timeline.totalcholesterol": lab.Cholesterol,
        "laboratory.timeline.hdlcholesterol": lab.HdlCholesterol,
        "laboratory.timeline.crp": lab.Inflammation,
        "laboratory.timeline.gfr": lab.Renal,
        "anamnesis.cardio.haveMiocardialHeartAttackQuestion": anamnese.haveMiocardialHeartAttack,
        "anamnesis.cardio.cardiovasclar-disase-question": anamnese.coronaryArteryDisease,
        "anamnesis.cardio.haveBypassSurgeryQuestion": anamnese.coronaryArteryDisease === true ? anamnese.haveBypassSurgery || anamnese.haveStentImplantation : anamnese.coronaryArteryDisease,
        "anamnesis.cardio.haveStentImplantationQuestion": anamnese.coronaryArteryDisease === true ? anamnese.haveBypassSurgery || anamnese.haveStentImplantation : anamnese.coronaryArteryDisease,
        "anamnesis.cardio.haveStrokeQuestion": anamnese.haveStroke,
        "anamnesis.cardio.haveStentImplantationOrSurgeryQuestion": anamnese.haveAtherosclerosis === true ? anamnese.haveAtherosclerosisStentImplantation : anamnese.haveAtherosclerosis,
        "anamnesis.cardio.hasEnlargementOfTheAortaQuestion": anamnese.hasEnlargementOfTheAorta,
        "anamnesis.cardio.haveAtherosclerosisLegArteriesQuestion": anamnese.haveAtherosclerosisLegArteries,
        "anamnesis.cardio.firstCardioDiseaseDateQuestion": anamnese.firstCardioDiseaseDate,
        "anamnesis.cardiovascular.smokeStatusQuestion": anamnese.smokeStatus,
        "anamnesis.cardiovascular.hasCardiovascularDiseaseQuestion": anamnese.hasCardiovascularDisease === true && anamnese.hasNoteBloodPressure === true
            ? anamnese.currentBloodPressure
            : anamnese.hasCardiovascularDisease === false && anamnese.hasNoteBloodPressure === false,
        "anamnesis.cardiovascular.hasNoteBloodPressureQuestion": anamnese.hasCardiovascularDisease === true && anamnese.hasNoteBloodPressure === true
            ? anamnese.currentBloodPressure
            : anamnese.hasCardiovascularDisease === false && anamnese.hasNoteBloodPressure === false,
        "anamnesis.cardiovascular.hasSufferingFromDiabetesQuestion": anamnese.hasSufferingFromDiabetes,
    }

    return Object.keys(answerMap).reduce((accumulator, current) => {
        if(isAcceptableValue(answerMap[current])){
            return accumulator;
        } else {
            return [...accumulator, current];
        }
    }, []);
}

function mapLaboratoryValues(lastLaboratory){
    return {
        Inflammation: mapToMgDl(lastLaboratory.crp?.value, lastLaboratory?.crp?.unit), //crp
        Cholesterol: mapToMgDl(lastLaboratory.totalcholesterol?.value, lastLaboratory.totalcholesterol?.unit),
        HdlCholesterol: mapToMgDl(lastLaboratory.hdlcholesterol?.value, lastLaboratory.hdlcholesterol?.unit),
        Renal: lastLaboratory.gfr?.value || 0
    }
}

function mapToMgDl(value, unit){
    switch (unit){
        case 'mgperdl': return value;
        case 'mgperl': return value * 10;
        default: return 0;
    }
}

