import React, {Component} from 'react';
import Flex from 'components/grid/Flex';
import {
    concrete,
    transparentBlack03,
    transparentBlack087,
    htmlWhite,
    linkWater,
    persianGreen
} from 'components/colors/Colors';
import {
    ClickAwayListener,
    Divider,
    withStyles,
} from '@material-ui/core';
import {profileContainer} from 'components/zindex/zIndex';
import {withTranslation} from "react-i18next";
import HmoAvatar from 'components/avatar/HmoAvatar'
import FeatureSwitch from 'utils/FeatureSwitch';
import LanguageSelector from 'components/language-selector/LanguageSelector';
import {connect} from 'react-redux';
import connector from './Profile.connect';
import {getName} from 'domain/User.model';
import _ from 'lodash';
import UserManager from 'scenes/user-manager/UserManager';
import OutstandingInvites from './OutstandingInvites';
import LogoutWithIcon from 'components/logout/LogoutWithIcon';
import WalkthroughBtn from './WalkthoughBtnWithIcon';
import { isPatient} from "domain/User.model";

const styles = theme => ({
    profile: {
        position: 'absolute',
        boxShadow: `0 0 5px 0 ${transparentBlack03}`,
        left: 50,
        bottom: 0,
        color: transparentBlack087,
        backgroundColor: htmlWhite,
        fontSize: 13,
        fontFamily: 'Arial',
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: 0.01071,
        zIndex: profileContainer,
        width: 'auto',
        paddingTop: 5
    },
    profileContent: {
        paddingTop: 5,
        paddingBottom: 5,
        paddingRight: 9,
        backgroundColor: htmlWhite,
        '&:hover': {
            backgroundColor: concrete,
            cursor: 'pointer'
        },
        whiteSpace: 'nowrap'
    },
    avatar: {
        width: 30,
        height: 30,
        margin: 10,
        '&:hover': {
            border: `2px solid ${linkWater}`,
            cursor: 'pointer'
        }
    },
    profileAvatar: {
        width: 50,
        height: 50,
        marginBottom: 5
    },
    icon: {
        width: 29,
        height: 18,
        margin: 4
    },
    username: {
        display: 'inline',
        textTransform: 'capitalize',
        fontWeight: 'bold'
    },
    email: {
        display: 'inline',
        whiteSpace: 'nowrap'
    },
    version: {
        color: persianGreen,
        fontSize: 12,
        textAlign: 'center'
    },
});


export class Profile extends Component {

    state = {showProfile: false};

    toggleProfile = () => {
        this.setState({showProfile: !this.state.showProfile})
    };

    hideProfile = () => {
        this.setState({showProfile: false})
    };

    render() {
        const {
            classes, t: translate, user, positionStyle, clinicCenters, fetchCurrentUser, outstandingInvites,
            careProviders
        } = this.props;
        const {showProfile} = this.state;
        const careProviderNames = user.careProviderIds.map(cpId => (careProviders || []).find(cp => cp.id === cpId)?.name);
        const clinicNames = user.clinicCenterIds.map(ccId => (clinicCenters || []).find(cc => cc.id === ccId)?.name);
        const careProviderAndClinicCenterNames = careProviderNames.concat(clinicNames);
        return (
            <ClickAwayListener onClickAway={this.hideProfile}>
                <div walkthrough-element="profile">
                    {
                        !_.isEmpty(outstandingInvites) &&
                            <OutstandingInvites/>
                    }
                    <HmoAvatar {...{user: user}} classes={{avatarRoot: classes.avatar}} onClick={this.toggleProfile}/>
                    {
                        showProfile &&
                        <Flex container direction={'column'} className={classes.profile} style={positionStyle}>
                            <Flex item container center column style={{padding: 12}}>
                                <HmoAvatar {...{user: user}} classes={{avatarRoot: classes.profileAvatar}}/>
                                <span className={classes.username}>{getName(user)}</span>
                                <span className={classes.email}>{user.emailAddress}</span>
                                <UserManager {...{
                                    buttonStyle: {marginTop: 10, marginRight: 'auto', marginLeft: 'auto'},
                                    userBusinessId: user?.businessId,
                                    emailAddresses: [user?.emailAddress],
                                    title: translate('global.account-management'),
                                    updateCallback: fetchCurrentUser
                                }}/>
                                { careProviderNames && careProviderAndClinicCenterNames.map((institutionName, index) => (
                                    <div key={index} className={classes.email}>{institutionName}</div>
                                ))}
                            </Flex>
                            {
                                FeatureSwitch.versionInProfile.active &&
                                <span className={classes.version}>v.{process.env.REACT_APP_VERSION}</span>
                            }
                            <Divider/>
                            <Flex item container
                                  alignItems={"center"}
                                  className={classes.profileContent}
                                  style={{paddingLeft: 9}}
                            >
                                <LanguageSelector/>
                            </Flex>
                            <Divider/>
                            {isPatient(user) && <WalkthroughBtn /> }
                            <Divider/>
                            <LogoutWithIcon/>
                        </Flex>

                    }
                </div>
            </ClickAwayListener>
        )
    }
}

export default withStyles(styles, {withTheme: true})(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(Profile)));
