import { fields } from './filter-configs/fields.conf';

export const categorizeRootNodes = (nodes) => {
    return nodes.reduce((categorized, node, index) => {
        const fieldConfig = fields[node.properties.field];
        return fieldConfig
            ? !categorized[fieldConfig.category]
                ? { ...categorized, [fieldConfig.category]: [{ node, index, config: fieldConfig }] }
                : {
                      ...categorized,
                      [fieldConfig.category]: [
                          ...categorized[fieldConfig.category],
                          { node, index, config: fieldConfig },
                      ],
                  }
            : categorized;
    }, {});
}
