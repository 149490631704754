import React, {Component} from 'react';
import {
    Typography,
    Button,
    MenuItem
} from "@material-ui/core";
import {withTranslation} from 'react-i18next';
import Flex from 'components/grid/Flex';
import _ from 'lodash';
import utils from 'utils/Utils';
import MultiInput from 'components/input/MultiInput';
import ConfirmationDialog from 'components/confirmation-dialog/ConfirmationDialog';
import DefaultTextField from 'components/hmo-textfield/DefaultTextField';
import Accordion from 'components/grid/Accordion';
import {CLINIC} from 'domain/User.model';
import MultiInputEmailAddressEditor from 'scenes/user-manager/access-manager/MultiInputEmailAddressEditor';
import {CLINIC_CENTER} from 'domain/EntityAuthorizationType.model';

export class Subordinate extends Component {

    state = {
        oldContact: {},
        isDeleteDialogOpen: false,
        contactToDelete: ''
    }

    componentDidMount() {
        this.setState({oldContact: this.props.contact});
    }

    save = event => {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.setState({
            oldContact: this.props.contact
        }, () => {
            this.props.saveContact(this.props.contact);
        })
    }

    changeState = event => {
        this.props.onContactChange(this.props.contact)(event);
    }

    handlePhoneNumbersChange = (props) => {
        this.changeState({
            target: {
                name: 'phoneNumbers',
                value: props.phoneNumbers
            }
        });
    }

    handleEmailAddressesChange = (props) => {
        this.changeState({
            target: {
                name: 'emailAddresses',
                value: props.emailAddresses
            }
        });
    }

    openDeleteDialog = contactToDelete => event => {
        this.setState({
            contactToDelete
        });
    }

    deleteUserDetails = () => {
        this.props.deleteUserDetails(this.state.contactToDelete);
        this.setState({
            contactToDelete: ''
        });
    }

    closeDeleteDialog = () => {
        this.setState({
            contactToDelete: ''
        });
    }

    render() {
        const { t: translate, contact, configuration, refreshContacts, entityIdToAssign } = this.props;
        const {changeState, openDeleteDialog, deleteUserDetails, save} = this;
        const {contactToDelete} = this.state;
        const isContactChanged = JSON.stringify(this.state.oldContact) !== JSON.stringify(contact);
        return (
            <Accordion openByDefault={!contact.givenName && !contact.familyName} titleComponent={
                <Flex item container justifyContent={'space-between'} alignItems={'center'}>
                    <Typography style={{fontSize: 16, fontWeight: 'bold'}}>
                        {translate('global.secretary')}: {utils.formattedUserName(contact)}
                    </Typography>

                    <Flex item={'0 1 auto'} container>
                        <Button size={'small'} onClick={openDeleteDialog(contact)} style={{marginRight: 10}}>
                            {translate('global.delete')}
                        </Button>
                        {
                            isContactChanged &&
                            <Button size={'small'} style={{backgroundColor: 'orange'}} onClick={save}>
                                {translate('global.save')}
                            </Button>
                        }
                    </Flex>
                </Flex>
            }>
                <Flex container column>
                    <Flex id={'first-line'} item container wrap>
                        <Flex item container>
                            <DefaultTextField label={translate("case.salutation")}
                                              style={{width: '100%'}}
                                              select
                                              SelectProps={{
                                                  value: contact.salutation || '',
                                                  name: 'salutation',
                                                  onChange: changeState
                                              }}>
                                {configuration.salutations && configuration.salutations.map(salutation => (
                                    <MenuItem key={salutation} value={salutation}>
                                        {translate(`global.${salutation}`)}
                                    </MenuItem>
                                ))}
                            </DefaultTextField>
                            <DefaultTextField value={contact.title || ''}
                                              name={'title'}
                                              style={{width: '100%'}}
                                              label={translate("case.title")}
                                              onChange={changeState}/>
                        </Flex>
                        <Flex item container>
                            <DefaultTextField value={contact.givenName || ''}
                                              label={translate("case.first-name")}
                                              style={{width: '100%'}}
                                              name={'givenName'}
                                              onChange={changeState}/>

                            <DefaultTextField label={translate("case.last-name")}
                                              value={contact.familyName || ''}
                                              onChange={changeState}
                                              style={{width: '100%'}}
                                              name={'familyName'}/>
                        </Flex>
                    </Flex>
                    <DefaultTextField label={translate("global.occupation")}
                                      value={contact.occupation || ''}
                                      onChange={changeState}
                                      name={'occupation'}/>
                    <Flex id={'third-line'} item container>
                        <Flex item container column style={{marginRight: 10}}>
                            <MultiInput {...{
                                inputValues: contact.phoneNumbers,
                                translate,
                                name: 'phoneNumbers',
                                updateInputValues: this.handlePhoneNumbersChange
                            }}/>
                        </Flex>
                        <Flex item container column>
                            {
                                contact.userEmailAddress && !contact.emailAddresses.includes(contact.userEmailAddress) &&
                                <DefaultTextField value={contact.userEmailAddress || ''}
                                                  label={translate("global.user-email-address")}
                                                  disabled
                                                  onChange={()=>{}}/>
                            }
                            <MultiInputEmailAddressEditor {...{
                                entityIdToAssign,
                                entityType: CLINIC_CENTER,
                                emailAddresses: contact.emailAddresses,
                                invitedRoleType: CLINIC,
                                person: contact,
                                updateInputValues: this.handleEmailAddressesChange,
                                refresh: refreshContacts
                            }}/>
                        </Flex>

                    </Flex>
                </Flex>
                <ConfirmationDialog {...{
                    dialogOpen: !_.isEmpty(contactToDelete),
                    onDialogClose: this.closeDeleteDialog,
                    onConfirm: deleteUserDetails,
                    confirmationTextKey: "clinic.delete-secretary"
                }}/>
            </Accordion>

        );
    }
}

export default withTranslation()(Subordinate);
