import React, {Component} from 'react';
import {TextField, withStyles} from "@material-ui/core";
import _ from 'lodash';

const styles = theme => ({
    notchedOrangeOutline: {
        borderColor: 'orange',
    },
    notchedBlueOutline: {
        borderColor: 'blue',
    },
    root: {
    '& label:not(.MuiInputLabel-shrink)': {
        color: 'rgba(0, 0, 0, 0.34)',
    },
    }
});

export class HmoTextField extends Component {

    selectAllOnFocus = event => {
        event.target.select && event.target.select();
    };

    handleKeyPress = (onEnterKey, onKeyPress) => event => {
        const isEnterPressed = (event.key && event.key === 'Enter') || (event.keyCode && event.keyCode === 13);
        const isShiftPressed = event.shiftKey;
        if (isEnterPressed && !isShiftPressed) {
            onEnterKey && onEnterKey(event);
        } else {
            onKeyPress && onKeyPress(event);
        }
    };

    render() {
        const {
            classes, onEnterKey, onKeyPress, onFocus, variant, inputPropsStyle, inputProps, selectAllOnFocus,
            autoComplete = "new-password", warning, info, InputProps, multiline, transparentBg = false, ...otherProps
        } = this.props;
        const backgroundColor = transparentBg ? undefined : "white" ;

        return (
            <TextField
                onKeyPress={this.handleKeyPress(onEnterKey, onKeyPress)}
                classes={{...(classes.root && {root: classes.root})}}
                multiline={multiline}
                {...{
                    ...otherProps,
                    ...(!!(onFocus || selectAllOnFocus) && {onFocus: onFocus || this.selectAllOnFocus}),
                    variant,
                    inputProps: {
                        ...inputProps,
                        autoComplete,
                    },
                    ...(variant === 'outlined' ? {
                        inputProps: {
                            ...inputProps,
                            autoComplete,
                            style: {
                                padding: '8px 10px',
                                backgroundColor,
                                ...inputPropsStyle
                            }
                        }
                    } : {
                        inputProps: {
                            ...inputProps,
                            autoComplete,
                            style: {
                                paddingLeft: 7,
                                ...(transparentBg && {borderBottomColor: 'unset'}),
                                backgroundColor,
                                ...inputPropsStyle
                            }
                        }
                    }),
                    InputProps: {
                        ...InputProps,
                        style:{...(multiline && {padding: 0}), color: 'inherit'},
                        classes: {
                            ..._.get(InputProps, 'classes'),
                            ...(warning && {notchedOutline: classes.notchedOrangeOutline}),
                            ...(info && {notchedOutline: classes.notchedBlueOutline})
                        },
                    }
                }}
            />
        );
    }
}

export default withStyles(styles)(HmoTextField);
