import React, {Component} from 'react';
import {
    withStyles,
    Typography,
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {
    htmlWhite,
    alabaster,
    concrete,
    htmlLightGrey,
    grey
} from 'components/colors/Colors';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import HmoAvatar from 'components/avatar/HmoAvatar';
import HmoTextField from 'components/hmo-textfield/HmoTextField';
import HmoFilledButton from 'components/button/HmoFilledButton';
import {SYSTEM_USER} from 'domain/User.model';
import Title from 'components/title/Title';

const styles = theme => ({
    avatar: {
        margin: 0
    },
    entryContainer: {
        borderRadius: 6,
        margin: 10,
        marginTop: 20,
        padding: 15
    },
    selectRoot: {
        color: htmlWhite,
        fontSize: '1.25rem',
        fontWeight: 500,
        padding: 'unset'
    },
    input: {
        padding: 10
    }
});

export class CommunicationsLog extends Component {

    state = {
        comment: ''
    };

    handleCommentChange = event => {
        this.setState({
            comment: event.target.value
        })
    };

    addComment = () => {
        if(this.state.comment){
            this.props.addLog(this.state.comment);
            this.setState({
                comment: ''
            })
        }
    };

    render() {
        const {classes, t: translate, logs, user, title, style, isEditDisabled} = this.props;
        const {comment} = this.state;
        const {handleCommentChange, addComment} = this;

        return (
            <Flex item={'0 1 auto'} container direction={'column'}
                  className={classes.entryContainer} style={{
                backgroundColor: alabaster,
                height: 'fit-content',
                maxHeight: '40vh',
                overflow: 'auto',
                ...style
            }}
            >
                <Title>{title}</Title>
                <Flex item container style={{marginTop: 10, marginBottom: 10}}>
                    <HmoAvatar {...{user}} style={{margin: 4}}/>
                    <HmoTextField
                        value={comment}
                        variant={'outlined'}
                        multiline
                        disabled={isEditDisabled}
                        placeholder={translate('global.add-entry')}
                        InputProps={{
                            classes: {input: classes.input}
                        }}
                        onChange={handleCommentChange}
                        fullWidth
                        name="comment"
                    />
                    <HmoFilledButton disabled={isEditDisabled} onClick={addComment} style={{marginLeft: 10}}>
                        {translate('global.send')}
                    </HmoFilledButton>
                </Flex>
                <Flex container direction={'column'}>
                    {
                        logs.map(log => (
                            <Flex item container direction={'column'} key={log.timestamp} style={{marginBottom: 10}}>
                                <Flex item container alignItems={'center'}>
                                    <Typography style={{fontWeight: 'bold', fontSize: '1rem'}}>
                                        {moment(log.timestamp).format('DD. MMM YYYY')}
                                    </Typography>
                                    <Typography style={{marginLeft: 10, marginRight: 10, color: grey}}>
                                        {moment(log.timestamp).format('HH:mm')}
                                    </Typography>
                                    <HmoAvatar {...{user: log.user || SYSTEM_USER}} style={{
                                        width: 20,
                                        height: 20,
                                        fontSize: 12,
                                    }}/>
                                </Flex>
                                <Flex item container style={{
                                    padding: 6,
                                    paddingBottom: 11,
                                    border: `1px solid ${htmlLightGrey}`,
                                    borderRadius: 4,
                                    backgroundColor: concrete,
                                    width: '100%'
                                }}>
                                    <Typography style={{wordBreak: 'break-all'}}>{log.comment || log.text}</Typography>
                                </Flex>
                            </Flex>
                        ))
                    }
                </Flex>
            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(CommunicationsLog));
