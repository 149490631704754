import {
    upsertClinic, listClinics, addClinicLog, fetchContacts, fetchClinicLogs, getClinic
} from './Clinics.action';
import {deleteUserDetails, updateUserDetails, createUserDetails, uploadProfileImage} from 'scenes/user-manager/UserManager.action';

export default {
    mapStateToProps: (state) => {
        return {
            user: state.mainReducer.user,
            configuration: state.caseReducer.configuration,
            clinics: state.clinicsReducer.clinics,
            rootsAllowedForUser: state.mainReducer.rootsAllowedForUser,
            indications : state.caseReducer.configuration.indications,
            subIndications: state.caseReducer.configuration.subIndications || [],
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            upsertClinic: clinic => dispatch(upsertClinic(clinic)),
            listClinics: () => dispatch(listClinics()),
            getClinic: clinicBusinessId => dispatch(getClinic(clinicBusinessId)),
            fetchClinicLogs: clinicBusinessId => dispatch(fetchClinicLogs(clinicBusinessId)),
            fetchContacts: clinicBusinessId => dispatch(fetchContacts(clinicBusinessId)),
            updateUserDetails: userDetailsData => dispatch(updateUserDetails(userDetailsData)),
            createUserDetails: props => dispatch(createUserDetails(props)),
            deleteUserDetails: userDetailsId => dispatch(deleteUserDetails(userDetailsId)),
            addClinicLog: (comment, clinicBusinessId, type) => dispatch(addClinicLog(comment, clinicBusinessId, type)),
            uploadContactImage: (contactBusinessId, file) => dispatch(uploadProfileImage(contactBusinessId, file)),
        };
    }
};
