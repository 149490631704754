export const FETCH_CONFIGURATION = '@case/FETCH_CONFIGURATION';
export const FETCH_CONFIGURATION_SUCCESS = '@case/FETCH_CONFIGURATION_SUCCESS';
export const FETCH_CONFIGURATION_FAIL = '@case/FETCH_CONFIGURATION_FAIL';

export const LIST_CASE_MANAGERS = '@case_edit/LIST_CASE_MANAGERS';
export const LIST_CASE_MANAGERS_SUCCESS = '@case_edit/LIST_CASE_MANAGERS_SUCCESS';
export const LIST_CASE_MANAGERS_FAIL = '@case_edit/LIST_CASE_MANAGERS_FAIL';

export const LIST_CASES = '@case-list/LIST_CASES';
export const LIST_CASES_SUCCESS = '@case-list/LIST_CASES_SUCCESS';
export const LIST_CASES_FAIL = '@case-list/LIST_CASES_FAIL';

export const ACCEPT_CASE = '@case-list/ACCEPT_CASE';
export const ACCEPT_CASE_SUCCESS = '@case-list/ACCEPT_CASE_SUCCESS';
export const ACCEPT_CASE_FAIL = '@case-list/ACCEPT_CASE_FAIL';

export const UPDATE_CASE_LIST_FILTERS = '@case-list/UPDATE_CASE_LIST_FILTERS';
export const UPDATE_CASE_LIST_PANELS = '@case-list/UPDATE_CASE_LIST_PANELS';


