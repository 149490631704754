import { Trans } from "react-i18next";
import { categoryLabels } from "../../filter-configs/categories";
import {useTheme} from "@material-ui/core/styles";
import { NodeRenderer } from "./node-renderers/NodeRenderer";
import { categorizeRootNodes } from "../../categorizeRootNodes";

export const FiltersByCategoryRenderer = ({formTree}) => {
    const theme = useTheme();
    const categorizedNodes = categorizeRootNodes(formTree);

    return Object.entries(categorizedNodes).map(([categoryName, categoryValues]) => (
        <div
            style={{
                background: "white",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: theme.palette.grey[300],
                borderRadius: "4px",
                padding: theme.spacing(1),
                marginBottom: theme.spacing(1),
            }}
            key={categoryName}
        >
            <div style={{ fontWeight: "bold", fontSize: "1.2em", marginBottom: theme.spacing(2) }}>
                <Trans i18nKey={categoryLabels[categoryName] || categoryName} />
                </div>

            {categoryValues.map((componentData, idx) => <NodeRenderer key={idx} componentData={componentData} indexPath={[componentData.index]} idx={idx} />)}
        </div>
    ));
};
