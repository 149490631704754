import React, {Component} from 'react';
import {
    withStyles,
    Typography,
    IconButton,
    Dialog,
    Select,
    MenuItem,
    ListItemText
} from "@material-ui/core";
import {
    Close as CloseIcon,
    Check as CheckIcon
} from "@material-ui/icons";
import Flex from 'components/grid/Flex';
import {
    grey,
    htmlGrey,
    htmlWhite
} from 'components/colors/Colors';
import {withTranslation} from 'react-i18next';
import HmoInputLabel from 'components/input-label/HmoInputLabel';
import HmoTextField from 'components/hmo-textfield/HmoTextField';
import utils from 'utils/Utils';

const styles = theme => ({
    inputRoot: {
        backgroundColor: htmlWhite,
        borderRadius: 4,
        paddingLeft: 7
    }
});

export class UpdateDialog extends Component {

    state = {
        newDescription: ''
    };

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    };

    save = () => {
        const fileName = this.props.fileName;
        const description = this.state.newDescription;
        const documentType = this.props.documentType;

        this.props.updateDocument(this.props.documentToUpdate, {fileName, description, documentType});
        this.setState({
            newDescription: ''
        });
        this.props.closeUpdateDialog();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open === true && prevProps.open === false) {
            this.reset();
        }
    }

    reset = () => {
        this.setState({
            newDescription: this.props.documentToUpdate.description
        });
    };

    render() {
        const {
            classes, t: translate, open, closeUpdateDialog, handleFileNameChange, fileNameWithoutExtension, fileExtension,
            preventDots, documentType, documentTypes, handleDocumentTypeChange
        } = this.props;
        const {handleChange, save} = this;
        const {newDescription} = this.state;
        const isMobile = utils.checkMobileBrowser();
        return (
            <Dialog open={open} onClose={closeUpdateDialog}>
                <Flex item container direction={'column'} padding={20}>
                    <Flex item container style={{marginBottom: 5, borderBottom: `1px solid ${grey}`}}
                          alignItems={'center'} justifyContent={'space-between'}>
                        <Flex item container alignItems={'center'}>
                            <Typography {...{
                                ...(isMobile ? {style: {fontWeight: 'bold'}} : {variant: 'h5'}),
                            }}>{translate('case.document-update-title')}</Typography>
                        </Flex>
                        <Flex item container justifyContent={'flex-end'}>
                            <IconButton onClick={closeUpdateDialog}>
                                <CloseIcon/>
                            </IconButton>
                            <IconButton onClick={save}>
                                <CheckIcon/>
                            </IconButton>
                        </Flex>

                    </Flex>
                    <Typography
                        style={{
                            color: htmlGrey,
                            margin: '10px 0'
                        }}>{translate('case.document-update-text')}</Typography>
                    <Flex item grow={0} container column fullWidth style={{marginBottom: 20}}>
                        <HmoInputLabel>{translate("case.descriptive-name")}</HmoInputLabel>
                        <Flex item container>
                            <HmoTextField
                                value={fileNameWithoutExtension || ''}
                                onChange={handleFileNameChange}
                                onKeyPress={preventDots}
                                placeholder={translate('case.document-upload-name-placeholder')}
                                fullWidth
                                name={'fileName'}
                            />
                            {
                                fileExtension &&
                                <Typography>.{fileExtension}</Typography>
                            }
                        </Flex>
                    </Flex>

                    <Flex item container column fullWidth style={{marginBottom: 20}}>
                        <HmoInputLabel>{translate("case.type")}</HmoInputLabel>
                        <Select
                            value={documentType || ''}
                            classes={{root: classes.inputRoot}}
                            onChange={handleDocumentTypeChange}
                            fullWidth
                            name={'documentType'}
                            renderValue={value => value.longName}
                        >
                            {documentTypes && documentTypes.map(fileTypeInArray => (
                                <MenuItem key={fileTypeInArray.enumName} value={fileTypeInArray}>
                                    <ListItemText primary={fileTypeInArray.longName}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </Flex>

                    <Flex item container column>
                        <HmoInputLabel>
                            {translate('case.document-update-description')}
                        </HmoInputLabel>
                        <HmoTextField
                            value={newDescription || ''}
                            onChange={handleChange}
                            name={'newDescription'}
                            rows={3}
                        />
                    </Flex>
                </Flex>
            </Dialog>
        );
    }
}

export default withStyles(styles)(withTranslation()(UpdateDialog));
