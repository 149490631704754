import { curry } from 'lodash/fp';
/**
 *
 * @param {{id:number}[]} dependencies
 * @param {number} stepDependsOn
 * @returns {boolean}
 */
export const isStepDependencyMissing = (dependencies, stepDependsOn) => {
    return stepDependsOn !== null ? !dependencies.some((dependency) => dependency.id === stepDependsOn) : false;
}

export const isStepDependencyMissingCurried = curry(isStepDependencyMissing);
