import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useRef, useEffect, useState, createRef } from "react";
import { min } from "lodash";

const useStyles = makeStyles((theme) => ({
    table: {
        display: "flex",
        flexDirection: "column",
        border: "1px solid #E2E1E4",
        borderRadius: "15px",
    },
    row: {
        display: "flex",
        "&:last-child $cell": {
            borderBottom: "unset",
        },
        "&:last-child $cell:first-child": {
            borderBottomLeftRadius: "15px",
        },
        "&:last-child $cell:last-child": {
            borderBottomRightRadius: "15px",
        },
        "&:first-child $cell:first-child": {
            borderTopLeftRadius: "15px",
        },
        "&:first-child $cell:last-child": {
            borderTopRightRadius: "15px",
        },
        "&:hover $cell:not($headerCell) ": {
            backgroundColor: theme.palette.action.hover,
            color: theme.palette.text.primary,
        },
    },
    noRowsPresent: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: 15,
        paddingRight: 15,
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    cell: {
        display: "flex",
        flex: 1,
        position: "relative",
        borderBottom: "1px solid #E2E1E4",
        backgroundColor: "#fff",
        paddingLeft: "18px",
        paddingRight: "18px",
        minHeight: "60px",
        alignItems: "center",
        fontSize: "16px",
        color: "#777777",
        overflowWrap: "anywhere"
    },
    headerCell: {
        textWrap: "nowrap",
        color: "#030303",
        fontSize: "14px",
        borderColor: "transparent",
        borderBottomColor: "#E2E1E4",
        backgroundColor: "#F8F8FA",
        fontWeight: "500",
    },
}));

const rowsSortingComparator = (a, b) => {
    return a.gene.localeCompare(b.gene) || new Date(a.date) - new Date(b.date);
};

/**
 *
 * @param {Object} props - Component props
 * @param {{field: string, headerName: string, width?: number, minWidth?: number renderCell?: (params: Object) => JSX}[]} props.columns - Columns to display
 * @param {Object[]} props.rows - Rows to display
 * @param {Object} props.style - Styles to apply to the root element of component
 * @returns
 */
export const AnamnesisTable = ({ style = {}, columns, rows = [] }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const sortedRows = [...rows].sort(rowsSortingComparator);
    const tableRef = useRef(null);
    const [headerWidths, setHeaderWidths] = useState([]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            resizeHeaders();
        });
        const tableRefValue = tableRef?.current;
        if (!tableRefValue) return;
        resizeObserver.observe(tableRef.current);
        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    const resizeHeaders = () => {
        const columnMaxWidthds = columns.map(({ field }) => {
            const allCellsOfField = tableRef.current.querySelectorAll(`[data-column=${field}]`);
            if (allCellsOfField.length < 2) return allCellsOfField[0]?.offsetWidth;
            const maxWidth = Array.from(allCellsOfField).reduce((a, b) => {
                return Math.max(a.offsetWidth, b.offsetWidth);
            });
            return maxWidth;
        });
        setHeaderWidths(columnMaxWidthds);
    };

    return (
        <div style={style}>
            <div ref={tableRef} className={classes.table}>
                <div className={classes.row}>
                    {columns.map((column, idx) => (
                        <div
                            className={[classes.cell, classes.headerCell].join(" ")}
                            key={column.headerName}
                            style={{
                                minWidth: column.width ? headerWidths[idx] || column.width : "",
                                maxWidth: column.width ? headerWidths[idx] || column.width : "",
                            }}
                        >
                            {column.headerName}
                        </div>
                    ))}
                </div>
                {rows.length === 0 && <div className={classes.noRowsPresent}>{t("global.no-entries")}</div>}
                {sortedRows.map((row, index) => (
                    <div key={index} className={classes.row}>
                        {columns.map((column, columnIndex) => (
                            <div
                                data-column={column.field}
                                className={classes.cell}
                                key={column.headerName + columnIndex}
                                style={{
                                    flex: column.flex,
                                    minWidth: column.minWidth ?? column.width,
                                    maxWidth: column.width,
                                }}
                            >
                                {column.renderCell
                                    ? column.renderCell({
                                          row,
                                          value: row[column.field],
                                      })
                                    : `${row[column.field] ?? ""} ${column.field === "vaf" ? "%" : ""}`}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};
