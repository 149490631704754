import React, {Component} from 'react';
import {withStyles, Switch, InputLabel} from "@material-ui/core";
import {silver2, htmlWhite, silver} from 'components/colors/Colors';
import Flex from 'components/grid/Flex';

const styles = theme => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    iOSSwitchBase: {
        padding: 1,
        '&$iOSChecked': {
            transform: 'translateX(16px)',
            color: htmlWhite,
            '& + $iOSBar': {
                backgroundColor: silver2,
                opacity: 1,
                border: 'none',
            },
        },
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.sharp,
        }),
    },
    iOSChecked: {},
    iOSBar: {
        borderRadius: 13,
        border: 'solid 1px',
        borderColor: silver,
        backgroundColor: silver2,
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    iOSIcon: {
        width: 24,
        height: 24,
    },
    iOSIconChecked: {
        boxShadow: theme.shadows[1],
    },
    checkedText: {
        color: 'black',
        fontWeight: 'bold'
    },
    unCheckedText: {
        color: 'lightgrey',
    }
});

export class GreySwitch extends Component {
    render() {
        const {classes, item, style, labelRight, labelLeft, isLeftSelected, toggle, disabled, ...other} = this.props;

        return <Flex style={{minHeight: 36, cursor: 'pointer', display: 'inline-flex', ...style}}
                     justifyContent={'space-between'} alignItems={'center'} onClick={disabled ? ()=>{} :toggle}>
            <InputLabel className={isLeftSelected ? classes.checkedText : classes.unCheckedText}>{labelLeft}</InputLabel>
            <Switch
                classes={{
                    root: classes.root,
                    switchBase: classes.iOSSwitchBase,
                    track: classes.iOSBar,
                    thumb: classes.iOSIcon,
                    checked: classes.iOSChecked,
                }}
                disableRipple
                checked={!isLeftSelected}
                {...other}
            />
            <InputLabel className={!isLeftSelected ? classes.checkedText : classes.unCheckedText}>{labelRight}</InputLabel>
        </Flex>;
    }
}

export default withStyles(styles)(GreySwitch);
