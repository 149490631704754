import React, {Component} from 'react';
import {
    InputAdornment,
    IconButton,
} from "@material-ui/core";
import {Add} from '@material-ui/icons';
import update from 'immutability-helper';
import _ from 'lodash';
import DefaultTextField from 'components/hmo-textfield/DefaultTextField';

const SingleInput = props => {
    const {
        translate, value, isFirst, handleInputChange, isDisabled, addInput, name, translateKey, standAlone,
        defaultTextFieldProps, onClick, disabledInput, labelOverrideForFirstInput
    } = props;
    return <DefaultTextField
            InputProps={{
                endAdornment: isFirst &&
                        <InputAdornment position={'end'}>
                            <IconButton style={{padding: 0}} onClick={addInput} disabled={isDisabled}>
                                <Add/>
                            </IconButton>
                        </InputAdornment>
            }}
            standAlone={standAlone}
            label={
                labelOverrideForFirstInput && isFirst
                        ? labelOverrideForFirstInput
                        : (isFirst ? '' : translate('global.additional')) + translate(translateKey || `global.${name}`)
            }
            value={value || ''}
            onChange={handleInputChange}
            disabled={isDisabled || disabledInput === value}
            name={name}
            onClick={onClick}
            {...defaultTextFieldProps}
    />
};

export class MultiInput extends Component {

    handleInputChange = index => event => {
        if (this.props.takeControlOnClick) {
            this.props.takeControlOnClick(this.props.inputValues[index], index);
        } else {
            const targetValue = event.target.value;
            if (_.isEmpty(targetValue) && this.props.inputValues.length > 1) {
                this.props.updateInputValues({
                    [this.props.name]: update(this.props.inputValues, {
                        $splice: [[index, 1]]
                    })
                })
            } else {
                this.props.updateInputValues({
                    [this.props.name]: update(this.props.inputValues, {
                        [index]: {$set: targetValue}
                    })
                })
            }
        }
    };

    addInput = event => {
        event.stopPropagation();
        event.preventDefault();
        if (!this.props.isDisabled) {
            if (this.props.inputValues[this.props.inputValues.length - 1] !== '') {
                this.props.updateInputValues({
                    [this.props.name]: update(this.props.inputValues || [], {
                        $push: ['']
                    })
                })
            }
        }
    };

    render() {
        const {
            translate, isDisabled, name, translateKey, standAlone, defaultTextFieldProps, takeControlOnClick,
            disabledInput, labelOverrideForFirstInput
        } = this.props;
        const {handleInputChange, addInput} = this;
        const inputValues = _.isEmpty(this.props.inputValues) ? [''] : this.props.inputValues;
        return (
                inputValues.map((value, index) => (
                        <SingleInput key={index} {...{
                            translateKey,
                            name,
                            standAlone,
                            labelOverrideForFirstInput,
                            index,
                            translate,
                            isDisabled,
                            isFirst: index === 0,
                            value,
                            addInput,
                            handleInputChange: handleInputChange(index),
                            defaultTextFieldProps,
                            disabledInput,
                            ...(takeControlOnClick && {
                                onClick: () => {
                                    takeControlOnClick(value, index)
                                }
                            }),
                        }}/>
                ))
        );
    }
}

export default MultiInput;
