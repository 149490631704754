import * as _ from "lodash";

function getAllowedLanguages() {
    return ['en', 'de']
}

function getMaxFileUploadSizeInBytes() {
    return _.get(window.appConfig, 'maxFileUploadSize', 50 * 1024 * 1024); // 50 MB
}

function getGoogleMapsApiKey() {
    let apiKey = _.get(window.appConfig, 'googleMaps.apiKey');
    if (_.isEmpty(apiKey)) {
        throw new Error("window.appConfig.googleMaps.apiKey is not given!");
    }
    return apiKey;
}

function getGoogleMapsInitialCenterConfig() {
    let initialCenter = _.get(window.appConfig, 'googleMaps.initialCenterConfig');
    if (_.isEmpty(initialCenter)) {
        throw new Error("window.appConfig.googleMaps.initialCenterConfig is not given!");
    }
    return initialCenter;
}

const configs = {
    getAllowedLanguages,
    getMaxFileUploadSizeInBytes,
    getGoogleMapsApiKey,
    getGoogleMapsInitialCenterConfig
};

export default configs;
