import { makeStyles } from "@material-ui/core/styles";
import { useContext } from "react";
import cohortFilterState from "../../../../filter-state";

const useStyles = makeStyles((theme) => ({
    addAnotherBtn: {
        cursor: "pointer",
        color: "#0763db",
        fontSize: "1.2em",
        marginLeft: theme.spacing(1),
        "&:hover": {
            color: "#0047a5",
        },
    },
}));

export const AddAndClauseButton = ({ nodeIndex, label }) => {
    const { dispatch } = useContext(cohortFilterState.queryFormContext);
    const classes = useStyles();
    return (
        <span
            className={classes.addAnotherBtn}
            onClick={() => {
                dispatch({
                    type: cohortFilterState.actions.ADD_AND_RULE,
                    payload: {
                        index: nodeIndex,
                    },
                });
            }}
        >
            {label}
        </span>
    );
};
