import {upsertChatLog, getChatLog} from './Chat.action';

export default {
    mapStateToProps: (state) => {
        return {
            user: state.mainReducer.user,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            upsertChatLog: (chatLog) => dispatch(upsertChatLog(chatLog)),
            getChatLog: (entityType, entityId) => dispatch(getChatLog(entityType, entityId)),
        };
    }
};
