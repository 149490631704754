import React, { Component } from 'react';
import { withStyles, Typography } from "@material-ui/core";

const styles = theme => ({
    title: {
        textTransform: 'uppercase',
        fontSize: 15,
        fontWeight: 'bold'
    }
});

export class Title extends Component {
    render() {
        const {classes, children, style} = this.props;

        return (
            <Typography className={classes.title} style={style}>
                {children}
            </Typography>
        );
    }
}

export default withStyles(styles)(Title);
