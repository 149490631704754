import React, {Component} from "react";
import {withStyles, IconButton, Typography, Tabs, Tab, Button, Box} from "@material-ui/core";
import Flex from "components/grid/Flex";
import {htmlWhite, htmlBlack, persianGreen} from "components/colors/Colors";
import {Done as DoneIcon, ArrowBack as BackIcon} from "@material-ui/icons";
import {CLINICS} from "routes/routes";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import EpiAlert from "components/alert/EpiAlert";
import ClinicMap from "scenes/clinics/components/ClinicMap";
import {connect} from "react-redux";
import connector from "scenes/clinics/Clinics.connect";
import update from "immutability-helper";
import CommunicationsLog from "components/log/CommunicationsLog";
import ClinicCenter from "./ClinicCenter";
import {withRouter} from "utils/Route.utils";
import Sidebar from "components/sidebar/Sidebar";
import HmoFilledButton from "components/button/HmoFilledButton";
import Divider from "components/divider/Divider";
import Contact from "./Contact";
import DefaultTextField from "components/hmo-textfield/DefaultTextField";
import {emptyClinicCenter, emptyContact} from "domain/Clinic.model";
import {t} from "i18next";
import NewCenterDialog from './NewCenterDialog';
import NewContactDialog from './NewContactDialog';
import {CLINIC_CENTER} from "domain/EntityAuthorizationType.model";
import {CLINIC} from "domain/User.model";

const styles = (theme) => ({
    titleBarRoot: {
        zIndex: 7,
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        minHeight: 45,
        color: htmlWhite,
    },
    tabulatorRoot: {
        zIndex: 6,
        backgroundColor: "#F0F9F8",
    },
    tabIndicator: {
        backgroundColor: htmlBlack,
    },
    tabSelected: {
        fontSize: 18,
        fontWeight: "bold",
        color: "black !important",
    },
    tabRoot: {
        textTransform: "capitalize",
        color: persianGreen,
    },
    entryContainer: {
        minHeight: 200,
        borderRadius: 6,
    },
});

const ClinicData = (props) => {
    const {translate, selectedClinic, onChange} = props;
    return (
            <Flex item container wrap fullWidth>
                <Flex item container fullWidth>
                    <DefaultTextField
                            fullWidth
                            value={selectedClinic?.name || ""}
                            name={"name"}
                            label={translate("global.name")}
                            error={!selectedClinic?.name}
                            onChange={onChange}
                    />
                    <DefaultTextField
                            fullWidth
                            value={selectedClinic?.street || ""}
                            name={"street"}
                            label={translate("case.street")}
                            onChange={onChange}
                    />
                    <DefaultTextField
                            fullWidth
                            value={selectedClinic?.zipCode || ""}
                            name={"zipCode"}
                            label={translate("case.zip")}
                            onChange={onChange}
                    />
                </Flex>
                <Flex item container fullWidth>
                    <DefaultTextField
                            fullWidth
                            value={selectedClinic?.city || ""}
                            name={"city"}
                            label={translate("case.city")}
                            onChange={onChange}
                    />
                    <DefaultTextField
                            fullWidth
                            value={selectedClinic?.state || ""}
                            name={"state"}
                            label={translate("case.state")}
                            onChange={onChange}
                    />
                    <DefaultTextField
                            fullWidth
                            value={selectedClinic?.country || ""}
                            name={"country"}
                            label={translate("case.country")}
                            onChange={onChange}
                    />
                </Flex>
                <DefaultTextField
                        value={selectedClinic?.website || ""}
                        name={"website"}
                        fullWidth
                        style={{marginBottom: 20}}
                        label={translate("case.website")}
                        onChange={onChange}
                />
            </Flex>
    );
};

const TitleBar = (props) => {
    const {classes, translate, save, clinic} = props;

    return (
            <Flex
                    container
                    item
                    className={classes.titleBarRoot}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    padding={5}
                    style={{backgroundColor: persianGreen, marginLeft: 60}}
            >
                <Flex item={"0 1 auto"} container alignItems={"center"}>
                    <Flex item={"0 1 auto"} style={{marginLeft: 5, marginRight: 15}}>
                        <IconButton
                                onClick={() => {
                                    save(true);
                                }}
                                style={{padding: 3}}
                        >
                            <BackIcon style={{color: htmlWhite}}/>
                        </IconButton>
                    </Flex>
                    <Flex item={"0 0 auto"} container column>
                        <Typography
                                style={{
                                    textTransform: "uppercase",
                                    fontSize: 12,
                                }}
                        >
                            {translate("global.clinic")}
                        </Typography>
                        <Typography variant="h6" style={{color: htmlWhite}}>
                            {clinic.name}
                        </Typography>
                    </Flex>
                </Flex>
                <Flex item container justifyContent={"flex-start"} style={{paddingLeft: 10, marginLeft: 10}}/>
                <Flex item={"0 1 auto"} container style={{paddingRight: 14}}>
                    <Button onClick={() => save(false)} disabled={!clinic?.name} style={{padding: 3}}>
                        <DoneIcon style={{color: htmlWhite}}/>
                    </Button>
                </Flex>
            </Flex>
    );
};

export class ClinicsEdit extends Component {
    state = {
        activityTab: "DETAILS",
        selectedClinic: {},
        alert: "",
        comments: [],
        contacts: [],
        isLoading: false,
        isNewCenterDialogOpen: false,
        isNewContactDialogOpen: false,
        newCenterBusinessId: ''
    };

    componentDidMount() {
        if (!_.isEmpty(this.props.routeParams.clinicBusinessId)) {
            this.props
                    .getClinic(this.props.routeParams.clinicBusinessId)
                    .then((response) => {
                        const clinic = response.payload.data;
                        if (clinic) {
                            this.setState(
                                    {
                                        selectedClinic: clinic,
                                    },
                                    () => {
                                        this.props.fetchClinicLogs(clinic.businessId).then((response) => {
                                            this.setState({comments: response.payload.data});
                                        });
                                        this.refreshContacts();
                                    }
                            );
                        }
                    })
                    .catch(() => {
                        this.props.navigate(CLINICS.path);
                    });
        }
    }

    toggleNewCenterDialog = () => {
        this.setState({isNewCenterDialogOpen: !this.state.isNewCenterDialogOpen});
    }

    toggleNewContactDialog = () => {
        this.setState({isNewContactDialogOpen: !this.state.isNewContactDialogOpen});
    }

    refreshContacts = () => {
        this.props.fetchContacts(this.state.selectedClinic.businessId).then((response) => {
            this.setState({contacts: response.payload.data});
        });
    };

    save = (goBack, callback) => {
        if (goBack) {
            this.props.navigate(CLINICS.path);
        } else {
            return this.props
                    .upsertClinic(this.state.selectedClinic)
                    .then((response) => {
                        this.props.listClinics();
                        this.setState({alert: "saved"});
                        callback && callback(response);
                        return response;
                    })
                    .catch((error) => {
                        this.setState({alert: "error"});
                        return error;
                    });
        }
    };

    delayedSave = _.debounce(this.save, 2000);

    changeSelectedClinicState = (event, value) => {
        const target = event.target;
        this.setState((oldState) => {
            return {
                selectedClinic: update(oldState.selectedClinic, {
                    [target.name]: {$set: target.value},
                }),
            };
        }, () => {
            this.delayedSave();
        });
    };

    onChangeCenter = (center, fn) => {
        const index = this.state.selectedClinic.centers.findIndex((e) => e.businessId === center.businessId);
        if (index !== -1) {
            this.setState((oldState) => {
                return {
                    selectedClinic: update(oldState.selectedClinic, {
                        centers: {
                            [index]: {$set: fn(this.state.selectedClinic.centers[index])},
                        },
                    }),
                };
            }, () => {
                this.delayedSave();
            });
        }
    };

    changeActivityTab = (event, value) => {
        this.setState({activityTab: value});
    };

    closeSnackbar = () => {
        this.setState({
            alert: "",
        });
    };

    addLog = (comment) => {
        this.props.addClinicLog(comment, this.state.selectedClinic.businessId, "CLINIC").then((response) => {
            this.setState({
                comments: update(this.state.comments, {
                    $push: [response.payload.data],
                }),
            });
        });
    };

    addClinicCenter = (name) => {
        const newCenter = emptyClinicCenter(this.state.selectedClinic.businessId, name);
        this.setState(
                {
                    selectedClinic: update(this.state.selectedClinic, {
                        centers: {
                            $push: [newCenter],
                        },
                    }),
                    isNewCenterDialogOpen: false,
                    newCenterBusinessId: newCenter.businessId
                },
                () => {
                    this.delayedSave.cancel();
                    this.save()
                }
        );
    };

    addContact = (contact) => {
        this.props.createUserDetails({
            userDetailsData: {
                ...emptyContact(this.state.selectedClinic.businessId),
                ...contact
            },
            invitedRoleType: CLINIC,
            entityType: CLINIC_CENTER,
            entityId: this.state.selectedClinic.centers?.find((e) => e.businessId === contact.clinicCenterBusinessId)?.id
        }).then(() => this.refreshContacts()).catch().then(()=>{
            this.setState({isNewContactDialogOpen: false});
        });

    };

    addSubordinate = (subordinate, contact) => {
        this.props.createUserDetails({
            userDetailsData: {
                ...emptyContact(this.state.selectedClinic.businessId),
                ...subordinate,
                subordinateToBusinessId: contact.businessId || null,
                clinicCenterBusinessId: contact.clinicCenterBusinessId || null,
            },
            invitedRoleType: CLINIC,
            entityType: CLINIC_CENTER,
            entityId: this.state.selectedClinic.centers?.find((e) => e.businessId === contact.clinicCenterBusinessId)?.id
        }).then(() => this.refreshContacts());
    };

    onContactChange = (contact) => (event) => {
        const index = this.state.contacts.findIndex(
                (contactInArray) => contact.businessId === contactInArray.businessId
        );
        if (index > -1) {
            this.setState({
                contacts: update(this.state.contacts, {
                    [index]: {
                        [event.target.name]: {$set: event.target.value},
                    },
                }),
            });
        }
    };

    uploadContactImage = (contact, file) => {
        this.setState(
                {
                    isLoading: true,
                },
                () => {
                    this.props
                            .updateUserDetails(contact)
                            .then(() => {
                                return this.props.uploadContactImage(contact.businessId, file).then(() => {
                                    return this.props
                                            .fetchContacts(this.props.routeParams.clinicBusinessId)
                                            .then((response) => {
                                                this.setState({contacts: response.payload.data, isLoading: false});
                                            });
                                });
                            })
                            .catch(() => {
                                this.setState({isLoading: false});
                            });
                }
        );
    };

    saveContact = (contact) => {
        this.props.updateUserDetails(contact).then(() => this.refreshContacts());
    };

    deleteUserDetails = (contact) => {
        this.props.deleteUserDetails(contact.id).then(() => {
            this.props.fetchContacts(this.state.selectedClinic.businessId).then((response) => {
                this.setState({contacts: response.payload.data});
            });
        });
    };

    render() {
        const {classes, t: translate, user, configuration, indications, subIndications} = this.props;
        const {
            selectedClinic, comments, activityTab, contacts, isLoading, isNewCenterDialogOpen, newCenterBusinessId,
            isNewContactDialogOpen
        } = this.state;
        const {
            save, changeActivityTab, addLog, addClinicCenter, addContact, saveContact, refreshContacts,
            onContactChange, deleteUserDetails, addSubordinate, uploadContactImage, onChangeCenter,
            toggleNewCenterDialog, toggleNewContactDialog
        } = this;

        const mainContacts = contacts.filter((contact) => _.isEmpty(contact.subordinateToBusinessId));
        const isSaveDisabled =
                _.isEmpty(selectedClinic) ||
                !_.get(selectedClinic, "zipCode") ||
                !_.get(selectedClinic, "street") ||
                !_.get(selectedClinic, "city") ||
                !_.get(selectedClinic, "country") ||
                !selectedClinic.name;

        const isReadyToAddContactPerson =
                this.state.selectedClinic.centers?.length > 0 &&
                this.state.selectedClinic.centers.some((center) => center.name);

        return (
                <>
                    <Sidebar/>
                    <Flex item container style={{marginTop: 60, marginLeft: 60}}>
                        <TitleBar {...{classes, save, isSaveDisabled, translate, clinic: selectedClinic}} />
                        <Flex item container column>
                            <Tabs
                                    value={activityTab}
                                    onChange={changeActivityTab}
                                    style={{left: 90}}
                                    classes={{indicator: classes.tabIndicator, root: classes.tabulatorRoot}}
                            >
                                <Tab
                                        value={"DETAILS"}
                                        classes={{
                                            selected: classes.tabSelected,
                                            root: classes.tabRoot,
                                        }}
                                        label={translate("global.details")}
                                />
                                <Tab
                                        value={"CONTACT"}
                                        classes={{
                                            selected: classes.tabSelected,
                                            root: classes.tabRoot,
                                        }}
                                        label={translate("global.contact-person")}
                                />
                            </Tabs>
                            {activityTab === "DETAILS" && (
                                    <Flex
                                            id={"clinic-details"}
                                            item
                                            container
                                            column
                                            padding={10}
                                            className={classes.entryContainer}
                                    >
                                        <ClinicData
                                                {...{
                                                    onChange: this.changeSelectedClinicState,
                                                    selectedClinic,
                                                    translate,
                                                }}
                                        />
                                        <Flex item grow={0} container justifyContent={"flex-end"}
                                              style={{marginBottom: 10}}>
                                            <HmoFilledButton onClick={toggleNewCenterDialog}>
                                                + {translate('clinic.add-center')}
                                            </HmoFilledButton>
                                        </Flex>
                                        {(_.get(selectedClinic, "centers") || []).length > 0 && (
                                                <div>
                                                    {selectedClinic.centers.map((center, index) => (
                                                            <ClinicCenter
                                                                    key={`${center.businessId} - ${index} - ${newCenterBusinessId}`}
                                                                    openByDefault={newCenterBusinessId
                                                                            ? newCenterBusinessId === center.businessId
                                                                            : index === 0}
                                                                    {...{
                                                                        user,
                                                                        onChangeCenter,
                                                                        center,
                                                                        indications,
                                                                        subIndications,
                                                                        saveClinic: save,
                                                                    }}
                                                            />
                                                    ))}
                                                </div>
                                        )}
                                        <Divider/>
                                        <CommunicationsLog
                                                {...{
                                                    style: {padding: 5, margin: 0, marginTop: 10},
                                                    title: translate("clinic.clinic-communications-log"),
                                                    logs: comments,
                                                    user,
                                                    addLog,
                                                }}
                                        />
                                    </Flex>
                            )}
                            {activityTab === "CONTACT" &&
                            (isReadyToAddContactPerson ? (
                                    <Flex id={"clinic-contacts"} item={"0 1 auto"} container column padding={10}>
                                        <Flex
                                                item
                                                grow={0}
                                                container
                                                justifyContent={"flex-end"}
                                                style={{marginBottom: 10}}
                                        >
                                            <HmoFilledButton onClick={toggleNewContactDialog} size={"small"}>
                                                + {translate("global.add-contact")}
                                            </HmoFilledButton>
                                        </Flex>

                                        {(mainContacts || []).length > 0 && (
                                                <Flex item container style={{flexWrap: "wrap"}}>
                                                    {mainContacts.map((contact, index) => (
                                                            <Contact
                                                                    key={`${contact.businessId} - ${index}`}
                                                                    {...{
                                                                        contact,
                                                                        contacts,
                                                                        configuration,
                                                                        clinicCenters: selectedClinic.centers,
                                                                        isLoading,
                                                                        saveContact,
                                                                        onContactChange,
                                                                        deleteUserDetails,
                                                                        addSubordinate,
                                                                        uploadContactImage,
                                                                        refreshContacts,
                                                                    }}
                                                            />
                                                    ))}
                                                </Flex>
                                        )}
                                    </Flex>
                            ) : (
                                    <Box textAlign="center" mt={2}>
                                        <Typography
                                                variant="h2">{t('global.add-clinic-center-before-creating-contact')}</Typography>
                                        <Typography variant="subtitle1">
                                            {t('global.add-clinic-center-before-creating-contact-subtitle')}
                                        </Typography>
                                    </Box>
                            ))}
                        </Flex>
                        <ClinicMap {...{selectedClinic, width: 330, height: 400, style: {minWidth: 330, margin: 20}}} />
                        <EpiAlert
                                {...{
                                    isOpen: !!this.state.alert,
                                    close: this.closeSnackbar,
                                    severity: this.state.alert === "error" ? this.state.alert : "success",
                                    message:
                                            this.state.alert === "error"
                                                    ? translate("global.backend-call-failed")
                                                    : this.state.alert
                                                            ? translate(`global.${this.state.alert}`)
                                                            : "",
                                }}
                        />
                    </Flex>
                    {
                        isNewCenterDialogOpen &&
                        <NewCenterDialog {...{
                            toggleNewCenterDialog,
                            addClinicCenter
                        }}/>
                    }
                    {
                        isNewContactDialogOpen &&
                        <NewContactDialog {...{
                            toggleNewContactDialog,
                            addContact,
                            clinicCenters: selectedClinic.centers
                        }}/>
                    }
                </>
        );
    }
}

export default withStyles(styles)(
        connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(withRouter(ClinicsEdit)))
);
