import React, {Component} from 'react';
import {IconButton, Typography, withStyles} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import Flex from 'components/grid/Flex';
import {electricViolet, funGreen, htmlBlue, htmlOrange, htmlWhite, porcelain} from 'components/colors/Colors';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import {isCaseManager, isPatient} from 'domain/User.model';
import PdfViewer from 'components/pdf-viewer/PdfViewer';

const styles = theme => ({
    inputRoot: {
        backgroundColor: htmlWhite,
        borderRadius: 4,
        paddingLeft: 7
    }
});

export class DocumentTile extends Component {

    state = {
        file: '',
        isPdfOpen: false,
        numPages: 0
    }

    getColor = (documentData, assignmentStatus) => {
        switch (documentData.type) {
            case 'TNE':
                return funGreen;
            case 'SECOND_OPINION':
                return funGreen;
            case 'GENERAL': // Same as default
            default:
                if (documentData.status === "UNREAD" && assignmentStatus === 'UNASSIGNED') {
                    return electricViolet;
                } else {
                    return assignmentStatus === 'ASSIGNED' ? funGreen : htmlOrange
                }
        }
    }

    getLabel = (documentData, assignmentStatus) => {
        if (documentData.type === 'TNE') {
            return this.props.t('case.document-type.consent');
        } else if (documentData.type === 'SECOND_OPINION') {
            return this.props.t('case.document-type.second-opinion');
        }
        if (documentData.status === "UNREAD" && assignmentStatus === 'UNASSIGNED') {
            return this.props.t("global.new");
        } else {
            return assignmentStatus === 'ASSIGNED' ? this.props.t("global.assigned") : this.props.t("global.unassigned")
        }
    }

    downloadAndShowPdf = () => {
        if (this.state.file) {
            this.setState({isPdfOpen: true});
        } else {
            this.props.downloadAndReturnCaseFile(this.props.document.documentData)
                    .then(file => {
                        this.setState({
                            file,
                            isPdfOpen: true
                        });
                    });
        }
    }

    toggleIsPdfOpen = () => {
        this.setState({isPdfOpen: !this.state.isPdfOpen});
    }

    render() {
        const {
            document, user, setFileToDelete, downloadCaseFile, setDocumentToUpdate, assignmentStatus
        } = this.props;
        const {documentData, uploader} = document
        const {toggleInput, getColor, getLabel, downloadAndShowPdf, toggleIsPdfOpen} = this;
        const {file, isPdfOpen} = this.state;
        const showDeleteButton = assignmentStatus === 'UNASSIGNED'
                && (isCaseManager(user) || (isPatient(user) && user.businessId === uploader.businessId))
        return (
                <Flex item container direction={'column'}
                      onClick={toggleInput}
                      style={{
                          ...(documentData.type === 'TNE' && {
                              border: `2px solid purple`,
                              borderRadius: 5,
                          }),
                          ...(documentData.type !== 'TNE' && {
                              borderLeft: `5px solid ${getColor(documentData, assignmentStatus)}`,
                              borderBottomRightRadius: 5,
                              borderTopRightRadius: 5,
                          }),
                          backgroundColor: porcelain,
                          padding: 5,
                          margin: '10px 0'
                      }}>
                    <Flex item container justifyContent={'space-between'}>
                        <Typography style={{
                            fontSize: 15,
                            textTransform: 'uppercase',
                            color: getColor(documentData, assignmentStatus)
                        }}>{getLabel(documentData, assignmentStatus)}</Typography>
                        <Flex item grow={0} container>
                            {
                                isCaseManager(user) &&
                                <IconButton style={{padding: 1}} onClick={() => setDocumentToUpdate(documentData)}>
                                    <EditIcon style={{color: htmlBlue}}/>
                                </IconButton>
                            }
                            {
                                showDeleteButton &&
                                <IconButton style={{padding: 1, marginLeft: 5}}
                                            onClick={() => setFileToDelete(documentData)}>
                                    <CancelIcon/>
                                </IconButton>
                            }
                        </Flex>
                    </Flex>
                    <Flex item container alignItems={'center'}>
                        <Typography>{moment(documentData.uploadTime).format('DD. MMM YYYY')}</Typography>
                        <Typography
                                onClick={event => {
                                    event.stopPropagation();
                                    if (documentData.fileName.endsWith('pdf')) {
                                        downloadAndShowPdf();
                                    } else {
                                        downloadCaseFile(documentData);
                                    }

                                }}
                                style={{
                                    color: htmlBlue,
                                    textDecoration: 'underline',
                                    marginLeft: 10,
                                    cursor: 'pointer',
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: 250
                                }}>{documentData.fileName}</Typography>
                        {
                            documentData.fileName.endsWith('pdf') &&
                            <IconButton style={{padding: 3, marginLeft: 5}} onClick={event => {
                                event.stopPropagation();
                                downloadCaseFile(documentData);
                            }}>
                                <DownloadIcon/>
                            </IconButton>
                        }
                    </Flex>

                    {
                        file && isPdfOpen &&
                        <PdfViewer fileBlobUrl={file} onClose={toggleIsPdfOpen}/>
                    }
                </Flex>
        )
    }
}

export default withStyles(styles)(withTranslation()(DocumentTile));
