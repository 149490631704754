import HMOApi, {Anamnesis} from "store/api/HMOApi";

const BASE_URL = '/anamnesis';

export const AnamnesisApi = HMOApi.injectEndpoints({
    endpoints: (build) => ({
        fetchAnamnesisByPatient : build.query({
            query: ({patientId}) => ({ url: `${BASE_URL}/${patientId}` }),
            providesTags: [Anamnesis],
        }),
        saveAnamnesis: build.mutation({
            query: (data) => ({
                url: BASE_URL,
                method: 'POST',
                data,
            }),
        }),
        addAutoimmuneDisease: build.mutation({
            query: (disease) => ({
                url: `${BASE_URL}/autoimmune-disease`,
                method: 'PUT',
                data: {disease},
            }),
        }),
        })
    })

export const {
    useFetchAnamnesisByPatientQuery,
    useFetchSubDataDistinctValuesByQuestionTypeQuery,
    useSaveAnamnesisMutation,
    useAddAutoimmuneDiseaseMutation,

} = AnamnesisApi;
