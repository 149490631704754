import { useContext } from "react";
import { Modal, Box } from "@mui/material";
import { fields } from "../../filter-configs/fields.conf";
import { defaultRulePropertiesByWidgetType } from "../../filter-configs/defaultRulePropertiesByWidgetType";
import { queryFormContext } from "../../filter-state/context";
import { ADD_RULE } from "../../filter-state/actions";
import { categoryLabels } from "../../filter-configs/categories";
import { Trans } from "react-i18next";
import { makeStyles } from "@material-ui/core";

const categorysWithFields = Object.entries(fields)
    .filter(([_, value]) => value.category)
    .reduce((acc, [field, value]) => {
        return acc[value.category]
            ? { ...acc, [value.category]: [...acc[value.category], { name: field, label: value.widgetConfig.label }] }
            : { ...acc, [value.category]: [{ name: field, label: value.widgetConfig.label }] };
    }, {});

const categorysWithFieldsDescByEntrySize = Object.fromEntries(Object.entries(categorysWithFields).sort((a, b) => b[1].length - a[1].length));

const useStyles = makeStyles((theme) => ({
    list: {
        listStyleType: "none",
        padding: "0 0 0 2px",
        margin: 0,
    },
    filterLink: {
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.primary.main,
        },
        "&.disabled": {
            cursor: "initial",
            color: theme.palette.text.disabled,
        },
    },
}));

export const AddFilterModal = ({formTree, isOpen, onClose }) => {
    const { dispatch } = useContext(queryFormContext);
    const classes = useStyles();
    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    borderRadius: "8px",
                    p: 4,
                }}
            >
                <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" style={{ gap: "30px" }}>
                    {Object.entries(categorysWithFieldsDescByEntrySize).map(([categoryName, categoryFields]) => (
                        <Box key={categoryName} display="flex" flexDirection="column">
                            <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                                <Trans i18nKey={categoryLabels[categoryName] || categoryName} />
                            </span>
                            <ul className={classes.list}>
                                {categoryFields.map((field) => {
                                    const fieldAlreadyExists = formTree.some(
                                        (rule) => rule.properties.field === field.name,
                                    );
                                    const rulePropertiesFromFieldsConfig = fields[field.name].defaultRuleProperties;
                                    const rulePropertiesFromWidgetType =
                                        defaultRulePropertiesByWidgetType[fields[field.name].widgetType];
                                    const defaultProperties = rulePropertiesFromFieldsConfig
                                        ? rulePropertiesFromFieldsConfig
                                        : rulePropertiesFromWidgetType
                                          ? rulePropertiesFromWidgetType
                                          : {};
                                    return (
                                        <li
                                            className={`${classes.filterLink} ${fieldAlreadyExists ? "disabled" : ""}`}
                                            key={field.name}
                                            onClick={() => {
                                                if (fieldAlreadyExists) return;
                                                dispatch({
                                                    type: ADD_RULE,
                                                    payload: {
                                                        type: "rule",
                                                        properties: {
                                                            field: field.name,
                                                            value: "",
                                                            disabled: false,
                                                            ...defaultProperties,
                                                        },
                                                    },
                                                });
                                                onClose();
                                            }}
                                        >
                                            {"- "}
                                            <Trans i18nKey={field.label} />
                                        </li>
                                    );
                                })}
                            </ul>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Modal>
    );
};
