import React, {Component} from 'react';
import {Dialog, Typography} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined';
import DefaultTextField from 'components/hmo-textfield/DefaultTextField';
import HmoFilledButton from 'components/button/HmoFilledButton';
import HmoBorderedButton from 'components/button/HmoBorderedButton';
import {withRouter} from 'utils/Route.utils';
import {connect} from 'react-redux';
import connector from './CareProviders.connect';

export class NewCareProviderDialog extends Component {

    state = {
        name: '',
        website: '',
        street: '',
        zipCode: '',
        country: '',
        state: '',
        city: '',
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    addCareProvider = () => {
        this.props.upsertCareProvider({
            name: this.state.name,
            website: this.state.website,
            street: this.state.street,
            zipCode: this.state.zipCode,
            country: this.state.country,
            state: this.state.state,
            city: this.state.city,
        }).then(response => {
            this.props.listCareProviders();
            this.props.selectCareProvider(response.payload.data)();
        });
    }

    render() {
        const {t: translate, cancel} = this.props;
        const {handleChange, addCareProvider} = this;
        const {name, website, street, zipCode, country, state, city} = this.state;
        const disabled = !name || !city;
        return (
                <Dialog open={true}>
                    <Flex item container column padding={15} style={{minWidth: 420}}>
                        <Flex item container justifyContent={'space-between'} alignItems={'center'}
                              style={{marginRight: 7}}>
                            <Typography variant={"h5"}>{translate("global.new-care-provider")}</Typography>
                            <LocalHospitalOutlinedIcon style={{marginLeft: 20}}/>
                        </Flex>
                        <DefaultTextField value={name || ''}
                                          label={translate("global.name")}
                                          name={'name'}
                                          error={!name}
                                          onChange={handleChange}/>
                        <DefaultTextField label={translate("case.website")}
                                          value={website || ''}
                                          name={'website'}
                                          onChange={handleChange}/>
                        <DefaultTextField value={country || ''}
                                          label={translate("case.country")}
                                          name={'country'}
                                          onChange={handleChange}/>
                        <DefaultTextField label={translate("case.state")}
                                          value={state || ''}
                                          name={'state'}
                                          onChange={handleChange}/>
                        <DefaultTextField label={translate("case.zip")}
                                          value={zipCode || ''}
                                          name={'zipCode'}
                                          onChange={handleChange}/>
                        <DefaultTextField value={street || ''}
                                          label={translate("case.street")}
                                          name={'street'}
                                          onChange={handleChange}/>
                        <DefaultTextField value={city || ''}
                                          label={translate("case.city")}
                                          error={!city}
                                          name={'city'}
                                          onChange={handleChange}/>

                        <Flex item container style={{marginTop: 10, marginRight: 7}}>
                            <HmoBorderedButton onClick={cancel} size={'small'} style={{width: '50%', marginRight: 10}}>
                                {translate("global.cancel")}
                            </HmoBorderedButton>
                            <HmoFilledButton onClick={addCareProvider} disabled={disabled} style={{width: '50%'}}>
                                {translate("global.add-care-provider")}
                            </HmoFilledButton>
                        </Flex>
                    </Flex>
                </Dialog>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(
        withTranslation()(
                withRouter(NewCareProviderDialog)
        )
);
