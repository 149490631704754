import { Button, DialogTitle, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { useTranslation } from "react-i18next";
import DialogContent from "@material-ui/core/DialogContent";
import {
    useContactConfirmationOfPatientConfidentialityQuery,
    useAcceptContactConfirmationOfPatientConfidentialityMutation,
} from "scenes/patient/PatientApi";
import { useParams } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { logout } from "scenes/Main.action";
import { useNavigate } from "react-router-dom";
import { MANAGED_CARE } from "../../routes/routes";

const useClasses = makeStyles((theme) => ({
    acceptButton: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.warning.main,
        "&:hover": {
            backgroundColor: theme.palette.warning.dark,
        },
    },
    title: {
        color: theme.palette.warning.dark,
    },
    content: {
        fontSize: "1.1em",
    },
}));

/**
 * This modal will not appear till it does initial data from the server.
 * probably some loading spinner should be added.
 *
 */
export const ContactPersonConfirmationsOfConfidentialityModal = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accesses = useSelector((state) => state.mainReducer.user.accesses, shallowEqual);
    const [isOpen, setOpen] = useState(true);
    const [acceptConfidentiality] = useAcceptContactConfirmationOfPatientConfidentialityMutation();
    const classes = useClasses();
    const { patientId } = useParams();
    const { t } = useTranslation();

    const { data, isLoading  } = useContactConfirmationOfPatientConfidentialityQuery();
    const hasConfirmedConfidentialityOfContactPerson = data?.some((x) => x.patientUserDetailsId === Number(patientId));
    return (
            <Dialog BackdropProps={{ style: { backdropFilter: "blur(8px)" } }} open={isOpen && !hasConfirmedConfidentialityOfContactPerson}>
                {isLoading || hasConfirmedConfidentialityOfContactPerson ? null :
                <>
                    <DialogTitle className={classes.title}>{t("global.warning")}!</DialogTitle>
                    <DialogContent className={classes.content} dividers={true}>
                        {t("contactPersonConfirmationsOfConfidentialityModal.content", { returnObjects: true }).map(
                            (line) => (
                                <p>{line}</p>
                            ),
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                const assignedPatientsCount = new Set(
                                    accesses
                                        .filter((x) => x.invitedRoleType === "CONTACT_PERSON")
                                        .map((x) => x.patientId),
                                ).size;
                                setOpen(false);
                                assignedPatientsCount > 1 ? navigate(MANAGED_CARE.path) : dispatch(logout());
                            }}
                        >
                            {t("global.cancel").toUpperCase()}
                        </Button>
                        <Button
                            onClick={() => {
                                acceptConfidentiality({ patientUserDetailsId: Number(patientId) })
                                    .then(() => {
                                        setOpen(false);
                                    })
                                    .catch((e) => {
                                        console.error(e);
                                    });
                            }}
                            className={classes.acceptButton}
                            variant="outlined"
                        >
                            {t("global.accept-and-continue")}
                        </Button>
                    </DialogActions>
                </>}
            </Dialog>
        )
};
