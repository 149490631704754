import { useContext } from "react";
import { DisableToggle } from "../node-control-components/DisableToggle";
import { RemoveRuleBtn } from "../node-control-components/RemoveRuleBtn";
import { AddOrClauseButton } from "../node-control-components/AddOrClauseButton";
import { FilterWidgetRenderer } from "../../search-rule-widgets/FilterWidgetRenderer";
import filterState from "../../../../filter-state";
import { Box } from "@material-ui/core";
import { Trans } from "react-i18next";

export const RuleRenderer = ({ componentData, indexPath }) => {
    const { dispatch } = useContext(filterState.queryFormContext);

    const handleUpdateRule = (_idx, updatedProperties) => {
        dispatch({
            type: filterState.actions.UPDATE_NODE,
            indexPath,
            payload: {
                properties: updatedProperties,
            },
        });
    };
    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
                <DisableToggle
                    rule={componentData.node}
                    idx={componentData.index}
                    handleUpdateRule={handleUpdateRule}
                />
            </div>
            {componentData.config.widgetConfig.showLabel !== false &&
                <Box display="flex" alignItems="center" mr={1}>
                    <Trans i18nKey={componentData.config.widgetConfig.label || componentData.node.properties.field} />
                </Box>
            }
            <FilterWidgetRenderer
                config={componentData.config.widgetConfig}
                rule={componentData.node}
                idx={componentData.index}
                handleUpdateRule={handleUpdateRule}
                widgetType={componentData.config.widgetType}
            />
            {componentData.config.widgetConfig.allowOrCondition && (
                <AddOrClauseButton
                    onClick={() => {
                        dispatch({
                            type: filterState.actions.TRANSFORM_RULE_TO_OR_GROUP,
                            indexPath: indexPath,
                        });
                    }}
                />
            )}
            <RemoveRuleBtn
                onClick={() => {
                    if (indexPath.length > 1) {
                        dispatch({
                            type: filterState.actions.REMOVE_RULE_FROM_AND_GROUP,
                            indexPath,
                        });
                    } else {
                        dispatch({
                            type: filterState.actions.REMOVE_RULE,
                            index: componentData.index,
                        });
                    }
                }}
            />
        </div>
    );
};
