import {makeStyles} from "@material-ui/core/styles";
import Flex from 'components/grid/Flex';
import {Typography} from '@material-ui/core';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({

}));

//NOTE LM: We dont have a UI for this
const CohortEdit = ({route}) => {
    const classes = useStyles();
    const {t: translate} = useTranslation();
    return (
            <Flex item container>
                CohortEdit
            </Flex>
    );
};

export default CohortEdit;
