import {
    getUserDetails,
    createUserDetails,
    updateUserDetails,
    deleteUserDetails,
    uploadProfileImage,
    fetchCareProviderContacts
} from 'scenes/user-manager/UserManager.action';

export default {
    mapStateToProps: (state) => {
        return {
            user: state.mainReducer.user,
            configuration: state.caseReducer.configuration
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            createUserDetails: (props) => dispatch(createUserDetails(props)),
            updateUserDetails: (props) => dispatch(updateUserDetails(props)),
            getUserDetails: userDetailsId => dispatch(getUserDetails(userDetailsId)),
            deleteUserDetails: userDetailsId => dispatch(deleteUserDetails(userDetailsId)),
            uploadContactImage: (contactBusinessId, file) => dispatch(uploadProfileImage(contactBusinessId, file)),
            fetchCareProviderContacts: careProviderId => dispatch(fetchCareProviderContacts(careProviderId)),
        };
    }
};
