import {
    Button
} from "@material-ui/core";
import HmoFilledButton from 'components/button/HmoFilledButton';
import AddIcon from '@material-ui/icons/Add';
import InviteManagerDialog from './InviteManagerDialog';
import _ from 'lodash';
import EmailAddressEditorDialog from 'scenes/user-manager/access-manager/EmailAddressEditorDialog';
import {GRANT} from 'domain/GrantType';
import {useState} from 'react';
import { useTranslation } from "react-i18next";

export const INVITE_MANAGER = 'INVITE_MANAGER';
export const EMAIL_MANAGER = 'EMAIL_MANAGER';

const AccessManager = (props) => {

    const {
        patient, person, invitedRoleType, callback, buttonStyle, floatingButton, disabled, beforeOpen,
        excludedUserIds = [], existingInvitesForEntity = [], entityIdToAssign, entityType, buttonTextOverride,
        inviteButtonTextOverride
    } = props;
    const {t: translate} = useTranslation();
    const [dialog, setDialog] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const onEmailChange = (email) => {
        setEmailAddress(email);
    };

    const toggleOpen = (event, dialogUpdate) => {
        if (event) event.stopPropagation();
        if(beforeOpen && !dialog){
            beforeOpen();
        }
        setDialog(dialogUpdate || (dialog ? '' : EMAIL_MANAGER))
    };

    const matchingEmail = existingInvitesForEntity.find(invite =>  invite.invitedRoleType === invitedRoleType
            && person?.emailAddresses?.findIndex(email => email === invite.invitedEmail) !== -1
    );
    const alreadyInvited = !_.isEmpty(person) && !!matchingEmail;
    const buttonText = buttonTextOverride
            || (
                    alreadyInvited
                            ? translate('global.pending-invitation')
                            : inviteButtonTextOverride || translate("global.grant-access")
            );
    return (
            <>
                {
                    floatingButton
                            ? <Button data-testid="grant-patient-access-btn" onClick={toggleOpen} disabled={disabled}
                                      style={{textTransform: 'unset', color: '#256af6', fontWeight: 'bold'}}>
                                {buttonText}
                                <AddIcon style={{backgroundColor: '#cbdbfd', borderRadius: '50%', marginLeft: 10}}/>
                            </Button>
                            : <HmoFilledButton data-testid="grant-patient-access-btn" onClick={toggleOpen} style={buttonStyle} disabled={disabled}>
                                {buttonText}
                            </HmoFilledButton>
                }

                {
                    dialog === INVITE_MANAGER &&
                    <InviteManagerDialog {...{
                        person, toggleOpen, patient, invitedRoleType, emailAddress,
                        entityIdToAssign,
                        refresh: callback,
                        onEmailClick: () => {
                            setDialog(EMAIL_MANAGER);
                        }
                    }}/>
                }
                {
                    dialog === EMAIL_MANAGER &&
                    <EmailAddressEditorDialog {...{
                        title: translate('global.select-email'),
                        editorType: GRANT,
                        invitedRoleType,
                        person,
                        patient,
                        entityIdToAssign,
                        entityType,
                        refresh: callback,
                        toggleOpen,
                        onEmailChange: email => {
                            onEmailChange(email);
                            toggleOpen(null, INVITE_MANAGER);
                        },
                        originalEmail: emailAddress || person?.emailAddresses?.[0],
                        excludedUserIds
                    }}/>
                }
            </>
    );
};

export default AccessManager;
