import * as actionTypes from './ManagedCare.action.types';
import _ from 'lodash';

const initialState = {
    patients: [],
    programTemplates: [],
    programNamesFromPatients: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_PATIENT_LIST_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.patients = action.payload?.data?.map(({patient, ...other})=>({
                ...patient,
                ...other
            }));
            newState.programNamesFromPatients = [...new Set(newState.patients.flatMap(item=>item?.programs?.split(',')))];
            return newState;
        }
        case actionTypes.GET_PROGRAM_TEMPLATE_LIST_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.programTemplates = action.payload.data;
            return newState;
        }
        default:
            return state;
    }
}
