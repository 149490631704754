import {makeStyles, useTheme, Box, IconButton} from "@material-ui/core";
import {DragIndicator, Edit, Delete} from "@material-ui/icons";
import {getStepStatus} from "scenes/patient/functions";
import {ItemStatusEnum} from 'domain/Step.model';
import {isManager} from "domain/User.model";
import {ProgramTemplateStepRendererViewMode} from "./ProgramTemplateStepRendererViewMode";
import {ProgramTemplateStepRendererUpsertMode} from "./ProgramTemplateStepRendererUpsertMode";
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import {isStepDependencyMissing, isStepDependencyPaused} from '../functions';

const useStyles = makeStyles((theme) => ({
    position: {
        fontSize: "40px",
        minWidth: "44px",
        marginRight: theme.spacing(1)
    },
    content: {
        background: "white",
        transition: theme.transitions.create("all", {duration: ".2s"}),
        borderStyle: "solid",
        borderWidth: "2px",
        borderRadius: "10px",
        borderColor: theme.palette.grey[400],
        flex: 1,
        minHeight: 100,
    },
    programStepContainerRoot: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
    }
}));

export const ProgramTemplateStepRenderer = ({
                                                user,
                                                position,
                                                step,
                                                toggleDelete,
                                                dragHandleProps,
                                                editableId,
                                                setEditableId,
                                                cancelStep,
                                                saveStep,
                                                otherStepsInProgram = [],
                                            }) => {
    const isEditable = editableId === step?.id;
    const stepDependencyIsMissing = isStepDependencyMissing(otherStepsInProgram, step.dependsOnTaskId);
    const stepDependencyIsPaused = isStepDependencyPaused(otherStepsInProgram, step.dependsOnTaskId);
    const noAppropriateDependencyFound = stepDependencyIsMissing || stepDependencyIsPaused;
    const classes = useStyles();
    const theme = useTheme();
    const stepStatus = getStepStatus(step);
    const stepColor = (() => {
        switch (stepStatus) {
            case ItemStatusEnum.Completed:
                return theme.palette.primary.light;
            case ItemStatusEnum.Upcoming:
                return theme.palette.warning.light;
            case ItemStatusEnum.Overdue:
                return theme.palette.error.light;
            case ItemStatusEnum.Normal:
            default:
                return theme.palette.grey[500];
        }
    })();
    const togglePaused = () => {
        saveStep({...step, paused: !step.paused});
    };

    return (
            <>
                <div className={classes.programStepContainerRoot}>
                    <Box display={"flex"} alignItems={"center"}>
                        <div style={{display: "flex"}} {...dragHandleProps}>
                            <DragIndicator/>
                        </div>
                        <div className={classes.position}>{position}</div>
                    </Box>

                    <Box
                            display={"flex"}
                            flexDirection={"column"}
                            flex={"1 1 auto"}
                            style={{borderColor: stepColor}}
                            className={classes.content}
                    >
                        {
                            isEditable &&
                            <ProgramTemplateStepRendererUpsertMode
                                    {...{
                                        step,
                                        otherStepsInProgram,
                                        cancelStep,
                                        saveStep
                                    }}
                            />
                        }

                        {
                            !isEditable &&
                            <ProgramTemplateStepRendererViewMode
                                    {...{
                                        step,
                                        dependencyIsMissing: noAppropriateDependencyFound,
                                        dependsOnPosition: otherStepsInProgram.find(d => d.id === step.dependsOnTaskId)?.position,
                                    }}
                            />
                        }
                    </Box>
                    {
                        isManager(user) &&
                        <Box display={"flex"} flexDirection={"column"} flex={"0 1 auto"}>

                            <IconButton data-testid="edit-step-btn" onClick={() => setEditableId(step.id)} >
                                <Edit/>
                            </IconButton>

                            <IconButton onClick={togglePaused}>
                                {step.paused ? <PlayCircleOutlineIcon/> : <PauseCircleOutlineIcon/>}
                            </IconButton>

                            <IconButton
                                    onClick={toggleDelete}
                                    disabled={!!(editableId || step.isCompleted)}
                            >
                                <Delete/>
                            </IconButton>
                        </Box>
                    }
                </div>
            </>
    );
};
