import _ from 'lodash';

/**
 * Generates the new order of steps based on the reordered item's source and destination indexes.
 * @param {{id: number}[]} steps
 * @param {string[]} stepsOrder
 * @param {{sourceIndex: number, destinationIndex: number}} indexes - indexes of the reordered item
 * @returns {string[]}
 */
export const getStepsOrderAfterReordering = (steps, stepsOrder, {sourceIndex, destinationIndex} ) => {
    const sortedObject = _.orderBy(steps, (item) => _.indexOf(stepsOrder, String(item.id)));
    const [reorderedItem] = sortedObject.splice(sourceIndex, 1);
    sortedObject.splice(destinationIndex, 0, reorderedItem);
    return sortedObject.map((item) => String(item.id));
};
