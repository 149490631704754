import {useTranslation} from "react-i18next";
import BlueButtonWithIcon from 'components/button/BlueButtonWithIcon';

const AddNewStepButton = ({onClick, disabled = false}) => {
    const {t} = useTranslation();
    return <BlueButtonWithIcon label={t("global.add-step")}
                               onClick={onClick}
                               disabled={disabled}/>;
};

export default AddNewStepButton;
