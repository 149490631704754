import React, {Component} from 'react';
import Flex from 'components/grid/Flex';
import { InputLabel } from '@material-ui/core';

export class RadioButtonSwitch extends Component {
    render() {
        const {
            item, style, labelRight, labelLeft, isLeftSelected, toggle, disabled
        } = this.props;
        return (
            <Flex item={item || '0 1 auto'} container style={{minHeight: 36, cursor: 'pointer', ...style}}
                  justifyContent={'space-between'} alignItems={'center'} onClick={disabled ? ()=>{} :toggle}>
                <InputLabel>{labelLeft}</InputLabel>
                <Flex container style={{
                    justifyContent: isLeftSelected ? 'unset' : 'flex-end',
                    backgroundColor: '#efefef',
                    borderRadius: 100,
                    boxShadow: "0px 3px 6px #00000029",
                    width: 48,
                    height: 24,
                    marginTop: 2,
                    marginBottom: 2,
                    marginLeft: 6,
                    marginRight: 6,
                }}>
                    <div style={{
                        backgroundColor: disabled ? 'grey' : '#2e75b6',
                        borderRadius: 100,
                        boxShadow: "0px 3px 6px #00000029",
                        width: 20,
                        marginTop: 3,
                        marginBottom: 2,
                        marginLeft: 2,
                        marginRight: 2,
                    }}/>
                </Flex>
                <InputLabel>{labelRight}</InputLabel>
            </Flex>
        );
    }
}

export default RadioButtonSwitch;
