import React from 'react';
import {TextField} from '@material-ui/core';

function isValidBloodPressureInput(inputValue) {
    if (typeof inputValue !== 'string') {
        return false;
    }

    if (inputValue.trim() === '') {
        return false;
    }

    if (!/^[0-9/ ]+$/.test(inputValue)) {
        return false;
    }

    inputValue = inputValue.replace(/\s/g, '');

    if (!/^\d{1,3}\/?\d{0,3}$/.test(inputValue)) {
        return false;
    }

    if (!/\d{1,3}\/?\d{0,3}/.test(inputValue)) {
        return false;
    }

    const [systolic, diastolic] = inputValue.split('/');

    // Check if systolic and diastolic values are within range
    return !(+systolic < 0 || +systolic > 300 || +diastolic < 0 || +diastolic > 300);
}

const BloodPressureInput = ({value, name, form, error, ...props}) => {


    const handleOnChange = (event) => {
        const inputValue = event.target.value;
        if (isValidBloodPressureInput(inputValue)) {
            form.setFieldValue(name, inputValue);
        } else {
            form.setFieldValue(name,'');
        }
    };

    return (
        <TextField
            {...props}
            label="Blutdruck"
            value={value || ''}
            onChange={handleOnChange}
            error={error}
            helperText={error ? "Invalid input" : ""}
            inputProps={{maxLength: 7}}
            placeholder="e.g. 120/80"
            fullWidth
        />
    );
};

export default BloodPressureInput;
