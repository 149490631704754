import React, {Component} from 'react';
import Flex from 'components/grid/Flex';
import Tag from 'components/tags/Tag';
import {lipstick, burntSienna, danube, htmlWhite, pictonBlue} from 'components/colors/Colors';
import _ from 'lodash';
import {Autocomplete} from '@material-ui/lab';
import HmoInputLabel from 'components/input-label/HmoInputLabel';
import DefaultTextField from 'components/hmo-textfield/DefaultTextField';

const colors = {
    "Herz": burntSienna,
    "Krebs": danube,
    "Orthopädie": lipstick
};

export class Indications extends Component {

    state = {
        isInputVisible: false,
    };

    handleIndicationChange = indication => {
        this.props.updateCase({
            indication,
            subIndications: []
        })
    };

    handleSubIndicationChange = (event, value) => {
        this.props.updateCase({
            subIndications: value
        })
    };

    render() {
        const {
            translate, indications, subIndications, selectedIndication, selectedSubIndications, isEditDisabled
        } = this.props;
        const subIndicationTags = subIndications
            .filter(si => si.indication === selectedIndication)
            .map(si => si.subIndication);
        return (
            <Flex container column>
                <Flex container column style={{margin: '5px 0px'}}>
                    <HmoInputLabel>{translate("case.indication")}</HmoInputLabel>
                    <Flex item container>
                        {
                            indications.map((indication, index) => (
                                <Tag {...{
                                    doTranslate: true,
                                    key: index,
                                    isEditDisabled,
                                    selected: selectedIndication === indication,
                                    tag: indication,
                                    selectedBackgroundColor: colors[indication],
                                    toggleSelection: this.handleIndicationChange,
                                    unSelectedColor: selectedIndication ? undefined : '#d32f2f'
                                }}/>
                            ))
                        }
                    </Flex>
                </Flex>
                {
                    selectedIndication && !_.isEmpty(subIndicationTags) &&
                    <Autocomplete
                        multiple
                        style={{marginRight: 8}}
                        options={subIndicationTags || []}
                        onChange={this.handleSubIndicationChange}
                        value={selectedSubIndications || ''}
                        freeSolo
                        disabled={isEditDisabled}
                        ChipProps={{
                            style: {
                                height: 25,
                                color: htmlWhite,
                                backgroundColor: pictonBlue
                            },
                            color: 'secondary'
                        }}
                        renderInput={params => (
                            <DefaultTextField
                                {...params}
                                label={translate("case.sub-indication")}
                                fullWidth
                            />
                        )}
                    />
                }
            </Flex>
        );
    }
}

export default Indications;
