import * as actionTypes from './Main.action.types';
import {FETCH_CURRENT_USER_SUCCESS, FETCH_CURRENT_USER_DETAILS_SUCCESS, GET_OUTSTANDING_INVITES_SUCCESS, REJECT_ACCESS, FETCH_WALKTHROUGH_SUCCESS } from 'scenes/user-manager/UserManager.action.types';
import _ from "lodash";
import {CLINIC_CENTER, CARE_PROVIDER} from 'domain/EntityAuthorizationType.model';
import {DOCTOR, ASSISTANT, CLINIC} from 'domain/User.model';

const initialState = {
    authenticated: false,
    isConfigurationLoaded: false,
    user: '',
    userDetails: null,
    accessToken: undefined,
    refreshToken: undefined,
    outstandingInvites: [],
    handledOutstandingInvites: false,
    appScreenIsBlocked: false,
    glowUpNewMedicationButton: false,
    walkthroughsCompleted: {
        medicationListWalkthrough: true,
        appIntroWalkthrough: true,
        introMedicationPlanWalkthrough: true,
        newMedicationPlanWalkthrough: true,
    }
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_CURRENT_USER_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.user = {
                ...action.payload.data,
                clinicCenterId: action.payload.data.accesses
                    .find(access => access.entityType === CLINIC_CENTER && access.invitedRoleType === CLINIC)?.entityId,
                careProviderId: action.payload.data.accesses
                    .find(access => access.entityType === CARE_PROVIDER
                        && (access.invitedRoleType === DOCTOR || access.invitedRoleType === ASSISTANT))?.entityId,
                careProviderIds: action.payload.data.accesses.filter(access => access.entityType === CARE_PROVIDER && (access.invitedRoleType === DOCTOR || access.invitedRoleType === ASSISTANT)).map(access => access.entityId),
                clinicCenterIds: action.payload.data.accesses.filter(access => access.entityType === CLINIC_CENTER && access.invitedRoleType === CLINIC).map(access => access.entityId)
            };
            newState.appIntroWalkthrough = !action.payload.data.walkthroughCompleted;
            newState.authenticated = true;
            return newState;
        }
        case GET_OUTSTANDING_INVITES_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.outstandingInvites = _.uniqBy(action.payload.data, 'invitedEmail');
            return newState;
        }
        case REJECT_ACCESS: {
            const newState = _.cloneDeep(state);
            const index = state.outstandingInvites.findIndex(invite => invite.inviteToken === action.data.inviteToken);
            if(index !== -1){
                newState.outstandingInvites = state.outstandingInvites.splice(index, 1);
            }
            return newState;
        }
        case actionTypes.SET_HANDLED_OUTSTANDING_INVITES : {
            const newState = _.cloneDeep(state);
            newState.handledOutstandingInvites = action.payload.data;
            return newState;
        }
        case actionTypes.LOAD_ACCESS_TOKEN: {
            const newState = _.cloneDeep(state);
            newState.accessToken = action.data.accessToken;
            return newState;
        }
        case actionTypes.LOAD_REFRESH_TOKEN: {
            const newState = _.cloneDeep(state);
            newState.refreshToken = action.data.refreshToken;
            return newState;
        }
        case actionTypes.LOGOUT_SUCCESS:
        case actionTypes.LOGOUT_FAIL: {
            const newState = _.cloneDeep(state);
            newState.user = '';
            newState.accessToken = undefined;
            newState.authenticated = false;
            return newState;
        }
        case actionTypes.LOAD_SETUP: {
            const newState = _.cloneDeep(state);
            newState.isConfigurationLoaded = false;
            return newState;
        }
        case actionTypes.LOAD_SETUP_DONE: {
            const newState = _.cloneDeep(state);
            newState.isConfigurationLoaded = true;
            return newState;
        }
        case  FETCH_CURRENT_USER_DETAILS_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.userDetails = action.payload.data;
            return newState;
        }

        case actionTypes.BLOCK_APP_SCREEN: {
            const newState = _.cloneDeep(state);
            newState.appScreenIsBlocked = true;
            return newState;
        }

        case actionTypes.TOGGLE_SHOW_APP_WALKTHROUGH: {
            const newState = _.cloneDeep(state);
            newState.walkthroughsCompleted.appIntroWalkthrough = !newState.walkthroughsCompleted.appIntroWalkthrough;
            return newState;
        }

        case actionTypes.SET_APP_INTRO_WALKTHROUGH_COMPLETED: {
            const newState = _.cloneDeep(state);
            newState.walkthroughsCompleted.appIntroWalkthrough = action.payload;
            return newState;
        }

        case actionTypes.SET_SHOW_MEDICATION_PLAN_WALKTHROUGH: {
            const newState = _.cloneDeep(state);
            newState.walkthroughsCompleted.introMedicationPlanWalkthrough = action.payload;
            return newState;
        }

        case actionTypes.SET_SHOW_NEW_MEDICATION_WALKTHROUGH: {
            const newState = _.cloneDeep(state);
            newState.walkthroughsCompleted.newMedicationPlanWalkthrough = action.payload;
            return newState;
        }

        case actionTypes.SET_MEDICATION_LIST_WALKTHROUGH_COMPLETED: {
            const newState = _.cloneDeep(state);
            newState.walkthroughsCompleted.medicationListWalkthrough = action.payload;
            return newState;
        }

        case actionTypes.SET_NEW_MEDICATION_BTN_HIGHLIGHT: {
            const newState = _.cloneDeep(state);
            newState.glowUpNewMedicationButton = action.payload;
            return newState;
        }

        case FETCH_WALKTHROUGH_SUCCESS: {
            const newState = _.cloneDeep(state);
            const { introMedicationPlanWalkthrough, newMedicationPlanWalkthrough, appIntroWalkthrough, medicationListWalkthrough } = action.payload.data;
            newState.walkthroughsCompleted = {
                introMedicationPlanWalkthrough,
                newMedicationPlanWalkthrough,
                appIntroWalkthrough,
                medicationListWalkthrough

            }
            return newState;;
        }

        default:
            return state;
    }
}
