import {Dialog, Typography, Button} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {useTranslation} from "react-i18next";
import {DateTextField} from "scenes/patient/program-view/ProgramStepRenderer/ProgramStepRendererCommonComponents";
import DatePicker from "components/calendar/DatePicker";
import moment from 'moment';
import {useState} from 'react';

const TrueDateSelectorDialog = (props) => {
    const {onClose, saveStep, step} = props;
    const [date, setDate] = useState(moment(step.date || moment()));
    const {t: translate} = useTranslation();
    const save = () => {
        saveStep({
            ...step,
            isCompleted: true,
            date: moment(step.date).isSame(date)
                    ? moment(date).set({hour: moment(step.date).hour(), minute: moment(step.date).minute()})
                    : date
        });
        onClose();
    }

    const setToday = () => {
        setDate(moment());
    }

    return (
            <Dialog open={true} onClose={onClose}>
                <Flex item container column padding={10}>
                    <Typography style={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        marginBottom: 10
                    }}>{translate('global.please-select-date')}</Typography>
                    <DatePicker
                            {...{
                                smallLabel: true,
                                style: {flex: "auto", marginBottom: 10},
                                value: date,
                                name: "date",
                                onChange: setDate,
                                TextFieldComponent: DateTextField,
                            }}
                    />
                    <Flex item container>
                        <Button onClick={() => setDate(step.date)}
                                disabled={!step.date}
                                style={{
                                    marginRight: 5,
                                    ...(moment(date).isSame(step.date, 'day') && {border: '1px solid lightgrey'})
                                }}>
                            {translate('global.select-original-date')}
                        </Button>
                        <Button onClick={setToday}
                                style={{...(moment(date).isSame(moment(), 'day') && {border: '1px solid lightgrey'})}}>
                            {translate('global.select-current-date')}
                        </Button>
                    </Flex>
                    <Flex item container style={{marginTop: 25}} justifyContent={'space-between'}>
                        <Button color={'secondary'} variant={'outlined'} onClick={onClose}>
                            {translate('global.cancel')}
                        </Button>
                        <Button color={'primary'} variant={'outlined'} onClick={save}>
                            {translate('global.save')}
                        </Button>
                    </Flex>
                </Flex>
            </Dialog>

    );
};

export default TrueDateSelectorDialog;
