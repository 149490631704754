import HMOApi from "store/api/HMOApi";

export const UnseenApi = HMOApi.injectEndpoints({
    endpoints: (build) => ({
        deleteUnseen: build.mutation({
            query: ({patientId, entityType, entityId, unseenBy}) => ({
                url: `/unseen/${patientId}/${entityType}/${entityId}/${unseenBy}`,
                method: 'DELETE',
            }),
        })
    }),
});

export const {
    useDeleteUnseenMutation
} = UnseenApi;
