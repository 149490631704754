import Flex from 'components/grid/Flex';
import {sideBarIndex} from 'components/zindex/zIndex';
import SidebarListItem from './SidebarListItem';
import {LANDING} from 'routes/routes';
import {makeStyles} from "@material-ui/core/styles";
import {Avatar, Divider, ClickAwayListener} from "@material-ui/core";
import LogoutWithIcon from 'components/logout/LogoutWithIcon';
import {useState} from 'react';
import {
    transparentBlack03,
    transparentBlack087,
    htmlWhite,
    persianGreen
} from 'components/colors/Colors';
import {profileContainer} from 'components/zindex/zIndex';
import FeatureSwitch from 'utils/FeatureSwitch';

const useStyles = makeStyles((theme) => ({
    profile: {
        position: 'absolute',
        boxShadow: `0 0 5px 0 ${transparentBlack03}`,
        left: 50,
        bottom: 0,
        color: transparentBlack087,
        backgroundColor: htmlWhite,
        fontSize: 13,
        fontFamily: 'Arial',
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: 0.01071,
        zIndex: profileContainer,
        width: 'auto',
        paddingTop: 5
    },
    version: {
        color: persianGreen,
        fontSize: 12,
        textAlign: 'center'
    },
    main: {
        backgroundColor: theme.palette.sidebarColor,
        color: 'red',
    },
}));

const Footer = ({classes}) => {
    const [showProfile, setShowProfile] = useState(false);
    const toggleShowProfile = () => {
        setShowProfile(!showProfile)
    };
    return <Flex container direction={'column'} alignItems={'center'}>
        {
            !showProfile &&
            <Avatar
                    id={`HmoAvatar-loading`}
                    style={{
                        width: 30,
                        height: 30,
                        backgroundColor: 'grey',
                        fontSize: 15
                    }}
                    onClick={toggleShowProfile}
            >
                {"--"}
            </Avatar>
        }

        {
            showProfile &&
            <ClickAwayListener onClickAway={toggleShowProfile}>
                <Flex container direction={'column'} className={classes.profile}>
                    <Flex item container center column style={{padding: 12}}>
                        <Avatar
                                id={`HmoAvatar-loading`}
                                style={{
                                    width: 30,
                                    height: 30,
                                    backgroundColor: 'grey',
                                    fontSize: 15
                                }}
                        >
                            {"--"}
                        </Avatar>
                    </Flex>
                    {
                        FeatureSwitch.versionInProfile.active &&
                        <span className={classes.version}>v.{process.env.REACT_APP_VERSION}</span>
                    }
                    <Divider/>
                    <LogoutWithIcon/>
                </Flex>
            </ClickAwayListener>
        }
    </Flex>
};

const LoadingWithSidebar = () => {
    const classes = useStyles();
    return (
            <Flex item container column className={classes.main} style={{
                position: 'fixed',
                height: '100vh',
                top: 0,
                left: 0,
                width: 60,
                maxWidth: 60,
                zIndex: sideBarIndex,
            }}>
                <SidebarListItem route={LANDING}/>
                <Flex item={'1'} container justifyContent={'center'} direction={'column'}/>
                <Footer classes={classes}/>
            </Flex>
    );
};

export default LoadingWithSidebar;
