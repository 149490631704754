import HMOApi from "store/api/HMOApi";

export const PendingDeletion = HMOApi.injectEndpoints({
    endpoints: (build) => ({
        removePendingDeletion: build.mutation({
            query: ({entityType, entityId}) => ({
                url: `pending-deletion/${entityType}/${entityId}`,
                method: "DELETE"
            }),
        }),
    }),
});

export const {
    useRemovePendingDeletionMutation
} = PendingDeletion;
