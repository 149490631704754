import React, {Component} from 'react';
import {Typography, withStyles} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import moment from 'moment/min/moment-with-locales';
import {dustyGrey, htmlWhite, materialBlue, transparentBlack054} from 'components/colors/Colors'
import {withTranslation} from "react-i18next";
import MomentUtils from '@date-io/moment';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import utils from 'utils/Utils';
import 'moment/locale/de';

const styles = theme => ({
    page: {
        border: `1px solid ${materialBlue}`,
        borderRadius: 4,
        width: 84,
        color: materialBlue,
    },
    title: {
        fontSize: 13,
        color: transparentBlack054,
        textTransform: 'uppercase',
        marginBottom: 4
    },
    header: {
        width: '100%',
        backgroundColor: materialBlue,
        color: htmlWhite,
        textAlign: 'center',
        borderRadius: 4,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        fontSize: 12,
        textTransform: 'capitalize',
        paddingTop: 2,
        paddingBottom: 2,
    },
    dayNumber: {
        fontWeight: '700',
        fontSize: 24,
        lineHeight: 'normal',
        padding: '5px 0',
    },
    dayName: {
        fontSize: 13,
        fontWeight: 'bold',
        textTransform: 'capitalize'
    },
    datePicker: {
        display: 'none'
    },
    root: {
        minWidth: 86,
        alignItems: 'center'
    }
});

export class CalendarPage extends Component {

    state = {
        open: false
    };

    toggleOpen = () => {
        if (!this.props.disabled) {
            this.setState({open: !this.state.open})
        }
    };

    render() {
        const {t: translate, classes, title, date, name, disabled, i18n, onChange, maxDate} = this.props;
        const {open} = this.state;

        const momentDate = date && moment(date).locale(i18n.language);
        const header = momentDate && momentDate.format("MMM YYYY");
        const dayNumber = momentDate && momentDate.format("D");
        const dayName = momentDate && momentDate.format("dddd");
        return (
            <MuiPickersUtilsProvider utils={MomentUtils} locale="de">
                <Flex container direction={"column"} onClick={this.toggleOpen} className={classes.root}>
                    <Typography className={classes.title}>{title}</Typography>
                    <Flex container direction={"column"} alignItems={'center'} className={classes.page}
                          style={{color: disabled && dustyGrey, borderColor: disabled && dustyGrey, height: 86}}
                    >
                        <Typography className={classes.header} style={{backgroundColor: disabled && dustyGrey}}>
                            {header || (<br/>)}
                        </Typography>
                        {
                            !date
                                ? <Typography style={{color: materialBlue, marginTop: 15}}>{translate('global.nv')}</Typography>
                                : <Typography className={classes.dayNumber}>{dayNumber || (<br/>)}</Typography>
                        }
                        <Typography className={classes.dayName}>{dayName || (<br/>)}</Typography>
                    </Flex>
                </Flex>
                <DatePicker
                    label={title}
                    fullWidth
                    variant="outlined"
                    value={date}
                    name={name}
                    disablePast
                    maxDate={maxDate}
                    minDateMessage={''}
                    format={utils.DATE_FORMAT}
                    onChange={onChange}
                    disabled={disabled}
                    open={open}
                    onClose={this.toggleOpen}
                    classes={{root: classes.datePicker}}
                />
            </MuiPickersUtilsProvider>
        )
    }
}

export default withStyles(styles)(withTranslation()(CalendarPage));
