import * as actionTypes from './CaseEdit.action.types';
import * as fileActionTypes from 'scenes/FileHandler.action.types';
import _ from 'lodash';
import {mapNewCase, emptyCase} from 'domain/Case.model';
import {GET_ALREADY_INVITED_EMAILS_SUCCESS} from 'scenes/user-manager/UserManager.action.types';

const initialState = {
    case: emptyCase(),
    caseOnBackend: emptyCase()
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_NEW_CASE_SUCCESS: {
            const newState = _.cloneDeep(state);
            const _case = mapNewCase(action.payload.data);
            caseFromBackend(newState, _case);
            return newState;
        }
        case actionTypes.CANCEL_CASE_SUCCESS:
        case actionTypes.REOPEN_CASE_SUCCESS:
        case actionTypes.FETCH_CASE_SUCCESS: {
            const newState = _.cloneDeep(state);
            caseFromBackend(newState, _.get(action, 'data.caseFromBackend') || _.get(action, 'payload.data'))
            return newState;
        }
        case GET_ALREADY_INVITED_EMAILS_SUCCESS:{
            const newState = _.cloneDeep(state);
            newState.case = {
                ...state.case,
                alreadyInvitedEmails: action?.payload?.data
            };
            return newState;
        }
        case actionTypes.UPDATE_CASE: {
            const newState = _.cloneDeep(state);
            newState.case = {
                ...newState.case,
                ...action.data
            }
            return newState;
        }
        case actionTypes.UPDATE_PATIENT: {
            const newState = _.cloneDeep(state);
            newState.case = {
                ...state.case,
                patient: {
                    ...state.case.patient,
                    ...action.data
                }
            };
            return newState;
        }
        case actionTypes.SAVE_CASE_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.caseOnBackend = state.case;
            return newState;
        }
        case actionTypes.ADD_LOG_SUCCESS: {
            const newState = {...state};
            newState.case = {
                ...newState.case,
                caseLogs: [
                    action.payload.data,
                    ...state.case.caseLogs
                ]
            }
            return newState;
        }
        case fileActionTypes.UPLOAD_CASE_FILE_SUCCESS: {
            const newState = {...state};
            newState.case = {
                ...newState.case,
                documents: [
                    action.payload.data,
                    ...state.case.documents
                ]
            }
            return newState;
        }
        case fileActionTypes.DELETE_CASE_FILE_SUCCESS: {
            const newState = {...state};
            const index = state.case.documents
                .findIndex(document => document.documentData.documentId === action.meta.previousAction.payload.data.id);
            const documents = [...state.case.documents];
            if (index > -1) {
                documents.splice(index, 1);
            }
            newState.case = {
                ...newState.case,
                documents
            }
            return newState;
        }
        case fileActionTypes.UPDATE_DOCUMENT_SUCCESS: {
            const newState = {...state};
            const data = action.meta.previousAction.payload.data;
            const documents = [...state.case.documents];
            const index = documents
                .findIndex(document => document.documentData.documentId === data.document.documentId);
            if (index > -1) {
                const document = documents[index]
                const {documentData} = document
                const {fileName, description, documentType} = data.options;
                const newDocumentData = {
                    ...documentData,
                    fileName,
                    description,
                    type: documentType?.enumName
                }
                const newDocument = {...document, documentData: newDocumentData}
                documents[index] = {
                    ...newDocument
                };
            }
            newState.case = {
                ...newState.case,
                documents
            }
            return newState;
        }
        case actionTypes.ADD_NEW_PATIENT_QUESTION_LOG_SUCCESS: {
            const newState = {...state};
            newState.case = {
                ...newState.case,
                patientQuestionLogs: [
                    action.payload.data,
                    ...state.case.patientQuestionLogs
                ]
            }
            return newState;
        }
        case actionTypes.RESET: {
            const newState = _.cloneDeep(state);
            caseFromBackend(newState, emptyCase());
            return newState;
        }
        case fileActionTypes.DOWNLOAD_CASE_FILE_SUCCESS: {
            const newState = _.cloneDeep(state);
            const downloadedDocumentId = _.get(action, 'meta.previousAction.payload.data.id');
            newState.case.documents
                .find(document => document.documentData.documentId === downloadedDocumentId).documentData.status = 'READ';
            newState.caseOnBackend.documents
                .find(document => document.documentData.documentId === downloadedDocumentId).documentData.status = 'READ';
            return newState;
        }
        default: {
            return state;
        }
    }
}

/**
 * This is to refresh data, not to save an update. The difference between caseOnBackend and case is what is used to save
 * to the backend.
 */
function caseFromBackend(newState, _case){
    newState.case = _case;
    newState.caseOnBackend = _case;
}
